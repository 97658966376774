import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";


const useStyles = makeStyles(styles,styles1);

const uomvalues = [
  
 
  { title: 'Inch'},
  { title: 'g'},
  { title: 'kg' },
  { title: 'ml' },
  { title: 'l' },
  { title: 'cm' },
  { title: 'm' },
  { title: 'nos' },
];

const variant = [
  
 
    { title: 'Inch'},
    { title: 'g'},
    { title: 'kg' },
    { title: 'ml' },
    { title: 'l' },
    { title: 'cm' },
    { title: 'm' },
    { title: 'nos' },
  ];
  


const Outlet = ({}) => {

  const [input, setInput] = React.useState('');


    // type validation-jain
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");

const [requiredName, setrequiredName] = React.useState("");
const [requiredProductName, setrequiredProductName] = React.useState("");


const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredAddressName, setrequiredAddressName] = React.useState("");

const [requiredLocation, setrequiredLocation] = React.useState("");
const [requiredLocationName, setrequiredLocationName] = React.useState("");


const [rquiredLongitude, setLongitude] = React.useState("");
const [requiredNameLongitude, setrequiredNameLongitude] = React.useState("");

const [rquiredLatitude, setLatitude] = React.useState("");
const [requiredNameLatitude, setrequiredNameLatitude] = React.useState("");



const [requiredContact, setContactName] = React.useState("");
const [requiredContactName, setrequiredContactName] = React.useState("");

const [requiredContactNum, setCantactNumber] = React.useState("");
const [requiredContactNumber, setrequiredContactNumber] = React.useState("");

const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");

const [requiredPhoneNo1, setPhoneNum1] = React.useState("");
const [requiredNamePhone1, setrequiredNamePhone1] = React.useState("");

const [requiredPhoneNo2, setPhoneNum2] = React.useState("");
const [requiredNamePhone2, setrequiredNamePhone2] = React.useState("");

const [requiredPhoneNo3, setPhoneNum3] = React.useState("");
const [requiredNamePhone3, setrequiredNamePhone3] = React.useState("");









const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



// check Box
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };



  const defaultProps = {
    options: uomvalues,
    getOptionLabel: (option) => option.title,
  };


  const flatProps = {
    options: uomvalues.map((option) => option.title),
  };
  

//   const defaultProps = {
//     options: variant,
//     getOptionLabel: (option) => option.title,
//   };


//   const flatProps = {
//     options: variant.map((option) => option.title),
//   };
  


  const handleChange = (value) => {
 
  }

  

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};


// function that verifies if value contains only numbers
const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("error");
    }

    if (requiredProductName === "") {
      setrequiredProductName("error");
    }
    if(requiredLocationName===""){
      setrequiredLocationName("error")
    }
    
      
      if (requiredNameLongitude === "") {
        setrequiredNameLongitude("error");
      }
      if (requiredNameLatitude === "") {
        setrequiredNameLatitude("error");
      }

      if (requiredProductName === "") {
        setrequiredProductName("error");
      }
      if (requiredContactName === "") {
        setrequiredContactName("error");
      }
      if (requiredContactNumber === "") {
        setrequiredContactNumber("error");
      }
      if (typeEmailState === "") {
        settypeEmailState("error");
      }
      if (requiredNamePhone1 === "") {
        setrequiredNamePhone1("error");
      }

      if (requiredNamePhone2 === "") {
        setrequiredNamePhone2("error");
      }
      if (requiredNamePhone3 === "") {
        setrequiredNamePhone3("error");
      }

      

      
  };







  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor, color:'white' }} icon>
            {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3>
             <b>Outlet</b>
              </h3> 
            </CardHeader>
            <CardBody>
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Product Name *"
                    id="product-name"

                    success={requiredProductName === "success"}
                    error={requiredProductName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredProductName("success");
                        } else {
                          setrequiredProductName("error");
                        }
                        setrequiredName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredProductName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Images"
                    id="images"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address1 *"
                    id="address1"

                    success={requiredAddressName === "success"}
                    error={requiredAddressName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredAddressName("success");
                        } else {
                          setrequiredAddressName("error");
                        }
                        setrequiredAddress(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredAddressName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address2"
                    id="address2"

                    id="address1"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>


              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Location *"
                    id="location"

                    success={requiredLocationName === "success"}
                    error={requiredLocationName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredLocationName("success");
                        } else {
                          setrequiredLocationName("error");
                        }
                        setrequiredLocation(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredLocationName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Landmark"
                    id="landmark"

                    id="address1"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>




              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="uom"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="City" margin="normal" />}
      /> }
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="variant"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="State" margin="normal" />}
      /> }
                </GridItem>
              </GridContainer>

              
             

            
               


{/* <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            Product Type
          </InputLabel>
          <Select
            // MenuProps={{
            //   className: classes.selectMenu
            // }}
            // classes={{
            //   select: classes.select
            // }}
            // value={simpleSelect}
            // onChange={handleSimple}
            // inputProps={{
            //   name: "simpleSelect",
            //   id: "simple-select"
            // }}
          >
            
            <MenuItem
              // classes={{
              //   root: classes.selectMenuItem,
              //   selected: classes.selectMenuItemSelected
              // }}
              // value="2"
            >
              Finished
            </MenuItem>
            <MenuItem
              // classes={{
              //   root: classes.selectMenuItem,
              //   selected: classes.selectMenuItemSelected
              // }}
              // value="3"
            >
             Consumed
            </MenuItem>
            
          </Select>
        </FormControl>
                   
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Quantity On Hand *"
                    id="quantity"

                    success={numberState === "success"}
                    error={numberState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyNumber(event.target.value)) {
                            setnumberState("success");
                          } else {
                            setnumberState("error");
                          }
                          setnumber(event.target.value);
                        },
                        type: "number",
                        endAdornment:
                          numberState === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
</GridContainer>
 */}

<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Longitude *"
                    id="longitude"

                    success={requiredNameLongitude === "success"}
                    error={requiredNameLongitude === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyNumber(event.target.value)) {
                            setrequiredNameLongitude("success");
                          } else {
                            setrequiredNameLongitude("error");
                          }
                          setLongitude(event.target.value);
                        },
                        type: "number",
                        endAdornment:
                        requiredNameLongitude === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Latitude *"
                    id="latitude"

                    success={requiredNameLatitude === "success"}
                    error={requiredNameLatitude === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyNumber(event.target.value)) {
                            setrequiredNameLatitude("success");
                          } else {
                            setrequiredNameLatitude("error");
                          }
                          setLatitude(event.target.value);
                        },
                        type: "number",
                        endAdornment:
                        requiredNameLatitude === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
</GridContainer>

             
        

              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Contact Person Name *"
                    id="contactname"

                    success={requiredContactName === "success"}
                    error={requiredContactName=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value,1)) {
                            setrequiredContactName("success");
                          } else {
                            setrequiredContactName("error");
                          }
                          setContactName(event.target.value);
                        },
                        type: "text",
                        endAdornment:
                          requiredContactName=== "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Contact Person Number *"
                    id="contactno"

                    success={requiredContactNumber === "success"}
                    error={requiredContactNumber=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyNumber(event.target.value)) {
                            setrequiredContactNumber("success");
                          } else {
                            setrequiredContactNumber("error");
                          }
                          setCantactNumber(event.target.value);
                        },
                        type: "number",
                        endAdornment:
                        requiredContactNumber  === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Contact Person Email *"
                    id="contactemail"

                    success={typeEmailState === "success"}
                    error={typeEmailState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyEmail(event.target.value)) {
                            settypeEmailState("success");
                          } else {
                            settypeEmailState("error");
                          }
                          settypeEmail(event.target.value);
                        },
                        type: "email",
                        endAdornment:
                        typeEmailState=== "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number1 *"
                    id="phoneno1"

                    success={requiredNamePhone1 === "success"}
                    error={requiredNamePhone1 === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyNumber(event.target.value)) {
                            setrequiredNamePhone1("success");
                          } else {
                            setrequiredNamePhone1("error");
                          }
                          setPhoneNum1(event.target.value);
                        },
                        type: "number",
                        endAdornment:
                          requiredNamePhone1 === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number2"
                    id="phoneno2"

                    success={requiredNamePhone2=== "success"}
                    error={requiredNamePhone2 === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setrequiredNamePhone2("success");
                        } else {
                          setrequiredNamePhone2("error");
                        }
                        setPhoneNum2(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredNamePhone2=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number3"
                    id="phneno3"

                    id="address1"

                    success={requiredNamePhone3=== "success"}
                    error={requiredNamePhone3=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setrequiredNamePhone3("success");
                        } else {
                          setrequiredNamePhone3("error");
                        }
                        setPhoneNum3(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredNamePhone3 === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

       
<br/>
<br/>
 <div className={classes.buttonGroup}>
                <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
                  <b>RESET</b>
                </Button>

                    <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
                  <b>SUBMIT</b>
                </Button>
              
              </div>

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
     
    </div>
  );
}
export default Outlet;



