
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';


import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";
import Moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Sidebar from "components/Sidebar/Sidebar.js";
import dateFormat from 'dateformat';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];




const LiensInitialPopulateFtl = ({user,tmGetLiensInitialPopulateFtlRequest,liensInitialPopulateFtl,date1,userId}) => {

  const history = useHistory();

  const location = useLocation();

const [dataStatus, setDataStatus] = React.useState(false);
const [status, setStatus] = React.useState("");

const [logo, setLogo] = React.useState(require("assets/img/tenanttracker_11803_logo_500.png"));
const [megaLogo, setMegaLogo] = React.useState(require("assets/img/mega_11803_logo_notag_500.jpg"));

const month=dateFormat(date1, 'mmm').toLowerCase();
const year=dateFormat(date1, 'yyyy');

React.useEffect(() => {
   
  if(!dataStatus){
    getLiensInitialPopulateFtlList(userId,month,year);
 
  console.log("liensInitialPopulateFtl"+liensInitialPopulateFtl)
  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus]);



const getLiensInitialPopulateFtlList=async(userId,month,year)=>{
  await tmGetLiensInitialPopulateFtlRequest(userId,month,year)
 
  
    }
 
  const classes = useStyles(); 

    
  if(dataStatus){
    return (
    <div>
      <form>
      <GridContainer>

<GridItem xs={12} sm={12} md={9} style={{marginTop:"17px"}} >
     
        <img  src={logo} alt="Card-img" height="50" width="200"  />

 
    </GridItem>

    <GridItem xs={12} sm={12} md={3} align="right">

             <h5 style={{fontSize:'18px',color: "#000000"}} >{dateFormat(date1, 'mmmm d,yyyy')}
              <div style={{fontSize:'18px',color: "#000000"}} >{liensInitialPopulateFtl.organizationName}</div></h5>

</GridItem>

              
{liensInitialPopulateFtl.propertyLiensDTOList.map(liens  => (   <GridItem xs={12} sm={12} md={12} style={{backgroundColor:"#E8E8E8"}}>
              <h5 style={{fontSize:'22px',fontWeight:"bold",color: "#000000"}} >The following tenants have active liens in place in the past 7 years.</h5>
              <h5 style={{fontSize:'21px',fontWeight:"bold",color: "#000000"}} >Property&nbsp;Name&nbsp;&nbsp;&nbsp;:&nbsp;{liens.propertyName}</h5>
              <h5 style={{fontSize:'19px',fontWeight:"bold",color: "#000000"}} >Company &nbsp;Name&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.companyName}</h5>
             
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Filed Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.filedDate}</h5>
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Filing type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.filingType}</h5>
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Action Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.actionType}</h5>
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;$&nbsp;{liens.amount}</h5>
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Filing Number&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.filingNumber}</h5>
              <h5 style={{fontSize:'17px',fontWeight:"bold",color: "#000000"}} >Jurisdiction&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liens.jurisdiction}</h5>
             
              </GridItem>))}

    <GridItem xs={12} sm={12} md={7} style={{marginTop:"17px"}} >
     
     <img  src={megaLogo} alt="Card-img" height="50" width="200" />
 </GridItem>

 <GridItem xs={12} sm={12} md={5} align="right" style={{marginTop:"20px"}}>

 <h5 style={{fontSize:'14px',color: "#000000"}} >Please go to  <a href = {liensInitialPopulateFtl.appUrl} target = "_blank">otso.megalytics.net</a>&nbsp;to see the details.</h5>

</GridItem>

       
      </GridContainer>
      </form>
    </div>
  );

}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}
LiensInitialPopulateFtl.propTypes = {
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmGetLiensInitialPopulateFtlRequest: PropTypes.func.isRequired,

  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  liensInitialPopulateFtl: state.tenantmonitoring.liensInitialPopulateFtl,
  loading: state.tenantmonitoring.loading,
  // tenantId: state.tenantmonitoring.tenantId,
  // uccScore: state.tenantmonitoring.uccScore,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiensInitialPopulateFtl);


