import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBase from 'components/MenuBase/SideMenuBase.js';
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import ReactPaginate from 'react-paginate';
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import Paginations from "components/Pagination/Pagination.js";

import Pagination from '@material-ui/lab/Pagination';
import { getFundDashboardCount } from "store/sagas/tenantmonitoring";

import { useLocation } from "react-router-dom";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
  //const useStyles = makeStyles(styles);


  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },

    content: {
      marginTop: "70px",
     // padding: "30px 15px",
      minHeight: "calc(100vh - 123px)"
    }
  }));


const TMHeatMap = ({user,loading,tmFundByOrgIdRequest,tmFundDashboardCountRequest,fundDasboardCount,
    fundsByOrg,tmDashboardRequest,tmDashboardFund,organizationId }) =>{

const history = useHistory();
const location = useLocation();
const[dynamicHeight,setDynamicHeight]= React.useState("");

const[showHeatMapFundRisk,setShowHeatMapFundRisk]= React.useState(false);

const [selectedFundId, setSelectedFundId] = React.useState("");
const [selectedFundName, setSelectedFundName] = React.useState("");
const [showHeatMap, setShowHeatMap] = React.useState(true);

const [dataStatus, setDataStatus] = React.useState(false);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
//const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

const [pageSize, setPageSize] = React.useState(10);
const [pageNum, setPageNum] = React.useState(0);
const [totalCount, setTotalCount] = React.useState("");
const [page, setPage] = React.useState(1);
const [userId, setUserId] = React.useState(user.id);

const classes = useStyles(); 





  const enableFund = (value) => {
    setSelectedFundId(value.fundId); 
    setSelectedFundName(value.fundName);
    setShowHeatMapFundRisk(true);
    setShowHeatMap(false);
  }

    React.useEffect(() => {
    
        setPageNum(50/25);
 
      //getTmFundByOrg(organizationId,pageSize,pageNum);
    
      
    if(!dataStatus){
       getTmFundByOrg(user.organizationId,user.id,pageSize,pageNum);
   
      getTmFundDashboardCount(user.organizationId,user.id);

        //getTmDashboard(organizationId,pageSize,pageNum);
        getTmDashboard(user.organizationId,user.id,pageSize,pageNum);
        }
        var heightRation = 125*fundsByOrg.length;
        setDynamicHeight(heightRation+'px');
        setTimeout(() => {
        setDataStatus(true);
        }, 2000);
                

      
      },[dataStatus]);

      const getTmFundByOrg = async (organisationId,userId,pageSize,pageNum) => {
    
        await tmFundByOrgIdRequest(organisationId,userId,pageSize,pageNum);
        

    
        };
        const getTmFundDashboardCount = async (organisationId,userId) => {
    
          await tmFundDashboardCountRequest(organisationId,userId);
        
     
          };

     const getTmDashboard = async (organizationId,userId,pageSize,pageNum) => {
        await tmDashboardRequest(organizationId,userId,pageSize,pageNum);
       
        
       
        };
        const  handlePageChange =(event, value) => {
         getTmDashboard(user.organizationId,userId,pageSize,value-1); 
         getTmFundByOrg(user.organizationId,userId,pageSize,value-1); 
            
            };

       
    
    const goback=()=>{
      //  history.push("/admin/dashboard");
      history.back();
    } 
 
   // getTmDashboard(organizationId);




  const chartOps = {

     series:tmDashboardFund,
   //  series:fundRisk,

      options: {
       
        yaxis: {
            reversed: false,
            labels: {
                show: false,
                style: {
                          fontSize: '16px',
                          fontWeight: 700,
                        }
                      }
                    },
                    xaxis: {
                        position: 'top',
                        labels: {
                          show: true,
                         
                            style: {
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      colors: ['black','black','black','black','black','black','black','black','black','black','black','black',],
                                      
                                    }
                                  }
                                },
        chart: {
          height: 250,
          type: 'heatmap',
          legend: {
            show: false,
          },
          toolbar:{
            show:false,
          },
          yaxis: {
            labels: {
             
                style: {
                          fontSize: '70px',
                          fontFamily: "Nunito Sans",
                          fontWeight: 400,
                          fontColor:'#00628B',
                        }
                      },
                events: {
                        dataPointSelection: (event, chartContext, config) => {
                      
                          
                        },
                        click: function(event, chartContext, config) {
                         
                                             
                          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                        }
                      } 
                    },

          xaxis: {
            labels: {
                show:true,
                style: {
                    colors: [],
                    fontSize: 'px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
              /**
              * Allows users to apply a custom formatter function to xaxis labels.
              *
              * @param { String } value - The default value generated
              * @param { Number } timestamp - In a datetime series, this is the raw timestamp 
              * @param { object } contains dateFormatter for datetime x-axis
              */
              formatter: function(value, timestamp, opts) {
                return opts.dateFormatter(new Date(timestamp)).format("dd MMM")
              }
            }
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
          
              console.log(config.w.config.labels[config.dataPointIndex]);
            },
            click: function(event, chartContext, config) {
             
             var el = event.target;
             var seriesIndex = parseInt(el.getAttribute("i"));
            
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            }
          }
        },
        plotOptions: {
          heatmap: {

            xaxis:{
                position: 'top',  
            },

            yaxis: {


                reversed: false,
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: [],
                        fontSize: '50px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                },
                
              }
            },
            
           
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 401,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 301,
                  to: 400,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 201,
                  to: 300,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 101,
                  to: 200,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 0,
                  to: 100,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  },

             
              ]
            }
          }
        },
        legend: {
            showForNullSeries: true,
            show : false,
            // formatter: function(seriesName, opts) {
            //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
            // }
          },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
              if(val == 0){
            //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
             return "NA";      
        }else
              return val;
          }
        },
        stroke: {
          width: 3
        },
        title: {
          text: ''
        },
      },
    
    
    };
 // }



 if(dataStatus){
    return (

     
      <Card>     
<br></br>
<GridItem >
<Button color="info" size="sm" round className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}  onClick={() =>history.push({ 
                           pathname: '/admin/dashboard',
                        
                        
                         }) }>
                      
       Grid View 
        </Button>
</GridItem>
<div id="chart">

{showHeatMap && 
<GridContainer >
{/* <GridItem xs={12} sm={12} md={12}>
      

      <Breadcrumbs aria-label="breadcrumb">
      <Link   style={{color:megaColor}} href="/"  onClick={() => history.push("/admin/tenants")} className={classes.link}>
        <HomeIcon fontSize="inherit" className={classes.icon} />
        <b>Dashboard</b>
      </Link>
      
     
    </Breadcrumbs>

 </GridItem>  */}


{/* <div><Button color="transparent" onClick={goback}>Go Back</Button></div>
       */}
{/* <GridItem xs={12} sm={12} md={12}>
       <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/" onClick={handleClick} className={classes.link}>
        <HomeIcon fontSize="inherit" className={classes.icon} />
        Dashboard
      </Link>
      <Link
        color="inherit"
        to={{pathname:'/admin/tenants/'}}
        onClick={handleClick}
        className={classes.link}
      >
        <WhatshotIcon fontSize="inherit" className={classes.icon} />
       Fund Risk
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon fontSize="inherit" className={classes.icon} />
       Tenants
      </Typography>
    </Breadcrumbs>

 </GridItem> */}


         <GridItem xs={12} sm={5} md={5}>
        { showHeatMap && <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Overall Fund  Risk Score By Month</b></h3>}
         </GridItem>
      

      
       
         {/* <GridItem xs={12} sm={7} md={7} style={{textAlign:'right'}}> */}
         {showHeatMap &&  <GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight: "105px",paddingTop:'10px',textAlign:"right"}} >

    {/* </GridItem> {showHeatMap && <GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right"> */}
              {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
             
             <br></br><br/>
                 
                <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>                 
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>            
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>              
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>             
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
              {/* <br></br><br/> */}
             
  </GridItem>
  
     }
   <GridItem xs={12} sm={12} md={3}></GridItem>
   <GridItem xs={12} sm={12} md={9} style={{paddingRight:"100px",marginTop:"15px"}}>
         { showHeatMap &&  <h4 style={{ color: megaColor }}> <b>The arrow indicates current month’s performance of the funds vs 1 year ago</b></h4>}
         <h4 style={{ color: megaColor,paddingRight:"4px",paddingTop:"10px" }}> <b style={{paddingLeft:"10px"}}><TrendingFlatIcon /> : Remains the same.</b> <b style={{paddingLeft:"30px"}}><ArrowUpwardIcon /> : Improvement.</b><b  style={{paddingLeft:"30px"}}><ArrowDownwardIcon/> : Declined.</b></h4>
        
                  </GridItem>
       
    
        <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign:'right'}}>
        {showHeatMap &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
      <h5> <b><div style={{paddingTop:'5px'}}>FUND NAME</div></b></h5>
      <SideMenuBase style ={{height:'180px'}} items={ fundsByOrg.fundList } parentCallBack={enableFund}/>
       </div>}
     </GridItem>

 
  <GridItem xs={12} sm={9} md={9} style={{marginLeft:"-37px"}}>
       
  {showHeatMap &&    
    
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={270} />

  }

 <div className={classes.root}>
{showHeatMap && fundsByOrg.totalPages>1&& <Pagination variant="outlined" color="primary"
shape="rounded" count={fundsByOrg.totalPages} page={1} onChange={handlePageChange} size="large" />}

 
 </div> 

<br/>
<br/>
<br/>
  
</GridItem>

    



    

 </GridContainer>


}

 <div>
 {showHeatMapFundRisk && <TMHeatMapPropertyFM fundName={selectedFundName} fundId={selectedFundId} backNavigation={false}/>}
 </div>
</div>
</Card>
    )
}else{

return(
  <div align="center">
  <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={2000} />
  </div>
)

}
}


TMHeatMap.propTypes = {
    tmNotificationList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        contifyId: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmDashboardRequest: PropTypes.func.isRequired,
    tmFundByOrgIdRequest: PropTypes.func.isRequired,
    tmFundDashboardCountRequest: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tmDashboardFund: state.tenantmonitoring.tmDashboardFund,
    fundsByOrg: state.tenantmonitoring.fundsByOrg,
    fundDasboardCount: state.tenantmonitoring.fundDasboardCount,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TMHeatMap);
