import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBase from 'components/MenuBase/SideMenuBase.js';
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import ReactPaginate from 'react-paginate';
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";

import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
//import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import Paginations from "components/Pagination/Pagination.js";

import Pagination from '@material-ui/lab/Pagination';
import { getFundDashboardCount } from "store/sagas/tenantmonitoring";

import { useLocation } from "react-router-dom";
 

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "variables/charts.js";
import FundPropertyGridView from "./FundPropertyGridView";
  //const useStyles = makeStyles(styles);
  import fundimage2 from "assets/img/fundimage2.png";
  import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from 'moment';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CloseIcon from '@material-ui/icons/Close';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Tooltip from '@material-ui/core/Tooltip';

import MonthlyReport from "views/PDF/ReportLatest.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    width:"95%",
   height:"95%",
  //  maxWidth: "500px",
  // paddingBottom:"150px",
    //background: "hsla(0, 0, 0, 0.7)"
     boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)"
   
  },

  
  
  title: {
    fontSize: 25,
    color:"#ffffff",
    textAlign:"center",
    paddingTop:"68px"
  },

 
  pos: {
  //textAlign:"right",
  backgroundColor:"#64bf6a",
  color:"#ffffff",
  fontSize:"18px",
  // marginLeft:"20px",
  // marginTop:"10px",
  paddingRight:"20px",
  paddingLeft:"20px",
   paddingTop:"10px",
 paddingBottom:"10px",
  },


cardHorizontal: {
  display: "flex",
  flex: "1 1 auto"
},
horizontal: {
  display: "flex",
  //margin: "0 -10px",
  alignItem: "center",
  // paddingLeft:"10px",
  paddingRight:"40px",
  paddingBottom:"40px",

paddingLeft:"40px",
 //justifyContent: "space-between",
 //flexWrap: "wrap",
//rowGap: "10px",
columnGap: "0.3em"

},
cardTitles: {
  // paddingLeft:"10px",
  fontSize:"18px",


},
chart:{
   marginLeft:"70px",
  // marginTop:"0px"
},
cardTitle: {

  // position: "absolute",
 marginTop: "15px",
 left: "30px",
 // height:"299px",
 width: "100%",
 background: "#fff",
 borderRadius: "3px",
 overflow: "hidden",
 boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)",
 transition: "all 0.2s ease-out",
 top: "-4px"
 },
// hover: {
//   transition: "all 0.2s ease-out",
//   boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
//   top: "-4px",
//   border: "1px solid #cccccc",
//   backgroundColor: "white"
// }
text:{
  display:"inline",
  // float:"center",
  },
  arrow:{
  display:"inline",
  // float:"center"
  },

expand: {
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
},
expandOpen: {
  transform: 'rotate(180deg)',
},
customWidth: {
  maxWidth: 200,
  fontSize: "15px",
  backgroundColor: megaColor,
}

}));

  

  

const GridViewFundList = ({user,loading,tmFundByOrgIdRequest,tmFundDashboardCountRequest,fundDasboardCount,
    fundsByOrg,tmDashboardRequest,tmDashboardFund,organizationId,tmFundDashboardGridViewRequest,fundDashboarGridView,tmFundApexChartDataRequest,fundApexChartData}) =>{

const history = useHistory();
const location = useLocation();
const[dynamicHeight,setDynamicHeight]= React.useState("");

const[showFundPropertyGridView,setShowFundPropertyGridView]= React.useState(false);

const [selectedFundId, setSelectedFundId] = React.useState("");
const [selectedFundName, setSelectedFundName] = React.useState("");
const [showFundGridView, setShowFundGridView] = React.useState(true);

const [dataStatus, setDataStatus] = React.useState(false);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
//const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

const [pageSize, setPageSize] = React.useState(10);
const [pageNum, setPageNum] = React.useState(0);
const [totalCount, setTotalCount] = React.useState(50);
const [page, setPage] = React.useState(1);
const [userId, setUserId] = React.useState(user.id);
const [month,setMonth] = React.useState("");
const [year,setYear] = React.useState("");
const [fundId,setFundId] = React.useState("");

const[chartOpen,setChartOpen] = React.useState("");
const[sortedArray,setSortedArray] = React.useState([]);

const classes = useStyles(); 

var now = moment();
   var monthArray=[];
   var yearArray=[];
  
   var currMonthName  =  moment().subtract(1, "month").format('MMM').toUpperCase();
   var currYear  = moment().format('YYYY');
   var prev1MonthName  = moment().subtract(2, "month").format('MMM').toUpperCase();
   var prev1Year  = moment().subtract(1, "month").format('YYYY').toLowerCase();
   var prev2MonthName  = moment().subtract(3, "month").format('MMM').toUpperCase();
   var prev2Year  = moment().subtract(2, "month").format('YYYY').toLowerCase();
   var prev3MonthName  = moment().subtract(4, "month").format('MMM').toUpperCase();;
   var prev3Year  = moment().subtract(3, "month").format('YYYY').toLowerCase();
   var prev4MonthName  = moment().subtract(5, "month").format('MMM').toUpperCase();;
   var prev4Year  = moment().subtract(4, "month").format('YYYY').toLowerCase();
   var prev5MonthName  = moment().subtract(6, "month").format('MMM').toUpperCase();;
   var prev5Year  = moment().subtract(5, "month").format('YYYY').toLowerCase();

   var prev6MonthName  = moment().subtract(7, "month").format('MMM').toUpperCase();;
   var prev6Year  = moment().subtract(6, "month").format('YYYY').toLowerCase();
   var prev7MonthName  = moment().subtract(8, "month").format('MMM').toUpperCase();;
   var prev7Year  = moment().subtract(7, "month").format('YYYY').toLowerCase();
   var prev8MonthName  = moment().subtract(9, "month").format('MMM').toUpperCase();;
   var prev8Year  = moment().subtract(8, "month").format('YYYY').toLowerCase();
   var prev9MonthName  = moment().subtract(10, "month").format('MMM').toUpperCase();;
   var prev9Year  = moment().subtract(9, "month").format('YYYY').toLowerCase();
   var prev10MonthName  = moment().subtract(11, "month").format('MMM').toUpperCase();;
   var prev10Year  = moment().subtract(10, "month").format('YYYY').toLowerCase();
   var prev11MonthName  = moment().subtract(12, "month").format('MMM').toUpperCase();;
   var prev11Year  = moment().subtract(11, "month").format('YYYY').toLowerCase();
   

   const month1=[prev11MonthName+"-20",prev10MonthName+"-20",prev9MonthName+"-20",prev8MonthName+"-20",prev7MonthName+"-21",prev6MonthName+"-21",prev5MonthName+"-21",prev4MonthName+"-21",prev3MonthName+"-21",prev2MonthName+"-21",prev1MonthName+"-21",currMonthName+"-21"];
   
   const year1=[prev5Year,prev4Year,prev3Year,prev2Year,prev1Year,currYear];




  const enableFund = (value) => {
    setSelectedFundId(value.fundId); 
    setSelectedFundName(value.fundName);
    
    setShowFundPropertyGridView(true);
    setShowFundGridView(false);
  
  }
  
    React.useEffect(() => {
    
        setPageNum(50/25);
     
    
      
    if(!dataStatus){
    
      getTmFundDashboardGridView(user.organizationId,user.id);
      // getTmFundApexChartData(fundId,month,year,user.organizationId,user.id);
    
        }
        
        setTimeout(() => {
        setDataStatus(true);
        }, 2000);
                

      
      },[dataStatus]);

     
        const getTmFundDashboardGridView = async (organisationId,userId,sortProperty) => {
    
          await tmFundDashboardGridViewRequest(organisationId,userId);
          // setSortedArray(fundDashboarGridView.sort());
         
          // alert("page"+JSON.stringify(fundDashboarGridView));
      
          };
    

     const getTmFundApexChartData = async (fundId,month,year,organizationId,userId) => {
   
        await tmFundApexChartDataRequest(fundId,month,year,organizationId,userId);
        // alert("page"+JSON.stringify(fundApexChartData));
     

        };
        const  handlePageChange =(event, value) => {
        // getTmDashboard(user.organizationId,userId,pageSize,value-1); 
         getTmFundDashboardGridView(user.organizationId,userId); 
        //  getTmFundApexChartData(fundId,month,year,user.organizationId,userId);
            
            };
    const goback=()=>{
      //  history.push("/admin/dashboard");
      history.back();
    } 

    const [openTrend, setOpenTrend] = React.useState(false);

    const handleClickOpenTrend = (value) => {
      
      // setDataStatus(false);
     
      getTmFundApexChartData(value.fundId,value.month,value.year,user.organizationId,value.userId);
     
      // alert("page1"+JSON.stringify(fundApexChartData));
      // setOrganisationId(user.organisationId);
     
      setFundId(value.fundId); 
      setSelectedFundName(value.fundName);
      setMonth(value.month);
      setYear(value.year);
      // setOrganisationId(value.organisationId);
   
 setTimeout(() => {
  setDataStatus(true);
  setOpenTrend(true);
}, 2000);


    };
  
    const handleCloseTrend = () => {
      setOpenTrend(false);
     
    };
 
   // getTmDashboard(organizationId);

   const lineChart = {
    // series:fundApexChartData.series,
    // series : [ {
    //   data : [ "387", "390", "390", "388", "388", "338", "325", "328", "323", "322", "325", "325" ]
    //   // data : [ "389", "387", "382", "385", "382", "330", "324", "318", "312", "314", "314", "316" ],
    // } ],

  options: {
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          fontSize: 15,
          fontColor: 'lightgrey'
        }
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          fontSize: 15,
          fontColor: 'lightgrey',
          maxTicksLimit: 5,
          padding: 25,
        }
      }]
    },
    chart: {
      height: 400,
      width: 800,
      type: 'line',
      
      dropShadow: {
        enabled: true,
        color: '#fff',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      
      zoom: {
        enabled: true
      },
      toolbar: {
        show: false
      }
      
    },
    
      
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 5,
      dashArray: 0,
    },
    markers: {
      size: 6,
      color: '#00008b'
  },


  
 
  grid: {
    // show: true,      // you can either change hear to disable all grids
    // type: 'line',
    row: {
        colors: ['#e5e5e5', 'transparent'],
        opacity: 0.5
    }, 
    // column: {
    //     colors: ['#f8f8f8', 'transparent'],
    // }, 

    xAxes: [{
      gridLines: {
        display: false,
      },
      lines: {
        show: true
      },
    
    }],
    // xaxis: {
    //   lines: {
    //     show: true
    //   }
    // }
    // grid: {
    //   show: true,      // you can either change hear to disable all grids
    //   type: 'line',
      
    //   xaxis: {
    //     lines: {
    //       show: true //or just here to disable only x axis grids
    //      }
    //    } 
      }, 
    xaxis: {
      
      categories:  month1,
      labels: {
        show: true,
        style: {
          fontSize: "13px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold"
        },
            },

            axisBorder: {
              show: true,
              color: '#78909C',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: 0
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#000000',
              height: 4,
              offsetX: 0,
              offsetY: 0
          },
            
            tickColor:"#ffffff"    
    },
    yaxis: {

     
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: "13px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold"
        },
            },
            title: {
              text: 'Tracker Score',
              style:{
                fontSize:"19px",
                fontWeight:"bold",
                fontColor:"black",
               
              },
            },
    },
    
  },
  
  
  };
  const handleClickOpenPdf = (value) => {

    history.push({ 
      pathname: '/admin/monthly-fund-report-download',
      state: {fundId:value.fundId,organisationId:user.organizationId,userId:value.userId,fundName:value.fundName,imageUrl:value.imageUrl}
     });

  };

  function handleClickHome(event) {
    // event.preventDefault();
    history.push({ 
     pathname: '/admin/pdf-download',
     state: {organisationId:user.organizationId}

    });
    
   }
 if(dataStatus){
    return (
      <div>
     
{showFundGridView&& <Card>
<br></br>
<GridContainer>
<GridItem xs={12} sm={12} md={6} style={{textAlign:"left"}}>


      
     <Button color="info" size="sm" round className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}  onClick={() =>history.push({ 
                           pathname: '/admin/tm-heatmap',
                        
                        
                         }) }>
                      
       List View  
        </Button>
        
</GridItem>

<GridItem xs={12} sm={12} md={6} style={{textAlign:"right",paddingRight:"100px"}}>
{/* <a href={MonthlyReport} target="_blank" 
                        rel="noreferrer" style={{fontSize:16}}>
               Monthly Report</a> */}
    <Link style={{cursor:'pointer'}} onClick={handleClickHome} >Monthly Report </Link>
      </GridItem>
</GridContainer>
<GridContainer >
{showFundGridView&&fundDashboarGridView.map(fund => (
     <GridItem xs={12} sm={12} md={4} className={classes.horizontal} >
      
        <Card  chart className={classes.root} variant="outlined" 
          > 
          


          <CardBody>
          {/* <img className={classes.cardTitle}   src={require("/home/adminuser/projects/tenantmonitoring/fund-image-uploads/"+fund.imageUrl)} alt="Card-img" height="160"  /> */}
          <img className={classes.cardTitle}   src={require("/home/ubuntu/tm-images/funds/"+fund.imageUrl)} alt="Card-img" height="160"  />

<br></br>
      
<h3 align="center" style={{fontSize:"22px",color:megaColor,cursor:'pointer'}}  onClick={() => enableFund({fundId:fund.fundId,fundName:fund.fundName})}><b>{fund.fundName.toUpperCase()}</b></h3>
            {/* <h4 className={classes.cardTitles}><b>{fund.score}</b></h4> */}


            <div align="center">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h4 className={classes.text}><b style={{color: fund.colorCode,fontSize:"28px"}}>{fund.score}</b>
         </h4>
           

         <h4 className={classes.arrow}> <b>{fund.currentStatus==='up' ?          <Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
<ArrowUpwardIcon  style={{color:"green",fontSize:"25px"}} onClick={() => handleClickOpenTrend({fundName:fund.fundName,fundId:fund.fundId,month:fund.month,year:fund.year,organizationId:user.organizationId,userId:fund.userId})}
/></Tooltip> : fund.currentStatus==="down" ?          <Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
<ArrowDownwardIcon style={{color:"red",fontSize:"22px"}} onClick={() => handleClickOpenTrend({fundName:fund.fundName,fundId:fund.fundId,month:fund.month,year:fund.year,organizationId:user.organizationId,userId:fund.userId})}
 /></Tooltip>:<Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
 <TrendingFlatIcon style={{color:"green",fontSize:"22px"}}/></Tooltip>}</b>
           </h4>
           </div>
            <h5 align="center" className={classes.cardTitles}><b >Rent Roll Score </b></h5>
            <div align="center">
                    <Link onClick={() => handleClickOpenPdf({fundName:fund.fundName,fundId:fund.fundId,organisationId:user.organizationId,organizationId:user.organizationId,userId:fund.userId,imageUrl:fund.imageUrl})} style={{cursor: "pointer"}}>View Report</Link>
       </div>
          </CardBody>

        </Card>
       
        </GridItem>
        ))}

       

        </GridContainer>

        {dataStatus&&  <div>
          <Dialog 
  maxWidth={'md'} open={openTrend} onClose={handleCloseTrend} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
         <GridItem align="right" paddingTop="10px">
          <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseTrend}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent>

          <h3 align="center" style={{fontSize:"26px", fontWeight:"bold"}}>{selectedFundName}</h3>
         <div id="chart" className={classes.chart} >
          {/* {showFundGridView&& fundApexChartData.map(fundChart => (  */}
         
           <Chart
        
    options={lineChart.options}
    series={fundApexChartData.series}
    type="line"
    width="800"
    height="400"
    // lineThickness= "5"
    // marginLeft="300"


  />
  {/* ))}  */}
  </div>
          </DialogContent>
        
            </Dialog>
            </div>  }

 
         </Card>}
      {showFundPropertyGridView && <FundPropertyGridView fundId={selectedFundId} fundName={selectedFundName}/>}
      </div>
    );
     



    
}else{

return(
  <div align="center">
  <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={2000} />
  </div>
)

}
}


GridViewFundList.propTypes = {
    tmNotificationList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        contifyId: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmFundApexChartDataRequest: PropTypes.func.isRequired,
   // tmFundByOrgIdRequest: PropTypes.func.isRequired,
    tmFundDashboardCountRequest: PropTypes.func.isRequired,
    tmFundDashboardGridViewRequest: PropTypes.func.isRequired,

    
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
   
    fundsByOrg: state.tenantmonitoring.fundsByOrg,
    fundDasboardCount: state.tenantmonitoring.fundDasboardCount,
    fundDashboarGridView: state.tenantmonitoring.fundDashboarGridView,
    fundApexChartData: state.tenantmonitoring.fundApexChartData,


    
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GridViewFundList);
