
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Assignment from "@material-ui/icons/Assignment";
import AddAlert from "@material-ui/icons/AddAlert";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';
import BTTable from "./BTTable";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check"
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
import BaseLineReport from "./BaseLineReport";


  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];










// const Liensreport = ({tmActiveLiensJudgmentDetailsRequest,liensJudgmentDetailsDTO,tmActiveLiensDetailsRequest,liensList}) => {
  const BankruptcyMenu = ({tmGetBankrutcyDetailsOfUserRequest,bankruptcyDetailsOfUser,user

    }) => {

  const [input, setInput] = React.useState('');
 // const [dataList, setDataList] = React.useState(tenantFailureStatusLogListByOrg);

  
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");
const [messageobj, setMessageobj] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const[failureCount,setFailureCount] = React.useState("");

const[id,setId] = React.useState("");
const [tenantId,setTenantId] = React.useState("");
const[type,setType] = React.useState("");
const[status,setStatus] = React.useState("");
const [showBaseLineReport, setShowBaseLineReport] = React.useState(false);
const [dataStatus, setDataStatus] = React.useState(false);
const [tenantName,setTenantName] = React.useState("");
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  const[fileDate,setFileDate] = React.useState("");
 const[statusDate,setStatusDate] = React.useState("");
 const[chapter,setChapter] = React.useState("");
 const[caseNumber,setCaseNumber] = React.useState("");
 const[dispositionCode,setDispositionCode] = React.useState("");
 const[socialSecurityNumber,setSocialSecurityNumber] = React.useState("");
 const[business,setBusiness] = React.useState("");
 const[debtorsCity,setDebtorsCity] = React.useState("");
 const[debtorsState,setDebtorsState] = React.useState("");
 const[attorneyName,setAttorneyName] = React.useState("");
 const[attorneyAddress,setAttorneyAddress] = React.useState("");
 const[attorneyPhone,setAttorneyPhone] = React.useState("");
 const[lawFirm,setLawFirm] = React.useState("");
 const[courtDistrict,setCourtDistrict] = React.useState("");
 const[courtAddress1,setCourtAddress1] = React.useState("");
 const[courtPhone,setCourtPhone] = React.useState("");
 const[judgesInitials,setJudgesInitials] = React.useState("");
 

 const [userId,setUserId] = React.useState(user.id);


 
React.useEffect(() => {
   
  if(!dataStatus){
    getBankruptcyDaetailsOfUser(user.id);

//   getActiveLiensList(tenantId);
//   getActiveLiensJudgmentScore(tenantId);
//   getActiveJudgementList(tenantId);

  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus]);



  const getBankruptcyDaetailsOfUser = async (userId)=>{
  
await tmGetBankrutcyDetailsOfUserRequest(userId)
 //alert(bankoReportList);

  }



const getBaseLineReport=(tenantId,fileDate,statusDate,chapter,caseNumber,dispositionCode,socialSecurityNumber,
    business,debtorsCity,debtorsState,attorneyName,attorneyAddress,attorneyPhone,lawFirm,
    courtDistrict,courtAddress1,courtPhone,judgesInitials)=>{
    setShowBaseLineReport(true);

       setTenantId(tenantId);
       setFileDate(fileDate);
       setStatusDate(statusDate);
       setChapter(chapter);
       setCaseNumber(caseNumber);
       setDispositionCode(dispositionCode);
       setSocialSecurityNumber(socialSecurityNumber);
       setBusiness(business);
       setDebtorsCity(debtorsCity);
       setDebtorsState(debtorsState);
       setAttorneyName(attorneyName);
       setAttorneyAddress(attorneyAddress);
       setAttorneyPhone(attorneyPhone);
       setLawFirm(lawFirm);
       setCourtDistrict(courtDistrict);
       setCourtAddress1(courtAddress1);
       setCourtPhone(courtPhone);
       setJudgesInitials(judgesInitials);

   
      





}



  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
    
  };

  // const handleCloseLiensreport = () => {
  //   setShowLiensreport(false);
  // };

  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }

    // const handleClickOpenViewLog = () => {

    //   setShowViewLog(true);
    // };
  
    const handleCloseBaseLineReport = () => {
        setShowBaseLineReport(false);
    };



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
  
    <GridContainer>
   
  
   <GridItem xs={12}>
  
   <Card>
          <CardHeader style={{ background: megaColor,color:"white", fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4><b>
             Bankruptcy</b>
              </h4> 
            </CardHeader>
            <CardBody>

    

            <GridContainer className={classes.gridContainer}>
           
            {/* <GridContainer>
           
            <GridItem xs={12} sm={12} md={12}><h3 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px"}}>TenantName:{tenantName}</h3></GridItem>


<GridItem xs={2}>
      <Button  color="info" round ><EmailOutlinedIcon /> Send SMS</Button>
</GridItem>

</GridContainer> */}
     
            <GridItem>
               
               <Dialog fullWidth={true} 
maxWidth={'md'} open={showBaseLineReport} onClose={handleCloseBaseLineReport}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseBaseLineReport}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>

<h2 style={{ color: megaColor,fontSize:'50px',paddingLeft:'30px' }}>{tenantName}</h2>

<GridItem paddingLeft='100px'>
          {showBaseLineReport && <BaseLineReport  tenantId ={tenantId} fileDate={fileDate} statusDate={statusDate} 
          chapter={chapter} caseNumber={caseNumber} dispositionCode={dispositionCode} socialSecurityNumber={socialSecurityNumber} business={business}
          debtorsCity={debtorsCity} debtorsState={debtorsState} attorneyName={attorneyName} attorneyAddress={attorneyAddress}
          attorneyPhone={attorneyPhone} lawFirm={lawFirm}  courtDistrict={ courtDistrict} courtAddress1={courtAddress1}
          courtPhone={courtPhone} judgesInitials={judgesInitials}
       
        />}
        </GridItem>
       </Dialog>
               </GridItem> 
             
</GridContainer>


           
             
           

             

         <BTTable
           columns={[



            // {
            //     id: "checkbox",
            //     accessor: "",
            //     filterable: false,
            //     disableSortBy: true,
            //     width: 20,
            //     Cell: ( rowInfo ) => {
            //         return (
            //             <Checkbox
            //                 type="checkbox"
            //                 className="checkbox"
            //               // checked={this.state.selected[rowInfo.original.title.props.children] === true}
            //               //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
            //             />
            //         );
            //     },
            //     Header: title => {
            //         return (
            //             <Checkbox
            //                 type="checkbox"
            //                 className="checkbox"
            //                 // checked={this.state.selectAll === 1}
            //                 // ref={input => {
            //                 //     if (input) {
            //                 //         input.indeterminate = this.state.selectAll === 2;
            //                 //     }
            //                 // }}
            //                 // onChange={() => this.toggleSelectAll()}
            //             />
            //         );
            //     },
               
            // },
            {
              Header: "Company Name",
              accessor: "business",
              width:50
            },
     
            {
              Header: "File Date",
              accessor: data => {
                return Moment(data.fileDate)
                .locale('en')
                  .format(" MMM D, YYYY")
              }
            },
            {
              Header: "Status date",
              accessor: data => {
                return Moment(data.statusDate)
                .locale('en')
                  .format(" MMM D, YYYY")
              }
              },



            
                {
                  Header: "Chapter",
                  accessor: "chapter",
                  align:"center",
                  width:"20"
                
                },

              
                  {
                   
                 Header: "Actions",
               accessor: "actions",
  
           
        
    
                 sortable: false,
                 filterable: false,
                
                  Cell: ({ cell,row }) => (<Link to={{ state :{tenantId : row.original.tenantId,tenantName : row.original.tenantName, fileDate:row.original.fileDate} } } 
                    onClick={() => getBaseLineReport(row.original.tenantId,row.original.fileDate,row.original.statusDate,row.original.chapter,
                        row.original.caseNumber,row.original.dispositionCode,row.original.socialSecurityNumber,row.original.business,row.original.debtorsCity,row.original.debtorsState,row.original.attorneyName,row.original.attorneyAddress,
                        row.original.attorneyPhone,row.original.lawFirm,row.original.courtDistrict,row.original.courtAddress1,
                       row.original.courtPhone,row.original.judgesInitials,row.original.tenantName)}
                   >Baseline Report</Link>),
  
    
              
                 
                },

           ]}
           data={bankruptcyDetailsOfUser.map((prop, key) => {

            
             return {
               tenantId: prop.id,
               tenantName: prop.tenantName,
               business: prop.business,
               fileDate: prop.fileDate,
               statusDate: prop.statusDate,
               chapter:prop.chapter,
               caseNumber:prop.caseNumber,
               dispositionCode:prop.dispositionCode,
               socialSecurityNumber:prop.socialSecurityNumber,
               debtorsCity:prop.debtorsCity,
               debtorsState:prop.debtorsState,
               attorneyName:prop.attorneyName,
               attorneyAddress:prop.attorneyAddress,
               attorneyPhone:prop.attorneyPhone,
               lawFirm:prop.lawFirm,
               courtDistrict:prop.courtDistrict,
               courtAddress1:prop.courtAddress1,
               courtPhone:prop.courtPhone,
               judgesInitials:prop.judgesInitials

             
              //  actions:(
              //    <div>

              //     <Link to={{pathname:'/admin/view-log', state :{tenantId : prop.id, month:prop.month, year:prop.year} } }> 
              //         <Button
              //           size="sm" color="info"
              //       onClick={getFailureList}
              //      >
              //          View Log
              //        </Button>{" "}

                      
              //      </Link> 
                      
              //    </div>
              //  )
          
              
             };
           })}


     
           //  data={tenantUploadStatusList}

           getTdProps={(state, rowInfo, column, instance) => {
             // rowInfo contains a lot of information about the row (including index on both the
             // page and it's index within the data set), the rowInfo.original objet contains 
             // values in the cells of the row clicked.
             return {
               // You can define an onClick function here that will apply to all rows
               onClick: (e, handleOriginal) => {
     
                  const rowData = rowInfo.original
                  // You can interact with the row data here, to use it however you want
                  this.setState({month: rowData.month, year: rowData.year})
               }
         }}}
         
         />
{/* <div>

<Dialog
           classes={{
             root: classes.center,
             paper: classes.modal
           }}
           open={showBaseLineReport}
           transition={Transition}
           keepMounted
           onClose={() => setShowBaseLineReport(false)}
           aria-labelledby="modal-slide-title"
           aria-describedby="modal-slide-description"
         >
           <DialogTitle
             id="classic-modal-slide-title"
             disableTypography
             className={classes.modalHeader}
           >
             <GridItem align="right">
             <Button
               justIcon
               className={classes.modalCloseButton}
               key="close"
               aria-label="Close"
               color="transparent"
               onClick={() => setShowBaseLineReport(false)}
             >
               <Close className={classes.modalClose} />
             </Button>
             </GridItem>
             <h4 ><b>{attorneyAddress}</b></h4>
           </DialogTitle>
           <DialogContent
             id="modal-slide-description"
             className={classes.modalBody}
           
           >
              
             <h5>Filed Date{fileDate}</h5>
             <h6>{statusDate} </h6>
           </DialogContent>
           <DialogActions
             className={classes.modalFooter + " " + classes.modalFooterCenter}
           >
             {/* <Button color="info" onClick={() => setShowBaseLineReport(false)}>Close</Button> */}
             
           {/* </DialogActions>
         </Dialog> */}
  <Clearfix />
            </CardBody>
          </Card>
     
     </GridItem>
    
    
 </GridContainer>
 
);
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

BankruptcyMenu.propTypes = {
    organizationScoreExecutionLogList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
   
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmGetBankrutcyDetailsOfUserRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  bankruptcyDetailsOfUser: state.tenantmonitoring.bankruptcyDetailsOfUser,
     loading: state.tenantmonitoring.loading,
     tenantId: state.tenantmonitoring.tenantId,

});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankruptcyMenu);

