import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CsvUpload from "components/CustomUpload/CsvUpload.js";
import TenantTable from "views/Tenant/TenantTable.js"
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Tooltip } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import DashboardIcon from '@material-ui/icons/Dashboard';

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CommentIcon from '@material-ui/icons/Comment';
import Pagination from '@material-ui/lab/Pagination';
import CommentaryTable from "./CommentaryTable";

const styles = {

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',
  },
    ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right",
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  
};

const useStyles = makeStyles(styles);

  const CommentaryList = ({
    commentaryList, tmCommentaryListRequest,user,tenantId,organisationId
  }) => {
    const history = useHistory();
    const location = useLocation();
    const [dataList, setDataList] = React.useState(commentaryList);
    const [messageobj, setMessageobj] = React.useState("");
    
    const [propertyId, setPropertyId ] = React.useState("");
  
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));


   // const[id,setId] = React.useState(location.state.id);
  //  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const[name,setName] = React.useState("");
  
    const[userId,setUserId] = React.useState(user.id);

    const [alert,setAlert] = React.useState(false);
    const [dataStatus,setDataStatus]=React.useState(false);

 
  
    const [alert2,setAlert2] = React.useState(false);

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNum, setPageNum] = React.useState(0);

    const [showNoDataAvailable,setShowNoDataAvailable]=React.useState(false);
    const [showDataAvailable,setShowDataAvailable]=React.useState(true);

    const hideAlert2 = () => {
      setAlert2(false);
    }

    useEffect(() => {
    
      console.log("organisationId"+organisationId);
      getCommentaryList(organisationId,user.id,tenantId,pageSize,pageNum);
    
      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
    //  if(commentaryList.commentaryListPaginations!=[]){
    //    setShowDataAvailable(true);
    //    setShowNoDataAvailable(false);
    //  }else{
    //   setShowDataAvailable(false);
    //   setShowNoDataAvailable(true);
    //  }
      }, [dataStatus,commentaryList]);
  
   
      const hideAlert = () => {
        setAlert(false);
        history.push({ 
          pathname: '/admin/select-org',
          state: {requestFrom:"tenant"}
         });
      
      } 

    const getCommentaryList = async (organisationId,userId,tenantId,pageSize,pageNum) => {
      await tmCommentaryListRequest(organisationId,userId,tenantId,pageSize,pageNum);
    //  alert(organisationId);
      };

      const handlePageChange =(event, value) => {
  
        setPage(value);
        getCommentaryList(organisationId,user.id,tenantId,pageSize,value-1);
    console.log("page"+JSON.stringify(commentaryList.commentaryListPaginations));
    
        setTimeout(() => {
      
        }, 500);
        
        console.log(commentaryList.resultSize); 
        };
  const classes = useStyles();
  if(dataStatus){

  return (
    <GridContainer>

      <GridItem xs={12}>

           {showDataAvailable&& <CommentaryTable
              columns={[
                
              {
                Header: "Date",
                accessor: "date",

              },

                {
                  Header: "Title",
                  accessor: "title",
                  width: 50,
                },

                
                {
                  Header: "Commentary",
                  accessor: "commentary",
                  isVisible: false,
                  width: 50,
                },

              ]}
              data={commentaryList.commentaryListPaginations.map((prop, key) => {

               
                return {
                //  id: prop.id,
                  organisationId:organisationId,
                  tenantName: prop.tenantName,
                  commentary: prop.commentary,
                  userId:prop.userId,
                  tenantId:prop.tenantId,
                  title:prop.title,
                  date:prop.date,
                  organisationName:prop.organisationName,

                  commentary:(
             <h5 style={{fontSize:"14px",font:"inherit"}} dangerouslySetInnerHTML={{__html:prop.commentary}}></h5>
                   
                    ),
                
      
                };
              })}


        
           //   data={tenantList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />}
            <br></br>
 <GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
 <div>
  <Pagination variant="outlined" color="primary"
 shape="rounded" count={commentaryList.totalPages} page={page} onChange={handlePageChange} size="large" />
 </div>
 
 </GridItem>
{/* 
 <GridItem xs={12} sm={12} md={12} className={classes.vertical} >
 
 <div>
 No Data Available...
 </div>
 
 </GridItem> */}
        </GridItem>
    </GridContainer>
    
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


CommentaryList.propTypes = {
  commentaryList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
      
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmCommentaryListRequest: PropTypes.func.isRequired,
  
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  commentaryList: state.tenantmonitoring.commentaryList,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentaryList);



