import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Badge from "components/Badge/Badge.js";
import { completedTasksChart } from "variables/charts";
import Heading from "components/Heading/Heading.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const OrgSelected = ({ orgName }) =>{
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      
      <Badge color="info"> <h2><b>Selected Company:   {orgName}</b></h2></Badge>
     <br/>
     <br/>
        {/* <Heading textAlign="left" style={{color: 'red'}}  title={"Selected Company :"}/> */}
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={6}>
        <Heading textAlign="left"  title={orgName}/>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}

export default OrgSelected;