import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Link from '@material-ui/core/Link';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import Heatmap from "components/Charts/Heatmap.js";
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapFundRisk from "components/Charts/HeapMapFundRisk.js";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";
import PropTypes from 'prop-types';
import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";


import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);





const DashboardTM = ({user,tmDashboardRequest,
                         tmDashboardFund,
                         tmFundByOrgIdRequest,
                         tmFundRiskCountRequest,
                         fundsByOrg,
                         fundRiskCount
}) => {
  const history = useHistory();

  const [input, setInput] = React.useState('');
  const [showHeatMap, setShowHeatMap] = React.useState(true);
  const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
  const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);
  const [fundActive, setFundActive] = React.useState(true);
  const [tenantActive, setTenantActive] = React.useState(false);
  const [organizationId, setOrganizationId] = React.useState("");
  const [fundDashboard, setFundDashboard] = React.useState("");
  const [isLoadingPage, setIsLoadingPage] = React.useState(false);
  const [selectedFundId, setSelectedFundId] = React.useState("");
  const [selectedFundName, setSelectedFundName] = React.useState("");
  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);
  
  const [month, setMonth] = React.useState("");

  const [year, setYear] = React.useState(2020);

  const location = useLocation();

  //const [backNavigation, setBackNavigation] = React.useState(location.state.backNavigation);

  //const [tenantId123, setTenantId123] = React.useState(location.state.tenantId);
  const [showHeatMapTenantRisk1, setShowHeatMapTenantRisk1] = React.useState(false);

  const [headingText, setHeadingText] = React.useState("Overall Fund  Risk Score By Month");

  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: (option) => option.title,
  // };

  const tenantDetails =()=> {

    history.push({
      pathname: '/admin/tenantNotification/',
      state : { tenantId : "5fbb5eaa1264e60ad5bb4626", contifyCompanyId:"185287", naics: "335999", accountNumber : "t-2288-1582917852547", tenantName : "Med El Corporation"}
    })

  // history.push({"/admin/tenantNotification/", state : { tenantId : "5fbb5eaa1264e60ad5bb4626", contifyCompanyId:"185287", naics: "335999", accountNumber : "t-2288-1582917852547", tenantName : "Med El Corporation"}} )
 
  }

  const handleClick = (event)=> {
    event.preventDefault();
   
    console.info('You clicked a breadcrumb.');
  }

  const enableTenant = (range) => {
   
    //const {rangeFrom,rangeTo}=range;
    
    setSelectedRangeFrom(range.rangeFrom);
    setSelectedRangeTo(range.rangeTo);
    setMonth(range.month);
    setShowHeatMapTenant(true);
    setShowHeatMapFundRisk(false);
    setTenantActive(true);
    setHeadingText("TenantTracker Risk Scores by Month");
    setShowHeatMapTenantRisk(false);
  }

  const enableTenantRisk = (value) => {
    setShowHeatMapTenantRisk(true);
    setShowHeatMapTenant(false);
    const textLabel1="TenantTracker";
    const textLable2= "Score and components by Month";
    setHeadingText(textLabel1+"'&trade;'"+textLable2); 
      

    setShowHeatMapFundRisk(false);
    setTenantActive(false);
  }
  const handleChange = (fundId,fundName) => {
    setSelectedFundId(fundId);
    setSelectedFundName(fundName);
    //getTmFundRiskCount(organizationId,fundId);
    setShowHeatMap(false);
    setShowHeatMapFundRisk(true);
    setHeadingText("Tenant  Risk by Fund by Month");  
    setFundActive(false);
    setTenantActive(false);
  }


  React.useEffect(() => {

    console.log(user.role);
    if(location.state){
      //alert("test");
    }
   // getTmDashboard(organizationId);
   // setFundDashboard(tmDashboardFund);
    //getTmFundByOrg(organizationId);
// if(location.state.backNavigation!="undefined"){
//   alert("Java.......................");
//   handleChange(location.state.fundIdNav, location.state.fundNameNav);

// }
   
   
    
   if(fundDashboard){
    setIsLoadingPage(true);
   }

  }, []);

  const getTmFundByOrg = async (organizationId) => {
    await tmFundByOrgIdRequest(organizationId);
    };

  const getTmDashboard = async (organizationId) => {
    await tmDashboardRequest(organizationId);
 
    };

    const getTmFundRiskCount = async (organizationId,fundId) => {
      await  tmFundRiskCountRequest(organizationId);
   
      };




  

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 

  return (
     <div>


  {/* <Breadcrumbs aria-label="breadcrumb">
      <Link   style={{color:megaColor}} href="/"  onClick={() => history.push("/admin/tenants")} className={classes.link}>
        <HomeIcon fontSize="inherit" className={classes.icon} />
        Dashboard
      </Link>
      <Link
        style={{color:megaColor,fonntSize:'18px'}}
        to={'/admin/dashboard'}
        onClick={() => history.push("/admin/tenants")}
        className={classes.link}
      >
        <WhatshotIcon fontSize="inherit" className={classes.icon} />
       Fund Risk
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon fontSize="inherit" className={classes.icon} />
       Tenants
      </Typography>
    </Breadcrumbs> */}

      <Card>


      <GridContainer >


         
         {/* <GridItem xs={12} sm={5} md={5}>
         <h5 style={{ color: megaColor,paddingLeft:'20px' }}> <b></b></h5>
         </GridItem>

       <GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right">
              {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
           
             {/* <br></br><br/>
                
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
             

  </GridItem>
   */}
  
      <br></br><br/>

    <GridItem xs={12} sm={12} md={12} style={{paddingLeft:'40px'}}>
      { showHeatMap && <Heatmap organizationId={"5f8ee03be74296262abdeb65"}/> }
      { showHeatMapTenant && <HeatMapTenant fundId={selectedFundId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year} /> }
      { showHeatMapFundRisk && <HeatMapRisk1 fundName={selectedFundName} fundId={selectedFundId} parentCallBack ={enableTenant}/> }
      { showHeatMapProperty &&  <HeatMapProperty/> }
      { showHeatMapTenantRisk1 &&  <HeatMapTenantRisk/> }
      </GridItem>
     {/* <HeatMapRisk1/> */}
     {/* <GridItem xs={12} sm={12} md={12} style={{paddingLeft : '40px',paddingTop: '40px'}}>
         <h5 style={{color : megaColor}}><b>INSIGHTS (FY2020)</b></h5>  
         </GridItem> */}

      {fundActive &&
       <GridItem xs={12} sm={12} md={12}>


       <br/>
       <br/>

      
           
        </GridItem>
  

      }



       {tenantActive &&
 <GridItem xs={12} sm={12} md={12}>


<br/>
<br/>


       
 </GridItem>
       }
{/*     
        <GridItem xs={12} sm={2} md={2}>
        
         </GridItem>
         {tenantActive&& 
         <GridItem xs={12} sm={10} md={10}>
         <h7 style={{ color: megaColor }}><b>( Click on the tenant to get its drill down details, arrows denote score upward/downward trends )</b></h7>  
         <br/>     <br/>
         </GridItem>
     }   */}
      </GridContainer>  
      </Card>
    </div>
  );
}



DashboardTM.propTypes = {
  tmNotificationList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.number,
      contifyId: PropTypes.number,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  tmDashboardRequest: PropTypes.func.isRequired,
  tmFundByOrgIdRequest: PropTypes.func.isRequired,
  tmFundRiskCountRequest: PropTypes.func.isRequired, 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmDashboardFund: state.tenantmonitoring.tmDashboardFund,
  fundsByOrg: state.tenantmonitoring.fundsByOrg,
  fundRiskCount: state.tenantmonitoring.fundsByOrg,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardTM);

