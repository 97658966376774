// import React from "react";
// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";

// // @material-ui/icons
// import PermIdentity from "@material-ui/icons/PermIdentity";
// import Close from "@material-ui/icons/Close";

// // core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// import Heatmap from "components/Charts/Heatmap.js";

// import HeatMapProperty from "components/Charts/HeapMapProperty.js";

// import HeatMapTenant from "components/Charts/HeapMapTenant.js";
// import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

// import avatar from "assets/img/faces/marc.jpg";
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

// import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Check from "@material-ui/icons/Check";

// import FormControl from "@material-ui/core/FormControl";
// // import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

// import {
//   whiteColor,
//   hexToRgb,
//   megaColor,
//   lyticsColor
// } from "assets/jss/material-dashboard-pro-react.js";


// const useStyles = makeStyles(styles,styles1);

// const top100Films = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'The Godfather', year: 1972 },
//   { title: 'The Godfather: Part II', year: 1974 },
//   { title: 'The Dark Knight', year: 2008 },
//   { title: '12 Angry Men', year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: 'Pulp Fiction', year: 1994 },
//   { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
//   { title: 'The Good, the Bad and the Ugly', year: 1966 },
//   { title: 'Fight Club', year: 1999 },
//   { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
//   { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
//   { title: 'Forrest Gump', year: 1994 },
//   { title: 'Inception', year: 2010 },
//   { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: 'Goodfellas', year: 1990 },
//   { title: 'The Matrix', year: 1999 },
//   { title: 'Seven Samurai', year: 1954 },
//   { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
//   { title: 'City of God', year: 2002 },
//   { title: 'Se7en', year: 1995 },
//   { title: 'The Silence of the Lambs', year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: 'Life Is Beautiful', year: 1997 },
//   { title: 'The Usual Suspects', year: 1995 },
//   { title: 'Léon: The Professional', year: 1994 },
//   { title: 'Spirited Away', year: 2001 },
//   { title: 'Saving Private Ryan', year: 1998 },
//   { title: 'Once Upon a Time in the West', year: 1968 },
//   { title: 'American History X', year: 1998 },
//   { title: 'Interstellar', year: 2014 },
//   { title: 'Casablanca', year: 1942 },
//   { title: 'City Lights', year: 1931 },
//   { title: 'Psycho', year: 1960 },
//   { title: 'The Green Mile', year: 1999 },
//   { title: 'The Intouchables', year: 2011 },
//   { title: 'Modern Times', year: 1936 },
//   { title: 'Raiders of the Lost Ark', year: 1981 },
//   { title: 'Rear Window', year: 1954 },
//   { title: 'The Pianist', year: 2002 },
//   { title: 'The Departed', year: 2006 },
//   { title: 'Terminator 2: Judgment Day', year: 1991 },
//   { title: 'Back to the Future', year: 1985 },
//   { title: 'Whiplash', year: 2014 },
//   { title: 'Gladiator', year: 2000 },
//   { title: 'Memento', year: 2000 },
//   { title: 'The Prestige', year: 2006 },
//   { title: 'The Lion King', year: 1994 },
//   { title: 'Apocalypse Now', year: 1979 },
//   { title: 'Alien', year: 1979 },
//   { title: 'Sunset Boulevard', year: 1950 },
//   { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
//   { title: 'The Great Dictator', year: 1940 },
//   { title: 'Cinema Paradiso', year: 1988 },
//   { title: 'The Lives of Others', year: 2006 },
//   { title: 'Grave of the Fireflies', year: 1988 },
//   { title: 'Paths of Glory', year: 1957 },
//   { title: 'Django Unchained', year: 2012 },
//   { title: 'The Shining', year: 1980 },
//   { title: 'WALL·E', year: 2008 },
//   { title: 'American Beauty', year: 1999 },
//   { title: 'The Dark Knight Rises', year: 2012 },
//   { title: 'Princess Mononoke', year: 1997 },
//   { title: 'Aliens', year: 1986 },
//   { title: 'Oldboy', year: 2003 },
//   { title: 'Once Upon a Time in America', year: 1984 },
//   { title: 'Witness for the Prosecution', year: 1957 },
//   { title: 'Das Boot', year: 1981 },
//   { title: 'Citizen Kane', year: 1941 },
//   { title: 'North by Northwest', year: 1959 },
//   { title: 'Vertigo', year: 1958 },
//   { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
//   { title: 'Reservoir Dogs', year: 1992 },
//   { title: 'Braveheart', year: 1995 },
//   { title: 'M', year: 1931 },
//   { title: 'Requiem for a Dream', year: 2000 },
//   { title: 'Amélie', year: 2001 },
//   { title: 'A Clockwork Orange', year: 1971 },
//   { title: 'Like Stars on Earth', year: 2007 },
//   { title: 'Taxi Driver', year: 1976 },
//   { title: 'Lawrence of Arabia', year: 1962 },
//   { title: 'Double Indemnity', year: 1944 },
//   { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
//   { title: 'Amadeus', year: 1984 },
//   { title: 'To Kill a Mockingbird', year: 1962 },
//   { title: 'Toy Story 3', year: 2010 },
//   { title: 'Logan', year: 2017 },
//   { title: 'Full Metal Jacket', year: 1987 },
//   { title: 'Dangal', year: 2016 },
//   { title: 'The Sting', year: 1973 },
//   { title: '2001: A Space Odyssey', year: 1968 },
//   { title: "Singin' in the Rain", year: 1952 },
//   { title: 'Toy Story', year: 1995 },
//   { title: 'Bicycle Thieves', year: 1948 },
//   { title: 'The Kid', year: 1921 },
//   { title: 'Inglourious Basterds', year: 2009 },
//   { title: 'Snatch', year: 2000 },
//   { title: '3 Idiots', year: 2009 },
//   { title: 'Monty Python and the Holy Grail', year: 1975 },
// ];




// const Property = ({}) => {

//   const [input, setInput] = React.useState('');


//     // type validation
// const [required, setrequired] = React.useState("");
// const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
// const [number, setnumber] = React.useState("");
// const [numberState, setnumberState] = React.useState("");
// const [url, seturl] = React.useState("");
// const [urlState, seturlState] = React.useState("");
// const [equalTo, setequalTo] = React.useState("");
// const [whichEqualTo, setwhichEqualTo] = React.useState("");
// const [equalToState, setequalToState] = React.useState("");

// // range validation
// const [minLength, setminLength] = React.useState("");
// const [minLengthState, setminLengthState] = React.useState("");
// const [maxLength, setmaxLength] = React.useState("");
// const [maxLengthState, setmaxLengthState] = React.useState("");
// const [range, setrange] = React.useState("");
// const [rangeState, setrangeState] = React.useState("");
// const [minValue, setminValue] = React.useState("");
// const [minValueState, setminValueState] = React.useState("");
// const [maxValue, setmaxValue] = React.useState("");
// const [maxValueState, setmaxValueState] = React.useState("");



// // check Box
//   const [checked, setChecked] = React.useState([24, 22]);
//   const handleToggle = value => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }
//     setChecked(newChecked);
//   };



//   const defaultProps = {
//     options: top100Films,
//     getOptionLabel: (option) => option.title,
//   };

  
//   const handleChange = (value) => {
//     alert(value);
//   }

//   const flatProps = {
//     options: top100Films.map((option) => option.title),
//   };

//   // function that verifies if a string has a given length or not
// const verifyLength = (value, length) => {
//   if (value.length >= length) {
//     return true;
//   }
//   return false;
// };

//   const [value, setValue] = React.useState(null);

//   const classes = useStyles(); 

//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12} sm={12} md={12}>
//           <Card>
//           <CardHeader style={{ background: megaColor, color:'white' }} icon>
//             {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
//               <CardIcon color="info">
//                 <PermIdentity />
//               </CardIcon>
//               <h3>
//              <b>Create Property</b>
//               </h3> 
//             </CardHeader>
//             <CardBody>
   
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Property Name"
//                     id="property-name"

//                     success={requiredState === "success"}
//                     error={requiredState === "error"}
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyLength(event.target.value, 1)) {
//                           setrequiredState("success");
//                         } else {
//                           setrequiredState("error");
//                         }
//                         setrequired(event.target.value);
//                       },
//                       type: "text",
//                       endAdornment:
//                         requiredState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="City"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Province/State"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                 {<Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Zip Code" margin="normal" />}
//       /> }
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Country FIPS"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="MSA"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Rentable Building Area"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControlLabel
//           control={
//             <Checkbox
//               tabIndex={-1}
//               // onClick={() => handleToggle(22)}
//               // checked={checked.indexOf(22) !== -1 ? true : false}
//               checkedIcon={<Check className={classes.checkedIcon} />}
//               icon={<Check className={classes.uncheckedIcon} />}
//               classes={{
//                 checked: classes.checked,
//                 root: classes.checkRoot
//               }}
//             />
//           }
//           classes={{ label: classes.label }}
//           label="LEED Certified"
//         />
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControlLabel
//           control={
//             <Checkbox
//               tabIndex={-1}
//               // onClick={() => handleToggle(22)}
//               // checked={checked.indexOf(22) !== -1 ? true : false}
//               checkedIcon={<Check className={classes.checkedIcon} />}
//               icon={<Check className={classes.uncheckedIcon} />}
//               classes={{
//                 checked: classes.checked,
//                 root: classes.checkRoot
//               }}
//             />
//           }
//           classes={{ label: classes.label }}
//           label="Energy Star"
//         />
//         <br></br>
//                 </GridItem>
//               </GridContainer>

//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Number Of Parking Spaces"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>

//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Tenancy
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//       </GridItem>

//                 </GridContainer>


               


           

                
     
                
//                 {/* <GridItem xs={12} sm={12} md={4}>
//                   <CustomInput
//                     labelText="Country"
//                     id="country"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem> */}
//                 {/* <GridItem xs={12} sm={12} md={6}> */}
//                 {/* <Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="ZipCode" margin="normal" />}
//       /> */}
//                 {/* </GridItem> */}
              

              
//               {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
//                   <Autocomplete
//                   onInputChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//         {...defaultProps}
//         id="zipcode"
//         debug
    
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="ZipCode" margin="normal" />}
//       />
//                 </GridItem>
//               </GridContainer> */}


// <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Property Category
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
                   
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Building Class
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//                 </GridItem>
// </GridContainer>


// <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Property Type
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Market Area
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//                 </GridItem>
// </GridContainer>

//               {/* <Button color="rose" className={classes.updateProfileButton}>
//                 Update Profile
//               </Button> */}

            


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Property Occupancy"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Market Vacancy"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Year Built"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Sub Market Vacancy"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Office Number"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="ZIP code"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Mobile Number"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="City"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Email"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>

//                 <CustomDropdown
//         buttonText="Search for state"
//         dropdownList={[
//           // "Action",
//           // "Another action",
//           // "Something else here",
//           // {divider: true},
//           // "Separated link",
//           // {divider: true},
//           // "One more separated link",
//         ]}
//       />
//                 </GridItem>
// </GridContainer>

//  */}
// <br/>
// <br/>
//  <div className={classes.buttonGroup}>
//                 <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
//                   <b>Save</b>
//                 </Button>

//                     <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
//                   <b>Cancel</b>
//                 </Button>
              
//               </div>

//               <Clearfix />
//             </CardBody>
//           </Card>
//         </GridItem>
       
//       </GridContainer>
     
//     </div>
//   );
// }
// export default Property;



import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SaveIcon from '@material-ui/icons/Save';
import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';



import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Badge from "components/Badge/Badge.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";






const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
}));
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const PropertyEdit = ({tmPropertySaveRequest,tmPropertyEditRequest,tmFundListRequest,fundList,propertyEditObj,tmPropertyByIdRequest,property,tmAssetManagerListRequest, assetManagerList,tmFundListByOrgRequest,
  tmFundListWithUserRequest,fundListWithUser}) => {

  const history = useHistory();
  const location = useLocation();
  const [input, setInput] = React.useState('');
  //const [dataList, setDataList] = React.useState(fundList);
  const [dataList, setDataList] = React.useState(assetManagerList)

    // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");



const [requiredPropNme, setrequiredPropNme] = React.useState("");
const [requiredStatePropNme, setrequiredStatePropNme] = React.useState("")

const [requiredCity, setrequiredCity] = React.useState("");
const [requiredStateCity, setrequiredStateCity] = React.useState("");

const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredStateAddress, setrequiredStateAddress] = React.useState("");

const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");

const [numberLimit,setLimit]=React.useState("");
const [numberStateLimit, setnumberStateLimit] = React.useState("");

const [requiredFundNme, setrequiredFundNme] = React.useState("");
const [requiredAssetMgrNme, setrequiredAssetMgrNme] = React.useState("");




const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");

const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");


const [propertyName,setPropertyName] =React.useState("");
const [city,setCity] =React.useState("");
const [address1,setAddress1] =React.useState("");
const [state,setState] =React.useState("");
const [zipCode,setZipCode] =React.useState("");
const [countyName,setCountyName] =React.useState("");
const [fipsCounty,setFipsCounty] =React.useState("");
const [msa,setMsa] =React.useState("");
const [buildingClass,setBuildingClass] =React.useState("");
const [rentableBuildingArea,setRentableBuildingArea] =React.useState("");
const [leedCertified,setLeedCertified] =React.useState("");
const [energyStar,setEnergyStar] =React.useState("");
const [tenancy,setTenancy] =React.useState("");
const [noOfParkingSpaces,setNoOfParkingSpaces] =React.useState("");
const [propertyOccupancy,setPropertyOccupancy] =React.useState("");
const [marketVaccancy,setMarketVaccancy] =React.useState("");
const [yearBuilt,setYearBuilt] =React.useState("");
const [subMarketVaccancy,setSubMarketVaccancy] =React.useState("");
const [dataStatus,setDataStatus]=React.useState(false);
const [propertyId, setPropertyId ] = React.useState(location.state.propertyId);
const [assetManagerId,setAssetManagerId]=React.useState(location.state.assetManagerId);
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
const [userId,setUserId] =React.useState(""); 
const [fundId,setFundId] =React.useState(""); 
const[name,setName]=React.useState("");
const[assetManagerName,setAssetManagerName] = React.useState("");
const [category,setCategory] =React.useState("");
const [subMarketArea,setSubMarketArea] =React.useState("");
const [subMarketOccupancy,setSubMarketOccupancy] =React.useState("");

const [marketArea,setMarketArea] =React.useState("");
//  const [type,setType] =React.useState("");

const[showFieldsCBD, setShowFieldCBD] = React.useState(false);
const[labelFundName,setLabelFundName] = React.useState(location.state.fundName);
const[labelAssetManagerName,setLabelAssetManagerName] = React.useState(location.state.assetManagerName);
// const [labelType,setLabelType]=React.useState(propertyEditObj.type);

const [squareFootage,setSquareFootage] =React.useState("");
const[requiredSquareFootage,setRequiredSquareFootage] = React.useState("")

const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);
const[propertySqftNew,setPropertySqftNew]= React.useState("");
const[rba,setRba]= React.useState("");


const hideAlert = () => {
  setAlert(false);
}


// check Box
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };



  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };
  const selectedPropertyManager = (assetManager) => {
    if(assetManager!=null){
    setUserId(assetManager.id);
    setAssetManagerId(assetManager.id); 
    setLabelAssetManagerName("");
    }
  };
  const allAssetManagerProps = {
  
    options: assetManagerList,
    getOptionLabel: (option) => option.firstName+" "+option.lastName
  };

    useEffect(() => {


      async function getPropertyById(propertyId) {
        await tmPropertyByIdRequest(propertyId);
     
      if(propertyEditObj){
        setPropertyName(propertyEditObj.propertyName);
        setCity(propertyEditObj.city);
        setAddress1(propertyEditObj.address1);
        setState(propertyEditObj.state);
        setCountyName(propertyEditObj.countyName);
        setFipsCounty(propertyEditObj.fipsCounty);
        setMsa(propertyEditObj.msa);
        setRentableBuildingArea(propertyEditObj.rentableBuildingArea);
        setNoOfParkingSpaces(propertyEditObj.noOfParkingSpaces);
        setPropertyOccupancy(propertyEditObj.propertyOccupancy);
        setMarketVaccancy(propertyEditObj.marketVaccancy);
        setYearBuilt(propertyEditObj.yearBuilt);
        setSubMarketVaccancy(propertyEditObj.subMarketVaccancy);
        setZipCode(propertyEditObj.zipCode);
        setTenancy(propertyEditObj.tenancy);
        setOrganizationId(propertyEditObj.organisationId);
        setUserId(propertyEditObj.userId);
        setAssetManagerId(propertyEditObj.assetManagerId);
        setFundId(propertyEditObj.fundId);
        setName(propertyEditObj.name);
        setCategory(propertyEditObj.category);
        setAssetManagerName(propertyEditObj.assetManagerName);
        setLabelFundName(propertyEditObj.fundName);
        setLabelAssetManagerName(propertyEditObj.assetManagerName);
        setBuildingClass(propertyEditObj.buildingClass);
        // setLabelType(propertyEditObj.type);
         setType(propertyEditObj.type);
        // setSelected(propertyEditObj.type);
       
        setMarketArea(propertyEditObj.marketArea);
        setSquareFootage(propertyEditObj.squareFootage);
        setPropertySqftNew(propertyEditObj.propertySqftNew);
        setRba(propertyEditObj.rba);
        
setLeedCertified(propertyEditObj.leedCertified);
setEnergyStar(propertyEditObj.energyStar);
if(propertyEditObj.marketArea==="CBD"){
  setShowFieldCBD(true);
  setSubMarketArea(propertyEditObj.subMarketArea);
 setSubMarketOccupancy(propertyEditObj.subMarketOccupancy);
  }else if(propertyEditObj.marketArea!=="CBD"){
    setShowFieldCBD(false);
  }
      }

    };

    setPropertyName("");
    setCity("");
    setAddress1("");
    setState("");
    setCountyName("");
    setFipsCounty("");
    setMsa("");
    setRentableBuildingArea("");
    setNoOfParkingSpaces("");
    setPropertyOccupancy("");
    setMarketVaccancy("");
    setYearBuilt("");
    setSubMarketVaccancy("");
    setZipCode("");
    setTenancy("");
    setOrganizationId("");
    setUserId("");
    setAssetManagerId("");
    setFundId("");
    setName("");
    setCategory("");
    setAssetManagerName("");
    setLabelFundName("");
    setLabelAssetManagerName("");
    setBuildingClass("");
     setType("");
  
   setSquareFootage("");
    setMarketArea("");
    setPropertySqftNew("");
    setRba("");
    
setLeedCertified("");
setEnergyStar("");
if(propertyEditObj.marketArea==="CBD"){
setShowFieldCBD(true);
setSubMarketArea("");
setSubMarketOccupancy("");
}else if(propertyEditObj.marketArea!=="CBD"){
setShowFieldCBD(false);
}

  getFundListWithUser(organizationId);
 
  getPropertyById(propertyId);
 getAssetManagerList(organizationId);
    
 setTimeout(() => {
  setDataStatus(true);
}, 1000);
    
      }, [dataStatus]);
  
    
      // const getFundList = async (organizationId) => {
      //   await tmFundListByOrgRequest(organizationId);
       
      //   };
      const getFundListWithUser = async (organizationId) => {
        await tmFundListWithUserRequest(organizationId);
       
        };

    
   
      setTimeout(() => {
        setDataStatus(true);
      }, 1000);

      const getAssetManagerList = async (organizationId) => {
        await tmAssetManagerListRequest(organizationId);
        
        };

        // const getFundListByOrg = async (organizationId) => {
        //   await tmFundListRequest(organizationId);
          
        //   };
        function hideAlert1(event) {
          history.push({ 
            pathname: '/admin/propertylist'
           });
          }
  
  const allFundProps = {
  
    options: fundListWithUser,
    getOptionLabel: (option) => option.name,
  };

  
  const handleChange = (value) => {
  
  };
  const selectedFund = (value) => {
    if(value!=null){
    setFundId(value.id)
    setLabelFundName("");
    }
  };
  // const selectedType = (type) => {
  //   console.log("type"+JSON.stringify(type));
  //       if(type!=null){
       
  //       setLabelType("");
  //       }
  //     };
  const [selected, setSelected] = React.useState("");


  const changeSelectOptionHandler = (event) => {
    setCategory(event.target.value);
 console.log(event.target.value);
  
  };
  const industrial = [
    "Select...",
    "Distribution Warehouse",
    "Flex space",
    "Industrial- Business Park",
    "Industrial Condo",
    "Manufacturing",
    "Office Showroom",
    "R&D",
    "Refrigerated/Cold Storage",
    "Self/Mini-Storage Facillity",
    "Truck Terminal/Hub/Transit",
    "Warehouse",
    "Other"
  ];
  const office = ["Select...","Institutional/Government",
  "Medical Office",
  "Office Building",
  "Office- Business Park",
  "Office Condo",
  "Office- R&D",
  "Office- Warehouse",
  "Other"];
  const retail = ["Select...","Anchor",
    "Community Center",
    "Day Care Facillity/Nursery",
    "Free Standing Building",
    "Neighborhood Center",
    "Outlet Center",
    "Post Office",
    "Regional Center/Mall",
    "Restaurant",
    "Retail Pad",
   "Service/Gas Station",
    "Street Retail",
    "Strip Center",
    "Speciality Center",
    "Theme/Festival Center",
    "Vehicle Related",
    "Other"];

    const land = ["Select..."," Commercial/Other","Industrial","Leased Land","Mutlifamily",
  "Office","Residential","Retail","Retail-Pad","Other"];
   
  const healthCare = ["Select..."," Acute Care Hospital","Other"];

  const mixedUse = ["Select...","  Neighborhood Commercial Zoing","Main Street Residential/Commercial",
"Urban Residential / Commercial","Office Convenience","Office/ Residential","Shopping Mall Conversion",
"Retail District Retrofit","Live / Work"];
 
  const other = ["Select..."," Golf Related","Marina","Religious Facillity","Special Purpose",
" Sport & Entertainment","Theatre/Performing Arts","Other"];
 
let [type, setType] = React.useState(null);
let options = null;
if (category === "Industrial") {
  type = industrial;
} else if (category === "Office") {
  type = office;
} else if (category === "Retail") {
  type = retail;
} else if (category === "Land") {
  type = land;
}
 else if (category === "Health Care") {
  type = healthCare;
}
else if (category === "Mixed Use") {
  type = mixedUse;
}
else if (category === "Other") {
  type = other;
}
console.log("category"+JSON.stringify(category));
console.log("type"+JSON.stringify(type));
  const setValueForCategory = event => {
    setCategory(event.target.value);
  };
  
  const setValueForTenancy = event => {
    setTenancy(event.target.value);
  };
  
  const setValueForBuildingClass= event => {
    setBuildingClass(event.target.value);
  };
  
  const setValueForMarketArea= event => {
 
    if(event.target.value==="CBD"){
     setShowFieldCBD(true);
     setMarketArea(event.target.value);
    
   
    }else if(event.target.value!=="CBD"){
     setMarketArea(event.target.value);
     setShowFieldCBD(false);
    }
  };

  const setValueForType= event => {
    console.log(event.target.value);
    setSelected(event.target.value);
    
    
    };
 

 
  const submitForm = () => {
    if(verifyLength(propertyName,1,propertyName)&&verifyLength(city,1,city)&& verifyLength(address1,1,address1)&&verifyNumber(noOfParkingSpaces,1,noOfParkingSpaces)&&verifyLength(yearBuilt,1,yearBuilt)&&verifyLength(fundId,1,fundId)&&verifyLength(assetManagerId,1,assetManagerId)&&verifyLength(squareFootage,1,squareFootage))
    {
    const property={

      id:propertyId,
      propertyName:propertyName,
      city:city,
      address1:address1,
      state:state,
      zipCode:zipCode,
      countyName:countyName,
      fipsCounty:fipsCounty,
      msa:msa,
      buildingClass:buildingClass,
      rentableBuildingArea:rentableBuildingArea,
      leedCertified:leedCertified,
      energyStar:energyStar,
      noOfParkingSpaces:noOfParkingSpaces,
      tenancy:tenancy,
      propertyOccupancy:propertyOccupancy,
      marketVaccancy:marketVaccancy,
      yearBuilt:yearBuilt,
      subMarketVaccancy:subMarketVaccancy,
      organisationId:organizationId,
      assetManagerId:assetManagerId,
      userId:userId,
      fundId:fundId,
      category:category,
      type:selected,
      subMarketArea:subMarketArea,
      subMarketOccupancy:subMarketOccupancy,
      marketArea:marketArea,
      squareFootage:squareFootage,
      propertySqftNew:propertySqftNew,
      rba:rba,
     
    }
   
    tmPropertyEditRequest(property);
  
    history.push( setAlert1(true),{ 
 
      pathname: '/admin/propertylist',
      // state:{ message:"Fund Saved Successfully" }
     });
      }
     else{
      setAlert(true);
      if (requiredStatePropNme === "") {
        setrequiredStatePropNme("error");
      }
      if (requiredStateCity === "") {
        setrequiredStateCity("error");
      }
      if (requiredStateAddress === "") {
        setrequiredStateAddress("error");
      }
      if (numberStateLimit === "") {
        setnumberStateLimit("error");
      }
      if (numberState === "") {
        setnumberState("error");
      }
      if (requiredFundNme === "") {
        setrequiredFundNme("error");
      }
      if (requiredAssetMgrNme === "") {
        setrequiredAssetMgrNme("error");
      }
      if (requiredSquareFootage=== "") {
        setRequiredSquareFootage("error");
      }   
    }
  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length,params) => {
    console.log(params);
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  
// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};


const verifyLengthLimit = (value, length) => {
  if ((value.length) == length) {
    return true;
  }
  return false;
};
const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (requiredStatePropNme === "") {
    setrequiredStatePropNme("error");
  }
  if (requiredStateCity === "") {
    setrequiredStateCity("error");
  }
  if (requiredStateAddress === "") {
    setrequiredStateAddress("error");
  }
  if (numberStateLimit === "") {
    setnumberStateLimit("error");
  }
  if (requiredFundNme === "") {
    setrequiredFundNme("error");
  }
  if (requiredAssetMgrNme === "") {
    setrequiredAssetMgrNme("error");
  }
  if (requiredSquareFootage=== "") {
    setRequiredSquareFootage("error");
  }   

};



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Edit Property</text> <span style={{marginLeft:"580px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
        <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"editProperty"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
    
          
          
            </CardHeader>
            <CardBody>
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Property Name *"
                    id="property-name"
                   value={propertyName}


                   success={requiredStatePropNme === "success"}
                   error={requiredStatePropNme === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setPropertyName(event.target.value);
                          setrequiredStatePropNme("success");
                        } else {
                         
                          setrequiredStatePropNme("error");
                          setPropertyName("");
                        }
                        setrequiredPropNme(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStatePropNme=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="City *"
                    id="last-name"
                    value={city}
                    success={requiredStateCity === "success"}
                    error={requiredStateCity === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setCity(event.target.value);
                          setrequiredStateCity("success");
                        } else {
                          setrequiredStateCity("error");
                          setCity("");
                        }
                        setrequiredCity(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateCity=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address1 *"
                    id="address1"
                    value={address1}
                    success={requiredStateAddress === "success"}
                    error={requiredStateAddress === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setAddress1(event.target.value);
                          setrequiredStateAddress("success");
                        } else {
                          setrequiredStateAddress("error");
                          setAddress1("");
                        }
                        setrequiredAddress(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateAddress === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Province/State"
                    id="last-name"
                    value={state}

                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setState(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                 <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="ZipCode"
                    id="zipCode"
                    value={zipCode}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setZipCode(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country FIPS"
                    id="last-name"
                    value={fipsCounty}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setFipsCounty(event.target.value);
                        } else {
                          setrequiredState("error");
                         
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="last-name"
                    value={countyName}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setCountyName(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Property Square Feet"
                    id="last-name"
                    value={squareFootage}

                    success={requiredSquareFootage === "success"}
                    error={requiredSquareFootage === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setSquareFootage(event.target.value);
                          setRequiredSquareFootage("success");
                        } else {
                          setRequiredSquareFootage("error");
                        }
                        setRequiredSquareFootage(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                      requiredSquareFootage === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="MSA"
                    id="property-name"
                    value={msa}


                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setMsa(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Rentable Building Area"
                    id="last-name"
                    value={rentableBuildingArea}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setRentableBuildingArea(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>


              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
               
                <FormControlLabel
          control={
            <Checkbox
            value={leedCertified}
            tabIndex={-1}
            onClick={handleToggle}
            checked={leedCertified}
            defaultChecked={classes.uncheckedIcon}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              onChange: event => {
                if (event.target.checked) {
                  setLeedCertified(true);
                } else {
                  setLeedCertified(false);
                }
                
              },
              
            }}
            />
          }
         
          classes={{ label: classes.label }}
          label="LEED Certified"
          name="LEED Certified"
          
        />
        
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
               
                <FormControlLabel
          control={
            <Checkbox
            value={energyStar}
            tabIndex={-1}
            onClick={handleToggle}
            checked={energyStar}
            defaultChecked={classes.uncheckedIcon}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              onChange: event => {
                if (event.target.checked) {
                  setEnergyStar(true);
                } else {
                  setEnergyStar(false);
                }
                
              },
              
            }}
            />
          }
         
          classes={{ label: classes.label }}
          label="Energy Star"
          name="Energy Star"
          
        />
        
        </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Number Of Parking Spaces *"
                    id="city"
                    value={noOfParkingSpaces}

                    success={numberState === "success"}
                    error={numberState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setNoOfParkingSpaces(event.target.value);
                          setnumberState("success");
                        } else {
                          setnumberState("error");
                          setNoOfParkingSpaces("");
                        }
                        setnumber(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      numberState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>

                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            defaultValue={tenancy}
          >
            Tenancy
          </InputLabel>
          <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    defaultValue={tenancy}
                    // value={useOfSpace}
                    
                    classes={{
                      select: classes.select
                    }}
                   
                    onChange={setValueForTenancy}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
          >
              <MenuItem value="Single Tenant New">
               Single Tenant New
            </MenuItem>
            <MenuItem value="Single Tenant Own">
            Single Tenant Own
            </MenuItem>
            <MenuItem value="Multiple">
           Multiple
            </MenuItem>
          </Select>

        </FormControl>




      </GridItem>

                </GridContainer>


               


           

                
     
                
               

<GridContainer>
                <GridItem xs={12} sm={12} md={6}>
               
                <FormControl fullWidth className={classes.selectFormControl}>
                <label for="field1">Property Category</label>
          {/** Bind changeSelectOptionHandler to onChange method of select.
           * This method will trigger every time different
           * option is selected.
           */}
          <select 
           style={{height:"35px", border: "none" ,backgroundColor:"#f7f7f7", disableUnderline:"false",
           fontFamily: "Arial, Helvetica, sans-serif",fontSize:"14px"
          }}
     
          value={category}
          onChange={changeSelectOptionHandler}>
            {/* <option>Property Category...</option> */}
            <option>Industrial</option>
            <option>Office</option>
            <option>Retail</option>
            <option>Land</option>
            <option>Health Care</option>
            <option>Mixed Use</option>
            <option>Other</option>
            
          </select>
       
          </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
               
               <FormControl fullWidth className={classes.selectFormControl}>
               <label for="field1">Property Type</label>
        
          <select 
        style={{height:"35px", border: "none" ,backgroundColor:"#f7f7f7", disableUnderline:"false",
        fontFamily: "Arial, Helvetica, sans-serif",fontSize:"14px"
       }}
      
     value={type}
       onChange={setValueForType}
          >
            {
             
              /** This is where we have used our options variable */
              options
            }
          </select>
          </FormControl>
      </GridItem>
      
</GridContainer>


<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            Building Class
          </InputLabel>
          <Select
           MenuProps={{
            className: classes.selectMenu
          }}
          defaultValue={buildingClass}
          // value={useOfSpace}
          
          classes={{
            select: classes.select
          }}
         
          onChange={setValueForBuildingClass}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
          >
                <MenuItem value="A">
            A
            </MenuItem>
            <MenuItem value="B">
           B
            </MenuItem>
            <MenuItem value="C">
            C
            </MenuItem>
            <MenuItem value="NA">
           NA
            </MenuItem>
          </Select>
        </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            Market Area
          </InputLabel>
          <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={marketArea}
          onChange={setValueForMarketArea}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
          >
                     <MenuItem value="CBD">
             CBD
            </MenuItem>
  
            <MenuItem value="Metro">
            Metro
            </MenuItem>
            <MenuItem value="Suburban">
            Suburban
            </MenuItem>
            <MenuItem value="Other">
            Other
            </MenuItem>
          </Select>
        </FormControl>
                </GridItem>
</GridContainer>

              {/* <Button color="rose" className={classes.updateProfileButton}>
                Update Profile
              </Button> */}

{showFieldsCBD &&   <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Sub Market Area "
                    id="subMarketArea"
                    value={subMarketArea}
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setSubMarketArea(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>   
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Sub Market Occupancy "
                    id="subMarketOccupancy"
                    value={subMarketOccupancy}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setSubMarketOccupancy(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>   

                </GridContainer>}
   


              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Property Occupancy"
                    id="property-name"
                    value={propertyOccupancy}
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setPropertyOccupancy(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Market Vacancy"
                    id="last-name"
                    value={marketVaccancy}
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setMarketVaccancy(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Year Built *"
                    id="property-name"
                    value={yearBuilt}
                    success={numberStateLimit === "success"}
                    error={numberStateLimit === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLengthLimit(event.target.value, 4)) {
                          setYearBuilt(event.target.value,0);
                          setnumberStateLimit("success");
                        } else {
                          setnumberStateLimit("error");
                          // setYearBuilt("");
                        }
                        setLimit(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Sub Market Vacancy"
                    id="last-name"
                    value={subMarketVaccancy}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setSubMarketVaccancy(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                     
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              {<Autocomplete
                     success={requiredFundNme === "success"}
                     error={requiredFundNme === "error"}
                  onChange={(event, newValue) => {
                    selectedFund(newValue);
                  }}
                  inputProps={{
                    onChange: value => {
                      if (verifyLength(value, 1)) {
                        setFundId(value)
                        setrequiredFundNme("success");
                      } else {
                        setrequiredFundNme("error");
                        setFundId("");
                      }
                      setrequiredFundNme(value);
                    },
                    type: "text",
                    endAdornment:
                   
                    requiredFundNme === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      )
                  }}
        {...allFundProps}
        id="fundId"
        debug
        size='medium'
        renderInput={(params) => <TextField success={requiredFundNme === "success"}
        error={requiredFundNme === "error"} {...params} defaultValue={labelFundName} onChange={({ target }) => setInput(target.value)} label={labelFundName} margin="normal" />}
      /> }
              



                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                           success={requiredAssetMgrNme === "success"}
                           error={requiredAssetMgrNme === "error"}    
                    onChange={(event, newValue) => {
                    // handleChange(newValue);
                    selectedPropertyManager(newValue);
                  }}
                  inputProps={{
                    onChange: value => {
                      if (verifyLength(value, 1)) {
                        setAssetManagerId(value)
                        setrequiredAssetMgrNme("success");
                      } else {
                        setrequiredAssetMgrNme("error");
                        setAssetManagerId("");
                      }
                      setrequiredAssetMgrNme(value);
                    },
                    type: "text",
                    endAdornment:
                   
                    requiredAssetMgrNme === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      )
                  }}
                  
                  {...allAssetManagerProps}
        id="assetManagerId"
        debug
        size='medium'
        renderInput={(params) => <TextField success={requiredAssetMgrNme === "success"}
        error={requiredAssetMgrNme === "error"} {...params}  defaultValue={labelAssetManagerName} onChange={({ target }) => setInput(target.value)} label={labelAssetManagerName} margin="normal" />}
        
              /> }
                {/* {<Autocomplete
                            
                    onChange={(event, newValue) => {
                    // handleChange(newValue);
                    selectedPropertyManager(newValue);
                  }}
                 
                  
                  {...allAssetManagerProps}
        id="assetManagerId"
        debug
        size='medium'

        renderInput={(params) => <TextField {...params}   defaultValue={labelAssetManagerName}
        onChange={({ target }) => setInput(target.value)} label={labelAssetManagerName} margin="normal" />}
        
       // renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Asset Manager" margin="normal" />}
        
              /> } */}
              
      </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Property Sqft New"
                    value={propertySqftNew}
                    id="property-sqft-new"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setPropertySqftNew(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="RBA"
                    value={rba}
                    id="rba"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setRba(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "number",
                      
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

<br></br>
<br></br>

<GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        
      </div>

 
</GridItem>
<div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>     
                     <div>              
                     <SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Property Edited Successfully!
</SweetAlert>
</div>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );

}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}
PropertyEdit.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertySaveRequest: PropTypes.func.isRequired,
  tmFundListRequest: PropTypes.func.isRequired,
  tmFundListWithUserRequest: PropTypes.func.isRequired,
  tmPropertyEditRequest: PropTypes.func.isRequired,
  tmPropertyByIdRequest: PropTypes.func.isRequired,
  tmAssetManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  
  // fundList: state.tenantmonitoring.fundList,
  propertyEditObj: state.tenantmonitoring.propertyEditObj,
  loading: state.tenantmonitoring.loading,
  assetManagerList: state.tenantmonitoring.assetManagerList,
  fundListWithUser:state.tenantmonitoring.fundListWithUser,
 // propertyId:state.tenantmonitoring.propertyId,
  //propertyEdit: state.tenantmonitoring.propertyEdit,
  //property: state.tenantmonitoring.property,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyEdit);