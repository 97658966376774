import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import moment from 'moment';
//import CreditReport from "components/CreditReport/CreditReport.js";
import DerogatoryReport from "components/DerogatoryReport/DerogatoryReport.js";
import { BrowserRouter, Route } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Badge from "components/Badge/Badge.js";
//import Link from '@material-ui/core/Link';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';

import CreditReportHeatMap from "components/Charts/CreditReportHeatMap.js";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import TMHeatMapPropertyTenant from "components/Charts/TMHeatMapPropertyTenant.js";

import DerogatoryHeatMap from 'components/Charts/DerogatoryReportHeatMap.js';
import PirsReportHeatMap from 'components/Charts/PirsReportHeatMap.js';


import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
import TMHeatMapGridViewPropertyTenant from "./TMHeatMapGridViewPropertyTenant";
 
const TMHeatMapGridTenantRisk = ({user,labelStatus,tenantName,tmTenantsOverAlCredPirsDeroScoreRequest,tenantsOverAlCredPirsDeroScore,tenantId,source,fundId,fundName,propertyId,propertyName,category,selectedRangeFrom,selectedRangeTo,month,year}) =>{
  const useStyles = makeStyles(styles);
  const history = useHistory();
  const classes = useStyles(); 
    const [scoreTypeValue, setScoreTypeValue] = React.useState(34);
    const [monthValue, setMonthValue] = React.useState(45);
    const [pirsEnabled, setPirsEnabled] = React.useState(false);
    const [scoreLabels, setScoreLabels] = React.useState(true);
    const[showBackNavigation,setShowBackNavigation]=React.useState(false);
    const [userId, setUserId] = React.useState(user.id);
    const [showPirsHeatMap, setShowPirsHeatMap] = React.useState(false);
    const [showDerogatoryHeatMap, setShowDerogatoryHeatMap] = React.useState(false);
    const [showCreditReportHeatMap, setShowCreditReportHeatMap] = React.useState(false);
    
    // const [selectedMonth, setSelectedMonth] = React.useState(month);

    const [monthNameValue, setMonthNameValue] = React.useState("");
   
    const [monthNameValue1, setMonthNameValue1] = React.useState(moment().format('MMM').toLowerCase());
    const [scoreCategoryValue, setScoreCategoryValue] = React.useState("");

    //const [monthNumber, setMonthNumber] = React.useState("");
    const [scoreType, setSetscoreType] = React.useState("");
    const [year1, setYear1] = React.useState(moment().format('YYYY'));

    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);

   var now = moment();
   var monthArray=[];
   var yearArray=[];
  
   var currMonthName  =  moment().subtract(1, "month").format('MMMM').toUpperCase();
   var currYear  = moment().format('YYYY');
   var prev1MonthName  = moment().subtract(2, "month").format('MMMM').toUpperCase();
   var prev1Year  = moment().subtract(1, "month").format('YYYY').toLowerCase();
   var prev2MonthName  = moment().subtract(3, "month").format('MMMM').toUpperCase();
   var prev2Year  = moment().subtract(2, "month").format('YYYY').toLowerCase();
   var prev3MonthName  = moment().subtract(4, "month").format('MMMM').toUpperCase();;
   var prev3Year  = moment().subtract(3, "month").format('YYYY').toLowerCase();
   var prev4MonthName  = moment().subtract(5, "month").format('MMMM').toUpperCase();;
   var prev4Year  = moment().subtract(4, "month").format('YYYY').toLowerCase();
   var prev5MonthName  = moment().subtract(6, "month").format('MMMM').toUpperCase();;
   var prev5Year  = moment().subtract(5, "month").format('YYYY').toLowerCase();
  




    const getTmDashboardTenantScoreBreakUp = async (tenantId,month,year,userId) => {
        await tmTenantsOverAlCredPirsDeroScoreRequest(tenantId,month,year,userId);
     
      
      };
      const drillDown = (y,x) => {
  
        setPirsEnabled(true);
    
       setScoreTypeValue(x);
       setMonthValue(y);
        if(y==3){
          setScoreTypeValue("PIRS");
            setPirsEnabled(true);
        }else if(y==2){
      
            setScoreTypeValue("CREDIT");
        }else if(y==3){
         
            setScoreTypeValue("DEROGATORY") ;
        }else{
            console.log("No type");
        }


      };

      function handleClickBack(event) {


        setShowBackNavigation(true);
      }
       function handleClickFundDashboard(event) {
         
       // setShowBackNavigation(true);
        history.push({ 
          pathname: '/dashboard'
         });
        
       }


    React.useEffect(() => {

 

    //   if(source == "property"){
    
        setScoreLabels(false);
       //}
     
        getTmDashboardTenantScoreBreakUp(tenantId,monthNameValue1,year1,userId);
    
  
      }, [monthValue,scoreTypeValue]);

     



    const chartOptions = {

      
  

     series:tenantsOverAlCredPirsDeroScore,
      options: {

        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 300, 
              
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                     position: 'top',
                      labels: {
                          position: 'top',
                          style: {
                                    fontSize: '16px',
                                    fontWeight: 700,
                                  }
                                }
                              },
        chart: {
          height: 350,
          type: ' ',
          events: {
           
            click: (event, chartContext, config) => {
           
            
              setPirsEnabled(true);
             
           
              var el = event.target;
              var seriesIndex = parseInt(el.getAttribute("i"));
              var dataPointIndex = parseInt(el.getAttribute("j"));
              var seriesName = config.globals.seriesNames[seriesIndex];

              var scoreCategory;
              var monthNameVar;
            
             

       if(dataPointIndex==0){
     
        setMonthNameValue(prev5MonthName);
        setYear1(prev5Year);
        setTimeout(() => {
 
        }, 3000);
        
      }else if(dataPointIndex==1){
        setMonthNameValue(prev4MonthName);
        setYear1(prev4Year);
        
      }else if(dataPointIndex==2){
        setMonthNameValue(prev3MonthName);
        setYear1(prev3Year);
        setTimeout(() => {
 
        }, 3000);
        
      }else if(dataPointIndex==3){
        setMonthNameValue(prev2MonthName);
        setYear1(prev2Year);
        setTimeout(() => {
 
        }, 3000);
      }else if(dataPointIndex==4){
        setMonthNameValue(prev1MonthName);
        setYear1(prev1Year);
        setTimeout(() => {
 
        }, 3000);
        
      }else if(dataPointIndex==5){
        setMonthNameValue(currMonthName);
        setYear1(currYear);
        setTimeout(() => {
 
        }, 3000);
      }
      
      else{
          console.log("No type");
      }


      if(seriesName=="Pandemic Risk Score"){
      
        scoreCategory="PIRS";
        setShowPirsHeatMap(true);
        setShowDerogatoryHeatMap(false);
        setShowCreditReportHeatMap(false);
       
  
    }else if(seriesName=="Credit Score"){
    
        scoreCategory="CREDIT";
        setShowPirsHeatMap(false);
        setShowDerogatoryHeatMap(false);
        setShowCreditReportHeatMap(true) ;
    
        setTimeout(() => {
 
        }, 3000);
  
    }else if(seriesName=="Derogatory Public Filings"){
        scoreCategory="DEROGATORY";
        setShowPirsHeatMap(false);
        setShowDerogatoryHeatMap(true);
        setShowCreditReportHeatMap(false); 
        setTimeout(() => {
 
        }, 3000);
       
    }else{
        console.log("No type");
    }
      
            } 
          },
          series: [
            {
              name: "Series 1",
              data: [{
                x: 'Jan',
                y: 212
              }, {
                x: 'Feb',
                y: 290
              }, {
                x: 'Mar',
                y: 83
              }, {
                x: 'Apr',
                y: 320
              }]
            },
            {
              name: "Series 2",
              data: [{
                x: 'Jan',
                y: 430
              }, {
                x: 'Feb',
                y: 413
              }, {
                x: 'Mar',
                y: 45
              }, {
                x: 'Apr',
                y: 350
              }]
            }
          ]
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 401,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 301,
                  to: 400,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 201,
                  to: 300,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 101,
                  to: 200,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 0,
                  to: 100,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
            showForNullSeries: true,
            show : false,
            // formatter: function(seriesName, opts) {
            //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
            // }
          },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
              
              if(val == 0){
            //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
             return "NA";      
        }else
              return val;
          }
        },
        stroke: {
          width: 1
        },
        title: {
          text: ''
        },
      },
    
    
    };
 

    return (
      
      
<div>

{!showBackNavigation&&!showCreditReportHeatMap&&!showDerogatoryHeatMap&&!showPirsHeatMap&&
<GridContainer >


<GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>



   <Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> <ArrowBackIosSharpIcon fontSize="inherit" className={classes.icon} />
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>


</GridItem>


         
<GridItem xs={12} sm={12} md={12}>
<h3 style={{ color: megaColor,paddingLeft:'40px' }}> <b>{tenantName}&nbsp;&nbsp;&nbsp;&nbsp;TenantTracker&trade; Score & Components by Month</b></h3>
</GridItem>
<GridItem xs={12} sm={5} md={3}>
     
</GridItem>

<GridItem xs={12} sm={7} md={9} className={classes.vertical} style={{paddingRight:"215px",textAlign:"right"}} >
      
       <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
       <Button color="transparent" size="sm" className={classes.firstButton}>
       <b><h7 style={{color:'red'}}>High Risk</h7></b>
       </Button>

       <Button color="transparent" size="sm" className={classes.middleButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent" size="sm" className={classes.middleButton}>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
       </Button>
     </div>
     <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
       <Button color="danger" size="sm" className={classes.firstButton}>
         <b>0 - 100 </b>
       </Button>
       <Button color="warning" size="sm" className={classes.middleButton}>
       <b>101 - 200 </b> 
       </Button>
       <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
       <b>201 - 300 </b>
       </Button>
       <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
       <b>301 - 400 </b>
       </Button>
       <Button color="success"  size="sm" className={classes.lastButton}>
       <b>401 - 500 </b>
       </Button>
     </div>
    

</GridItem>



<GridItem xs={12} sm={12} md={12} align="center">
<div id="chart">
<Chart  options={chartOptions.options} series={chartOptions.series} type="heatmap" height={300} width={1200} />
</div>
    


</GridItem>
</GridContainer>
}

 {showBackNavigation&& <TMHeatMapGridViewPropertyTenant fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} tenantId ={tenantId} tenantName={tenantName} month={month} year={year} category={category} backNavigation={true}/>}

 {showCreditReportHeatMap && <CreditReportHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} month={monthNameValue} year={year1} category={category} fundId={fundId} fundName={fundName} scoreType={"CREDIT"}/>}

 {showDerogatoryHeatMap && <DerogatoryHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} month={monthNameValue} year={year1} category={category} fundId={fundId} fundName={fundName} scoreType={"DEROGATORY"}/> }

 {showPirsHeatMap && <PirsReportHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} month={monthNameValue} year={year1} category={category} fundId={fundId} fundName={fundName} scoreType="PIRS"/> }

</div>
    )

}

TMHeatMapGridTenantRisk.propTypes = {

    loading: PropTypes.bool.isRequired,
    tmTenantsOverAlCredPirsDeroScoreRequest: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tenantsOverAlCredPirsDeroScore: state.tenantmonitoring.tenantsOverAlCredPirsDeroScore,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TMHeatMapGridTenantRisk);
  
  