/*eslint-disable*/
import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon  from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import image from "assets/img/bg17.jpg";
import logo3 from "assets/img/logo3.png";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AuthActions } from '../../store/ducks/auth';
import { useHistory } from "react-router-dom";

import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ChangePassword from './ChangePassword';
import {
  whiteColor,
   hexToRgb,
   megaColor,
   lyticsColor
 } from "assets/jss/material-dashboard-pro-react.js";


import Close from "@material-ui/icons/Close";
 import SweetAlert from "react-bootstrap-sweetalert";

const style = {
  imagesStyles,
  loginPageStyle,
  textMuted: {
    color: "#6c757d"
  }
};

const useStyles = makeStyles(loginPageStyle,imagesStyles);


const ForgotPassword = ({ tmGetUserByLoginAndEmailRequest,userByLoginAndEmail,user }) => {

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');


  const [requiredLogin, setRequiredLogin] =React.useState("");
  const [requiredEmail,setRequiredEmail] = React.useState("");

  const [alert,setAlert] = React.useState(false);
  const hideAlert = () => {
    setAlert(false);
  }


  // const[userId,setUserId] = useState('');
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [showIndex, setShowIndex] = React.useState(false);
  const[id,setId]=React.useState("");
  //const [loggedIn,setLoggedIn]

  React.useEffect(() => {
    // getUserByLoginAndEmail(username,email);
   
  },[]);


  const getUserByLoginAndEmail = async (login,email) => {
   
    await tmGetUserByLoginAndEmailRequest("login","email");
    // console.log("username"+JSON.stringify(login));
     //console.log("login"+JSON.stringify(login));
    
    };

 
 

//   const handleSubmit = (e) => {
//     e.preventDefault();
   
//  signInRequest(email, password); 
//  loggedInUserRequest(email);
//  history.push('/');
//   };



  const [open, setOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClickOpenChangePassword = () => {

    if(verifyLength(login,1)&&verifyLength(email,1)){

    setShowChangePassword(true);
    // history.push( setAlert1(true),{ 
 
    //   pathname: '/',
    //   // state:{ message:"Fund Saved Successfully" }
    //  });
    
    }
     
        else{
        
          setAlert(true);
          //setAlert2(true);
       
        if (requiredLogin === "") {
          setRequiredLogin("error");
         
        }
        if (requiredEmail === "") {
          setRequiredEmail("error");
         
        }
  }
  };
   
 
  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  

  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
   
  };
  const handleCancel=(e)=>{
    e.preventDefault();
    history.push('/');  
}

// console.log("username"+JSON.stringify(username));
// console.log("email"+JSON.stringify(email));
  const classes = useStyles();
  return (
    <div>
     
      
        <div className={classes.container}>
        
          <GridContainer justify="center" paddingTop="50px">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
              {/* onSubmit={handleSubmit}    */}

           
                <form className={classes.form} >
              

                  <CardHeader
                    color="white"
                    signup
                    image
                    className={classes.cardHeader}
                  >

                  </CardHeader>      
                          
                  <CardBody>
                    <br></br>
                    <br></br>
                    <br></br>
                  <GridItem paddingTop="100px"  align="center"  >
               <text style={{fontSize:"30px", color:megaColor, fontWeight:"bold"}}  >FORGOT PASSWORD ? </text>
               </GridItem>
               <br></br>
               <br></br>
<br></br>
<CustomInput
                    labelText="UserName *"
                    id="login"
                    // onChange={handlePasswordChange}
                    success={requiredLogin === "success"}
                    error={requiredLogin === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                    
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setLogin(event.target.value),
                          setRequiredLogin("success");
                        } else {
                          setRequiredLogin("error");
                          setLogin("");
                        }
                        setLogin(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                      requiredLogin === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
               
               <CustomInput
                    labelText="Email*"
                    id="email"
                    // onChange={handlePasswordChange}
                    success={requiredEmail === "success"}
                    error={requiredEmail === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                    
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setEmail(event.target.value),
                          setRequiredEmail("success");
                        } else {
                          setRequiredEmail("error");
                          setEmail("");
                        }
                        setEmail(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                      requiredEmail === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                   
                  </CardBody>
                  {/* <div align="center" >
                 
                  <Button  type="submit" size="sm"  color="info" style={{ fontSize:"17px" , fontWeight:"bold"}} onClick={handleClickOpenChangePassword}>Next</Button> */}
                   <div className={classes.textCenter}>
                    <Button color="info" size="sm" style={{ fontSize:"16px" , fontWeight:"bold"}} onClick={handleClickOpenChangePassword}>
        
 
                    Next
                   
                    </Button>
                    <Button color="info" size="sm" style={{ fontSize:"15px" , fontWeight:"bold"}} onClick={handleCancel} >
                      Cancel
       
        </Button>
                  
                  <GridItem >
               
               <Dialog fullScreen
maxWidth={'lg'} open={showChangePassword} onClose={handleCloseChangePassword}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
     
<GridItem paddingLeft='30px' align="justify">
  
{showChangePassword && <ChangePassword login={login} email={email} />} </GridItem>
          </Dialog>
               </GridItem>
                 
                 
                  </div>
                 
                </form>
              </Card>
            </GridItem>
            <div>
  
  <SweetAlert
              error
            show={alert}
            style={{ display: "block", marginTop: "-100px" }}
             onConfirm={() => hideAlert()}
             confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
             confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: "#328da8"}}
            // onCancel={() => hideAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.error
            }
          >
            Please fill all mandatory fields
          </SweetAlert>
        
                       </div>  
            
          </GridContainer>
        </div>
     
      </div>
   
  );
}


ForgotPassword.propTypes = {
    tmGetUserByLoginAndEmailRequest: PropTypes.func.isRequired,
  // loggedInUserRequest: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    loading: state.tenantmonitoring.loading,
    // fundList: state.tenantmonitoring.fundList,
    userByLoginAndEmail:state.tenantmonitoring.userByLoginAndEmail,
  
    
  });    

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ForgotPassword);
