import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import api from '../../services/api';

import { Creators as AuthActions } from '../ducks/auth';
import { Creators as ErrorActions } from '../ducks/error';
import { useHistory } from "react-router-dom";


export function* signIn(action) {

 //let history = useHistory();


  const { email, password } = action.payload;
  const user_object = {
    username: email,
    password: password,
    rememberMe:false
  };


  try {

    
    const { data } = yield call(api.post, '/api/authenticate', user_object);
 
    if (data.id_token) {
      localStorage.setItem('@DeliveryApp:token', data.id_token);
     
     
      const authData = {
        user: {
          username: email,
          email: email,
        },
        token: data.id_token,
      };
      localStorage.setItem('@DeliveryApp:user', JSON.stringify(authData.user)); 
     
      yield put(push('/admin/dashboard'));
      
    yield put(AuthActions.signInSuccess(authData));
      
     
     
    // history.push('/');
    
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Failure',
          message: 'Login Failed',
        }),
      );
    }
  } catch (err) {
   
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Login Failed',
        message: 'verify Username/Password',
        // position: 'center'
      }),
    );
    yield put(ErrorActions.setError(err));
  }
  
  // yield put(push('/admin/dashboard'));
}

export function* logout() {
  localStorage.removeItem('@DeliveryApp:token');
  localStorage.removeItem('@DeliveryApp:user');
  localStorage.removeItem('@DeliveryApp:selectedOrgId');
  localStorage.removeItem('@DeliveryApp:selectedArrayItemsCompany');
  localStorage.removeItem('@DeliveryApp:selectedArrayItemsProperty');
  localStorage.removeItem('@DeliveryApp:searchedParamsFromCompany');
  localStorage.removeItem('@DeliveryApp:searchedParamsFromProperty');
  yield put(push('/login'));
}

export function* loggeduser(action) {
 
  const { username } = action.payload;
  //const { username } = 'tester';

  
  
  try{

  const loggeduserObject  = yield call(api.get,'/api/account');
  console.log("UserObject...................."+loggeduserObject.data);
  
  const authData = {
    user: {

      id:loggeduserObject.data.id,
      organizationId:loggeduserObject.data.organizationId,

      username: loggeduserObject.data.login,
      email: loggeduserObject.data.username,
      roles: loggeduserObject.data.authorities,
      mainRole: loggeduserObject.data.mainRole,
      firstName: loggeduserObject.data.firstName,
      lastName: loggeduserObject.data.lastName,
      organizationName:loggeduserObject.data.organizationName
    }
  };
  localStorage.setItem('@DeliveryApp:user', JSON.stringify(authData.user));

  yield put(AuthActions.signInSuccess(authData));

}catch (err) {
    // yield put(
    //   toastrActions.add({
    //     type: 'error',
    //     title: 'Login Failed',
    //     message: 'verify Username/Password',
    //   }),
    // );
}
}
