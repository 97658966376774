import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBase from 'components/MenuBase/SideMenuBase.js';
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import ReactPaginate from 'react-paginate';
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";

import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
//import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import Paginations from "components/Pagination/Pagination.js";

import Pagination from '@material-ui/lab/Pagination';
import { getFundDashboardCount } from "store/sagas/tenantmonitoring";

import { useLocation } from "react-router-dom";
 

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "variables/charts.js";
  //const useStyles = makeStyles(styles);


  const useStyles = makeStyles({
    root: {
      width:"400px",
      height:"350px",
      backgroundColor:"#ffffff",
      //display: 'inline-block'
   
    },
    
    title: {
      fontSize: 40,
      color:megaColor,
      textAlign:"center",
      paddingTop:"100px"
    },
    pos: {
    //textAlign:"right",
    backgroundColor:"#64bf6a",
    color:"#ffffff",
    fontSize:"5px",
    marginLeft:"60px",
    marginTop:"10px",
    paddingRight:"15px",
    paddingLeft:"15px",
     paddingTop:"0px",
   paddingBottom:"0px"
    },
   
  });
  


const TMAMNoDashboardConfigured = ({user,loading }) =>{

const history = useHistory();
const location = useLocation();
const[dynamicHeight,setDynamicHeight]= React.useState("");

const[showHeatMapFundRisk,setShowHeatMapFundRisk]= React.useState(false);

const [selectedFundId, setSelectedFundId] = React.useState("");
const [selectedFundName, setSelectedFundName] = React.useState("");
const [showHeatMap, setShowHeatMap] = React.useState(true);

const [dataStatus, setDataStatus] = React.useState(false);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
//const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

const [pageSize, setPageSize] = React.useState(10);
const [pageNum, setPageNum] = React.useState(0);
const [totalCount, setTotalCount] = React.useState("");
const [page, setPage] = React.useState(1);
const [userId, setUserId] = React.useState(user.id);



const classes = useStyles(); 


// React.useEffect(() => {
    

 
 
//   }, []);
  

  const enableFund = (value) => {
    setSelectedFundId(value.fundId); 
    setSelectedFundName(value.fundName);
    setShowHeatMapFundRisk(true);
    setShowHeatMap(false);
  }

    // React.useEffect(() => {
 
    //   },[]);

     
       

       
    
    const goback=()=>{
      //  history.push("/admin/dashboard");
      history.back();
    } 
 
   // getTmDashboard(organizationId);


 if(dataStatus){
    return (

 

<Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          No Dashboard Configured
        </Typography>
        
      </CardContent>
    
    </Card>

       
    );
     



    
}else{

return(
  <div align="center">
  <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={2000} />
  </div>
)

}
}


TMAMNoDashboardConfigured.propTypes = {
    tmNotificationList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        contifyId: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmDashboardRequest: PropTypes.func.isRequired,
   // tmFundByOrgIdRequest: PropTypes.func.isRequired,
    tmFundDashboardCountRequest: PropTypes.func.isRequired,
    tmFundDashboardGridViewRequest: PropTypes.func.isRequired,

    
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tmDashboardFund: state.tenantmonitoring.tmDashboardFund,
    fundsByOrg: state.tenantmonitoring.fundsByOrg,
    fundDasboardCount: state.tenantmonitoring.fundDasboardCount,
    fundDashboarGridView: state.tenantmonitoring.fundDashboarGridView,



    
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TMAMNoDashboardConfigured);
