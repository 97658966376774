
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Assignment from "@material-ui/icons/Assignment";
import AddAlert from "@material-ui/icons/AddAlert";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';
import BTTable from "./BTTable";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check"
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor,
    infoColor,
  } from "assets/jss/material-dashboard-pro-react.js";
import BaseLineReport from "./BaseLineReport";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];










// const Liensreport = ({tmActiveLiensJudgmentDetailsRequest,liensJudgmentDetailsDTO,tmActiveLiensDetailsRequest,liensList}) => {
  const ParentBankruptcy = ({tmGetBankoReportByTenantRequest,bankoReportList,tmCreditSafeBankruptcyReportByParentIdRequest,creditSafeParentBankruptcyReport,tmGetTenantPropertyDetailsRequest,tenantPropertyDetails

    }) => {

  const [input, setInput] = React.useState('');
 // const [dataList, setDataList] = React.useState(tenantFailureStatusLogListByOrg);

  
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");
const [messageobj, setMessageobj] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const[failureCount,setFailureCount] = React.useState("");

const[id,setId] = React.useState("");
const [parentId,setParentId] = React.useState(location.state.parentId);
const[type,setType] = React.useState("");

const [showBaseLineReport, setShowBaseLineReport] = React.useState(false);
const [dataStatus, setDataStatus] = React.useState(false);
const [parentName,setParentName] = React.useState(location.state.parentName);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  const[filedDate,setFiledDate] = React.useState("");
 const[filingType,setFilingType] = React.useState("");
 const[actionType,setActionType] = React.useState("");
 const[status,setStatus] = React.useState("");
 const[caseName,setCaseName] = React.useState("");
 const[socialSecurityNumber,setSocialSecurityNumber] = React.useState(location.state.socialSecurityNumber);
 const[courtName,setCourtName] = React.useState("");
 const[debtorsCity,setDebtorsCity] = React.useState(location.state.debtorsCity);
 const[debtorsState,setDebtorsState] = React.useState(location.state.debtorsState);
 const[attorney,setAttorney] = React.useState("");
 const[attorneyStreetAddress,setAttorneyStreetAddress] = React.useState("");
 const[attorneyZip,setAttorneyZip] = React.useState("");
 const[attorneyState,setAttorneyState] = React.useState("");
 const[attorneyCity,setAttorneyCity] = React.useState("");
 const[attorneyPhone,setAttorneyPhone] = React.useState("");
 const[lawFirm,setLawFirm] = React.useState("");
 const[courtDistrict,setCourtDistrict] = React.useState("");
 const[courtAddress,setCourtAddress] = React.useState("");
 const[courtTelephone,setCourtTelephone] = React.useState("");
 const[judgesInitials,setJudgesInitials] = React.useState("");
 
 const [alert,setAlert] = React.useState(false);

 const [showNoDataFound, setShowNoDataFound] = React.useState(true);
 const [showDataFound, setShowDataFound] = React.useState(true);

   const [tenantAddress, setTenantAddress] = React.useState("");
   const [city, setCity] = React.useState("");
     const [state, setState] = React.useState("");
     const [zip, setZip] = React.useState("");
     const[propertyName,setPropertyName]=React.useState("");
     const [year,setYear] = React.useState(moment().format('YYYY').toLowerCase());
     const [month1, setMonth1] = React.useState(moment().subtract(1, "month").format('MMM').toLowerCase());

     const[selectedFundId,setSelectedFundId]=React.useState("");
     const[selectedFundName,setSelectedFundName]=React.useState("");
     const[propertyId,setPropertyId]=React.useState();
     const[imageUrl,setImageUrl]=React.useState("");
     const[currentStatus,setCurrentStatus]=React.useState("");
     const[colorCode,setColorCode]=React.useState("");
     const[propertyAddress,setPropertyAddress]=React.useState("");
     const[score,setScore]=React.useState("");
     const[userId,setUserId]=React.useState("");
 const history = useHistory();

 const hideAlert = () => {
  setAlert(false);
  history.push({ 
    pathname: '/admin/monitored-tenants-by-fund',
    // state: {requestFrom:"fund"}
   });

}
 
React.useEffect(() => {


  if(creditSafeParentBankruptcyReport.length>0){
    // setShowCompanyName(false);
 
  setShowNoDataFound(false);
    setShowDataFound(true);
     
   }
 else{
   setShowNoDataFound(true);
    setShowDataFound(false); 
   // setAlert(true);
 
 }
   
 


  if(!dataStatus){
    // getTenantPropertyDetails(tenantId,month1,year);
    getCreditSafeParentBankoReportByTenant(parentId);
    // getBankoReportByTenant(tenantId);

//   getActiveLiensList(tenantId);
//   getActiveLiensJudgmentScore(tenantId);
//   getActiveJudgementList(tenantId);

  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus,creditSafeParentBankruptcyReport]);



//   const getBankoReportByTenant = async (tenantId)=>{
  
// await tmGetBankoReportByTenantRequest(tenantId)
//  //alert(bankoReportList);

//   }

  const getCreditSafeParentBankoReportByTenant = async (parentId)=>{
  
    await  tmCreditSafeBankruptcyReportByParentIdRequest(parentId)
     console.log("creditSafeParentBankruptcyReport"+JSON.stringify(creditSafeParentBankruptcyReport));
    
      }
    //   const getTenantPropertyDetails=async (tenantId,month,year) => {
    //     await tmGetTenantPropertyDetailsRequest(tenantId,month,year);
    //     setTenantAddress(tenantPropertyDetails.tenantAddress);
    //     setCity(tenantPropertyDetails.tenantCity);
    //     setState(tenantPropertyDetails.tenantState);
    //     setZip(tenantPropertyDetails.tenantZip);
    //     setPropertyName(tenantPropertyDetails.propertyName);
    //     setImageUrl(tenantPropertyDetails.imageUrl);
    //     setPropertyAddress(tenantPropertyDetails.propertyAddress);
    //     setCurrentStatus(tenantPropertyDetails.currentStatus);
    //     setScore(tenantPropertyDetails.propertyScore);
    //     setUserId(tenantPropertyDetails.userId);
    //     setPropertyId(tenantPropertyDetails.propertyId);
    //     setSelectedFundId(tenantPropertyDetails.fundId);
    //     setSelectedFundName(tenantPropertyDetails.fundName);
    //     setColorCode(tenantPropertyDetails.colorCode);
    //     console.log("tenantPropertyDetails"+JSON.stringify(tenantPropertyDetails));
    //     // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    //   }

const getBaseLineReport=(parentId,filedDate,filingType,actionType,status,caseName,judgesInitials,
  courtName,courtAddress,courtTelephone,lawFirm,attorney,attorneyStreetAddress,attorneyCity,
  attorneyState,attorneyZip,attorneyPhone)=>{
    setShowBaseLineReport(true);

       setParentId(parentId);
       setFiledDate(filedDate);
       setFilingType(filingType);
       setActionType(actionType);
       setStatus(status);
       setCaseName(caseName);
       setJudgesInitials(judgesInitials);
       setCourtName(courtName);
       setCourtAddress(courtAddress);
       setCourtTelephone(courtTelephone);
       setLawFirm(lawFirm);
       setAttorney(attorney);
       setAttorneyStreetAddress(attorneyStreetAddress);
       setAttorneyCity(attorneyCity);
       setAttorneyState(attorneyState);
       setAttorneyZip(attorneyZip);
       setAttorneyPhone(attorneyPhone);

}



  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
    
  };

  // const handleCloseLiensreport = () => {
  //   setShowLiensreport(false);
  // };

  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }

    // const handleClickOpenViewLog = () => {

    //   setShowViewLog(true);
    // };
  
    const handleCloseBaseLineReport = () => {
     
        setShowBaseLineReport(false);
       
          setShowDataFound(true);
       
    };
    // const enableTenant = () => {
      
    //   history.push({ 
    //     pathname: '/admin/monitored-tenant-risk',
    //     state:{tenantId:tenantId,userId:userId,tenantName:tenantName,city:city,state:state,zip:zip,tenantAddress:tenantAddress}
    //    });
    //   // <Redirect to="/property-details"/>
    
    // }
    // const enableProperty = () => {
  
    //   history.push({ 
    //     pathname: '/admin/monitored-tenants-property-details',
    //     state:{fundId:selectedFundId,fundName:selectedFundName,propertyName:propertyName,propertyId:propertyId,propertyAddress:propertyAddress,score:score,currentStatus:currentStatus,colorCode:colorCode,imageUrl:imageUrl,userId:userId}
    //    });
    //   // <Redirect to="/property-details"/>
    
    // }

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
  
    <GridContainer>
   
  
   <GridItem xs={12}>
  
   

    

            <GridContainer className={classes.gridContainer}>
            <GridContainer>
           
            <GridItem xs={12} sm={12} md={12}>
            <h3 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px",cursor:'pointer'}}>Parent Name: {parentName}</h3>
            {/* <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px" }}>{tenantAddress}&nbsp;{city}&nbsp;{state}&nbsp;{zip}</h4> */}
            {/* <h4 style={{color: megaColor, fontWeight:'bold',fontSize:"18px",cursor:'pointer'}} onClick={enableProperty}><b>Property Name:&nbsp;{propertyName}</b></h4> */}

            </GridItem>


{/* <GridItem xs={2}>
      <Button  color="info" round ><EmailOutlinedIcon /> Send SMS</Button>
</GridItem> */}

</GridContainer>
     
            <GridItem>
               
               <Dialog fullWidth={true} 
maxWidth={'md'} open={showBaseLineReport} onClose={handleCloseBaseLineReport}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseBaseLineReport}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>

<h4 style={{ color: megaColor,fontSize:'30px',paddingLeft:'30px',fontWeight:"bold" }}>{parentName}</h4>

<GridItem paddingLeft='100px'>

          {showBaseLineReport && <BaseLineReport  parentId ={parentId} filedDate={filedDate} filingType={filingType} 
          actionType={actionType} status={status} caseName={caseName} judgesInitials={judgesInitials} courtName={courtName}
          courtAddress={courtAddress} courtTelephone={courtTelephone} lawFirm={lawFirm} attorney={attorney}
          attorneyStreetAddress={attorneyStreetAddress} attorneyCity={attorneyCity}  attorneyState={ attorneyState} attorneyZip={attorneyZip}
          attorneyPhone={attorneyPhone}
       
        />}
        </GridItem>
       </Dialog>
               </GridItem> 
      
</GridContainer>


           
             
           

             

{showDataFound&&  <BTTable
           columns={[



            // {
            //     id: "checkbox",
            //     accessor: "",
            //     filterable: false,
            //     disableSortBy: true,
            //     width: 20,
            //     Cell: ( rowInfo ) => {
            //         return (
            //             <Checkbox
            //                 type="checkbox"
            //                 className="checkbox"
            //               // checked={this.state.selected[rowInfo.original.title.props.children] === true}
            //               //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
            //             />
            //         );
            //     },
            //     Header: title => {
            //         return (
            //             <Checkbox
            //                 type="checkbox"
            //                 className="checkbox"
            //                 // checked={this.state.selectAll === 1}
            //                 // ref={input => {
            //                 //     if (input) {
            //                 //         input.indeterminate = this.state.selectAll === 2;
            //                 //     }
            //                 // }}
            //                 // onChange={() => this.toggleSelectAll()}
            //             />
            //         );
            //     },
               
            // },
             
     
              //   {
              //     Header: "Date",
              //     accessor: data => {
              //       return Moment(data.bankoDate)
              //       .locale('en')
              //         .format(" MMM D, YYYY")
              //     }
        
              // },

              {
                Header: "Case",
                accessor: "caseName",
                width:50
              },
              {
                Header: "File Date",
                accessor: data => {
                  return Moment(data.filedDate)
                  .locale('en')
                    .format(" MMM D, YYYY")
                }
              },
                {
                  Header: "Filing Type",
                  accessor: "filingType",
                  align:"center",
                  width:"20"
                
                },
                {
                  Header: "Action Type",
                  accessor: "actionType",
                  align:"center",
                  width:"20"
                
                },

              
                
                // {
                //   Header: "Status date",
                //   accessor: data => {
                //     return Moment(data.statusDate)
                //     .locale('en')
                //       .format(" MMM D, YYYY")
                //   }
                //   },
  
                  {
                   
                 Header: "Actions",
               accessor: "actions",
  
           
        
    
                 sortable: false,
                 filterable: false,
                
                  Cell: ({ cell,row }) => (<Link to={{ state :{parentId : row.original.parentId,parentName : row.original.parentName, filedDate:row.original.filedDate} } } 
                    onClick={() => getBaseLineReport(row.original.parentId,row.original.filedDate,row.original.filingType,row.original.actionType,
                        row.original.status,row.original.caseName,row.original.judgesInitials,row.original.courtName,row.original.courtAddress,row.original.courtTelephone,row.original.lawFirm,row.original.attorney,
                        row.original.attorneyStreetAddress,row.original.attorneyCity,row.original.attorneyState,row.original.attorneyZip,
                       row.original.attorneyPhone)}
                   >Baseline Report</Link>),
  
    
              
                 
                },

           ]}
           data={creditSafeParentBankruptcyReport.map((prop, key) => {

            
             return {
               parentId: prop.id,
               parentName: prop.parentName,
               filedDate: prop.filedDate,
               filingType: prop.filingType,
               actionType:prop.actionType,
               status:prop.status,
               caseName:prop.caseName,
               judgesInitials:prop.judgesInitials,
               courtName:prop.courtName,
               courtAddress:prop.courtAddress,
               courtTelephone:prop.courtTelephone,
               lawFirm:prop.lawFirm,
               attorney:prop.attorney,
               attorneyStreetAddress:prop.attorneyStreetAddress,
               attorneyCity:prop.attorneyCity,
               attorneyState:prop.attorneyState,
               attorneyZip:prop.attorneyZip,
               attorneyPhone:prop.attorneyPhone

             
              //  actions:(
              //    <div>

              //     <Link to={{pathname:'/admin/view-log', state :{tenantId : prop.id, month:prop.month, year:prop.year} } }> 
              //         <Button
              //           size="sm" color="info"
              //       onClick={getFailureList}
              //      >
              //          View Log
              //        </Button>{" "}

                      
              //      </Link> 
                      
              //    </div>
              //  )
          
              
             };
           })}


     
           //  data={tenantUploadStatusList}

           getTdProps={(state, rowInfo, column, instance) => {
             // rowInfo contains a lot of information about the row (including index on both the
             // page and it's index within the data set), the rowInfo.original objet contains 
             // values in the cells of the row clicked.
             return {
               // You can define an onClick function here that will apply to all rows
               onClick: (e, handleOriginal) => {
     
                  const rowData = rowInfo.original
                  // You can interact with the row data here, to use it however you want
                  this.setState({month: rowData.month, year: rowData.year})
               }
         }}}
         
         />}
{/* <div>

<Dialog
           classes={{
             root: classes.center,
             paper: classes.modal
           }}
           open={showBaseLineReport}
           transition={Transition}
           keepMounted
           onClose={() => setShowBaseLineReport(false)}
           aria-labelledby="modal-slide-title"
           aria-describedby="modal-slide-description"
         >
           <DialogTitle
             id="classic-modal-slide-title"
             disableTypography
             className={classes.modalHeader}
           >
             <GridItem align="right">
             <Button
               justIcon
               className={classes.modalCloseButton}
               key="close"
               aria-label="Close"
               color="transparent"
               onClick={() => setShowBaseLineReport(false)}
             >
               <Close className={classes.modalClose} />
             </Button>
             </GridItem>
             <h4 ><b>{attorneyAddress}</b></h4>
           </DialogTitle>
           <DialogContent
             id="modal-slide-description"
             className={classes.modalBody}
           
           >
              
             <h5>Filed Date{fileDate}</h5>
             <h6>{statusDate} </h6>
           </DialogContent>
           <DialogActions
             className={classes.modalFooter + " " + classes.modalFooterCenter}
           >
             {/* <Button color="info" onClick={() => setShowBaseLineReport(false)}>Close</Button> */}
             
           {/* </DialogActions>
         </Dialog> */}

     
     </GridItem>

     {showNoDataFound&&<GridItem align="center" >
              <h3><b>No Bankruptcy Reported...</b></h3>
            </GridItem>
  }
     <br></br>
  <br></br>
  <br></br>
  <br></br>
     {/* <div>
           
 
           <SweetAlert
          info
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
          onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
         <b style={{fontSize:"20px"}}>No Bankruptcy Reported...</b> 
        </SweetAlert>
      
                     </div> */}
 </GridContainer>
 
);
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

ParentBankruptcy.propTypes = {
    organizationScoreExecutionLogList: PropTypes.arrayOf(
    PropTypes.shape({
      parentId: PropTypes.string,
   
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmGetBankoReportByTenantRequest: PropTypes.func.isRequired,
  tmCreditSafeBankruptcyReportByParentIdRequest: PropTypes.func.isRequired,
  tmGetTenantPropertyDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  bankoReportList: state.tenantmonitoring.bankoReportList,
  creditSafeParentBankruptcyReport: state.tenantmonitoring.creditSafeParentBankruptcyReport,
     loading: state.tenantmonitoring.loading,
     parentId: state.tenantmonitoring.parentId,
     tenantPropertyDetails: state.tenantmonitoring.tenantPropertyDetails,

});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentBankruptcy);


