



import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
 import {Redirect} from 'react-router-dom';

import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import { useLocation } from "react-router-dom";

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import FundList from 'views/Fund/FundList.js';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Heading from "components/Heading/Heading.js";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles,styles1);

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const Property = ({tmPropertySaveRequest,tmCompanyListRequest,companyList}) => {


  const location = useLocation();
  const [input, setInput] = React.useState('');
  const [dataList, setDataList] = React.useState(companyList);
  const [messageobj, setMessageobj] = React.useState("");

    // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");


const [propertyName,setPropertyName] =React.useState("");
const [city,setCity] =React.useState("");
const [address1,setAddress1] =React.useState("");
const [state,setState] =React.useState("");
const [zipCode,setZipCode] =React.useState("");
const [countyName,setCountyName] =React.useState("");
const [msa,setMsa] =React.useState("");
// const [buildingClass,setBuildingClass] =React.useState("");
const [rentableBuildingArea,setRentableBuildingArea] =React.useState("");
const [leedCertified,setLeedCertified] =React.useState("");
const [energyStar,setEnergyStar] =React.useState("");
const [tenancy,setTenancy] =React.useState("");
const [noOfParkingSpaces,setNoOfParkingSpaces] =React.useState("");
const [propertyOccupancy,setPropertyOccupancy] =React.useState("");
const [marketVaccancy,setMarketVaccancy] =React.useState("");
const [yearBuilt,setYearBuilt] =React.useState("");
const [subMarketVaccancy,setSubMarketVaccancy] =React.useState("");
const [fund,setFund] =React.useState("");
const [selectedOrganization,setSelectedOrganization] =React.useState("");


const [requestFrom, setRequestFrom] = React.useState("");



// check Box
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };



  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  useEffect(() => {
      
    getCompanyList();

    if(location.state){
       setRequestFrom(location.state.requestFrom);
      console.log("setRequestFrom"+JSON.stringify(setRequestFrom));
      
      // alert("usereffect st::"+organisationId);
      setMessageobj(location.state.message);
    }
    
 
  }, [messageobj]);
  const getCompanyList = async () => {
    await tmCompanyListRequest();
    
    };

  const allCompanyProps = {
  
    options: companyList,
    getOptionLabel: (option) => option.name,
  };

  
  const handleChange = (value) => {
   
  };
  const selectedOrg = (value) => {
   

   localStorage.removeItem('@DeliveryApp:selectedOrgId');
   localStorage.removeItem('@DeliveryApp:selectedOrgName');

   localStorage.setItem('@DeliveryApp:selectedOrgId', JSON.stringify(value.id));
   localStorage.setItem('@DeliveryApp:selectedOrgName', JSON.stringify(value.name));
   if(value!=null){
   setSelectedOrganization(value.name);
   }
  
  };


  const submitForm = () => {
    const property={
      propertyName:propertyName,
      city:city,
      address1:address1,
      state:state,
      zipCode:zipCode,
      countyName:countyName,
      msa:msa,
      // buildingClass:buildingClass,
      rentableBuildingArea:rentableBuildingArea,
      leedCertified:leedCertified,
      energyStar:energyStar,
      noOfParkingSpaces:noOfParkingSpaces,
      tenancy:tenancy,
      propertyOccupancy:propertyOccupancy,
      marketVaccancy:marketVaccancy,
      yearBuilt:yearBuilt,
      subMarketVaccancy:subMarketVaccancy


    }
    tmPropertySaveRequest(property);

  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

const [open, setOpen] = React.useState(true);


// const [fromProperty,setFromProperty] = React.useState(false);
// const [fromTenant, setFromTenant] = React.useState(false);

// const selectFromFund = () => {
//   if(setFromFund(true)){
//   history.push({
//     pathname: '/admin/fundlist'})}
// };
console.log("requestFrom"+JSON.stringify(requestFrom));

const handleClose = () => {
 
  if(requestFrom === "fund"){
    
      history.goBack(requestFrom)}
  else if( requestFrom === "property"){

    
       
      history.goBack(requestFrom)}
         
          else if( requestFrom === "tenant"){
              
             
      history.goBack(requestFrom)}
      else if( requestFrom === "user"){         
        history.goBack(requestFrom)}

        else if( requestFrom === "createFund"){         
          history.goBack(requestFrom)}

          else if( requestFrom === "editFund"){         
            history.goBack(requestFrom)}
  
            else if( requestFrom === "createProperty"){         
              history.goBack(requestFrom)}

              else if( requestFrom === "editProperty"){         
                history.goBack(requestFrom)}
    
                else if( requestFrom === "createTenant"){         
                  history.goBack(requestFrom)}
      
                  else if( requestFrom === "editTenant"){         
                    history.goBack(requestFrom)}

                    else if( requestFrom === "createUser"){         
                      history.goBack(requestFrom)}

                      else if( requestFrom === "editUser"){         
                        history.goBack(requestFrom)}
        
                      
        
    history.push({
            pathname: '/admin/tenantlist'})

}
  


const handleClickOpen = () => {
  setOpen(true);
};


 
// };
const handleClose1 = () => {
  if(value!=null)
 
   setOpen(false);
  // if(verifyLength(selectedOrganization,0))
  // setOpen(false);
 
};


  const [value, setValue] = React.useState(null);
  const history = useHistory();
  const classes = useStyles(); 

  return (


    <div>
      {/* <OrgSelected orgName={selectedOrganization}/> */}


       <Dialog open={open} disableBackdropClick={true} fullWidth={true} disableEscapeKeyDown={true} onClose={handleClose1} aria-labelledby="form-dialog-title">
         <DialogTitle id="form-dialog-title" style={{"color":megaColor  }}>Select Organization</DialogTitle>
        <DialogContent>
          <div>
   
     
           
              <Autocomplete
              
                 onChange={(event, newValue) => {
                  selectedOrg(newValue);
                   }
                  }
                  
        {...allCompanyProps}
        id="companyId"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.id)} label="Organization" margin="normal" />}
       /> 


</div>
 
      {/* <br/>
      <br/>
      <br/> */}
        </DialogContent>
        <DialogActions>
      
        {/* <Button color="info"  className={classes.firstButton} onClick={submitForm}>
         Apply 
       </Button> */}
    {/* <button onClick={this.onSubmit}>Login</button> */}
          <Button onClick={handleClose} color="info" style={{fontSize:16}}>
            Select
          </Button>
          <Button onClick={() => history.push({
  pathname: '/admin/companylist'})} color="info" style={{fontSize:16}}>
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
       </div>
//     <div>
//       <form>
     
//               <GridContainer>
//               <GridItem xs={12} sm={12} md={6}>
//                 {<Autocomplete
//                   onChange={(event, newValue) => {
//                     selectedOrg(newValue);
//                   }}
//         {...allCompanyProps}
//         id="companyId"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Organization" margin="normal" />}
//       /> }




//      </GridItem>
// <GridItem xs={12} sm={12} md={12}>
     
//       <div className={classes.buttonGroup}>
//         <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm}>
//           Select 
//         </Button>
        
//       </div>

 
// </GridItem>
              
//               </GridContainer>

          

// <br></br>
// <br></br>


    
//       </form>
//     </div>
  );
}
Property.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertySaveRequest: PropTypes.func.isRequired,
  tmCompanyListRequest: PropTypes.func.isRequired,
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  companyList: state.tenantmonitoring.companyList
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Property);