import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Html from 'react-pdf-html';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import ReactPdfTable from "react-pdf-table";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  heading: {
    fontSize: 25,
    marginBottom: 20,
    textAlign: 'center',
    
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 80,
  
  },
  image1: {
    width:"130",
    height:"30", 
    
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerRow: {
    backgroundColor: "red",
    borderWidth: 0,
    fontSize: 8,
    textAlign: "right"
  },
  cellRightAlign: {
    borderWidth: 0,
    fontSize: 8,
    textAlign: "right"
  },
  inline: {
    display: "flex",
    flexDirection: "row"
  },
  inline1: {
    display: "flex",
    flexDirection: "row",
    width:"205",
    height:"52", 
    margin: 60,
    align: "left",
    width:"50%"
    
  },
  inline2: {
    display: "flex",
    flexDirection: "row",
    color: megaColor, 
    fontSize:13, 
    margin: 50,
    justifyContent:"right",
    width:"50%"
  }
});

const PdfOne = ({ tenantName,organisationName,legalName,legalFormOfEntity,entityType,primaryNaicsCode,city,state,address,postalCode,OFAC,
  creditScore,trailingDayScore,threeToSixmonthsPaymentScore,dbtScore,industryDbtScore,corporationFilingStatus,yearStarted,
  trailingDayCommentary,threeToSixmonthsPaymentCommentary,industryBenchmarkCommentary,trailingDayColorCode,threeToSixmonthsPaymentColorCode,
  creditScoreColorCode,primaryIndustry,websiteUrl,country,corporationFilingName,corporationFilingAddress,corporationFilingAddressType,filingDate,
  incorporationState,incorporationDate,nameType,registrationType,cautionaryUccScore,cautionaryUccCommentary,cautionaryUccColorCode,taxLiensAndJudgementScore,
  taxLiensAndJudgementCommentary,taxLiensAndJudgementColorCode,civilSuitsScore,civilSuitsCommentary,civilSuitsColorCode,corporationFilingScore,corporationFilingCommentary,
  corporationFilingScoreColorCode,yearsInBusinessCommentary,taxLienDetailList,judgementDetailList,liensCount,liensPlusJudgmentCount,judgmentCount,suitsCount,taxLiens,judgment,
  uccCount,totLiensAndJudgment

}) =>{
 
 
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const html = ` <html>
  <head> 
  <style>

  .card {
    width: 100%;
    border-radius: 15px;
    background-color: #fbfbff;
     border-style: solid;
     border-width: 4px 4px 4px 4px;
     border-radius: 15px;
     border-color: #f0f0f0;
  
  }
  
  .container {
    padding: 16px 16px 16px;
  }
  
    table {
      border-collapse: collapse;
      width: 100%;
      
    }
    
    th, td {
      padding: 6px;
      text-align: left;
      font-size: 12px;
    }
    tr {
      border-bottom: 1pt solid #E8E8E8;
      }
  </style>
  </head>
  <body>
  <div class="card">
  <div class="container">
    <table >
      <tr>
      <td>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${tenantName}</td>
      </tr>
      <tr>
      <td>LegalName &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${legalName}</td>
      </tr>
      <tr>
        <td>Primary NAICS Code</td>
        <td>${primaryNaicsCode}</td>
        <td>Entity Type</td>
        <td>${entityType}</td>
      </tr>
      <tr>
        <td>Year Started</td>
        <td>${yearStarted}</td>
        <td>Primary Industry</td>
        <td>${primaryIndustry}</td>
      </tr>
      <tr>
        <td>Website URL</td>
        <td>${websiteUrl}</td>
        <td>OFAC</td>
        <td>${OFAC}</td>
      </tr>
  
    </table>
    </div>
  </div>
  <br/>
  </body>
  </html> 
`;

const html1 = ` <html>
<head> 
<style>

.card {
  width: 100%;
  border-radius: 15px;
  background-color: #fbfbff;
   border-style: solid;
   border-width: 4px 4px 4px 4px;
   border-radius: 15px;
   border-color: #f0f0f0;

}

.container {
  padding: 16px 16px 16px;
}

  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  
  th, td {
    padding: 6px;
    text-align: left;
    font-size: 12px;
  }
  tr {
    border-bottom: 1pt solid #E8E8E8;
    }
</style>
</head>
<body>
<div class="card">
<div class="container">
  <table >
    <tr>
    <td>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${tenantName}</td>
    </tr>
    <tr>
    <td>Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${address}</td>
    </tr>
    <tr>
      <td>Country</td>
      <td>${country}</td>
      <td>State</td>
      <td>${state}</td>
    </tr>
    <tr>
      <td>City</td>
      <td>${city}</td>
      <td>Zip / Postal Code</td>
      <td>${postalCode}</td>
    </tr>
    
  </table>
  </div>
</div>
<br/>
</body>
</html> 
`;


const html2 = `<html>
<head>

<style>
.card {
  width: 100%;
  border-radius: 15px;
  background-color: #fbfbff;
   border-style: solid;
   border-width: 4px 4px 4px 4px;
   border-radius: 15px;
   border-color: #f0f0f0;

}

.container {
  padding: 16px 16px 16px;
}

  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  
  th, td {
    padding: 6px;
    text-align: left;
    font-size: 12px;
  }
  tr {
    border-bottom: 1pt solid #E8E8E8;
    }
</style>
</head>
<body>
<div class="card">
<div class="container">
  <table >
    <tr>
    <td>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${corporationFilingName}</td>
    
    </tr>
    <tr>
      <td>Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${corporationFilingAddress}</td>
   
    </tr>
    <tr>
    <td>Name Type</td>
    <td>${nameType}</td>
    <td>Address Type</td>
      <td>${corporationFilingAddressType}</td>
    </tr>
    <tr>
      <td>Status</td>
      <td>${corporationFilingStatus}</td>
      <td>Filing Date</td>
      <td>${filingDate}</td>
    </tr>
    <tr>
    <td>Incorporation State</td>
    <td>${incorporationState}</td>
    <td>Incorporation Date</td>
    <td>${incorporationDate}</td>
  </tr>
    <tr>
      <td>Registration Agent</td>
      <td>${registrationType}</td>
      <td></td>
      <td></td>
    </tr> 
  </table>
  </div>
</div>
<br/>
</body>
</html> 
`;

const html3 = `<html>
<head>

<style>
.card0 {
  width: 100%;
  border-radius: 15px;
  background-color: #fbfbff;
   border-style: solid;
   border-width: 4px 4px 4px 4px;
   border-radius: 15px;
   border-color: #f0f0f0;
   padding:0px;

}
.card {
  width: 80px;
  height: 20px;
   border-radius: 35px;
   background-color: ${creditScoreColorCode} ;
   text-align: center;
  padding-bottom: 50px;
  padding-top: 0px;
  align-item: center;
  font-size: 15px;
}


  .td1left, .th1 {
    border-right: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 5px;
    font-size: 11px;
  }

  .td1right {
    border-bottom: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td1center {
    border-bottom: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td1centerdbt {
    color: #4caf50; 
    text-align: center;
    padding-top: 18px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 15px;
  }

  .td1bottomleft {
    border-right: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 5px;
    font-size: 11px;
  }

  .td1bottomright {
    border-left: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }
  
  .h51 {
    font-size: 12px;
    line-height: 1.6;
  }
  .h52 {
    font-size: 12px;
    line-height: 1.6;
    color: #757D75;
  }
  .h53 {
    font-size: 13px;
    padding-top: 15px;
  
  }
  .card1 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${trailingDayColorCode} ;
     text-align: center;
    padding-top: 12px;
    align-item: center;
    font-size: 15px;
  }
  .card2 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${threeToSixmonthsPaymentColorCode} ;
     text-align: center;
     padding-top: 12px;
     align-item: center;
     font-size: 15px;
  }

  .cardcenter{
   align: center;
   padding-left: 25%;
   padding-top: 11px;
    
  }

  .hr1 {
    border: 1px solid #95C6F7;
  }

  .button1{
border-radius: 15px;
  }
  .table2{
    border-radius: 5px;
    border-collapse: 
    collapse; 
    width: 100%;
     background-color:#F8F8F8;
  }
  table1 {
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border-color: red;
    
  }
  
</style>
</head>
<body>
<hr class="hr1">

  <table style="border-collapse: collapse; width: 100%; height: 150px;">
  <tr>
  <h5 style="color: #000000; font-size: 15px;line-height: 1.5; padding-left: 8px; padding-bottom: 18px;">Risk Score</h5>
  </tr>
    <tr >
    <td>
    <div class="card">
        <p>${creditScore}</p>
      </div>
      </td>
<td></td>
<td></td>
<td style="padding-left: 150px;">
    <td style="background-color: red; border-color:black; color:black; font-size: 12px; border-radius: 5px; text-align:center; padding-top: 6px;">1-20</td><td style="background-color:#ff9800; border-color:black; color:black; font-size: 12px; border-radius: 5px; text-align:center; padding-top: 6px;">21-29</td><td style="background-color:#ffdf00; border-color:black; color:black; font-size: 12px; border-radius: 5px; text-align:center; padding-top: 6px;">30-50</td><td style="background-color:#32cd32; border-color:black; color:black; font-size: 12px; border-radius: 5px; text-align:center; padding-top: 6px;">51-70</td><td style="background-color:#4caf50; border-color:black; color:black; font-size: 12px; border-radius: 5px; text-align:center; padding-top: 6px;">70-100</td>
    </td>

  </tr>
  <tr> 
  <td style="width:500px;">
</td>
<td style="width:500px;">
</td>
<td style="width:500px;">
</td>
<td style="width:500px;">
</td>
<td style="width:500px;">
</td>
<td style="width:90%;">
</td>
<td style="width:90%;">
</td>
<td style="width:90%;">
</td>
  <td style="color:black; font-size: 8px;width:100px;">Very High</td><td style="color:black; font-size: 8px;width:50px; text-align:center">High</td><td style="color:black; font-size: 8px;width:50px; text-align:center">Moderate</td><td style="color:black; font-size: 8px;width:20px; text-align:center">Low</td><td style="color:black; font-size: 8px;width:100px; text-align:center">Very Low</td>
  </tr>
    <h5 class="h53">The Risk Score predicts the likelihood that a business's payment performance will become
    seriously delinquent, defined as 90-plus days beyond terms within the next 12 months or that
    the business will go bankrupt</h5>
  </table>
  <h4 style="color: #00628B; font-size: 18px">Payment Trends</h4>
  <div class="card0">

    <table class="table1">
  <tr>
    <td class="td1left" style="color: #00628B;">Trailing 90 Days payment behaviour</td>
    <td class="td1center"> <div class="cardcenter"><div class="card1">
    <div >${trailingDayScore}</div>
  </div></div></td>
    <td class="td1right">${trailingDayCommentary}</td>
  </tr>
  <tr>
    <td class="td1left" style="color: #00628B;">3-6 Months Payment Trends</td>
    <td class="td1center"> <div class="cardcenter" style="padding-top: 5px;"><div class="card2">
    <div >${threeToSixmonthsPaymentScore}</div>
  </div></div></td>
    <td class="td1right">${threeToSixmonthsPaymentCommentary}</td>
  </tr>
  <tr>
    <td class="td1bottomleft" style="color: #00628B;">Industry Benchmark</td>
    <td class="td1centerdbt">${dbtScore} DBT vs ${industryDbtScore} DBT</td>
    <td class="td1bottomright">${industryBenchmarkCommentary}</td>
  </tr>
</table>

</div>
<h5 class="h51">*${yearsInBusinessCommentary}</h5>
<h5 >Commentaries
<p class="h52">-The company has been in business for 2 years.<br>-The company does not currently have Business Spend data.<br>-The DBT Trend has remained stable over the last four months.<br>The company has 3 legal filings registered against it with a total value of $11,318.-The company is not part of a group.
<br>The local score for this company has decreased from 30 to 27.<br>
The credit limit for this company has decreased from $1,000 to $0.</p></h5>

</body>
</html> 
`;

const html4 = `<html>
<head>

<style>

.card0 {
  width: 100%;
  border-radius: 15px;
  background-color: #fbfbff;
   border-style: solid;
   border-width: 4px 4px 4px 4px;
   border-radius: 15px;
   border-color: #f0f0f0;
   padding:0px;

}
.card {
  width: 30%;
  height: 23%;
   border-radius: 30px;
   background-color: #ffdf00 ;
   text-align: center;
  padding-bottom: 54px;
}

.container {
  padding: 16px 16px 16px;
}


  .td1left, .th1 {
    border-right: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 5px;
    font-size: 11px;
  }

  .td1right {
    border-bottom: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td1center {
    border-bottom: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td1centerdbt {
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td1bottomleft {
    border-right: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 5px;
    font-size: 11px;
  }

  .td1bottomright {
    border-left: 1px solid #D0D0D0;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }
  
  .h51 {
    font-size: 18px;
    line-height: 1.6;
    color: #000000;
    font-weight: 1200;
  }
  .h52 {
    font-size: 12px;
    line-height: 1.6;
    color: #757D75;
  }
  .card1 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${cautionaryUccColorCode} ;
     text-align: center;
     padding-top: 12px;
    align-item: center;
    font-size: 15px;
  }
  .card2 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${taxLiensAndJudgementColorCode} ;
     text-align: center;
     padding-top: 12px;
     align-item: center;
     font-size: 15px;
  }

  .card3 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${civilSuitsColorCode} ;
     text-align: center;
     padding-top: 12px;
     align-item: center;
     font-size: 15px;
  }

  .card4 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${corporationFilingScoreColorCode} ;
     text-align: center;
     padding-top: 12px;
     align-item: center;
     font-size: 15px;
  }

  .cardcenter{
   align: center;
   padding-left: 25%;
    
  }

  .hr1 {
    border: 1px solid #95C6F7;
  }

  .h41 {
    color: #00628B; 
    font-size: 16px;
    line-height: 1.6px;
  }

</style>
</head>
<body>
<hr class="hr1">
<p class="h41">Background Check Summary - Derogatory Filings</p>
<div class="card0">

<table class="table1">
  <tr>
    <td class="td1left" style="color: #00628B;">Cautionary UCC</td>
    <td class="td1center"> <div class="cardcenter"><div class="card1">
    <div >${cautionaryUccScore}</div>
  </div></div></td>
    <td class="td1right">${cautionaryUccCommentary}</td>
  </tr>
  <tr>
    <td class="td1left" style="color: #00628B;">Taxliens & Judgement</td>
    <td class="td1center"> <div class="cardcenter" style="padding-top: 5px;"><div class="card2">
    <div >${taxLiensAndJudgementScore}</div>
  </div></div></td>
    <td class="td1right">${taxLiensAndJudgementCommentary}</td>
  </tr>
  <tr>
    <td class="td1left" style="color: #00628B;">Taxliens & Judgement</td>
    <td class="td1center"> <div class="cardcenter" style="padding-top: 5px;"><div class="card3">
    <div >${civilSuitsScore}</div>
  </div></div></td>
    <td class="td1right">${civilSuitsCommentary}</td>
  </tr>
  <tr>
    <td class="td1bottomleft" style="color: #00628B;">Taxliens & Judgement</td>
    <td class="td1centerdbt"> <div class="cardcenter" style="padding-top: 5px;"><div class="card4">
    <div >${corporationFilingScore}</div>
  </div></div></td>
    <td class="td1bottomright" tyle="padding-top: 8px;">${corporationFilingCommentary}</td>
  </tr>
 
</table>
</div>
<h5 class="h51">Active Tax Liens</h5>
<table  style="width:100%">
  <tr>
    <th style="color:#00628B; font-size: 12px;">Date Filed</th>
    <th style="color:#00628B; font-size: 12px;">Filing Type</th>
    <th style="color:#00628B; font-size: 12px;">Filing Number</th>
    <th style="color:#00628B; font-size: 12px;">Amount</th>
    <th style="color:#00628B; font-size: 12px;">Juridiction</th>
  </tr>
<tr></tr>
  <tr>
  <td style="color:#000000; font-size: 10px;">07-20-2022</td>
  <td style="color:#000000; font-size: 10px;">STATE TAX LEIN</td>
  <td style="color:#000000; font-size: 10px;">1000000928563</td>
  <td style="color:#000000; font-size: 10px;">$10446</td>
  <td style="color:#000000; font-size: 10px;">PALM BEACH COUNTY CIRCUIT COURT</td>
</tr>
<tr>
<td style="color:#000000; font-size: 10px;">11-07-2021</td>
<td style="color:#000000; font-size: 10px;">STATE TAX LEIN</td>
<td style="color:#000000; font-size: 10px;">20210514432</td>
<td style="color:#000000; font-size: 10px;">$447</td>
<td style="color:#000000; font-size: 10px;">PALM BEACH COUNTY CIRCUIT COURT</td>
</tr>
<tr>
<td style="color:#000000; font-size: 10px;">07-20-2022</td>
<td style="color:#000000; font-size: 10px;">STATE TAX LEIN</td>
<td style="color:#000000; font-size: 10px;">20210513662</td>
<td style="color:#000000; font-size: 10px;">$425</td>
<td style="color:#000000; font-size: 10px;">PALM BEACH COUNTY CIRCUIT COURT</td>
</tr>

<h5 class="h51">Active Judgements</h5>
<tr>
    <th style="color:#00628B; font-size: 12px;">Date Filed</th>
    <th style="color:#00628B; font-size: 12px;">Filing Type</th>
    <th style="color:#00628B; font-size: 12px;">Case Number</th>
    <th style="color:#00628B; font-size: 12px;">Amount</th>
    <th style="color:#00628B; font-size: 12px;">Plaintiff</th>
  </tr>
</table>
</body>
</html> 
`;

const html5 = `<html>
<head>

<style>
.card {
  width: 30%;
  height: 23%;
   border-radius: 30px;
   background-color: #ffdf00 ;
   text-align: center;
  padding-bottom: 54px;
}

.container {
  padding: 16px 16px 16px;
}


  .td1, .th1 {
    border: 1px solid #808080;
    text-align: left;
    padding: 8px;
    font-size: 11px;
  }
  .td2 {
    border: 1px solid #808080;
    text-align: left;
    padding-top: 20px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 12px;
  }

  .td3 {
    border: 1px solid #808080;
    text-align: left;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 11px;
  }

  .td4{
    color: #4caf50; 
    font-size: 15px; 
    font-weight: 700;
    padding-top: 12px; 
    padding-left:15px;
    border: 1px solid #808080;
    text-align: center;
  }

  .h51 {
    font-size: 18px;
    line-height: 1.6;
    color: #000000;
    font-weight: 1200;
  }
  .h52 {
    font-size: 12px;
    line-height: 1.6;
    color: #757D75;
  }
  .card1 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${cautionaryUccColorCode} ;
     text-align: center;
    padding: 20px;
    align-item: center;
    font-size: 15px;
  }
  .card2 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${taxLiensAndJudgementColorCode} ;
     text-align: center;
     padding: 20px;
     align-item: center;
     font-size: 15px;
  }

  .card3 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${civilSuitsColorCode} ;
     text-align: center;
     padding: 20px;
     align-item: center;
     font-size: 15px;
  }

  .card4 {
    width: 70px;
    height: 40px;
     border-radius: 35px;
     background-color: ${corporationFilingScoreColorCode} ;
     text-align: center;
     padding: 20px;
     align-item: center;
     font-size: 15px;
  }

  .cardcenter{
   align: center;
   padding-left: 25%;
    
  }

  .hr2 {
    border: 1px solid #D0D0D0;
  }
  .hr1 {
    border: 1px solid #95C6F7;
  }

  .h41 {
    color: #8A9A5B; 
    font-size: 16px;
    line-height: 1.6px;
  }

</style>
</head>
<body>
<hr class="hr1">
<h5 style="color:#00628B; font-size: 20px; ">Ongoing Case</h5>
<hr class="hr2">
<table  style="width:100%">
<tr>
  <th style="color:#00628B; font-size: 12px;">Docket Title</th>
  <th style="color:#00628B; font-size: 12px;">Docket Number</th>
  <th style="color:#00628B; font-size: 12px;">Filing Date</th>
  <th style="color:#00628B; font-size: 12px;">Court</th>
  <th style="color:#00628B; font-size: 12px;">Nature of Suit</th>
  <th style="color:#00628B; font-size: 12px;">Company Interest</th>
</tr>
<tr></tr>
<tr>
<td style="color:#000000; font-size: 10px;">Capital Auto Brockers, LLC v.</td>
<td style="color:#000000; font-size: 10px;"><a href="">9:22.CV-81344</a></td>
<td style="color:#000000; font-size: 10px;">08-31-2022</td>
<td style="color:#000000; font-size: 10px;">S.D.Fla</td>
<td style="color:#000000; font-size: 10px;">Torts: Other Fraud(370),Torts</td>
<td style="color:#000000; font-size: 10px;">Defendant</td>
</tr>
</table>
<h5 style="color:#00628B; font-size: 20px;">Lawsuits</h5>
<table>
<tr>
<td style="color: #000000; font-size: 12px;">CASE NUMBER:</td>
<td style="color: #000000; font-size: 12px;">0207079A</td>
<td style="color: #000000; font-size: 12px;">DATE FILED:</td>
<td style="color: #000000; font-size: 12px;">06-07-2022</td>
</tr>
<tr>
<td style="color: #000000; font-size: 12px;">PLAINTIFF INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px; line-height:0px;">Plaintiff Name:</td>
<td style="color:#000000; font-size: 11px;line-height:0px;">VOUGHT HERITAGE COMMU</td>
</tr>
<tr>
<td style="color: #000000; font-size: 12px;">DEFENDANT INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Defendant Name:</td>
<td style="color:#000000; font-size: 11px;">ATLANTIC ACCEPTANCE C</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Defendant Name:</td>
<td style="color:#000000; font-size: 11px;">ATLANTIC MOTOR CARS A</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Defendant Name:</td>
<td style="color:#000000; font-size: 11px;">ATLANTIC MOTORS ETAL</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Defendant Name:</td>
<td style="color:#000000; font-size: 11px;">ODAY DAVIDSON ETAL</td>
</tr>
<tr>
<td style="color: #000000; font-size: 12px;">COURT & CASE INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Case Number:</td>
<td style="color:#000000; font-size: 11px;">0207079A</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Case Type:</td>
<td style="color:#000000; font-size: 11px;">32300 -DEBT AGRMT</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Date Filed:</td>
<td style="color:#000000; font-size: 11px;">06-07-2022L</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">State Filed:</td>
<td style="color:#000000; font-size: 11px;">TX</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Court Location:</td>
<td style="color:#000000; font-size: 11px;">NO. 1</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Court Name:</td>
<td style="color:#000000; font-size: 11px;">DALLAS COUNTY COURT AT LAW</td>
</tr>
<br>

<tr>
<td style="color: #000000; font-size: 12px;">CASE NUMBER:</td>
<td style="color: #000000; font-size: 12px;">2002-0519CA</td>
<td style="color: #000000; font-size: 12px;">DATE FILED:</td>
<td style="color: #000000; font-size: 12px;">08-19-2022</td>
</tr>
<tr>
<td style="color: #000000; font-size: 12px;">PLAINTIFF INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Plaintiff Name:</td>
<td style="color:#000000; font-size: 11px;">MARK ALLEN</td>
</tr>
<tr>
<td style="color: #000000; font-size: 11px;">DEFENDANT INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Defendant Name:</td>
<td style="color:#000000; font-size: 11px;">ATLANTIC ACCEPTANCE CORPORATION</td>

</tr>
<tr>
<td style="color: #000000; font-size: 12px;">COURT & CASE INFORMATION</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Case Number:</td>
<td style="color:#000000; font-size: 11px;">0207079A</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Case Type:</td>
<td style="color:#000000; font-size: 11px;">32300 -DEBT AGRMT</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Date Filed:</td>
<td style="color:#000000; font-size: 11px;">08-19-2022L</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">State Filed:</td>
<td style="color:#000000; font-size: 11px;">FL</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Location Filed:</td>
<td style="color:#000000; font-size: 11px;">DUVAL</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Filing Office Address:</td>
<td style="color:#000000; font-size: 11px;">330 E BAY STREET JACKSONVILLE, FL 32202</td>
</tr>
<tr>
<td style="color:#00628B; font-size: 12px;">Court Name:</td>
<td style="color:#000000; font-size: 11px;">DUVAL COUNTY/CIRCUIT COURT</td>
</tr>

</table>
</body>
</html> 
`;
return(
<Document>
    <Page size="A4" style={styles.body} >
   <Image 
   style={styles.image1}
   src={require("assets/img/tenanttracker_11803_logo_500.png")} />

    {/* <Text style={styles.heading}>REPORTS</Text> */}
    <Text style={{ color: megaColor, textAlign: 'center', margin: 30, fontSize:25 ,fontWeight: 800}} >
   {tenantName}
      </Text>
<Image
        style={styles.image}
        src={require("assets/img/property2.jpeg")}
      
      />
   
   <View style={styles.inline}>
   <Image
        // style={styles.image2}
        style={styles.inline1}
        src={require("assets/img/mega_11803_logo_notag_500.jpg")}></Image>
      
   <Text style={styles.inline2} >Megalytics®
        4809 North Ravenswood Avenue
        Suite 215
        Chicago, IL 60640
        Phone: 312-818-1930
        Email: info@megalytics.net</Text>
        
              </View>
       
      
    
 <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900}} break>
   COMPANY SUMMARY
      </Text>
      <Html>{html}</Html>

      <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900}}>
   LEGAL ENTITY
      </Text>

      <Html>{html1}</Html>

      <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900}}>
   CORPORATION FILINGS
      </Text>

      <Html>{html2}</Html>

      <Text style={{ color: "#00628B", textAlign: 'left', paddingLeft: "8px",fontSize:16 ,fontWeight: 900}} break>
   COMPANY CREDIT REPORT
      </Text>

      <Html>{html3}</Html>

      <Text style={{ color: "#00628B", textAlign: 'left',paddingLeft: "8px", fontSize:16 ,fontWeight: 900}} >
   COMPANY DEROGATORY PUBLIC FILINGS
      </Text>
      <Html>{html4}</Html>

      <Text style={{ color: "#00628B", textAlign: 'left',paddingLeft: "8px", fontSize:18 ,fontWeight: 900}} break>
   LAW SUITS
      </Text>
      <Html>{html5}</Html>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
   
  </Document>
  
  );
 
  
}
  export default PdfOne;