/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination ,
  isVisible

} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import Instructions from "components/Instruction/Instruction.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Timeline from "@material-ui/icons/Timeline";
import Moment from 'react-moment';
// core components
import styles1 from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Paginations from "components/Pagination/Pagination.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Heading from "components/Heading/Heading.js";
import { useAppendSearchParam } from 'use-url-search-params-hooks';
import ReactSelectMaterialUi from "react-select-material-ui";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useHistory } from 'react-router-dom';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";




const newStyles = {
  ...styles,
  ...styles1,

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right"
  },

  headerStyle: {
    background:'blue',
    textAlign:'center',
    color: 'darkorange',
    borderRadius: '5px',
    padding: '5px',
    border:'1px solid black',
    borderRight: '3px solid yellow',
    borderLeft:'3px solid yellow',
    borderTop:'3px solid yellow',
    borderBottom:'3px solid yellow'
    },
};

const useStyles = makeStyles(newStyles);

const todayv = new Date();
const simpleOptions = ["Option 1", "Option 2", "Option 3"];

  


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (

    


    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: filterValue || "",
        onChange: e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
      //  placeholder: `Search ${count} records...`
      placeholder: `Search Records...`
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function PropertyTable({ columns, data, tmList, tmRequest,  }) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [days, setDays] = React.useState(1);
  const [selectedPeriod, setSelectedPeriod] = React.useState("");
  const [today, setToday] = React.useState(todayv);
  const [year, setYear] = React.useState(1);
  const [months, setMonths] = React.useState(0);
  const [checkedA, setCheckedA] = React.useState(true);
  const [checkedB, setCheckedB] = React.useState(false);
  
  
  const [dataList, setDataList] = React.useState(tmList);
  //const [params, setParams] = useState({ startDate: "", endDate: "", viewAllTenants:true});
  const classes = useStyles();



  const getTmList = async (start,end) => {
    
    await tmRequest(start, end);
    };

    

  const handleChange = (event) => {
   
   const todayNow = moment().format("D MMM YYYY"); 
   const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
   const month = moment().subtract(1, 'months').format("D MMM YYYY");
   const quarter = moment().subtract(3, 'months').format("D MMM YYYY");
   const sixMonths = moment().subtract(6, 'months').format("D MMM YYYY");
   const year = moment().subtract(1, 'years').format("D MMM YYYY");
  
    switch (event.target.value) {
      case "7 days":
        setSelectedPeriod(sevenDays +"    -    "+ todayNow)
        getTmList(sevenDays,todayNow);
        return ;
        break;
      case "Month":
       setSelectedPeriod(month +"    -     "+ todayNow)
       getTmList(month,todayNow);
        return <button>Login</button>;
        break;
        case "Quarter":
        setSelectedPeriod(quarter +"    -    "+ todayNow)
        getTmList(quarter,todayNow);
        return ;
        break;
      case "Six Months":
       setSelectedPeriod(sixMonths +"    -    "+ todayNow)
       getTmList(sixMonths,todayNow);
        return ;
        break;  
      case "Year":
       setSelectedPeriod(year +"    -    "+ todayNow)
       getTmList(year,todayNow);
        return ;
        break;  
      default:
        return null;
    }
   

  
  }

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 }
      
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function() {}
  );
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (

    
    <>

      <GridContainer className={classes.gridContainer}>
      <GridItem style={{textAlign: "right" }} xs={12} sm={12}>
      <Button style={{ background: megaColor,color:"white",fontSize:"14px" }} size="sm" round  onClick={() => history.push('/admin/property')}><b><AddCircleIcon/>&nbsp;Create New Property</b></Button>
 </GridItem>


  
     
    
      <GridItem xs={4} sm={4} align="right">
      {/* <ReactSelectMaterialUi
        label="Show Last"
        options={[
          { divider: true },
          "7 days",
          { divider: true },
          "Month",
          { divider: true },
          "Quarter",
          { divider: true },
          "Six Months",
          { divider: true },
          "Year"
        ]}
        fullWidth={true}
        onChange={handleChange}
       
      />
    */}

    {/* <FormControl className={classes.formControl}>
        <InputLabel htmlFor="period-native-simple"><b><font color="#659EC7">Show Last</font></b></InputLabel>
        <NativeSelect
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'period',
            id: 'period-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="7 days">7 days</option>
          <option value="Month">Month</option>
          <option value="Quarter">Quarter</option>
          <option value="Quarter"> Six Months</option>
          <option value="Quarter">Year</option>
       
        </NativeSelect>
      </FormControl> */}
     
      </GridItem>

                 <GridItem xs={4} sm={4}>

   
<h5>  <b><font color="#659EC7">{selectedPeriod}</font></b> 
</h5> 
</GridItem>

 </GridContainer>


 <br/>
<br/>
     
      <div className="ReactTable -striped -highlight">
     
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length  !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc
                    })}
                     style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length === key
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    // { " -odd": i % 2 === 0 },
                    //{ " -even": i % 2 === 1 }
                  )}
                  style={{
                    color: 'black',
                    fontSize :'11pt',
                    borderBottom: '1px solid #D3D3D3',
                    
                  }}
                >
                  {row.cells.map(cell => {
                    return (
                      
                      <td {...cell.getCellProps()} className="rt-td" style={{textAlign:cell.column.align, width:cell.column.width}}>
                      
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom">
        
      
          <div className="-pagination">
            <div className="-previous">
              <Button justIcon round
                color="info"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
               
              >
          <NavigateBeforeIcon/> 
              </Button>
            </div>
            <div className="-center">
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={pageSelect}
                      onChange={event => {
                        gotoPage(event.target.value);
                        handlePageSelect(event.target.value);
                      }}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select"
                      }}
                    >
                      {pageSelectData.map((prop, key) => {
                        return (
                          <MenuItem
                            key={key}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={key}
                          >
                            Page {key + 1}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={numberOfRows}
                      onChange={event => {
                        setPageSize(event.target.value);
                        setNumberOfRows(event.target.value);
                      }}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows"
                      }}
                    >
                      {numberOfRowsData.map(prop => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={prop}
                          >
                            {prop} rows
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>
            <div className="-next">
              <Button  justIcon round
                color="info"
                onClick={() => nextPage()}
                disabled={!canNextPage}
               
              >
               <NavigateNextIcon/> 
              </Button>
            </div>

    
          </div>
        </div>
        
        
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";


PropertyTable.propTypes = {
  tmList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.number,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmList: state.tenantmonitoring.tmList,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyTable);
//export default Table;
