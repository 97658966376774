import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
//import CreditReport from "components/CreditReport/CreditReport.js";
import DerogatoryReport from "components/DerogatoryReport/DerogatoryReport.js";
import { BrowserRouter, Route } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";

import CreditReportHeatMap from "components/Charts/CreditReportHeatMap.js";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';



import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
const HeapMapTenantRisk = ({user,labelStatus,tenantName,tmTenantsOverAlCredPirsDeroScoreRequest,tenantsOverAlCredPirsDeroScore,tenantId,source,month}) =>{
  const useStyles = makeStyles(styles);
  const history = useHistory();
  const classes = useStyles(); 
    const [scoreTypeValue, setScoreTypeValue] = React.useState(34);
    const [monthValue, setMonthValue] = React.useState(45);
    const [pirsEnabled, setPirsEnabled] = React.useState(false);
    const [scoreLabels, setScoreLabels] = React.useState(true);
//const [month, setMonth] = React.useState("");
    const [userId, setUserId] = React.useState(user.id);
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);


    const getTmDashboardTenantScoreBreakUp = async (tenantId,month,year,userId) => {
        await tmTenantsOverAlCredPirsDeroScoreRequest(tenantId,month,year,userId);
    //  alert(monthValue);
      };
      const drillDown = (y,x) => {
       //alert();
        setPirsEnabled(true);
    
       setScoreTypeValue(x);
       setMonthValue(y);
        if(y==3){
          setScoreTypeValue("PIRS");
            setPirsEnabled(true);
        }else if(y==2){
      
            setScoreTypeValue("CREDIT");
        }else if(y==3){
         
            setScoreTypeValue("DEROGATORY") ;
        }else{
            console.log("No type");
        }

     console.log(pirsEnabled);
     console.log(scoreTypeValue);
      };



    React.useEffect(() => {

    //   if(source == "property"){
   
        setScoreLabels(false);
       //}
  
        getTmDashboardTenantScoreBreakUp(tenantId,month,2021,userId);
        
  
      }, [monthValue,scoreTypeValue]);

     



    const chartOptions = {

      
  

     series:tenantsOverAlCredPirsDeroScore,
      options: {

        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 300, 
              
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                     position: 'top',
                      labels: {
                          position: 'top',
                          style: {
                                    fontSize: '16px',
                                    fontWeight: 700,
                                  }
                                }
                              },
        chart: {
          height: 350,
          type: ' ',
          events: {
           
            click: (event, chartContext, config) => {
           
            
              setPirsEnabled(true);
             
           
              var el = event.target;
              var seriesIndex = parseInt(el.getAttribute("i"));
              var dataPointIndex = parseInt(el.getAttribute("j"));
              var seriesName = config.globals.seriesNames[seriesIndex];
              history.push({
                pathname: '/admin/report/',
                state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId,tenantName:tenantName,month:month}
              })
           //   if(source == "property"){
          //   history.push({
          //     pathname: '/manager/reportAssetmanager/',
          //     state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId}
          //   })
          // }else{
          //   history.push({
          //     pathname: '/admin/report/',
          //     state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId}
          //   })
          // }
             
             //drillDown(config.seriesIndex,config.dataPointIndex); 
           // console.log(config);
           // config.seriesIndex;
            //config.dataPointIndex; 
            // console.log(chartContext);
            } 
          },
          series: [
            {
              name: "Series 1",
              data: [{
                x: 'Jan',
                y: 212
              }, {
                x: 'Feb',
                y: 290
              }, {
                x: 'Mar',
                y: 83
              }, {
                x: 'Apr',
                y: 320
              }]
            },
            {
              name: "Series 2",
              data: [{
                x: 'Jan',
                y: 430
              }, {
                x: 'Feb',
                y: 413
              }, {
                x: 'Mar',
                y: 45
              }, {
                x: 'Apr',
                y: 350
              }]
            }
          ]
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 400,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
            showForNullSeries: true,
            show : false,
            // formatter: function(seriesName, opts) {
            //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
            // }
          },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
              
              if(val == 0){
            //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
             return "NA";      
        }else
              return val;
          }
        },
        stroke: {
          width: 1
        },
        title: {
          text: ''
        },
      },
    
    
    };
 

    return (
      
      
<div id="chart">
<br/>
<br/>

<GridContainer >



         
<GridItem xs={12} sm={12} md={12}>
<h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>{tenantName}&nbsp;&nbsp;&nbsp;&nbsp;TenantTracker&trade; Score & Components by Month</b></h3>
</GridItem>
<GridItem xs={12} sm={5} md={5}>

</GridItem>

{scoreLabels&&<GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right">
     {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
  
    <br></br><br/>
       
       <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
       <Button color="transparent" size="sm" className={classes.firstButton}>
       <b><h7 style={{color:'red'}}>High Risk</h7></b>
       </Button>

       <Button color="transparent" size="sm" className={classes.middleButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent" size="sm" className={classes.middleButton}>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
       </Button>
     </div>
     <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
       <Button color="danger" size="sm" className={classes.firstButton}>
         <b>0 - 100 </b>
       </Button>
       <Button color="warning" size="sm" className={classes.middleButton}>
       <b>102 - 200 </b> 
       </Button>
       <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
       <b>201 - 300 </b>
       </Button>
       <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
       <b>301 - 400 </b>
       </Button>
       <Button color="success"  size="sm" className={classes.lastButton}>
       <b>401 - 500 </b>
       </Button>
     </div>
    

</GridItem>
}
</GridContainer>


<Chart  options={chartOptions.options} series={chartOptions.series} type="heatmap" height={350} />
<br/>



</div>
    )

}

HeapMapTenantRisk.propTypes = {

    loading: PropTypes.bool.isRequired,
    tmTenantsOverAlCredPirsDeroScoreRequest: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tenantsOverAlCredPirsDeroScore: state.tenantmonitoring.tenantsOverAlCredPirsDeroScore,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HeapMapTenantRisk);
  
  