import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBase from 'components/MenuBase/SideMenuBase.js';
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import ReactPaginate from 'react-paginate';
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";

import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
//import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import Paginations from "components/Pagination/Pagination.js";

import Pagination from '@material-ui/lab/Pagination';
import { getFundDashboardCount } from "store/sagas/tenantmonitoring";

import { useLocation } from "react-router-dom";
 
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CloseIcon from '@material-ui/icons/Close';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "variables/charts.js";
//import { CardView } from 'react-card-with-image'

import CardMedia from '@material-ui/core/CardMedia';
  //const useStyles = makeStyles(styles);

  import property2 from "assets/img/property2.jpeg";
  import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from 'moment';
import TMHeatMapRisk1 from "./TMHeatMapRisk1";
import TMHeatMapRiskGridView from "./TMHeatMapRiskGridView";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import GaugeChart from 'react-gauge-chart'
import MTTable from "components/MTTable/MTTable";
import PropertyDetailsTable from "./PropertyDetailsTable";
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Tooltip } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchField from "react-search-field";
import { goBack } from "connected-react-router";
import FundPropertyGridView from "./FundPropertyGridView";
import TenantNotification from "views/TenantNotification/TenantNotification";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const useStyles = makeStyles((theme) => ({
    root: {
    width:"120px",
     height:"80px",
     float:"left",
     borderRadius: 9,
     cursor:"pointer",
       boxShadow: "10px 10px 15px 2px rgba(0,0,0,0.3)"
     
    },
    root1: {
      width:"120px",
       height:"80px",
       float:"left",
       marginLeft : "20px",
       borderRadius: 9,
       cursor:"pointer",
         boxShadow: "10px 10px 15px 2px rgba(0,0,0,0.3)"
       
      },

      root2: {
        width:"120px",
         height:"80px",
         float:"left",
         marginLeft : "20px",
         borderRadius: 9,
         cursor:"pointer",
           boxShadow: "10px 10px 15px 2px rgba(0,0,0,0.3)"
         
        },
        root3: {
          width:"120px",
           height:"80px",
           float:"right",
           marginLeft : "20px",
           borderRadius: 9,
           cursor:"pointer",
             boxShadow: "10px 10px 15px 2px rgba(0,0,0,0.3)"
           
          },
          root4: {
            width:"120px",
             height:"80px",
             float:"right",
             marginLeft : "20px",
             borderRadius: 9,  
             cursor:"pointer",        
               boxShadow: "10px 10px 15px 2px rgba(0,0,0,0.3)"
             
            },
  
    
    
    title: {
      fontSize: 25,
      color:"#ffffff",
      textAlign:"center",
      paddingTop:"68px"
    },

   
    pos: {
    //textAlign:"right",
    backgroundColor:"#64bf6a",
    color:"#ffffff",
    fontSize:"18px",
    // marginLeft:"20px",
    // marginTop:"10px",
    paddingRight:"12px",
    paddingLeft:"12px",
     paddingTop:"8px",
   paddingBottom:"8px",
    },

  
  cardHorizontal: {
    display: "flex",
    flex: "1 1 auto"
  },
  horizontal: {
    display: "flex",
    //margin: "0 -10px",
    alignItem: "center",
    paddingLeft:"40px",
   paddingRight:"40px",
    paddingBottom:"40px",

//  paddingLeft:"40px",
   //justifyContent: "space-between",
   //flexWrap: "wrap",
//rowGap: "10px",
columnGap: "0.3em"
  
},
  cardTitles: {
    // paddingLeft:"10px",
    fontSize:"18px",
    marginLeft:"50px",
  
  },
  chart:{
    marginLeft:"70px",
   // marginTop:"0px"
 },
  cardTitle: {

    width: "90%",
    //  height: "5vw",
    objectFit: "cover",
   // position: "absolute",
  marginTop: "8px",
  // marginLeft:"50px",
//  align:"center",
	// height:"299px",
	// width: "225px",
	background: "#fff",
	borderRadius: "3px",
	overflow: "hidden",
	boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)",
  transition: "all 0.2s ease-out",
  top: "-4px"
  },
  // hover: {
  //   transition: "all 0.2s ease-out",
  //   boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
  //   top: "-4px",
  //   border: "1px solid #cccccc",
  //   backgroundColor: "white"
  // }

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
 text:{
    display:"inline",
    
    // marginLeft:"50px",
    // float:"center",
    },
    text1:{
      display:"inline",
    },
    arrow:{
    display:"inline",
    float:"right"
    // float:"center"
    },
    gradient:{
      display:"inline",
      float:"left",
    
     
      },
      gradient1:{
        display:"inline",
        float:"right",
        
        
       
        },

        gradient2:{
          display:"inline",
          float:"center",
        
         
          },
          gradient4:{
            display:"inline",
            float:"right",
            
            
           
            },
        Inlinetop :{
          
          display: "flex",
          flexDirection: "column",
          margin:"auto",
         justifyContent:"space-between"
         

      },
      Inlinetop1:{
          
        display: "flex",
        flexDirection: "row",
     alignItems:"justify",
     justifyContent:"center"
       
  
      },
      gridstyle:{
        padding: theme.spacing(1),
      },
      customWidth: {
        maxWidth: 200,
        fontSize: "15px",
        backgroundColor: megaColor,
      }
  }));
  


const MonitoredTenantsPropertyDetails = ({user,loading,organizationId,tmFundDashboardGridViewRequest,fundDashboarGridView,tmGetFundPropertyGridViewDashboardRequest,fundPropertyGridViewDashboard,fundPropertyApexChartData,tmFundPropertyApexChartDataRequest,tmGetTenantScoreGraphDataByPropertyIdAndUserIdRequest,tenantScoreGraphDataByPropertyIdAnduserId,tmGetTenantRiskCountByPropertyGridViewRequest,tenantRiskCountByPropertyGridView,tmGetTenantBarGraphDataRequest,tenantBarGraphData,tmGetPropertyTenantLineChartRequest,propertyTenantLineChart,tmCreditScoreChartCompanyListRequest,tmGetPropertyTenantLineChartPaginationRequest,propertyTenantLineChartPagination,
  tmGetPropertyTenantSearchRequest,propertyTenantSearch,tmGetPreviousMonthTenantRiskCountRequest,previousMonthTenantRiskCount,tmGetTenantListBasedOnLowRiskRequest,tenantListBasedOnLowRisk,tmGetTenantListBasedOnMediumLowRiskRequest,tenantListBasedOnMediumLowRisk,tmGetTenantListBasedOnMediumHighRiskRequest,tenantListBasedOnMediumHighRisk,tmGetTenantListBasedOnModerateHighRiskRequest,tenantListBasedOnModerateHighRisk,tmGetTenantListBasedOnHighRiskRequest,tenantListBasedOnHighRisk,parentCallBackNavigation,tmPropertyByIdRequest,propertyEditObj}) =>{

const history = useHistory();
const location = useLocation();
const[dynamicHeight,setDynamicHeight]= React.useState("");

const[showHeatMapFundRisk,setShowHeatMapFundRisk]= React.useState(false);

const [selectedFundId, setSelectedFundId] = React.useState("");
const [selectedFundName, setSelectedFundName] = React.useState("");
const [showHeatMap, setShowHeatMap] = React.useState(true);

const [dataStatus, setDataStatus] = React.useState(false);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
//const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));


const [pageSize, setPageSize] = React.useState(8);
const [pageNum, setPageNum] = React.useState(0);
//const [totalCount, setTotalCount] = React.useState("");
const [page, setPage] = React.useState(1);
const [totalCount, setTotalCount] = React.useState(50);
const [userId, setUserId] = React.useState(user.id);

const [loader,setLoader] = React.useState(false)

// const[propertyId,setPropertyId]=React.useState("");
// const[propertyName,setPropertyName]=React.useState("");


const [month, setMonth] = React.useState("");

  const [year, setYear] = React.useState("");
  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);
  const[heatMapPropertyRiskVisible,setHeatMapPropertyRiskVisible]=React.useState(false);
  const[heatMapPropertyTenantVisible,setHeatMapPropertyTenantVisible]=React.useState(false);
  const[heatMapPropertyVisible,setHeatMapPropertyVisible]=React.useState(true);

  const[showBackNavigation,setShowBackNavigation]=React.useState(false);
  // const [directNavigation, setDirectNavigation] = React.useState(backNavigation);

  const[showHeatMapRisk1,setShowHeatMapRisk1]= React.useState(false);
  const [showFundPropertyGridView, setShowFundPropertyGridView] = React.useState(true);


const [openTrend, setOpenTrend] = React.useState(false);
const [openMonitoredTenants, setOpenMonitoredTenants] = React.useState(false);

const classes = useStyles(); 

const [expanded, setExpanded] = React.useState(false);


const[lowRiskCount,setLowRiskCount]= React.useState("");
const[mediumLowRiskCount,setMediumLowRiskCount]= React.useState("");
const[moderateHighRiskCount,setModerateHighRiskCount]= React.useState("");
const[mediumHighRiskCount,setMediumHighRiskCount]= React.useState("");
const[highRiskCount,setHighRiskCount]= React.useState("");

const[previousLowRiskCount,setPreviousLowRiskCount]= React.useState("");
const[previousMediumLowRiskCount,setPreviousMediumLowRiskCount]= React.useState("");
const[previousModerateHighRiskCount,setPreviousModerateHighRiskCount]= React.useState("");
const[previousMediumHighRiskCount,setPreviousMediumHighRiskCount]= React.useState("");
const[previousHighRiskCount,setPreviousHighRiskCount]= React.useState("");


const[lowRiskPercent,setLowRiskPercent]= React.useState("");
const[mediumLowRiskPercent,setMediumLowRiskPercent]= React.useState("");
const[moderateHighRiskPercent,setModerateHighRiskPercent]= React.useState("");
const[mediumHighRiskPercent,setMediumHighRiskPercent]= React.useState("");
const[highRiskPercent,setHighRiskPercent]= React.useState("");
const[tenantName,setTenantName] = React.useState("");
const [companyListRequest,setCompanyListRequest] =React.useState("");

const [myOptions, setMyOptions] = React.useState([])

const[tenantList,setTenantList] = React.useState([]);




const[searchPageNum,setSearchPageNum] = React.useState(0);
const[searchPageSize,setSearchPageSize] = React.useState(8);
const [searchTerm,setSearchTerm]= React.useState("");
const [searchTenantName,setSearchTenantName] = React.useState("");
const[searchTenantList,setSearchTenantist]=React.useState([]);
const[showSearch,setShowSearch]=React.useState(true);
const[showList,setShowList]=React.useState(false);
const[prevTotalTenantCount,setPrevTotalTenantCount]= React.useState("");
const[currTotalTenantCount,setCurrTotalTenantCount]= React.useState("");

const[tenantId,setTenantId] = React.useState("");

const[series,setSeries] = React.useState("");

const[categories,setCategories] = React.useState("");

const [showNoDataFound, setShowNoDataFound] = React.useState(true);
  const [showDataFound, setShowDataFound] = React.useState(true);
  const [showButton,setShowButton] = React.useState(true);
  const [showPreviousCount,setShowPreviousCount] = React.useState(true);
  const [showCurrentCount,setShowCurrentCount] = React.useState(true);

  const [currMonth,setCurrentMonth] =  React.useState(tenantRiskCountByPropertyGridView.currMonth);
  const [prevMonth,setPreviousMonth] =  React.useState(previousMonthTenantRiskCount.prevMonth);

  const [showHighRisk,setShowHighRisk] = React.useState([]);
  const [showMediumHighRisk,setShowMediumHighRisk] = React.useState([]);
   const [showModerateHighRisk,setShowModerateHighRisk] = React.useState([]);
   const [showMediumLowRisk,setShowMediumLowRisk] = React.useState([]);
   const [showLowRisk,setShowLowRisk] = React.useState([]);

   const [highRisk,setHighRisk] = React.useState(false);
  const [mediumHighRisk,setMediumHighRisk] = React.useState(false);
   const [moderateHighRisk,setModerateHighRisk] = React.useState(false);
   const [mediumLowRisk,setMediumLowRisk] = React.useState(false);
   const [lowRisk,setLowRisk] = React.useState(false);
const [showSearchBar,setShowSearchBar] = React.useState(false);

const [fundId,setFundId] = React.useState(location.state.fundId);
const [fundName,setFundName] = React.useState(location.state.fundName);
const [propertyName,setPropertyName] = React.useState(location.state.propertyName);
const [propertyAddress,setPropertyAddress] = React.useState(location.state.propertyAddress);
const [score,setScore] = React.useState(location.state.score);
const [currentStatus,setCurrentStatus] = React.useState(location.state.currentStatus);
const [propertyId,setPropertyId] = React.useState(location.state.propertyId);
const [imageUrl,setImageUrl] = React.useState(location.state.imageUrl);
const [colorCode,setColorCode] = React.useState(location.state.colorCode);
// const [imageUrl,setImageUrl] = React.useState(location.state.imageUrl);

const[searchPropertyId,setSearchPropertyId] = React.useState(propertyId);
// fundId,fundName,propertyName,propertyAddress,score,currentStatus,propertyId,imageUrl,colorCode,

const mergedlist = [
  { tenantName: 'Autolus Inc.', currentMonthScore:"318", status:"up"},
  { tenantName: 'MUFG Investor Service', currentMonthScore:"322", status:"down" },
  { tenantName: 'The NASDAQ OMX Group, Inc.', currentMonthScore:"283",status:"up" },
 
  ];



  const handlePageChange =(event, value) => {
  
    setPage(value);
    // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
    getPropertyTenantSearchRequest(searchTerm,propertyId,value-1,pageSize);
console.log("page"+JSON.stringify(propertyTenantSearch.tenantList));

console.log("pageNum"+value);
console.log("pageSize"+pageSize);
console.log("searchTerm"+searchTerm);
    setTimeout(() => {
      setShowButton(false);
      setShowList(true);
    
    }, 1000);
    
    console.log(propertyTenantSearch.resultSize); 
    };

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const handlePageChangeLowRisk =(event, value) => {
  
    setPage(value);
    // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
    getTenantListBasedOnLowRiskTenant(searchTerm,propertyId,userId,month,value-1,pageSize);
console.log("page"+JSON.stringify(tenantListBasedOnLowRisk.tenantList));

    setTimeout(() => {
      setShowButton(false);
   setLowRisk(true);
   setShowButton(true);
    
    }, 500);
    
    console.log(propertyTenantSearch.resultSize); 
    };


    const handlePageChangeMediumLowRisk =(event, value) => {
  
      setPage(value);
      // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
      getTenantListBasedOnMediumLowRiskTenant(searchTerm,propertyId,userId,month,value-1,pageSize);
  console.log("page"+JSON.stringify(tenantListBasedOnMediumLowRisk.tenantList));
  
      setTimeout(() => {
        setShowButton(false);
     setMediumLowRisk(true);
     setShowButton(true);
      
      }, 500);
      
      console.log(tenantListBasedOnMediumLowRisk.resultSize); 
      };
  
      const handlePageChangeMediumHighRisk =(event, value) => {
  
        setPage(value);
        // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
        getTenantListBasedOnMediumHighRiskTenant(searchTerm,propertyId,userId,month,value-1,pageSize);
    console.log("page"+JSON.stringify(tenantListBasedOnMediumHighRisk.tenantList));
    
        setTimeout(() => {
          setShowButton(false);
       setMediumHighRisk(true);
       setShowButton(true);
        
        }, 500);
        
        console.log(tenantListBasedOnMediumHighRisk.resultSize); 
        };
    
        const handlePageChangeModerateHighRisk =(event, value) => {
  
          setPage(value);
          // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
          getTenantListBasedOnModerateHighRiskTenant(searchTerm,propertyId,userId,month,value-1,pageSize);
      console.log("page"+JSON.stringify(tenantListBasedOnModerateHighRisk.tenantList));
      
          setTimeout(() => {
            setShowButton(false);
         setModerateHighRisk(true);
         setShowButton(true);
          
          }, 500);
          
          console.log(tenantListBasedOnModerateHighRisk.resultSize); 
          };

          const handlePageChangeHighRisk =(event, value) => {
  
            setPage(value);
            // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
            getTenantListBasedOnHighRiskTenant(searchTerm,propertyId,userId,month,value-1,pageSize);
        console.log("page"+JSON.stringify(tenantListBasedOnHighRisk.tenantList));
        
            setTimeout(() => {
              setShowButton(false);
           setHighRisk(true);
           setShowButton(true);
            
            }, 500);
            
            console.log(tenantListBasedOnHighRisk.resultSize); 
            };
      
            const handleClickOpenMonitoredTenants = (value) => {
              setOpenMonitoredTenants(true);
             
              setTenantName(value.tenantName); 
              setTenantId(value.tenantId);
      
          
              console.log("teantname"+tenantName);
              console.log("tenantId"+tenantId);
              
            };
          
            const handleCloseMonitoredTenants = () => {
              setOpenMonitoredTenants(false);
              
            };

            
  const handleClickOpenTrend = (value) => {
    setOpenTrend(true);
   
    setTenantName(value.tenantName); 
    setTenantId(value.tenantId);
    setSeries(value.series);
   

    console.log("teantname"+tenantName);
    console.log("tenantId"+tenantId);
    console.log("series"+series);
    console.log("categories"+categories);
    

//     // setOrganisationId(value.organisationId);
 
// setTimeout(() => {
// setDataStatus(true);

// }, 4000);


  };


  const handleCancel =  (e) =>{

    getPropertyTenantSearchRequest("",propertyId,pageNum,pageSize);
    setSearchTerm("");
  console.log("search"+searchTerm);
  setTimeout(() => {
    setShowButton(false);
    setShowList(true);
    setShowSearchBar(true);
  
  }, 500);
    // event.target.value=""
  
    
  }

  const handleCloseTrend = () => {
    setOpenTrend(false);
    
  };

  const backNavigation=(fundId)=>{
  
    parentCallBackNavigation(fundId);

      }
      const getPropertyById = async (propertyId) => { 
        await tmPropertyByIdRequest(propertyId);
        };
      const [occupiedSqftPercentage, setOccupiedSqftPercentage]=React.useState(0);

    React.useEffect(() => {
      setShowList(false);
      setShowSearchBar(false);
    
      setShowButton(true);
      getPropertyById(propertyId);
      if(propertyEditObj!=null){
        setOccupiedSqftPercentage((propertyEditObj.propertySqftNew / propertyEditObj.rba) * 100);
       }
      //  alert("propertyId"+propertyId);
      //  alert("propertynname"+propertyName);
      //  alert("fundId"+fundId);
      //  alert("fundName"+fundName);
      //  alert("imageUrl"+imageUrl);
      //  alert("currentStatus"+currentStatus);
      //  alert("userId"+userId);
      //  alert("score"+score);
      //  alert("tenantId"+tenantId);
     
if(showPreviousCount==true){
       setMonth(prevMonthName2);
       setShowPreviousCount(true);
      
}
 if(showCurrentCount==true){
  setMonth(currMonthName2); 
  setShowCurrentCount(true);
  setShowPreviousCount(false);
}

      if(propertyTenantSearch.searchTerm==""){
      setShowSearch(false);
      
      }else{
        setShowSearch(true);
      setSearchTerm("");
       
      }
      if((propertyTenantSearch.tenantList ==null)&& (tenantRiskCountByPropertyGridView.length<0)){
        // setShowCompanyName(false);
        setShowNoDataFound(true);
      setShowDataFound(false); 
        
       }
     else{
      setShowNoDataFound(false);
      setShowDataFound(true);
     
       // setAlert(true);
     
     }
     console.log("imageUrl"+imageUrl);
    if(!dataStatus){
       getTenantRiskCountByPropertyGridView(propertyId,userId);
       getPreviousMonthTenantRiskCount(propertyId,userId);
      //  getTenantListBasedOnLowRiskTenant(searchTerm,propertyId,pageNum,pageSize)
            }
        
        setTimeout(() => {
        setDataStatus(true);
       
        }, 3000);
                

      
      },[dataStatus,tenantRiskCountByPropertyGridView,propertyTenantSearch]);

      const onSearchClick =  (searchTerm,searchPropertyId,searchPageNum,searchPageSize)=>{
        getPropertyTenantSearchRequest(searchTerm,propertyId,pageNum,pageSize);
        setSearchTerm(propertyTenantSearch.searchTerm);
        
        console.log("searchTerm"+searchTerm);
        console.log("propertyId"+propertyId);
        console.log("pageNum"+pageNum);
        console.log("pageSize"+pageSize);
        setTimeout(() => {
          setShowButton(false);
          setShowList(true);
          setShowSearchBar(true);
          setLowRisk(false);
        
        }, 1000);
      
        
      }

       


    const showPreviousMonthCount=()=>{
      getPreviousMonthTenantRiskCount(propertyId,userId);

      setTimeout(() => {
        setShowPreviousCount(true);
        setShowCurrentCount(false);
        setMonth(prevMonthName2);
        // setShowList(false);
        setShowSearchBar(false);
        setShowSearchBar(false);
      }, 500);
    }

    const showCurrentMonthCount=()=>{
      getTenantRiskCountByPropertyGridView(propertyId,userId);
      setTimeout(() => {
        setShowPreviousCount(false);
        setShowCurrentCount(true);
        setMonth(currMonthName2);

        // setShowList(false);
        setShowSearchBar(false);
      
      }, 500);
    }

const showTenantList=()=>{
  getPropertyTenantSearchRequest(searchTerm,propertyId,pageNum,pageSize);
  setLoader(true);
  setShowSearchBar(true);
  setLowRisk(false);
  setMediumLowRisk(false);
  setMediumHighRisk(false);
  setModerateHighRisk(false);
  setHighRisk(false);

  setTimeout(() => {
    setLoader(false);
    setShowSearchBar(true);
    setShowButton(false);
    setShowList(true);
  
  }, 3000);


}
const getTenantListBasedOnLowRiskTenant = async (searchTerm,propertyId,userId,month,pageNum,pageSize) => {
    
  await   tmGetTenantListBasedOnLowRiskRequest(searchTerm,propertyId,userId,month,pageNum,pageSize);
  
console.log("tenantListBasedOnLowRisk"+JSON.stringify(tenantListBasedOnLowRisk));



  };

  const getTenantListBasedOnMediumLowRiskTenant = async (searchTerm,propertyId,userId,month,pageNum,pageSize) => {
    
    await   tmGetTenantListBasedOnMediumLowRiskRequest(searchTerm,propertyId,userId,month,pageNum,pageSize);
    
  console.log("tenantListBasedOnMediumLowRisk"+JSON.stringify(tenantListBasedOnMediumLowRisk));

    };


  const getTenantListBasedOnMediumHighRiskTenant = async (searchTerm,propertyId,userId,month,pageNum,pageSize) => {
    
    await   tmGetTenantListBasedOnMediumHighRiskRequest(searchTerm,propertyId,userId,month,pageNum,pageSize);
    
  console.log("tenantListBasedOnMediumHighRisk"+JSON.stringify(tenantListBasedOnMediumHighRisk.tenantList));

    };

    const getTenantListBasedOnModerateHighRiskTenant = async (searchTerm,propertyId,userId,month,pageNum,pageSize) => {
    
      await   tmGetTenantListBasedOnModerateHighRiskRequest(searchTerm,propertyId,userId,month,pageNum,pageSize);
      
    console.log("tenantListBasedOnModerateHighRisk"+JSON.stringify(tenantListBasedOnModerateHighRisk));
  
      };

      const getTenantListBasedOnHighRiskTenant = async (searchTerm,propertyId,userId,month,pageNum,pageSize) => {
    
        await   tmGetTenantListBasedOnHighRiskRequest(searchTerm,propertyId,userId,month,pageNum,pageSize);
        
      console.log("tenantListBasedOnHighRisk"+JSON.stringify(tenantListBasedOnHighRisk));
    
        };
        

const showLowRiskList=()=>{
  
  getTenantListBasedOnLowRiskTenant(searchTerm,propertyId,userId,month,pageNum,pageSize);

   console.log("tenantListBasedOnLowRisk"+tenantListBasedOnLowRisk);
   setLoader(true);
   setHighRisk(false);
   setMediumLowRisk(false);
   setMediumHighRisk(false);
   setModerateHighRisk(false);
   setShowList(false);
   setShowSearchBar(false);
   setShowButton(true);
   setShowNoDataFound(false);

   if(lowRiskCount==0){
   
    setLowRisk(false);
    setShowNoDataFound(true);
    setHighRisk(false);
    setMediumLowRisk(false);
    setMediumHighRisk(false);
    setModerateHighRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
   }

   
   else{

  setTimeout( () => {
setLoader(false);
    // setDataStatus(true);
    setLowRisk(true);
   
    setShowNoDataFound(false);
  }, 5000);
   }

}

const showMediumLowRiskList=()=>{
  getTenantListBasedOnMediumLowRiskTenant(searchTerm,propertyId,userId,month,pageNum,pageSize);
  console.log("tenantListBasedOnRisk"+tenantListBasedOnMediumLowRisk);

  setLoader(true);
  setHighRisk(false);
  setLowRisk(false);
  setModerateHighRisk(false);
  setMediumHighRisk(false);
  setShowList(false);
  setShowSearchBar(false);
  setShowButton(true);
  setShowNoDataFound(false);

  if(mediumLowRiskCount==0 ){
    setMediumLowRisk(false);
    setLoader(false);
    setShowNoDataFound(true);
    setHighRisk(false);
    setLowRisk(false);
    setModerateHighRisk(false);
    setMediumHighRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
 
    
  }else{
  
   
   setTimeout(() => {
    setLoader(false);
    setMediumLowRisk(true);
   
  setShowNoDataFound(false);
  }, 5000);
  
}
}
const showMediumHighRiskList=()=>{
  getTenantListBasedOnMediumHighRiskTenant(searchTerm,propertyId,userId,month,pageNum,pageSize);
  console.log("tenantListBasedOnRisk"+tenantListBasedOnMediumHighRisk);
  
  setLoader(true);
  setHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setModerateHighRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
    setShowNoDataFound(false);

  if(mediumHighRiskCount==0 ){
    setMediumHighRisk(false);
    setLoader(false);
    setShowNoDataFound(true);
    setHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setModerateHighRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
 
  }else{
   setTimeout(() => {
    setLoader(false);
    setMediumHighRisk(true);
    
  setShowNoDataFound(false);
  }, 5000);
}
}
const showModerateHighRiskList=()=>{
  getTenantListBasedOnModerateHighRiskTenant(searchTerm,propertyId,userId,month,pageNum,pageSize);
  console.log("tenantListBasedOnModerateHighRisk"+tenantListBasedOnModerateHighRisk);

  setLoader(true);
  setHighRisk(false);
    setMediumHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
    setShowNoDataFound(false);

 
  if(moderateHighRiskCount==0 ){

    setLoader(false);
    setModerateHighRisk(false);
    setShowNoDataFound(true);
    setHighRisk(false);
    setMediumHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
   }
   else{

  setTimeout(() => {
    setLoader(false);
    setModerateHighRisk(true);
    
  setShowNoDataFound(false);
  }, 5000);
   }
}

const showHighRiskList=()=>{
  getTenantListBasedOnHighRiskTenant(searchTerm,propertyId,userId,month,pageNum,pageSize);
  console.log("tenantListBasedOnHighRisk"+tenantListBasedOnHighRisk);

  setLoader(true);
  setModerateHighRisk(false);
    setMediumHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
    setShowNoDataFound(false);

  if(highRiskCount==0 ){
    setLoader(false);
    setHighRisk(false);
    setShowNoDataFound(true);
    setModerateHighRisk(false);
    setMediumHighRisk(false);
    setMediumLowRisk(false);
    setLowRisk(false);
    setShowList(false);
    setShowSearchBar(false);
    setShowButton(true);
   }else{
  
    setTimeout(() => {
      setLoader(false);
    setHighRisk(true);
    
    setShowNoDataFound(false);
  }, 5000); 
}
}


          const getPropertyTenantSearchRequest = async (searchTerm,propertyId,pageNum,pageSize) => {
           
            await tmGetPropertyTenantSearchRequest(searchTerm,propertyId,pageNum,pageSize);
            console.log("pageNum"+pageNum);
            console.log("pageSize"+pageSize);
            console.log("searchTerm"+searchTerm);
            setSearchTerm(propertyTenantSearch.searchTerm);
      // console.log("propertyTenantSearch"+JSON.stringify(propertyTenantSearch));
      // setSearchTenantName(propertyTenantSearch.tenantName);
      // setSearchTenantist(propertyTenantSearch.tenantList);
   

      console.log("searchTenantList"+JSON.stringify(propertyTenantSearch.tenantList));
      
  
            };
  
        
           

        
     
        const getPreviousMonthTenantRiskCount = async (propertyId,userId) => {
    
          await   tmGetPreviousMonthTenantRiskCountRequest(propertyId,userId);
          
       console.log("previousMonthTenantRiskCount"+JSON.stringify(previousMonthTenantRiskCount));
       
       setPrevTotalTenantCount(previousMonthTenantRiskCount.totalTenantCount);
     
       setPreviousLowRiskCount(previousMonthTenantRiskCount.lowRiskCount);
      setPreviousMediumLowRiskCount(previousMonthTenantRiskCount.mediumLowRiskCount);
      setPreviousModerateHighRiskCount(previousMonthTenantRiskCount.moderateHighRiskCount);
      setPreviousMediumHighRiskCount(previousMonthTenantRiskCount.mediumHighRiskCount);
      setPreviousHighRiskCount(previousMonthTenantRiskCount.highRiskCount);

     
          };
     

          const getTenantRiskCountByPropertyGridView = async (propertyId,userId) => {
    
            await   tmGetTenantRiskCountByPropertyGridViewRequest(propertyId,userId);
            
         console.log("tenantRiskCountByPropertyGridView"+JSON.stringify(tenantRiskCountByPropertyGridView));
         
          
         setCurrTotalTenantCount(tenantRiskCountByPropertyGridView.totalTenantCount);
        setLowRiskCount(tenantRiskCountByPropertyGridView.lowRiskCount);
        setMediumLowRiskCount(tenantRiskCountByPropertyGridView.mediumLowRiskCount);
        setModerateHighRiskCount(tenantRiskCountByPropertyGridView.moderateHighRiskCount);
        setMediumHighRiskCount(tenantRiskCountByPropertyGridView.mediumHighRiskCount);
        setHighRiskCount(tenantRiskCountByPropertyGridView.highRiskCount);

        // setLowRiskPercent(tenantRiskCountByPropertyGridView.lowRiskPercent);
        // setMediumLowRiskPercent(tenantRiskCountByPropertyGridView.mediumLowRiskPercent);
        // setModerateHighRiskPercent(tenantRiskCountByPropertyGridView.moderateHighRiskPercent);
        // setMediumHighRiskPercent(tenantRiskCountByPropertyGridView.mediumHighRiskPercent);
        // setHighRiskPercent(tenantRiskCountByPropertyGridView.highRiskPercent);
       
            };

        
          function handleClickFundPropertyGridViewDashboard(event) {
  
            setShowBackNavigation(true);
        
            
           }

           function handleClickDashboard(event) {
  
            history.push({ 
              pathname: '/dashboard'
             });
          }
       
    
    const goback=()=>{
      //  history.push("/admin/dashboard");
      history.back();
    } 
    var now = moment();
    var monthArray=[];
    var yearArray=[];

    let currMonthName1 = moment().subtract(1, "month").format('MMM');
    let prevMonthName1 = moment().subtract(2, "month").format('MMM');


    let currMonthName2 = moment().subtract(1, "month").format('MMM').toLowerCase();
    let prevMonthName2 = moment().subtract(2, "month").format('MMM').toLowerCase();

     
    var currMonthName  =  moment().subtract(1, "month").format('MMM');
    var prev1MonthName  = moment().subtract(2, "month").format('MMM');
    var prev2MonthName  = moment().subtract(3, "month").format('MMM');
    var prev3MonthName  = moment().subtract(4, "month").format('MMM');
    var prev4MonthName  = moment().subtract(5, "month").format('MMM');
    var prev5MonthName  = moment().subtract(6, "month").format('MMM');
    var prev6MonthName  = moment().subtract(7, "month").format('MMM');
    var prev7MonthName  = moment().subtract(8, "month").format('MMM');
    var prev8MonthName  = moment().subtract(9, "month").format('MMM');
    var prev9MonthName  = moment().subtract(10, "month").format('MMM')
    var prev10MonthName  = moment().subtract(11, "month").format('MMM');
    var prev11MonthName  = moment().subtract(12, "month").format('MMM');

    var currYear  = moment().subtract(1, "month").format('YYYY').toString().slice(-2);
    var prev1MonthYear  = moment().subtract(2, "month").format('YYYY').toString().slice(-2);
    var prev2MonthYear  = moment().subtract(3, "month").format('YYYY').toString().slice(-2);
    var prev3MonthYear  = moment().subtract(4, "month").format('YYYY').toString().slice(-2);
    var prev4MonthYear  = moment().subtract(5, "month").format('YYYY').toString().slice(-2);
    var prev5MonthYear  = moment().subtract(6, "month").format('YYYY').toString().slice(-2);
    var prev6MonthYear  = moment().subtract(7, "month").format('YYYY').toString().slice(-2);
    var prev7MonthYear  = moment().subtract(8, "month").format('YYYY').toString().slice(-2);
    var prev8MonthYear  = moment().subtract(9, "month").format('YYYY').toString().slice(-2);
    var prev9MonthYear  = moment().subtract(10, "month").format('YYYY').toString().slice(-2);
    var prev10MonthYear  = moment().subtract(11, "month").format('YYYY').toString().slice(-2);
    var prev11MonthYear  = moment().subtract(12, "month").format('YYYY').toString().slice(-2);


    const month1=[prev11MonthName+"-"+prev11MonthYear,prev10MonthName+"-"+prev10MonthYear,prev9MonthName+"-"+prev9MonthYear,prev8MonthName+"-"+prev8MonthYear,prev7MonthName+"-"+prev7MonthYear,prev6MonthName+"-"+prev6MonthYear,prev5MonthName+"-"+prev5MonthYear,prev4MonthName+"-"+prev4MonthYear,prev3MonthName+"-"+prev3MonthYear,prev2MonthName+"-"+prev2MonthYear,prev1MonthName+"-"+prev1MonthYear,currMonthName+"-"+currYear];
  
  
    const lineChart = {
    
    options: {
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            fontSize: 15,
            fontColor: 'lightgrey'
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 15,
            fontColor: 'lightgrey',
            maxTicksLimit: 5,
            padding: 25,
          }
        }]
      },
      // title: {
      //   text: tenantName,
      // },
      chart: {
        height: 400,
        width: 800,
        type: 'line',
        
        dropShadow: {
          enabled: true,
          color: '#fff',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        }
        
      },
      
      
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      markers: {
        size: 3,
        color: '#00008b'
    },
  
  
    
   
    grid: {
      // show: true,      // you can either change hear to disable all grids
      // type: 'line',
      row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0.5
      }, 
      // column: {
      //     colors: ['#f8f8f8', 'transparent'],
      // }, 
  
      xAxes: [{
        gridLines: {
          display: false,
        },
        lines: {
          show: true
        },
      
      }],
      // xaxis: {
      //   lines: {
      //     show: true
      //   }
      // }
      // grid: {
      //   show: true,      // you can either change hear to disable all grids
      //   type: 'line',
        
      //   xaxis: {
      //     lines: {
      //       show: true //or just here to disable only x axis grids
      //      }
      //    } 
        }, 
      xaxis: 
      
        {
        categories:  month1,
        labels: {
          show: true,
          style: {
            fontSize: "5px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
              },
  
              axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#000000',
                height: 4,
                offsetX: 0,
                offsetY: 0
            },
              
              // tickColor:"#ffffff"    
      },
      yaxis: {
  
       
        show: true,
        labels: {
          show: true,
          style: {
            fontSize: "7px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
              },
      },
      
    },
    
    
    };

    const lineChart2 = {
    
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 15,
              fontColor: 'lightgrey'
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: 'lightgrey',
              maxTicksLimit: 5,
              padding: 25,
            }
          }]
        },
        // title: {
        //   text: tenantName,
        // },
        chart: {
          height: 400,
          width: 800,
          type: 'line',
          
          dropShadow: {
            enabled: true,
            color: '#fff',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false
          }
          
        },
        
        
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        markers: {
          size: 3,
          color: '#00008b'
      },
    
    
      
     
      grid: {
        // show: true,      // you can either change hear to disable all grids
        // type: 'line',
        row: {
            colors: ['#e5e5e5', 'transparent'],
            opacity: 0.5
        }, 
        // column: {
        //     colors: ['#f8f8f8', 'transparent'],
        // }, 
    
        xAxes: [{
          gridLines: {
            display: false,
          },
          lines: {
            show: true
          },
        
        }],
        // xaxis: {
        //   lines: {
        //     show: true
        //   }
        // }
        // grid: {
        //   show: true,      // you can either change hear to disable all grids
        //   type: 'line',
          
        //   xaxis: {
        //     lines: {
        //       show: true //or just here to disable only x axis grids
        //      }
        //    } 
          }, 
        xaxis: {
          
          categories:  month1,
          labels: {
            show: true,
            style: {
              fontSize: "5px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
    
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#000000',
                  height: 4,
                  offsetX: 0,
                  offsetY: 0
              },
                
                // tickColor:"#ffffff"    
        },
        yaxis: {
    
         
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: "7px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
        },
        
      },
      
      
      };
    
      const lineChart3 = {
    
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
              ticks: {
                fontSize: 15,
                fontColor: 'lightgrey'
              }
            }],
            yAxes: [{
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 15,
                fontColor: 'lightgrey',
                maxTicksLimit: 5,
                padding: 25,
              }
            }]
          },
          // title: {
          //   text: tenantName,
          // },
          chart: {
            height: 400,
            width: 800,
            type: 'line',
            
            dropShadow: {
              enabled: true,
              color: '#fff',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            
            zoom: {
              enabled: true
            },
            toolbar: {
              show: false
            }
            
          },
          
          
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
          },
          markers: {
            size: 3,
            color: '#00008b'
        },
      
      
        
       
        grid: {
          // show: true,      // you can either change hear to disable all grids
          // type: 'line',
          row: {
              colors: ['#e5e5e5', 'transparent'],
              opacity: 0.5
          }, 
          // column: {
          //     colors: ['#f8f8f8', 'transparent'],
          // }, 
      
          xAxes: [{
            gridLines: {
              display: false,
            },
            lines: {
              show: true
            },
          
          }],
          // xaxis: {
          //   lines: {
          //     show: true
          //   }
          // }
          // grid: {
          //   show: true,      // you can either change hear to disable all grids
          //   type: 'line',
            
          //   xaxis: {
          //     lines: {
          //       show: true //or just here to disable only x axis grids
          //      }
          //    } 
            }, 
          xaxis: {
            
            categories:  month1,
            labels: {
              show: true,
              style: {
                fontSize: "5px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold"
              },
                  },
      
                  axisBorder: {
                    show: true,
                    color: '#78909C',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#000000',
                    height: 4,
                    offsetX: 0,
                    offsetY: 0
                },
                  
                  // tickColor:"#ffffff"    
          },
          yaxis: {
      
           
            show: true,
            labels: {
              show: true,
              style: {
                fontSize: "7px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold"
              },
                  },
          },
          
        },
        
        
        };

        const lineChart4 = {
    
          options: {
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 15,
                  fontColor: 'lightgrey'
                }
              }],
              yAxes: [{
                gridLines: {
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                  fontSize: 15,
                  fontColor: 'lightgrey',
                  maxTicksLimit: 5,
                  padding: 25,
                }
              }]
            },
            // title: {
            //   text: tenantName,
            // },
            chart: {
              height: 400,
              width: 800,
              type: 'line',
              
              dropShadow: {
                enabled: true,
                color: '#fff',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              
              zoom: {
                enabled: true
              },
              toolbar: {
                show: false
              }
              
            },
            
            
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight',
              lineCap: 'butt',
              colors: undefined,
              width: 2,
              dashArray: 0,
            },
            markers: {
              size: 3,
              color: '#00008b'
          },
        
        
          
         
          grid: {
            // show: true,      // you can either change hear to disable all grids
            // type: 'line',
            row: {
                colors: ['#e5e5e5', 'transparent'],
                opacity: 0.5
            }, 
            // column: {
            //     colors: ['#f8f8f8', 'transparent'],
            // }, 
        
            xAxes: [{
              gridLines: {
                display: false,
              },
              lines: {
                show: true
              },
            
            }],
            // xaxis: {
            //   lines: {
            //     show: true
            //   }
            // }
            // grid: {
            //   show: true,      // you can either change hear to disable all grids
            //   type: 'line',
              
            //   xaxis: {
            //     lines: {
            //       show: true //or just here to disable only x axis grids
            //      }
            //    } 
              }, 
            xaxis: {
              
              categories:  month1,
              labels: {
                show: true,
                style: {
                  fontSize: "5px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: "bold"
                },
                    },
        
                    axisBorder: {
                      show: true,
                      color: '#78909C',
                      height: 1,
                      width: '100%',
                      offsetX: 0,
                      offsetY: 0
                  },
                  axisTicks: {
                      show: true,
                      borderType: 'solid',
                      color: '#000000',
                      height: 4,
                      offsetX: 0,
                      offsetY: 0
                  },
                    
                    // tickColor:"#ffffff"    
            },
            yaxis: {
        
             
              show: true,
              labels: {
                show: true,
                style: {
                  fontSize: "7px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: "bold"
                },
                    },
            },
            
          },
          
          
          };

          const lineChart5 = {
    
            options: {
              scales: {
                xAxes: [{
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    fontSize: 15,
                    fontColor: 'lightgrey'
                  }
                }],
                yAxes: [{
                  gridLines: {
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    fontSize: 15,
                    fontColor: 'lightgrey',
                    maxTicksLimit: 5,
                    padding: 25,
                  }
                }]
              },
              // title: {
              //   text: tenantName,
              // },
              chart: {
                height: 400,
                width: 800,
                type: 'line',
                
                dropShadow: {
                  enabled: true,
                  color: '#fff',
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2
                },
                
                zoom: {
                  enabled: true
                },
                toolbar: {
                  show: false
                }
                
              },
              
              
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
              },
              markers: {
                size: 3,
                color: '#00008b'
            },
          
          
            
           
            grid: {
              // show: true,      // you can either change hear to disable all grids
              // type: 'line',
              row: {
                  colors: ['#e5e5e5', 'transparent'],
                  opacity: 0.5
              }, 
              // column: {
              //     colors: ['#f8f8f8', 'transparent'],
              // }, 
          
              xAxes: [{
                gridLines: {
                  display: false,
                },
                lines: {
                  show: true
                },
              
              }],
              // xaxis: {
              //   lines: {
              //     show: true
              //   }
              // }
              // grid: {
              //   show: true,      // you can either change hear to disable all grids
              //   type: 'line',
                
              //   xaxis: {
              //     lines: {
              //       show: true //or just here to disable only x axis grids
              //      }
              //    } 
                }, 
              xaxis: {
                
                categories:  month1,
                labels: {
                  show: true,
                  style: {
                    fontSize: "5px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold"
                  },
                      },
          
                      axisBorder: {
                        show: true,
                        color: '#78909C',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#000000',
                        height: 4,
                        offsetX: 0,
                        offsetY: 0
                    },
                      
                      // tickColor:"#ffffff"    
              },
              yaxis: {
          
               
                show: true,
                labels: {
                  show: true,
                  style: {
                    fontSize: "7px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold"
                  },
                      },
              },
              
            },
            
            
            };
            const lineChart6 = {
    
              options: {
                scales: {
                  xAxes: [{
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontSize: 15,
                      fontColor: 'lightgrey'
                    }
                  }],
                  yAxes: [{
                    gridLines: {
                      drawBorder: false,
                    },
                    ticks: {
                      beginAtZero: true,
                      fontSize: 15,
                      fontColor: 'lightgrey',
                      maxTicksLimit: 5,
                      padding: 25,
                    }
                  }]
                },
                // title: {
                //   text: tenantName,
                // },
                chart: {
                  height: 400,
                  width: 800,
                  type: 'line',
                  
                  dropShadow: {
                    enabled: true,
                    color: '#fff',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                  },
                  
                  zoom: {
                    enabled: true
                  },
                  toolbar: {
                    show: false
                  }
                  
                },
                
                
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight',
                  lineCap: 'butt',
                  colors: undefined,
                  width: 2,
                  dashArray: 0,
                },
                markers: {
                  size: 3,
                  color: '#00008b'
              },
            
            
              
             
              grid: {
                // show: true,      // you can either change hear to disable all grids
                // type: 'line',
                row: {
                    colors: ['#e5e5e5', 'transparent'],
                    opacity: 0.5
                }, 
                // column: {
                //     colors: ['#f8f8f8', 'transparent'],
                // }, 
            
                xAxes: [{
                  gridLines: {
                    display: false,
                  },
                  lines: {
                    show: true
                  },
                
                }],
                // xaxis: {
                //   lines: {
                //     show: true
                //   }
                // }
                // grid: {
                //   show: true,      // you can either change hear to disable all grids
                //   type: 'line',
                  
                //   xaxis: {
                //     lines: {
                //       show: true //or just here to disable only x axis grids
                //      }
                //    } 
                  }, 
                xaxis: {
                  
                  categories:  month1,
                  labels: {
                    show: true,
                    style: {
                      fontSize: "5px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: "bold"
                    },
                        },
            
                        axisBorder: {
                          show: true,
                          color: '#78909C',
                          height: 1,
                          width: '100%',
                          offsetX: 0,
                          offsetY: 0
                      },
                      axisTicks: {
                          show: true,
                          borderType: 'solid',
                          color: '#000000',
                          height: 4,
                          offsetX: 0,
                          offsetY: 0
                      },
                        
                        // tickColor:"#ffffff"    
                },
                yaxis: {
            
                 
                  show: true,
                  labels: {
                    show: true,
                    style: {
                      fontSize: "7px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: "bold"
                    },
                        },
                },
                
              },
              
              
              };
    const lineChart1 = {
    
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 15,
              fontColor: 'lightgrey'
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: 'lightgrey',
              maxTicksLimit: 5,
              padding: 25,
            }
          }]
        },
        // title: {
        //   text: tenantName,
        // },
        chart: {
          height: 400,
          width: 800,
          type: 'line',
          
          dropShadow: {
            enabled: true,
            color: '#fff',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false
          }
          
        },
        
          
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 5,
          dashArray: 0,
        },
        markers: {
          size: 5,
          color: '#00008b'
      },
    
    
      
     
      grid: {
        // show: true,      // you can either change hear to disable all grids
        // type: 'line',
        row: {
            colors: ['#e5e5e5', 'transparent'],
            opacity: 0.5
        }, 
        // column: {
        //     colors: ['#f8f8f8', 'transparent'],
        // }, 
    
        xAxes: [{
          gridLines: {
            display: false,
          },
          lines: {
            show: true
          },
        
        }],
        // xaxis: {
        //   lines: {
        //     show: true
        //   }
        // }
        // grid: {
        //   show: true,      // you can either change hear to disable all grids
        //   type: 'line',
          
        //   xaxis: {
        //     lines: {
        //       show: true //or just here to disable only x axis grids
        //      }
        //    } 
          }, 
        xaxis: {
          
          categories:  month1,
          labels: {
            show: true,
            style: {
              fontSize: "11px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
    
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#000000',
                  height: 4,
                  offsetX: 0,
                  offsetY: 0
              },
                
                // tickColor:"#ffffff"    
        },
        yaxis: {
    
         
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
                title: {
                  text: 'Tracker Score',
                  style:{
                    fontSize:"19px",
                    fontWeight:"bold",
                    fontColor:"black",
                   
                  },
                },
        },
        
      },
      
      
      };
    
      const divStyles={
      
        // padding: 10,
        // margin: "auto",
        textAlign:"justify",
        // gridGap: "30px",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr 2fr)",
        // columnGap:"30%",
        // position: "absolute",
        // left: "100px",
        // justifyContent:"space-between",
      
       
      }
      const divStyles1={
      
        // padding: 10,
        // margin: "auto",
        textAlign:"justify",
        // gridGap: "30px",
        display: "grid",
        fontSize:"12px",
        fontWeight:"bold",
        marginLeft:"40px",
        cursor:"pointer"

        // gridTemplateColumns: "repeat(4, 1fr 2fr)",
        // columnGap:"30%",
        // position: "absolute",
        // left: "100px",
        // justifyContent:"space-between",
      
       
      }

 if(dataStatus){
    return (
  <div>
    {!showBackNavigation&&showFundPropertyGridView&&
    <Card>
      <br></br>
<GridContainer>


      <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px"}}>
        
      <img className={classes.cardTitle}   src={require("/home/ubuntu/tm-images/property/"+imageUrl)} alt="Card-img" height="160"  />
      {/* <img className={classes.cardTitle}   src={require("/home/adminuser/projects/tenantmonitoring/property-image-uploads/"+imageUrl)} alt="Card-img" height="160"  /> */}



      <div > 
<h3  style={{fontSize:"17px",color:megaColor}}><b>{propertyName}</b></h3>
</div>     
<div className={classes.Inlinetop}>
        <div>
            <span >  
            <h4 className={classes.gradient} style={{fontWeight:"bold",fontSize:"12px",paddingTop:"15px",lineHeight:"0px"}}>{propertyAddress}</h4>
<h4 className={classes.gradient1} style={{marginRight:"25px"}}><b  style={{color:colorCode,fontSize:"21px"}}>{score}</b> 
<b>{currentStatus==='up' ?          <Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
<ArrowUpwardIcon  style={{color:"green",fontSize:"22px"}}  
/></Tooltip> : currentStatus==='down' ?<Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
<ArrowDownwardIcon style={{color:"red",fontSize:"22px"}} /></Tooltip>:          <Tooltip classes={{ tooltip: classes.customWidth }} title="Trend is comparison of current month score vs average of last 11 months" >
<TrendingFlatIcon style={{color:"green",fontSize:"22px"}}/></Tooltip>}</b></h4>
              </span>
              </div>
              </div>
            
            
            
       
                      {/* <h5 align="center" className={classes.cardTitles}><b >Rent Roll Score </b></h5> */}
           
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
     
      <div className={classes.Inlinetop}>
        <div>
            <span > 
              
               <Link className={classes.gradient} style={{cursor:"pointer"}} href="#" onClick={handleClickDashboard}>Dashboard</Link>  
{/* <Link className={classes.gradient} style={{cursor:"pointer"}} onClick={handleClickFundPropertyGridViewDashboard}> &nbsp;Back</Link> */}

            <div className={classes.gradient1} >
                <Button color="transparent" size="sm" className={classes.firstButton} >
                <b><h7 style={{color:'red',fontSize:"11px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton} >
                <b ><h7 style={{color:'green',fontSize:"11px"}}>Low Risk</h7></b>
                </Button>
              </div>
                  
 </span>
        </div>
    </div>


<div className={classes.Inlinetop}>
        <div>
            <span > {showCurrentCount&&<h4 className={classes.gradient} style={{fontSize:"18px",fontWeight:"bold"}}>{currMonthName1+"-"+currYear} Tenant Risk Count: {currTotalTenantCount}</h4>}
            {showPreviousCount&&<h4 className={classes.gradient} style={{fontSize:"18px",fontWeight:"bold"}}>{prevMonthName1+"-"+prev1MonthYear} Tenant Risk Count: {prevTotalTenantCount}</h4>}
            
           
                   <div className={classes.gradient1} >
                     
                     <Button  style = {{color:'white',backgroundColor:'#f44336'}} size="sm" className={classes.firstButton}>
                    
                     </Button>
                     <Button style = {{color:'white',backgroundColor:'#ff9800'}} size="sm" className={classes.middleButton}>
                   
                     </Button>
                     <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                  
                     </Button>
                     <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                     
                     </Button>
                     <Button style = {{color:'white',backgroundColor:'#4caf50'}}  size="sm" className={classes.lastButton}>
                   
                     </Button>
                   </div>
 </span>
        </div>
        <h4 className={classes.gradient} style={{fontSize:"15px",fontWeight:"bold"}}>Rentable Building Area (RBA): {propertyEditObj.rba}</h4>
        {/* <h4 className={classes.gradient} style={{fontSize:"15px",fontWeight:"bold"}}>Occupied SQFT %: {occupiedSqftPercentage.toFixed(2)}</h4> */}

    </div>
    <br></br>
    
     {showCurrentCount&& <div className={classes.Inlinetop1}>
        <div>
            <span > 
           

              <Card className={classes.root} onClick={showHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>High Risk</h3>
              <h3 align="center"><Button round  style={{color: "#f44336",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {highRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#f44336",textShadow: "3px 3px 5px grey"}}> {highRiskCount}</h3> */}
            </Card>

            <Card className={classes.root1} onClick={showMediumHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium High Risk</h3>
              <h3 align="center"><Button round  style={{color: "#ff9800",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {mediumHighRiskCount} </Button></h3>

                 {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ff9800"}}> {mediumHighRiskCount}</h3> */}
            </Card>
            <Card className={classes.root2} onClick={showModerateHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Moderate Risk </h3>
              <h3 align="center"><Button round  style={{color: "#ffdf00",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {moderateHighRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ffdf00"}}> {moderateHighRiskCount}</h3> */}
            </Card>
            <Card className={classes.root4} onClick={showLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Low Risk </h3>
              <h3 align="center"><Button round  style={{color: "#4caf50",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {lowRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#4caf50"}}> {lowRiskCount}</h3> */}
            </Card>
            <Card className={classes.root3} onClick={showMediumLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium Low Risk </h3>
              <h3 align="center"><Button round  style={{color: "#32cd32",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {mediumLowRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#32cd32"}}> {mediumLowRiskCount}</h3> */}
          </Card>
            
 </span>
        </div>
    </div>}
<br></br>
    {showPreviousCount&&<div className={classes.Inlinetop1}>
        <div>
            <span > 
            <Card className={classes.root} onClick={showHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>High Risk</h3>
              <h3 align="center"><Button round  style={{color: "#f44336",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {previousHighRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#f44336",textShadow: "3px 3px 5px grey"}}> {highRiskCount}</h3> */}
            </Card>

            <Card className={classes.root1} onClick={showMediumHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium High Risk</h3>
              <h3 align="center"><Button round  style={{color: "#ff9800",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {previousMediumHighRiskCount} </Button></h3>

                 {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ff9800"}}> {mediumHighRiskCount}</h3> */}
            </Card>
            <Card className={classes.root2} onClick={showModerateHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Moderate Risk </h3>
              <h3 align="center"><Button round  style={{color: "#ffdf00",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {previousModerateHighRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ffdf00"}}> {moderateHighRiskCount}</h3> */}
            </Card>

            <Card className={classes.root4} onClick={showLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Low Risk </h3>
              <h3 align="center"><Button round  style={{color: "#4caf50",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {previousLowRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#4caf50"}}> {lowRiskCount}</h3> */}
            </Card>
            
            <Card className={classes.root3} onClick={showMediumLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium Low Risk </h3>
              <h3 align="center"><Button round  style={{color: "#32cd32",backgroundColor:"#ffffff",border:"1px solid #D3D3D3",fontSize:'20px',fontWeight:"bold",borderRadius:30,width:"3px",height:"30px"}} > {previousMediumLowRiskCount} </Button></h3>

              {/* <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#32cd32"}}> {mediumLowRiskCount}</h3> */}
          </Card>
            

              {/* <Card className={classes.root} onClick={showHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>High Risk</h3>
              <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#f44336"}}> {previousHighRiskCount}</h3>
            </Card>

            <Card className={classes.root1} onClick={showMediumHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium High Risk</h3>
                 <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ff9800"}}> {previousMediumHighRiskCount}</h3>
            </Card>
            <Card className={classes.root2} onClick={showModerateHighRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Moderate Risk </h3>
              <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#ffdf00"}}> {previousModerateHighRiskCount}</h3>
            </Card>
            <Card className={classes.root3} onClick={showMediumLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Medium Low Risk </h3>
              <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#32cd32"}}> {previousMediumLowRiskCount}</h3>
          </Card>
            <Card className={classes.root4} onClick={showLowRiskList}>
              <h3 align="center" style={{fontSize:"12px",fontWeight:"bold",color:megaColor,lineHeight: "0px"}}>Low Risk </h3>
              <h3 align="center" style={{fontSize:"20px",fontWeight:"bold",color:"#4caf50"}}> {previousLowRiskCount}</h3>
            </Card> */}
 </span>
        </div>
    </div>}
    </GridItem>

    <GridItem  xs={12} sm={12} md={12} align="right" style={{paddingRight:"100px",cursor:"pointer"}}>
      <div>
      <Link onClick={showPreviousMonthCount}>Previous Month |</Link>
      <Link onClick={showCurrentMonthCount}> Current Month</Link>

      </div>
       </GridItem>
    <GridItem>
 <hr />
        </GridItem>

     <GridItem xs={12} sm={2} md={2} align="left" style={{marginLeft:"50px",cursor:"pointer"}}>
     {showButton&& <Link  onClick={showTenantList}>Show Tenants</Link>}
    
   
</GridItem>
   

  {showSearchBar&&showDataFound&&<GridItem  xs={12} sm={10} md={9} align="right" >
<SearchField
  placeholder="Search Tenant..."
  // onClick={() =>{submit(prop.id)}}
  // onSearchClick={() =>{onSearchClick(searchTerm,propertyId,pageNum,pageSize)}}
  onSearchClick={onSearchClick}
  classNames="test-class"

  value={propertyTenantSearch.searchTerm}

 
/>

{showSearch&&<Button color="info" size="sm" onClick={handleCancel} style={{fontSize:16, fontWeight: "bold"}}>
        <CloseIcon/>  &nbsp;&nbsp;Clear  
        </Button>}
</GridItem>}


{showList&&showDataFound&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{propertyTenantSearch.tenantList.map(search => (
  <GridItem xs={12} sm={12} md={3} >
    <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification',
                           state: {tenantName:search.tenantName,tenantId:search.tenantId,propertyId:search.propertyId,propertyName:propertyName,fundId:fundId,fundName:fundName,imageUrl:imageUrl,userId:userId,propertyAddress:propertyAddress}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{search.tenantName}</h3>

              {/* <h3 style={divStyles1} 
              onClick={() => handleClickOpenMonitoredTenants({tenantName:search.tenantName,tenantId:search.tenantId,propertyId:search.propertyId,propertyName:propertyName,fundId:fundId,fundName:fundName,userId:userId})}

               >{search.tenantName}</h3> */}
   <Chart
        style={divStyles}
        options={lineChart.options}
        series={search.series}
      //  xaxis={search.xaxis}
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
    
        onClick={() => handleClickOpenTrend({tenantName:search.tenantName,tenantId:search.tenantId,series:search.series})}
    
      />
  </GridItem>
   ))} 

{/* <div>
          <Dialog 
  maxWidth={'md'} open={openMonitoredTenants} onClose={handleCloseMonitoredTenants} fullWidth={true} aria-labelledby="form-dialog-title">
         {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
         {/* <GridItem align="right" paddingTop="20px">
          <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseMonitoredTenants}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent>
    
        <TenantNotification tenantId={tenantId} tenantName={tenantName} />
          </DialogContent>
        
            </Dialog>
            </div>  */} 
</GridContainer> 
 }


{lowRisk&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{tenantListBasedOnLowRisk.tenantList.map(lowRisk => (
  <GridItem xs={12} sm={12} md={3} >
   <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification-fm',
                           state: {tenantName:lowRisk.tenantName,tenantId:lowRisk.tenantId}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{lowRisk.tenantName}</h3>
   <Chart
           style={divStyles}
        options={lineChart2.options}
        series={lowRisk.series}
       
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
        // marginLeft="300"
        onClick={() => handleClickOpenTrend({tenantName:lowRisk.tenantName,tenantId:lowRisk.tenantId,series:lowRisk.series})}
    
      />
  </GridItem>
   ))} 
</GridContainer> }

{mediumLowRisk&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{tenantListBasedOnMediumLowRisk.tenantList.map(mediumLowRisk => (
  <GridItem xs={12} sm={12} md={3}>
   <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification-fm',
                           state: {tenantName:mediumLowRisk.tenantName,tenantId:mediumLowRisk.tenantId}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{mediumLowRisk.tenantName}</h3>
   <Chart
         style={divStyles}
        options={lineChart3.options}
        series={mediumLowRisk.series}
       
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
        // marginLeft="300"
        onClick={() => handleClickOpenTrend({tenantName:mediumLowRisk.tenantName,tenantId:mediumLowRisk.tenantId,series:mediumLowRisk.series})}
    
      />
  </GridItem>
   ))} 
</GridContainer> }

{mediumHighRisk&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{tenantListBasedOnMediumHighRisk.tenantList.map(mediumHighRisk => (
  <GridItem xs={12} sm={12} md={3} >
   <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification-fm',
                           state: {tenantName:mediumHighRisk.tenantName,tenantId:mediumHighRisk.tenantId}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{mediumHighRisk.tenantName}</h3>
   <Chart
         style={divStyles}
        options={lineChart4.options}
        series={mediumHighRisk.series}
       
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
        // marginLeft="300"
        onClick={() => handleClickOpenTrend({tenantName:mediumHighRisk.tenantName,tenantId:mediumHighRisk.tenantId,series:mediumHighRisk.series})}
    
      />
  </GridItem>
   ))} 
</GridContainer> }

{moderateHighRisk&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{tenantListBasedOnModerateHighRisk.tenantList.map(moderateHighRisk => (
  <GridItem xs={12} sm={12} md={3}>
   <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification-fm',
                           state: {tenantName:moderateHighRisk.tenantName,tenantId:moderateHighRisk.tenantId}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{moderateHighRisk.tenantName}</h3>
   <Chart
         style={divStyles}
        options={lineChart5.options}
        series={moderateHighRisk.series}
       
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
        // marginLeft="300"
        onClick={() => handleClickOpenTrend({tenantName:moderateHighRisk.tenantName,tenantId:moderateHighRisk.tenantId,series:moderateHighRisk.series})}
    
      />
  </GridItem>
   ))} 
</GridContainer> }


{highRisk&&<GridContainer style={{marginLeft:"20px",marginRight:"20px"}}>
{tenantListBasedOnHighRisk.tenantList.map(highRisk => (
  <GridItem xs={12} sm={12} md={3} >
  <h3 style={divStyles1} onClick={() =>history.push({ 
                           pathname: '/admin/tenantNotification-fm',
                           state: {tenantName:highRisk.tenantName,tenantId:highRisk.tenantId}
                        
                        
                         }) }
    //  onClick={()=> <Link to={{pathname:'/admin/tenantNotification-fm/', state :{tenantId : search.tenantId, contifyCompanyId:search.tenantContifyId, naics:search.naics, accountNumber : search.bankoAccountNumber,tenantName:search.tenantName} } }>{search.tenantName}</Link>
            >{highRisk.tenantName}</h3>
   <Chart
         style={divStyles}
        options={lineChart6.options}
        series={highRisk.series}
       
        type="line"
        width="250"
        height="170"
        // lineThickness= "5"
        // marginLeft="300"
        onClick={() => handleClickOpenTrend({tenantName:highRisk.tenantName,tenantId:highRisk.tenantId,series:highRisk.series})}
    
      />
  </GridItem>
   ))} 
</GridContainer> }
            <br></br>
        
            <GridItem xs={12} sm={12} md={3}></GridItem>


            {showList&&showDataFound&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={propertyTenantSearch.totalPages} page={page} onChange={handlePageChange} size="large" />
</div>

</GridItem>}

{lowRisk&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}}>
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListBasedOnLowRisk.totalPages} page={page} onChange={handlePageChangeLowRisk} size="large" />
</div>

</GridItem>}

{mediumLowRisk&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListBasedOnMediumLowRisk.totalPages} page={page} onChange={handlePageChangeMediumLowRisk} size="large" />
</div>

</GridItem>}

{mediumHighRisk&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListBasedOnMediumHighRisk.totalPages} page={page} onChange={handlePageChangeMediumHighRisk} size="large" />
</div>

</GridItem>}
{moderateHighRisk&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListBasedOnModerateHighRisk.totalPages} page={page} onChange={handlePageChangeModerateHighRisk} size="large" />
</div>

</GridItem>}

{highRisk&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListBasedOnHighRisk.totalPages} page={page} onChange={handlePageChangeHighRisk} size="large" />
</div>

</GridItem>}


{showNoDataFound&&<GridItem align="center" >
              <h3><b>No Data Available...</b></h3>
            </GridItem>
  }

  
<br></br>

<div>
          <Dialog 
  maxWidth={'md'} open={openTrend} onClose={handleCloseTrend} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
         <GridItem align="right" paddingTop="10px">
          <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseTrend}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent style={{overflow: "hidden" }}>

          <h3 align="center" style={{fontSize:"26px", fontWeight:"bold"}}>{tenantName}</h3>
         <div id="chart" className={classes.chart} >
          {/* {showFundGridView&& fundApexChartData.map(fundChart => (  */}
         
           <Chart
        
        tenantId={tenantId}
        tenantName={tenantName}
        options={lineChart1.options}
        series={series}
    type="line"
    width="800"
    height="400"
    // lineThickness= "5"
    // marginLeft="300"


  />
  {/* ))}  */}
  </div>
          </DialogContent>
        
            </Dialog>
            </div> 
          {/* <Dialog 
  maxWidth={'md'} open={openTrend} onClose={handleCloseTrend} fullWidth={true} aria-labelledby="form-dialog-title"> */}
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
         {/* <GridItem align="right" paddingTop="20px">
          <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseTrend}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent style={{overflow: "hidden" }}>

          <h3 align="center" style={{fontSize:"18px", fontWeight:"bold"}}>{tenantName}</h3>
         <div id="chart" align="center"  >
          {/* {showFundGridView&& fundApexChartData.map(fundChart => (  */}
{/*          
           <Chart 
    tenantId={tenantId}
    tenantName={tenantName}
    options={lineChart1.options}
    series={series}
    type="line"
    width="530"
    height="300"

 

  />
  </div>
    </DialogContent>
        
        </Dialog> */}
      
  {/* ))}  */}

 

    </GridContainer>
    {loader&&<div align="center">
  <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={5000} />
  </div>}
  
    </Card>}
    {showBackNavigation&&
  

  <FundPropertyGridView fundName={fundName} fundId={fundId} propertyId={propertyId} propertyName={propertyName} backNavigation={true}/>

}


    </div> 
    

    );
     



    
}else{

return(
  <div align="center">
  <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={5000} />
  </div>
)

}
}


MonitoredTenantsPropertyDetails.propTypes = {
    // tmNotificationList: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     tenantId: PropTypes.number,
    //     contifyId: PropTypes.number,
    //   }),
    // ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmGetTenantScoreGraphDataByPropertyIdAndUserIdRequest: PropTypes.func.isRequired,
    tmFundPropertyApexChartDataRequest: PropTypes.func.isRequired,
    tmFundDashboardCountRequest: PropTypes.func.isRequired,
    tmFundDashboardGridViewRequest: PropTypes.func.isRequired,
    tmGetFundPropertyGridViewDashboardRequest: PropTypes.func.isRequired,
    tmGetTenantRiskCountByPropertyGridViewRequest: PropTypes.func.isRequired,
    tmGetTenantBarGraphDataRequest: PropTypes.func.isRequired,
    tmGetPropertyTenantLineChartRequest: PropTypes.func.isRequired,
    tmCreditScoreChartCompanyListRequest: PropTypes.func.isRequired,
    tmGetPropertyTenantLineChartPaginationRequest: PropTypes.func.isRequired,
    tmGetPropertyTenantSearchRequest: PropTypes.func.isRequired,
    tmGetPreviousMonthTenantRiskCountRequest: PropTypes.func.isRequired,
    tmGetTenantListBasedOnLowRiskRequest: PropTypes.func.isRequired,
    tmGetTenantListBasedOnMediumLowRiskRequest: PropTypes.func.isRequired,
    tmGetTenantListBasedOnMediumHighRiskRequest: PropTypes.func.isRequired,
    tmGetTenantListBasedOnModerateHighRiskRequest: PropTypes.func.isRequired,
    tmGetTenantListBasedOnHighRiskRequest: PropTypes.func.isRequired,
    tmPropertyByIdRequest:PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tenantScoreGraphDataByPropertyIdAnduserId: state.tenantmonitoring.tenantScoreGraphDataByPropertyIdAnduserId,
    fundPropertyApexChartData: state.tenantmonitoring.fundPropertyApexChartData,
    fundDasboardCount: state.tenantmonitoring.fundDasboardCount,
    fundDashboarGridView: state.tenantmonitoring.fundDashboarGridView,
    fundPropertyGridViewDashboard: state.tenantmonitoring.fundPropertyGridViewDashboard,
    tenantRiskCountByPropertyGridView: state.tenantmonitoring.tenantRiskCountByPropertyGridView,
    // propertyTenantLineChart: state.tenantmonitoring.propertyTenantLineChart,
    propertyTenantLineChartPagination: state.tenantmonitoring.propertyTenantLineChartPagination,
    propertyTenantSearch: state.tenantmonitoring.propertyTenantSearch,
    previousMonthTenantRiskCount: state.tenantmonitoring.previousMonthTenantRiskCount,
    tenantListBasedOnLowRisk: state.tenantmonitoring.tenantListBasedOnLowRisk,
    tenantListBasedOnMediumLowRisk: state.tenantmonitoring.tenantListBasedOnMediumLowRisk,
    tenantListBasedOnMediumHighRisk: state.tenantmonitoring.tenantListBasedOnMediumHighRisk,
    tenantListBasedOnModerateHighRisk: state.tenantmonitoring.tenantListBasedOnModerateHighRisk,
    tenantListBasedOnHighRisk: state.tenantmonitoring.tenantListBasedOnHighRisk,
    propertyEditObj: state.tenantmonitoring.propertyEditObj,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MonitoredTenantsPropertyDetails);
