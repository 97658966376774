import React from 'react';
import styled, { css } from 'styled-components';
import { MenuBaseContainer, renderMenuItems } from './MenuBaseProperty.js';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
const setAlignment = ({ align }) => {
  switch (align) {
    case 'right':
      return css`
        right: 0;
        top: 0;
        bottom: 0;
        flex-flow: column nowrap;
      `;
    case 'top':
      return css`
        top: 0;
        left: 0;
        right: 0;
        flex-flow: row nowrap;
      `;
    case 'bottom':
      return css`
        bottom: 0;
        left: 0;
        right: 0;
        flex-flow: row nowrap;
      `;
    case 'left':
    default:
      return css`
        left: 0;
        top: 0;
        bottom: 0;
        flex-flow: column nowrap;
      `;
  }
};

export const SideMenuBaseContainer = styled(MenuBaseContainer)`
display: flex;
list-style: none;
margin-top :25px;
justify-content: space-around;
text-align:right;
position: relative;
  ${props => setAlignment(props)};
  
  li {
    color:#00628B;
    margin-bottom: 45px;
    margin-left: 30px;
    min-width: 10px;
    min-height: 5px; 
    max-height: 100px;
    font-size: 15px;
    display: flex;
    text-align: right;
    align-items: right;
    justify-content: flex-end;
    flex: 1 0 0;
    float: right;
    &:hover {
      background-color: D3D3D3;
      color: #00628B;
      cursor: pointer;
    }
    
    &.selected {
      background-color: blue;
      color: white;
    }
  }
`;

const SideMenuBase = (props) => {
  const { align } = props;
  return (
    <SideMenuBaseContainer
      { ...props }
      direction={align === 'top' || 'bottom' ? 'horizontal' : 'vertical'}
    >
      { renderMenuItems(props) }
    </SideMenuBaseContainer>
  );
};

export default SideMenuBase;