import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
//angel
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import TMHeatMapTenantRisk from "components/Charts/TMHeatMapTenantRisk.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import moment from 'moment';

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { textAlign } from "@material-ui/system";
import { Tooltip } from "@material-ui/core";
import TMAMHeatMapTenantRisk from "./TMAMHeatMapTenantRisk";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  gridBorder:{
    border: 1, 
    borderColor: '#D3D3D3',
    borderStyle: 'solid',
    paddingLeft:'20px'
    
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  vertical: { 
              padding:"10px"
             
            } ,
    customWidth: {
      maxWidth: 200,
      fontSize: "15px",
      backgroundColor: megaColor,
    }
};

const useStyles = makeStyles(styles);

const MTCredReportHeatMap = ({user,tenantName,tenantAddress,tenantId,month,month1,year,scoreType,creditScoreData, pirsMonthNameValue,tenantsScoreByType,tmTenantsScoreByTypeRequest,propertyName,propertyId,category,fundId,fundName,selectedRangeFrom,selectedRangeTo,tmGetCommentaryFromCompanyReportRequest,commentaryFromCompanyReport }) =>{
 

  const history = useHistory();



  const [buttonColor, setButtonColor] = React.useState("success");
  const [buttonGreenColor, setButtonGreenColor] = React.useState("success");
  const [buttonRedColor, setButtonRedColor] = React.useState("danger");


  const [threeToSixMonthsPaymentScoreType, setThreeToSixMonthsPaymentScoreType] = React.useState("THREETOSIX_MONTHS_PAYMENT_BEHAVIOR");
  const [overAllScoreType, setOverAllScoreType] = React.useState("OVERALL");
  const [creditScoreType, setCreditScoreType] = React.useState("CREDIT_SCORE");
  const [trailingDayPaymentScoreType, setTrailingDayPaymentScoreType] = React.useState("NINETY_DAYS_PAYMENT_BEHAVIOR");
  const [delinquencyScoreType, setDelinquencyScoreType] = React.useState("RISK_OF_90+_DAY_DELINQUENCY");

  const [dataStatus, setDataStatus] = React.useState(false);
 
  const[showBackNavigation,setShowBackNavigation]=React.useState(false);

  const [creditScore, setCreditScore] = React.useState(0);
  const [overAllScore, setOverAllScore] = React.useState(0);
  const [threeToSixMonthsPaymentScore, setThreeToSixMonthsPaymentScore] = React.useState(0);
  const [trailingDayPaymentScore, setTrailingDayPaymentScore] = React.useState(0);
  const [dbtScore, setDbtScore] = React.useState(0);
  const [industryDbtScore, setIndustryDbtScore] = React.useState(0);
  const [delinquencyScore, setDelinquencyScore] = React.useState(0);

   const [threeToSixMonthsPaymentScoreCSS, setThreeToSixMonthsPaymentScoreCSS] = React.useState("");
    const [trailingDayPaymentScoreCSS, setTrailingDayPaymentScoreCSS] = React.useState("");
    const [dbtScoreCSS, setDbtScoreCSS] = React.useState("");
    const [corporationFilingStatusScoreCSS, setCorporationFilingStatusScoreCSS] = React.useState("");
    const [creditScoreCSS, setCreditScoreCSS] = React.useState("");
    const [delinquencyScoreCSS, setDelinquencyScoreCSS] = React.useState("");


    const [creditScoreDescription, setCreditScoreDescription] = React.useState("");
    const [overAllScoreDescription, setOverAllScoreDescription] = React.useState("");
    const [threeToSixMonthsPaymentScoreDescription, setThreeToSixMonthsPaymentScoreDescription] = React.useState("");
    const [trailingDayPaymentScoreDescription, setTrailingDayPaymentScoreDescription] = React.useState("");
    const [dbtvsIndustryDbtScoreDescription, setDbtvsIndustryDbtScoreDescription] = React.useState("");
      const [delinquencyScoreDescription, setDelinquencyScoreDescription] = React.useState("");


      const [spendingGrowthIndexScoreDescription, setSpendingGrowthIndexScoreDescription] = React.useState("");
      const [spendingGrowthIndex, setSpendingGrowthIndex] = React.useState(0);
      const [spendingGrowthIndexScoreCSS,setSpendingGrowthIndexScoreCSS] = React.useState("");
      // const [spendingGrowthIndex, seSpendingGrowthIndex] = React.useState("SPENDING_GROWTH_INDEX");

      const[showSpendingGrowthIndex,setShowSpendingGrowthIndex] = React.useState(false);

      const [month2, setMonth2] = React.useState(moment().subtract(1, "month").format('MMM').toLowerCase());

    // const [month2, setMonth2] = React.useState(moment().subtract(1, "month").format('MMMM').toUpperCase());

    const [prev1Year, setPrev1Year] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());

    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

    const [userId, setUserId] = React.useState(user.id);

    const [yearsInBusiness,setYearsInBusiness] = React.useState("");

    // const [tenantAddress, setTenantAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
const [currCreditOverallStatus,setCurrCreditOverallStatus] = React.useState("");
const [industryBenchMarkScore,setIndustryBenchMarkScore] = React.useState("");
const [dbtIndustryDbt,setDbtvsIndustryDbt] = React.useState(false);
const [dbtIndustryDbtMinusOne,setDbtvsIndustryDbtMinusOne] = React.useState(false);

const [noOfTradeLines, setNoOfTradeLines] = React.useState("");
const [showNoOfTradeLines,setShowNoOfTradeLines] = React.useState(true);
const [showNoOfTradeLinesIfCRNotAvailable,setShowNoOfTradeLinesIfCRNotAvailable] = React.useState(false);

    function handleClickBack(event) {
  
       
      setShowBackNavigation(true);
 
    }
 
    function handleClickFundDashboard(event) {
      
    // setShowBackNavigation(true);
     history.push({ 
       pathname: '/dashboard'
      });
     
    }

    const getYearsInBusinessCommentary=async (tenantId,month2,year) => {
      await tmGetCommentaryFromCompanyReportRequest(tenantId,month2,year);
      console.log("commentaryFromCompanyReport"+JSON.stringify(commentaryFromCompanyReport[0]));
      // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    }

  React.useEffect(() => {
 
   // setButtonColor("red");
   //setButtonColor("#FF0000");
   setShowSpendingGrowthIndex(false);
    if(!dataStatus && month!='undefined' && tenantId!="" && month!=""){
    getTmTenantScoreByType(tenantId,month1,year,scoreType);
    getYearsInBusinessCommentary(tenantId,month2,year);

    }else{

     

      setCreditScore(tenantsScoreByType.creditScore);
      setOverAllScore(tenantsScoreByType.overAllScore);
      setThreeToSixMonthsPaymentScore(tenantsScoreByType.threeToSixMonthsPaymentScore);
      setTrailingDayPaymentScore(tenantsScoreByType.trailingDayPaymentScore);
      setDbtScore(tenantsScoreByType.dbtScore);
      setIndustryDbtScore(tenantsScoreByType.industryDbtScore);
      setDelinquencyScore(tenantsScoreByType.delinquencyScore);
      setDelinquencyScoreCSS(tenantsScoreByType.delinquencyCss);
      setDelinquencyScoreDescription(tenantsScoreByType.delinquencyCommentary);
  setCreditScoreDescription(tenantsScoreByType.creditCommentary);
      setTrailingDayPaymentScoreCSS(tenantsScoreByType.trailingNinetyDaysCss);
      setTrailingDayPaymentScoreDescription(tenantsScoreByType.trailingNinetyDaysCommentary);

      //angel
      setThreeToSixMonthsPaymentScoreCSS(tenantsScoreByType.threeToSixPaymentCss);
      setThreeToSixMonthsPaymentScoreDescription(tenantsScoreByType.threeToSixPaymentCommentary);
      setCreditScoreCSS(tenantsScoreByType.creditCss);
      setDbtvsIndustryDbtScoreDescription(tenantsScoreByType.industryBenchMarkCommentary);


    setSpendingGrowthIndex(tenantsScoreByType.spendingGrowthIndex);
    setSpendingGrowthIndexScoreCSS(tenantsScoreByType.spendingGrowthIndexScoreCSS);
    setSpendingGrowthIndexScoreDescription(tenantsScoreByType.spendingGrowthIndexScoreDescription);

    // setTenantAddress(tenantsScoreByType.tenantAddress);
    setCity(tenantsScoreByType.city);
    setState(tenantsScoreByType.state);
    setZip(tenantsScoreByType.zip);
    setCurrCreditOverallStatus(tenantsScoreByType.currCreditOverallStatus);
    setNoOfTradeLines(tenantsScoreByType.noOfTradeLines);

    // if(creditScoreData.spendingGrowthIndex>0 || creditScoreData.spendingGrowthIndex>0){
    //   setShowSpendingGrowthIndex(true);
    // }
    if(tenantsScoreByType.noOfTradeLines>=0){
      setShowNoOfTradeLines(true);
      setShowNoOfTradeLinesIfCRNotAvailable(false);
    
    }
    if(tenantsScoreByType.noOfTradeLines==-1){
      setShowNoOfTradeLines(false);
      setShowNoOfTradeLinesIfCRNotAvailable(true);
      
    }
    if(tenantsScoreByType.creditScore==0 || tenantsScoreByType.creditScore=="" || tenantsScoreByType.creditScore==null || tenantsScoreByType.noApiDataAvailableCredit==false){
      setCreditScore("NA");
      // setCreditScoreCSS("#D3D3D3");
    }
    
    if(tenantsScoreByType.overAllScore==0 || tenantsScoreByType.overAllScore=="" || tenantsScoreByType.overAllScore==null ){
      setOverAllScore("NA");
    }
    
    if(tenantsScoreByType.threeToSixMonthsPaymentScore==0 || tenantsScoreByType.threeToSixMonthsPaymentScore=="" || tenantsScoreByType.threeToSixMonthsPaymentScore==null || tenantsScoreByType.noApiDataAvailableThreeToSix==false){
      setThreeToSixMonthsPaymentScore("NA");
      setThreeToSixMonthsPaymentScoreDescription("No Data Available");
      setThreeToSixMonthsPaymentScoreCSS("#D3D3D3");
      // setThreeToSixMonthsPaymentScoreCSS("#D3D3D3");
    }
    
    if(tenantsScoreByType.trailingDayPaymentScore==0 || tenantsScoreByType.trailingDayPaymentScore=="" || tenantsScoreByType.trailingDayPaymentScore==null || tenantsScoreByType.noApiDataAvailableTrailingDay==false){
    setTrailingDayPaymentScore("NA");
    // setTrailingDayPaymentScoreCSS("#D3D3D3");
    }
    
    if(tenantsScoreByType.dbtScore>=0){
      setDbtvsIndustryDbt(true);
      setDbtvsIndustryDbtMinusOne(false);
    }
    
    if(tenantsScoreByType.dbtScore==-1){
      // setDbtScore("NA");
      setDbtvsIndustryDbt(false);
      setDbtvsIndustryDbtMinusOne(true);
      setIndustryBenchMarkScore("NA");
      setDbtvsIndustryDbtScoreDescription("No Data Available");
    }
    // console.log("spendingGrowthIndexScore"+tenantsScoreByType.spendingGrowthIndex);

    // if(tenantsScoreByType.spendingGrowthIndex==0 || tenantsScoreByType.spendingGrowthIndex=="" || tenantsScoreByType.spendingGrowthIndex==null ){
    //   setShowSpendingGrowthIndex(false);
    //   setSpendingGrowthIndex("NA");
    //   setSpendingGrowthIndexScoreCSS("#D3D3D3");
    // }
    
    // if(tenantsScoreByType.delinquencyScore==0 || tenantsScoreByType.delinquencyScore=="" || tenantsScoreByType.delinquencyScore==null ){
    //     setDelinquencyScore("NA");
    //     setDelinquencyScoreCSS("#D3D3D3");
    // }
    
    // if(tenantsScoreByType.creditScore==0 || tenantsScoreByType.creditScore=="" || tenantsScoreByType.creditScore==null ){
    //   setCreditScore("NA");
    //   setCreditScoreCSS("#D3D3D3");
    // }
    
    // if(tenantsScoreByType.overAllScore==0 || tenantsScoreByType.overAllScore=="" || tenantsScoreByType.overAllScore==null ){
    //   setOverAllScore("NA");
    // }
    
    // if(tenantsScoreByType.threeToSixMonthsPaymentScore==0 || tenantsScoreByType.threeToSixMonthsPaymentScore=="" || tenantsScoreByType.threeToSixMonthsPaymentScore==null ){
    //   setThreeToSixMonthsPaymentScore("NA");
    //   setThreeToSixMonthsPaymentScoreCSS("#D3D3D3");
    // }
    
    // if(tenantsScoreByType.trailingDayPaymentScore==0 || tenantsScoreByType.trailingDayPaymentScore=="" || tenantsScoreByType.trailingDayPaymentScore==null ){
    // setTrailingDayPaymentScore("NA");
    // setTrailingDayPaymentScoreCSS("#D3D3D3");
    // }
    
    // if(tenantsScoreByType.dbtScore==0 || tenantsScoreByType.dbtScore=="" || tenantsScoreByType.dbtScore==null ){
    //   setDbtScore("NA");
    // }
    
    // if(tenantsScoreByType.industryDbtScore==0 || tenantsScoreByType.industryDbtScore=="" || tenantsScoreByType.industryDbtScore==null ){
    //   setIndustryDbtScore("NA");
    // }
    

    }


    // if(creditScore >= 401 && creditScore <= 500){
    //   setCreditScoreDescription("Based on payment  history  the company has  a low risk of default in the next 12 months")
    //  }else if(creditScore >= 301 && creditScore <= 400){
    //   setCreditScoreDescription("Based on payment  history  the company has  a lower risk of default in the next 12 months")
    //  }else if(creditScore >= 201 && creditScore <= 300){
    //   setCreditScoreDescription("Based on the payment history, the company has a moderate risk of default in the next 12 months")
    //  }else if(creditScore >= 101 && creditScore <= 200){
    //   setCreditScoreDescription("Based on payment  history  the company has  a slightly higher  risk of default in the next 12 months")
    // }else if(creditScore >= 0 && creditScore <= 100){
    //   setCreditScoreDescription("Based on payment  history  the company has  a high risk of default in the next 12 months")
    // }

    // if(dbtScore<industryDbtScore){
    //   setDbtvsIndustryDbtScoreDescription("This company pays faster  than industry peers ‘based on dbt vs industry’");
    // }else{
    //   setDbtvsIndustryDbtScoreDescription("This company pays slower  than industry peers ‘based on dbt vs industry’");
    // }

 
   
    setTimeout(() => {
      setDataStatus(true);
    }, 1000);

  }, [dataStatus,month,tenantsScoreByType]);

  const getTmTenantScoreByType = async (selectedTenantId,month,year,scoreCategoryValue) => {
    await tmTenantsScoreByTypeRequest(selectedTenantId,month,year,scoreCategoryValue);
    setCreditScore(tenantsScoreByType.creditScore);
    setOverAllScore(tenantsScoreByType.overAllScore);
    setThreeToSixMonthsPaymentScore(tenantsScoreByType.threeToSixMonthsPaymentScore);
    setTrailingDayPaymentScore(tenantsScoreByType.trailingDayPaymentScore);
    setDbtScore(tenantsScoreByType.dbtScore);
    setIndustryDbtScore(tenantsScoreByType.industryDbtScore);
    setDelinquencyScore(tenantsScoreByType.delinquencyScore);
    setSpendingGrowthIndex(tenantsScoreByType.spendingGrowthIndex);

  
    //setDataStatus(true);
  };

 

 

  const classes = useStyles();
  if(dataStatus && dbtScore!=null){
  return (

   
    <Card>

    <div style={{width:'auto',paddingLeft:'30px',paddingRight:'30px'}}>
      
    {!showBackNavigation&&
       <GridContainer>
      {/* <GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>



<Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> 
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>

<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>


</GridItem> */}
<GridItem xs={12} sm={12} md={12}>
<h3 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'25px' }}>Tenant Name: {tenantName}</h3>
</GridItem>
<GridItem>
      <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px",paddingLeft:'25px'}}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>
      </GridItem>
<GridItem xs={12} sm={12} md={12} >  
<h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px" }}>Risk of 90+ Day Delinquency - [{pirsMonthNameValue}&nbsp;{year}]</h4>

 </GridItem>   
      
    {/* </GridContainer>
    
  
     
     <GridContainer className={classes.gridBorder} >
 */}



      
<GridItem xs={12} sm={4} md={4} className={classes.gridBorder}  justify = "center" style={{paddingTop:'10px',paddingBottom:'10px',borderRight:'none'}}>
 
<Tooltip classes={{ tooltip: classes.customWidth }} title="Predicts the likelihood that company  will become severely delinquent (90 days plus) within the next 12 months or that the business will go bankrupt.">
<Button round style={{ color:"#000000",backgroundColor: creditScoreCSS,fontSize:'20px'}}  > &nbsp;&nbsp;&nbsp;&nbsp; <b>{creditScore} </b>&nbsp;&nbsp;&nbsp;&nbsp;</Button>
</Tooltip>
<br></br>
<b>  {creditScoreDescription}</b>
</GridItem>


<GridItem xs={12} sm={8} md={8} className={classes.gridBorder} style={{paddingLeft:'230px'}} justify = "right">
                
                <div className={classes.buttonGroup} style={{paddingLeft:'11px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'#4caf50'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
  </GridItem>


{/* </GridContainer> */}

<GridItem xs={12} sm={12} md={12}>
{showNoOfTradeLines&&<h5 style={{ color: megaColor, fontWeight:'bold' }}>Payment Trends&nbsp;<b style={{fontSize:"13px",color:"#000000"}}>(&nbsp;Based on {noOfTradeLines} Trade Lines&nbsp;)</b></h5>}
{showNoOfTradeLinesIfCRNotAvailable&& <h5 style={{ color: megaColor, fontWeight:'bold' }}>Payment Trends</h5>}

</GridItem>

       

      {/* <GridContainer> */}

   
       
{/*    
      <GridItem xs={12} sm={4} md={4} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',paddingLeft:'53px'}}>
        
        <b style={{color:megaColor,paddingTop:'18px'}}>Risk of 90+ Day Delinquency</b>
          </GridItem>
  
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}} justify = "center">
          
          <Button round  style={{ color:"#000000",backgroundColor: delinquencyScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{delinquencyScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>

    
     
          <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
             
        <b>  {delinquencyScoreDescription}</b>
  
     
    </GridItem>
        */}
 


   


      <GridItem xs={12} sm={4} md={4} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',paddingLeft:'50px'}}>
        
      <b style={{color:megaColor,paddingTop:'18px'}}> Trailing 90 Days payment behaviour</b>
        </GridItem>

        {/* <GridItem xs={12} sm={1} md={1} >
        
        <Divider orientation="vertical"/>
       
    </GridItem> */}

         <GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}} justify = "center">
        
        <Button round  style={{ color:"#000000",backgroundColor: trailingDayPaymentScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{trailingDayPaymentScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
      
        </GridItem>
        {/* <GridItem xs={12} sm={1} md={1}>
        
        <Divider orientation="vertical"/>
       
    </GridItem> */}
  
   
        <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
           
      <b>  {trailingDayPaymentScoreDescription}</b>

   
  </GridItem>





  <GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'30px',paddingLeft:'50px'}} justify = "center">
  <b style={{color:megaColor}}> 3-6 Months Payment Trends   </b>   
                  
          </GridItem>
  
        
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}}  justify = "center">
          
  <Button round  style={{color:"#000000",backgroundColor: threeToSixMonthsPaymentScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{threeToSixMonthsPaymentScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
         

  
     
          <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b>{threeToSixMonthsPaymentScoreDescription}</b>

     
    </GridItem>
  


    <GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px',paddingLeft:'50px'}} justify = "center">
    <b style={{color:megaColor}} > Industry Benchmark </b>  
             
          </GridItem>
  
  
          {dbtIndustryDbt&& <GridItem xs={12} sm={3} md={3} style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:'center'}} >
          
          <font style={{color:'#4caf50',fontSize:'20px'}}><b>{dbtScore} DBT </b></font><b>vs</b>  <font style={{color:'#4caf50',fontSize:'20px'}}><b>{industryDbtScore} DBT</b></font>
        
          </GridItem>}

          {dbtIndustryDbtMinusOne&&<GridItem xs={12} sm={3} md={3} style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:'center'}} >
          
          <font style={{color:'#4caf50',fontSize:'20px'}}><b>{industryBenchMarkScore} </b></font>
        
          </GridItem>}
    
     
          <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b> {dbtvsIndustryDbtScoreDescription}</b>
     
    </GridItem>



    {showSpendingGrowthIndex &&<GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}} justify = "center">
  <b style={{color:megaColor}}> Spending Growth Index</b>   
                  
          </GridItem>}
  
        
  
          {showSpendingGrowthIndex&&<GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}}  justify = "center">
          
  <Button round  style={{color:"#000000",backgroundColor: spendingGrowthIndexScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{spendingGrowthIndex} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>}
        
         

     
          {showSpendingGrowthIndex&& <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b>{spendingGrowthIndexScoreDescription}</b>

     
    </GridItem> }
     
     
    <GridItem xs={12} sm={12} md={12} className={classes.vertical} style={{paddingTop:'15px'}}>
       <h4 style={{fontSize:"15px",fontWeight:"bold"}}>*{commentaryFromCompanyReport}</h4>
           
          </GridItem>
      </GridContainer>}
       
    
    <br></br>
   
    {showBackNavigation&& <TMAMHeatMapTenantRisk source={"property"} labelStatus={true}  tenantName={tenantName} tenantId={tenantId} propertyName={propertyName} propertyId={propertyId} category={category}  month={month} year={year} fundId={fundId} fundName={fundName} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} tenantAddress={tenantAddress} city={city} state={state} zip={zip}/>}
  
    </div>
    </Card>
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}


MTCredReportHeatMap.propTypes = {


  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByTypeRequest: PropTypes.func.isRequired,
  tmGetCommentaryFromCompanyReportRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmNotificationList: state.tenantmonitoring.tmNotificationList,
  pirsData:state.tenantmonitoring.pirsData,
  user: state.auth.loggedUser,
  tenantsScoreByType: state.tenantmonitoring.tenantsScoreByType,
  commentaryFromCompanyReport:state.tenantmonitoring.commentaryFromCompanyReport,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MTCredReportHeatMap);


