import React from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import history from './history';
import { Provider } from 'react-redux'
import Private from './private';
import Guest from './guest';

import Login from '../views/Login/index';

import Dashboard from '../views/Dashboard/Dashboard';
import AdminLayout from "layouts/Admin.js";
import ManagerLayout from "layouts/Manager.js";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { Creators as AuthActions } from 'store/ducks/auth.js';
const Routes = ({user,loading,loggedInUserRequest,tmUserNameRoleRequest,loggedUser,token}) => (

 
  React.useEffect(() => {
  // getLoggedInUserRequest();
  
  // const email = JSON.parse(localStorage.getItem('@DeliveryApp:email'));
   const token = JSON.parse(localStorage.getItem('@DeliveryApp:id_token'));
   
  }, []),
  
   <ConnectedRouter history={history}>
     <Switch>

      <Guest exact path="/login" component={Login} />
     <Private path="/" component={AdminLayout} />
     
    </Switch>

    {/* <Switch>
    <Guest exact path="/login" component={Login}/>
       {user && user.role =="ROLE_FUND_MANAGER" ?  
      <> 
       
       <Private path="/" component={AdminLayout} /> 
        </>
         :
         <> 
         <Private path="/"  component={ManagerLayout} />
  
         </>
        } 
         </Switch>   */}
  </ConnectedRouter>
 

);


Routes.propTypes = {
  
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedUser: state.auth.loggedUser, 
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...AuthActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Routes);

//export default Routes;
