import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMapPropertyTenants from "components/Charts/HeatMapPropertyTenants.js";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HeatMapPropertyRisk from "components/Charts/HeatMapPropertyRisk.js";


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import { getPropertyDashboardCount } from "store/sagas/tenantmonitoring";

const useStyles = makeStyles(styles);

const HeapMapProperty = ({user,tmPropertyByOrgIdRequest,tmPropertyDashboardCountRequest,propertyDasboardCount,propertyByOrg,tmProperyTenantsByOrganizationRequest,propertyHeatMap,organizationId="1234" }) =>{
 
  const classes = useStyles(); 
  const history = useHistory();
  const[heatMapPropertyVisible,setHeatMapPropertyVisible]=React.useState(true);
  const[heatMapPropertyRiskVisible,setHeatMapPropertyRiskVisible]=React.useState(false);
  const[heatMapPropertyTenantVisible,setHeatMapPropertyTenantVisible]=React.useState(false);
  const[propertyId,setPropertyId]=React.useState("");
  const[propertyName,setPropertyName]=React.useState("");

  const [selectedFundName, setSelectedFundName] = React.useState("");
  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);

  const [userId, setUserId] = React.useState(user.id);
  const [fundId, setFundId] = React.useState(fundId);
  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

  // const [showHeatMap, setShowHeatMap] = React.useState(true);
  // const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  // const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
  // const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  // const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);


  const [month, setMonth] = React.useState("");

  const [year, setYear] = React.useState(2020);
      React.useEffect(() => {


     
          getTmDashboardPropertyMap(user.organizationId); 
          getTmDashboardPropertyList(user.organizationId); 
          getTmPropertyDashboardCount(organisationId,fundId);
    
        }, [propertyByOrg]);


        const enableTenant = (range) => {
   
          const {rangeFrom,rangeTo,month,year}=range;
          setSelectedRangeFrom(range.rangeFrom);
          setSelectedRangeTo(range.rangeTo);
          setMonth(range.month);
          setYear(range.year);
        
          setHeatMapPropertyRiskVisible(false);
          setHeatMapPropertyVisible(false);
          setHeatMapPropertyTenantVisible(true);
         // setTenantActive(true);
       //   setHeadingText("Selected Tenant Risk Scores by Month");
         // setShowHeatMapTenantRisk(false);
        }
  
        const getTmDashboardPropertyMap = async (organizationId) => {
          await tmProperyTenantsByOrganizationRequest(organizationId);
          };

          const getTmDashboardPropertyList = async (organizationId) => {
            await tmPropertyByOrgIdRequest(organizationId);
            };


            const getTmPropertyDashboardCount = async (organisationId,fundId) => {
              await tmPropertyDashboardCountRequest(organisationId,fundId);
              };


            const handleChange = (propertyId,propertyName) => {
            
              setHeatMapPropertyVisible(false);
              setHeatMapPropertyRiskVisible(true);
              setPropertyId(propertyId);
              setPropertyName(propertyName);
             //  history.push({
             //   pathname: '/admin/dashboard/',
             //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
             // })
             
 
            //  setDashboardSelectedTenantId(tenantId);
             }

    // series: [
    //   {
    //     name: "Series 1",
    //     data: [{
    //       x: 'W1',
    //       y: 22
    //     }, {
    //       x: 'W2',
    //       y: 29
    //     }, {
    //       x: 'W3',
    //       y: 13
    //     }, {
    //       x: 'W4',
    //       y: 32
    //     }]
    //   },
    //   {
    //     name: "Series 2",
    //     data: [{
    //       x: 'W1',
    //       y: 43
    //     }, {
    //       x: 'W2',
    //       y: 43
    //     }, {
    //       x: 'W3',
    //       y: 43
    //     }, {
    //       x: 'W4',
    //       y: 43
    //     }]

    function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

   const chartOps = {

      
    

      series: propertyHeatMap,
      options: {
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '14px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                      labels: {
                       
                          style: {
                                    fontSize: '14px',
                                    fontWeight: 700,
                                  }
                                }
                              },
        
        chart: {
          height: 250,
          type: 'heatmap',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
          series: [
            {
              name: "Series 1",
              data: [{
                x: 'Jan',
                y: 212
              }, {
                x: 'Feb',
                y: 290
              }, {
                x: 'Mar',
                y: 83
              }, {
                x: 'Apr',
                y: 320
              }]
            },
            {
              name: "Series 2",
              data: [{
                x: 'Jan',
                y: 430
              }, {
                x: 'Feb',
                y: 413
              }, {
                x: 'Mar',
                y: 45
              }, {
                x: 'Apr',
                y: 350
              }]
            }
          ]
        },
        
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 300,
                style: {
                    colors: [],
                    fontSize: '50px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 400,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 1
        },
        title: {
          text: ''
        },
      },
    
    
    };
  


    return (

      <GridContainer >
<br/>
<br/>

       
  <GridItem xs={12} sm={12} md={12}>
         <h5 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Property Risk Score by Month</b></h5>
         </GridItem>
         
         <GridItem xs={12} sm={5} md={5}>
         <h5 style={{ color: megaColor,paddingLeft:'20px' }}> <b><br/></b></h5>
         </GridItem>

{heatMapPropertyRiskVisible&&  <GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right">
              {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
           
             <br></br><br/>
                
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
             

  </GridItem>
}




{heatMapPropertyTenantVisible&&  <GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right">
              {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
           
             <br></br><br/>
                
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
             

  </GridItem>
}
  {/* </GridContainer >
      
      <GridContainer> */}





    {heatMapPropertyVisible && <GridItem xs={12} sm={12} md={12}>
    <div id="chart">
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={350} />
</div>
    


</GridItem>
    }
<GridItem xs={12} sm={12} md={12}>

 {heatMapPropertyRiskVisible && <HeatMapPropertyRisk propertyId={propertyId} propertyName={propertyName} parentCallBack ={enableTenant}/>}
 {heatMapPropertyTenantVisible && <HeatMapPropertyTenants propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year}/>}

 <br/>
<br/> 

  {heatMapPropertyVisible && <div className={classes.buttonGroup}>
<Button color="transparent" className={classes.firstButton}>
                  <b>Property Names </b>
                </Button>
                {propertyByOrg.map(property => (

                <Button  style = {{color:'#00628B',backgroundColor:'#81A594'}} className={classes.middleButton} onClick={() => handleChange(property.id,property.propertyName)}>
                <b> {property.propertyName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowUpwardIcon/></b>
                </Button>

))} 
  </div> }
  <br/>
<br/>
 </GridItem>

   </GridContainer>
    
    )



}


HeapMapProperty.propTypes = {
  loading: PropTypes.bool.isRequired,
  tmProperyTenantsByOrganizationRequest: PropTypes.func.isRequired,
  tmPropertyByOrgIdRequest: PropTypes.func.isRequired,
  tmPropertyDashboardCountRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  propertyHeatMap: state.tenantmonitoring.propertyHeatMap,
  propertyByOrg: state.tenantmonitoring.propertyByOrg,
  propertyDasboardCount: state.tenantmonitoring.propertyDasboardCount,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeapMapProperty);

