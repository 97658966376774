/*eslint-disable*/
import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon  from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import image from "assets/img/bg17.jpg";
import logo3 from "assets/img/logo3.png";
import PropTypes from 'prop-types';

import Close from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";

import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AuthActions } from '../../store/ducks/auth';
import { useHistory } from "react-router-dom";
import {
  whiteColor,
   hexToRgb,
   megaColor,
   lyticsColor
 } from "assets/jss/material-dashboard-pro-react.js";
 

const style = {
  imagesStyles,
  loginPageStyle,
  textMuted: {
    color: "#6c757d"
  }
};

const useStyles = makeStyles(loginPageStyle,imagesStyles);


const ChangePassword = ({ user,tmChangePasswordWithUserIdAndPasswordRequest,changePassword,tmGetUserByLoginAndEmailRequest,userByLoginAndEmail,login,email}) => {

  const history = useHistory();
  const location = useLocation();



  
  //const [loggedIn,setLoggedIn]
  const [password, setPassword] = React.useState("");
  const[confirmPassword,setConfirmPassword] = React.useState("");
  const [requiredPassword, setRequiredPassword] = React.useState("");
  const [required, setrequired] = React.useState("");

  const [messageobj, setMessageobj] = React.useState("");
  const [requiredConfirmPassword, setRequiredConfirmPassword] = React.useState("");
  const[id,setId]=React.useState("");

const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);
const [alert2,setAlert2] = React.useState(false);

 const [userId, setUserId] = React.useState("");
 const [dataStatus,setDataStatus]=React.useState(false);
 
function verifyPassword(password,confirmPassword){
  return password === confirmPassword ;
}

const hideAlert = () => {
  setAlert(false);
}

const hideAlert2 = () => {
  setAlert2(false);
}
  React.useEffect(() => {
 
   
  
       
   async function getUserByLoginAndEmail(login,email) {
      
    await tmGetUserByLoginAndEmailRequest(login,email);
  
   console.log("userByLoginAndEmail"+JSON.stringify(userByLoginAndEmail));
     //setId(changePassword.id);
    setUserId(userByLoginAndEmail.id);
    console.log("userId"+userByLoginAndEmail.id);
  };
  getUserByLoginAndEmail(login,email);

   setTimeout(() => {
    setDataStatus(true);
  }, 3000);
    

  },[dataStatus]);


  const getChangePassword = async (userId,password) => {
  
     await tmChangePasswordWithUserIdAndPasswordRequest(userId,password);
  

    };

  // const getUserByLoginAndEmail = async (login,email,userId) => {
  
  //      await tmGetUserByLoginAndEmailRequest(login,email,userId);
    
  
  //      };
  

      function hideAlert1(event) {
        history.push({ 
          pathname: '/'
         });
        } 
const handleCancel=(e)=>{
    e.preventDefault();
    history.push('/');  
}

const handleCancels=()=>{
 console.log("handleCancelsgfg");
 console.log("userId"+userId);
 console.log("pw"+password);
 if(verifyLength(password,1)&&verifyLength(confirmPassword,1)&&verifyPassword(password,confirmPassword)){

 getChangePassword(userId,password);
 history.push( setAlert1(true),{ 
 
    pathname: '/',
    // state:{ message:"Fund Saved Successfully" }
   });
  
  }
   
      else{
      
        setAlert(true);
        //setAlert2(true);
     
      if (requiredPassword === "value") {
        setRequiredPassword("success");
       
      }
      if (requiredConfirmPassword === "value") {
        setRequiredConfirmPassword("success");
       
      }
}
};
const verifyLength = (value, length) => {
  if ((value!=null)&&(value.length >= length)) {
    return true;
  }
  return false;
};





//
const [value, setValue] = React.useState(null);
  const classes = useStyles();
  return (
    <div>
     
    
        <div className={classes.container}>
        
        <GridContainer justify="center" paddingTop="50px">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
              {/* onSubmit={handleSubmit}    */}

           
                <form className={classes.form} >
              

                  <CardHeader
                    color="white"
                    signup
                    image
                    className={classes.cardHeader}
                  >

                  </CardHeader>      
                          
                  <CardBody>
                    <br></br>
                    <br></br>
                    <br></br>
                  <GridItem paddingTop="100px"  align="center"  >
               <text style={{fontSize:"30px", color:megaColor, fontWeight:"bold"}}  > CHANGE PASSWORD </text>
               </GridItem>
               <br></br>
               <br></br>
<br></br>
        
<CustomInput
                    labelText="Password *"
                    id="password"
                    // onChange={handlePasswordChange}
                    success={requiredPassword === "success"}
                    error={requiredPassword === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setPassword(event.target.value);
                          setRequiredPassword("success");
                        } else {
                          setRequiredPassword("error");
                          setPassword("");
                        }
                        setPassword(event.target.value);
                      },
                      type: "password",
                      endAdornment:
                      requiredPassword === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
               
               <CustomInput
                    labelText="Confirm Password *"
                    id="last-name"
                    // onChange={handleConfirmPassword}

                    success={requiredConfirmPassword === "success"}
                    error={requiredConfirmPassword === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyPassword(password,confirmPassword)) {
                          setConfirmPassword(event.target.value);
                          setRequiredConfirmPassword("success");
                          
                                     
                
                          
                        } else {
                         //setAlert2(true);
                          setRequiredConfirmPassword("error");
                        }
                        setConfirmPassword(event.target.value);
                      },
                      type: "password",
                      endAdornment:
                     
                      requiredConfirmPassword === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                  </CardBody>
                  <br></br>
                  <div  align="center" >
                 
                  {/* <Button  type="submit" size="lg" color="info" >Change</Button> */}
                  <Button color="info" size="sm" style={{ fontSize:"15px" , fontWeight:"bold"}} onClick={handleCancels} >
                      Change
       
        </Button>
        <Button color="info" size="sm" style={{ fontSize:"15px" , fontWeight:"bold"}} onClick={handleCancel} >
                      Cancel
       
        </Button>
                  </div>
                 
                </form>
              </Card>
            </GridItem>
            <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: "#33D7FF"}}
// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Password Changed Successfully!
</SweetAlert>
</div>
<div>
  
<SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
           confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: "#328da8"}}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>   
                     <div>
  
<SweetAlert
            error
          show={alert2}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert2()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
           confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: "#328da8"}}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Password don't match
        </SweetAlert>
      
                     </div>   
            
          </GridContainer>
        </div>
     
      </div>
 
  );
}


ChangePassword.propTypes = {
  tmChangePasswordWithUserIdAndPasswordRequest: PropTypes.func.isRequired,
  tmGetUserByLoginAndEmailRequest: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  userByLoginAndEmail: state.tenantmonitoring.userByLoginAndEmail,
  changePassword:state.tenantmonitoring.changePassword,

  
});    

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePassword);
