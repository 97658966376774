import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/DashboardTM/DashboardTM.js";


import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";
import MonitoredTenants from "views/TenantMonitoring/MonitoredTenants/MonitoredTenants.js";
import Notification from "views/Notification/Notification.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import SelectOrganization from "views/SelectOrganization/SelectOrganization.js";
import Report from "components/Charts/Report.js";

import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import QueuePlayNextOutlinedIcon from '@material-ui/icons/QueuePlayNextOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import DynamicFeedOutlinedIcon from '@material-ui/icons/DynamicFeedOutlined';
import SettingsEthernetOutlinedIcon from '@material-ui/icons/SettingsEthernetOutlined';
import AddToQueueOutlinedIcon from '@material-ui/icons/AddToQueueOutlined';



//jain
import Tenant from "views/Tenant/Tenant.js";
import Company from "views/Company/Company.js";
import Property from "views/Property/Property.js";
import Fund from "views/Fund/Fund.js";
import Tenantmonitoringsettings from "views/Tenantmonitoringsettings/Tenantmonitoringsettings.js";
import Tenantlist from "views/Tenant/TenantList.js";
import Companylist from "views/Company/CompanyList.js";
import Propertylist from "views/Property/PropertyList.js";
import Fundlist from "views/Fund/FundList.js";
import Tenantmonitoringsettingslist from "views/Tenantmonitoringsettings/Tenantmonitoringsettingslist.js";
import Userform from "views/User/Userform.js";
import Userlist from "views/User/Userlist.js";
import Notificationsettings from "views/Notificationsettings/Notificationsettings.js";

//ANGEL
import  AssetManagerNotificationSettings from "views/Notificationsettings/AssetManagerNotificationSettings.js";

import Liensreport from "views/Reports/Liensreport.js";
import Civilsuitsreport from "views/Reports/Civilsuitsreport.js";
import Cautionaryreports from "views/Reports/Cautionaryreports.js";
import Fundedit from "views/Fund/FundEdit.js";
import Propertyedit from "views/Property/PropertyEdit.js";
import Tenantedit from "views/Tenant/TenantEdit.js";
import Companyedit from "views/Company/CompanyEdit.js";
import Useredit from "views/User/UserEdit.js";
import HeatMapCompanyAdmin from "components/Charts/HeatMapCompanyAdmin.js";
import { components } from "react-select";
import HeapMapProperty from "components/Charts/HeapMapProperty.js";

import TMHeatMap from "components/Charts/TMHeatMap.js";


import HeatMapAssetManager from "components/Charts/HeatMapAssetManager.js";
import TMHeatMapAssetManager from "components/Charts/TMHeatMapAssetManager.js";
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import MonitoredTenantsByFund from "views/TenantMonitoring/MonitoredTenants/MonitoredTenantsByFund.js";
import MonitoredTenantsByProperty from "views/TenantMonitoring/MonitoredTenants/MonitoredTenantsByProperty.js";
import TenantUpload from "views/Tenant/TenantUpload.js";
import FailureCountReport from 'views/Tenant/FailureCountReport.js';
//angel:icon

import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
//import HouseIcon from '@material-ui/icons/House';
import GroupIcon from '@material-ui/icons/Group';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

//product IMS-jain

import Addnewproduct from "views/Product/Addnewproduct.js";
import Outlet from "views/Product/Outlet.js";
import Productcategory from "views/Product/Productcategory.js";
import Productbrand from "views/Product/Productbrand.js";
import Experience from "views/Product/Experience.js";
import Employeedetails from "views/Product/Employeedetails.js";
import PopulateInitialScore from "views/Company/PopulateInitialScore";
import ViewFailureList from "views/Company/ViewFailureList";
import ViewLog from 'views/Company/ViewLog.js';
import BTTable from 'components/Bankruptcy/BTTable.js';
import Bankruptcy from "components/Bankruptcy/Bankruptcy";
import BaseLineReport from "components/Bankruptcy/BaseLineReport";
import ViewTenantDetails from "views/Tenant/ViewTenantDetails";
import ForgotPassword from "views/Login/ForgotPassword";
import ChangePassword from "views/Login/ChangePassword";
import ConfigureReport from "views/Company/ConfigureReport.js"
import TMHeatMapCM from "components/Charts/TMHeatMapCM";

import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BackupIcon from '@material-ui/icons/Backup';
//import PirsUploadLog from "views/Pirs/PirsUploadLog.js";

import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LaunchIcon from '@material-ui/icons/Launch';
import OrderFormAccordion from "components/Accordion/OrderFormAccordion.js";

import PirsUploadLog from "views/Pirs/PirsUploadLog.js";
import PirsList from "views/Pirs/PirsList";
import DashboardList from "views/Company/DashboardList"
import IndustryModalPage from "components/Accordion/IndustryModalPage";
import GridViewFundList from "components/Charts/GridViewFundList";
import FundPropertyGridView from "components/Charts/FundPropertyGridView";
import CompanyAdminFundGridView from "components/Charts/CompanyAdminFundGridView";
import AssetManagerGridView from "components/Charts/AssetManagerGridView";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BankruptcyMenu from "components/Bankruptcy/BankruptcyMenu";
import PropertyImageUpload from "components/CustomUpload/PropertyImageUpload";
import FundImageUpload from "components/CustomUpload/FundImageUpload";

import Search from "views/SearchLists/Search";
import MergedList from "views/SearchLists/MergedList";
import GenerateScore from "views/SearchLists/GenerateScore";
import PropertyDetails from "components/Charts/PropertyDetails";
import BranchDetailsDemo from "components/Charts/BranchDetailsDemo";
import News from "components/News/News";

import SendEmail from "views/SendEmil/SendEmail";
import TenantNotificationFromGridView from "views/TenantNotification/TenantNotificationFromGridView";
import NotificationLog from "views/Notification/NotificationLog";
import Assignment from "@material-ui/icons/Assignment";
import MonitoredTenantsPropertyDetails from "components/Charts/MonitoredTenantsPropertyDetails";
import AMMonitoredTenantRisk from "components/Charts/AMMonitoredTenantRisk";
import ParentList from "views/Parent/ParentList.js";
import Parent from "views/Parent/Parent";
import ParentEdit from "views/Parent/ParentEdit.js";
import ParentNotification from "views/TenantNotification/ParentNotification";
import CommentaryCreate from "views/Tenant/CommentaryCreate";
import Pdf2 from "views/PDF/Pdf2";
import PdfReport from "views/PDF/PdfReport";
import PdfOne from "views/PDF/PdfOne";
import PrintComponent from "translations/PrintComponent";
import PrintReport from "translations/PrintReport";
import PdfMonthlyReportDownload from "views/PDF/PdfMonthlyReportDownload";
import PropertyMonthlyPdfReport from "views/PDF/PropertyMonthlyPdfReport";
import PropertyMonthlyReportDownload from "views/PDF/PropertyMonthlyReportDownload";
import MonthlyFundReport from "./views/PDF/MonthlyFundReport";
import MonthlyFundReportDownload from "./views/PDF/MonthlyFundReportDownload";
import SettingsIcon from '@material-ui/icons/Settings';
import CleanupSettings from "./views/PDF/CleanupSettings";
import TenantNewsSettings from "./views/Tenant/TenantNewsSettings";

var dashRoutes = [
  {
    path: "/dashboard1",
    name: "Dashboard1",
    visible:false,
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    role:'FUND_MANAGER',
    layout: "/admin"
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "التقويم",
    icon: DashboardIcon,
    visible:true,
    role:"FUND_MANAGER",
    component: GridViewFundList,
    layout: "/admin"
  },

  {
     path: "/tm-heatmap",
   //path: "/dashboard",
    name: "Dashboard",
    visible:false,
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: TMHeatMap,
    role:'FUND_MANAGER',
    layout: "/admin"
  },
  
  {
    path: "/property-details",
  //path: "/dashboard",
   name: "Property Details",
   visible:false,
   rtlName: "لوحة القيادة",
   icon: DashboardIcon,
   component: PropertyDetails,
   role:'FUND_MANAGER',
   layout: "/admin"
 },
 {
  path: "/monitored-tenants-property-details",
//path: "/dashboard",
 name: "Monitored Tenants Property Details",
 visible:false,
 rtlName: "لوحة القيادة",
 icon: DashboardIcon,
 component: MonitoredTenantsPropertyDetails,
 role:'ASSET_MANAGER',
 layout: "/admin"
},
{
  path: "/monitored-tenant-risk",
//path: "/dashboard",
 name: "Monitored Tenants Tenant Risk",
 visible:false,
 rtlName: "لوحة القيادة",
 icon: DashboardIcon,
 component: AMMonitoredTenantRisk,
 role:'ASSET_MANAGER',
 layout: "/admin"
},

{
      path: "/branch-details",
    //path: "/dashboard",
     name: "Property Details",
     visible:false,
     rtlName: "لوحة القيادة",
     icon: DashboardIcon,
     component: BranchDetailsDemo,
     role:'FUND_MANAGER',
     layout: "/admin"
   },
  

  {
    path: "/fund-property-grid-view",
   //path: "/dashboard",
   name: "FundPropertyGridView",
   visible:false,
   rtlName: "لوحة القيادة",
   icon: DashboardIcon,
   component: FundPropertyGridView,
   role:'FUND_MANAGER',
   layout: "/admin"
 },

 {
  path: "/companyAdmin",
  name: "Dashboard",
  visible:true,
  rtlName: "لوحة القيادة",
  icon: DashboardIcon,
  component: CompanyAdminFundGridView,
  role:'COMPANY_ADMIN',
  layout: "/admin"
},


 

  {
    path: "/cm-heatmap",
    name: "Dashboard",
    visible:false,
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: TMHeatMapCM,
    role:'COMPANY_ADMIN',
    layout: "/admin"
  },
  
  {
    path: "/company",
    name: "Company",
    rtlName: "التقويم",
    icon: BusinessIcon,
    visible:false,
    role:"ADMIN",
    component: Company,
    layout: "/admin"
  },
  // {
  //   path: "/pdf-download",
  //   name: "PDF Generation",
  //   rtlName: "التقويم",
  //   icon: BusinessIcon,
  //   visible:true,
  //   role:"ADMIN",
  //   component: PdfMonthlyReportDownload,
  //   layout: "/admin"
  // },
  {
    path: "/pdf-report-view",
    name: "PDF Generation",
    rtlName: "التقويم",
    icon: BusinessIcon,
    visible:false,
    role:"ADMIN",
    component: PdfReport,
    layout: "/admin"
  },
  {
    path: "/companylist",
    name: "Company",
    rtlName: "التقويم",
    icon:BusinessIcon,
    visible:true,
    role:"ADMIN",
    component: Companylist,
    layout: "/admin"
  },
  {
    path: "/userlist",
    name: "User",
    rtlName: "التقويم",
    icon: AccountCircleIcon,
    visible:false,
    role:"ADMIN",
    component: Userlist,
    layout: "/admin"
  },
  {
    path: "/rentroll",
    name: "Rent Roll",
    rtlName: "التقويم",
    icon: MonetizationOnIcon,
    visible:true,
    role:"ADMIN",
    component: Search,
    layout: "/admin"
  },
  // {
  //   path: "/sendEmail",
  //   name: "Send Email",
  //   rtlName: "التقويم",
  //   icon: MonetizationOnIcon,
  //   visible:true,
  //   role:"ADMIN",
  //   component: SendEmail,
  //   layout: "/admin"
  // },
  {
    path: "/mergedlist",
    name: "MergedList",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: MergedList,
    layout: "/admin"
  },
  {
    path: "/tenant-news-settings",
    name: "News Settings",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: TenantNewsSettings,
    layout: "/admin"
  },
  {
    path: "/generate-score",
    name: "Generate Score",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: GenerateScore,
    layout: "/admin"
  },
  // {
  //   path: "/order-form",
  //   name: "Order Form",
  //   rtlName: "التقويم",
  //   icon: LaunchIcon,
  //   visible:false,
  //   role:"ADMIN",
  //   component: OrderFormAccordion,
  //   layout: "/admin"
  // },

  

  {
    path: "/industry-modalpage",
    name: "Industry Modal Page",
    rtlName: "التقويم",
    icon: LaunchIcon,
    visible:false,
    role:"ADMIN",
    component: IndustryModalPage,
    layout: "/admin"
  },
  


  {
         path: "/user-profile",
         name: "User Profile",
         rtlName: "ملف تعريفي للمستخدم",
         icon: AccountCircleIcon,
         visible:false,
        
         component: UserProfile,
         layout: "/admin"
     },
  
  {
    path: "/select-org",
    name: "Choose Company",
    visible:true,
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: SelectOrganization,
    role:'ADMIN',
    layout: "/admin"
  },

  {
    path: "/companyAdmin",
    name: "Dashboard",
    rtlName: "خريطة المتجه",
    mini: "VM",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    visible:false,
    role:"COMPANY_ADMIN",
    component: HeatMapCompanyAdmin,
    layout: "/admin"
  },

  {
    path: "/monitoredTenants",
    name: "Monitored Tenants",
    rtlName: "خرائط جوجل",
    mini: "GM",
    visible:true,
    role:"COMPANY_ADMIN",
    icon:AddToQueueOutlinedIcon,
    rtlMini: "زم",
    component: MonitoredTenants,
    layout: "/admin"
  },

  {
    path: "/monitored-tenants-by-fund",
    name: "Monitored Tenants",
    rtlName: "خرائط جوجل",
    mini: "GM",
    visible:true,
    role:"FUND_MANAGER",
    icon:AddToQueueOutlinedIcon,
    rtlMini: "زم",
    component: MonitoredTenantsByFund,
    layout: "/admin"
  },
  {
    path: "/pdf-download",
    name: "Monthly Report",
    visible:false,
    rtlName: "لوحة القيادة",
    icon: AssessmentIcon,
    component: PdfMonthlyReportDownload,
    role:'ADMIN',
    layout: "/admin"
  },
  {
    path: "/monthly-fund-report-download",
    name: "Monthly Fund Report",
    visible:false,
    rtlName: "لوحة القيادة",
    icon: AssessmentIcon,
    component: MonthlyFundReportDownload,
    role:'ADMIN',
    layout: "/admin"
  },
  {
    path: "/cleanup-settings",
    name: "Settings",
    visible:true,
    rtlName: "لوحة القيادة",
    icon: SettingsIcon,
    component: CleanupSettings,
    role:'ADMIN',
    layout: "/admin"
  },
  {
    path: "/bankruptcy",
    name: "Bankruptcy",
    rtlName: "التقويم",
    icon: AccountBalanceIcon,
    visible:false,
    role:"FUND_MANAGER",
    component: Bankruptcy,
    layout: "/admin"
  },
  {
    path: "/bankruptcy-dashboard",
    name: "Bankruptcy",
    rtlName: "التقويم",
    icon: AccountBalanceIcon,
    visible:false,
    role:"FUND_MANAGER",
    component: BankruptcyMenu,
    layout: "/admin"
  },

  
 

  {
    //path: "/dashboardmanager",
    path: "/tm-heatmap-asset-manager",
    name: "Dashboard",
    rtlName: "خريطة المتجه",
    mini: "VM",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    visible:false,
    role:"ASSET_MANAGER",
    component: TMHeatMapAssetManager,
    layout: "/admin"
  },
  {
    path: "/dashboardmanager",
    name: "Dashboard",
    rtlName: "خريطة المتجه",
    mini: "VM",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    visible:true,
    role:"ASSET_MANAGER",
    component: AssetManagerGridView,
    layout: "/admin"
  },
 
  {
    path: "/monitored-tenants-by-property",
    name: "Monitored Tenants",
    rtlName: "خرائط جوجل",
    mini: "GM",
    visible:true,
    role:"ASSET_MANAGER",
    icon:AddToQueueOutlinedIcon,
    rtlMini: "زم",
    component: MonitoredTenantsByProperty,
    layout: "/admin"
  },
  {
    path: "/pdf-property-download",
    name: "PDF Generation",
    rtlName: "التقويم",
    icon: BusinessIcon,
    visible:false,
    role:"ADMIN",
    component: PropertyMonthlyReportDownload,
    layout: "/admin"
  },
  {
    path: "/bankruptcy-dashboard",
    name: "Bankruptcy",
    rtlName: "التقويم",
    icon: AccountBalanceIcon,
    visible:false,
    role:"ASSET_MANAGER",
    component: BankruptcyMenu,
    layout: "/admin"
  },
  {
    path: "/report",
    name: "Report",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"FUND_MANAGER",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    component: Report,
    layout: "/admin"
  },

  {
    path: "/tenants-monitored",
    name: "Monitored Tenants",
    rtlName: "خرائط جوجل",
    mini: "GM",
    visible:false,
    role:"FUND_MANAGER",
    icon:AddToQueueOutlinedIcon,
    rtlMini: "زم",
    component: MonitoredTenants,
    layout: "/admin"
  },
 

  {
    path: "/tenantNotification-fm",
    name: "All Tenants",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"FUND_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component:TenantNotification,
    layout: "/admin"
  },
  {
    path: "/tenantNotification",
    name: "All Tenants",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"FUND_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component:TenantNotificationFromGridView,
    layout: "/admin"
  },

  {
    path: "/news",
    name: "News",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"FUND_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component:News,
    layout: "/admin"
  },


  {
    path: "/tenantNotification-am",
    name: "All Tenants",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"ASSET_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component: TenantNotification,
    layout: "/admin"
  },
  {
    path: "/parentNotification-am",
    name: "All Tenants",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"ASSET_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component: ParentNotification,
    layout: "/admin"
  },
  {
    path: "/tenantNotification",
    name: "All Tenants",
    rtlName: "خريطة المتجه",
    mini: "VM",
    visible:false,
    role:"ASSET_MANAGER",
    icon:DynamicFeedOutlinedIcon,
    component: TenantNotification,
    layout: "/admin"
  },
  {
    collapse: false,
    name: "Tenant Monitoring",
    rtlName: "خرائط",
    icon: AssessmentIcon,
    state: "monitatedTenantCollapse",
    visible:false,
    role:"USER",
    views: [
      {
        path: "/tenants",
        name: "Monitored Tenants",
        rtlName: "خرائط جوجل",
        mini: "GM",
        visible:true,
        role:"ADMIN1",
        icon:AddToQueueOutlinedIcon,
        rtlMini: "زم",
        component: MonitoredTenants,
        layout: "/admin"
      },
      
      // {
      //   path: "/vector-maps",
      //   name: "Notifications",
      //   rtlName: "خريطة المتجه",
      //   mini: "VM",
      //   icon:NotificationsActiveOutlinedIcon,
      //   rtlMini: "تم",
      //   component: Notification,
      //   layout: "/admin"
      // },
      // {
      //   path: "/allTenants",
      //   name: "All Tenants",
      //   rtlName: "خريطة المتجه",
      //   mini: "VM",
      //   icon:BusinessOutlinedIcon,
      //   rtlMini: "تم",
      //   component: VectorMap,
      //   layout: "/admin"
      // },
      {
        path: "/tenantNotification",
        name: "All Tenants",
        rtlName: "خريطة المتجه",
        mini: "VM",
        visible:true,
        role:"ADMIN1",
        icon:DynamicFeedOutlinedIcon,
        component: TenantNotification,
        layout: "/admin"
      },
      {
        path: "/notification1",
        name: "Notification",
        rtlName: "خريطة المتجه",
       // mini: "VM",
        icon:SettingsEthernetOutlinedIcon,
        rtlMini: "تم",
        visible:false,
        role:"ADMIN1",
        component: Property,
        layout: "/admin"
      },
      {
        path: "/report",
        name: "Report",
        rtlName: "خريطة المتجه",
        mini: "VM",
        visible:false,
        role:"ADMIN1",
        icon:SettingsEthernetOutlinedIcon,
        rtlMini: "تم",
        component: Report,
        layout: "/admin"
      }
    ]
  },

  // {
  //   collapse: true,
  //   name: "Pages",
  //   rtlName: "صفحات",
  //   icon: Image,
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/pricing-page",
  //       name: "Pricing Page",
  //       rtlName: "عالتسعير",
  //       mini: "PP",
  //       rtlMini: "ع",
  //       component: Dashboard,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/rtl-support-page",
  //       name: "RTL Support",
  //       rtlName: "صودعم رتل",
  //       mini: "RS",
  //       rtlMini: "صو",
  //       component: RTLSupport,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/timeline-page",
  //       name: "Timeline Page",
  //       rtlName: "تيالجدول الزمني",
  //       mini: "T",
  //       rtlMini: "تي",
  //       component: TimelinePage,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/login-page",
  //       name: "Login Page",
  //       rtlName: "هعذاتسجيل الدخول",
  //       mini: "L",
  //       rtlMini: "هعذا",
  //       component: LoginPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/register-page",
  //       name: "Register Page",
  //       rtlName: "تسجيل",
  //       mini: "R",
  //       rtlMini: "صع",
  //       component: RegisterPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/lock-screen-page",
  //       name: "Lock Screen Page",
  //       rtlName: "اقفل الشاشة",
  //       mini: "LS",
  //       rtlMini: "هذاع",
  //       component: LockScreenPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/user-page",
  //       name: "User Profile",
  //       rtlName: "ملف تعريفي للمستخدم",
  //       mini: "UP",
  //       rtlMini: "شع",
  //       component: UserProfile,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/error-page",
  //       name: "Error Page",
  //       rtlName: "صفحة الخطأ",
  //       mini: "E",
  //       rtlMini: "البريد",
  //       component: ErrorPage,
  //       layout: "/auth"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "المكونات",
  //   icon: Apps,
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "انهيار متعدد المستويات",
  //       mini: "MC",
  //       rtlMini: "ر",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "وصفت",
  //           mini: "B",
  //           rtlMini: "ب",
  //           component: Buttons,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "وصفت",
  //       mini: "B",
  //       rtlMini: "ب",
  //       component: Buttons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "نظام الشبكة",
  //       mini: "GS",
  //       rtlMini: "زو",
  //       component: GridSystem,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "لوحات",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Panels,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "الحلو تنبيه",
  //       mini: "SA",
  //       rtlMini: "ومن",
  //       component: SweetAlert,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "إخطارات",
  //       mini: "N",
  //       rtlMini: "ن",
  //       component: Notifications,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "الرموز",
  //       mini: "I",
  //       rtlMini: "و",
  //       component: Icons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "طباعة",
  //       mini: "T",
  //       rtlMini: "ر",
  //       component: Typography,
  //       layout: "/admin"
  //     }
  //   ]
  // },
//   {
//     collapse: true,
//     name: "Forms",
//     rtlName: "إستمارات",
//     icon: "content_paste",
//     state: "formsCollapse",
//     views: [
//       {
//         path: "/regular-forms",
//         name: "Regular Forms",
//         rtlName: "أشكال عادية",
//         mini: "RF",
//         rtlMini: "صو",
//         component: RegularForms,
//         layout: "/admin"
//       },
//       {
//         path: "/extended-forms",
//         name: "Extended Forms",
//         rtlName: "نماذج موسعة",
//         mini: "EF",
//         rtlMini: "هوو",
//         component: ExtendedForms,
//         layout: "/admin"
//       },
//       {
//         path: "/validation-forms",
//         name: "Validation Forms",
//         rtlName: "نماذج التحقق من الصحة",
//         mini: "VF",
//         rtlMini: "تو",
//         component: ValidationForms,
//         layout: "/admin"
//       },
//       {
//         path: "/wizard",
//         name: "Wizard",
//         rtlName: "ساحر",
//         mini: "W",
//         rtlMini: "ث",
//         component: Wizard,
//         layout: "/admin"
//       }
//    ]
//  },
//   {
//     collapse: true,
//     name: "Tables",
//     rtlName: "الجداول",
//     icon: GridOn,
//     state: "tablesCollapse",
//     views: [
//       {
//         path: "/regular-tables",
//         name: "Regular Tables",
//         rtlName: "طاولات عادية",
//         mini: "RT",
//         rtlMini: "صر",
//         component: RegularTables,
//         layout: "/admin"
//       },
//       {
//         path: "/extended-tables",
//         name: "Extended Tables",
//         rtlName: "جداول ممتدة",
//         mini: "ET",
//         rtlMini: "هور",
//         component: ExtendedTables,
//         layout: "/admin"
//       },
//       {
//         path: "/react-tables",
//         name: "React Tables",
//         rtlName: "رد فعل الطاولة",
//         mini: "RT",
//         rtlMini: "در",
//         component: ReactTables,
//         layout: "/admin"
//       }
//    ]
//  },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: Place,
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "خرائط جوجل",
  //       mini: "GM",
  //       rtlMini: "زم",
  //       component: GoogleMaps,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       name: "Full Screen Map",
  //       rtlName: "خريطة كاملة الشاشة",
  //       mini: "FSM",
  //       rtlMini: "ووم",
  //       component: FullScreenMap,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector-maps",
  //       name: "Vector Map",
  //       rtlName: "خريطة المتجه",
  //       mini: "VM",
  //       rtlMini: "تم",
  //       component: VectorMap,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "الحاجيات",
  //   icon: WidgetsIcon,
  //   component: Widgets,
  //   layout: "/admin"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "الرسوم البيانية",
  //   icon: Timeline,
  //   component: Charts,
  //   layout: "/admin"
  // },
  //  {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "التقويم",
  //   icon: DateRange,
  //   component: Calendar,
  //   layout: "/admin"
  // },

  //jain
  
 
  {
    path: "/fundlist",
    name: "Fund",
    rtlName: "التقويم",
    icon: MonetizationOnIcon,
    visible:true,
    role:"ADMIN",
    component: Fundlist,
    layout: "/admin"
  },
  {
    path: "/fund-image-upload",
    name: "Fund",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: FundImageUpload,
    layout: "/admin"
  },

  {
    path: "/propertylist",
    name: "Property",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:true,
    role:"ADMIN",
    component: Propertylist,
    layout: "/admin"
  },
  {
    path: "/property-image-upload",
    name: "Property",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: PropertyImageUpload,
    layout: "/admin"
  },
  {
    path: "/parentlist",
    name: "Parent",
    rtlName: "التقويم",
    icon: HomeWorkIcon,
    visible:true,
    role:"ADMIN",
    component: ParentList,
    layout: "/admin"
  },
  {
    path: "/parent",
    name: "Parent",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    component: Parent,
    role:"ADMIN",
    layout: "/admin"
  },
  {
    path: "/parent-edit",
    name: "Parent Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: ParentEdit,
    layout: "/admin"
  },
  {
    path: "/tenantlist",
    name: "Tenant",
    rtlName: "التقويم",
    icon: HomeWorkIcon,
    visible:true,
    role:"ADMIN",
    component: Tenantlist,
    layout: "/admin"
  },
 

  {
    path: "/mergedlist",
    name: "MergedList",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: MergedList,
    layout: "/admin"
  },

  {
    path: "/generate-score",
    name: "Generate Score",
    rtlName: "التقويم",
    icon: HomeIcon,
    visible:false,
    role:"ADMIN",
    component: GenerateScore,
    layout: "/admin"
  },
  {
    path: "/notification-log",
    name: "Notification Log",
    rtlName: "التقويم",
    icon: Assignment,
    visible:true,
    role:"ADMIN",
    component: NotificationLog,
    layout: "/admin"
  },

  {
    path: "/pirs-upload",
    name: "Upload PIRS",
    rtlName: "التقويم",
    icon: BackupIcon,
    visible:true,
    role:"ADMIN",
    component: PirsUploadLog,
    layout: "/admin"
  },
  {
    path: "/tenantmonitoringsettingslist",
    name: "Tenantmonitoringsettings",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Tenantmonitoringsettingslist,
    layout: "/admin"
  },
  {
    path: "/tenant",
    name: "Tenant",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    component: Tenant,
    role:"ADMIN",
    layout: "/admin"
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    rtlName: "التقويم",
    icon: DateRange,
    visible:true,
    component: ForgotPassword,
  
    layout: "/auth"
  },
  {
    path: "/change-password",
    name: "Change Password",
    rtlName: "التقويم",
    icon: DateRange,
    visible:true,
    component: ChangePassword,
  
    layout: "/auth"
  },
  {
    path: "/company",
    name: "Company",
    rtlName: "التقويم",
    icon: BusinessIcon,
    visible:false,
    role:"ADMIN",
    component: Company,
    layout: "/admin"
  },
  {
    path: "/property",
    name: "Property",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Property,
    layout: "/admin"
  },

  {
    path: "/fund",
    name: "Fund",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Fund,
    layout: "/admin"
  },

  {
    path: "/tenantmonitoringsettings",
    name: "Tenantmonitoringsettings",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Tenantmonitoringsettings,
    layout: "/admin"
  },

  {
    path: "/userform",
    name: "User Form",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Userform,
    layout: "/admin"
  },

  {
    path: "/fund-edit",
    name: "Fund Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Fundedit,
    layout: "/admin"
  },
  {
    path: "/property-edit",
    name: "Property Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Propertyedit,
    layout: "/admin"
  },
  {
    path: "/tenant-edit",
    name: "Tenant Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Tenantedit,
    layout: "/admin"
  },
  {
    path: "/tenant-commentary",
    name: "Tenant Commentary",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: CommentaryCreate,
    layout: "/admin"
  },
  {
    path: "/company-edit",
    name: "Company Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Companyedit,
    layout: "/admin"
  },
  {
    path: "/user-edit",
    name: "User Edit",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Useredit,
    layout: "/admin"
  },
  {
    path: "/view-tenant-details",
    name: "View Tenant Details",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    component: ViewTenantDetails,
    role:"ADMIN",
    layout: "/admin"
  },
  {
    path: "/notification-settings",
    name: "Notification Settings",
    rtlName: "التقويم",
    icon: DateRange,
    visible:true,
    role:"FUND_MANAGER",
    component: Notificationsettings,
    layout: "/admin"
  },
  //angel
  
  {
    path: "/asset-manager-notification-settings",
    name: "Notification Settings",
    rtlName: "التقويم",
    icon: DateRange,
    visible:true,
    role:"ASSET_MANAGER",
    component: AssetManagerNotificationSettings,
    layout: "/admin"
  },

  {
    path: "/liensreport",
    name: "Liens and Judgements Report",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"FUND_MANAGER",
    component: Liensreport,
    layout: "/admin"
  },
  {
    path: "/tenant-upload",
    name: "Tenant Upload",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: TenantUpload,
    layout: "/admin"
  },
  {
    path: "/tenant-failure-status",
    name: "Failure Count",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: FailureCountReport,
    layout: "/admin"
  },

  {
    path: "/populate-initial-score",
    name: "Populate Initial Score",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: PopulateInitialScore,
    layout: "/admin"
  },

  {
    path: "/view-failure-list",
    name: "View Failure List",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: ViewFailureList,
    layout: "/admin"
  },

  {
    path: "/view-log",
    name: "View Log",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: ViewLog,
    layout: "/admin"
  },

  {
    path: "/civilsuitsreport",
    name: "Civil Suits",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"FUND_MANAGER",
    component: Civilsuitsreport,
    layout: "/admin"
  },
  {
    path: "/cautionaryreports",
    name: "Cautionary Report",
    rtlName: "التقويم",
    visible:false,
    icon: DateRange,
    role:"FUND_MANAGER",
    component: Cautionaryreports,
    layout: "/admin"
  },
  
 

  {
    path: "/heatmapPropertyFM",
    name: "Dashboard1",
    rtlName: "خريطة المتجه",
    mini: "VM",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    visible:false,
    role:"FUND_MANAGER",
    component: HeatMapPropertyFM,
    layout: "/admin"
  },
  {
    path: "/reportAssetmanager",
    name: "Report",
    rtlName: "خريطة المتجه",
    mini: "VM",
    icon:SettingsEthernetOutlinedIcon,
    rtlMini: "تم",
    visible:false,
    role:"FUND_MANAGER",
    component: Report,
    layout: "/manager"
  },
  
  {
    path: "/baseline-report",
    name: "Base Line Report",
    rtlName: "التقويم",
    icon: MonetizationOnIcon,
    visible:false,
    role:"ADMIN",
    component: BaseLineReport,
    layout: "/admin"
  },

  {
    path: "/bttable",
    name: "BTTable",
    rtlName: "التقويم",
    icon: MonetizationOnIcon,
    visible:false,
    role:"ADMIN",
    component: BTTable,
    layout: "/admin"
  },
  {
    path: "/Product",
    name: "AddNewProduct",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Addnewproduct,
    layout: "/admin"
  },
  {
    path: "/Outlet",
    name: "Outlet",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Outlet,
    layout: "/admin"
  },
  {
    path: "/Productcategory",
    name: "Product Category",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Productcategory,
    layout: "/admin"
  },
  {
    path: "/Productbrand",
    name: "Product Brand",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Productbrand,
    layout: "/admin"
  },
  {
    path: "/Experience",
    name: "Experience Details",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Experience,
    layout: "/admin"
  },
  {
    path: "/Employeedetails",
    name: "Employee Details",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: Employeedetails,
    layout: "/admin"
  },
  {
    path: "/configure-report",
    name: "Configure report",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: ConfigureReport,
    layout: "/admin"
  },
  {
    path: "/view-pirs-list",
    name: "View PIRS List",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: PirsList,
    layout: "/admin"
  },
  {
    path: "/dashboard-list",
    name: "Dashboard List",
    rtlName: "التقويم",
    icon: DateRange,
    visible:false,
    role:"ADMIN",
    component: DashboardList,
    layout: "/admin"
  },,
  


];
export default dashRoutes;
