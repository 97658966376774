import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';


import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import SaveIcon from '@material-ui/icons/Save';
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

// import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

import ContifyIdModalPage from "components/Popup/ContifyIdModalPage.js"
import CorteraIdModalPage from "components/Popup/CorteraIdModalPage.js"
import CreditSafeIdModalPage from "components/Popup/CreditSafeIdModalPage.js"
import SaveAltIcon from '@material-ui/icons/SaveAlt';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Check from "@material-ui/icons/Check";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import { confirmAlert } from 'react-confirm-alert'; 
import SweetAlert from "react-bootstrap-sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';

import Datetime from "react-datetime";
import Moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Badge from "components/Badge/Badge.js";
import Edit from "@material-ui/icons/Edit";

import CloseIcon from '@material-ui/icons/Close';
import Switch from "@material-ui/core/Switch";
import TLOModalPage from "components/Popup/TLOModalPage";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TenantEdit = ({tmTenantSaveRequest,tmTenantEditRequest,tmPropertyListRequest,propertyList,tmPropertyByOrgIdRequest,propertyByOrg,tenantEditObj,tmTenantByIdRequest,tenant,
  tmPropertyWithUserRequest,propertyWithUser,tmParentByOrgIdRequest,parentByOrg}) => {

  const history = useHistory();

  const location = useLocation();
  const [input, setInput] = React.useState('');
  const [dataList, setDataList] = React.useState(propertyWithUser);

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");

const [labelParentName,setLabelParentName]=React.useState(tenantEditObj.parentName);
const [labelPropertyName,setLabelPropertyName]=React.useState(tenantEditObj.propertyName);
const [labelUseOfSpace,setLabelUseOfSpace]=React.useState(tenantEditObj.useOfSpace);
  // type validation
  const [required, setrequired] = React.useState("");
  const [requiredName, setrequiredName] = React.useState("");
  
const [requiredLegal, setrequiredLegal] = React.useState("");
const [requiredLegalName, setrequiredLegalName] = React.useState("");

const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredAddressName, setrequiredAddressName] = React.useState("");


//const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [requiredCountryName, setrequiredCountryName] = React.useState("");

const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");

const [requiredCity, setrequiredCity ]= React.useState("");
const [requiredCityName, setrequiredCityName] = React.useState("");

// const [requiredState, setrequiredState ]= React.useState("");
 const [requiredStateName, setrequiredStateName] = React.useState("");

const [rent, setrent] = React.useState("");
const[rentableBuildingArea,setRentableBuldingArea] = React.useState("");
const [rentState, setrentState] = React.useState("");

const [phonenum, setPhoneNum] = React.useState("");
const [phoneNumState, setPhoneNumState] = React.useState("");

const [phoneNumTwo, setPhoneNumTwo] = React.useState("");
const [phoneNumTwoState, setPhoneNumTwoState] = React.useState("");
const [requiredProperty, setrequiredProperty] = React.useState("");
const [requiredContifyId, setrequiredContifyId ]= React.useState("");
const [requiredCreditSafeId, setrequiredCreditSafeId ]= React.useState("");
const [requiredCorteraId, setrequiredCorteraId ]= React.useState("");
const [requiredBankoId, setrequiredbankoId ]= React.useState("");
const[requiredZip, setRequiredZip] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");

const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const [requiredTenantRentableBuildingArea, setRequiredTenantRentableBuildingArea] = React.useState("");
const [requiredTenantPropertyRentableBuildingArea, setRequiredTenantPropertyRentableBuildingArea] = React.useState("");

const [tenantRentableBuildingArea, setTenantRentableBuildingArea] = React.useState("");
const [tenantPropertyRentableBuildingArea,setTenantPropertyRentableBuildingArea]=React.useState("");


const [name, setName] = React.useState("");
const [legalName, setLegalName] = React.useState("");
const [dba,setDba]=React.useState("");
const [feinNumber,setFeinNumber]=React.useState("");
const [address1,setAddress1]=React.useState("");
const [address2,setAddress2]=React.useState("");
const [notificationEmail,setNotificationEmail]=React.useState("");
const [numberOfLocations,setNumberOfLocations]=React.useState("");
const [useOfSpace,setUseOfSpace]=React.useState("");
const [countryId,setcountryId]=React.useState("");
const [propertyName,setPropertyName]=React.useState("");
const [city,setCity]=React.useState("");
const [country,setCountry]=React.useState("");
const [state,setState]=React.useState("");
const [annualRent,setAnnualRent]=React.useState("");
const [zip,setZip]=React.useState("");
const [leaseStartDate,setLeaseStartDate]=React.useState("");
const [leaseEndDate,setLeaseEndDate]=React.useState("");
const [ phnNo1,setPhnNo1]=React.useState("");
const [ phnNo2,setphnNo2]=React.useState("");
const [contifyId,setContifyId]=React.useState("");
const [creditSafeId,setCreditSafeId]=React.useState("");
const [corteraId,setCorteraId]=React.useState("");
const [bankoId,setBankoId]=React.useState("");
const [tenantId , setTenantId ] = React.useState(location.state.tenantId);

const [propertyId , setPropertyId ] = React.useState("");
const [fundName , setFundName ] = React.useState("");
const [fundId , setFundId ] = React.useState("");
const[bankruptcy,setBankruptcy] = React.useState("");
const[initialDataPopulated,setInitialDataPopulated] = React.useState("");
const [dataStatus,setDataStatus]=React.useState(false);

const [openContify, setOpenContify] = React.useState(false);
const [openCreditSafe, setOpenCreditSafe] = React.useState(false);
const [openCortera, setOpenCortera] = React.useState(false);
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const[entityType,setEntityType]=React.useState("");
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const [checkedCortera, setCheckedCortera] = React.useState(false);
const [checkedCreditsafe, setCheckedCreditsafe] = React.useState(false);

const[naics,setNaics]=React.useState("");
const [requiredNaics,setRequiredNaics] = React.useState("");

const[parentId,setParentId]=React.useState("");
const[parentName,setParentName]=React.useState("");
const[requiredParent,setRequiredParent]=React.useState("");
const [openTlo, setOpenTlo] = React.useState(false);
const [requiredTloId, setRequiredTloId ]= React.useState("");
const [tloId,setTloId]=React.useState("");

const hideAlert = () => {
  setAlert(false);
}

  const allPropertyProps = {
    options: propertyWithUser,
    getOptionLabel: (option) => option.propertyName,
  };

  const selectedProperty = (value) => {
  
    if(value!=null){
    setPropertyId(value.id);
    setLabelPropertyName("");
    }
  };

  const recieveContifyId = (value) => {
    setContifyId(value);
    setOpenContify(false);

  //  alert("Final Page"+value);

  };
  const recieveCreditSafeId = (value) => {
    setCreditSafeId(value);
    setOpenCreditSafe(false);
  
  //  alert("Final Page"+value);

  };
  const recieveCorteraId = (value) => {
    setCorteraId(value);
    setOpenCortera(false);
  
  //  alert("Final Page"+value);

  };

  const recieveTloId = (value) => {
    setTloId(value);
    setOpenTlo(false);

  };
  
 
    useEffect(() => {
     
      async function getTenantById(tenantId) {
        await tmTenantByIdRequest(tenantId);
        if(tenantEditObj){
        setName(tenantEditObj.name);
        setLegalName(tenantEditObj.legalName);
        setDba(tenantEditObj.dba);
        setFeinNumber(tenantEditObj.feinNumber);
        setAddress1(tenantEditObj.address1);
        setAddress2(tenantEditObj.address2);
        setNotificationEmail(tenantEditObj.notificationEmail);
        setNumberOfLocations(tenantEditObj.numberOfLocations);
        setCity(tenantEditObj.city);
        setState(tenantEditObj.state);
        setCountry(tenantEditObj.country);
        setAnnualRent(tenantEditObj.annualRent);
        setPhnNo1(tenantEditObj.phnNo1);
        setphnNo2(tenantEditObj.phnNo2);
        setContifyId(tenantEditObj.contifyId);
        setCreditSafeId(tenantEditObj.creditSafeId);
        setCorteraId(tenantEditObj.corteraId);
        setBankoId(tenantEditObj.bankoId);
        setUseOfSpace(tenantEditObj.useOfSpace);
        setZip(tenantEditObj.zip);
        setLeaseStartDate(tenantEditObj.leaseStartDate);
        setLeaseEndDate(tenantEditObj.leaseEndDate);
        setOrganizationId(tenantEditObj.organisationId);
        setPropertyId(tenantEditObj.propertyId);
        setFundName(tenantEditObj.fundName);
        setFundId(tenantEditObj.fundId);
        setPropertyName(tenantEditObj.propertyName);
        setPropertyId(tenantEditObj.propertyId);
        setLabelPropertyName(tenantEditObj.propertyName);
        setLabelUseOfSpace(tenantEditObj.useOfSpace);
        setInitialDataPopulated(tenantEditObj.initialDataPopulated);
        setBankruptcy(tenantEditObj.bankruptcy);
        setTenantRentableBuildingArea(tenantEditObj.tenantRentableBuildingArea);
        setTenantPropertyRentableBuildingArea(tenantEditObj.tenantPropertyRentableBuildingArea);
        setCheckedCortera(tenantEditObj.corteraEnabled);
        setCheckedCreditsafe(tenantEditObj.creditSafeEnabled);
        setNaics(tenantEditObj.naics);    
        setParentId(tenantEditObj.parentId);
        setParentName(tenantEditObj.parentName);
        setLabelParentName(tenantEditObj.parentName);
        setTloId(tenantEditObj.tloId);
        setEntityType(tenantEditObj.entityType);
          
        
        }
      };
     

      setName("");
      setLegalName("");
      setDba("");
      setFeinNumber("");
      setAddress1("");
      setAddress2("");
      setNotificationEmail("");
      setNumberOfLocations("");
      setCity("");
      setState("");
      setCountry("");
      setAnnualRent("");
      setPhnNo1("");
      setphnNo2("");
     
      setUseOfSpace("");
      setZip("");
      setLeaseStartDate("");
      setLeaseEndDate("");
      setOrganizationId("");
      setPropertyId("");
      setFundName("");
      setFundId("");
      setPropertyName("");
      setPropertyId("");
      setLabelPropertyName("");
      setLabelUseOfSpace("");
      setInitialDataPopulated("");
      setBankruptcy("");
      setTenantRentableBuildingArea("");
      setTenantPropertyRentableBuildingArea("");
      setEntityType("");
      setNumberOfLocations("");
      setCheckedCortera("");
      setCheckedCreditsafe("");
      setNaics("");
      setParentId("");
      setParentName("");
      setLabelParentName("");
      
      getPropertyListWithUser(organisationId);
      getParentListByOrg(organisationId);
      // getPropertyListByOrg(organisationId);
      getTenantById(tenantId);
      

      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
     

      }, [dataStatus]);
  
  
       
  
      const getParentListByOrg = async (organisationId) => {
        await tmParentByOrgIdRequest(organisationId);
     
         };
  // const getPropertyListByOrg = async (organisationId) => {
  //  await tmPropertyByOrgIdRequest(organisationId);

  //   };
  const getPropertyListWithUser = async (organisationId) => {
    await tmPropertyWithUserRequest(organisationId);
 
     };


  
      
    //  setDataStatus(true);
      
     
      const handleClickOpenContify = () => {
        setOpenContify(true);
        setOpenCreditSafe(false);
        setOpenCortera(false);
      };
  
      const handleClickOpenCreditSafe = () => {
        setOpenContify(false);
        setOpenCreditSafe(true);
        setOpenCortera(false);
      };
      const handleClickOpenCortera = () => {
        setOpenContify(false);
        setOpenCreditSafe(false);
        setOpenCortera(true);
      };

      const handleClickOpenTlo = () => {
        setOpenContify(false);
        setOpenCreditSafe(false);
        setOpenCortera(false);
        setOpenTlo(true);
        
      };
  
  
      const handleCloseContify = () => {
        setOpenContify(false);
       // setrequiredContifyId("success");
      };
  
      const handleCloseCreditSafe = () => {
        setOpenCreditSafe(false);
        //setrequiredCreditSafeId("success");
      };
  
      const handleCloseCortera = () => {
        setOpenCortera(false);
      };
  
      const handleCloseTlo = () => {
        setOpenTlo(false);
      };

  const handleChange = (value) => {
    
  };

  function hideAlert1(event) {
    history.push({ 
      pathname: '/admin/tenantlist'
     });
    }
  const setSelectedLeaseStartDate=(value)=>{
    console.log("value"+value);
   value=Moment(value).format('MM-DD-YYYY');
  
   setLeaseStartDate(value);
  
   console.log("value"+value);
  //return value;
  }
  
  const setSelectedLeaseEndDate=(value)=>{
    console.log("value"+value);
   value=Moment(value).format('MM-DD-YYYY');
  
   setLeaseEndDate(value);
  
   console.log("value"+value);
  //return value;
  }
  const handleEntityType = event => {
    setEntityType(event.target.value);
  };

  const submitForm = () => {
  
    console.log("tenantRentableBuildingArea"+tenantRentableBuildingArea);
    console.log("tenantPropertyRentableBuildingArea"+tenantPropertyRentableBuildingArea);


 
    // if(requiredTenantPropertyRentableBuildingArea==="value"){
    //   setRequiredTenantPropertyRentableBuildingArea("success");
    // }
    
    if(verifyLength(name,1)&&verifyLength(address1,1)&&verifyLength(propertyId,1)&&verifyLength(city,1)&&verifyLength(state,1)&&verifyLength(tenantRentableBuildingArea,1)&&verifyLength(tenantPropertyRentableBuildingArea,1)&&verifyLength(naics,1)&&verifyLength(zip,1)
    // &&verifyLength(contifyId,1)&&verifyLength(creditSafeId,1)
    )
      
    

    //&&verifyLength(tenantRentableBuildingArea,1)&&verifyLengthRent(tenantPropertyRentableBuildingArea,1))
  {
const tenant={

id:tenantId,  
name: name,
legalName: legalName,
dba:dba,
feinNumber:feinNumber,
address1:address1,
address2:address2,
notificationEmail:notificationEmail,
numberOfLocations:numberOfLocations,
useOfSpace:useOfSpace,
countryId:countryId,
propertyName:propertyName,
city:city,
state:state,
zip:zip,
leaseStartDate:leaseStartDate,
leaseEndDate:leaseEndDate,
phnNo1:phnNo1,
phnNo2:phnNo2,
annualRent:annualRent,
country:country,
contifyId:contifyId,
corteraId:corteraId,
creditSafeId:creditSafeId,
bankoId,bankoId,
organisationId:organizationId,
propertyId:propertyId,
fundName:fundName,
fundId:fundId,
initialDataPopulated:initialDataPopulated,
bankruptcy:bankruptcy,
tenantRentableBuildingArea:tenantRentableBuildingArea,
tenantPropertyRentableBuildingArea:tenantPropertyRentableBuildingArea,
entityType:entityType,
creditSafeEnabled:checkedCreditsafe,
corteraEnabled:checkedCortera,

naics:naics,
parentId:parentId,
parentName:parentName,
tloId:tloId,
};
console.log("tenant"+tenant);

tmTenantEditRequest(tenant);
history.push( setAlert1(true),{ 
 
  pathname: '/admin/tenantlist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }
else{
   setAlert(true);
  if (requiredName === "") {
    setrequiredName("error");
  }

  if (requiredAddressName === "") {
    setrequiredAddressName("error");
  }

  if (requiredStateName === "") {
    setrequiredStateName("error");
  }

  
  if (requiredCityName === "") {
    setrequiredCityName("error");
  }
  if (requiredProperty=== "") {
    setrequiredProperty("error");
  }

  if (requiredTenantRentableBuildingArea=== "") {
    setRequiredTenantRentableBuildingArea("error");
  }
  if (requiredTenantPropertyRentableBuildingArea=== "") {
    setRequiredTenantPropertyRentableBuildingArea("error");
  }
  
  if (requiredNaics==="") {
    setRequiredNaics("error");
  }
  if(requiredZip === ""){
    setRequiredZip("error");
  }

  if(requiredContifyId === ""){
    setrequiredContifyId("error");
  }
  if(requiredCreditSafeId === ""){
    setrequiredCreditSafeId("error");
  }
 
}
  };


const verifyLength = (value) => {
  if (typeof value !=='undefined' && value)  {
    return true;
  }
  return false;
};

const verifyLengthRent = (value) => {
  if ((value!=null)) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const handleSimple = event => {
  setUseOfSpace(event.target.value);
};

const typeClick = () => {
  if (requiredName === "") {
    setrequiredName("error");
  }

  if (requiredLegalName === "") {
    setrequiredLegalName("error");
  }
  if (requiredAddressName === "") {
    setrequiredAddressName("error");
  }

  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (requiredState === "") {
    setrequiredState("error");
  }

  if (requiredCountryName === "") {
    setrequiredCountryName("error");
  }
  
  if (requiredCityName === "") {
    setrequiredCityName("error");
  }
  if (requiredProperty=== "") {
    setrequiredProperty("error");
  }


  if (rentState=== "") {
    setrentState("error");
  }
  if (requiredTenantRentableBuildingArea=== "") {
    setRequiredTenantRentableBuildingArea("error");
  }
  if (requiredTenantPropertyRentableBuildingArea=== "") {
    setRequiredTenantPropertyRentableBuildingArea("error");
  }
  
  if (phoneNumState=== "") {
    setPhoneNumState("error");
  }

  if (phoneNumTwoState=== "") {
    setPhoneNumTwoState("error");
  }



  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }

    const allParentProps = {

      options: parentByOrg,
       getOptionLabel: (option) => option.name,
     };

     const selectedParent = (value) => {
      setParentId(value.id);
      setParentName(value.name);
      setLabelPropertyName("");
    };
  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} >
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Edit Tenant</text> <span style={{marginLeft:"600px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
        <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"editTenant"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                   
            </CardHeader>
            <CardBody>

           
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Name *"
                    id="name"
                    value={name}

                    success={requiredName === "success"}
                    error={requiredName === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setName(event.target.value);
                          setrequiredName("success");
                          
                        } else {
                          setrequiredName("error");
                          setName("");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Legal Name"
                    id="legalName"
                    value={legalName}

                    success={requiredLegalName === "success"}
                    error={requiredLegalName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setLegalName(event.target.value);
                          setrequiredLegalName("success");
                        } else {
                          setrequiredLegalName("error");
                          setLegalName("");
                        }
                        setrequiredLegal(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredLegalName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="DBA"
                    id="dba"
                    value={dba}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setDba(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                    
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="FEIN"
                    id="feinNumber"
                    value={feinNumber}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setFeinNumber(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 1 *"
                    id="address1"
                    value={address1}
                    
                    success={requiredAddressName === "success"}
                    error={requiredAddressName === "error"}


                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setAddress1(event.target.value);
                          setrequiredAddressName("success");
                        } else {
                          setrequiredAddressName("error");
                          setAddress1("");
                        }
                        setrequiredAddress(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredAddressName  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput 
                  labelText="Notification Email"

                  success={typeEmailState === "success"}
                  error={typeEmailState === "error"}
                    id="notificationEmail"
                    value={notificationEmail}

                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyEmail(event.target.value,0)) {
                          setNotificationEmail(event.target.value);
                          settypeEmailState("success");
                        } else {
                          settypeEmailState("error");
                          
                        }
                        settypeEmail(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                        typeEmailState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 2"
                    id="address2"
                    value={tenantEditObj. address2}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,0)) {
                          setAddress2(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="# of Locations"
                    id="numberOfLocations"
                    value={numberOfLocations}

                    success={numberState=== "success"}
                    error={numberState === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value, 0)) {
                          setNumberOfLocations(event.target.value);
                          setnumberState("success");
                        } else {
                          setnumberState("error");
                          setNumberOfLocations("");
                        }
                        settypeEmail(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      numberState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
              </GridContainer>

<GridContainer>
<GridItem xs={12} sm={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
           
          >
            Use of Space
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            defaultValue={useOfSpace}
            // value={useOfSpace}
            
            classes={{
              select: classes.select
            }}
           
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
                <MenuItem value="1">
               Headquarters

            </MenuItem>
            <MenuItem value="2">
          
Regional Office
            </MenuItem>
            <MenuItem value="3">
            Sales Office
            </MenuItem>
            <MenuItem value="4">
         
Back Office
            </MenuItem>
            <MenuItem value="5">
            Retail Store

            </MenuItem>
            <MenuItem value="6">
            Manufacturing
            </MenuItem>

            <MenuItem value="7">
            Warehouse
            </MenuItem>
            <MenuItem value="8">
            Office

            </MenuItem>
            <MenuItem value="9">
            Medical Office

            </MenuItem>
            <MenuItem value="10">
            Restaurant

            </MenuItem>
            <MenuItem value="11">
         
Other

            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
           
              
               
      <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    value={country}
                    success={requiredCountryName === "success"}
                    error={requiredCountryName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setCountry(event.target.value);
                          setrequiredCountryName("success");
                        } else {
                          setrequiredCountryName("error");
                          setCountry("");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredCountryName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
</GridContainer>

                <GridContainer>
                 <GridItem xs={12} sm={12} md={6}>  
                 {<Autocomplete
                
                success={requiredProperty === "success"}
                error={requiredProperty === "error"}
                  onChange={(event, newValue) => {
                    
                    selectedProperty(newValue);
                    
                  }}
                  inputProps={{
                    onChange: value => {
                      if (verifyLength(value, 1)) {
                        setPropertyId(value)
                        setrequiredProperty("success");
                      } else {
                        setrequiredProperty("error");
                        setPropertyId("");
                      }
                      setrequiredProperty(value);
                    },
                    type: "text",
                    endAdornment:
                   
                    requiredProperty === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      )
                  }}

        {...allPropertyProps}
        id="propertyName"
        
        debug
        size='medium'
        renderInput={(params) => <TextField  success={requiredProperty === "success"}
        error={requiredProperty === "error"} {...params} defaultValue={labelPropertyName}  onChange={({ target }) => setInput(target.value)}   label={labelPropertyName}  margin="normal" />}
      /> }

               
                 </GridItem> 

                 <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="City *"
                    id="city"
                    value={city}
                    success={requiredCityName=== "success"}
                    error={requiredCityName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setCity(event.target.value);
                          setrequiredCityName("success");
                        } else {
                          setrequiredCityName("error");
                          setCity("");
                        }
                        setrequiredCity(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredCityName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>

 </GridContainer>
                      
            

<GridContainer>
                

<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="State *"
                    id="state"
                  
                    value={state}
                    success={requiredStateName=== "success"}
                    error={requiredStateName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setState(event.target.value);
                          setrequiredStateName("success");
                        } else {
                          setrequiredStateName("error");
                          setState("");
                        }
                        setrequiredState(event.target.value);
                      },
                      type: "text",

                      endAdornment:
                     
                      requiredStateName=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>


                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Annual Rent"
                    id="annualRent"
                    value={annualRent}

                    success={rentState=== "success"}
                    error={rentState=== "error"}


                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value,0)) {
                          setAnnualRent(event.target.value);
                          setrentState("success");
                        } else {
                          setrentState("error");
                          setAnnualRent("");
                        }
                        setrent(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                      rentState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
</GridContainer>

              {/* <Button color="rose" className={classes.updateProfileButton}>
                Update Profile
              </Button> */}
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Rentable Building Area *"
                    id="tenantRentableBuildingArea"
                    value={tenantRentableBuildingArea}

                    success={requiredTenantRentableBuildingArea=== "success"}
                    error={requiredTenantRentableBuildingArea=== "error"}


                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setTenantRentableBuildingArea(event.target.value);
                          setRequiredTenantRentableBuildingArea("success");
                        } else {
                          setRequiredTenantRentableBuildingArea("error");
                          setTenantRentableBuildingArea("");
                      
                        }
                        setRentableBuldingArea(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                      requiredTenantRentableBuildingArea === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>


            


                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                   labelText="Total Rentable Building Area *"
                   id="tenantPropertyRentableBuildingArea"
                   value={tenantPropertyRentableBuildingArea}

                   success={requiredTenantPropertyRentableBuildingArea=== "success"}
                   error={requiredTenantPropertyRentableBuildingArea=== "error"}

                    formControlProps={{
                      fullWidth: true
                    }}
                 


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setTenantPropertyRentableBuildingArea(event.target.value);
                          setRequiredTenantPropertyRentableBuildingArea("success");
                        } else {
                          setRequiredTenantPropertyRentableBuildingArea("error");
                          setTenantPropertyRentableBuildingArea("");
                        }
                        setRequiredTenantPropertyRentableBuildingArea(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                      requiredTenantPropertyRentableBuildingArea  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
</GridContainer>

<GridContainer>
                

<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="ZipCode *"
                    id="zipCode"
                   value={zip}
                    success={requiredZip=== "success"}
                    error={requiredZip=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setZip(event.target.value);
                          setRequiredZip("success");
                        } else {
                          setRequiredZip("error");
                        }
                        setRequiredZip(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                      requiredZip === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="NAICS *"
                    id="naics"
                    value={naics}
                    success={requiredNaics === "success"}
                    error={requiredNaics === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setNaics(event.target.value);
                          setRequiredNaics("success");
                        } else {
                          setRequiredNaics("error");
                        }
                        setRequiredNaics(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredNaics === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>

</GridContainer>
<GridContainer>

<GridItem xs={12} sm={12} md={6}>
<FormControl fullWidth className={classes.selectFormControl}>
<CustomInput
    labelText="Phone NO1:Example +15243567896,+14352345678"
    
     id="phnNo1"

     
     value={phnNo1}
     success={phoneNumState === "success"}
     error={phoneNumState === "error"}
     
     formControlProps={{
       fullWidth: true
     }}



     inputProps={{
       onChange: event => {
         if (verifyLength(event.target.value,0)) {
           setPhnNo1(event.target.value);
           setPhoneNumState("success");
         } else {
           setPhoneNumState("error");
           setPhnNo1("");
         }
         setPhoneNum(event.target.value);
       },
       type: "number",
       endAdornment:
       phoneNumState === "error" ? (
           <InputAdornment position="end">
             <Close className={classes.danger} />
           </InputAdornment>
         ) : (
           undefined
         )
     }}
   />

</FormControl>
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>

<FormControl fullWidth className={classes.selectFormControl}>
<CustomInput
     labelText="Phone NO2:Example +15243567896,+14352345678"
     id="phnNo2"
value={phnNo2}
     success={phoneNumTwoState=== "success"}
     error={phoneNumTwoState=== "error"}
     
     formControlProps={{
       fullWidth: true
     }}



     inputProps={{
       onChange: event => {
         if (verifyLength(event.target.value,0)) {
           setphnNo2(event.target.value);
           setPhoneNumTwoState("success");
         } else {
           setPhoneNumTwoState("error");
           setphnNo2("");
         }
         setPhoneNumTwo(event.target.value);
       },
       type: "number",
       endAdornment:
       phoneNumTwoState === "error" ? (
           <InputAdornment position="end">
             <Close className={classes.danger} />
           </InputAdornment>
         ) : (
           undefined
         )
     }}
   />
</FormControl>
</GridItem>

  


</GridContainer>

              


              {/* <GridContainer> */}
                
                
              {/* </GridContainer> */}

              <GridContainer>
            
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel className={classes.label}>
        Lease Start Date
      </InputLabel>
      <br />
      <FormControl fullWidth>
        <Datetime
          timeFormat={false}
          dateFormat="MM-DD-YYYY"
          value={leaseStartDate}
          onChange={setSelectedLeaseStartDate}
        />
      </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <InputLabel className={classes.label}>
        Lease End Date
      </InputLabel>
      <br />
      <FormControl fullWidth>
        <Datetime
          timeFormat={false}
          dateFormat="MM-DD-YYYY"
          onChange={setSelectedLeaseEndDate}
          value={leaseEndDate}
        />
      </FormControl>
   </GridItem>
  
  
</GridContainer>
<GridContainer>
<GridItem xs={12} sm={12} md={6}>  
                 
                 {<Autocomplete
                 
                 success={requiredParent === "success"}
                 error={requiredParent === "error"}
                   onChange={(event, newValue) => {
                     
                    selectedParent(newValue);
                     
                   }}
                   inputProps={{
                     onChange: value => {
                       if (verifyLength(value, 1)) {
                         setParentId(value)
                         setRequiredParent("success");
                       } else {
                         setRequiredParent("error");
                         setParentId("");
                       }
                       setRequiredParent(value);
                     },
                     type: "text",
                     endAdornment:
                    
                     requiredProperty === "error" ? (
                         <InputAdornment position="end">
                           <Close className={classes.danger} />
                         </InputAdornment>
                       ) : (
                         undefined
                       )
                   }}
 
         {...allParentProps}
         id="parentName"
         
         debug
         size='medium'
         renderInput={(params) => <TextField  success={requiredParent === "success"}
         error={requiredParent === "error"} {...params} defaultValue={labelParentName}  onChange={({ target }) => setInput(target.value)}   label={labelParentName}  margin="normal"/>}
       /> }
                  </GridItem> 

                  <GridItem xs={12} sm={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            Entity Type
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={tenantEditObj.entityType}
            onChange={handleEntityType}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="Parent">
               Parent
            </MenuItem>
            <MenuItem value="Sub Tenant">
             Sub Tenant
            </MenuItem>
            <MenuItem value="Gaurantor">
            Gaurantor
            </MenuItem>
            <MenuItem value="Ultimate">
            Ultimate
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
</GridContainer>
<GridContainer>
          
            
    
          <GridItem xs={12} sm={12} md={6}>
          <br></br>
          
          <FormControlLabel
    control={
      <Switch
        checked={checkedCreditsafe}
        // disabled="true"
        onChange={event => setCheckedCreditsafe(event.target.checked)}
        value={checkedCreditsafe}
        color="primary"
        classes={{
          switchBase: classes.switchBase,
          checked: classes.switchChecked,
          thumb: classes.switchIcon,
          track: classes.switchBar,
         
        }}
      />
    }
    classes={{
      label: classes.label
    }}
    label="CreditSafe"
    name="creditSafe"
  />
          </GridItem>
      
          <GridItem xs={12} sm={12} md={6}>
          <br></br>
          
          <FormControlLabel
    control={
      <Switch
        checked={checkedCortera}
       
        onChange={event => setCheckedCortera(event.target.checked)}
        value={checkedCortera}
        color="primary"
        classes={{
          switchBase: classes.switchBase,
          checked: classes.switchChecked,
          thumb: classes.switchIcon,
          track: classes.switchBar
        }}
      />
    }
    classes={{
      label: classes.label
    }}
  label="Cortera"
    name="cortera"
  />
          </GridItem>
        
          
        </GridContainer>


<GridContainer>
                <GridItem xs={5} > 
               
              <CustomInput
                labelText="Contify ID *"
                id="disabled"
                value={contifyId}
                success={requiredContifyId=== "success"}
                error={requiredContifyId=== "error"}
                formControlProps={{
                 fullWidth: true
                 }}
                  inputProps={{
                disabled: true
               }}
              />
              </GridItem>
              <GridItem xs={1}> 
               
{/* <Icon onClick={() => setModal(true)}><VpnKeyIcon/></Icon> */}
{/* <Button onClick={() => setModal(true)}>show modal</Button> */}
<br></br>
<br></br>
<Icon onClick={handleClickOpenContify}><VpnKeyIcon/></Icon>
       <div>
         
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openContify} onClose={handleCloseContify} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseContify}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent>
          <ContifyIdModalPage sendDataBack={recieveContifyId}/>
          </DialogContent>
        
        
          
            </Dialog>
            </div>
            </GridItem>
        
          {/*</GridContainer><label>Company Name:</label>
          <TextField id="standard-basic"/>
          <br></br>
          <label>Company URL:</label>
          <TextField id="standard-basic"/>
{/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            type="email"
            fullWidth
          /> */}

 
      


<GridItem xs={5} >
                <CustomInput
                labelText="Credit Safe ID *"
                id="disabled"
                value={creditSafeId}
                success={requiredCreditSafeId=== "success"}
                error={requiredCreditSafeId=== "error"}
                formControlProps={{
                 fullWidth: true
                 }}
                  inputProps={{
                disabled: true
               }}
              />
                </GridItem>

                <GridItem xs={1}>

{/* <Button onClick={() => setModal(true)}>show modal</Button> */}
{/* <Icon onClick={() => setModal(true)}><VpnKeyIcon/></Icon> */}
<br></br>
<br></br>
<Icon onClick={handleClickOpenCreditSafe}><VpnKeyIcon/></Icon>
<div>
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openCreditSafe} onClose={handleCloseCreditSafe} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
       
       
        <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseCreditSafe}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent>
<CreditSafeIdModalPage sendDataBack={recieveCreditSafeId}/>
        </DialogContent>
       
       
      </Dialog>
       </div>
       </GridItem>

              
              </GridContainer>

              


              

              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="zipcode1"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Contify ID" margin="normal" />}
      /> }
                </GridItem>
              
</GridContainer>
 */}


<GridContainer>
  <GridItem xs={5}>

  <CustomInput labelText="Cortera ID" id="disabled" value={corteraId}  formControlProps={{ fullWidth: true}} inputProps={{disabled: true}}/>

</GridItem>
<GridItem xs={1}>
{/* <Icon onClick={() => setModal(tr ue)}><VpnKeyIcon/></Icon> */}
{/* <Button onClick={() => setModal(true)}>show modal</Button> */}
<br></br>
<br></br>
<Icon onClick={handleClickOpenCortera}><VpnKeyIcon/></Icon>
<div>
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openCortera} onClose={handleCloseCortera} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
       
         <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseCortera}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
        <DialogContent>
        {/*<label>Comany Name:</label>
          <TextField id="standard-basic"/>
          <br></br>
          <label>Company URL:</label>
          <TextField id="standard-basic"/>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            // type="email"
            fullWidth
          /> */}



 
        <CorteraIdModalPage sendDataBack={recieveCorteraId}/>
        </DialogContent>
       
      </Dialog>
       </div>
       </GridItem>

               
       <GridItem xs={5}>
                  
                <FormControl fullWidth className={classes.selectFormControl}>
                         
                <CustomInput style={{fontSize:"20px"}}
                labelText="Banko ID *" id="disabled"
                //  id="standard-read-only-input"
                     value={bankoId} 
                //  label="Banko Id *"
                 InputProps={{
                   readOnly: true,
                 }}
               
                  
                
                  ></CustomInput>
               
         
              </FormControl>
                </GridItem>

                <GridItem xs={1}>
                

                {/* <Icon onClick={() => setModal(true)}><VpnKeyIcon/></Icon> */}
{/* <Button onClick={() => setModal(true)}>show modal</Button> */}

<Tooltip classes={{ tooltip: classes.customWidth }} title="BankoId Already generated">

<Icon onClick={handleClickOpen}><InfoOutlinedIcon/></Icon>
</Tooltip>
<div>
         
       {/*<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        {/*<DialogContent>
        {/*<label>Comany Name:</label>
          <TextField id="standard-basic"/>
          <br></br>
          <label>Company URL:</label>
          <TextField id="standard-basic"/>

          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            type="email"
            fullWidth
          /> */}

 </div>

</GridItem>
</GridContainer>
<GridContainer>
         
         <GridItem xs={5} >
         <CustomInput
         labelText="TLO ID *"
         id="disabled"
         success={requiredTloId=== "success"}
         error={requiredTloId=== "error"}
         value={tloId}
         formControlProps={{
          fullWidth: true
          }}
           inputProps={{
         disabled: false
        }}
       />
         </GridItem>

         <GridItem xs={1}>

<br></br>
<br></br>
<Icon onClick={handleClickOpenTlo}><VpnKeyIcon/></Icon>
<div>
<Dialog  fullWidth={true}
maxWidth={'lg'} open={openTlo} onClose={handleCloseTlo} fullWidth={true} aria-labelledby="form-dialog-title">
 
 <GridItem align="right" paddingTop="20px">
 <DialogActions>
 <Button 
   justIcon
   round
   simple
   onClick={handleCloseTlo}
   color="info"
   className="close" >
   <CloseIcon/>
   </Button>
  
   </DialogActions>
   </GridItem>
 <DialogContent>

 <TLOModalPage sendDataBack={recieveTloId}/>
 </DialogContent>

</Dialog>
</div>
</GridItem>

       
       </GridContainer>
<br></br>
<br></br>
<GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>
<div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>  
                     <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Tenant Edited Successfully!
</SweetAlert>
</div>
              <Clearfix />
            </CardBody>
          </Card>
          
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
  
}
}

TenantEdit.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertySaveRequest: PropTypes.func.isRequired,
  tmPropertyByOrgIdRequest:PropTypes.func.isRequired,
  tmPropertyListRequest:PropTypes.func.isRequired,
  tmTenantSaveRequest:PropTypes.func.isRequired,
  tmTenantEditRequest: PropTypes.func.isRequired,
  tmTenantByIdRequest: PropTypes.func.isRequired,
  tmParentByOrgIdRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  propertyList: state.tenantmonitoring.propertyList,
  loading: state.tenantmonitoring.loading,
  tenantEdit: state.tenantmonitoring.tenantEdit,
  tenant: state.tenantmonitoring.tenant,
  // propertyByOrg:state.tenantmonitoring.propertyByOrg,
  propertyWithUser:state.tenantmonitoring.propertyWithUser,
  tenantEditObj: state.tenantmonitoring.tenantEditObj,
  parentByOrg: state.tenantmonitoring.parentByOrg,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TenantEdit);