
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';


import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";
import Moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Sidebar from "components/Sidebar/Sidebar.js";

import dateFormat from 'dateformat';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const ActiveStatusFtl = ({user,tmGetActiveStatusFtlRequest,activeStatusFtl,date1,userId}) => {

  const history = useHistory();

  const location = useLocation();
  const [input, setInput] = React.useState('');
 

const [dataStatus, setDataStatus] = React.useState(false);
const [status, setStatus] = React.useState("");

const [showCautionaryreports, setShowCautionaryreports] = React.useState(true);
// const [month,setMonth] = React.useState(moment().subtract(1, 'months').format("MMM").toLowerCase());
// const [year,setYear] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());

const [logo, setLogo] = React.useState(require("assets/img/tenanttracker_11803_logo_500.png"));
const [megaLogo, setMegaLogo] = React.useState(require("assets/img/mega_11803_logo_notag_500.jpg"));

const [scoreDTOList, setScoreDTOList] = React.useState(false);
const [scoreDeclinedDTOList, setScoreDeclinedDTOList] = React.useState(false);
const [showNoDataFound, setShowNoDataFound] = React.useState(true);

const month=dateFormat(date1, 'mmm').toLowerCase();
const year=dateFormat(date1, 'yyyy');

React.useEffect(() => {
  setShowNoDataFound(false);
  if(!dataStatus){
  getDerogatoryFtl(userId,month,year);
  // setScoreDTOList(true);
  // setScoreDeclinedDTOList(true);
  }
   console.log("activeStatusFtl"+activeStatusFtl);
   if((activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDTOList.length === 0) && (activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDeclinedDTOList.length > 0)){
    console.log("111111");
    setScoreDTOList(false);
    setScoreDeclinedDTOList(true);
  }
  if((activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDTOList.length > 0) && (activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDeclinedDTOList.length === 0)){
    console.log("2222222");
    setScoreDTOList(true);
    setScoreDeclinedDTOList(false);
  }
if((activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDTOList.length === 0) && (activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDeclinedDTOList.length === 0)){
  console.log("333333");
  setScoreDTOList(false);
  setScoreDeclinedDTOList(false);
  setShowNoDataFound(true);
}
if((activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDTOList.length > 0) && (activeStatusFtl!=""&&activeStatusFtl.propertyActiveStatusDeclinedDTOList.length > 0)){
  console.log("333cccccc");
  setScoreDTOList(true);
  setScoreDeclinedDTOList(true);
}
  setTimeout(() => {
    setDataStatus(true);
    }, 3000);

}, [dataStatus]);



const getDerogatoryFtl=async(userId,month,year)=>{
  await tmGetActiveStatusFtlRequest(userId,month,year)
  
    }

  const classes = useStyles(); 

  if(dataStatus){
    return (
    <div>
      <form>
      <GridContainer>

<GridItem xs={12} sm={12} md={9} style={{marginTop:"17px"}} >
     
        <img  src={logo} alt="Card-img" height="50" width="200"  />

 
    </GridItem>

     <GridItem xs={12} sm={12} md={3} align="right">

             <h5 style={{fontSize:'18px',color: "#000000"}} >{dateFormat(date1, 'mmmm d,yyyy')}
            <div style={{fontSize:'18px',color: "#000000"}} >{activeStatusFtl.organizationName}</div></h5>

</GridItem>

              
              {scoreDTOList&& <GridItem xs={12} sm={12} md={12} >
      <h5 style={{fontSize:'20px',fontWeight:"bold",color: "#000000"}} >The corporate filing status of the following tenants have moved from active to Inactive status.</h5>
     <table style={{ width: 880 }}>
           
    <tr>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94",textAlign: "left", padding: "7px",color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Property</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94", textAlign: "left",padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Company</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94", textAlign: "center",padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Prior Status</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94",textAlign: "center", padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Current Status</td>

        </tr>
        {activeStatusFtl.propertyActiveStatusDTOList.map(status  => (      <tr>


       <td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", padding: "7px"}}>{status.propertyName}</td>

<td style={{backgroundColor:"#eeeeee", border: "1px solid #ffffff", fontSize: "15px", padding: "7px"}}>{status.companyName}</td>

<td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", textAlign: "center"}}>{status.prevScore}</td>

 <td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", textAlign: "center"}}>{status.newScore}</td>

</tr>))}
            </table>
              
              </GridItem>}
          {scoreDeclinedDTOList&&<GridItem xs={12} sm={12} md={12} >
              <h5 style={{fontSize:'20px',fontWeight:"bold",color: "#000000"}} >The corporate filing status of the following tenants have moved from Inactive to active status.</h5>
             <table style={{ width: 880 }}>
           
                <tr>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94",textAlign: "left", padding: "7px",color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Property</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94", textAlign: "left",padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Company</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94", textAlign: "center",padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Prior Status</td>
            <td style={{border: "1px solid #dddddd", backgroundColor: "#a89c94",textAlign: "center", padding: "7px", color:"#ffffff", fontWeight: "bold", fontSize:"16px"}}>Current Status</td>

        </tr>
        {activeStatusFtl.propertyActiveStatusDeclinedDTOList.map(status  => (  <tr>


<td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", padding: "7px"}}>{status.propertyName}</td>
<td style={{backgroundColor:"#eeeeee", border: "1px solid #ffffff", fontSize: "15px", padding: "7px"}}>{status.companyName}</td>
<td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", textAlign: "center"}}>{status.prevScore}</td>
<td style={{backgroundColor: "#eeeeee", border: "1px solid #ffffff", fontSize: "15px", textAlign: "center"}}>{status.newScore}</td>
</tr>))}
            </table>
              
              </GridItem>}



    <GridItem xs={12} sm={12} md={7} style={{marginTop:"17px"}} >
     
     <img  src={megaLogo} alt="Card-img" height="50" width="200" />
 </GridItem>

 <GridItem xs={12} sm={12} md={5} align="right"  style={{marginTop:"20px"}}>

 <h5 style={{fontSize:'14px',color: "#000000"}} >Please go to  <a href = {activeStatusFtl.appUrl} target = "_blank">otso.megalytics.net</a>&nbsp;to see the details.</h5>

</GridItem>

{showNoDataFound&&<GridItem align="center" >
              <h3><b>No Data Available...</b></h3>
            </GridItem>
  }
      </GridContainer>
      </form>
    </div>
  );

}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}
ActiveStatusFtl.propTypes = {
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmGetActiveStatusFtlRequest: PropTypes.func.isRequired,

  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  activeStatusFtl: state.tenantmonitoring.activeStatusFtl,
  loading: state.tenantmonitoring.loading,
  // tenantId: state.tenantmonitoring.tenantId,
  // uccScore: state.tenantmonitoring.uccScore,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveStatusFtl);


