
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import bailoutImg from "assets/img/pirs/bailout.png";
import businessRemote from "assets/img/pirs/businessRemote.png";
import Box from '@material-ui/core/Box';

import changeInEmployment from "assets/img/pirs/changeInEmployment.png";
import consumerConfidence from "assets/img/pirs/consumerConfidence.png";
import degreeOfOpenness from "assets/img/pirs/degreeOfOpenness.png";
import directImpact from "assets/img/pirs/directImpact.png";
import GovernmentBailOut from "assets/img/pirs/GovernmentBailOut.png";
import indirectImpact from "assets/img/pirs/indirectImpact.png";
import openess from "assets/img/pirs/openess.png";
import operationalmitigation from "assets/img/pirs/operationalmitigation.png";
import outlookForRecovery from "assets/img/pirs/outlookForRecovery.png";
import supplychain from "assets/img/pirs/supplychain.png";
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import PermIdentity from "@material-ui/icons/PermIdentity";
import CardIcon from "components/Card/CardIcon.js";
import SideMenuBaseProperty from 'components/MenuBase/SideMenuBaseProperty.js';

import moment from 'moment';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
 cardTitle,
 roseColor,megaColor
} from "assets/jss/material-dashboard-pro-react.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react";
import { Toll } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

const styles = {
 cardTitle,
 cardTitleWhite: {
 ...cardTitle,
 color: "#FFFFFF",
 marginTop: "0"
 },
 cardCategoryWhite: {
 margin: "0",
 color: "rgba(255, 255, 255, 0.8)",
 fontSize: ".875rem"
 },
 cardCategory: {
 color: "#999999",
 marginTop: "10px"
 },
 imgPadding :{
 paddingLeft : "20px"
 },
 icon: {
 color: "#333333",
 margin: "10px auto 0",
 width: "130px",
 height: "130px",
 border: "1px solid #E5E5E5",
 borderRadius: "50%",
 lineHeight: "174px",
 "& svg": {
 width: "55px",
 height: "55px"
 },
 "& .fab,& .fas,& .far,& .fal,& .material-icons": {
 width: "55px",
 fontSize: "55px"
 }
 },
 iconRose: {
 color: roseColor
 },
 marginTop30: {
 marginTop: "30px"
 },
 testimonialIcon: {
 marginTop: "30px",
 "& svg": {
 width: "40px",
 height: "40px"
 }
 },
 cardTestimonialDescription: {
 fontStyle: "italic",
 color: "#999999"
 },
 customWidth: {
 maxWidth: 200,
 fontSize: "15px",
 backgroundColor: megaColor,
 }
};

const useStyles = makeStyles(styles);

//export default function Widgets() {

 const GenerateScore = ({ loading,scores,tmTenantsScoreByPIRSRequest,tenantPIRSMap,tenantId,propertyListAssetManager}) =>{
 
 const location = useLocation();
 
 const[tenantName,setTenantName] =React.useState("");

// const enableProperty = (value) => {
 
// setPropertyId(value.propertyId); 
// setPropertyName(value.propertyName);
// setShowHeatMapFundRisk(true);
// setHeatMapPropertyVisible(false);
// setHeatMapPropertyTenantVisible(false);
 
// }
 
 // const [bailoutMlScore, setBailoutMlScore] = React.useState(scores.bailoutMlScore);
 // const [changeInJobs, setChangeInJobs] = React.useState(scores.changeInJobs);
 // const [consumerConfidenceScore, setConsumerConfidenceScore] = React.useState(scores.consumerConfidence);
 // const [indirectImpactMlScore, setIndirectImpactMlScore] = React.useState(scores.indirectImpactMlScore);
 // const [mlDirectScore, setMlDirectScore] = React.useState(scores.mlDirectScore);
 // const [recoveryMlScore, setRecoveryMlScore] = React.useState(scores.recoveryMlScore);
 // const [openness, setOpenness] = React.useState(scores.openness);
 // const [percentRemote, setPercentRemote] = React.useState(scores.percentRemote);
 // const [overallPirsMlScore, setOverallPirsMlScore] = React.useState(scores.overallPirsMlScore);
 // const [supplyChainConstraint, setSupplyChainConstraint] = React.useState(scores.supplyChainConstraint);
 // const [operationalMitigationMlScore, setOperationalMitigationMlScore] = React.useState(scores.operationalMitigationMlScore);
 
 const [bailoutMlScore, setBailoutMlScore] = React.useState("");
 const [changeInJobs, setChangeInJobs] = React.useState("");
 const [consumerConfidenceScore, setConsumerConfidenceScore] = React.useState("");
 const [indirectImpactMlScore, setIndirectImpactMlScore] = React.useState("");
 const [mlDirectScore, setMlDirectScore] = React.useState("");
 const [recoveryMlScore, setRecoveryMlScore] = React.useState("");
 const [openness, setOpenness] = React.useState("");
 const [percentRemote, setPercentRemote] = React.useState("");
 const [overallPirsMlScore, setOverallPirsMlScore] = React.useState("");
 const [supplyChainConstraint, setSupplyChainConstraint] = React.useState("");
 const [operationalMitigationMlScore, setOperationalMitigationMlScore] = React.useState("");

 const [bailoutMlScoreCSS, setBailoutMlScoreCSS] = React.useState("");
 const [changeInJobsCSS, setChangeInJobsCSS] = React.useState("");
 const [consumerConfidenceScoreCSS, setConsumerConfidenceScoreCSS] = React.useState("");
 const [indirectImpactMlScoreCSS, setIndirectImpactMlScoreCSS] = React.useState("");
 const [mlDirectScoreCSS, setMlDirectScoreCSS] = React.useState("#4caf50");
 const [mlDirectScoreCSS1, setMlDirectScoreCSS1] = React.useState("#ffdf00");
 const [mlDirectScoreCSS2, setMlDirectScoreCSS2] = React.useState("#ff9800");
 const [mlDirectScoreCSS3, setMlDirectScoreCSS3] = React.useState("#FF0000");
 const [mlDirectScoreCSS4, setMlDirectScoreCSS4] = React.useState("#32cd32");

 const [recoveryMlScoreCSS, setRecoveryMlScoreCSS] = React.useState("");
 const [opennessCSS, setOpennessCSS] = React.useState("");
 const [percentRemoteCSS, setPercentRemoteCSS] = React.useState("");
 const [overallPirsMlScoreCSS, setOverallPirsMlScoreCSS] = React.useState("");
 const [supplyChainConstraintCSS, setSupplyChainConstraintCSS] = React.useState("");
 const [operationalMitigationMlScoreCSS, setOperationalMitigationMlScoreCSS] = React.useState("");
 const [dataStatus, setDataStatus] = React.useState(false);
 const [month, setMonth] = React.useState(moment().subtract(1, "month").format('MMMM').toUpperCase());
 const [year, setYear] = React.useState(moment().format('YYYY'));


 var now = moment();
 var monthArray=[];
 var yearArray=[];
 

 React.useEffect(() => {
 if(!dataStatus){
 getTenantsScoreByPIRS(tenantId,month,year);
 }
 
 else{

 
 if(bailoutMlScore > 0 && bailoutMlScore <= 100){
 setBailoutMlScoreCSS("#FF0000")
 }else if(bailoutMlScore > 101 && bailoutMlScore < 200){
 setBailoutMlScoreCSS("#ff9800")
 }else if(bailoutMlScore > 201 && bailoutMlScore <= 300){
 setBailoutMlScoreCSS("#ffdf00")
 }else if(bailoutMlScore > 301 && bailoutMlScore <= 400){
 setBailoutMlScoreCSS("#32cd32")
}else if(bailoutMlScore > 401 && bailoutMlScore <= 500){
 setBailoutMlScoreCSS("#4caf50")
}

if(changeInJobs > 0 && changeInJobs <= 100){
 setChangeInJobsCSS("#FF0000")
 }else if(changeInJobs > 101 && changeInJobs <= 200){
 setChangeInJobsCSS("#ff9800")
 }else if(changeInJobs > 201 && changeInJobs <= 300){
 setChangeInJobsCSS("#ffdf00")
 }else if(changeInJobs > 301 && changeInJobs <= 400){
 setChangeInJobsCSS("#32cd32")
}else if(changeInJobs > 401 && changeInJobs <= 500){
 setChangeInJobsCSS("#4caf50")
}


if(consumerConfidenceScore > 0 && consumerConfidenceScore <= 100){
 setConsumerConfidenceScoreCSS("#FF0000")
 }else if(consumerConfidenceScore > 101 && consumerConfidenceScore <= 200){
 setConsumerConfidenceScoreCSS("#ff9800")
 }else if(consumerConfidenceScore > 201 && consumerConfidenceScore <= 300){
 setConsumerConfidenceScoreCSS("#ffdf00")
 }else if(consumerConfidenceScore > 301 && consumerConfidenceScore <= 400){
 setConsumerConfidenceScoreCSS("#32cd32")
}else if(consumerConfidenceScore > 401 && consumerConfidenceScore <= 500){
 setConsumerConfidenceScoreCSS("#4caf50")
}

if(indirectImpactMlScore > 0 && indirectImpactMlScore <= 100){
 setIndirectImpactMlScoreCSS("#FF0000")
 }else if(indirectImpactMlScore > 101 && indirectImpactMlScore <= 200){
 setIndirectImpactMlScoreCSS("#ff9800")
 }else if(indirectImpactMlScore > 201 && indirectImpactMlScore <= 300){
 setIndirectImpactMlScoreCSS("#ffdf00")
 }else if(indirectImpactMlScore > 301 && indirectImpactMlScore <= 400){
 setIndirectImpactMlScoreCSS("#32cd32")
}else if(indirectImpactMlScore > 401 && indirectImpactMlScore <= 500){
 setIndirectImpactMlScoreCSS("#4caf50")
}

if(mlDirectScore > 0 && mlDirectScore <= 100){
 setMlDirectScoreCSS("#FF0000")
 }else if(mlDirectScore > 101 && mlDirectScore <= 200){
 setMlDirectScoreCSS("#ff9800")
 }else if(mlDirectScore > 201 && mlDirectScore <= 300){
 setMlDirectScoreCSS("#ffdf00")
 }else if(mlDirectScore > 301 && mlDirectScore <= 400){
 setMlDirectScoreCSS("#32cd32")
}else if(mlDirectScore > 401 && mlDirectScore <= 500){
 setMlDirectScoreCSS("#4caf50")
}


if(recoveryMlScore > 0 && recoveryMlScore <= 100){
 setRecoveryMlScoreCSS("#FF0000")
 }else if(recoveryMlScore > 101 && recoveryMlScore <= 200){
 setRecoveryMlScoreCSS("#ff9800")
 }else if(recoveryMlScore > 201 && recoveryMlScore <= 300){
 setRecoveryMlScoreCSS("#ffdf00")
 }else if(recoveryMlScore > 301 && recoveryMlScore <= 400){
 setRecoveryMlScoreCSS("#32cd32")
}else if(recoveryMlScore > 401 && recoveryMlScore <= 500){
 setRecoveryMlScoreCSS("#4caf50")
}

if(openness > 0 && openness <= 100){
 setOpennessCSS("#FF0000")
 }else if(openness > 101 && openness <= 200){
 setOpennessCSS("#ff9800")
 }else if(openness > 201 && openness <= 300){
 setOpennessCSS("#ffdf00")
 }else if(openness > 301 && openness <= 400){
 setOpennessCSS("#32cd32")
}else if(openness > 401 && openness <= 500){
 setOpennessCSS("#4caf50")
}

if(percentRemote > 0 && percentRemote <= 100){
 setPercentRemoteCSS("#FF0000")
 }else if(percentRemote > 101 && percentRemote <= 200){
 setPercentRemoteCSS("#ff9800")
 }else if(percentRemote > 201 && percentRemote <= 300){
 setPercentRemoteCSS("#ffdf00")
 }else if(percentRemote > 301 && percentRemote <= 400){
 setPercentRemoteCSS("#32cd32")
}else if(percentRemote > 401 && percentRemote <= 500){
 setPercentRemoteCSS("#4caf50")
}

if(overallPirsMlScore > 0 && overallPirsMlScore <= 100){
 setOverallPirsMlScoreCSS("#FF0000")
 }else if(overallPirsMlScore > 101 && overallPirsMlScore <= 200){
 setOverallPirsMlScoreCSS("#ff9800")
 }else if(overallPirsMlScore > 201 && overallPirsMlScore <= 300){
 setOverallPirsMlScoreCSS("#ffdf00")
 }else if(overallPirsMlScore > 301 && overallPirsMlScore <= 400){
 setOverallPirsMlScoreCSS("#32cd32")
}else if(overallPirsMlScore > 401 && overallPirsMlScore <= 500){
 setOverallPirsMlScoreCSS("#4caf50")
}


if(operationalMitigationMlScore > 0 && operationalMitigationMlScore <= 100){
 setOperationalMitigationMlScoreCSS("#FF0000")
 }else if(operationalMitigationMlScore > 101 && operationalMitigationMlScore <= 200){
 setOperationalMitigationMlScoreCSS("#ff9800")
 }else if(operationalMitigationMlScore > 201 && operationalMitigationMlScore <= 300){
 setOperationalMitigationMlScoreCSS("#ffdf00")
 }else if(operationalMitigationMlScore > 301 && operationalMitigationMlScore <= 400){
 setOperationalMitigationMlScoreCSS("#32cd32")
}else if(operationalMitigationMlScore > 401 && operationalMitigationMlScore <= 500){
 setOperationalMitigationMlScoreCSS("#4caf50")
}

if(supplyChainConstraint > 0 && supplyChainConstraint <= 100){
 setSupplyChainConstraintCSS("#FF0000")
 }else if(supplyChainConstraint > 101 && supplyChainConstraint <= 200){
 setSupplyChainConstraintCSS("#ff9800")
 }else if(supplyChainConstraint > 201 && supplyChainConstraint <= 300){
 setSupplyChainConstraintCSS("#ffdf00")
 }else if(supplyChainConstraint > 301 && supplyChainConstraint <= 400){
 setSupplyChainConstraintCSS("#32cd32")
}else if(supplyChainConstraint > 401 && supplyChainConstraint <= 500){
 setSupplyChainConstraintCSS("r#4caf50ed")
}
 }
setTimeout(() => {
 setDataStatus(true);
}, 3000);
 
 
 
 },[dataStatus]); 

 const getTenantsScoreByPIRS = async (tenantId,month,year) => {
 await tmTenantsScoreByPIRSRequest(tenantId,month,year);
 setBailoutMlScore(tenantPIRSMap.bailoutMlScore);
 setChangeInJobs(tenantPIRSMap.changeInJobs);
 setConsumerConfidenceScore(tenantPIRSMap.consumerConfidence);
 setIndirectImpactMlScore(tenantPIRSMap.indirectImpactMlScore);
 setMlDirectScore(tenantPIRSMap.mlDirectScore);
 setRecoveryMlScore(tenantPIRSMap.recoveryMlScore);
 setOpenness(tenantPIRSMap.openness);
 setPercentRemote(tenantPIRSMap.percentRemote);
 setOverallPirsMlScore(tenantPIRSMap.overallPirsMlScore);
 setSupplyChainConstraint(tenantPIRSMap.supplyChainConstraint);
 setOperationalMitigationMlScore(tenantPIRSMap.operationalMitigationMlScore);


 };
 // [bailoutMlScore,changeInJobs,consumerConfidenceScore,indirectImpactMlScore,mlDirectScore,recoveryMlScore,openness,supplyChainConstraint,operationalMitigationMlScore,overallPirsMlScore,percentRemote]
 const classes = useStyles();
 if(dataStatus){
 return (
 <div>

 <GridContainer>


 <GridItem xs={12} sm={12} md={12}>
 <Card>
 <CardHeader style={{ background: megaColor,color:"white", fontSize:"17px" }} icon>
 <CardIcon color="info">
 <PermIdentity />
 </CardIcon>
 <h4><b>
 TENANT TRACKER REPORT</b>
 </h4> 
 </CardHeader>
 <CardBody>
<br/>
<br/>

 <GridContainer >


<GridItem xs={12} sm={5} md={5}>
 <h1 style={{ color: megaColor }}> <b>CARLYLE CENTER</b></h1>
 <h3 style={{ color: megaColor }}> <b>168 Bradford DR, Port Matilda PA, 16870</b></h3>
 <h3 style={{ color: megaColor }}> <b>California, San Jose, 94088</b></h3>
 </GridItem>



 <GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight:"60px",textAlign:"right"}} >
 {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6> */}
 
 
 <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
 <Button color="transparent" size="sm" className={classes.firstButton}>
 <b><h7 style={{color:'red'}}>High Risk</h7></b>
 </Button>

 <Button color="transparent" size="sm" className={classes.middleButton}>
 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
 </Button>
 <Button color="transparent" size="sm" className={classes.middleButton}>
 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
 </Button>
 <Button color="transparent" size="sm" className={classes.lastButton}>
 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
 </Button>
 <Button color="transparent" size="sm" className={classes.lastButton}>
 <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
 </Button>
 </div>
 <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
 <Button color="danger" size="sm" className={classes.firstButton}>
 <b>0 - 100 </b>
 </Button>
 <Button color="warning" size="sm" className={classes.middleButton}>
 <b>101 - 200 </b> 
 </Button>
 <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
 <b>201 - 300 </b>
 </Button>
 <Button style = {{color:'white',backgroundColor:'#32cd32'}} size="sm" className={classes.lastButton}>
 <b>301 - 400 </b>
 </Button>
 <Button color="success" size="sm" className={classes.lastButton}>
 <b>401 - 500 </b>
 </Button>
 </div>
 

 </GridItem>
 </GridContainer>
 <GridItem xs={12} sm={12} md={12}>
 {/* <h1 className={`${classes.cardTitle} ${classes.marginTop30}`} >
 <b>Property Overall Score <font style={{color:overallPirsMlScoreCSS,size:'50px',width:'150px'}}>{overallPirsMlScore}</font></b>
 </h1> */}

 
 
 
 </GridItem>

 {/* <GridContainer>
<GridItem xs={12} sm={2} md={3} >
 <Card pricing plain>
 <CardBody pricing plain>
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div >
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The Direct Impact is based on economic, employment and government restrictions that impact an industry">
 <img src={directImpact} alt="Logo" width="80" height="80"/>
 </Tooltip>
 </div> */}
 {/* <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: mlDirectScoreCSS,fontSize:'30px'}}>
 <b>{mlDirectScore}</b>
 </h5>
 <h6 className={`${classes.cardTitle}`}>
 <h1 style={{color: mlDirectScoreCSS}}><b>410</b></h1>
 <br></br>
 <h3><b>OVERALL</b></h3> 
 </h6>
 */} 
 
 {/* </CardBody>
 </Card>
 </GridItem> */}
 
 {/* <GridItem xs={12} sm={2} md={3}>
 <Card pricing plain>
 <CardBody pricing plain> */}
 
 {/* <div>
 
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The oprational mitigation score that the industry has available to mitigate the impact of restrictions">
 <img src={operationalmitigation} alt="Logo" width="80" height="80"/>
 </Tooltip>
 
 </div> */}
 {/* <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: operationalMitigationMlScoreCSS,fontSize:'30px'}}>
 <b>{operationalMitigationMlScore}</b>
 </h5>
 <h6 className={`${classes.cardTitle}`}>
 <h1 style={{color: mlDirectScoreCSS1}}><b>220</b></h1>
 <br></br>
 <h3><b>PIRS</b></h3> 
 </h6> */}
 
 
 
 {/* </CardBody>
 </Card>
 </GridItem> */}
 

 {/* <GridItem xs={12} sm={3} md={3}>
 <Card pricing plain>
 <CardBody pricing plain> */}
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div >
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The Indirect Impact score takes into account other factors that may impact the industry in a positive or negative way."> 
 <img src={indirectImpact} alt="Logo" width="80" height="80"/>
 </Tooltip> 
 </div> */}
 
 {/* <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: indirectImpactMlScoreCSS,fontSize:'30px'}}>
 <b>{indirectImpactMlScore}</b>
 </h5> */}
 
 {/* <h6 className={`${classes.cardTitle}`}>
 <h1 style={{color: mlDirectScoreCSS2}}><b>135</b></h1>
 <br></br>
 <h3><b>DEROGATORY</b></h3> 

 </h6> */}
 
{/* 
 </CardBody>
 </Card>
 </GridItem> */}
 

 {/* <GridItem xs={12} sm={3} md={3}>
 <Card pricing plain>
 <CardBody pricing plain> */}
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div>
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The industries probability of recovery from initial and ongoing pandemic measures.">
 <img src={outlookForRecovery} alt="Logo" width="80" height="80"/>
 </Tooltip>
 </div> */}
 {/* <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: recoveryMlScoreCSS,fontSize:'30px'}}>
 <b>{recoveryMlScore}</b>
 </h5>
 <h6 className={`${classes.cardTitle}`}>
 <h1 style={{color: mlDirectScoreCSS3}}><b>550</b></h1>
 <br></br>
 <h3><b>CREDIT</b></h3> 
 </h6> */}
 
 
 {/* </CardBody>
 </Card> */}

 {/* </GridItem> */}
 {/* </GridContainer> */}
 

<GridContainer>
 <GridItem xs={12} sm={12} lg={12}>
 <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
 <b>Total Tenant: <b style={{color: mlDirectScoreCSS}}>50</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <b>Highest Tenant Score : <b style={{color: mlDirectScoreCSS}}>550</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <b>Lowest Tenant Score : <b style={{color: mlDirectScoreCSS2}}>135</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 </h3>
 
 </GridItem>
</GridContainer>
<br></br>
<br></br>
<br></br>
<GridContainer>
<GridItem xs={12} sm={12} md={2} style={{width:"20px"}}>
 {/* <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}> */}
 
 <Box color="black" bgcolor="#d3d3d3" p={1}>
 <b style={{fontSize:"25px"}}>Low Risk : <b style={{color: mlDirectScoreCSS}}>3</b></b>
 </Box>
</GridItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<GridItem xs={12} sm={12} md={2} style={{width:"20px"}}>

 <Box color="black" bgcolor="#d3d3d3" p={1}>
 <b style={{fontSize:"25px"}}>Medium Low Risk : <b style={{color: mlDirectScoreCSS4}}>10</b></b>
 </Box>
 </GridItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <GridItem xs={12} sm={12} md={2} style={{width:"20px"}}>

 <Box color="black" bgcolor="#d3d3d3" p={1}>
 <b style={{fontSize:"25px"}}>Moderate High Risk : <b style={{color: mlDirectScoreCSS1}}>1</b></b>
 </Box>
 </GridItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <GridItem xs={12} sm={12} md={2} style={{width:"20px"}}>

 <Box color="black" bgcolor="#d3d3d3" p={1}>
 <b style={{fontSize:"25px"}}>Medium High Risk : <b style={{color: mlDirectScoreCSS2}}>0</b></b>
 </Box>
 </GridItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <GridItem xs={12} sm={12} md={2} style={{width:"20px"}}>

 <Box color="black" bgcolor="#d3d3d3" p={1}>
 <b style={{fontSize:"25px"}}>High Risk : <b style={{color: mlDirectScoreCSS2}}>0</b></b>
 </Box>
 </GridItem>
 
 
 {/* <b style={{fontSize:"30px"}}>Low Risk : <b style={{color: mlDirectScoreCSS}}>3</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 <b style={{fontSize:"30px"}}> <b style={{color: mlDirectScoreCSS4}}>10</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 <b style={{fontSize:"30px"}}> <b style={{color: mlDirectScoreCSS1}}>1</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <b style={{fontSize:"30px"}}> <b style={{color: mlDirectScoreCSS2}}>0</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <b style={{fontSize:"30px"}}>High Risk : <b style={{color: mlDirectScoreCSS3}}>0</b></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 */}
 {/* </h3> */}
 

</GridContainer>

 <br></br>
 <br></br>
 <br></br>
 <br></br>



{/* <GridItem xs={12} sm={5} md={5}>
<h3 style={{ color: megaColor,paddingLeft:'20px',fontSize:'30px' }}> <b>CARLY CENTER</b></h3>
</GridItem> */}



{/* <GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight:"60px",paddingTop:'10px',textAlign:"right"}} > */}
{/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6> */}


{/* <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
<Button color="transparent" size="sm" className={classes.firstButton}>
<b><h7 style={{color:'red'}}>High Risk</h7></b>
</Button>

<Button color="transparent" size="sm" className={classes.middleButton}>
<b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
</Button>
<Button color="transparent" size="sm" className={classes.middleButton}>
<b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
</Button>
<Button color="transparent" size="sm" className={classes.lastButton}>
<b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
</Button>
<Button color="transparent" size="sm" className={classes.lastButton}>
<b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
</Button>
</div>
<div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
<Button color="danger" size="sm" className={classes.firstButton}>
<b>0 - 100 </b>
</Button>
<Button color="warning" size="sm" className={classes.middleButton}>
<b>101 - 200 </b> 
</Button>
<Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
<b>201 - 300 </b>
</Button>
<Button style = {{color:'white',backgroundColor:'#32cd32'}} size="sm" className={classes.lastButton}>
<b>301 - 400 </b>
</Button>
<Button color="success" size="sm" className={classes.lastButton}>
<b>401 - 500 </b>
</Button>
</div>


</GridItem>


 */}



<GridContainer>
<GridItem xs={12} sm={3} md={3} style={{paddingTop:'4px',textAlign: 'right'}}>

<div style={{display: 'inline-block', textAlign: 'right'}}>
<h5> <b><div style={{paddingTop:'5px',color: megaColor,fontSize:'28px'}}>TENANTS</div></b></h5><br/>
<h5> <b><div style={{paddingTop:'5px',color: megaColor,fontSize:'25px'}}>Bank of America</div></b></h5><br/>
<h5> <b><div style={{paddingTop:'5px',color: megaColor,fontSize:'25px'}}>Starbucks Corporation</div></b></h5><br/>
<h5> <b><div style={{paddingTop:'5px',color: megaColor,fontSize:'25px'}}>Brundidge & Stanger</div></b></h5>

{/* <SideMenuBaseProperty style ={{height:propertyListAssetManager.listHeight+'px'}} items={ propertyListAssetManager.propertyList } height={propertyListAssetManager.lineHeight}/> */}
</div>
</GridItem>



<GridItem xs={12} sm={9} md={9} style={{paddingTop:'8px'}}>

{/* <h5> <b><div style={{paddingTop:'5px',color: megaColor}}></div></b></h5> */}
<h5> <b style={{paddingTop:'8px',color: megaColor,fontSize:'25px'}}>&nbsp;&nbsp;&nbsp;PIRS SCORE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DEROGATORY SCORE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CREDIT SCORE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TRACKER SCORE</b></h5><br/>
<h5> <b style={{paddingTop:'5px',color: '#4caf50',fontSize:'25px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;410 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;463 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;404&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;479</b></h5>
<h5> <b style={{paddingTop:'5px',color: megaColor,fontSize:'25px'}}>&nbsp;</b></h5>
<h5> <b style={{paddingTop:'5px',color: '#ffdf00',fontSize:'25px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;209 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;294 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;234 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;220</b></h5>
<h5> <b style={{paddingTop:'5px',color: megaColor,fontSize:'25px'}}>&nbsp;</b></h5>
<h5> <b style={{paddingTop:'5px',color: '#ff9800',fontSize:'25px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;110 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;134 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;150&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;179</b></h5>

{/* <Chart options={chartOps.options} series={chartOps.series} type="heatmap" height={propertyListAssetManager.chartHeight} /> */}

</GridItem>
</GridContainer>
<br/>
<br/>
<br/>
 {/* <GridItem xs={12} sm={2} lg={2}>
 <Card pricing plain>
 <CardBody pricing plain>
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div>
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The openness score is based on government restrictions by state that impact an industry">
 <img src={degreeOfOpenness} alt="Logo" width="80" height="80"/>
 </Tooltip>
 </div>
 <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: opennessCSS,fontSize:'30px'}}> 
 <b>{openness} </b> 
 </h5>
 <h6 className={`${classes.cardTitle}`}>
 <b>Degree of Openness</b>
 </h6> */}
 
 
 


 {/* <GridItem xs={12} sm={2} lg={2}>
 <Card pricing plain>
 <CardBody pricing plain>
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div >
 <Tooltip classes={{ tooltip: classes.customWidth }} title="The Core Business Remote score looks at the ability of the industry workforce to work remote ">
 <img src={businessRemote} alt="Logo" width="80" height="80"/>
 </Tooltip>
 </div>
 <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: percentRemoteCSS,fontSize:'30px'}}>
 <b>{percentRemote}</b>
 </h5>
 <h6 className={`${classes.cardTitle}`}>
 <b> % CORE BUSINESS REMOTE</b>


 </h6> */}
 
 
 


{/* // <GridItem xs={12} sm={3} lg={3}>
// <Card pricing plain>
// <CardBody pricing plain>
// {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div >
// <Tooltip classes={{ tooltip: classes.customWidth }} title="The Change in Employment score is based on the monthly employment change for the industry ">
// <img src={changeInEmployment} alt="Logo" width="80" height="80"/>
// </Tooltip>
// </div>
// <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: changeInJobsCSS,fontSize:'30px'}}>
// <b>{changeInJobs}</b>
// </h5>
// <h6 className={`${classes.cardTitle}`}>
// <b> CHANGE IN EMPLOYEMENT</b>


// </h6> */}
 
 

 

 



 
 
{/* // </CardBody>
// </Card>
// </GridItem>
 

// <GridItem xs={12} sm={2} lg={2} >
// <Card pricing plain>
// <CardBody pricing plain> */}
 {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
 {/* <div>
// <Tooltip classes={{ tooltip: classes.customWidth }} title="The Consumer Confidence Index measures consumer attitudes and buying intentions. The Industrial Production Index (IPI) measures real output in the manufacturing sector. The Purchasing Managers' Index (PMI) is an index of the prevailing direction of economic trends in the manufacturing and service sectors that summarizes whether market conditions, as viewed by purchasing managers, are expanding, staying the same, or contracting.">
// <img src={consumerConfidence} alt="Logo" width="80" height="80"/>
// </Tooltip>
// </div>
// <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: consumerConfidenceScoreCSS,fontSize:'30px'}}>
// <b>{consumerConfidenceScore}</b>
// </h5>
// <h6 className={`${classes.cardTitle}`}>
// <b> CCI, IPI, & PMI 
// </b>

// </h6> */}
 
 

 
 </CardBody>
 </Card>
 </GridItem>
 </GridContainer>
 <br/>
 <br/>
 <br/>
 </div>
 );
}else{
 return(
<div align="center">
<Loader
 type="ThreeDots"
 color="#00BFFF"
 height={500}
 width={100}
 timeout={3000} //3 secs
 /></div>
 )
}
 }
 GenerateScore.propTypes = {
 loading: PropTypes.bool.isRequired,
 //tmFundSaveRequest: PropTypes.func.isRequired,
 tmTenantsScoreByPIRSRequest: PropTypes.func.isRequired,
 logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 user: state.auth.loggedUser,
 loading: state.tenantmonitoring.loading,
 tenantPIRSMap: state.tenantmonitoring.tenantPIRSMap,
 
 
 
}); 



const mapDispatchToProps = dispatch => bindActionCreators(
 {

 ...TMActions,
 },
 dispatch,
);
export default connect(
 mapStateToProps,
 mapDispatchToProps
)(GenerateScore);