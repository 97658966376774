import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/Card/CardContent";
// import CardHeader from "@material-ui/core/Card/CardHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "components/Badge/Badge.js";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-kit-pro-react/components/customTabsStyle.js";
import Link from '@material-ui/core/Link';
import PropertyDetails from "components/Charts/PropertyDetails";
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function CustomTabForGridView(props) {
    const history = useHistory();
    const location = useLocation();
  const [value, setValue] = React.useState(0);
  const[showBackNavigation,setShowBackNavigation]=React.useState(false);
  const [showTenantNotification, setShowTenantNotification] = React.useState(true);
  const[tenantId,setTenantId]=React.useState(location.state.tenantId);
  const[tenantName,setTenantName]=React.useState(location.state.tenantName);
  const [propertyId, setPropertyId] = React.useState(location.state.propertyId);

  const [propertyName, setPropertyName] = React.useState(location.state.propertyName);
  const[imageUrl,setImageUrl]=React.useState(location.state.imageUrl);
const[propertyAddress,setPropertyAddress]=React.useState(location.state.propertyAddress);
const[fundId,setFundId]=React.useState(location.state.fundId);
const[fundName,setFundName]=React.useState(location.state.fundName);

const[userId,setUserId]=React.useState(location.state.userId);

  console.log("tenantName"+tenantName);
  console.log("propertyId"+propertyId);
  console.log("propertyName"+propertyName);
  console.log("imageUrl"+imageUrl);
  console.log("propertyAddress"+propertyAddress);
  console.log("fundId"+fundId);
  console.log("fundName"+fundName);
  console.log("userId"+userId);
  

  const handleChange = (event, value) => {
    setValue(value);
  };

  function handleClickPropertyDetails(event) {
  
    setShowBackNavigation(true);
    setShowTenantNotification(false);
    // history.push({ 
    //     pathname: '/property-details',
    //     state: {tenantName:tenantName,tenantId:tenantId,propertyId:propertyId,propertyName:propertyName,fundId:fundId,fundName:fundName,imageUrl:imageUrl,userId:userId,propertyAddress:propertyAddress}

    //    });

    
   }

  const { headerColor, title, tabs, rtlActive, plainTabs } = props;
  const classes = useStyles();
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });
  const tabsContainer = classNames({
    [classes.tabsContainer]: true,
    [classes.tabsContainerRTL]: rtlActive
  });
  let completedTasksBadge = <Badge secondary={true} 
                                    badgeContent="123" /> 
  return (
      <div>
    {!showBackNavigation&&showTenantNotification&& <Card plain={plainTabs}>
      <CardHeader style={{ background: megaColor,color:"white" }} plain={plainTabs}>
        {title !== undefined ? (
          <div className={cardTitle}>{"title"}</div>
        ) : null}
        <Tabs
          classes={{
            root: classes.customTabsRoot,
            flexContainer: tabsContainer,
            indicator: classes.displayNone
          }}
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon !== undefined) {
              icon = {
                icon: <prop.tabIcon className={classes.tabIcon} />
              };
            } else {
              icon = {};
            }
            return (
             
              <Tab
                key={key}
                classes={{
                  root: classes.customTabRoot,
                  selected: classes.customTabSelected,
                  wrapper: classes.customTabWrapper
                }}
                style={{
              
                  fontWeight: 'bold',
                  fontSize: '21px'
                }}
                // icon={<prop.tabIcon className={tabIcon} />}
                {...icon}
                label={prop.tabName}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      {/* <CardHeader
          classes={{
            root: cardHeader,
            title: cardTitle,
            content: classes.cardHeaderContent,
            action: classes.cardHeaderAction
          }}
          title={title}
          action={

          }
        /> */}
      <CardBody>
    <Link className={classes.gradient} style={{cursor:"pointer",textAlign:"right"}} onClick={handleClickPropertyDetails} > &nbsp;Back</Link> 

        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>}
     {showBackNavigation&&
  

        // <PropertyDetails fundName={fundName} fundId={fundId} propertyId={propertyId} propertyName={propertyName} backNavigation={true}/>
        <PropertyDetails  fundName={fundName} fundId={fundId} propertyId={propertyId} tenantId={tenantId} tenantName={tenantName} propertyName={propertyName} imageUrl={imageUrl} propertyAddress={propertyAddress} backNavigation={true}/>
      
      }
      </div>
  );
}

CustomTabForGridView.defaultProps = {
  headerColor: "teal"
};

CustomTabForGridView.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "gray",
    "mega",
    "teal"
   
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

