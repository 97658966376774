import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CompanyTable from "views/Company/CompanyTable.js";
import { useHistory } from 'react-router-dom';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';


import { CardContent, Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import AddCircleIcon from '@material-ui/icons/AddCircle';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
 whiteColor,
 hexToRgb,
 megaColor,
 lyticsColor,
 infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import { NavigateBeforeSharp } from "@material-ui/icons";
// import ViewCompanyDetails from "./ViewCompanyDetails";
import ConfigureReport from "views/Company/ConfigureReport";
import ViewCompanyDetails from "views/Company/ViewCompanyDetails";
import MergedTable from "./MergedTable";



const styles = {
 cardIconTitle: {
 ...cardTitle,
 marginTop: "15px",
 marginBottom: "0px",
 color:'#fff'
 },
 customWidth: {
 maxWidth: 200,
 fontSize: "15px",
 backgroundColor: megaColor,
 },
 root: {
  width:"100%",
   height:"100%",
    // maxWidth: "400px",
 
  // paddingBottom:"150px",
    //background: "hsla(0, 0, 0, 0.7)"
     boxShadow: "10px 10px 15px 10px rgba(0,0,0,0.3)"
   
  },
  text:{
    display:"inline",
    float:"left",
    },

    arrow:{
      // display:"inline",
    float:"right",
 
    // float:"center"
    }

};



const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
// params.set("page", pageNumber.toString());
// params.set("size", size.toString());
// params.set("sortProperty", sortBy);
// params.set("sortDirection", sortDirection);
// params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

 const MergedList = ({loading,
 companyList, tmCompanyListRequest, tmCompanyListByOrgRequest,tmCompanyDeleteRequest,companyDelete,tmAllTenantsTrailingScoreRequest,tenantsTrailingScoreList,selectedArrayItems
 }) => {
 const history = useHistory();

 const location = useLocation();
 
// const [tenantName,setTenantName] = React.useState(location.state.tenantName);
const[companySearchListRequestParams,setCompanySearchListRequestParams] =React.useState(localStorage.getItem('@DeliveryApp:selectedArrayItemsCompany'));
const[propertySearchListRequestParams,setPropertySearchListRequestParams] =React.useState(localStorage.getItem('@DeliveryApp:selectedArrayItemsProperty'));

const[searchedParametersFromCompany,setSearchedParametersFromCompany] = React.useState( JSON.parse(localStorage.getItem('@DeliveryApp:searchedParamsFromCompany')));
const[searchedParametersFromProperty,setSearchedParametersFromProperty]= React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:searchedParamsFromProperty')));



const [combinedList,setCombinedList] = React.useState([]);

const[resultSet,setResultSet] = React.useState([]);
// const[searchedParamsfromCompany,setSearchedParamsfromCompany]= React.useState(localStorage.getItem('@DeliveryApp:searchedParamsFromCompany'));
// const[searchedParamsfromProperty,setSearchedParamsfromProperty] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:searchedParamsFromProperty')));


 const [messageobj, setMessageobj] = React.useState("");
 const [companyId, setCompanyId ] = React.useState("");
 // const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
 const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
 const [alert1, setAlert1] = React.useState(false);
 const [openConfigureApiAccess, setOpenConfigureApiAccess] = React.useState(false);
 const [organisationName, setOrganisationName] = React.useState("");
 const [organisationId, setOrganisationId] = React.useState("");


 const[name,setName] = React.useState("");
 const[address1,setAddress] = React.useState("");
 const[city,setCity] = React.useState("");
 const[state,setState] = React.useState("");
 const[zip,setZip] = React.useState("");

//  const[name,setName] = React.useState(location.state.searchedParamsfromCompany.name);

 const[address2,setAddress2] = React.useState("");

 const[city1,setCity1] = React.useState("");
 const[state1,setState1] = React.useState("");
 const[postalCode,setPostalCode] = React.useState("");

 const[showGenerateScore, setShowGenerateScore] = React.useState(false);

 const[showClearSelection, setShowClearSelection] = React.useState(false);

const [dataStatus,setDataStatus] = React.useState(false);
const [showNoDataFound, setShowNoDataFound] = React.useState(true);
const [showList, setShowList] = React.useState(true);

const [showParamsCompany, setShowParamsCompany] = React.useState(true);
const [showParamsProperty, setShowParamsProperty] = React.useState(true);
// const [alert1,setAlert1] = React.useState(false);

const hideAlert1 = () => {
  setAlert1(false);
  setShowList(false); 
  
  history.replace(location.state.resultSet,{});
  //setDataStatus(false);
}
// const [showDataFound, setShowDataFound] = React.useState(true);
//  const list=(props)=>{
//    console.log("list"+props.location.aboutProps);
//    setDataList(props.location.aboutProps);
//  }
//  const [companySearchListRequestParams, setcompanySearchListRequestParams] = React.useState(location.state.companySearchListRequestParams);

 
   const handleClickOpenShowGenerateScore = () => {
    setShowGenerateScore(true);
   };
   const handleCloseShowGenerateScore = () => {
    setShowGenerateScore(false);
   };
 
 
 const [showViewCompanyDetails, setShowViewCompanyDetails] = React.useState(false);

 const mergedlist = [
 { address: "410 TERRY AVENUE NORTH , SEATTLE, WA, 98109",
 city: "SEATTLE",
 creditSafeId: "US001-X-US23487038",
 headQuarters: "HeadOffice",
 id: null,
 postCode: "98109",
 state: "WA",
 tenantName: "AMAZON COM INCORPORATED",
 type: "CREDITSAFE" },
 { address: "PO BOX 8046, SEATTLE, WA, 98108",
 city: "SEATTLE",
 creditSafeId: "US001-X-US40280091",
 headQuarters: "HeadOffice",
 id: null,
 postCode: "98108",
 state: "WA",
 tenantName: "AMAZON.COM.CA, INC",
 type: "CREDITSAFE"},
 { address: "410 TERRY AVENUE NORTH , SEATTLE, WA, 98109",
 city: "SEATTLE",
 creditSafeId: "US001-X-US23487038",
 headQuarters: "HeadOffice",
 id: null,
 postCode: "98109",
 state: "WA",
 tenantName: "AMAZON COM INCORPORATED",
 type: "CREDITSAFE"},
 { address: "207 BOREN AVE N, SEATTLE, WA, 98109",
 city: "SEATTLE",
 creditSafeId: "US001-X-US62475576",
 headQuarters: "Branch",
 id: null,
 postCode: "98109",
 state: "WA",
 tenantName: "AMAZON",
 type: "CREDITSAFE"},
 ];
 


//  let list = companySearchListRequestParams.concat(propertySearchListRequestParams);
 
 // const [alert2,setAlert2] = React.useState(false);
 
 // const hideAlert2 = () => {
 // setAlert2(false);
 // }
 React.useEffect(() => {
 
  // if(resultSet.length>0){

  //   setShowNoDataFound(false);
  //   setDataStatus(true);

  // }
  // else{
  //   setShowNoDataFound(true);
  //   setDataStatus(false);
  // }
// alert("searchedParametersFromCompany"+searchedParametersFromCompany.name);

if(searchedParametersFromCompany!=null){
  setName(searchedParametersFromCompany.name);
  setAddress(searchedParametersFromCompany.address1);
  setZip(searchedParametersFromCompany.zip);
  setCity(searchedParametersFromCompany.city);
  setState(searchedParametersFromCompany.state1);
  

}
if(searchedParametersFromProperty!=null){
 
  setAddress2(searchedParametersFromProperty.address);
  setPostalCode(searchedParametersFromProperty.postCode);
  setCity1(searchedParametersFromProperty.city);
  setState1(searchedParametersFromProperty.state);
  

}


  if(location.state!=null&&showList){
    // setShowCompanyName(false);
 setDataStatus(true);
 
    setShowList(true);
    setResultSet(location.state.resultSet);
    
   }
 else{

  setDataStatus(false);
   setShowList(false); 
 
   setResultSet("");
   // setAlert(true);
 
 }

 
 }, [resultSet,dataStatus]);

 const deleteCompany = async (companyId) => {
 await tmCompanyDeleteRequest(companyId);
 history.push({ 
 
 pathname: '/admin/companylist',
 // state:{ message:"Fund Saved Successfully" }
 });
 

 };

 const handleCancel = e => {

  localStorage.removeItem('@DeliveryApp:selectedArrayItemsCompany');
  localStorage.removeItem('@DeliveryApp:selectedArrayItemsProperty');
  localStorage.removeItem('@DeliveryApp:searchedParamsFromCompany');
  localStorage.removeItem('@DeliveryApp:searchedParamsFromProperty');
  setAlert1(true);
  // setShowList(false);
  // setShowNoDataFound(true);
  // setAlert1(true);
  
  // setTimeout(() => {
  //   e.target.value=null;
  //   }, 1000);
  
    };


 const getCompanyList = async () => {
 await tmCompanyListByOrgRequest();
 };

 const handleClickOpenConfigureApiAccess= (orgId,orgName) => {
 setOrganisationId(orgId)
 setOrganisationName(orgName);
 setOpenConfigureApiAccess(true);
 
 };

 const handleCloseConfigureApiAccess=()=>{
 setOpenConfigureApiAccess(false);
 }

 const handleClickOpenClearSelection= (orgId,orgName) => {
  
  setShowClearSelection(true);
  
  };

 const handleCloseClearSelection=()=>{
  setShowClearSelection(false);
  }
 

 
 
 
 const submit = (companyId) => {
 confirmAlert({
 customUI: ({ onClose }) => {
 return (
 
 <div className='custom-ui' 
 style={{
 backgroundColor: "#81A594 ",
 fontWeight:"bold",
 color:'#fff',
 
 textAlign: "center",
 // alignItems: "center",
 paddingTop: "20px",
 height: "150px",
 width: "300px",
 
 }}>
 
 <h1 >Are you sure?</h1>
 <p fontWeight="bold">You want to Delete this Company?</p>
 
 <button backgroundColor= "#000"
 onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
 <button 
 
 onClick={() => {
 deleteCompany(companyId)
 onClose();
 
 
 }}
 >
 &nbsp; &nbsp; Yes &nbsp;&nbsp;
 </button>
 </div>
 
 

 );
 }
 });
 }
// // 
const getColumnWidth = (data, accessor, headerText) => {
 if (typeof accessor === 'string' || accessor instanceof String) {
 accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
 }
 const minWidth = 200;
 const magicSpacing = 10;
 const cellLength = Math.max(
 ...data.map(row => (`${accessor(row)}` || '').length),
 headerText.length,
 );
 return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
 const maxWidth = 400
 const magicSpacing = 10
 const cellLength = Math.max(
 ...rows.map(row => (`${row[accessor]}` || '').length),
 headerText.length,
 )
 return Math.min(maxWidth, cellLength * magicSpacing)
}
const [open, setOpen] = React.useState(false);
 
const handleClickOpen = () => {
 setOpen(true);
};

const handleClose = () => {
 setOpen(false);
};


const handleCloseViewCompanyDetails = () => {
 setShowViewCompanyDetails(false);
};

 const classes = useStyles();
 if(dataStatus&&location.state.resultSet){
 return (
 
 
 <GridContainer>
{/* 
 <GridItem xs={12}>
 <OrgSelected orgName={selectedOrganization}/>
 </GridItem> */}
 
 <GridItem xs={12}>
 
 
 {/* <Card>
 <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
 <CardIcon color="info">
 <Assignment />
 </CardIcon>
 <h4><b>Searched Companies</b></h4>
 </CardHeader>
 <CardBody>
 {messageobj && <SnackbarContent
 message={messageobj}
 close
 icon={AddAlert}
 color="info"
 />
 } */}




{showList&&
 
<GridItem xs={12} sm={12} md={12}>
<Card className={classes.root} variant="outlined">
  <CardContent>
<div className={classes.text}   style={{paddingLeft:"40px",paddingTop:"10px"}} ><h4 style={{fontSize:"20px"}}><b>Company Search Criteria:</b></h4>

<h4  ><b style={{color:megaColor}}>Name:</b> {name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b style={{color:megaColor}}>Address: </b>{address1}
</h4>
<h4 className={classes.text} ><b  style={{color:megaColor}}>City:</b> {city}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b  style={{color:megaColor}}>State:</b>  {state} &nbsp;&nbsp;&nbsp;&nbsp; 

<b  style={{color:megaColor}}>&nbsp;&nbsp;&nbsp;PostalCode:</b> {zip}</h4></div>
  
<div className={classes.arrow} style={{paddingRight:"40px",paddingTop:"10px"}} ><h4 style={{fontSize:"20px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Search Criteria:</b></h4>
<h4 ><b style={{color:megaColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Address: </b>{address2}
</h4>
<h4 className={classes.arrow}><b  style={{color:megaColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City:</b> {city1}  <b  style={{color:megaColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State:</b>  {state1}  

<b  style={{color:megaColor}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PostalCode:</b> {postalCode}</h4></div>
  

</CardContent>
</Card>
</GridItem>}


{showList&&<GridItem  xs={12} sm={12} md={3}>
      <div className={classes.buttonGroup} >
         {/* <Link to={{pathname:'/admin/rentroll',state:{resultSet:companySearchListRequestParams,searchedParamsfromCompany:searchedParams}}} > */}
        <Button   type="reset" color="info" onClick={handleCancel} round style={{fontSize:18, fontWeight:"bold"}} className={classes.firstButton}>
      Clear Selection
        </Button>
           {/* </Link> */}
        </div>
        </GridItem>
}

{showList&&<MergedTable
 
 columns={[
 
 // {
 // Header: "Company Name",
 // accessor: "companyname",
 
 // Cell: ({ cell,row }) => (<Link to={{ state :{organisationId : row.original.organisationId, name:row.original.name} } } 
 // onClick={() => getViewCompanyDetails(row.original.organisationId,row.original.name,
 // row.original.email,row.original.address2,row.original.country,row.original.mobileNumber, row.original.city,row.original.state,row.original.postalCode,row.original.address1,)}
 // >{row.original.name}</Link>),





 
 // width: getColumnWidthNew(companyList, 'accessor', 'Tenant'),
 // },

 {
 Header: "Company Name",
 accessor: "tenantName",
 width: 50,
 },
 {
    Header: "Address",
    accessor: "address",
    width: 50,
  },

  // {
  //   Header: "Address2",
  //   accessor: "address2",
  //   width: 50,
  // },
  {
    Header: "State",
    accessor: "state",
    width: 50,
  },

  {
    Header: "City",
    accessor: "city",
    width: 30,
  },
  
  {
    Header: "Post Code",
    accessor: "postCode",
    width: 50,
  },
  {
    Header: "Location Type",
    accessor: "headQuarters",
    width: 50,
  },

 
// {
// Header: "Address",
// accessor: "address",
// width: 50,
// },
 // {
 // Header: "State",
 // accessor: "state",
 // width: 50,
 // },
 // {
 // Header: "PostalCode",
 // accessor: "postalCode",
 // width: 50,
 // },

 
 // {
 // Header: "Select",
 // accessor: "select",
 // isVisible: false,
 // width: 50,
 // disableFilters: true,
 // disableSortBy: true,
 // },
 
 ]}

 data={resultSet}
//  data={resultSet.map((prop, key) => {
//  return {
//  // id: prop.id,
//  tenantName:prop.tenantName,
// // propertyname: prop.propertyname,
 

// headQuarters:prop.headQuarters,
//  address:prop.address,
//  // email: prop.email,
//  // address2: prop.address2,
//  // country: prop.country,
//  // mobileNumber: prop.mobileNumber,
 
//  city: prop.city,
//  state: prop.state,
//  postCode:prop.postCode,
//  // address1: prop.address1,
 
 

 
 

 
//  };
//  })}
 // data={companyList}

 getTdProps={(state, rowInfo, column, instance) => {
 // rowInfo contains a lot of information about the row (including index on both the
 // page and it's index within the data set), the rowInfo.original objet contains 
 // values in the cells of the row clicked.
 return {
 // You can define an onClick function here that will apply to all rows
 onClick: (e, handleOriginal) => {
 
 const rowData = rowInfo.original
 // You can interact with the row data here, to use it however you want
 this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
 }
 }}}
 
 />}
 <br></br>
{showList&&<GridContainer className={classes.gridContainer}>
 <GridItem style={{textAlign: "left" }} xs={12} sm={12}>
 <Button style={{ background: megaColor,color:"white",fontSize:"14px"}} size="sm" round  onClick={handleClickOpenShowGenerateScore} ><b>&nbsp;Generate Score</b></Button>

 {/* onClick={() => history.push('/admin/generate-score')} */}
</GridItem>


<GridItem>


               
               <Dialog fullWidth={true}  
maxWidth={'sm'} open={showGenerateScore} onClose={handleCloseShowGenerateScore}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      <GridItem align="right">
    
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseShowGenerateScore}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<DialogContent align="center">
  <h3 style={{fontWeight:"bold"}}>Yet to be implemented....</h3><br></br><br></br>
</DialogContent>
          </Dialog>
               </GridItem>
              


</GridContainer>}


 {/* </CardBody>
 </Card> */}
 </GridItem>

 <div>
<SweetAlert
info
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
All selected companies from both property and company search will be removed.
</SweetAlert>
</div>

 
 </GridContainer>
 );
}else{
  return(
<div align="center" >
  {loading ? <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    />: <b  style={{fontSize:"22px",marginTop:"150px"}}><br></br><br></br>No Companies Selected...<br></br><br></br><br></br></b>}


   
    </div>
    
    )
  

}
}





MergedList .propTypes = {
 companyList: PropTypes.arrayOf(
 PropTypes.shape({
 tenantId: PropTypes.string,
 }),
 ).isRequired,

 loading: PropTypes.bool.isRequired,
 tmCompanyListRequest: PropTypes.func.isRequired,
 tmCompanyListByOrgRequest: PropTypes.func.isRequired,
 tmCompanyDeleteRequest: PropTypes.func.isRequired,

 logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 user: state.auth.loggedUser,
 companyList: state.tenantmonitoring.companyList,
 companyDelete: state.tenantmonitoring.companyDelete,
 loading: state.tenantmonitoring.loading,
 tenantId: state.tenantId
}); 

const mapDispatchToProps = dispatch => bindActionCreators(
 {

 ...TMActions,
 },
 dispatch,
);

export default connect(
 mapStateToProps,
 mapDispatchToProps,
)(MergedList );
