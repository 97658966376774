import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Link from '@material-ui/core/Link';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";
import TMHeatMapAssetManager from "components/Charts/TMHeatMapAssetManager.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapFundRisk from "components/Charts/HeapMapFundRisk.js";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import TMAMHeatMapPropertyTenant from "components/Charts/TMAMHeatMapPropertyTenant.js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import Badge from "components/Badge/Badge.js";


import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);



const TMAMHeapMapRisk1 = ({user,loading,tmTenantsRiskCountByPropertyRequest,propertyId,organizationId,fundId,fundName,propertyName,count,parentCallBack,tmFundRiskCountRequest,fundRiskCount,tenantsRiskCountByProperty,backNavigation,
  selectedFundName,selectedFundManagerName}) => {
  
  
  const history = useHistory();

  const [input, setInput] = React.useState('');
  const [showHeatMap, setShowHeatMap] = React.useState(true);
  const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
  const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);
  const [fundActive, setFundActive] = React.useState(true);
  const [tenantActive, setTenantActive] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const[showBackNavigation,setShowBackNavigation]=React.useState(false);
  const [month, setMonth] = React.useState("");
  const[heatMapPropertyTenantVisible,setHeatMapPropertyTenantVisible]=React.useState(false);
  const[heatMapRiskVisible,setHeatMapRiskVisible]=React.useState(true);
  const [directNavigation, setDirectNavigation] = React.useState(backNavigation);
  const [dataStatus, setDataStatus] = React.useState(false);
  const [year, setYear] = React.useState(2020);

  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState("");
  const [selectedRangeTo, setSelectedRangeTo] = React.useState("");
  //const [riskList, setRiskList] = React.useState(count);
  const [category, setCategory] = React.useState("");
 


  const sendDataBack=(range)=>{
  //parentCallBack(range);
  setPageStatus(false);
  setHeatMapRiskVisible(false)
  // setHeatMapPropertyTenantVisible(false);
  setSelectedRangeFrom(range.rangeFrom);
  setSelectedRangeTo(range.rangeTo);

  setCategory(range.category);
  setMonth(range.month.substr(0,3));
  setYear("20"+range.month.substr(4,5)); 
  console.log("year20"+range.month.substr(4,5));
  setHeatMapPropertyTenantVisible(true);

    }
   
  const handleChange = (value) => {
    setShowHeatMap(false);
    setShowHeatMapFundRisk(true);
    setFundActive(false);
    setTenantActive(false);
  }


  const [value, setValue] = React.useState(null);
  const [lowRiskValues,setLowRiskValues] = React.useState([]);
  const [mediumLowRiskValues,setMediumLowRiskValues] = React.useState([]);
  const [mediumHighRiskValues,setMediumHighRiskValues] = React.useState([]);
  const [moderateHighRiskValues,setModerateHighRiskValues] = React.useState([]);
  const [highRiskValues,setHighRiskValues] = React.useState([]);

  const [userId, setUserId] = React.useState(user.id);
  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
 const[showFundDetails,setShowFundDetails]=React.useState(false);
  const location = useLocation();

  React.useEffect(() => {

    if(user.organizationId=="654b476a423c6401f20f16db"){
      setShowFundDetails(false);
    }else{
      setShowFundDetails(true);
    }
    if(backNavigation){
       
      setDirectNavigation(false);
    }

    // if(!pageStatus){
    //   getTmPropertyTenantRiskCount(propertyId,userId);
  
   
    //    }
   
    //    setTimeout(() => {
    //    setPageStatus(true);
    //    }, 2000);

 
  //  setLowRiskValues(riskList.lowRiskValues);
  if(!pageStatus){
   getTmPropertyTenantRiskCount(propertyId,userId);
   setTimeout(() => {
    setPageStatus(true);
    }, 3000);
  }else{
    if(tenantsRiskCountByProperty){
     
       setLowRiskValues(tenantsRiskCountByProperty.LOW_RISK);
       setMediumLowRiskValues(tenantsRiskCountByProperty.MEDIUM_LOW_RISK);
       setMediumHighRiskValues(tenantsRiskCountByProperty.MEDIUM_HIGH_RISK);
       setModerateHighRiskValues(tenantsRiskCountByProperty.MODERATE_HIGH_RISK);
       setHighRiskValues(tenantsRiskCountByProperty.HIGH_RISK);
       setPageStatus(true);
     //  setHeatMapRiskVisible(true);
    }
  }
 
 
  }, [pageStatus,tenantsRiskCountByProperty]);

  const getTmPropertyTenantRiskCount = async (propertyId,userId) => {
    await  tmTenantsRiskCountByPropertyRequest(propertyId,userId);
    setPageStatus(true);
  
    };
    function handleClickHome(event) {
     
     
       setShowBackNavigation(true);

     }
     function handleClickFundDashboard(event) {
      // event.preventDefault();
     
      // history.push({ 
      //  pathname: '/dashboard'
      // });
      setShowBackNavigation(true);
      history.push({ 
        pathname: '/dashboard'
       });
      
     }
     function handleClickFundDashboard(event) {
      // event.preventDefault();
      setShowBackNavigation(true);
      history.push({ 
       pathname: '/dashboardmanager'
      });
      
     }
     
    function handleClickPropertyDashboard(event) {
      // event.preventDefault();
      history.push({ 
       pathname: '/tenant-risk-count-by-property'
      });
      
     }
 

  const classes = useStyles(); 

  
  if(pageStatus){


  return (
     <div>


{!directNavigation&&heatMapRiskVisible&&
      <GridContainer >


<GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>
   <Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard} className={classes.link}>
<Badge color="info"><b> <ArrowBackIosSharpIcon fontSize="inherit" className={classes.icon} />
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>

</GridItem>


<GridItem xs={12} sm={12} md={12} style={{paddingLeft:"50px"}}>
         <h3 style={{ color: megaColor }}> <b>{propertyName} - Property Tenants Risk Count by Month</b></h3>
       {showFundDetails&&  <h3 style={{ color: megaColor,fontSize:"20px" }}> <b>Fund: {selectedFundName}</b></h3>}
 {showFundDetails&&<h3 style={{ color: megaColor,fontSize:"20px"}}> <b>Fund Manager: {selectedFundManagerName}</b></h3>}

         </GridItem>
  
         




         <GridItem xs={12} sm={2} md={2}>
     
     </GridItem>
     
     <GridItem xs={12} sm={10} md={10} className={classes.vertical} style={{paddingLeft:"370px",textAlign:"left"}} >
     

<div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>

 </GridItem>

         
 <GridItem xs={12} sm={3} md={3} style={{paddingTop:'20px',paddingLeft:'30px'}} >


</GridItem>
<GridItem  xs={12} sm={9} md={9} style={{paddingRight:"30px"}}>
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="transparent" style={{width:'60px',fontSize:'18px',color:'#000000',textAlign:'center'}} className={classes.firstButton} onClick={() => sendDataBack()} >
                 <b>{highrisk.monthName} </b>
               </Button>
              
      
    ))}
             </div>
            

 </GridItem>

<GridItem xs={12} sm={3} md={3} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5> <b>Low Risk</b></h5>

</GridItem>
 <GridItem xs={12} sm={9} md={9} style={{paddingRight:"30px"}}>
            
 
 
 <div className={classes.buttonGroup}>
           
             {pageStatus&&lowRiskValues.map(lowRisk => (
               <Button color="success" style={{width:'60px',fontSize:'18px',color:'#000'}}  className={classes.firstButton} onClick={() => sendDataBack({rangeFrom: 401, rangeTo: 500,category:"LOW_RISK",month:lowRisk.monthName})}  disabled={lowRisk.riskCount==0}>

            
                  <b>{lowRisk.riskCount} </b>
               </Button>
              ))} 
      
      
             </div>
         

</GridItem>

<GridItem xs={12} sm={3} md={3} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> Medium Low Risk</b></h5>




</GridItem>

<GridItem xs={12} sm={9} md={9} style={{paddingRight:"30px"}}>
            
    
 
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
            {mediumLowRiskValues.map(mediumLowRisk => (
              <Button style={{width:'60px',color:'#000',backgroundColor:'#32cd32',fontSize:'18px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom: 301, rangeTo: 400,category:"MEDIUM_LOW_RISK",month:mediumLowRisk.monthName,year:2020})}  disabled={mediumLowRisk.riskCount==0}>
                 <b>{mediumLowRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={12} sm={3} md={3} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b>Moderate High Risk</b></h5>
            
       

</GridItem>

 <GridItem xs={12} sm={9} md={9} style={{paddingRight:"30px"}} >
            
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
           
            { moderateHighRiskValues.map(moderateHighRisk => (
              <Button style={{width:'60px',color:'#000',backgroundColor:'#ffdf00',fontSize:'18px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom: 201, rangeTo: 300,category:"MODERATE_HIGH_RISK",month:moderateHighRisk.monthName})} disabled={moderateHighRisk.riskCount==0}>
                 <b>{moderateHighRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={12} sm={3} md={3} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> Medium High Risk</b></h5>
            
          
         

</GridItem>
  
 <GridItem  xs={12} sm={9} md={9} style={{paddingRight:"30px"}} >
             

             <div className={classes.buttonGroup}>
          
             { mediumHighRiskValues.map(mediumHighRisk => (
            
               <Button color="warning" style={{width:'60px',fontSize:'18px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom: 101, rangeTo: 200,category:"MEDIUM_HIGH_RISK",month:mediumHighRisk.monthName})} disabled={mediumHighRisk.riskCount==0}>
                 <b>{mediumHighRisk.riskCount} </b>
               </Button>             
      
    ))}
             </div>
            

 </GridItem>

 <GridItem xs={12} sm={3} md={3} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> High Risk</b></h5>
            
          
         

</GridItem>

  <GridItem  xs={12} sm={9} md={9} style={{paddingRight:"30px"}} >
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="danger" style={{width:'60px',fontSize:'18px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom: 0, rangeTo: 100,category:"HIGH_RISK",month:highrisk.monthName})} disabled={highrisk.riskCount==0}>
                 <b>{highrisk.riskCount} </b>
               </Button>
              
      
    ))}
             </div>

             <br/>
<br/>
<br/>

 </GridItem>

 




      </GridContainer>
      
  }
 

  {showBackNavigation&&
  

    <TMHeatMapAssetManager fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} backNavigation={true}/>

  }

 {heatMapPropertyTenantVisible && <TMAMHeatMapPropertyTenant fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year} category={category} selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/>}

    
    </div>
  );
}else{
    return(

      <div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}

TMAMHeapMapRisk1.propTypes = {
    tmNotificationList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        contifyId: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmFundRiskCountRequest: PropTypes.func.isRequired, 
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    fundRiskCount: state.tenantmonitoring.fundRiskCount,
    tenantsRiskCountByProperty: state.tenantmonitoring.tenantsRiskCountByProperty,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TMAMHeapMapRisk1));
  
  
