import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Html from 'react-pdf-html';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import ReactPdfTable from "react-pdf-table";
import Moment from 'moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";

import moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

Font.register({
  family: "RobotoRegular",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
});
// Create styles


const PdfMonthlyReport = ({generateMonthlyPdfReport,organisationId
}) =>{


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  boldExample: {
    fontWeight: "bold",
    fontFamily: RobotoRegular,
    paddingTop: "15px",
    fontSize: "11px",
    textAlign: 'justify'
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
 
  textFont: {

    paddingTop: "15px",
    fontSize: "11px",
    textAlign: 'justify',
    fontFamily: "Roboto",
  },
 
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
    
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 150,
    width:"230",
    height:"120",
   
  },
  imageCarter: {
    marginVertical: 5,
    marginHorizontal: 150,
    width:"200",
    height:"90",
   
  },
  image1: {
    width:"530",
    height:"340",
    marginTop:10
    
  },
  
  image2: {
    width:"250",
    height:"150", 
    marginTop:20,
  
    
  },
  image3: {
    width:"20",
    height:"30",
    marginTop:100,
    marginLeft:10,
    marginRight:10
    
  },
  
  inline: {
    display: "flex",
    flexDirection: "row"
  },
  
  inline1: {
    display: "flex",
    flexDirection: "row",
    width:"205",
    height:"52", 
    margin: 60,
    align: "left",
    width:"50%"
    
  },
  inline2: {
    display: "flex",
    flexDirection: "row",
    color: megaColor, 
    fontSize:13, 
    margin: 50,
    justifyContent:"right",
    width:"50%"
  },
 
  table: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:10,
    // height:"auto"
   
  }, 
  tableCautionary: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:5,
    borderColor:"#FFFFFF",
    // height:"auto"
   
  }, 

  table1: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:15,
    // height:"190px",
   
  },

  table2: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:10,
    // height:"190px",
    // marginLeft:10
   
  }, 

  table3: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:30 
  },
  table4: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop: 25
  },
  table5: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop: 30
    // height:"180px"
  },
  table6: { 
    borderCollapse: "collapse",
    width: "100%",
    marginTop:20,
    // height:"auto"
   
  },
  table7: { 
    borderCollapse: "collapse",
    width: "100%",
    // marginLeft:20,
    marginTop:20,
    // height:"auto"
   
  }, 
  table3Orange: { 
    borderCollapse: "collapse",
    border:"1px solid #FFFFFF",
    width: "100%",
    height:"auto",
    // marginTop:25 
  },
  table4Orange: { 
    borderCollapse: "collapse",
    border:"1px solid #FFFFFF",
    width: "100%",
  },
  table5Orange: { 
    borderCollapse: "collapse",
    border:"1px solid #FFFFFF",
    width: "100%",
    // height:"auto"
  },

  tableRow: { 
    margin: 0.5, 
    flexDirection: "row",
    // backgroundColor:"#cdd4ea",
  }, 
  
  tableRow1: { 
    marginTop:10, 
    flexDirection: "row",
    
    // backgroundColor:"#cdd4ea",
  }, 
  

  tableCell: { 
    margin:5,
    fontSize: "10px",
    color:"#FFFFFF"
  },

  tableCell1: { 
    margin:5,
    fontSize: "11px" ,
    color:"#FFFFFF",
    textAlign:"left",
    marginTop:"9px",
    fontWeight:"bold"
  },
  tableCell2: { 
    margin:5,
    fontSize: "11px" ,
    color:"#FFFFFF",
    textAlign:"center",
    marginTop:"9px",
    fontWeight:"bold"
  },
  tableHeader1: { 
    width: "70%", 
   backgroundColor:"#3f3b7a"
  }, 
  
  tableHeader11: { 
    width: "80%", 
   backgroundColor:"#3f3b7a"
  },
  tableHeader111: { 
    width: "60%", 
   backgroundColor:"#3f3b7a"
  }, 
  tableCell1Left: { 
    width: "60%", 
    margin: 0.5
  //  backgroundColor:"#e8ebf5"
  }, 
  tableCell1Right: { 
    width: "40%", 
  //  backgroundColor:"#e8ebf5",
  marginLeft:0.5
  }, 

  tableHeaderFund: { 
    width: "60%", 
    backgroundColor:"#4573c4",
    marginLeft:1
    },

  tableHeader2: { 
  width: "60%", 
  backgroundColor:"#3f3b7a",
  marginLeft:0.5
  },

  tableHeader222: { 
    width: "20%", 
    backgroundColor:"#3f3b7a",
    marginLeft:0.5
    },

    tableHeader444: { 
      width: "20%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },

      tableHeader33Category: { 
        width: "40%", 
        backgroundColor:"#3f3b7a",
        marginLeft:0.5
        },
        tableHeader33CategoryNegativeNews: { 
          width: "60%", 
          backgroundColor:"#3f3b7a",
          marginLeft:0.5
          },
        tableHeader33CategoryPositiveNews: { 
          width: "55%", 
          backgroundColor:"#3f3b7a",
          marginLeft:0.5
          },
        tableHeader33Category1: { 
          width: "40%", 
          backgroundColor:"#3f3b7a",
          marginLeft:0.5
          },
          tableHeader33Category1PostiveNews: { 
            width: "45%", 
            backgroundColor:"#3f3b7a",
            marginLeft:0.5
            },
  tableHeader22: { 
    width: "25%", 
    backgroundColor:"#3f3b7a",
    marginLeft:0.5
    },


  tableHeader3: { 
    width: "15%", 
   backgroundColor:"#3f3b7a",
   marginLeft:0.5
  },
  tableHeader33: { 
    width: "25%", 
    backgroundColor:"#3f3b7a",
    marginLeft:0.5
    },
    tableHeader333: { 
      width: "30%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },
  tableHeader4: { 
    width: "15%", 
   backgroundColor:"#3f3b7a",
   marginLeft:0.5
  },
  tableHeader44: { 
    width: "20%", 
   backgroundColor:"#3f3b7a",
   marginLeft:0.5
  },
  tableHeader5: { 
    width: "10%", 
   backgroundColor:"#3f3b7a",
   marginLeft:0.5
  },

  tableHeader55: { 
    width: "15%", 
   backgroundColor:"#3f3b7a",
   marginLeft:0.5
  },
  tableHeader6: { 
    width: "40%", 
    backgroundColor:"#4573c4",
    marginLeft:1
    },

    tableHeader2Orange: { 
      width: "40%", 
      backgroundColor:"#3f3b7a",
      // marginLeft:1
      },
    
      tableHeader3Orange: { 
        width: "20%", 
       backgroundColor:"#3f3b7a"
      },
      
      tableHeader4Orange: { 
        width: "20%", 
       backgroundColor:"#3f3b7a"
      },
    
      tableHeader5Orange: { 
        width: "20%", 
       backgroundColor:"#3f3b7a"
      },
    
      tableHeader6Orange: { 
        width: "40%", 
        backgroundColor:"#3f3b7a",
        marginLeft:1
        },
    
  tableCell2Left: { 
  width: "60%", 
  backgroundColor:"#e8ebf5",

  },

tableCell3: { 
width: "40%", 
backgroundColor:"#e8ebf5",
  
},

tableCell4: { 
 width: "20%", 
backgroundColor:"#e8ebf5",
},
tableCell5: { 
 width: "20%", 
backgroundColor:"#e8ebf5",
      
 },
tableCell6: { 
 width: "20%", 
backgroundColor:"#e8ebf5",
},

tableCell7: { 
  width: "40%", 
  backgroundColor:"#cdd4ea",
    
  },
  
  tableCell8: { 
   width: "20%", 
  backgroundColor:"#cdd4ea",
  },
  tableCell9: { 
   width: "20%", 
  backgroundColor:"#cdd4ea",
        
   },
  tableCell10: { 
   width: "20%", 
  backgroundColor:"#cdd4ea",
  },

  tableCell3Orange: { 
    width: "40%", 
    backgroundColor:"#fcece7",
      
    },
    
    tableCell4Orange: { 
     width: "20%", 
    backgroundColor:"#fcece7",
    },
    tableCell5Orange: { 
     width: "20%", 
    backgroundColor:"#fcece7",
          
     },
    tableCell6Orange: { 
     width: "20%", 
    backgroundColor:"#fcece7",
    },
    
    tableCell7Orange: { 
      width: "40%", 
      backgroundColor:"#ffffff",
        
      },
      
      tableCell8Orange: { 
       width: "20%", 
      backgroundColor:"#ffffff",
      },
      tableCell9Orange: { 
       width: "20%", 
      backgroundColor:"#ffffff",
            
       },
      tableCell10Orange: { 
       width: "20%", 
      backgroundColor:"#ffffff",
      },
  tableCell2Right: { 
    width: "40%", 
    backgroundColor:"#e8ebf5",
    marginLeft:1
    },

  tableCellHeader: {
    margin: 6, 
    fontSize: "11px",
    fontWeight: 500,
    
  },
  tableCellHeader11: {
    margin: 5, 
    fontSize: "10px",
    fontWeight: 500,
    
  },

  tableCellHeader1: {
    margin: 6, 
    fontSize: "10px",
    fontWeight: 500,
    textAlign:"center",
    // paddingRight:"20px",
    
  },
  tableCellHeader1Cautionary: {
    margin: 6, 
    fontSize: "9px",
    fontWeight: 500,
    textAlign:"left",
    // paddingRight:"20px",
    
  },
  tableCellHeader1Category: {
    margin: 6, 
    fontSize: "10px",
    fontWeight: 500,
    textAlign:"left",
    // paddingRight:"20px",
    
  },
  tableCellHeader2: {
    margin: 5, 
    fontSize: "11px",
    fontWeight: 500,
    color:"#FFFFFF"
  },
  tableCellHeader2Credit: {
    margin: 5, 
    fontSize: 11,
    fontWeight: 500,
    color:"#FFFFFF"
  },
  tableCellHeaderCategory: {
    margin: 6, 
    fontSize: 12,
    fontWeight: 500,
    color:"#FFFFFF"
  },
  row:{
    borderBottom: "1pt solid #cfe0e8",
    paddingTop: "5px",
    display: "flex", 
    flexDirection: "row",
  },
 
  inlineLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
   position: 'absolute',
   top: 30,
   justifyContent:"space-between",
   left:"35px",
   right:"35px"
  },

  inlineFooter: {
    display: "flex",
    flexDirection: "row",
   borderTop:"2px solid #3f3b7a",
   position: 'absolute',
   bottom: 30,
   color: '#828081',
   justifyContent:"space-between",
   left:"35px",
   right:"35px"
  },

  inlineFooter1: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
   marginTop:6
  },

  inlineFooter2: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
   marginTop:6
  },
  inlineFooter3: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
   marginTop:6
  },
  inlineFooter4: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
   marginTop:6
  },
  inlineFooter5: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
   marginTop:6
  },

  card: {
    width: "60%",
    height: "auto",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
     borderStyle: "outset",
     borderWidth: "1px 1px 1px 1px",
     borderColor: "#cfe0e8",
     marginTop:10,
    display:"flex",
    flexDirection:"row",
  },
  box:{
    border:"1px solid #3f3b7a",
    width:"60%",
    height:"200px",
    marginTop:20,
    display:"flex",
    flexDirection:"row",
    borderRadius:"10px",
  },
  box1:{
    border:"1px solid #3f3b7a",
    width:"40%",
    height:"auto",
    marginTop:55,
    marginBottom:45,
    display:"flex",
    flexDirection:"column",
    borderRadius:"10px",
    backgroundColor:"#f1f5fb",
    padding:"7px"
  },
  box2:{
    border:"1px solid #3f3b7a",
    borderBottom:"5px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    width:"100%",
    height:"80px",
    // marginBottom:25,   
    display:"flex",
    flexDirection:"row",
    borderRadius:"10px",
    marginTop:12
  },

  box2Bottom:{
    border:"1px solid #8190b9",
    width:"100%",
    height:"60px",
    display:"flex",
    flexDirection:"row",
    backgroundColor:"#f1f5fb"

  },
  box3:{
    border:"1px solid #FFFFFF",
    width:"40%",
    marginTop:35,
    marginLeft:10,
    height:"auto"
   
  },
  boxFund1:{
    border:"1px solid #3f3b7a",
    width:"75%",
    height:"65px",
    marginBottom:10,   
    marginLeft:40,
    backgroundColor:"#f1f5fb",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    paddingTop:"20px",
    borderRadius:"5px",
  },
  boxFund2:{
    border:"1px solid #8190b9",
    width:"65%",
    height:"60px",
    marginBottom:10,   
    marginLeft:40,
    backgroundColor:"#734ea0",
    display:"flex",
    flexDirection:"row",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    paddingTop:"20px"
  },
  boxNonFund:{
    border:"1px solid #8190b9",
    width:"65%",
    height:"50px",
    marginBottom:10,   
    marginLeft:40,
    backgroundColor:"#9bd45f",
    display:"flex",
    flexDirection:"row",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    paddingTop:"15px"
  },
  box4:{
    width:"100%",
    height:"auto",
    border:"1px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    borderRadius:"5px",
    marginTop:20
  },
  box4Credit:{
    width:"100%",
    height:"auto",
    border:"1px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    borderRadius:"5px",
    marginTop:20
    
  },
  box444:{
    width:"100%",
    height:"auto",
    border:"1px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    borderRadius:"5px",
    marginTop:20
  },
  box44:{
    width:"75%",
    height:"65px",
    border:"1px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    borderRadius:"5px",
    padding:7,
    marginTop:20,
    marginLeft:40,
  },
  // box5:{
  //   width:"50%",
  //   height:"100px",
  //   display:"flex",
  //   flexDirection:"row",
  //   border:"1px solid #8190b9",
  //   backgroundColor:"#f1f5fb",
  //   display:"flex",
  //   flexDirection:"row",
  //   marginRight:20,
  //   marginTop:10,
  //   padding:"7px"

  // },

  box5:{
    width:"100%",
    border:"1px solid #3f3b7a",
    backgroundColor:"#f1f5fb",
    marginTop:10,
    padding:"7px",
    borderRadius:"5px"

  },
  box6:{
    width:"50%",
    height:"auto",
    display:"flex",
    flexDirection:"row",
    border:"1px solid #8190b9",
    backgroundColor:"#f1f5fb",
    display:"flex",
    flexDirection:"row",
    marginTop:10,
    padding:"7px"
  },
  
  box7:{
    border:"1px solid #8190b9",
    width:"25%",
    height:"300px",
    marginTop:20,
    marginLeft:20

  },
  arrow: {
    width:"14px",
    height:"14px",
    marginLeft:5
  
  },
  arrow1: {
    width:"14px",
    height:"14px",
    textAlign:"center",
    marginTop:5,
    marginLeft:15 
  },

  arrow2: {
    width:"10px",
    height:"10px",
    textAlign:"left",
    // marginTop:20
    // marginLeft:50 
  },
  inlineHeader: {
    display: "flex",
    flexDirection: "row",
    left:"465px"
  },
  imageHeader: {
    width:"60px",
    height:"20px",
  },
  tableCell66: { 
    margin:3,
    fontSize: "11px",
    fontWeight:800,
    color:"#FFFFFF",
    textAlign:"center"
  },
  tableCell55: { 
    margin:3,
    fontSize: "11px",
    fontWeight:800,
    color:"#FFFFFF",
    textAlign:"center",
    marginTop:"9px"
  },
  
});

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [chartLabels,setChartLabels] = React.useState([]);
  const [chartData,setChartData] = React.useState([]);


  const [dataStatus, setDataStatus] = React.useState(false);
  const [showFundScoreList, setShowFundScoreList] = React.useState(false);
  const [showFundPortfolioSqftList, setShowFundPortfolioSqftList] = React.useState(false);
  const [showTopPerformingTenantsList,setShowTopPerformingTenantsList] = React.useState(false);
  const [showTopPerformingPropertiesList,setShowTopPerformingPropertiesList] = React.useState(false);
  const [showTopPerformingIndustriesList,setShowTopPerformingIndustriesList] = React.useState(false);
  const [showTopPerformingFundsList,setShowTopPerformingFundsList] = React.useState(false);

  const [showFundDetailsList,setShowFundDetailsList] = React.useState(false);
  const [noDataAvailableTopPerformingTenantsList,setNoDataAvailableTopPerformingTenantsList] = React.useState(false);
  const [noDataAvailableTopPerformingPropertiesList,setNoDataAvailableTopPerformingPropertiesList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustyList,setNoDataAvailableTopPerformingIndustyList] = React.useState(false);
  const [noDataAvailableTopPerformingFundsList,setNoDataAvailableTopPerformingFundsList] = React.useState(false);


  const [noDataAvailableFundScoreList,setNoDataAvailableFundScoreList] = React.useState(false);
  const [noDataAvailableFundPortfolioSqftList,setNoDataAvailableFundPortfolioSqftList] = React.useState(false);
  const [noDataAvailableSuits,setNoDataAvailableSuits] = React.useState(false);
  const [showPieChart,setShowPieChart]=React.useState(false);

  const [noDataAvailableInactiveTenantList,setNoDataAvailableInactiveTenantList] = React.useState(false);
  const [noDataAvailablePositiveAlertsTenantList,setNoDataAvailablePositiveAlertsTenantList] = React.useState(false);
  const [noDataAvailableNegativeAlertsTenantList,setNoDataAvailableNegativeAlertsTenantList] = React.useState(false);
  const [noDataAvailableFundTopPerformingProperties,setNoDataAvailableFundTopPerformingProperties] = React.useState(false);
  const [noDataAvailableFundTopPerformingTenants,setNoDataAvailableFundTopPerformingTenants] = React.useState(false);
  const [noDataAvailableFundTopPerformingIndustries,setNoDataAvailableFundTopPerformingIndustries] = React.useState(false);
  const [noDataAvailableIncreasedCreditScoreList,setNoDataAvailableIncreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableDecreasedCreditScoreList,setNoDataAvailableDecreasedCreditScoreList] = React.useState(false);

  const [showInactiveTenantList, setShowInactiveTenantList] = React.useState(false);
  const [showPositiveAlertsTenantList,setShowPositiveAlertsTenantList] = React.useState(false);
  const [showNegativeAlertsTenantList,setShowNegativeAlertsTenantList] = React.useState(false);
  const [showFundTopPerformingProperties,setShowFundTopPerformingProperties] = React.useState(false);
  const [showFundTopPerformingTenants,setShowFundTopPerformingTenants] = React.useState(false);
  const [showFundTopPerformingIndustries,setShowFundTopPerformingIndustries] = React.useState(false);

  const [showFundLowestScoringTable,setShowFundLowestScoringTable] = React.useState(false);
  const [noDataAvailableFundLowestScoringTable,setNoDataAvailableFundLowestScoringTable] = React.useState(false);
  const [showIncrementedCreditScoreList, setShowIncrementedCreditScoreList] = React.useState(false);
  const [showDecrementedCreditScoreList, setShowDecrementedCreditScoreList] = React.useState(false);

  const [showLowRiskCountPercentage, setShowLowRiskCountPercentage] = React.useState(false);
  const [showMediumLowRiskCountPercentage, setShowMediumLowRiskCountPercentage] = React.useState(false);
  const [showModerateHighRiskCountPercentage, setShowModerateHighRiskCountPercentage] = React.useState(false);
  const [showMediumHighRiskCountPercentage, setShowMediumHighRiskCountPercentage] = React.useState(false);
  const [showHighRiskCountPercentage, setShowHighRiskCountPercentage] = React.useState(false);

  const [noDataAvailableLowestScoringProperties,setNoDataAvailableLowestScoringProperties] = React.useState(false);
  const [noDataAvailableLowestScoringTenants,setNoDataAvailableLowestScoringTenants] = React.useState(false);
  const [noDataAvailableLowestScoringIndustries,setNoDataAvailableLowestScoringIndustries] = React.useState(false);
  const [showLowestScoringProperties,setShowLowestScoringProperties] = React.useState(false);
  const [showLowestScoringTenants,setShowLowestScoringTenants] = React.useState(false);
  const [showLowestScoringIndustries,setShowLowestScoringIndustries] = React.useState(false);
  const [showCautinaryUccList,setShowCautinaryUccList] = React.useState(false);
  const [noDataAvailableCautionaryUccList,setNoDataAvailableCautionaryUccList] = React.useState(false);

  const [showTaxLienList,setShowTaxLienList] = React.useState(false);
  const [noDataAvailableTaxLienList,setNoDataAvailableTaxLienList] = React.useState(false);

  const [showJudgementList,setShowJudgementList] = React.useState(false);
  const [noDataAvailableJudgementList,setNoDataAvailableJudgementList] = React.useState(false);

  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [noDataAvailableSuitsList,setNoDataAvailableSuitsList] = React.useState(false);

  const [showNewCautinaryUccList,setShowNewCautinaryUccList] = React.useState(false);
  const [noDataAvailableNewCautionaryUccList,setNoDataAvailableNewCautionaryUccList] = React.useState(false);

  const [showNewTaxLienList,setShowNewTaxLienList] = React.useState(false);
  const [noDataAvailableNewTaxLienList,setNoDataAvailableNewTaxLienList] = React.useState(false);

  const [showNewJudgementList,setShowNewJudgementList] = React.useState(false);
  const [noDataAvailableNewJudgementList,setNoDataAvailableNewJudgementList] = React.useState(false);

  const [showNewSuitsList,setShowNewSuitsList] = React.useState(false);
  const [noDataAvailableNewSuitsList,setNoDataAvailableNewSuitsList] = React.useState(false);

  const [showNoNewDerogFilings, setShowNoNewDerogFilings] = React.useState(false);
  const [showNewDerogFilingsCount, setShowNewDerogFilingsCount] = React.useState(false);

const[loader,setLoader]=React.useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const todayNow = moment().format("MMMM YYYY"); 
  var currentYear  = moment().format('YYYY');

  const[showArepLogo,setShowArepLogo]=React.useState(false);
  const[showCarterLogo,setShowCarterLogo]=React.useState(false);

React.useEffect(() => {
 
  // if(generateMonthlyPdfReport!=""){
  //   setLoader(false);
  
  if(organisationId=="60f7cf3a09b25f474b336f8b"){
   setShowArepLogo(true);
   setShowCarterLogo(false);
  }else if(organisationId=="654b476a423c6401f20f16db"){
    setShowArepLogo(false);
    setShowCarterLogo(true);
  }

  setShowPieChart(true);

  setChartLabels(generateMonthlyPdfReport.riskCounts.labels);
  setChartData(generateMonthlyPdfReport.riskCounts.riskCounts);

  if(generateMonthlyPdfReport.lowRiskCountPercentage > 0){
    setShowLowRiskCountPercentage(true);
  }else{
    setShowLowRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport.mediumLowRiskCountPercentage > 0){
    setShowMediumLowRiskCountPercentage(true);
  }else{
    setShowMediumLowRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport.moderateHighRiskCountPercentage > 0){
    setShowModerateHighRiskCountPercentage(true);
  }else{
    setShowModerateHighRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport.mediumHighRiskCountPercentage > 0){
    setShowMediumHighRiskCountPercentage(true);
  }else{
    setShowMediumHighRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport.highRiskCountPercentage > 0){
    setShowHighRiskCountPercentage(true);
  }else{
    setShowHighRiskCountPercentage(false);
  }
  
  //topPerforming

  if(generateMonthlyPdfReport.topPerformingFundsList!==null || generateMonthlyPdfReport.topPerformingFundsList!=[]){
    setShowTopPerformingFundsList(true);
    setNoDataAvailableTopPerformingFundsList(false);
  }

  if(generateMonthlyPdfReport.topPerformingFundsList.length===0 || generateMonthlyPdfReport.topPerformingFundsList===null || generateMonthlyPdfReport.topPerformingFundsList==[ ]){
    setShowTopPerformingFundsList(false);
    setNoDataAvailableTopPerformingFundsList(true);
  }

  if(generateMonthlyPdfReport.topPerformingTenantsList!==null || generateMonthlyPdfReport.topPerformingTenantsList!=[]){
      setShowTopPerformingTenantsList(true);
      setNoDataAvailableTopPerformingTenantsList(false);
    }

    if(generateMonthlyPdfReport.topPerformingTenantsList.length===0 || generateMonthlyPdfReport.topPerformingTenantsList===null || generateMonthlyPdfReport.topPerformingTenantsList==[ ]){
      setShowTopPerformingTenantsList(false);
      setNoDataAvailableTopPerformingTenantsList(true);
    }
    
  if(generateMonthlyPdfReport.topPerformingPropertiesList!==null || generateMonthlyPdfReport.topPerformingPropertiesList!=[ ]){
      setShowTopPerformingPropertiesList(true);
      setNoDataAvailableTopPerformingPropertiesList(false);
    }
    if(generateMonthlyPdfReport.topPerformingPropertiesList.length===0 || generateMonthlyPdfReport.topPerformingPropertiesList==null || generateMonthlyPdfReport.topPerformingPropertiesList==[ ] || generateMonthlyPdfReport.topPerformingPropertiesList.length === 0){
      setShowTopPerformingPropertiesList(false);
      setNoDataAvailableTopPerformingPropertiesList(true);
    }    
   
  if(generateMonthlyPdfReport.topPerformingIndustriesList!==null || generateMonthlyPdfReport.topPerformingIndustriesList!=[]){

    setShowTopPerformingIndustriesList(true);
    setNoDataAvailableTopPerformingIndustyList(false);

    }
    if(generateMonthlyPdfReport.topPerformingIndustriesList.length===0 || generateMonthlyPdfReport.topPerformingIndustriesList===null || generateMonthlyPdfReport.topPerformingIndustriesList==[]){
      setShowTopPerformingIndustriesList(false);
      setNoDataAvailableTopPerformingIndustyList(true);
    }
    
   //positive and negative news list
    if(generateMonthlyPdfReport.negativeNewsList!==null || generateMonthlyPdfReport.negativeNewsList!=[ ]){
      setShowNegativeAlertsTenantList(true);
      setNoDataAvailableNegativeAlertsTenantList(false);
    }
    if(generateMonthlyPdfReport.negativeNewsList.length===0 || generateMonthlyPdfReport.negativeNewsList===null || generateMonthlyPdfReport.negativeNewsList==[ ]){
      setShowNegativeAlertsTenantList(false);
      setNoDataAvailableNegativeAlertsTenantList(true);
    }

    if(generateMonthlyPdfReport.positiveNewsList!==null || generateMonthlyPdfReport.positiveNewsList!=[ ]){
      setShowPositiveAlertsTenantList(true);
      setNoDataAvailablePositiveAlertsTenantList(false);
    }
    if(generateMonthlyPdfReport.positiveNewsList.length===0 || generateMonthlyPdfReport.positiveNewsList===null || generateMonthlyPdfReport.positiveNewsList==[ ]){
      setShowPositiveAlertsTenantList(false);
      setNoDataAvailablePositiveAlertsTenantList(true);
    }

    // creditscore incremented and decremented list
    if(generateMonthlyPdfReport.creditScoreIncrementedList!==null || generateMonthlyPdfReport.creditScoreIncrementedList!=[ ]){
      setShowIncrementedCreditScoreList(true);
      setNoDataAvailableIncreasedCreditScoreList(false);
    }
    if(generateMonthlyPdfReport.creditScoreIncrementedList.length===0 || generateMonthlyPdfReport.creditScoreIncrementedList===null || generateMonthlyPdfReport.creditScoreIncrementedList==[ ]){
      setShowIncrementedCreditScoreList(false);
      setNoDataAvailableIncreasedCreditScoreList(true);
    }

    if(generateMonthlyPdfReport.creditScoreDecrementedList!=null || generateMonthlyPdfReport.creditScoreDecrementedList!=[ ]){
      setShowDecrementedCreditScoreList(true);
      setNoDataAvailableDecreasedCreditScoreList(false);
    }
    if(generateMonthlyPdfReport.creditScoreDecrementedList.length===0|| generateMonthlyPdfReport.creditScoreDecrementedList==null || generateMonthlyPdfReport.creditScoreDecrementedList==[ ]){
      setShowDecrementedCreditScoreList(false);
      setNoDataAvailableDecreasedCreditScoreList(true);
    }


    //lowestScoring
    if(generateMonthlyPdfReport.lowestScoringPropertiesList.length === 0){
      setShowLowestScoringProperties(false);
      setNoDataAvailableLowestScoringProperties(true);
   
    }
    if(generateMonthlyPdfReport.lowestScoringPropertiesList.length > 0){
      setShowLowestScoringProperties(true);
      setNoDataAvailableLowestScoringProperties(false);
    }
  
    if(generateMonthlyPdfReport.lowestScoringTenantsList.length > 0){
      setShowLowestScoringTenants(true);
      setNoDataAvailableLowestScoringTenants(false);
    
    }
    if(generateMonthlyPdfReport.lowestScoringTenantsList.length === 0){
      setShowLowestScoringTenants(false);
      setNoDataAvailableLowestScoringTenants(true);
    }
    
    if(generateMonthlyPdfReport.lowestScoringIndustriesList.length > 0){
      setShowLowestScoringIndustries(true);
      setNoDataAvailableLowestScoringIndustries(false);
     
    }
    if(generateMonthlyPdfReport.lowestScoringIndustriesList.length === 0){
      setShowLowestScoringIndustries(false);
      setNoDataAvailableLowestScoringIndustries(true);
    }

   //cautionaryUcclist
if(generateMonthlyPdfReport.cautionaryUccFundDTOList.length===0){
  setShowCautinaryUccList(false);
  // setNoDataAvailableCautionaryUccList(true);
}else{
  setShowCautinaryUccList(true);
  setNoDataAvailableCautionaryUccList(false);
}
 

  if(generateMonthlyPdfReport.taxLiensFundDTOList.length===0){
     
    setShowTaxLienList(false);
      // setNoDataAvailableTaxLienList(true);
    }else{
      setShowTaxLienList(true);
      setNoDataAvailableTaxLienList(false);
    }
   

    if(generateMonthlyPdfReport.judgementFundDTOList.length===0){

        setShowJudgementList(false);
        // setNoDataAvailableJudgementList(true);
    }else{
      setShowJudgementList(true);
      setNoDataAvailableJudgementList(false);
    }
     
      if(generateMonthlyPdfReport.suitsFundDTOList.length===0){
         
          setShowSuitsList(false);
          // setNoDataAvailableSuitsList(true);
      
        }else{
          setShowSuitsList(true);
          setNoDataAvailableSuitsList(false);
        }

        if(generateMonthlyPdfReport.cautionaryUccFundDTOList.length===0 && generateMonthlyPdfReport.taxLiensFundDTOList.length===0 && generateMonthlyPdfReport.judgementFundDTOList.length===0 && generateMonthlyPdfReport.suitsFundDTOList.length===0){
          setShowSuitsList(false);
          setShowJudgementList(false);
          setShowTaxLienList(false);
          setShowCautinaryUccList(false);

        }else{
          // setShowNoNewDerogFilings(false);
          // setShowNewDerogFilingsCount(true);
        }
     
        //new derog filings
        if(generateMonthlyPdfReport.newCautionaryUccFundDTOList.length===0){     
       
          setShowNewCautinaryUccList(false);
          // setNoDataAvailableNewCautionaryUccList(true);
          // setShowNoNewDerogFilings(true);
      
          }
          else{
       
            setShowNewCautinaryUccList(true);
            setNoDataAvailableNewCautionaryUccList(false);
            setShowNoNewDerogFilings(false);
          }
            
        
          if(generateMonthlyPdfReport.newTaxLiensFundDTOList.length===0){    
        
            setShowNewTaxLienList(false);
            // setNoDataAvailableNewTaxLienList(true);
            // setShowNoNewDerogFilings(true);   
       
            }else{
              setShowNewTaxLienList(true);
              setNoDataAvailableNewTaxLienList(false);
              setShowNoNewDerogFilings(false);
            }
          
        
            if(generateMonthlyPdfReport.newJudgementFundDTOList.length===0){
    
              setShowNewJudgementList(false);
              // setNoDataAvailableNewJudgementList(true);
              // setShowNoNewDerogFilings(true);

              }else{
              setShowNewJudgementList(true);
              setNoDataAvailableNewJudgementList(false);
              setShowNoNewDerogFilings(false);
            
              }
              
              if(generateMonthlyPdfReport.newSuitsFundDTOList.length===0){
          
                setShowNewSuitsList(false);
                // setNoDataAvailableNewSuitsList(true);
                // setShowNoNewDerogFilings(true);
              
                }else{
                    
                setShowNewSuitsList(true);
                setNoDataAvailableNewSuitsList(false);
                setShowNoNewDerogFilings(false);
                }

                if(generateMonthlyPdfReport.newCautionaryUccFundDTOList.length===0 && generateMonthlyPdfReport.newTaxLiensFundDTOList.length===0 && generateMonthlyPdfReport.newJudgementFundDTOList.length===0 && generateMonthlyPdfReport.newSuitsFundDTOList.length===0){
                  setShowNoNewDerogFilings(true);
                  setShowNewDerogFilingsCount(false);
                  setShowNewSuitsList(false);
                  setShowNewJudgementList(false);
                  setShowNewTaxLienList(false);
                  setShowNewCautinaryUccList(false);

                }else{
                  setShowNoNewDerogFilings(false);
                  setShowNewDerogFilingsCount(true);
                }
              // }
        // setTimeout(() => {
  //   setDataStatus(true);
   
  //   }, 4000);
    }, [dataStatus]);
   
    const toCurrency=(value)=>{
      let number = parseFloat(value);
      return number.toLocaleString('en-US');
    }
    

const ChartJsImage = require('chartjs-to-image');


const myChart = new ChartJsImage();
myChart.setConfig({
  type: 'pie',
  data: { labels: chartLabels, datasets: [{data: chartData, backgroundColor: [
    '#4caf50',
    '#32cd32',
    '#ffdf00',
    '#ff9800',
    'red',
]}] },
options: {
  plugins: {
    datalabels: {
      // align: 'end',
      // anchor: 'end',
      color: "#000000",
      formatter: function (value) {
        return Math.round(value) + '%';
      },
      font: {
        weight: 'bold',
        size: 18,
      }
    }
   
  },
  legend: {
    display: true,
    position: "bottom"
  },
}
});



const dataUrl =  myChart.getUrl();

return(
<Document>
    <Page size="A4" style={styles.body} >
    <View style={styles.inlineHeader} fixed>
  <Image
        style={styles.imageHeader}
        src={require("assets/img/tenanttracker_11803_logo_500.png")}
   
      />
</View>
<View>
    {showArepLogo&&<Image
        style={styles.image}
        src={require("assets/img/AREP_Logo.jpg")}
      
      />}
        {showCarterLogo&&<Image
        style={styles.imageCarter}
        src={require("assets/img/AphorioCarterSponsorLogo.png")}
      
      />}
</View>
<Image
        style={styles.image1}
        src={require("assets/img/AREP Monthly Report Image.jpg")}
      
      />
  <Text style={{fontSize:"30px" ,fontWeight: 900, marginTop:30,textAlign:"center"}}>Monthly Portfolio Summary</Text>
  <Text style={{fontSize:"20px" ,fontWeight: 900, marginTop:30,textAlign:"center"}}>{todayNow}</Text>

  <Text style={{fontSize:"30px" ,fontWeight: 900,textAlign:"center",lineHeight:"1.5px"}} break>Portfolio Summary</Text>
  <View style={styles.box4}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             <Text style={{fontSize:"12px",color:"#000000"}}>The Portfolio has an overall score of {generateMonthlyPdfReport.portfolioScore} (on a scale of 0-500),which is based on a blended weighted average based on overall square footage of the three funds.</Text>
             </View>      
        </View> 
  <View style={styles.inline}>
  <View style={styles.box}>
     <View style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center"}}>
        <View>
      <Text style={{fontSize:"12px" ,fontWeight: 900,paddingLeft:"80px"}}>Portfolio Tenant Risk</Text> 

          {showPieChart&& <Image
        style={styles.image2}
        src={dataUrl}
      
      />}
      </View>
        </View>

  </View>

  <Image
        style={styles.image3}
        src={require("assets/img/bluerightarrow.png")}
      
      />

  <View style={styles.box1}>
    <View style={{display:"flex",flexDirection:"column",width:"90%"}}>
  <Text style={{fontSize:"13px",color:"#000000",marginTop:5}}>The Portfolio has: </Text>
  {showLowRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
 <Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPdfReport.lowRiskCountPercentage}% of its tenants with a Low Risk Score (401 and higher)</Text>
 </View>
 </View>}
 
 {showMediumLowRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPdfReport.mediumLowRiskCountPercentage}% of the tenants with a Medium Low Risk Score (301 to 400)</Text>
 </View>
 </View>}
 
 {showModerateHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPdfReport.moderateHighRiskCountPercentage}% of the tenants have a Moderately High Risk Score (201 to 300)</Text>
 </View>
 </View>}

 {showMediumHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPdfReport.mediumHighRiskCountPercentage}% of the tenants with a Medium High Risk Score (101 to 200)</Text>
 </View>
 </View>}

 {showHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPdfReport.highRiskCountPercentage}% of the tenants have a very High Risk Score (0 to 100)</Text>
 </View>
 </View>}
 
  </View>
  </View>
  </View>


{/* <View break> */}
<Text style={{fontSize:"15px",color:"#000000",marginTop:17}}>Top Performing Funds</Text>
{showTopPerformingFundsList&&<View style={styles.table}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Funds</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell2}>Fund Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.topPerformingFundsList!=""&&generateMonthlyPdfReport.topPerformingFundsList.map((topPerformingFund, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingFund.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingFund.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingFund.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323",  width: "10%"}}> 
          {topPerformingFund.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingFund.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 
    </View>))}
</View>}
{noDataAvailableTopPerformingFundsList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>No Data Available for top performing funds...</Text>}

<Text style={{fontSize:"15px",color:"#000000",marginTop:"20px"}}>Top Performing Properties</Text>
{showTopPerformingPropertiesList&&<View style={styles.table}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Properties</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell66}>Property Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.topPerformingPropertiesList!=""&&generateMonthlyPdfReport.topPerformingPropertiesList.map((topPerformingProperty, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingProperty.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingProperty.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingProperty.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {topPerformingProperty.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingProperty.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 
    </View>))}
</View>}
{noDataAvailableTopPerformingPropertiesList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no properties which have a score of more than 300 for the current month.</Text>}

<View break>

<Text style={{fontSize:"15px",color:"#000000",marginTop:"10px"}}>Lowest Performing Properties</Text>
{showLowestScoringProperties&&<View style={styles.table}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Properties</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell66}>Property Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.lowestScoringPropertiesList!=""&&generateMonthlyPdfReport.lowestScoringPropertiesList.map((lowestScoringProperty, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{lowestScoringProperty.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringProperty.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringProperty.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {lowestScoringProperty.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          lowestScoringProperty.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View>
    </View>))}
</View>}
{noDataAvailableLowestScoringProperties&&<Text style={{fontSize:"12px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no properties which have a score of less than 300 for the current month.</Text>}

<Text style={{fontSize:"15px",color:"#000000",marginTop:"20px"}}>Top Performing Tenants</Text>
{showTopPerformingTenantsList&&<View style={styles.table2}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Tenants</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell2}>Tenant Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

   {generateMonthlyPdfReport.topPerformingTenantsList!=""&&generateMonthlyPdfReport.topPerformingTenantsList.map((topPerformingTenant, i)=>(
  <View style={styles.tableRow}>

       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingTenant.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingTenant.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingTenant.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {topPerformingTenant.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingTenant.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 
    </View>))}
</View>}
{noDataAvailableTopPerformingTenantsList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no tenants which have a score of more than 300 for the current month.</Text>}


<Text style={{fontSize:"15px",color:"#000000",marginTop:"10px"}}>Lowest Performing Tenants</Text>
{showLowestScoringTenants&&<View style={styles.table2}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Tenants</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell2}>Tenant Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.lowestScoringTenantsList!=""&&generateMonthlyPdfReport.lowestScoringTenantsList.map((lowestScoringTenant, i)=>(
  <View style={styles.tableRow}>

       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{lowestScoringTenant.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringTenant.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringTenant.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {lowestScoringTenant.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          lowestScoringTenant.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View>
    </View>))}
</View>}
{noDataAvailableLowestScoringTenants&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no tenants which have a score less than 300 for the current month.</Text>}


<Text style={{fontSize:"15px",color:"#000000",marginTop:"10px"}}>Top Performing Industries</Text>
{showTopPerformingIndustriesList&&<View style={styles.table1}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Industries</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell55}>Industry Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

   {generateMonthlyPdfReport.topPerformingIndustriesList!=""&&generateMonthlyPdfReport.topPerformingIndustriesList.map((topPerformingIndustry,i)=>(

<View style={styles.tableRow}>
 
<View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingIndustry.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingIndustry.currentScore}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingIndustry.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {topPerformingIndustry.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingIndustry.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 

    </View>))}
</View>}

    {noDataAvailableTopPerformingIndustyList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>No Data Available for top performing industries...</Text>}
</View>
{/* </View> */}

<View break>
<Text style={{fontSize:"15px",color:"#000000",marginTop:"10px"}}>Lowest Performing Industries</Text>

{showLowestScoringIndustries&&<View style={styles.table1}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Industries</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell55}>Industry Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Portfolio (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.lowestScoringIndustriesList!=""&&generateMonthlyPdfReport.lowestScoringIndustriesList.map((lowestScoringIndustry,i)=>(

<View style={styles.tableRow}>
 
<View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{lowestScoringIndustry.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringIndustry.currentScore}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringIndustry.percentageOfPortfolio}%</Text> 
</View> 

<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {lowestScoringIndustry.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          lowestScoringIndustry.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View>
    </View>))}
</View>}

    {noDataAvailableLowestScoringIndustries&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:20}}>No Data Available for lowest scoring industries...</Text>}
</View>

<View break>
<Text style={{fontSize:"17px",color:"#000000"}}>Credit Score Changes</Text>

<View style={styles.box4Credit}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.increasedTenantCreditScoreCount} tenants of the total {generateMonthlyPdfReport.totalTenantCount} tenants across all funds had increases of over 50 basis points, {generateMonthlyPdfReport.decreasedTenantCreditScoreCount} tenants of the total {generateMonthlyPdfReport.totalTenantCount} tenants across all funds had decreases of over 50 basis points.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.creditScoreText}</Text>

             </View>      
        </View> 
{/* <View style={styles.inline}> */}

<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Tenants with Increased Credit Score</Text>

{showIncrementedCreditScoreList&& <View style={styles.table6}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader11}> 
            <Text style={styles.tableCellHeader2Credit}>Tenants</Text> 
          </View> 
          <View style={styles.tableHeader222}> 
            <Text style={styles.tableCellHeader2Credit}>Previous Score</Text> 
          </View> 
          <View style={styles.tableHeader222}> 
            <Text style={styles.tableCellHeader2Credit}>Current Score</Text> 
          </View>
    </View>

    {generateMonthlyPdfReport.creditScoreIncrementedList!=""&&generateMonthlyPdfReport.creditScoreIncrementedList.map((increment, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "80%"}}> 
<Text style={styles.tableCellHeader}>{increment.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "20%"}}> 
<Text style={styles.tableCellHeader1}>{increment.prevScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "20%"}}> 
<Text style={styles.tableCellHeader1}>{increment.currScore}&nbsp;({increment.percentage}%)</Text> 
</View> 

    </View>))}
</View>}
{noDataAvailableIncreasedCreditScoreList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no tenants which have a credit score which increased by 50 basis points in the current month.</Text>}


<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Tenants with Decreased Credit Score</Text>

{showDecrementedCreditScoreList&&<View style={styles.table7}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader11}> 
            <Text style={styles.tableCellHeader2Credit}>Tenants</Text> 
          </View> 
          <View style={styles.tableHeader222}> 
            <Text style={styles.tableCellHeader2Credit}>Previous Score</Text> 
          </View> 
          <View style={styles.tableHeader222}> 
            <Text style={styles.tableCellHeader2Credit}>Current Score</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.creditScoreDecrementedList!=""&&generateMonthlyPdfReport.creditScoreDecrementedList.map((decrement, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "80%"}}> 
<Text style={styles.tableCellHeader}>{decrement.name}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "20%"}}> 
<Text style={styles.tableCellHeader1}>{decrement.prevScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "20%"}}> 
<Text style={styles.tableCellHeader1}>{decrement.currScore}&nbsp;({decrement.percentage}%)</Text> 
</View> 

    </View>))}
</View>}
{noDataAvailableDecreasedCreditScoreList&&<Text style={{fontSize:"11px",fontWeight:900,textAlign:"left",marginTop:"20px"}}>There are no tenants which have a credit score which decreased by 50 basis points in the current month.</Text>}

{/* </View> */}

</View>


<View break>
<Text style={{fontSize:"18px",color:"#000000"}}>Derogatory Summary</Text>

{showNoNewDerogFilings && <View style={styles.box4}>
<View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPdfReport.tenantLiensCount} had new liens, {generateMonthlyPdfReport.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPdfReport.tenantJudgementCount} had new judgements, and {generateMonthlyPdfReport.tenantSuitsCount} had new civil suits, and {generateMonthlyPdfReport.inactiveTenantCount} became inactive.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>There are no new derogatory filings for the current month.</Text>
             </View>

        </View>}

        {showNewDerogFilingsCount && <View style={styles.box4}>
        <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPdfReport.tenantLiensCount} had new liens, {generateMonthlyPdfReport.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPdfReport.tenantJudgementCount} had new judgements, and {generateMonthlyPdfReport.tenantSuitsCount} had new civil suits, and {generateMonthlyPdfReport.inactiveTenantCount} became inactive.</Text> */}
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.tenantDerogatoryCount} tenants have active derogatory filings; {generateMonthlyPdfReport.newLiensDerogCount} active liens, {generateMonthlyPdfReport.newCautionaryUccDerogCount} active cautionary UCC filings, {generateMonthlyPdfReport.newJudgementDerogCount} active judgements and {generateMonthlyPdfReport.newSuitsDerogCount} active civil suits.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.newDerogText}</Text>

             </View>      

        </View>}

        {showNewCautinaryUccList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Cautionary UCC Filings</Text>}

{showNewCautinaryUccList&&generateMonthlyPdfReport.newCautionaryUccFundDTOList!=""&&generateMonthlyPdfReport.newCautionaryUccFundDTOList.map((cautionaryUccDetailsProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {cautionaryUccDetailsProperty.fundName}</Text>

{cautionaryUccDetailsProperty!=""&& cautionaryUccDetailsProperty.cautionaryUccPropertyList.map((cautionaryUccDetailsTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {cautionaryUccDetailsTenant.propertyName}</Text>
{cautionaryUccDetailsTenant!=""&& cautionaryUccDetailsTenant.cautionaryUccAndTenantDTOList.map((cautionaryUccDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {cautionaryUccDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader44}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
  <View style={styles.tableHeader33}> 
    <Text style={styles.tableCell}>Secured Party Information</Text> 
  </View> 
  <View style={styles.tableHeader33Category}> 
    <Text style={styles.tableCell}>Collateral</Text> 
  </View> 
</View>

{cautionaryUccDetails.cautionaryUccDetailsList!="" && cautionaryUccDetails.cautionaryUccDetailsList.map((cautionaryUccDetails, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.jurisdiction}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "25%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.securedPartyName}%</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "40%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.collateral}%</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableNewCautionaryUccList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Cautionary UCC Filings...</Text>}

{showNewTaxLienList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Tax Lien Filings</Text>}

{showNewTaxLienList&&generateMonthlyPdfReport.newTaxLiensFundDTOList!=""&&generateMonthlyPdfReport.newTaxLiensFundDTOList.map((taxLienProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {taxLienProperty.fundName}</Text>

{taxLienProperty!=""&& taxLienProperty.taxLiensPropertyList.map((taxLienTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {taxLienTenant.propertyName}</Text>
{taxLienTenant!=""&& taxLienTenant.taxLiensTenantDTOList.map((taxLienDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {taxLienDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
</View>

{taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>${toCurrency(taxLien.amount)}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.jurisdiction}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableNewTaxLienList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Tax Lien Filings...</Text>}


{showNewJudgementList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Judgement Filings</Text>}

{showNewJudgementList&&generateMonthlyPdfReport.newJudgementFundDTOList!=""&&generateMonthlyPdfReport.newJudgementFundDTOList.map((judgementProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {judgementProperty.fundName}</Text>

{judgementProperty!=""&& judgementProperty.judgementPropertyList.map((judgementTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {judgementTenant.propertyName}</Text>
{judgementTenant!=""&& judgementTenant.judgementTenantDTOList.map((judgementDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {judgementDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Case Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableNewJudgementList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Judgement...</Text>}


{showNewSuitsList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Civil Suits</Text>}

{showNewSuitsList&&generateMonthlyPdfReport.newSuitsFundDTOList!=""&&generateMonthlyPdfReport.newSuitsFundDTOList.map((suitsProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {suitsProperty.fundName}</Text>

{suitsProperty!=""&& suitsProperty.suitsPropertyList.map((suitsTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {suitsTenant.propertyName}</Text>
{suitsTenant!=""&& suitsTenant.suitsTenantDTOList.map((suitsDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {suitsDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Case Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suits, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(suits.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableNewSuitsList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Civil Suits...</Text>}



<View style={styles.box4}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPdfReport.tenantLiensCount} had new liens, {generateMonthlyPdfReport.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPdfReport.tenantJudgementCount} had new judgements, and {generateMonthlyPdfReport.tenantSuitsCount} had new civil suits, and {generateMonthlyPdfReport.inactiveTenantCount} became inactive.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.derogText}</Text>
             </View>      
        </View> 
        {showCautinaryUccList&& <Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Cautionary UCC Filings</Text>}

{showCautinaryUccList&&generateMonthlyPdfReport.cautionaryUccFundDTOList!=""&&generateMonthlyPdfReport.cautionaryUccFundDTOList.map((cautionaryUccDetailsProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {cautionaryUccDetailsProperty.fundName}</Text>

{cautionaryUccDetailsProperty!=""&& cautionaryUccDetailsProperty.cautionaryUccPropertyList.map((cautionaryUccDetailsTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {cautionaryUccDetailsTenant.propertyName}</Text>
{cautionaryUccDetailsTenant!=""&& cautionaryUccDetailsTenant.cautionaryUccAndTenantDTOList.map((cautionaryUccDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {cautionaryUccDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader44}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
  <View style={styles.tableHeader33}> 
    <Text style={styles.tableCell}>Secured Party Information</Text> 
  </View> 
  <View style={styles.tableHeader33Category}> 
    <Text style={styles.tableCell}>Collateral</Text> 
  </View> 
</View>

{cautionaryUccDetails.cautionaryUccDetailsList!="" && cautionaryUccDetails.cautionaryUccDetailsList.map((cautionaryUccDetails, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.jurisdiction}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "25%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.securedPartyName}%</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "40%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.collateral}%</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableCautionaryUccList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Cautionary UCC Filings...</Text>}


{showTaxLienList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Tax Lien Filings</Text>}

{showTaxLienList&&generateMonthlyPdfReport.taxLiensFundDTOList!=""&&generateMonthlyPdfReport.taxLiensFundDTOList.map((taxLienProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {taxLienProperty.fundName}</Text>

{taxLienProperty!=""&& taxLienProperty.taxLiensPropertyList.map((taxLienTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {taxLienTenant.propertyName}</Text>
{taxLienTenant!=""&& taxLienTenant.taxLiensTenantDTOList.map((taxLienDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {taxLienDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
</View>

{taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.jurisdiction}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableTaxLienList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Tax Lien Filings...</Text>}


{showJudgementList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Judgement Filings</Text>}

{showJudgementList&&generateMonthlyPdfReport.judgementFundDTOList!=""&&generateMonthlyPdfReport.judgementFundDTOList.map((judgementProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {judgementProperty.fundName}</Text>

{judgementProperty!=""&& judgementProperty.judgementPropertyList.map((judgementTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {judgementTenant.propertyName}</Text>
{judgementTenant!=""&& judgementTenant.judgementTenantDTOList.map((judgementDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {judgementDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Case Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableJudgementList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Judgement...</Text>}


{showSuitsList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Civil Suits</Text>}

{showSuitsList&&generateMonthlyPdfReport.suitsFundDTOList!=""&&generateMonthlyPdfReport.suitsFundDTOList.map((suitsProperty, i)=>(

<View>
<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Fund: {suitsProperty.fundName}</Text>

{suitsProperty!=""&& suitsProperty.suitsPropertyList.map((suitsTenant, i)=>(
<View>
<Text style={{fontSize:"14px",color:"#000000",marginTop:10,marginBottom:5}}>Property: {suitsTenant.propertyName}</Text>
{suitsTenant!=""&& suitsTenant.suitsTenantDTOList.map((suitsDetails, i)=>(

<View>
<Text style={{fontSize:"12",color:"#000000",marginTop:5}}>Tenant: {suitsDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Case Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suits, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(suits.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suits.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>))}

</View>))}
</View>
))}

{noDataAvailableSuitsList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Suits...</Text>}

</View>

<View break>
<Text style={{fontSize:"17",color:"#000000"}}>News Summary</Text>

<View style={styles.box444}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.positiveNewsTenantCount} tenant had positive news alerts, {generateMonthlyPdfReport.negativeNewsTenantCount} tenants had negative news alerts over the last month.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.newsText}</Text>
             </View>      
        </View> 
  <Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Tenants with Positive News Alerts:</Text>

       {showPositiveAlertsTenantList&&<View style={styles.table}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader33Category1PostiveNews}> 
            <Text style={styles.tableCellHeader2}>Name</Text> 
          </View> 
          <View style={styles.tableHeader33CategoryPositiveNews}> 
            <Text style={styles.tableCellHeader2}>Category</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.positiveNewsList!=""&&generateMonthlyPdfReport.positiveNewsList.map((positiveNews, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "45%",borderRight:"1px solid #3B5323"}}> 
<Text style={styles.tableCellHeader11}>{positiveNews.tenantName}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "55%"}}> 
<Text style={styles.tableCellHeader1Category}>{positiveNews.category.join(", ")}</Text> 
</View> 

    </View>))}
</View>
}
{noDataAvailablePositiveAlertsTenantList&&<Text style={{fontSize:"11px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for positive news alerts...</Text>}

<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Tenants with Negative News Alerts:</Text>

{showNegativeAlertsTenantList&&<View style={styles.table}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader33Category1}> 
            <Text style={styles.tableCellHeader2}>Name</Text> 
          </View> 
          <View style={styles.tableHeader33CategoryNegativeNews}> 
            <Text style={styles.tableCellHeader2}>Category</Text> 
          </View> 
    </View>

    {generateMonthlyPdfReport.negativeNewsList!=""&&generateMonthlyPdfReport.negativeNewsList.map((negativeNews, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "40%",borderRight:"1px solid #3B5323"}}> 
<Text style={styles.tableCellHeader11}>{negativeNews.tenantName}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader1Category}>{negativeNews.category.join(", ")}</Text> 
</View> 

    </View>))}
</View>}
{noDataAvailableNegativeAlertsTenantList&&<Text style={{fontSize:"11px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for negative news alerts...</Text>}

</View>

<View style={styles.inlineFooter} fixed>
  <View style={styles.inlineFooter1}>
     <Text >Megalytics®</Text>
  </View>
  <View style={styles.inlineFooter2}>
     <Text style={{paddingLeft:"33px"}}>Copyright © {currentYear} </Text>
  </View>
  <View style={styles.inlineFooter3}>
    <Text style={{paddingLeft:"33px"}}>All Rights Reserved</Text>
  </View>
  <View style={styles.inlineFooter4}>
    <Text style={{paddingLeft:"33px"}}>Proprietary and Confidential</Text>
  </View>
  <View style={styles.inlineFooter5} >
      <Text style={{paddingLeft:"36px"}} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )}  />
</View>
</View>


    </Page>
   
  </Document>
  
  );
 
  
}
  export default PdfMonthlyReport;