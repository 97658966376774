import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Link from '@material-ui/core/Link';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapFundRisk from "components/Charts/HeapMapFundRisk.js";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);







const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const HeapMapRisk1 = ({user,loading,tmTenantsRiskCountByPropertyRequest,propertyId,organizationId,fundId,fundName,propertyName,count,parentCallBack,tmFundRiskCountRequest,fundRiskCount,tenantsRiskCountByProperty,parentCallBackNavigation}) => {
  
  
  const history = useHistory();

  const [input, setInput] = React.useState('');
  const [showHeatMap, setShowHeatMap] = React.useState(true);
  const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
  const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);
  const [fundActive, setFundActive] = React.useState(true);
  const [tenantActive, setTenantActive] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const[showBackNavigation,setShowBackNavigation]=React.useState(false);

  //const [riskList, setRiskList] = React.useState(count);

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };


  const sendDataBack=(range)=>{
  
  parentCallBack(range);
    }


    const backNavigation=(fundId)=>{
  
      parentCallBackNavigation(fundId);

        }
//   const enableTenant = (value) => {
    
//     setShowHeatMapTenant(true);
//     setShowHeatMapFundRisk(false);
//     setTenantActive(true);
//   }
  const handleChange = (value) => {
    setShowHeatMap(false);
    setShowHeatMapFundRisk(true);
    setFundActive(false);
    setTenantActive(false);
  }

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  
//   const history = useHistory();
  const [value, setValue] = React.useState(null);
  const [lowRiskValues,setLowRiskValues] = React.useState([]);
  const [mediumLowRiskValues,setMediumLowRiskValues] = React.useState([]);
  const [mediumHighRiskValues,setMediumHighRiskValues] = React.useState([]);
  const [moderateHighRiskValues,setModerateHighRiskValues] = React.useState([]);
  const [highRiskValues,setHighRiskValues] = React.useState([]);

  const [userId, setUserId] = React.useState(user.id);
  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));




  const location = useLocation();

  React.useEffect(() => {

  
  //  setLowRiskValues(riskList.lowRiskValues);
   getTmPropertyTenantRiskCount(propertyId,userId);
  
    if(loading && tenantsRiskCountByProperty){
       
       setLowRiskValues(tenantsRiskCountByProperty.LOW_RISK);
       setMediumLowRiskValues(tenantsRiskCountByProperty.MEDIUM_LOW_RISK);
       setMediumHighRiskValues(tenantsRiskCountByProperty.MEDIUM_HIGH_RISK);
       setModerateHighRiskValues(tenantsRiskCountByProperty.MODERATE_HIGH_RISK);
       setHighRiskValues(tenantsRiskCountByProperty.HIGH_RISK);
       setPageStatus(true);
    }
   
 
 
  }, [loading]);

  const getTmPropertyTenantRiskCount = async (propertyId,userId) => {
    await  tmTenantsRiskCountByPropertyRequest(propertyId,userId);
    setPageStatus(true);
  
    };
    function handleClickHome(event) {
      // event.preventDefault();

      // history.push({ 
      //   pathname: '/admin/dashboard',
      //   state:{ fundIdNav: fundId,fundNameNav:fundName,backNavigation:true }
      //  });
    
     
       setShowBackNavigation(true);

     }

     function handleClickFundDashboard(event) {
      // event.preventDefault();
      history.back();
      // history.push({ 
      //  pathname: '/dashboard'
      // });
      
     }
     
    function handleClickPropertyDashboard(event) {
      // event.preventDefault();
      history.push({ 
       pathname: '/tenant-risk-count-by-property'
      });
      
     }
 

  const classes = useStyles(); 

if(pageStatus){


  return (
     <div>
     
     <br/>
<br/>

 <Link   style={{color:megaColor}} href="#"  onClick={handleClickHome} className={classes.link}>
        <HomeIcon fontSize="inherit" className={classes.icon} />
        Back1
      </Link>

{!showBackNavigation&&
      <GridContainer >

<br/>
<br/>

{/* <GridItem xs={12} sm={5} md={5}>
         <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Tenant  Risk by Property by Month   
</b></h3>
         </GridItem> */}

<GridItem xs={12} sm={12} md={12}>
         <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Property Risk Score by Month</b></h3>
         </GridItem>


 <GridItem xs={12} sm={12} md={12} style={{paddingTop:'20px',paddingLeft:'30px'}} >
 
<h3> <b><font color="#00628B">{propertyName}</font></b></h3>

</GridItem>
         
<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7> </h7>
           
         
        

</GridItem>
  
  <GridItem  xs={10} sm={10} md={10} >
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="transparent" style={{width:'140px',fontSize:'20px',color:'#00628B',textAlign:'center'}} className={classes.firstButton} onClick={() => sendDataBack()} >
                 <b>{highrisk.monthName} </b>
               </Button>
              
      
    ))}
             </div>
            

 </GridItem>


<GridItem xs={2} sm={2} md={2} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5> <b>Low Risk</b></h5>

</GridItem>
 <GridItem xs={10} sm={10} md={10}  >
            
 
 
 <div className={classes.buttonGroup}>
           
             {pageStatus&&lowRiskValues.map(lowRisk => (
               <Button color="success" style={{width:'140px',fontSize:'20px',color:'#000'}}  className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:401,rangeTo:500,month:lowRisk.monthName})}>

            
                  <b>{lowRisk.riskCount} </b>
               </Button>
              ))} 
      
      
             </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> Medium Low Risk</b></h5>




</GridItem>

<GridItem xs={10} sm={10} md={10}  >
            
    
 
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
            {mediumLowRiskValues.map(mediumLowRisk => (
              <Button style={{width:'140px',color:'#000',backgroundColor:'#32cd32',fontSize:'20px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:301,rangeTo:400,month:mediumLowRisk.monthName,year:2020})}>
                 <b>{mediumLowRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b>Moderate High Risk</b></h5>
            
       

</GridItem>

 <GridItem xs={10} sm={10} md={10}  >
            
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
           
            { moderateHighRiskValues.map(moderateHighRisk => (
              <Button style={{width:'140px',color:'#000',backgroundColor:'#ffdf00',fontSize:'20px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:201,rangeTo:300,month:moderateHighRisk.monthName})}>
                 <b>{moderateHighRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> Medium High Risk</b></h5>
            
          
         

</GridItem>
  
 <GridItem  xs={10} sm={10} md={10} >
             

             <div className={classes.buttonGroup}>
          
             { mediumHighRiskValues.map(mediumHighRisk => (
            
               <Button color="warning" style={{width:'140px',fontSize:'20px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:101,rangeTo:200,month:mediumHighRisk.monthName})} >
                 <b>{mediumHighRisk.riskCount} </b>
               </Button>             
      
    ))}
             </div>
            

 </GridItem>

 <GridItem xs={2} sm={2} md={2} style={{paddingTop:'15px',paddingLeft:'30px',color:'#00628B',textAlign:'right'}} >

<h5><b> High Risk</b></h5>
            
          
         

</GridItem>

  <GridItem  xs={10} sm={10} md={10}>
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="danger" style={{width:'140px',fontSize:'20px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:1,rangeTo:100,month:highrisk.monthName})} >
                 <b>{highrisk.riskCount} </b>
               </Button>
              
      
    ))}
             </div>

 </GridItem>

 



 
      </GridContainer>
      
  }

  {showBackNavigation&&
    <TMHeatMapPropertyFM fundName={fundName} fundId={fundId} />

  }
    
    </div>
  );
}else{
    return(

      <div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}

HeapMapRisk1.propTypes = {
    tmNotificationList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        contifyId: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    tmFundRiskCountRequest: PropTypes.func.isRequired, 
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    fundRiskCount: state.tenantmonitoring.fundRiskCount,
    tenantsRiskCountByProperty: state.tenantmonitoring.tenantsRiskCountByProperty,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HeapMapRisk1));
  
  
