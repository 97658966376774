import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import warningimages from "assets/img/warningimages.png";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  return (
   
    <div className={classes.contentCenter}>
       <Card>
      <GridContainer>
        <GridItem md={12}>
        {/* <img className={classes.card} src={require("/home/angel/projects/tm-web/src/assets/img/warningimages.png")} alt="Card-img" height="160"  /> */}
        {/* <img className={classes.card} src={require("/home/ubuntu/tm-images/warningimages.png")} alt="Card-img" height="160"  /> */}
        <img className={classes.card} src={warningimages} alt="Card-img" height="160"  />
        <h1 className={classes.title}>Oops...</h1>
          <h1 className={classes.subTitle}>Can't reached.Looks like you're lost.</h1>
          {/* <h2 className={classes.subTitle}>Looks like you're lost.</h2> */}
          {/* <h4 className={classes.description}>
            Ooooops! Looks like you got lost.
          </h4> */}
        </GridItem>
      </GridContainer>
      </Card>
    </div>
   
  );
}
