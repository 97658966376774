import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { widgetStories, bugs, website, server } from "variables/general.js";
import { useLocation } from "react-router-dom";
import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { textAlign } from "@material-ui/system";
import { Tooltip } from "@material-ui/core";
import moment from 'moment';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import PropertyDetails from "components/Charts/PropertyDetails";
import { Redirect } from 'react-router-dom';
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: megaColor,
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  gridBorder:{
    border: 1, 
    borderColor: '#D3D3D3',
    borderStyle: 'solid'
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  vertical: { 
              padding:"10px"
             
            } ,
    customWidth: {
      maxWidth: 200,
      fontSize: "15px",
      backgroundColor: megaColor,
    },
h: {
      marginLeft:"0px",
    },
};

const useStyles = makeStyles(styles);

const CreditReport = ({ loading, params,creditScoreData, tmCreditScoreRequest,tmGetCommentaryFromCompanyReportRequest,commentaryFromCompanyReport,tenantPropertyDetails,tmGetTenantPropertyDetailsRequest }) =>{
  const { tenantId,organizationId } = params;
  // const location = useLocation();
  // const[tenantName,setTenantName] =React.useState(location.state.tenantName);
  
  const history = useHistory();
  const location = useLocation();
 
  // const[tenantName,setTenantName] =React.useState(location.state.tenantName);

  const [buttonColor, setButtonColor] = React.useState("success");
  const [buttonGreenColor, setButtonGreenColor] = React.useState("success");
  const [buttonRedColor, setButtonRedColor] = React.useState("danger");


  const [threeToSixMonthsPaymentScoreType, setThreeToSixMonthsPaymentScoreType] = React.useState("THREETOSIX_MONTHS_PAYMENT_BEHAVIOR");
  const [overAllScoreType, setOverAllScoreType] = React.useState("OVERALL");
  const [creditScoreType, setCreditScoreType] = React.useState("CREDIT_SCORE");
  const [trailingDayPaymentScoreType, setTrailingDayPaymentScoreType] = React.useState("NINETY_DAYS_PAYMENT_BEHAVIOR");
  const [delinquencyScoreType, setDelinquencyScoreType] = React.useState("RISK_OF_90+_DAY_DELINQUENCY");
  // const [spendingGrowthIndex, seSpendingGrowthIndex] = React.useState("SPENDING_GROWTH_INDEX");
  const [dataStatus, setDataStatus] = React.useState(false);
 

  const [creditScore, setCreditScore] = React.useState(0);
  const [overAllScore, setOverAllScore] = React.useState(0);
  const [threeToSixMonthsPaymentScore, setThreeToSixMonthsPaymentScore] = React.useState(0);
  const [trailingDayPaymentScore, setTrailingDayPaymentScore] = React.useState(0);
  const [delinquencyScore, setDelinquencyScore] = React.useState(0);
  const [dbtScore, setDbtScore] = React.useState(0);
  const [industryDbtScore, setIndustryDbtScore] = React.useState(0);
  const [spendingGrowthIndex, setSpendingGrowthIndex] = React.useState(0);


   const [threeToSixMonthsPaymentScoreCSS, setThreeToSixMonthsPaymentScoreCSS] = React.useState("");
    const [trailingDayPaymentScoreCSS, setTrailingDayPaymentScoreCSS] = React.useState("");
    const [delinquencyScoreCSS, setDelinquencyScoreCSS] = React.useState("");
    const [dbtScoreCSS, setDbtScoreCSS] = React.useState("");
    const [corporationFilingStatusScoreCSS, setCorporationFilingStatusScoreCSS] = React.useState("");
    const [creditScoreCSS, setCreditScoreCSS] = React.useState("");
    const [spendingGrowthIndexScoreCSS,setSpendingGrowthIndexScoreCSS] = React.useState("");


    const [creditScoreDescription, setCreditScoreDescription] = React.useState("");
    const [overAllScoreDescription, setOverAllScoreDescription] = React.useState("");
    const [threeToSixMonthsPaymentScoreDescription, setThreeToSixMonthsPaymentScoreDescription] = React.useState("");
    const [trailingDayPaymentScoreDescription, setTrailingDayPaymentScoreDescription] = React.useState("");
    const [dbtvsIndustryDbtScoreDescription, setDbtvsIndustryDbtScoreDescription] = React.useState("");
    const [delinquencyScoreDescription, setDelinquencyScoreDescription] = React.useState("");
    const [spendingGrowthIndexScoreDescription, setSpendingGrowthIndexScoreDescription] = React.useState("");
    const [yearsInBusiness, setYearsInBusiness] = React.useState("");

const[year,setYear]=React.useState(moment().format('YYYY'))
    const [month, setMonth] = React.useState(moment().format('MMM').toLowerCase());
    const [commentaryMonth, setCommentaryMonth] = React.useState(moment().subtract(1, "month").format('MMMM').toLowerCase());

    const [month1, setMonth1] = React.useState(moment().subtract(1, "month").format('MMMM').toUpperCase());
    const [month2, setMonth2] = React.useState(moment().subtract(1, "month").format('MMM').toLowerCase());

    
    const [prev1Year, setPrev1Year] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());
    const [tenantAddress, setTenantAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");

const[showSpendingGrowthIndex,setShowSpendingGrowthIndex] = React.useState(false);
const [currCreditOverallStatus,setCurrCreditOverallStatus] = React.useState("");

const[showPropertyDetails,setShowPropertyDetails] = React.useState(false);
const[showBackNavigation,setShowBackNavigation]=React.useState(false);
const[showCredit,setShowCredit] = React.useState(true);
const [tenantName,setTenantName] = React.useState(location.state.tenantName);
const[propertyName,setPropertyName]=React.useState("");
const[selectedFundId,setSelectedFundId]=React.useState("");
const[selectedFundName,setSelectedFundName]=React.useState("");
const[propertyId,setPropertyId]=React.useState();
const[imageUrl,setImageUrl]=React.useState("");
const[currentStatus,setCurrentStatus]=React.useState("");
const[colorCode,setColorCode]=React.useState("");
const[propertyAddress,setPropertyAddress]=React.useState("");
const[score,setScore]=React.useState("");
const[userId,setUserId]=React.useState("");
const[tenantId1,setTenantId1]=React.useState("");
const[showCreditReport,setShowCreditReport]=React.useState(true);

const [industryBenchMarkScore,setIndustryBenchMarkScore] = React.useState("");
const [dbtIndustryDbt,setDbtvsIndustryDbt] = React.useState(true);
const [dbtIndustryDbtMinusOne,setDbtvsIndustryDbtMinusOne] = React.useState(false);
const [noOfTradeLines, setNoOfTradeLines] = React.useState("");
const [showNoOfTradeLines,setShowNoOfTradeLines] = React.useState(true);
const [showNoOfTradeLinesIfCRNotAvailable,setShowNoOfTradeLinesIfCRNotAvailable] = React.useState(false);


// const html = `<html>
// <body>
//   <style>
//     .my-heading4 {
//       background: darkgreen;
//       color: white;
//     }
//     pre {
//       background-color: #eee;
//       padding: 10px;
//     }
//   </style>
//   <h1>Heading 1</h1>
//   <h2 style="background-color: pink">Heading 2</h2>
//   <h3>Heading 3</h3>
//   <h4 class="my-heading4">Heading 4</h4>
//   <p>
//     Paragraph with <strong>bold</strong>, <i>italic</i>, <u>underline</u>,
//     <s>strikethrough</s>,
//     <strong><u><s><i>and all of the above</i></s></u></strong>
//   </p>
//   <p>
//     Paragraph with image and
//     <a href="http://google.com">link</a>
//   </p>
//   <hr />
//   <ul>
//     <li>Unordered item</li>
//     <li>Unordered item</li>
//   </ul>
//   <ol>
//     <li>Ordered item</li>
//     <li>Ordered item</li>
//   </ol>
//   <br /><br /><br /><br /><br />
//   Text outside of any tags
//   <table>
//     <thead>
//       <tr>
//         <th>Column 1</th>
//         <th>Column 2</th>
//         <th>Column 3</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>Foo</td>
//         <td>Bar</td>
//         <td>Foobar</td>
//       </tr>
//       <tr>
//         <td colspan="2">Foo</td>
//         <td>Bar</td>
//       </tr>
//       <tr>
//         <td>Some longer thing</td>
//         <td>Even more content than before!</td>
//         <td>Even more content than before!</td>
//       </tr>
//     </tbody>
//   </table>
//   <div style="width: 200px; height: 200px; background: pink"></div>
//   <pre>
// function myCode() {
// const foo = 'bar';
// }
// </pre>
// </body>
// </html>
// `
// ;

// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles1.page}>
//       <View style={styles1.section}>
//         <Text>{html}</Text>
//       </View>
//       <View style={styles1.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

  React.useEffect(() => {

    setShowSpendingGrowthIndex(false);
    
    if(!dataStatus){
      getTenantPropertyDetails(tenantId,month2,year);
      getTmCreditScoreRequest(tenantId);
      getYearsInBusinessCommentary(tenantId,month2,prev1Year);
    
      
    }
  
else{



    // setCreditScore(creditScoreData.creditScore);
    setOverAllScore(creditScoreData.overAllScore);
    setThreeToSixMonthsPaymentScore(creditScoreData.threeToSixMonthsPaymentScore);
    setTrailingDayPaymentScore(creditScoreData.trailingDayPaymentScore);
    setDbtScore(creditScoreData.dbtScore);
    setIndustryDbtScore(creditScoreData.industryDbtScore);

    setDelinquencyScore(creditScoreData.delinquencyScore);
         //angel
    setTrailingDayPaymentScoreCSS(creditScoreData.trailingNinetyDaysCss);
    setTrailingDayPaymentScoreDescription(creditScoreData.trailingNinetyDaysCommentary);

    setDelinquencyScoreCSS(creditScoreData.delinquencyCss);
    setDelinquencyScoreDescription(creditScoreData.delinquencyCommentary);

    setSpendingGrowthIndex(creditScoreData.spendingGrowthIndex);
    setSpendingGrowthIndexScoreCSS(creditScoreData.spendinGrowthCss);
    setSpendingGrowthIndexScoreDescription(creditScoreData.spendingGrowthIndexScoreDescription);

    
    setThreeToSixMonthsPaymentScoreCSS(creditScoreData.threeToSixPaymentCss);
    setThreeToSixMonthsPaymentScoreDescription(creditScoreData.threeToSixPaymentCommentary);
    setCreditScoreCSS(creditScoreData.creditCss);
    setDbtvsIndustryDbtScoreDescription(creditScoreData.industryBenchMarkCommentary);
    setCreditScoreDescription(creditScoreData.creditCommentary);
    setYearsInBusiness(creditScoreData.yearsInBusiness);
    
    setTenantAddress(tenantPropertyDetails.tenantAddress);
    setCity(tenantPropertyDetails.tenantCity);
    setState(tenantPropertyDetails.tenantState);
    setZip(tenantPropertyDetails.tenantZip);
    setPropertyName(tenantPropertyDetails.propertyName);
    setImageUrl(tenantPropertyDetails.imageUrl);
    setPropertyAddress(tenantPropertyDetails.propertyAddress);
    setCurrentStatus(tenantPropertyDetails.currentStatus);
    setScore(tenantPropertyDetails.propertyScore);
    setUserId(tenantPropertyDetails.userId);
    setPropertyId(tenantPropertyDetails.propertyId);
    setSelectedFundId(tenantPropertyDetails.fundId);
    setSelectedFundName(tenantPropertyDetails.fundName);
    setColorCode(tenantPropertyDetails.colorCode);
    setTenantId1(tenantPropertyDetails.tenantId);
    
    
    setCurrCreditOverallStatus(creditScoreData.currCreditOverallStatus);
    setNoOfTradeLines(creditScoreData.noOfTradeLines);

// console.log("spendinGrowthCss"+creditScoreData.spendinGrowthCss);

// if(creditScoreData.spendingGrowthIndex==0 || creditScoreData.spendingGrowthIndex=="" || creditScoreData.spendingGrowthIndex==null ){
//   setShowSpendingGrowthIndex(false);
//   setSpendingGrowthIndex("NA");
//   setSpendingGrowthIndexScoreCSS("#D3D3D3");
// }

// if(creditScoreData.delinquencyScore==0 || creditScoreData.delinquencyScore=="" || creditScoreData.delinquencyScore==null ){
//     setDelinquencyScore("NA");
//     setDelinquencyScoreCSS("#D3D3D3");
// }

if(creditScoreData.noOfTradeLines>=0){
  setShowNoOfTradeLines(true);
  setShowNoOfTradeLinesIfCRNotAvailable(false);

}
if(creditScoreData.noOfTradeLines==-1){
  setShowNoOfTradeLines(false);
  setShowNoOfTradeLinesIfCRNotAvailable(true);
  
}
if(creditScoreData.spendingGrowthIndex>0 || creditScoreData.spendingGrowthIndex>0){
  setShowSpendingGrowthIndex(true);
}

if(creditScoreData.creditScore==0 || creditScoreData.creditScore=="" || creditScoreData.creditScore==null || creditScoreData.noApiDataAvailableCredit==false){
  setCreditScore("NA");
  // setCreditScoreCSS("#D3D3D3");
}

if(creditScoreData.overAllScore==0 || creditScoreData.overAllScore=="" || creditScoreData.overAllScore==null ){
  setOverAllScore("NA");
}

if(creditScoreData.threeToSixMonthsPaymentScore==0 || creditScoreData.threeToSixMonthsPaymentScore=="" || creditScoreData.threeToSixMonthsPaymentScore==null || creditScoreData.noApiDataAvailableThreeToSix==false){
  setThreeToSixMonthsPaymentScore("NA");
  setThreeToSixMonthsPaymentScoreDescription("No Data Available");
  setThreeToSixMonthsPaymentScoreCSS("#D3D3D3");
}

if(creditScoreData.trailingDayPaymentScore==0 || creditScoreData.trailingDayPaymentScore=="" || creditScoreData.trailingDayPaymentScore==null || creditScoreData.noApiDataAvailableTrailingDay==false){
setTrailingDayPaymentScore("NA");
setTrailingDayPaymentScoreDescription("No Data Available");
// setTrailingDayPaymentScoreCSS("#D3D3D3");
}
    
if(creditScoreData.dbtScore >=0){
    setDbtvsIndustryDbt(true);
  setDbtvsIndustryDbtMinusOne(false);
}

if(creditScoreData.dbtScore==-1){
  // setDbtScore("NA");
  setDbtvsIndustryDbt(false);
  setDbtvsIndustryDbtMinusOne(true);
  setIndustryBenchMarkScore("NA");
  setDbtvsIndustryDbtScoreDescription("No Data Available");
}

if(creditScoreData.dbtScore>=0 && creditScoreData.industryDbtScore==-1){
  // setDbtScore("NA");
  setDbtvsIndustryDbt(true);
  setDbtvsIndustryDbtMinusOne(false);
  setIndustryDbtScore("NA");
}

// if(creditScoreData.dbtScore==-1 || creditScoreData.dbtScore=="" || creditScoreData.dbtScore==null){
//   setDbtScore("0");
// }
// // if(creditScoreData.dbtScore==0){
// //   setDbtScore("0");
// // }

// if(creditScoreData.industryDbtScore==-1 || creditScoreData.industryDbtScore=="" || creditScoreData.industryDbtScore==null ){
//   setIndustryDbtScore("0");
// }
// if(creditScoreData.industryDbtScore==0 ){
//   setIndustryDbtScore("0");
// }
// if(creditScoreData.dbtScore==-1 && creditScoreData.industryDbtScore==-1){
//   setDbtvsIndustryDbtScoreDescription("No Data Available.")
// }
    //setCreditScoreDescription(creditScoreData.industryBenchMarkCommentary);
}

    
    // if(threeToSixMonthsPaymentScore > 401 && threeToSixMonthsPaymentScore <= 500){
    //   setThreeToSixMonthsPaymentScoreCSS("#4caf50")
    //  }else if(threeToSixMonthsPaymentScore > 301 && threeToSixMonthsPaymentScore < 400){
    //   setThreeToSixMonthsPaymentScoreCSS("#32cd32")
    //  }else if(threeToSixMonthsPaymentScore > 201 && threeToSixMonthsPaymentScore <= 300){
    //   setThreeToSixMonthsPaymentScoreCSS("#ffdf00")
    //  }else if(threeToSixMonthsPaymentScore > 101 && threeToSixMonthsPaymentScore <= 200){
    //   setThreeToSixMonthsPaymentScoreCSS("#ff9800")
    // }else if(threeToSixMonthsPaymentScore => 0 && threeToSixMonthsPaymentScore <= 100){
    //   setThreeToSixMonthsPaymentScoreCSS("#FF0000")
    // }


    // if(trailingDayPaymentScore > 401 && trailingDayPaymentScore <= 500){
    //   setTrailingDayPaymentScoreCSS("#4caf50")
    //  }else if(trailingDayPaymentScore > 301 && trailingDayPaymentScore < 400){
    //   setTrailingDayPaymentScoreCSS("#32cd32")
    //  }else if(trailingDayPaymentScore > 201 && trailingDayPaymentScore <= 300){
    //   setTrailingDayPaymentScoreCSS("#ffdf00")
    //  }else if(trailingDayPaymentScore > 101 && trailingDayPaymentScore <= 200){
    //   setTrailingDayPaymentScoreCSS("#ff9800")
    // }else if(trailingDayPaymentScore => 0 && trailingDayPaymentScore <= 100){
    //   setTrailingDayPaymentScoreCSS("#FF0000")
    // }


    // if(creditScore > 401 && creditScore <= 500){
    //   setCreditScoreCSS("#4caf50")
    //  }else if(creditScore > 301 && creditScore < 400){
    //   setCreditScoreCSS("#32cd32")
    //  }else if(creditScore > 201 && creditScore <= 300){
    //   setCreditScoreCSS("#ffdf00")
    //  }else if(creditScore > 101 && creditScore <= 200){
    //   setCreditScoreCSS("#ff9800")
    // }else if(creditScore => 0 && creditScore <= 100){
    //   setCreditScoreCSS("#FF0000")
    // }

    // if(threeToSixMonthsPaymentScore > 401 && threeToSixMonthsPaymentScore <= 500){
    //   setThreeToSixMonthsPaymentScoreDescription("This company's payment trend is increasingly downward over the last 3-6 months.")
    //  }else if(threeToSixMonthsPaymentScore > 301 && threeToSixMonthsPaymentScore < 400){
    //   setThreeToSixMonthsPaymentScoreDescription("This company's payment trend is slightly downward over the last 3-6 months.")
    //  }else if(threeToSixMonthsPaymentScore > 201 && threeToSixMonthsPaymentScore <= 300){
    //   setThreeToSixMonthsPaymentScoreDescription("This company's payment trend is consistent with previous payment times over the last 3-6 months.")
    //  }else if(threeToSixMonthsPaymentScore > 101 && threeToSixMonthsPaymentScore <= 200){
    //   setThreeToSixMonthsPaymentScoreDescription("This company's payment trend has increased slightly upward over the last 3-6 months.")
    // }else if(threeToSixMonthsPaymentScore => 0 && threeToSixMonthsPaymentScore <= 100){
    //   setThreeToSixMonthsPaymentScoreDescription("This company's payment trend is increasingly longer and critical over the last 3-6 months.")
    // }


    // if(trailingDayPaymentScore > 401 && trailingDayPaymentScore <= 500){
    //   setTrailingDayPaymentScoreDescription("This company's payment trend is increasingly downward over the last 3 months ")
    //  }else if(trailingDayPaymentScore > 301 && trailingDayPaymentScore < 400){
    //   setTrailingDayPaymentScoreDescription("This company's payment trend is slightly downward over the last 3 months ")
    //  }else if(trailingDayPaymentScore > 201 && trailingDayPaymentScore <= 300){
    //   setTrailingDayPaymentScoreDescription("This company's payment trend is consistent with previous payment times over the last 3 months")
    //  }else if(trailingDayPaymentScore > 101 && trailingDayPaymentScore <= 200){
    //   setTrailingDayPaymentScoreDescription("This company's payment trend has increased slightly upward over the last 3 months")
    // }else if(trailingDayPaymentScore => 0 && trailingDayPaymentScore <= 100){
    //   setTrailingDayPaymentScoreDescription("This company's payment trend is increasingly longer and critical over the last 3 months")
    // }


    // if(creditScore > 401 && creditScore <= 500){
    //   setCreditScoreDescription("Based on payment  history  the company has  a low risk of default in the next 12 months")
    //  }else if(creditScore > 301 && creditScore < 400){
    //   setCreditScoreDescription("Based on payment  history  the company has  a lower risk of default in the next 12 months")
    //  }else if(creditScore > 201 && creditScore <= 300){
    //   setCreditScoreDescription("Based on the payment history, the company has a moderate risk of default in the next 12 months")
    //  }else if(creditScore > 101 && creditScore <= 200){
    //   setCreditScoreDescription("Based on payment  history  the company has  a slightly higher  risk of default in the next 12 months")
    // }else if(creditScore => 0 && creditScore <= 100){
    //   setCreditScoreDescription("Based on payment  history  the company has  a high risk of default in the next 12 months")
    // }

    // if(dbtScore<industryDbtScore){
    //   setDbtvsIndustryDbtScoreDescription("This company pays faster  than industry peers ‘based on dbt vs industry’");
    // }else{
    //   setDbtvsIndustryDbtScoreDescription("This company pays slower  than industry peers ‘based on dbt vs industry’");
    // }
   
  
  setTimeout(() => {
    setDataStatus(true);
  }, 3000);

  }, [dataStatus,creditScoreData,commentaryFromCompanyReport]);

  const getTmCreditScoreRequest = async (tenantId) => {
       
    await tmCreditScoreRequest(tenantId);
  
 
    setCreditScore(creditScoreData.creditScore);
    setOverAllScore(creditScoreData.overAllScore);
    setThreeToSixMonthsPaymentScore(creditScoreData.threeToSixMonthsPaymentScore);
    setTrailingDayPaymentScore(creditScoreData.trailingDayPaymentScore);
    setDbtScore(creditScoreData.dbtScore);
    setIndustryDbtScore(creditScoreData.industryDbtScore);
    setDelinquencyScore(creditScoreData.delinquencyScore);
    setSpendingGrowthIndex(creditScoreData.spendingGrowthIndex);
    setCurrCreditOverallStatus(creditScoreData.currCreditOverallStatus);
    // setDataStatus(true);
    };

    const getYearsInBusinessCommentary=async (tenantId,month2,year) => {
      await tmGetCommentaryFromCompanyReportRequest(tenantId,month2,year);
      console.log("commentaryFromCompanyReport"+JSON.stringify(commentaryFromCompanyReport[0]));
      // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    }
    const getTenantPropertyDetails=async (tenantId,month,year) => {
      await tmGetTenantPropertyDetailsRequest(tenantId,month,year);
      console.log("tenantPropertyDetails"+JSON.stringify(tenantPropertyDetails));
      // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    }

    // function handleClickPropertyDetails(event) {
    //   setShowPropertyDetails(true);
    //   // history.push({ 
    //   //   pathname: '/property-details',
    //   //   state:{tenantId:tenantId}
    //   //  });
    // }
    const enableProperty = () => {
      
      // setSelectedFundId(value.fundId); 
      // setSelectedFundName(value.fundName);
      // setPropertyId(value.propertyId);
      // setPropertyName(value.propertyName);
      // setScore(value.score);
      // setPropertyAddress(value.propertyAddress);
      // setCurrentStatus(value.currentStatus);
      // setColorCode(value.colorCode);
      // setImageUrl(value.imageUrl);
      // alert("imageUrl"+selectedFundId);
      // alert("imageUrl"+selectedFundName);
      // alert("imageUrl"+propertyName);
      // alert("imageUrl"+propertyId);
      // alert("imageUrl"+propertyAddress);
      // alert("imageUrl"+score);
      // alert("imageUrl"+currentStatus);
      // alert("imageUrl"+colorCode);
      // alert("imageUrl"+userId);
      // alert("imageUrl"+imageUrl);

      history.push({ 
        pathname: '/admin/monitored-tenants-property-details',
        state:{fundId:selectedFundId,fundName:selectedFundName,propertyName:propertyName,propertyId:propertyId,propertyAddress:propertyAddress,score:score,currentStatus:currentStatus,colorCode:colorCode,imageUrl:imageUrl,userId:userId}
       });
      // <Redirect to="/property-details"/>
    
    }
    const enableTenant = () => {
      
      history.push({ 
        pathname: '/admin/monitored-tenant-risk',
        state:{tenantId:tenantId,userId:userId,tenantName:tenantName,city:city,state:state,zip:zip,tenantAddress:tenantAddress}
       });
      // <Redirect to="/property-details"/>
    
    }
    
  const classes = useStyles();
  if(dataStatus){
  return (
    <div>
   
{/* <Link className={classes.gradient} style={{cursor:"pointer"}} onClick={handleClickPropertyDetails}> &nbsp;Back</Link> */}

<Tooltip classes={{ tooltip: classes.customWidth }} title=" Click here to go to Dashboard" >
<h3 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px",cursor:'pointer'}} onClick={enableTenant}>Tenant Name: {tenantName}</h3>
</Tooltip>
<h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px" }}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>
{/* <h4 style={{color: megaColor, fontWeight:'bold',fontSize:"18px",cursor:'pointer'}} onClick={() => enableProperty({fundId:selectedFundId,fundName:selectedFundName,propertyName:propertyName,propertyId:propertyId,propertyAddress:propertyAddress,score:score,currentStatus:currentStatus,colorCode:colorCode,imageUrl:imageUrl})}><b>Property Name:&nbsp;{propertyName}</b></h4> */}
<h4 style={{color: megaColor, fontWeight:'bold',fontSize:"18px",cursor:'pointer'}} onClick={enableProperty}><b>Property Name:&nbsp;{propertyName}</b></h4>


 <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px" }}>Risk of 90+ Day Delinquency - [{month1}&nbsp;{prev1Year}]</h4>

  


     <GridContainer className={classes.gridBorder}>

    
        
<GridItem xs={12} sm={4} md={4} className={classes.vertical} justify = "center"> 
<Tooltip classes={{ tooltip: classes.customWidth }} title="Predicts the likelihood that company  will become severely delinquent (90 days plus) within the next 12 months or that the business will go bankrupt.">
<Button round style={{ color:"#000000",backgroundColor: creditScoreCSS,fontSize:'20px'}}  > &nbsp;&nbsp;&nbsp;&nbsp; <b>{creditScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
</Tooltip>
<br></br>
<b>  {creditScoreDescription}</b>
</GridItem>

       

<GridItem xs={12} sm={8} md={8} className={classes.vertical} style={{paddingLeft:'230px'}}justify = "right">
                
                <div className={classes.buttonGroup} style={{paddingLeft:'11px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'#4caf50'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
  </GridItem>


</GridContainer>

        {showNoOfTradeLines&&<h5 style={{ color: megaColor, fontWeight:'bold' }}>Payment Trends&nbsp;<b style={{fontSize:"13px",color:"#000000"}}>(&nbsp;Based on {noOfTradeLines} Trade Lines&nbsp;)</b></h5>}
         {showNoOfTradeLinesIfCRNotAvailable&& <h5 style={{ color: megaColor, fontWeight:'bold' }}>Payment Trends</h5>}

      <GridContainer>
<GridItem xs={12} sm={4} md={4} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}}>
        
      <b style={{color:megaColor,paddingTop:'18px'}}> Trailing 90 Days payment behaviour</b>
        </GridItem>


         <GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}} justify = "center">
        
        <Button round  style={{ color:"#000000",backgroundColor: trailingDayPaymentScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{trailingDayPaymentScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
      
        </GridItem>
        {/* <GridItem xs={12} sm={1} md={1}>
        
        <Divider orientation="vertical"/>
       
    </GridItem> */}
  
   
        <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
           
      <b>  {trailingDayPaymentScoreDescription}</b>

   
  </GridItem>





  <GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}} justify = "center">
  <b style={{color:megaColor}}> 3-6 Months Payment Trends   </b>   
                  
          </GridItem>
  
        
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}}  justify = "center">
          
  <Button round  style={{color:"#000000",backgroundColor: threeToSixMonthsPaymentScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{threeToSixMonthsPaymentScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
         

     
          <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b>{threeToSixMonthsPaymentScoreDescription}</b>

     
    </GridItem>
  


    <GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}} justify = "center">
    <b style={{color:megaColor}} > Industry Benchmark </b>  
             
          </GridItem>
  
  
          {dbtIndustryDbt&& <GridItem xs={12} sm={3} md={3} style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:'center'}} >
          
          <font style={{color:'#4caf50',fontSize:'20px'}}><b>{dbtScore} DBT </b></font><b>vs</b>  <font style={{color:'#4caf50',fontSize:'20px'}}><b>{industryDbtScore} DBT</b></font>
        
          </GridItem>}

          {dbtIndustryDbtMinusOne&&<GridItem xs={12} sm={3} md={3} style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:'center'}} >
          
          <font style={{color:'#4caf50',fontSize:'20px'}}><b>{industryBenchMarkScore} </b></font>
        
          </GridItem>}
    
     
          <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b> {dbtvsIndustryDbtScoreDescription}</b>
     
    </GridItem>


    {showSpendingGrowthIndex&&<GridItem xs={12} sm={4} md={4} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}} justify = "center">
  <b style={{color:megaColor}}> Spending Growth Index</b>   
                  
          </GridItem>}
  
        
  
          {showSpendingGrowthIndex&&<GridItem xs={12} sm={3} md={3} className={classes.vertical} style={{ border: '1px solid #D3D3D3',textAlign:'center'}}  justify = "center">
          
  <Button round  style={{color:"#000000",backgroundColor: spendingGrowthIndexScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{spendingGrowthIndex} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>}
        
         

     
          {showSpendingGrowthIndex&& <GridItem xs={12} sm={5} md={5} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'20px'}}>
  <b>{spendingGrowthIndexScoreDescription}</b>

     
    </GridItem> }
     
        
      </GridContainer>

      {/* {commentaryFromCompanyReport[0].map(search => ( */}
     <GridItem xs={12} sm={12} md={12} className={classes.vertical} style={{paddingTop:'15px'}}>
 <h4 style={{fontSize:"15px",fontWeight:"bold"}}>*{commentaryFromCompanyReport}</h4>
     
    </GridItem>

    {/* {showPropertyDetails&& <PropertyDetails fundId={selectedFundId} fundName={selectedFundName} propertyId={propertyId} propertyName={propertyName} colorCode={colorCode} currentStatus={currentStatus} score={score} propertyId={propertyId} imageUrl={imageUrl}/>} */}
  
       {/* ))}  */}
    </div>
    
  );
}else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
}


CreditReport.propTypes = {


  loading: PropTypes.bool.isRequired,
  getTmCreditScoreRequest: PropTypes.func.isRequired,
  tmGetCommentaryFromCompanyReportRequest: PropTypes.func.isRequired,
  tmGetTenantPropertyDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmNotificationList: state.tenantmonitoring.tmNotificationList,
  pirsData:state.tenantmonitoring.pirsData,
  creditScoreData:state.tenantmonitoring.creditScoreData,
  commentaryFromCompanyReport:state.tenantmonitoring.commentaryFromCompanyReport,
  tenantPropertyDetails:state.tenantmonitoring.tenantPropertyDetails,
  
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditReport);

