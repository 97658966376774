import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfOne from './PdfOne';
import PdfReport from './PdfReport';
import ReactPDF from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { set } from "date-fns";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import PdfMonthlyReport from "./PdfMonthlyReport";
import { Link } from 'react-router-dom';
import MonthlyReport from "./ReportLatest.pdf";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";

const styles = {

  app: {
    textAlign: "center"
  },
  downloadLink: {
    fontSize: "large",
    fontWeight: "bold",
    textAlign:"center",
    paddingLeft:"686px"
  }
  
};
const useStyles = makeStyles(styles);

const PdfMonthlyReportDownload = ({user,tmGenerateMonthlyPdfReportRequest,generateMonthlyPdfReport,tmGenerateMonthlyFundDetailsPdfReportRequest,generateMonthlyFundDetailsPdfReport}) =>{


  const location = useLocation();
  const [dataStatus, setDataStatus] = React.useState(false);
  const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
  // const [organisationId, setOrganisationId] = React.useState(user.organizationId);
const[userId,setUserId]=React.useState(user.id);
  // const [tenantId, setTenantId] = React.useState(location.state.tenantId);
  // const [tenantName, setTenantName] = React.useState(location.state.tenantName);
  const [organisationName, setOrganisationName] = React.useState(location.state.organisationName);
  const [companyAddress, setCompanyAddress] = React.useState("");
  const [legalName, setLegalName] = React.useState("");
  const [legalFormOfEntity, setLegalFormOfEntity] = React.useState("");
  const [entityType, setEntityType] = React.useState("");
  const [primaryNaicsCode, setPrimaryNaicsCode] = React.useState("");
  const [noOfLocations, setNoOfLocations] = React.useState("");
  const [yearStarted, setYearStarted] = React.useState("");
  const [primaryIndustry, setPrimaryIndustry] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [OFAC, setOFAC] = React.useState("");
  const [legalEntityName, setLegalEntityName] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [corporationFilingName, setCorporationFilingName] = React.useState("");
  const [nameType, setNameType] = React.useState("");
  const [corporationFilingAddress, setCorporationFilingAddress] = React.useState("");
  const [corporationFilingAddressType, setCorporationFilingAddressType] = React.useState("");
  const [corporationFilingStatus, setCorporationFilingStatus] = React.useState("");
  const [filingDate, setFilingDate] = React.useState("");
 
  const [incorporationState, setIncorporationState] = React.useState("");
  const [incorporationDate, setIncorporationDate] = React.useState("");
  const [registeredAgentAddress, setRegisteredAgentAddress] = React.useState("");
  const [registrationType, setRegistrationType] = React.useState("");
  const [creditScore, setCreditScore] = React.useState("");
  const [trailingDayScore, setTrailingDayScore] = React.useState("");
  const [threeToSixmonthsPaymentScore, setThreeToSixmonthsPaymentScore] = React.useState("");
  const [dbtScore, setDbtScore] = React.useState("");
  const [industryDbtScore, setIndustryDbtScore] = React.useState("");
  const [trailingDayCommentary, setTrailingDayCommentary] = React.useState("");
  const [threeToSixmonthsPaymentCommentary, setThreeToSixmonthsPaymentCommentary] = React.useState("");
  const [industryBenchmarkCommentary, setIndustryBenchmarkCommentary] = React.useState("");
  const [trailingDayColorCode, setTrailingDayColorCode] = React.useState("");
  const [threeToSixmonthsPaymentColorCode, setThreeToSixmonthsPaymentColorCode] = React.useState("");
  const [creditScoreColorCode, setCreditScoreColorCode] = React.useState("");
  const [yearsInBusinessCommentary, setYearsInBusinessCommentary] = React.useState("");


  const [derogScore, setDerogScore] = React.useState("");
  const [derogCommentary, setDerogCommentary] = React.useState("");
  const [derogColorCode, setDerogColorCode] = React.useState("");
  const [cautionaryUccScore, setCautionaryUccScore] = React.useState("");
  const [cautionaryUccCommentary, setCautionaryUccCommentary] = React.useState("");
  const [cautionaryUccColorCode, setCautionaryUccColorCode] = React.useState("");
  const [taxLiensAndJudgementScore, setTaxLiensAndJudgementScore] = React.useState("");
  const [taxLiensAndJudgementCommentary, setTaxLiensAndJudgementCommentary] = React.useState("");
  const [taxLiensAndJudgementColorCode, setTaxLiensAndJudgementColorCode] = React.useState(""); 
  const [civilSuitsScore, setCivilSuitsScore] = React.useState("");
  const [civilSuitsCommentary, setCivilSuitsCommentary] = React.useState("");
  const [civilSuitsColorCode, setCivilSuitsColorCode] = React.useState("");
  const [corporationFilingScore, setCorporationFilingScore] = React.useState("");
  const [corporationFilingCommentary, setCorporationFilingCommentary] = React.useState("");
  const [corporationFilingScoreColorCode, setCorporationFilingScoreColorCode] = React.useState("");
 
  const [taxLienDetailList, seTaxLienDetailList] = React.useState([]);
  const [judgementDetailList, setJudgementDetailList] = React.useState([]);
  const [suitsList, setSuitsList] = React.useState("");
  const [cautionaryUccDetailsList, setCautionaryUccDetailsList] = React.useState([]);
  const [liensCount, setLiensCount] = React.useState("");
  const [judgmentCount, setJudgmentCount] = React.useState("");
  const [liensPlusJudgmentCount, setLiensPlusJudgmentCount] = React.useState("");
  const [suitsCount, setSuitsCount] = React.useState("");
  const [taxLiens, setTaxLiens] = React.useState("");
  const [judgment, setJudgment] = React.useState("");
  const [suits, setSuits] = React.useState("");
  const [uccCount, setUccCount] = React.useState("");
  const [totLiensAndJudgment, setTotLiensAndJudgment] = React.useState("");

 
  React.useEffect(() => {
   
    // if(!dataStatus){

      getMonthlyPdfReports(organisationId);

  setTimeout(() => {
    setDataStatus(true);
  }, 3000);

 
    }, [dataStatus]);

    const getMonthlyPdfReports = async (organisationId) => {
      await tmGenerateMonthlyPdfReportRequest(organisationId);
      };

      const classes = useStyles();

      if(dataStatus){
    return(

      <div className={classes.app}>

<div className={classes.downloadLink}>

{/* <a href={MonthlyReport} target="_blank" 
                        rel="noreferrer" >
               Print</a> */}
        <PDFDownloadLink
          document={<PdfMonthlyReport generateMonthlyPdfReport={generateMonthlyPdfReport} />}
   
          fileName={organisationName  + ".pdf"}
        >
          {/* {({ blob, url, loading, error }) =>
            loading ? "Loading..." :   <Button color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
            <PrintIcon/>  &nbsp;&nbsp;Print  
            </Button>
          } */}

<Button color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
            <PrintIcon/>  &nbsp;&nbsp;Print  
            </Button>
        </PDFDownloadLink>
      </div>

      <div>
            <div style={{margin: "auto",fontSize:"2rem", color:"#000",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={30000} //3 secs
      />
            </div>
            <PDFViewer showToolbar={false} width={800} height={820} style={{border:"none",backgroundColor:"transparent"}} >
            <PdfMonthlyReport generateMonthlyPdfReport={generateMonthlyPdfReport} generateMonthlyFundDetailsPdfReport={generateMonthlyFundDetailsPdfReport} organisationId={organisationId}/>
            </PDFViewer>
        </div>
      {/* <PDFViewer width={800} height={820} showToolbar={false}>
      <PdfMonthlyReport generateMonthlyPdfReport={generateMonthlyPdfReport} generateMonthlyFundDetailsPdfReport={generateMonthlyFundDetailsPdfReport}/>

      </PDFViewer> */}

    
    </div>

);
    }else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={30000} //3 secs
      /></div>
      )
}
    }

PdfMonthlyReportDownload.propTypes = {

      loading: PropTypes.bool.isRequired,
      tmGenerateMonthlyPdfReportRequest: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    };
    
    const mapStateToProps = state => ({
      user: state.auth.loggedUser,
      generateMonthlyPdfReport: state.tenantmonitoring.generateMonthlyPdfReport,
 
    });              
    
    const mapDispatchToProps = dispatch => bindActionCreators(
      {
    
        ...TMActions,
      },
      dispatch,
    );
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps,
    )(PdfMonthlyReportDownload);