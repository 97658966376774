
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
//import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";



const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },

];




const Productbrand = ({}) => {

  const [input, setInput] = React.useState('');

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

//   //email validation
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");

const [requiredcomment, setrequiredComment] = React.useState("");
const [requiredStateComment, setrequiredStateComment] = React.useState("");

const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");

const [fundName, setFundName] = React.useState("");




  
  const handleChange = (value) => {
    // alert(value);
  }

  const submitForm = () => {


  };

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};


const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("error");
    }
    if (requiredStateComment === "") {
        setrequiredStateComment("error");
      }
     
  };



  const [value, setValue] = React.useState(null);


  // function that returns true if value is email, false otherwise
// const verifyEmail = value => {
//   var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (emailRex.test(value)) {
//     return true;
//   }
//   return false;
// };

// const typeClick = () => {
 
//   if (typeEmailState === "") {
//     settypeEmailState("error");
//   }
  
// };

  const classes = useStyles(); 

  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
        <CardHeader style={{ background: '#00628B', color:'white' }} icon>
            {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3>
             <b>  Product Brand</b>
              </h3> 
            </CardHeader>
            <CardBody>

           
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText="Brand Name"
                    id="brandname"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                         //setFundName(event.target.value);
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                

              </GridContainer>

              <GridContainer>
                
                <GridItem xs={12} sm={12} md={6}>
<label> Description:   </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<TextField
         id="outlined-multiline-flexible"
         success={requiredStateComment === "success"}
        error={requiredStateComment === "error"}
         label=""
         multiline
         rowsMax={4}
         value={value}
         // onChange={handleChange}
         variant="outlined"



         inputProps={{
           onChange: event => {
             if (verifyLength(event.target.value, 1)) {
               //setComments(event.target.value);
               setrequiredStateComment("success");
             } else {
               setrequiredStateComment("error");
             }
             setrequiredComment(event.target.value);
           },
           type: "text",
           endAdornment:
             requiredStateComment === "error" ? (
               <InputAdornment position="end">
                 <Close className={classes.danger} />
               </InputAdornment>
             ) : (
               undefined
             )
         }}
       />
       
               </GridItem>        
       </GridContainer>
              
                 
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Active"



          inputProps={{
            onChange: event => {
              if (verifyLength(event.target.value, 1)) {
               
              } else {
                setrequiredState("error");
              }
              setrequired(event.target.value);
            },
            type: "text",
            endAdornment:
              requiredState === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                undefined
              )
          }}

        />
                </GridItem>
   
     </GridContainer>

<br/>
<br/>
 <div className={classes.buttonGroup}>
                <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton} onClick={submitForm}>
                  <b>SUBMIT</b>
                </Button>

              </div>
              
              

              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
      </form>

    </div>
  );
}
export default Productbrand;
