import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CsvUpload from "components/CustomUpload/CsvUpload.js";
import TenantTable from "views/Tenant/TenantTable.js"
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Tooltip } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import DashboardIcon from '@material-ui/icons/Dashboard';

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import ViewTenantDetails from "views/Tenant/ViewTenantDetails";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',
  },
    ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right",
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const ParentList = ({
    tmParentListByOrgRequest,parentList,tmTenantInitialScoreRequest,tmAllTenantsTrailingScoreRequest,tenantsTrailingScoreList,tmParentDeleteRequest,parentDelete,tmPopulateInitialDataForTenantRequest,tmGenarateParentInitialReportRequest,generateParentInitialReport,
    populateInitialDataForTenant,organisationName
  }) => {
    const history = useHistory();
    const location = useLocation();
    const [dataList, setDataList] = React.useState(parentList);
    const [messageobj, setMessageobj] = React.useState("");
    const [tenantId , setTenantId ] = React.useState("");
    const [propertyId, setPropertyId ] = React.useState("");
    const [parentId , setParentId ] = React.useState("");
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));


   // const[id,setId] = React.useState(location.state.id);
   const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const[name,setName] = React.useState("");
    const[legalName,setLegalName] = React.useState("");
    const[city,setCity] = React.useState("");
    const[state,setState] = React.useState("");
    const[fundId,setFundId] = React.useState("");
    const[fundName,setFundName] = React.useState("");
    const[isUploaded,setIsUploaded] = React.useState("");
    const[createdDate,setCreatedDate] = React.useState("");
    const[modifiedDate,setModifiedDate] = React.useState("");
    const[currentStatus,setCurrentStatus] = React.useState("");
    const[corteraId,setCorteraId] = React.useState("");
    const[creditSafeId,setCreditSafeId] = React.useState("");
    const[contifyId,setContifyId] = React.useState("");
    const[naics,setNaics] = React.useState("");
    const[zipCode,setZipCode] = React.useState("");
    
    const[propertyName,setPropertyName] = React.useState("");
    const[propertyCategory,setPropertyCategory] = React.useState("");
    const[enableTenantMonitoring,setEnableTenantMonitoring] = React.useState("");
    const[deleted,setDeleted] = React.useState("");
    const[address1,setAddress] = React.useState("");
    const[initialDataPopulated,setInitialDataPopulated] = React.useState("");
    const [showViewTenantDetails, setShowViewTenantDetails] = React.useState(false);
    const [alert,setAlert] = React.useState(false);
    const [dataStatus,setDataStatus]=React.useState(false);

    const [showCompanyName,setShowCompanyName]=React.useState(true);
    const[entityType,setEntityType] = React.useState("");
    // const [alert1,setAlert1] = React.useState(false);
  
    // const hideAlert1 = () => {
    //   setAlert1(false);
    // }
  
    const [alert2,setAlert2] = React.useState(false);
    const [alert3,setAlert3] = React.useState(false);


    const hideAlert2 = () => {
      setAlert2(false);
    }

    const hideAlert3 = () => {
      setAlert3(false);
    }

    useEffect(() => {
      if(organisationId==""||organisationId==null||organisationId==undefined){
        setShowCompanyName(false);
        setAlert(true);
      }
      getParentList(organisationId);
      if(location.state){
        setMessageobj(location.state.message);
      }
      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
     
      }, [messageobj,dataStatus]);
  
   
      const hideAlert = () => {
        setAlert(false);
        history.push({ 
          pathname: '/admin/select-org',
          state: {requestFrom:"tenant"}
         });
      
      } 

    const getParentList = async (organisationId) => {
      await tmParentListByOrgRequest(organisationId);
    //  alert(organisationId);
      };


      const deleteParent = async (parentId) => {
        await tmParentDeleteRequest(parentId);
        console.log("parentDelete"+parentDelete);
        setAlert3(true);
        };


        const parentInitialReport = (parentId) => {
          getTmGenarateParentInitialReportRequest(parentId);
          console.log("parentId"+parentId);
          console.log(generateParentInitialReport);
          setAlert2(true);
        }
        const getTmGenarateParentInitialReportRequest = async (parentId) => {
          await tmGenarateParentInitialReportRequest(parentId);
        
        }
        // const getPopulateInitialDataForTenant = async(tenantId)=>{
        //  await tmPopulateInitialDataForTenantRequest(tenantId);
        // }


  
  //angel


  const getViewTenantDetails=(organisationId,name,legalName,
    city,state,zipCode,fundId,fundName,isUploaded,createdDate,modifiedDate,currentStatus,corteraId,creditSafeId,
    contifyId,naics,propertyId,propertyName,propertyCategory,entityType,enableTenantMonitoring,deleted,
    address1,initialDataPopulated)=>{
      setShowViewTenantDetails(true);

      setOrganisationId(organisationId);
      setName(name);
       setLegalName(legalName);
       setCity(city);
       setState(state);
       setFundId(fundId);
       setFundName(fundName);
       setIsUploaded(isUploaded);
       setCreatedDate(createdDate);
       setModifiedDate(modifiedDate);
       setCurrentStatus(currentStatus);
       setCorteraId(corteraId);
       setCreditSafeId(creditSafeId);
       setContifyId(contifyId);
       setNaics(naics);
       setPropertyId(propertyId);
       setPropertyName(propertyName);
       setPropertyCategory(propertyCategory);
       setEnableTenantMonitoring(enableTenantMonitoring);
       setDeleted(deleted);
       setZipCode(zipCode);
       setAddress(address1);
       setInitialDataPopulated(initialDataPopulated);
       setEntityType(entityType);

 
}


// const submit = (tenantId) => {
//   setAlert1(true);
// }

const submit = (parentId) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        
        <div className='custom-ui' 
        style={{
          backgroundColor: "#81A594 ",
         fontWeight:"bold",
          color:'#fff',
        
          textAlign: "center",
         // alignItems: "center",
          paddingTop: "20px",
          height: "150px",
          width: "300px",
          
          }}>
            
          <h1 >Are you sure?</h1>
          <p fontWeight="bold">You want to Delete this Parent?</p>
         
          <button  backgroundColor= "#000"
             onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button 
             
            onClick={() => {
              deleteParent(parentId)
              onClose();
             
           
            }}
          >
          &nbsp; &nbsp; Yes &nbsp;&nbsp;
          </button>
          </div>
    
     

      );
    }
  });
}
 
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getTenantInitialScore=(tenantId)=>{
  tmTenantInitialScoreRequest(tenantId);
}

const getAllTenantInitialScore=()=>{
  tmAllTenantsTrailingScoreRequest();
}

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}


// uploadTenant = (e) => {
// e.preventDefault();
// this.setState({
//   selectedFile:e.target.files[0]
// });
// tmUploadTenantRequest(this.state.selectedFile,'5f8ee03be74296262abdeb65','5f71f8a1bc82180272f8eed3')
// };

const [open, setOpen] = React.useState(false);
    
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


const handleCloseViewTenantDetails = () => {
  setShowViewTenantDetails(false);
};

  
  const classes = useStyles();
  if(dataStatus){

  return (
    <GridContainer>
       {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem> */}
     
      <GridItem xs={12}>
     
     
      

        <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Parent List</text> {showCompanyName && <span style={{marginLeft:"610px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>    
      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"tenant"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </span>}
                    

          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }

   
               <GridContainer className={classes.gridContainer}>
      
         <GridItem style={{textAlign: "right"}} xs={12} sm={12}>
         

         <Button style={{ background: megaColor,color:"white",fontSize:"14px"}} size="sm" round  onClick={() => history.push('/admin/parent')}><b><AddCircleIcon/>&nbsp;Create New Parent</b></Button>
       
                    
                      
              
         </GridItem>
         <GridItem>
               
               <Dialog  fullWidth={true} maxWidth={'xl'} 
 open={showViewTenantDetails} onClose={handleCloseViewTenantDetails}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewTenantDetails}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem  align="justify">
          {showViewTenantDetails && <ViewTenantDetails  organisationId ={organisationId} name={name} 
          legalName={legalName} city={city} state={state} zipCode={zipCode} fundId={fundId} fundName={fundName}
          isUploaded={isUploaded} createdDate={createdDate} modifiedDate={modifiedDate} currentStatus={currentStatus}
          corteraId={corteraId} creditSafeId={creditSafeId}  contifyId={ contifyId} naics={naics}
          propertyId={propertyId} propertyName={propertyName} propertyCategory={propertyCategory} entityType={entityType} enableTenantMonitoring={enableTenantMonitoring} deleted={deleted}
          address1={address1} initialDataPopulated={initialDataPopulated}
         
         
        />}
       
       </GridItem>  
       </Dialog>
               </GridItem> 
      
         
        </GridContainer>
{/*    
                 <CsvUpload/> */}
        
         
 {/* if(initialDataPopulated===true){
   true;
 } */}
            <TenantTable
              columns={[
                
              {
                Header: "Name",
                accessor: "name",

                Cell: ({ cell,row }) => (<Link to={{ state :{organisationId : row.original.organisationId, name:row.original.name} } } 
                  onClick={() => getViewTenantDetails(row.original.organisationId,row.original.name,row.original.legalName,
                      row.original.city,row.original.state,row.original.zipCode,row.original.fundId,row.original.fundName,row.original.isUploaded,row.original.createdDate,row.original.modifiedDate,row.original.currentStatus,
                      row.original.corteraId,row.original.creditSafeId,row.original.contifyId,row.original.naics,
                     row.original.propertyId,row.original.propertyName,row.original.propertyCategory,row.original.entityType,row.original.enableTenantMonitoring,row.original.deleted,row.original.address1,row.original.initialDataPopulated)}
                 >{row.original.name}</Link>),



                
                //width: getColumnWidth(tenantList, 'tenantName', 'Tenant'),
                
                // Cell: ({ cell,row ,
                //   column: { tenantId } }) => (
                
                // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
             // }
             width: getColumnWidthNew(parentList, 'accessor', 'Name'),
            },
           

        //       {
        //        Header: "ContifyId",
        //         accessor: "contifyId",
        //         width: 50,
                
        //     },
        //     {
        //       Header: "CorteraId",
        //        accessor: "corteraId",
        //        width: 50,
               
        //    },
        //    {
        //     Header: "CreditSafeId",
        //      accessor: "creditSafeId",
        //      width: 50,
             
        //  },
           
                {
                  Header: "City",
                  accessor: "city",
                  width: 50,
                },

                
                {
                  Header: "Address",
                  accessor: "address1",
                  
                  isVisible: false,
                  width: 50,
                },

                {
                  Header: "PropertyName",
                  accessor: "propertyName",
                  
                  isVisible: false,
                  width: 50,
                },


                {
                  Header: "Initial Setup",
                  accessor: "initialDataPopulated",
                  
                 isVisible: false,
      
                },

              
                {
                  Header: "Actions",
                accessor: "actions",
                disableFilters: true,
                disableSortBy: true,
                 },


              ]}
              data={parentList.map((prop, key) => {

               
                return {
                //  id: prop.id,
                  organisationId:organisationId,
                  name: prop.name,
                  legalName:prop.legalName,
                  city: prop.city,
                
                  state:prop.state,
                  zipCode: prop.zipCode,
                  fundId:prop.fundId,
                  fundName:prop.fundName,
                  isUploaded:prop.isUploaded,
                  createdDate:prop.createdDate,
                  modifiedDate:prop.modifiedDate,
                  currentStatus:prop.currentStatus,
                  contifyId: prop.contifyId,
                  corteraId: prop.corteraId,
                  creditSafeId: prop.creditSafeId,
                  naics: prop.naics,
                  propertyId: prop.propertyId,
                  propertyName: prop.propertyName,
                  propertyCategory: prop.propertyCategory,
                  entityType: prop.entityType,
                  enableTenantMonitoring: prop.enableTenantMonitoring,
                  deleted: prop.deleted,
                  address1: prop.address1,
                  initialDataPopulated: prop.initialDataPopulated,
                  
                
                  initialDataPopulated:(


                  // render(){ if(prop.initialDataPopulated){
                    <div>
                  {/* //   return( */}
                
                  {prop.initialDataPopulated ? 
                     <Tooltip classes={{ tooltip: classes.customWidth }} title="True">
                  <DoneIcon style={{ color: "green" }} />
                  </Tooltip> : 
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="False">
                  <CloseIcon  style={{ color: "red" }}/>
                  </Tooltip>}
                     {/*                   
//                    <Link to={{pathname:'/admin/tenant-edit', state :{tenantId : prop.id} } }> */}
                   
                
                   
                     {/* <Button
//                         justIcon
//                         round
//                         simple
//                         onClick={() =>{}}
                       
//                         //   onClick={() => 
//                         //   alert("You've pressed the edit button on colmun id: " + prop.id)
//                         // }
//                         color="success"
//                         className="done"
//                       >
//                         <DoneIcon />
//                       </Button>{" "} */}
{/*                       
//                   //   )
//                   // }
//                   // else{ */}
                 {/* // return( */}
                    
                    {/* use this button to remove the data row */}
                    {/* <Button
//                         justIcon
//                         round
//                         simple
//                         onClick={() =>{}}
//                         color="danger"
//                         className="remove"
//                       >
//                         <Close />
//                       </Button>{" "}  */}
{/*                   
                  // {/* // )
                  // // } */} 
                  </div>
                 
                  ),
                    
                  actions: (
                    // we've added some custom button actions
                    <div>
                   <Link to={{pathname:'/admin/parent-edit', state :{parentId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="edit"
                      >
                        <Tooltip classes={{ tooltip: classes.customWidth }} title="Edit Parent">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit(prop.id)}}
                        color="info"
                        className="remove"
                      >
                        <Tooltip classes={{ tooltip: classes.customWidth }} title="Delete Parent">
                        <DeleteIcon/>
                        </Tooltip>
                      </Button>{" "}

                      {/* <Button
                        justIcon
                        round
                        simple
                      // onClick={() => getPopulateInitialDataForTenant()}
                        size="md"
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="score"
                      >
                         <Tooltip style={{ fontSize: '50px' }} title="Populate Score">
                         <AssignmentTurnedInIcon/>
                        </Tooltip>
                      
                      </Button>{" "} */}

<Link to={{pathname:'/admin/parentlist', state :{parentId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{parentInitialReport(prop.id)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Initial Report">
                         <DashboardIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                    </div>
                  )
                };
              })}


        
           //   data={tenantList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
          
        </Card>
        
        </GridItem>
        
        <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
         
           onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please select Company
        </SweetAlert>
      
                     </div>
                     {/* <div>
  <SweetAlert
        warning
        show={alert1}
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to delete property?"
        onConfirm={() =>deleteTenant(tenantId)}
        onCancel={() => hideAlert1()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
        showConfirm
      >
       
      </SweetAlert>

</div> */}

<div>
<SweetAlert
success
show={alert2}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert2()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
  {generateParentInitialReport}
{/* Generated Fund Initial Report. */}
</SweetAlert>

       </div>

<div>
<SweetAlert
success
show={alert3}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert3()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
  {parentDelete}
{/* Generated Fund Initial Report. */}
</SweetAlert>

       </div>
    </GridContainer>
    
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


ParentList.propTypes = {
  parentList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
      
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmTenantListRequest: PropTypes.func.isRequired,
  tmParentListByOrgRequest: PropTypes.func.isRequired,
  tmParentDeleteRequest: PropTypes.func.isRequired,
  tmTenantInitialScoreRequest: PropTypes.func.isRequired,
  tmGenarateParentInitialReportRequest: PropTypes.func.isRequired,
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  parentList: state.tenantmonitoring.parentList,
  parentDelete: state.tenantmonitoring.parentDelete,
  loading: state.tenantmonitoring.loading,
  generateParentInitialReport: state.tenantmonitoring.generateParentInitialReport,

  //tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentList);



