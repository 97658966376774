import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// core components
import Button from "components/CustomButtons/Button.js";

import React, { Component } from "react";
import Chart from "react-apexcharts";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
// import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PublishIcon from '@material-ui/icons/Publish';
import { useLocation } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { isConstructorDeclaration } from "typescript";
import axios ,{get} from 'axios';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import AddIcon from "@material-ui/icons/Add";
import { Fab} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form as FinalForm, Field } from "react-final-form";
import CircularProgress from '@material-ui/core/CircularProgress';
//  import { FileUpload } from '@welcome-ui/file-upload'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import blue from "@material-ui/core/colors/blue";
import { Tooltip } from "@material-ui/core";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    //width:"300px",
   //height:"400px",
   maxWidth: 445,
  // paddingBottom:"150px",
    //background: "hsla(0, 0, 0, 0.7)"
     //boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)"
   
  },
  input: {
    display: "none"
  },
  button: {
    color: blue[900],
    margin: 30,
    size:"large"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
});

const PropertyImageUpload =({propertyImageUpload,tmPropertyImageUploadRequest,organisationId})=>{
  const location = useLocation();

  
  const classes = useStyles(); 
  const [propertyId, setPropertyId ] = React.useState(location.state.propertyId);
        const [state,setState]=React.useState("");
        const [alert,setAlert] = React.useState(false);
        const [progress, setProgress] = React.useState(100);

        const history = useHistory();
// const[status,setStatus] = React.useState(uploadTenantStatus);

// const handleUploadClick = event => {
//   console.log();
//   var file = event.target.files[0];
//   const reader = new FileReader();
//   var url = reader.readAsDataURL(file);

//   reader.onloadend = function(e) {
//     setState({file:file})
//   }.bind(this);
//   console.log(url); // Would see a path?

//   setState({
//     mainState: "uploaded",
//     selectedFile: event.target.files[0],
//     imageUploaded: 1
//   });
// };
   
        React.useEffect(() => {
          console.log("props"+propertyId);
         // console.log("org"+organisationId);
          setProgress(0);
        
      
       
        }, []);



//         var maxSize = '1024';
// function fileSizeValidate(fdata) {
// 	 if (fdata.files && fdata.files[0]) {
//                 var fsize = fdata.files[0].size/1024;
//                 if(fsize > maxSize) {
//                 	 alert('Maximum file size exceed, This file size is: ' + fsize + "KB");
//                 	 return false;
//                 } else {
//                 	return true;
//                 }
//      }
//  }


        const handleCloseShowPictureUpload = () => {
          history.push({ 
 
            pathname: '/admin/propertylist',
            // state:{ message:"Fund Saved Successfully" }
            
           });
        };

      const handleCancel = e => {
      setState("");
          e.target.value=null
       setProgress(0); 
        };


const hideAlert = () => {
  setAlert(false);
}
    const onFormSubmit=(e)=>{
    if(state!==""){
        fileUpload(state.file);
      setProgress(100);
        e.preventDefault();
    }
    else if(state===""){
      setProgress(0);
      e.preventDefault();
    }
      }

      const onChange=(e)=> {
       
        setState({file:e.target.files[0]})
        
        
      }
     const fileUpload=(file)=>{
   
      uploadPropertyImage(file,propertyId)
  
       
  
      } 

      const uploadPropertyImage = async (file,propertyId) => {
       

        await tmPropertyImageUploadRequest(file,propertyId)
        
       setProgress(100);
        
        // setAlert(true);
        // console.log("organizationId"+JSON.stringify(organizationId));
      };
     

    return (
      <div align="center" >
        <br></br>
        <br></br>
        <Card className={classes.root}>
       
      <form onSubmit={onFormSubmit} >
        <br></br>
        <br></br>
      
        <div>
            <GridItem align="right">
    
      
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseShowPictureUpload}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
         
</GridItem>
        </div>
        <div>
      <font style={{fontSize: "27px", fontWeight:"bold",color: megaColor}}>UPLOAD IMAGE</font>

     
      </div>
      <br></br>
      <br></br>
      {/* <CustomFileInput onChange={onChange}/> */}
        {/* <input type="file" onChange={onChange} /> */}
        {/* style={{
    padding: '10px',
    background: 'red',
    display: 'table',
    color: '#fff'
     }} */}


<label htmlFor="contained-button-file">

              <Fab component="span" className={classes.button}>
              <Tooltip classes={{ tooltip: classes.customWidth }} title="Choose File">
              <AddPhotoAlternateIcon style={{fontSize:"35px"}}/>
                        </Tooltip>
              
              </Fab>
            </label>.

      {/* <label for="actual-btn" style={{align: "center", backgroundColor: "red",
  color: "white",
  padding: "0.5rem",
  fontSize:17, fontWeight: "bold" ,borderRadius: 10
 }}>Choose File</label>   */}

<input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              style={{width:'200px',height: '60px'
          }}
              onChange={onChange}
            />
          
         {/* <input
            style={{ display: "block" }}
          id="actual-btn" 
          style={{width:'200px',height: '60px'
          }}
        hidden
          type="file"
          onChange={onChange}
         
        />
         */}
      {/* <span  id="file-chosen" >No file chosen</span> */}
      
        {/* <label class="file"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <input
          //  style={{ display: "block" }}
          
          style={{width:'180px',height: '30px', 
          color: 'red'}}
          id="actual-btn"
       
          // name="choose-file"
          type="file"
          onChange={onChange}
         
        />
       </label> */}
       <br></br>
 
       &nbsp;&nbsp;&nbsp;
       <Button     id="send" color="info" size="sm" type="submit" 
        style={{fontSize:14, fontWeight: "bold", borderRadius: 2}}>
          Upload 
        </Button>
     {/* <br></br> */}
{/* <br></br> */}
       
       
        
        <Button
                type="reset"
                  size="sm"
                  color="info"
                  style={{fontSize:14, fontWeight: "bold", marginRight:"20px"}}
                  // className={`ts ${submitting ? "loading" : ""} button`}
                  // disabled={submitting}
                   onClick={handleCancel}
                >
                  Reset
                </Button>
              
              
                <div>
                <CircularProgress variant="determinate" value={progress} />
                </div>
                <div align="center">
                   
                   <b>{propertyImageUpload}</b>
                  
         
         </div>
        <br />
        <br />
        
                      {/* <Button
                        round color="info" size="sm" type="submit">upload
                      </Button> */}
                 
      </form>
     
       
       <div>
       <SweetAlert
info
show={alert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

confirmBtnCssClass={
classes.button + " " + classes.error

}
>
{/* <b>The data is being uploaded.Please verify after some time.If still problem exist please contact system administrator </b> */}
<b>{propertyImageUpload}</b>
</SweetAlert>
         </div>
         </Card>
       </div>

       
     
   )
  
}

PropertyImageUpload.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertyImageUploadRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  propertyImageUpload:state.tenantmonitoring.propertyImageUpload
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyImageUpload);
