
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import FundTable from "views/Fund/FundTable.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import PublishIcon from '@material-ui/icons/Publish';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  }
};


const useStyles = makeStyles(theme => ({

  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'inline-block',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
}));
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});


  const FundList = ({
    fundList,tmFundListRequest,tmFundListByOrgRequest,tmFundDeleteRequest,fundDelete,tmGenarateFundInitialReportRequest,generateFundInitialReport,tmGenarateScoreForFundRequest,generateFundScore
  }) => {
    
    const location = useLocation();
    const [dataList, setDataList] = React.useState(fundList);
    const [messageobj, setMessageobj] = React.useState("");
    const [fundId, setFundId ] = React.useState("");
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    const [alert,setAlert] = React.useState(false);
    const [dataStatus,setDataStatus]=React.useState(false);
    const [alert1,setAlert1] = React.useState(false);
    const [alert2,setAlert2] = React.useState(false);
    const [alert3,setAlert3] = React.useState(false);
    const [showCompanyName,setShowCompanyName]=React.useState(true);
  
    // const hideAlert1 = () => {
    //   setAlert1(false);
    // }
      const history = useHistory();
    
    useEffect(() => {
      if(organisationId==""||organisationId==null||organisationId==undefined){
        setShowCompanyName(false);
        setAlert(true);
      }
      
      getFundList(organisationId);

    //  deleteFund(fundId);
     // getFundList();
    if(location.state){
      setMessageobj(location.state.message);
    }
    setTimeout(() => {
      setDataStatus(true);
    }, 2000);
   
   
    }, [messageobj,dataStatus]);

   


// const hideAlert = () => {
//   setAlert(false);
//   history.push({ 
//     pathname: '/admin/select-org'
//    });

// }
const hideAlert = () => {
  setAlert(false);
  history.push({ 
    pathname: '/admin/select-org',
    state: {requestFrom:"fund"}
   });

}


const hideAlert2 = () => {
  setAlert2(false);
}

const hideAlert3 = () => {
    setAlert3(false);
  }

const fundInitialReport = (fundId) => {
  getTmGenarateFundInitialReportRequest(fundId);
  console.log("fundId"+fundId);
  setAlert2(true);
}

const getTmGenarateFundInitialReportRequest = async (fundId) => {
  await tmGenarateFundInitialReportRequest(fundId);
 

}

const fundScore = (fundId) => {
  geneerateScoreForFund(fundId);
  console.log("fundId"+fundId);
  setAlert3(true);
}

const geneerateScoreForFund = async (fundId) => {
  await tmGenarateScoreForFundRequest(fundId);
  console.log("generateFundScore"+generateFundScore)

}
    const deleteFund = async (fundId) => {
      await tmFundDeleteRequest(fundId);
      setAlert1(false);
      };

    const getFundList = async (organisationId) => {
      await tmFundListByOrgRequest(organisationId);
      
      };
      //angel
      const submit = (fundId) => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              
              <div className='custom-ui' 
              style={{
                backgroundColor: "#81A594 ",
               fontWeight:"bold",
                color:'#fff',
              
                textAlign: "center",
               // alignItems: "center",
                paddingTop: "20px",
                height: "150px",
                width: "300px",
                
                }}>
                  
                <h1 >Are you sure?</h1>
                <p fontWeight="bold">You want to Delete this Fund?</p>
               
                <button  backgroundColor= "#000"
                   onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                <button 
                   
                  onClick={() => {
                    deleteFund(fundId)
                    onClose();
                    
                 
                  }}
                >
                &nbsp; &nbsp; Yes &nbsp;&nbsp;
                </button>
                </div>
          
           

            );
          }
        });
      }

    //   const submit = (fundId) => {
    //     setAlert1(true)
    // }

    // const submit = (fundId) => {
      
    //  
    

//  const getColumnWidth = (data, accessor, headerText) => {
//         const cellLength = Math.max(
//           ...data.map(row => {
//             let value = '';
      
//             if (typeof accessor === 'string') {
//               value = _.get(row, accessor);
//             } else {
//               value = accessor(row);
//             }
      
//             if (typeof value === 'number') return value.toString().length;
//             return (value || '').length;
//           }),
//           headerText.length
//         );
      
//         const magicSpacing = 12;
//         return cellLength * magicSpacing;
//       };
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}



  
  const classes = useStyles();
  if(dataStatus){
  return (
   
    

    <GridContainer  >
    
     {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem> */}
      <GridItem xs={12}  >
        <Card >
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
           
           
      <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Fund List</text> {showCompanyName && <span style={{marginLeft:"650px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     
 
 <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"fund"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </span>}
                     
                    
          </CardHeader>
          <CardBody>

 {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }
   
  
    
            <FundTable
              columns={[
                
              {
                Header: "Fund Name",
                accessor: "name",
                //width: getColumnWidth(tmList, 'tenantName', 'Tenant'),
              //   Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification/',
              //    state :{id : row.original.id,
              //       name:row.original.name} } }>{row.original.name}</Link>
             
              
              // ),
             
  
             width: getColumnWidthNew(fundList, 'accessor', 'Fund Name'),
            },
            {
              Header: "Fund Type",
              accessor: "type",
              width: 50,  
            },
                
              {
                Header: "Fund Manager",
                accessor: "fundManagerName",
                width: 50,
              },
              {
                Header: "Comments",
                accessor: "comments",
                width: 50,
              },


              {
                Header: "Actions",
                accessor: "actions",
                disableFilters: true,
                disableSortBy: true,
                width: 50,
              },

     
              ]}

              data={fundList.map((prop, key) => {
                return {
                  id: prop.id,
                  name: prop.name,
                  type: prop.type,
                  fundManagerName: prop.fundManagerName,
                  comments: prop.comments,
                  
                  actions: (
                    // we've added some custom button actions
                    <div>
                   <Link to={{pathname:'/admin/fund-edit', state :{fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Edit Fund">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      {/* use this button to remove the data row */}
                      <Link to={{pathname:'/admin/fundlist', state :{fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit(prop.id)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Delete Fund">
                        <DeleteIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                      <Link to={{pathname:'/admin/fund-image-upload',state : { fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                      onClick={() =>{}}
                        //onClick={() =>{handleClickOpenShowPictureUpload(prop.id)}}
                     // onClick={handleClickOpenShowPictureUpload}   
                        size="md"
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="upload"
                      >
                           <Tooltip classes={{ tooltip: classes.customWidth }} title="Upload Image">
                        <PublishIcon />
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      {/* use this button to remove the data row */}
                      <Link to={{pathname:'/admin/fundlist', state :{fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{fundInitialReport(prop.id)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Initial Report">
                         <DashboardIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                      <Link to={{pathname:'/admin/fundlist', state :{fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{fundScore(prop.id)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Score">
                         <AssignmentTurnedInIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                    </div>
                  )
                };
              })}

              //data={fundList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
      <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
          onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please select Company
        </SweetAlert>
      
                     </div>
                     {/* <div>
  <SweetAlert
        success
        show={alert1}
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to delete fund?"
        onConfirm={() =>deleteFund(fundId)}
        onCancel={() => hideAlert1()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
        showConfirm
      >
       
      </SweetAlert>

</div> */}
     <div>
<SweetAlert
success
show={alert2}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert2()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
   {generateFundInitialReport}
{/* Generated Fund Initial Report. */}
</SweetAlert>

       
</div>

<div>
<SweetAlert
success
show={alert3}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert3()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
   {generateFundScore}
{/* Generated Fund Score. */}
</SweetAlert>

       
</div>
    </GridContainer>

    
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


FundList.propTypes = {
  fundList: PropTypes.arrayOf(
    PropTypes.shape({
      organisationId: PropTypes.string,
      
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmRequest: PropTypes.func.isRequired,
  tmFundListByOrgRequest: PropTypes.func.isRequired,
  tmFundDeleteRequest:PropTypes.func.isRequired,
  tmGenarateFundInitialReportRequest: PropTypes.func.isRequired,
  tmGenarateScoreForFundRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  fundList: state.tenantmonitoring.fundList,
  fundDelete: state.tenantmonitoring.fundDelete,
  loading: state.tenantmonitoring.loading,
  organisationId: state.organisationId,
  generateFundInitialReport: state.tenantmonitoring.generateFundInitialReport,
  generateFundScore: state.tenantmonitoring.generateFundScore,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundList);




