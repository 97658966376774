import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactSpeedometer from "react-d3-speedometer"
import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  }
};

const useStyles = makeStyles(styles);



  const ScoreCard = ({ creditScore }) =>{
 
  const classes = useStyles();

  const [overAllScore, setOverAllScore] = React.useState(creditScore.overAllScore)
  const [creditScoreValue, setCreditScoreValue] = React.useState(creditScore.creditScore)
  //const [overAllScore, setOverAllScore] = React.useState(creditScore.overAllScore)
  const [derogatoryScore, setDerogatoryScore] = React.useState(creditScore.derogatoryScore)
  const [pirsScore, setPirsScore] = React.useState(creditScore.pirsScore)

  return (
    <div>
        

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
    
            
            <GridItem xs={12} sm={3} md={3}>
              <Card pricing plain>
                <CardBody pricing plain>
<ReactSpeedometer
    width={200}
    value={overAllScore}
    height={130}
    ringWidth={45}
    needleColor="steelblue"
    needleTransitionDuration={4000}
    needleTransition="easeElastic"
    currentValueText="OverAll Score: ${value}"
    customSegmentStops={[0, 100, 200, 300, 400, 500]}
    textColor="#AAA"
    minValue={0}
    maxValue={500}
  />
                  
                </CardBody>
              </Card>
            </GridItem>
            
            <GridItem xs={12} sm={2} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                <ReactSpeedometer
    width={200}
    height={130}
    value={creditScoreValue}
    ringWidth={45}
    needleColor="steelblue"
    needleTransitionDuration={5000}
    needleTransition="easeElastic"
    currentValueText="Credit Score: ${value}"
    customSegmentStops={[0, 100, 200, 300, 400, 500]}
    textColor="#AAA"
    minValue={0}
    maxValue={500}
  />
                 
                  
                  
                </CardBody>
              </Card>
            </GridItem>
            

             <GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                <ReactSpeedometer
    width={200}
    height={130}
    value={derogatoryScore}
    ringWidth={45}
    needleColor="steelblue"
    needleTransitionDuration={6000}
    needleTransition="easeElastic"
    currentValueText="Derogatory Score: ${value}"
    customSegmentStops={[0, 100, 200, 300, 400, 500]}
    textColor="#AAA"
    minValue={0}
    maxValue={500}
  />
                </CardBody>
              </Card>
            </GridItem>
            

             <GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                <ReactSpeedometer
    width={200}
    height={130}
    value={pirsScore}
    ringWidth={45}
    needleColor="steelblue"
    needleTransitionDuration={7000}
    needleTransition="easeElastic"
    currentValueText="PIRS Score: ${value}"
    customSegmentStops={[0, 100, 200, 300, 400, 500]}
    textColor="#AAA"
    minValue={0}
    maxValue={500}
  />
                </CardBody>
              </Card>
            </GridItem>
     
          </GridContainer>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}

export default ScoreCard;