import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfOne from './PdfOne';
import PdfReport from './PdfReport';
import ReactPDF from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { set } from "date-fns";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";

const styles = {

  app: {
    textAlign: "center"
  },
  downloadLink: {
    fontSize: "large",
    fontWeight: "bold"
  }
  
};
const useStyles = makeStyles(styles);

const Pdf2 = ({tmGetTenantReportsDetailsRequest,reportsDetails}) =>{


  const location = useLocation();
  const [dataStatus, setDataStatus] = React.useState(false);
  const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
  const [tenantId, setTenantId] = React.useState(location.state.tenantId);
  const [tenantName, setTenantName] = React.useState(location.state.tenantName);
  const [organisationName, setOrganisationName] = React.useState("");
  const [companyAddress, setCompanyAddress] = React.useState("");
  const [legalName, setLegalName] = React.useState("");
  const [legalFormOfEntity, setLegalFormOfEntity] = React.useState("");
  const [entityType, setEntityType] = React.useState("");
  const [primaryNaicsCode, setPrimaryNaicsCode] = React.useState("");
  const [noOfLocations, setNoOfLocations] = React.useState("");
  const [yearStarted, setYearStarted] = React.useState("");
  const [primaryIndustry, setPrimaryIndustry] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [OFAC, setOFAC] = React.useState("");
  const [legalEntityName, setLegalEntityName] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [corporationFilingName, setCorporationFilingName] = React.useState("");
  const [nameType, setNameType] = React.useState("");
  const [corporationFilingAddress, setCorporationFilingAddress] = React.useState("");
  const [corporationFilingAddressType, setCorporationFilingAddressType] = React.useState("");
  const [corporationFilingStatus, setCorporationFilingStatus] = React.useState("");
  const [filingDate, setFilingDate] = React.useState("");
 
  const [incorporationState, setIncorporationState] = React.useState("");
  const [incorporationDate, setIncorporationDate] = React.useState("");
  const [registeredAgentAddress, setRegisteredAgentAddress] = React.useState("");
  const [registrationType, setRegistrationType] = React.useState("");
  const [creditScore, setCreditScore] = React.useState("");
  const [trailingDayScore, setTrailingDayScore] = React.useState("");
  const [threeToSixmonthsPaymentScore, setThreeToSixmonthsPaymentScore] = React.useState("");
  const [dbtScore, setDbtScore] = React.useState("");
  const [industryDbtScore, setIndustryDbtScore] = React.useState("");
  const [trailingDayCommentary, setTrailingDayCommentary] = React.useState("");
  const [threeToSixmonthsPaymentCommentary, setThreeToSixmonthsPaymentCommentary] = React.useState("");
  const [industryBenchmarkCommentary, setIndustryBenchmarkCommentary] = React.useState("");
  const [trailingDayColorCode, setTrailingDayColorCode] = React.useState("");
  const [threeToSixmonthsPaymentColorCode, setThreeToSixmonthsPaymentColorCode] = React.useState("");
  const [creditScoreColorCode, setCreditScoreColorCode] = React.useState("");
  const [yearsInBusinessCommentary, setYearsInBusinessCommentary] = React.useState("");


  const [derogScore, setDerogScore] = React.useState("");
  const [derogCommentary, setDerogCommentary] = React.useState("");
  const [derogColorCode, setDerogColorCode] = React.useState("");
  const [cautionaryUccScore, setCautionaryUccScore] = React.useState("");
  const [cautionaryUccCommentary, setCautionaryUccCommentary] = React.useState("");
  const [cautionaryUccColorCode, setCautionaryUccColorCode] = React.useState("");
  const [taxLiensAndJudgementScore, setTaxLiensAndJudgementScore] = React.useState("");
  const [taxLiensAndJudgementCommentary, setTaxLiensAndJudgementCommentary] = React.useState("");
  const [taxLiensAndJudgementColorCode, setTaxLiensAndJudgementColorCode] = React.useState(""); 
  const [civilSuitsScore, setCivilSuitsScore] = React.useState("");
  const [civilSuitsCommentary, setCivilSuitsCommentary] = React.useState("");
  const [civilSuitsColorCode, setCivilSuitsColorCode] = React.useState("");
  const [corporationFilingScore, setCorporationFilingScore] = React.useState("");
  const [corporationFilingCommentary, setCorporationFilingCommentary] = React.useState("");
  const [corporationFilingScoreColorCode, setCorporationFilingScoreColorCode] = React.useState("");
 
  const [taxLienDetailList, seTaxLienDetailList] = React.useState([]);
  const [judgementDetailList, setJudgementDetailList] = React.useState([]);
  const [suitsList, setSuitsList] = React.useState("");
  const [cautionaryUccDetailsList, setCautionaryUccDetailsList] = React.useState([]);
  const [liensCount, setLiensCount] = React.useState("");
  const [judgmentCount, setJudgmentCount] = React.useState("");
  const [liensPlusJudgmentCount, setLiensPlusJudgmentCount] = React.useState("");
  const [suitsCount, setSuitsCount] = React.useState("");
  const [taxLiens, setTaxLiens] = React.useState("");
  const [judgment, setJudgment] = React.useState("");
  const [suits, setSuits] = React.useState("");
  const [uccCount, setUccCount] = React.useState("");
  const [totLiensAndJudgment, setTotLiensAndJudgment] = React.useState("");
const [businessToken,setBusinessToken] = React.useState("");
 
  React.useEffect(() => {
   
    // if(!dataStatus){

    getTenantReportsDetails(organisationId,tenantId,"B-RVPK-T165");
    console.log("reportsDetails"+reportsDetails);

  // }else{

  
    setOrganisationName(reportsDetails.organisationName);

  if(reportsDetails.companyAddress==null || reportsDetails.companyAddress=="undefined"){
    setCompanyAddress("NA");
  }else{
    setCompanyAddress(reportsDetails.companyAddress);
  }
  
  if(reportsDetails.legalName==null || reportsDetails.legalName=="undefined"){
    setLegalName("NA");
  }else{
    setLegalName(reportsDetails.legalName);
  }

  if(reportsDetails.legalFormOfEntity==null || reportsDetails.legalFormOfEntity=="undefined"){
    setLegalFormOfEntity("NA");
  }else{
    setLegalFormOfEntity(reportsDetails.legalFormOfEntity);
  }


  if(reportsDetails.entityType==null || reportsDetails.entityType=="undefined"){
    setEntityType("NA");
  }else{
    setEntityType(reportsDetails.entityType);
  }


  if(reportsDetails.primaryNaicsCode==null || reportsDetails.primaryNaicsCode=="undefined"){
    setPrimaryNaicsCode("NA");
  }else{
    setPrimaryNaicsCode(reportsDetails.primaryNaicsCode);
  }


  if(reportsDetails.noOfLocations==null || reportsDetails.noOfLocations=="undefined"){
    setNoOfLocations("NA");
  }else{
    setNoOfLocations(reportsDetails.noOfLocations);
  }


  if(reportsDetails.yearStarted==null || reportsDetails.yearStarted=="undefined"){
    setYearStarted("NA");
  }else{
    setYearStarted(reportsDetails.yearStarted);
  }


  if(reportsDetails.primaryIndustry==null || reportsDetails.primaryIndustr=="undefined"){
    setPrimaryIndustry("NA");
  }else{
    setPrimaryIndustry(reportsDetails.primaryIndustry);
  }


  if(reportsDetails.websiteUrl==null || reportsDetails.websiteUrl=="undefined"){
    setWebsiteUrl("NA");
  }else{
    setWebsiteUrl(reportsDetails.websiteUrl);
  }


  if(reportsDetails.OFAC==null || reportsDetails.OFAC=="undefined"){
    setOFAC("NA");
  }else{
    setOFAC(reportsDetails.OFAC);
  }


  if(reportsDetails.legalEntityName==null || reportsDetails.legalEntityName=="undefined"){
    setLegalEntityName("NA");
  }else{
    setLegalEntityName(reportsDetails.legalEntityName);
  }


  if(reportsDetails.postalCode==null || reportsDetails.postalCode=="undefined"){
    setPostalCode("NA");
  }else{
    setPostalCode(reportsDetails.postalCode);
  }


  if(reportsDetails.country==null || reportsDetails.country=="undefined"){
    setCountry("NA");
  }else{
    setCountry(reportsDetails.country);
  }


  if(reportsDetails.state==null || reportsDetails.state=="undefined"){
    setState("NA");
  }else{
    setState(reportsDetails.state);
  }

  
  if(reportsDetails.address==null || reportsDetails.address=="undefined"){
    setAddress("NA");
  }else{
    setAddress(reportsDetails.address);
  }


  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }


  if(reportsDetails.corporationFilingName==null || reportsDetails.corporationFilingName=="undefined"){
    setCorporationFilingName("NA");
  }else{
    setCorporationFilingName(reportsDetails.corporationFilingName);
  }


  if(reportsDetails.nameType==null || reportsDetails.nameType=="undefined"){
    setNameType("NA");
  }else{
    setNameType(reportsDetails.nameType);
  }


  if(reportsDetails.corporationFilingAddress==null || reportsDetails.corporationFilingAddress=="undefined"){
    setCorporationFilingAddress("NA");
  }else{
    setCorporationFilingAddress(reportsDetails.corporationFilingAddress);
  }


  if(reportsDetails.corporationFilingAddressType==null || reportsDetails.corporationFilingAddressType=="undefined"){
    setCorporationFilingAddressType("NA");
  }else{
    setCorporationFilingAddressType(reportsDetails.corporationFilingAddressType);
  }


  if(reportsDetails.corporationFilingStatus==null || reportsDetails.corporationFilingStatus=="undefined"){
    setCorporationFilingStatus("NA");
  }else{
    setCorporationFilingStatus(reportsDetails.corporationFilingStatus);
  }


  if(reportsDetails.filingDate==null || reportsDetails.filingDate=="undefined"){
    setFilingDate("NA");
  }else{
    setFilingDate(reportsDetails.filingDate);
  }


  if(reportsDetails.incorporationState==null || reportsDetails.incorporationState=="undefined"){
    setIncorporationState("NA");
  }else{
    setIncorporationState(reportsDetails.incorporationState);
  }


  if(reportsDetails.incorporationDate==null || reportsDetails.incorporationDate=="undefined"){
    setIncorporationDate("NA");
  }else{
    setIncorporationDate(reportsDetails.incorporationDate);
  }


  if(reportsDetails.registeredAgentAddress==null || reportsDetails.registeredAgentAddress=="undefined"){
    setRegisteredAgentAddress("NA");
  }else{
    setRegisteredAgentAddress(reportsDetails.registeredAgentAddress);
  }


  if(reportsDetails.registrationType==null || reportsDetails.registrationType=="undefined"){
    setRegistrationType("NA");
  }else{
    setRegistrationType(reportsDetails.registrationType);
  }

  
  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }
  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }
  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }
  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }


    setCreditScore(reportsDetails.creditScore);
    setTrailingDayScore(reportsDetails.trailingDayScore);
    setThreeToSixmonthsPaymentScore(reportsDetails.threeToSixmonthsPaymentScore);
    setDbtScore(reportsDetails.dbtScore);
    setIndustryDbtScore(reportsDetails.industryDbtScore);
    setTrailingDayCommentary(reportsDetails.trailingDayCommentary);
    setThreeToSixmonthsPaymentCommentary(reportsDetails.threeToSixmonthsPaymentCommentary);
    setIndustryBenchmarkCommentary(reportsDetails.industryBenchmarkCommentary);
    setTrailingDayColorCode(reportsDetails.trailingDayColorCode);
    setThreeToSixmonthsPaymentColorCode(reportsDetails.threeToSixmonthsPaymentColorCode);
    setCreditScoreColorCode(reportsDetails.creditScoreColorCode);
    setYearsInBusinessCommentary(reportsDetails.yearsInBusinessCommentary);
    setDerogScore(reportsDetails.derogScore);
    setDerogCommentary(reportsDetails.derogCommentary);
    setDerogColorCode(reportsDetails.derogColorCode);
    setCautionaryUccScore(reportsDetails.cautionaryUccScore);
    setCautionaryUccCommentary(reportsDetails.cautionaryUccCommentary);
    setCautionaryUccColorCode(reportsDetails.cautionaryUccColorCode);
    setTaxLiensAndJudgementScore(reportsDetails.taxLiensAndJudgementScore);
    setTaxLiensAndJudgementCommentary(reportsDetails.taxLiensAndJudgementCommentary);
    setTaxLiensAndJudgementColorCode(reportsDetails.taxLiensAndJudgementColorCode);
    setCivilSuitsScore(reportsDetails.civilSuitsScore);
    setCivilSuitsCommentary(reportsDetails.civilSuitsCommentary);
    setCivilSuitsColorCode(reportsDetails.civilSuitsColorCode);
    setCorporationFilingScore(reportsDetails.corporationFilingScore);
    setCorporationFilingCommentary(reportsDetails.corporationFilingCommentary);
    setCorporationFilingScoreColorCode(reportsDetails.corporationFilingScoreColorCode);
    seTaxLienDetailList(JSON.stringify(reportsDetails.taxLienDetailList));
    setJudgementDetailList(reportsDetails.JudgementDetailList)
    setSuitsList(reportsDetails.suitsList);
    setCautionaryUccDetailsList(reportsDetails.cautionaryUccDetailsList);
    setLiensCount(reportsDetails.liensCount);
    setJudgmentCount(reportsDetails.judgmentCount);
    setLiensPlusJudgmentCount(reportsDetails.liensPlusJudgmentCount);
    setSuitsCount(reportsDetails.suitsCount);
    setTaxLiens(reportsDetails.taxLiens);
    setJudgment(reportsDetails.judgment);
    setSuits(reportsDetails.suits);
    setUccCount(reportsDetails.uccCount);
    setTotLiensAndJudgment(reportsDetails.totLiensAndJudgment);

    console.log("taxLienDetailList"+taxLienDetailList);

  // }

  setTimeout(() => {
    setDataStatus(true);
  }, 3000);

 
    }, [dataStatus]);

    const getTenantReportsDetails = async (organisationId,tenantId,businessToken) => {
      await tmGetTenantReportsDetailsRequest(organisationId,tenantId,businessToken);
      };

      const classes = useStyles();

      if(dataStatus){
    return(

      <div className={classes.app}>
      <PDFViewer width={800} height={500} showToolbar={false}>
      <PdfReport reportsDetails={reportsDetails} tenantName={tenantName} organisationName={organisationName} legalName={legalName} legalFormOfEntity={legalFormOfEntity} entityType={entityType} primaryNaicsCode={primaryNaicsCode} noOfLocations={noOfLocations} primaryIndustry={primaryIndustry} websiteUrl={websiteUrl} OFAC={OFAC} legalEntityName={legalEntityName}
        postalCode={postalCode} country={country} state={state} address={address} city={city} corporationFilingName={corporationFilingName} nameType={nameType} corporationFilingAddress={corporationFilingAddress} corporationFilingAddressType={corporationFilingAddressType} corporationFilingStatus={corporationFilingStatus} 
        filingDate={filingDate} incorporationState={incorporationState} incorporationDate={incorporationDate} registeredAgentAddress={registeredAgentAddress} registrationType={registrationType} creditScore={creditScore} trailingDayScore={trailingDayScore} threeToSixmonthsPaymentScore={threeToSixmonthsPaymentScore} dbtScore={dbtScore}
        industryDbtScore={industryDbtScore} trailingDayCommentary={trailingDayCommentary} threeToSixmonthsPaymentCommentary={threeToSixmonthsPaymentCommentary} industryBenchmarkCommentary={industryBenchmarkCommentary} yearStarted={yearStarted} trailingDayColorCode={trailingDayColorCode} threeToSixmonthsPaymentColorCode={threeToSixmonthsPaymentColorCode} creditScoreColorCode={creditScoreColorCode}
        primaryIndustry={primaryIndustry} websiteUrl={websiteUrl} cautionaryUccScore={cautionaryUccScore} cautionaryUccCommentary={cautionaryUccCommentary} cautionaryUccColorCode={cautionaryUccColorCode} taxLiensAndJudgementScore={taxLiensAndJudgementScore} taxLiensAndJudgementCommentary={taxLiensAndJudgementCommentary} taxLiensAndJudgementColorCode={taxLiensAndJudgementColorCode}
        civilSuitsScore={civilSuitsScore} civilSuitsCommentary={civilSuitsCommentary} civilSuitsColorCode={civilSuitsColorCode} corporationFilingScore={corporationFilingScore} corporationFilingCommentary={corporationFilingCommentary} corporationFilingScoreColorCode={corporationFilingScoreColorCode} yearsInBusinessCommentary={yearsInBusinessCommentary} taxLienDetailList={[taxLienDetailList]}
        judgementDetailList={[judgementDetailList]} liensCount={liensCount} judgmentCount={judgmentCount} liensPlusJudgmentCount={liensPlusJudgmentCount} suitsCount={suitsCount} taxLiens={taxLiens} judgment={judgment} uccCount={uccCount} totLiensAndJudgment={totLiensAndJudgment}/>

      </PDFViewer>

      <div className={classes.downloadLink}>
        <PDFDownloadLink
          document={<PdfReport reportsDetails={reportsDetails} tenantName={tenantName} organisationName={organisationName} legalName={legalName} legalFormOfEntity={legalFormOfEntity} entityType={entityType} primaryNaicsCode={primaryNaicsCode} noOfLocations={noOfLocations} primaryIndustry={primaryIndustry} websiteUrl={websiteUrl} OFAC={OFAC} legalEntityName={legalEntityName}
          postalCode={postalCode} country={country} state={state} address={address} city={city} corporationFilingName={corporationFilingName} nameType={nameType} corporationFilingAddress={corporationFilingAddress} corporationFilingAddressType={corporationFilingAddressType} corporationFilingStatus={corporationFilingStatus} 
          filingDate={filingDate} incorporationState={incorporationState} incorporationDate={incorporationDate} registeredAgentAddress={registeredAgentAddress} registrationType={registrationType} creditScore={creditScore} trailingDayScore={trailingDayScore} threeToSixmonthsPaymentScore={threeToSixmonthsPaymentScore} dbtScore={dbtScore}
          industryDbtScore={industryDbtScore} trailingDayCommentary={trailingDayCommentary} threeToSixmonthsPaymentCommentary={threeToSixmonthsPaymentCommentary} industryBenchmarkCommentary={industryBenchmarkCommentary} yearStarted={yearStarted} trailingDayColorCode={trailingDayColorCode} threeToSixmonthsPaymentColorCode={threeToSixmonthsPaymentColorCode} creditScoreColorCode={creditScoreColorCode}
          primaryIndustry={primaryIndustry} websiteUrl={websiteUrl} cautionaryUccScore={cautionaryUccScore} cautionaryUccCommentary={cautionaryUccCommentary} cautionaryUccColorCode={cautionaryUccColorCode} taxLiensAndJudgementScore={taxLiensAndJudgementScore} taxLiensAndJudgementCommentary={taxLiensAndJudgementCommentary} taxLiensAndJudgementColorCode={taxLiensAndJudgementColorCode}
          civilSuitsScore={civilSuitsScore} civilSuitsCommentary={civilSuitsCommentary} civilSuitsColorCode={civilSuitsColorCode} corporationFilingScore={corporationFilingScore} corporationFilingCommentary={corporationFilingCommentary} corporationFilingScoreColorCode={corporationFilingScoreColorCode} yearsInBusinessCommentary={yearsInBusinessCommentary} taxLienDetailList={[taxLienDetailList]}
          judgementDetailList={[judgementDetailList]} liensCount={liensCount} judgmentCount={judgmentCount} liensPlusJudgmentCount={liensPlusJudgmentCount} suitsCount={suitsCount} taxLiens={taxLiens} judgment={judgment} uccCount={uccCount} totLiensAndJudgment={totLiensAndJudgment}/>}
   
          fileName={tenantName  + ".pdf"}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Print"
          }
        </PDFDownloadLink>
      </div>
    </div>

);
    }else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
    }

Pdf2.propTypes = {

      loading: PropTypes.bool.isRequired,
      tmGetTenantReportsDetailsRequest: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    };
    
    const mapStateToProps = state => ({
      user: state.auth.loggedUser,
      reportsDetails: state.tenantmonitoring.reportsDetails,

    });              
    
    const mapDispatchToProps = dispatch => bindActionCreators(
      {
    
        ...TMActions,
      },
      dispatch,
    );
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Pdf2);