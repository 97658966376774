import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CurrencyFormat from 'react-currency-format';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TMHeatMapTenantRisk from "components/Charts/TMHeatMapTenantRisk.js";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";
import Badge from "components/Badge/Badge.js";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { textAlign } from "@material-ui/system";
import Cautionaryreports from "views/Reports/Cautionaryreports";
import Liensreport from "views/Reports/Liensreport";
import Civilsuitsreport from "views/Reports/Civilsuitsreport";
import TMAMHeatMapTenantRisk from "./TMAMHeatMapTenantRisk";
import AMMonitoredTenantRisk from "./AMMonitoredTenantRisk";
import moment from 'moment';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  gridBorder:{ border: 1, 
    borderColor: '#D3D3D3',
    borderStyle: 'solid'
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  vertical: { 
  padding:"20px"} 
};

const useStyles = makeStyles(styles);

const TMAMDerogatoryReportHeatMap = ({user,tenantId,tenantAddress,tenantName,pirsMonthNameValue,month,month1,year,scoreType,creditScoreData, tenantsScoreByType,tmTenantsScoreByTypeRequest,propertyName,propertyId,category,fundId,fundName,selectedRangeFrom,selectedRangeTo }) =>{
// export default function Widgets() {

  // const { tenantId,organizationId } = params;
  const history = useHistory();

  const location = useLocation();


  // const [selectedTenantId, setSelectedTenantId] = React.useState(location.state.tenantId);
  // const [uccScore,setUccScore] = React.useState(location.state.uccScore);
 
  // const [filedDate, setFiledDate] = React.useState(location.state.filedDate);
  // const [jurisdiction, setJurisdiction] = React.useState(location.state.jurisdiction);
  // const [securedPartyName, setSecuredPartyName] = React.useState(location.state.securedPartyName);
  // const [collateral, setCollateral] = React.useState(location.state.collateral);

  // const [filingType, setFilingType] = React.useState(location.state.filingType);
  // const [filingNumber, setFilingNumber] = React.useState(location.state.filingNumber);
  // const [amount, setAmount] = React.useState(location.state.amount);
  // const [plaintiff, setPlaintiff] = React.useState(location.state.plaintiff);

  
  const [uccFilingScore, setUccFilingScore] = React.useState(0);
  const [taxLiensJudgementScore, setTaxLiensJudgementScore] = React.useState(0);
  const [civilSuitsScore, setCivilSuitsScore] = React.useState(0);
  const [corporationFilingStatusScore, setCorporationFilingStatusScore] = React.useState(0);
  const [derogatoryScore, setDerogatoryScore] = React.useState(0);
  const [creditScore, setCreditScore] = React.useState(0);
  const [bankruptcyFilingScore, setBankruptcyFilingScore] = React.useState(10);
  const [buttonColor, setButtonColor] = React.useState("success");

  const [dataStatus, setDataStatus] = React.useState(false);

  const[showBackNavigation,setShowBackNavigation]=React.useState(false);

  const [uccCount, setUccCount] = React.useState(0);
  const [liensCount, setTaxLiensCount] = React.useState(0);
  const [judgmentCount, setJudgmentCount] = React.useState(0);
  const [civilSuitsCount, setCivilSuitsCount] = React.useState(0);
  const [filingStatusCount, setFilingStatusCount] = React.useState(0);
  const [bankruptcy, setBankruptcy] = React.useState(false);
  
  const [totTaxLienceAmount, setTotTaxLienceAmount] = React.useState("");
  const [totSuitsAmount, setTotSuitsAmount] = React.useState("");
  const [totalJudgmentAmount, setTotalJudgmentAmount] = React.useState("");
  const [totLiensAndJudgment, setTotLiensAndJudgment] = React.useState("");

    const [uccFilingScoreCSS, setUccFilingScoreCSS] = React.useState("");
    const [taxLiensJudgementScoreCSS, setTaxLiensJudgementScoreCSS] = React.useState("");
    const [civilSuitsScoreCSS, setCivilSuitsScoreCSS] = React.useState("");
    const [corporationFilingStatusScoreCSS, setCorporationFilingStatusScoreCSS] = React.useState("");
    const [bankruptcyFilingScoreCSS, setBankruptcyFilingScoreCSS] = React.useState("");
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

    const [userId, setUserId] = React.useState(user.id);

    const [showLien, setShowLien] = React.useState("");
    const [showUcc, setShowUcc] = React.useState("");
   
    const [showSuits, setShowSuits] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [showCautionaryreports, setShowCautionaryreports] = React.useState(false);
    const [showCivilsuitsreport, setShowCivilsuitsreport] = React.useState(false);
    const [showLiensreport, setShowLiensreport] = React.useState(false);
   
    // const [uccFilingScoreDescription, setUccFilingScoreDescription] = React.useState("");
    // const [civilSuitsScoreDescription, setCivilSuitsScoreDescription] = React.useState("");
    // const [taxLiensJudgementScoreDescription, setTaxLiensJudgementScoreDescription] = React.useState("");
    const [corporationFilingStatusScoreDescription, setCorporationFilingStatusScoreDescription] = React.useState("");
    const [derogatoryScoreCSS, setDerogatoryScoreCSS] = React.useState("");
    
    const [showBankruptcyFiling,setShowBankruptcyFiling] = React.useState(true);
    const [showDerog,setShowDerog] = React.useState(true);
    
    // const [tenantAddress, setTenantAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [prev1Year, setPrev1Year] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());

    function handleClickBack(event) {

      setShowBackNavigation(true);
 
    }
 
    function handleClickFundDashboard(event) {
      
    // setShowBackNavigation(true);
     history.push({ 
       pathname: '/dashboard'
      });
     
    }
  React.useEffect(() => {
    setShowBankruptcyFiling(false);
    setShowDerog(true);
   if(!dataStatus){
    getTmTenantScoreByType(tenantId,month1,year,scoreType);
   }
   else{

    setUccCount(tenantsScoreByType.uccCount);
    setTaxLiensCount(tenantsScoreByType.liensCount)
    setCivilSuitsCount(tenantsScoreByType.suitsCount)
    setJudgmentCount(tenantsScoreByType.judgmentCount)
    // setFilingStatusCount(derogatoryScoreCount.filingStatusCount)
    // setBankruptcy(derogatoryScoreCount.bankruptcy)
    setTotTaxLienceAmount(tenantsScoreByType.taxLiens);
    setTotSuitsAmount(tenantsScoreByType.suits)
    setTotLiensAndJudgment(tenantsScoreByType.totLiensAndJudgment);

    //angel
    setUccFilingScoreCSS(tenantsScoreByType.uccCss);
    //setUccFilingScoreDescription(tenantsScoreByType.uccCss);
    setCivilSuitsScoreCSS(tenantsScoreByType.suitsCss);
    //setCivilSuitsScoreDescription(tenantsScoreByType.suitsCommentary);
    setTaxLiensJudgementScoreCSS(tenantsScoreByType.liensJudgmentCss);
    //setTaxLiensJudgementScoreDescription(tenantsScoreByType.liensJudgmentCommentary);
    setCorporationFilingStatusScoreCSS(tenantsScoreByType.filingStatusCss);
    //setCorporationFilingStatusScoreDescription(tenantsScoreByType.filingStatusCommentary);
    setDerogatoryScoreCSS(tenantsScoreByType.deroCss);

    // setTenantAddress(tenantsScoreByType.tenantAddress);
    setCity(tenantsScoreByType.city);
    setState(tenantsScoreByType.state);
    setZip(tenantsScoreByType.zip);

    setCorporationFilingStatusScoreDescription(tenantsScoreByType.filingStatusCommentary);

    if(tenantsScoreByType.derogatoryScore==10){
      setShowBankruptcyFiling(true);
      setShowDerog(false);
          }

          if(tenantsScoreByType.derogatoryScore==10){
            setShowBankruptcyFiling(true);
            setShowDerog(false);
                }
      
          if(tenantsScoreByType.uccFilingScore==0 || tenantsScoreByType.uccFilingScore=="" || tenantsScoreByType.uccFilingScore==null || tenantsScoreByType.noApiDataAvailableUcc==false){
           setUccFilingScore("NA");
           
          }
          
          if(tenantsScoreByType.taxLiensJudgementScore==0 || tenantsScoreByType.taxLiensJudgementScore=="" || tenantsScoreByType.taxLiensJudgementScore==null || tenantsScoreByType.noApiDataAvailableLiensJudg==false){
            setTaxLiensJudgementScore("NA");
           
          }
          
          if(tenantsScoreByType.civilSuitsScore==0 || tenantsScoreByType.civilSuitsScore=="" || tenantsScoreByType.civilSuitsScore==null  || tenantsScoreByType.noApiDataAvailableSuits==false){
            setCivilSuitsScore("NA");
           
          }
          
          if(tenantsScoreByType.corporationFilingStatusScore==0 || tenantsScoreByType.corporationFilingStatusScore=="" || tenantsScoreByType.corporationFilingStatusScore==null || tenantsScoreByType.noApiDataAvailableCfs==false){
            setCorporationFilingStatusScore("NA");
        
          }
          
          if(tenantsScoreByType.derogatoryScore==0 || tenantsScoreByType.derogatoryScore=="" || tenantsScoreByType.derogatoryScore==null || tenantsScoreByType.noApiDataAvailableDerog==false){
            setDerogatoryScore("NA");
           
          }
    
   
    if(liensCount>0){

      setShowLien( <b>The company has <Link  component="button"
      variant="body2" onClick={handleClickOpenLiensreport}>{liensCount}</Link> Liens   and {judgmentCount} Judgements with a total value of  <CurrencyFormat value={totLiensAndJudgment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b>);
      }else{
      setShowLien(<b> The company has 0 Liens and 0 Judgements.</b> );
    }
   
    if(civilSuitsCount>0){

      setShowSuits( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenCivilsuitsreport}>{civilSuitsCount}</Link>  Filings with a total value of  ${totSuitsAmount} in Civil suits</b>);

      }else{
      setShowSuits(<b>The company has 0 Filings in Civil suits</b>);
    }


    if(uccCount>0){
      
      setShowUcc( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenCautionaryreports}>{uccCount}</Link>  Cautionary UCC filings</b>);
    }else{
      setShowUcc(  <b> The company has 0 Cautionary UCC filings </b>);
    }
    if(corporationFilingStatusScore==450){
setStatus("Acive")
    }else{
      setStatus("Inactive");
    }
  
  }
   
    // if(uccFilingScore > 401 && uccFilingScore <= 500){
    //   setUccFilingScoreCSS("#4caf50")
    //  }
    //  else if(uccFilingScore > 301 && uccFilingScore < 400){
    //   setUccFilingScoreCSS("#32cd32")
    //  }else if(uccFilingScore > 201 && uccFilingScore <= 300){
    //   setUccFilingScoreCSS("#ffdf00")
    //  }else if(uccFilingScore > 101 && uccFilingScore <= 200){
    //   setUccFilingScoreCSS("#ff9800")
    // }else if(uccFilingScore => 0 && uccFilingScore <= 100){
    //   setUccFilingScoreCSS("#FF0000")
    // }


    // if(taxLiensJudgementScore > 401 && taxLiensJudgementScore <= 500){
    //   setTaxLiensJudgementScoreCSS("#4caf50")
    //  }else if(taxLiensJudgementScore > 301 && taxLiensJudgementScore < 400){
    //   setTaxLiensJudgementScoreCSS("#32cd32")
    //  }else if(taxLiensJudgementScore > 201 && taxLiensJudgementScore <= 300){
    //   setTaxLiensJudgementScoreCSS("#ffdf00")
    //  }else if(taxLiensJudgementScore > 101 && taxLiensJudgementScore <= 200){
    //   setTaxLiensJudgementScoreCSS("#ff9800")
    // }else if(taxLiensJudgementScore => 0 && taxLiensJudgementScore <= 100){
    //   setTaxLiensJudgementScoreCSS("#FF0000")
    // }


    // if(civilSuitsScore > 401 && civilSuitsScore <= 500){
    //   setCivilSuitsScoreCSS("#4caf50")
    //  }else if(civilSuitsScore > 301 && civilSuitsScore < 400){
    //   setCivilSuitsScoreCSS("#32cd32")
    //  }else if(civilSuitsScore > 201 && civilSuitsScore <= 300){
    //   setCivilSuitsScoreCSS("#ffdf00")
    //  }else if(civilSuitsScore > 101 && civilSuitsScore <= 200){
    //   setCivilSuitsScoreCSS("#ff9800")
    // }else if(civilSuitsScore => 0 && civilSuitsScore <= 100){
    //   setCivilSuitsScoreCSS("#FF0000")
    // }


    // if(corporationFilingStatusScore > 401 && corporationFilingStatusScore <= 500){
    //   setCorporationFilingStatusScoreCSS("#4caf50")
    //  }else if(corporationFilingStatusScore > 301 && corporationFilingStatusScore < 400){
    //   setCorporationFilingStatusScoreCSS("#32cd32")
    //  }else if(corporationFilingStatusScore > 201 && corporationFilingStatusScore <= 300){
    //   setCorporationFilingStatusScoreCSS("#ffdf00")
    //  }else if(corporationFilingStatusScore > 101 && corporationFilingStatusScore <= 200){
    //   setCorporationFilingStatusScoreCSS("#ff9800")
    // }else if(corporationFilingStatusScore > 0 && corporationFilingStatusScore <= 100){
    //   setCorporationFilingStatusScoreCSS("#FF0000")
    // }



    if(bankruptcyFilingScore > 401 && bankruptcyFilingScore <= 500){
      setBankruptcyFilingScoreCSS("#4caf50")
     }else if(bankruptcyFilingScore > 301 && bankruptcyFilingScore < 400){
      setBankruptcyFilingScoreCSS("#32cd32")
     }else if(bankruptcyFilingScore > 201 && bankruptcyFilingScore <= 300){
      setBankruptcyFilingScoreCSS("#ffdf00")
     }else if(bankruptcyFilingScore > 101 && bankruptcyFilingScore <= 200){
      setBankruptcyFilingScoreCSS("#ff9800")
    }else if(bankruptcyFilingScore => 0 && bankruptcyFilingScore <= 100){
      setBankruptcyFilingScoreCSS("#FF0000")
    }
   
    setTimeout(() => {
      setDataStatus(true);
    }, 4000);
  
  }, [dataStatus,month,tenantsScoreByType]);
  const [open, setOpen] = React.useState(false);
    
  const handleClickOpen = () => {
    setOpen(true);
  };

  

const handleClickOpenCautionaryreports = () => {

  setShowCautionaryreports(true);
  setShowCivilsuitsreport(false);
  setShowLiensreport(false);
 
};

const handleClickOpenCivilsuitsreport = () => {
  setShowCautionaryreports(false);
  setShowCivilsuitsreport(true);
  setShowLiensreport(false);
 
};
const handleClickOpenLiensreport = () => {
  setShowCautionaryreports(false);
  setShowCivilsuitsreport(false);
  setShowLiensreport(true);
};
const handleCloseCautionaryreports = () => {
  setShowCautionaryreports(false);
};
const handleCloseCivilsuitsreport = () => {
  setShowCivilsuitsreport(false);
};


const handleCloseLiensreport = () => {
  setShowLiensreport(false);
};




  const getTmTenantScoreByType = async (tenantId,month,year,scoreType) => {
    console.log("getTmTenantScoreByType",tenantId);
    await tmTenantsScoreByTypeRequest(tenantId,month,year,scoreType);
    setUccFilingScore(tenantsScoreByType.uccFilingScore);
    setTaxLiensJudgementScore(tenantsScoreByType.taxLiensJudgementScore);
    setCivilSuitsScore(tenantsScoreByType.civilSuitsScore);
    setCorporationFilingStatusScore(tenantsScoreByType.corporationFilingStatusScore);
    setDerogatoryScore(tenantsScoreByType.derogatoryScore);
    setCreditScore(tenantsScoreByType.creditScore);
    setBankruptcyFilingScore(tenantsScoreByType.bankruptcyFilingScore);


   
  };

 
    
  

  

  const classes = useStyles();

  if(dataStatus){
  return (
 
    <div style={{width:'auto',paddingLeft:'30px',paddingRight:'30px'}}>
 
   
     {!showBackNavigation &&  <GridItem xs={12} sm={12} md={12}>
<h3 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'25px' }}>Tenant Name: {tenantName}</h3>
</GridItem>}
{!showBackNavigation &&<GridItem>
      <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px",paddingLeft:'25px'}}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>
      </GridItem>
  }
   {!showBackNavigation && <GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'20px'}}>



<Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> 
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>


</GridItem>}


{!showBackNavigation &&<GridItem xs={12} sm={12} md={12}>
   
 <h3 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'30px' }}>Derogatory Score - [{pirsMonthNameValue}&nbsp;{year}]</h3>
 
 </GridItem>}
  

      
 {!showBackNavigation &&<GridContainer className={classes.gridBorder}>
      
<GridItem xs={12} sm={3} md={3} className={classes.gridBorder}  justify = "center" style={{paddingTop:'10px',paddingBottom:'10px',borderRight:'none'}}>
      <h6 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'30px' }}></h6>  
<Button round  color={buttonColor} style={{color: "#000000",backgroundColor: derogatoryScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{derogatoryScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
 
</GridItem>


{/* <GridItem xs={12} sm={9} md={9} className={classes.vertical} style={{paddingLeft:'100px'}}justify = "right"> */}
<GridItem xs={12} sm={9} md={9} className={classes.gridBorder} style={{paddingLeft:'300px'}} justify = "right">
               
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'#FF0000'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'#4caf50'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>

  </GridItem>
  </GridContainer>}




       

  {!showBackNavigation &&showDerog&&  <GridContainer >




      <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'40px',paddingLeft:'40px'}}>

        <b style={{color:megaColor}}>     Cautionary UCC     </b>  
          
        </GridItem>

   

         <GridItem xs={12} sm={3} md={3} className={classes.vertical}  justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:'center'}}>
        
        <Button round  style={{color:"#000000",backgroundColor: uccFilingScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{uccFilingScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
      
        </GridItem>
       
  
   
        <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'40px'}}>
           
           

       {/* <b> The company has  <Link to={{pathname:'/admin/cautionaryreports/', state : {tenantId:tenantId} } }>{uccCount}</Link> Cautionary UCC filings </b> */}

      
       {showUcc}
       <Dialog fullWidth={true} 
  maxWidth={'lg'} open={showCautionaryreports} onClose={handleCloseCautionaryreports} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <GridItem align="right">
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseCautionaryreports}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
</GridItem>
<GridItem paddingLeft='200px'>
 {showCautionaryreports && <Cautionaryreports  year={year}  tenantId ={tenantId} uccScore={uccFilingScore} uccFilingScoreCSS={uccFilingScoreCSS} month1={month1}/>}
            </GridItem>
            {/* {showCautionaryreports && <Cautionaryreports   tenantId ={tenantId} uccScore={uccFilingScore}/>} */}
        
            </Dialog>
       
       {/* {showCautionaryreports && <Cautionaryreports  tenantId ={tenantId} uccScore={uccFilingScore}/>} */}
        
  </GridItem>




  <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'40px',paddingLeft:'40px'}}>
  <b style={{color:megaColor}}>    Tax Liens & Judgement    </b>
           
          </GridItem>
  
        
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical}  justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: taxLiensJudgementScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{taxLiensJudgementScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'40px'}}>
          {showLien}
         
          <Dialog fullWidth={true}
  maxWidth={'xl'} open={showLiensreport} onClose={handleCloseLiensreport}  aria-labelledby="form-dialog-title">
    
        <GridItem align="right">
       
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseLiensreport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
        
        
            </DialogActions>
        
            </GridItem>
            <GridItem paddingLeft='200px' align="justify">
            {/* {showLiensreport && <Liensreport  tenantId ={selectedTenantId} taxLiensJudgementScore={taxLiensJudgementScore}/>} */}
            {showLiensreport && <Liensreport  year={year} month1={month1} tenantId ={tenantId} liensJudgementScore={taxLiensJudgementScore} taxLiensJudgementScoreCSS={taxLiensJudgementScoreCSS}/>}
            {/* filedDate={filedDate} jurisdiction={jurisdiction} securedPartyName={securedPartyName} collateral={collateral} */}
            </GridItem>
            </Dialog>
     
    </GridItem>
  


    <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'40px',paddingLeft:'40px'}}>
    <b style={{color:megaColor}}>Civil Suits      </b>      
              
          </GridItem>
  
         
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: civilSuitsScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{civilSuitsScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'40px'}}>
      {showSuits} 
      <Dialog fullWidth={true}
  maxWidth={'lg'} open={showCivilsuitsreport} onClose={handleCloseCivilsuitsreport} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
       <GridItem align="right">
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseCivilsuitsreport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
            {/* <Button onClick={handleCloseCivilsuitsreport} color="info">
            Close
            </Button> */}
            </DialogActions>
            </GridItem>
            <GridItem paddingLeft='200px' align="justify">
            {/* {showCivilsuitsreport && <Civilsuitsreport  tenantId ={selectedTenantId} suitsScore={civilSuitsScore}/>} */}
            {showCivilsuitsreport && <Civilsuitsreport  year={year} month1={month1} tenantId ={tenantId} suitsScore={civilSuitsScore} civilSuitsScoreCSS={civilSuitsScoreCSS}/>}
            {/* filedDate={filedDate} jurisdiction={jurisdiction} securedPartyName={securedPartyName} collateral={collateral} */}
            </GridItem>
            </Dialog>  
    </GridItem>

    <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'40px',paddingLeft:'40px'}}>
        
     <b style={{color:megaColor}}> Corporation Filing Status    </b>       
          </GridItem>
  
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: corporationFilingStatusScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{corporationFilingStatusScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
         
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'40px'}}>
  <b> {corporationFilingStatusScoreDescription}</b>

     
    </GridItem>

</GridContainer>}
{!showBackNavigation &&showBankruptcyFiling&&  <GridContainer className={classes.gridBorder}>
  <GridItem xs={12} sm={3} md={3} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'40px'}}>
        
     <b style={{color:megaColor}}> Bankruptcy Filing  </b>       
          </GridItem> 
  
   <GridItem xs={12} sm={3} md={3} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'20px',textAlign:"center"}}>
          
          <Button round style={{backgroundColor: bankruptcyFilingScoreCSS,color:"#000000",fontSize:"20px"}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{derogatoryScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
          <br></br>
          </GridItem> 
       
    
     
      <GridItem xs={12} sm={6} md={6}  style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}}>
          <b>The company filed chapter 11 bankruptcy
</b>
     
    </GridItem> 
    </GridContainer>}
<br></br>
<br></br>

    {/* <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}}>
        
     <b style={{color:megaColor}}> Bankruptcy Filing  </b>       
          </GridItem> */}
  
  
           {/* <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px'}}>
          
          <Button round  style={{backgroundColor: bankruptcyFilingScoreCSS}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{bankruptcyFilingScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem> */}
       
    
     
          {/* <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3'}}>
          <b>The company filed chapter 11 bankruptcy
</b>
     
    </GridItem> */}

 

   
       
<br></br>
{showBackNavigation && <TMAMHeatMapTenantRisk source={"property"} labelStatus={true}  tenantName={tenantName} tenantId={tenantId} propertyName={propertyName} propertyId={propertyId} month={month} year={year} category={category} fundId={fundId} fundName={fundName} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} tenantAddress={tenantAddress} city={city} state={state} zip={zip}/>}
    
    </div>
  
  );
}else{
  return(

      <div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}

TMAMDerogatoryReportHeatMap.propTypes={
  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByTypeRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantsScoreByType :state.tenantmonitoring.tenantsScoreByType,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TMAMDerogatoryReportHeatMap);
