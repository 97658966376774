
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import DoneIcon from '@material-ui/icons/Done';

import CloseIcon from '@material-ui/icons/Close';
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];

  const BaseLineReport = ({tmGetBankoReportByTenantRequest,bankoReportList,
    filedDate,filingType,actionType,status,caseName,judgesInitials,
    courtName,courtAddress,courtTelephone,lawFirm,attorney,attorneyStreetAddress,attorneyCity,
    attorneyState,attorneyZip,attorneyPhone
  
    }) => {

  const [input, setInput] = React.useState('');
 // const [dataList, setDataList] = React.useState(tenantFailureStatusLogListByOrg);

  
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const[failureCount,setFailureCount] = React.useState("");


const [tenantId,setTenantId] = React.useState(location.state.tenantId);

const [tenantName,setTenantName] = React.useState(location.state.tenantName);
const [showBaseLineReport, setShowBaseLineReport] = React.useState(false);
const [dataStatus, setDataStatus] = React.useState(false);


// const[fileDate,setFileDate] = React.useState(location.state.fileDate);
// const[statusDate,setStatusDate] = React.useState(location.state.statusDate);
// const[chapter,setChapter] = React.useState(location.state.chapter);
// const[caseNumber,setCaseNumber] = React.useState(location.state.caseNumber);
// const[dispositionCode,setDispositionCode] = React.useState(location.state.dispositionCode);
// const[ssnTaxId,setSsnTaxId] = React.useState(location.state.ssnTaxId);
// const[business,setBusiness] = React.useState(location.state.business);
// const[debtorsCity,setDebtorsCity] = React.useState(location.state.debtorsCity);
// const[debtorsState,setDebtorsState] = React.useState(location.state.debtorsState);
// const[attorneyName,setAttorneyName] = React.useState(location.state.attorneyName);
// const[attorneyAddress,setAttorneyAddress] = React.useState(location.state.attorneyAddress);


const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));

React.useEffect(() => {
   
  if(!dataStatus){
    // getBankoReportByTenant(tenantId);
 

  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus]);



const getBankoReportByTenant = async (tenantId)=>{
  
    await tmGetBankoReportByTenantRequest(tenantId)
     //alert(bankoReportList);
    
      }
// 
  

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (tenantId) => {
            
    
    setTenantId(tenantId);
 
   }

  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};



    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{marginLeft:"60px",marginBottom:"60px"}}>

     {/* <h3 style={{fontWeight:'bold',fontSize:'22px',color:megaColor}}>Business Name: {tenantName}</h3> */}
         

{/* <Button  style = {{color:'#00628B',backgroundColor:'#81A594'}} className={classes.middleButton} onClick={() => handleChange(tenant.id,tenant.tenantName)}>  */}

  {/* </Button> */}
                  
  <GridItem>
 
          
           </GridItem>
          
          
         <br></br>

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}>   <label><b>Filed Date:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Moment(filedDate).format('MMM D, YYYY')}</h5>
              
                  
                
                </GridItem>
             

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Filing Type:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {filingType}</h5>
                
                </GridItem>

          
                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Action Type:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{actionType}</h5>
                </GridItem>
               
                <GridItem>
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Status:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;{status}</h5>
                </GridItem>

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Case Name:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{caseName}</h5>
                
                </GridItem>

                <GridItem >
               <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Judges Initials:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;
              {judgesInitials}</h5>
                </GridItem>
               

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Court Name:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {courtName}</h5>
                </GridItem>

                {/* <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Court Address: </b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;{courtAddress}</h5>
                </GridItem> */}

                <GridItem>
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Court Telephone:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{courtTelephone}</h5>
                </GridItem>
               
    
                <GridItem >
               <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Law Firm:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{lawFirm}</h5>
                </GridItem>


            
                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Attorney:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {attorney}</h5>
                </GridItem>

                <GridItem >
               <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Attorney Stree tAddress:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               {attorneyStreetAddress}</h5>
                </GridItem>

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Attorney City:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attorneyCity}</h5>
                </GridItem>
              
                <GridItem >
               <h5 style={{fontWeight:'bold',fontSize:'17px'}}> <label><b>Attorney State:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               {attorneyState}</h5>
                </GridItem>

                <GridItem >
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Attorney Zip:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;{attorneyZip}</h5>
                </GridItem>

                <GridItem>
                <h5 style={{fontWeight:'bold',fontSize:'17px'}}><label><b>Attorney Phone:</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attorneyPhone}</h5>
                </GridItem> 


        
              
              
              <Clearfix />
         
       </GridItem>
       
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

BaseLineReport.propTypes = {
    scoreExecutionLogListByTenantId: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmGetBankoReportByTenantRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  bankoReportList: state.tenantmonitoring.bankoReportList,
   loading: state.tenantmonitoring.loading,
 tenantId: state.tenantmonitoring.tenantId,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseLineReport);

