import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Link from '@material-ui/core/Link';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapFundRisk from "components/Charts/HeapMapFundRisk.js";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";
import PropTypes from 'prop-types';
import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import DerogatoryHeatMap from 'components/Charts/DerogatoryReportHeatMap.js';
import PirsReportHeatMap from 'components/Charts/PirsReportHeatMap.js';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import HeapMapTenantRisk from 'components/Charts/HeapMapTenantRisk.js';

import CreditReportHeatMap from 'components/Charts/CreditReportHeatMap.js';
import Badge from "components/Badge/Badge.js";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

//dddd
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);

const Report = ({user,tenantsScoreByType,tmTenantsScoreByTypeRequest
}) => {
  const history = useHistory();
  const location = useLocation();
 const [selectedTenantId, setSelectedTenantId] = React.useState(location.state.tenantId);
 const [selectedTenantName, setSelectedTenantName] = React.useState(location.state.tenantName);
 const [monthNumber, setMonthNumber] = React.useState(location.state.monthNumber);
 const [scoreType, setSetscoreType] = React.useState(location.state.scoreType);
 const [monthNameValue, setMonthNameValue] = React.useState("");
 const [scoreCategoryValue, setScoreCategoryValue] = React.useState("");
 const[showBackNavigation,setShowBackNavigation]=React.useState(false);



  const [input, setInput] = React.useState('');
  const [showCreditReportHeatMap, setShowCreditReportHeatMap] = React.useState(false);
  const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  const [showDerogatoryHeatMap, setShowDerogatoryHeatMap] = React.useState(false);
  const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  const [showPirsHeatMap, setShowPirsHeatMap] = React.useState(false);
  const [fundActive, setFundActive] = React.useState(true);
  const [tenantActive, setTenantActive] = React.useState(false);
  const [organizationId, setOrganizationId] = React.useState("1234567890");
  const [fundDashboard, setFundDashboard] = React.useState("");
  const [isLoadingPage, setIsLoadingPage] = React.useState(false);
  const [selectedFundId, setSelectedFundId] = React.useState("");
  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);
  const [year, setYear] = React.useState(2020);

  const [dataStatus, setDataStatus] = React.useState(false);
  const [showHeatMapTenantRisk1, setShowHeatMapTenantRisk1] = React.useState(false);
  const [headingText, setHeadingText] = React.useState("Overall Fund  Risk Score By Month");
  const [monthName,setMonthName]= React.useState("Overall Fund  Risk Score By Month");
  const [userId, setUserId] = React.useState(user.id);
  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));


  function handleClickBack(event) {
  
       
    setShowBackNavigation(true);

  }

  function handleClickFundDashboard(event) {
    
  // setShowBackNavigation(true);
   history.push({ 
     pathname: '/dashboard'
    });
   
  }

  const handleChange = (fundId) => {
    setSelectedFundId(fundId);
    
    // setShowHeatMap(false);
    // setShowHeatMapFundRisk(true);
    setHeadingText("Tenant  Risk by Fund by Month");  
    setFundActive(false);
    setTenantActive(false);
  }

  const calc =()=>{

    var scoreCategory;
    var monthNameVar;


    // if(monthNumber==0){
    //   monthNameVar="nov" ;
    
       if(monthNumber==0){
        monthNameVar="dec";
        
      }else if(monthNumber==1){
        monthNameVar="jan" ;
        
      }else if(monthNumber==2){
        monthNameVar="feb" ;
        setYear(2021);
        
      }else if(monthNumber==3){
        monthNameVar="mar" ;
        setYear(2021);
      }else if(monthNumber==4){
        monthNameVar="apr" ;
        setYear(2021);
        
      }else if(monthNumber==5){
        monthNameVar="may" ;
        setYear(2021);
      }else if(monthNumber==6){
        monthNameVar="jun" ;
      }else if(monthNumber==7){
        monthNameVar="jul" ;
      }else if(monthNumber==8){
        monthNameVar="aug" ;
      }else if(monthNumber==9){
        monthNameVar="sep" ;
      }else if(monthNumber==10){
        monthNameVar="oct" ;
      }
      else{
          console.log("No type");
      }

    if(scoreType=="Pandemic Risk Score"){

      scoreCategory="PIRS";
      setShowPirsHeatMap(true);
      setShowDerogatoryHeatMap(false);
      setShowCreditReportHeatMap(false);
     

  }else if(scoreType=="Credit Score"){
  
      scoreCategory="CREDIT";
      setShowPirsHeatMap(false);
      setShowDerogatoryHeatMap(false);
      setShowCreditReportHeatMap(true) ;
  

  }else if(scoreType=="Derogatory Public Filings"){
      scoreCategory="DEROGATORY";
      setShowPirsHeatMap(false);
      setShowDerogatoryHeatMap(true);
      setShowCreditReportHeatMap(false) 
     
  }else{
      console.log("No type");
  }


setScoreCategoryValue(scoreCategory);
setMonthNameValue(monthNameVar);


  }


  React.useEffect(() => {

    setTimeout(() => {
      calc();
    
    }, 3000);

//  if(!dataStatus && monthNameValue!="" && monthNameValue!='undefined' && scoreCategoryValue!="PIRS"){

//   getTmTenantScoreByType(selectedTenantId,monthNameValue,year,scoreCategoryValue);
//  } 
  


  }, [monthNameValue,scoreCategoryValue,tenantsScoreByType,dataStatus]);

  

  const getTmTenantScoreByType = async (selectedTenantId,monthNameValue,year,scoreCategoryValue) => {
    await tmTenantsScoreByTypeRequest(selectedTenantId,monthNameValue,year,scoreCategoryValue);
    setDataStatus(true); 
   
  };

 
  const [open, setOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenCreditReportHeatMAp = () => {
    setShowCreditReportHeatMap(true);
    setShowDerogatoryHeatMap(false);
    setShowPirsHeatMap(false);
    
  };
  const handleClickOpenDerogatoryReportHeatMAp = () => {
    setShowCreditReportHeatMap(false);
    setShowDerogatoryHeatMap(true);
    setShowPirsHeatMap(false);
  };
  const handleClickOpenPirsHeatMAp = () => {
    setShowPirsHeatMap(true);
    setShowCreditReportHeatMap(false);
    setShowDerogatoryHeatMap(false);
   
  };

  const handleCloseCreditReportHeatMap = () => {
    setShowCreditReportHeatMap(false);
   
  };
  const handleCloseDerogatoryReportHeatMap = () => {
    setShowDerogatoryHeatMap(false);
  
  };
  const handleClosePirsHeatMAp = () => {
    setShowPirsHeatMap(false);
   
  };
  
  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
// if(tenantsScoreByType){
  return (
    <Card>
     <div>
     
    

      <GridContainer style={{margin:'10px'}}>

<GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>
   <Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> <ArrowBackIosSharpIcon fontSize="inherit" className={classes.icon} />
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>
</Breadcrumbs>
</GridItem>
     
    <GridItem xs={12} sm={12} md={12}>
   

      {/* { showCreditReportHeatMap && <CreditReportHeatMap  params={{"tenantId":selectedTenantId,"month":monthNameValue,"year":year,"scoreType":scoreCategoryValue}}/> }
      { showDerogatoryHeatMap && <DerogatoryHeatMap params={{"tenantId":selectedTenantId,"month":monthNameValue,"year":year,"scoreType":scoreCategoryValue}}/> }
       */}

{/* <HeatMapTenantRisk onClick={handleClickOpenCredit}/> */}

{/* {<HeatMapTenantRisk tenantId={selectedTenantId} tenantName={selectedTenantName}/>} */}

{/* 
       <Dialog fullWidth={true}
  maxWidth={'md'}  open={showCreditReportHeatMap} close={handleCloseCreditReportHeatMap} > <GridItem align="right">
  <DialogActions>
            <Button 
            justIcon
            round
            simple
            onClick={handleCloseCreditReportHeatMap}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
            </DialogActions>
  </GridItem>  */}
  
  
  {showCreditReportHeatMap && <CreditReportHeatMap  tenantId ={selectedTenantId} tenantName={selectedTenantName} month={monthNameValue} year={year} scoreType={scoreCategoryValue}/>}
    {/* </Dialog> */}
  {/* <Dialog fullWidth={true}
  maxWidth={'md'}  open={showDerogatoryHeatMap} close={handleCloseDerogatoryReportHeatMap} > <GridItem align="right">
  <DialogActions>
            <Button 
            justIcon
            round
            simple
            onClick={handleCloseDerogatoryReportHeatMap}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
            </DialogActions>
  </GridItem>  */}

      { showDerogatoryHeatMap && <DerogatoryHeatMap  tenantId ={selectedTenantId} tenantName={selectedTenantName} month={monthNameValue} year={year} scoreType={scoreCategoryValue}/> }
      {/* </Dialog> */}
      {/* <Dialog fullWidth={true}
  maxWidth={'md'}  open={showPirsHeatMap} close={handleClosePirsHeatMAp} > <GridItem align="right">
  <DialogActions>
            <Button 
            justIcon
            round
            simple
            onClick={handleClosePirsHeatMAp}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
            </DialogActions>
  </GridItem>  */}
      { showPirsHeatMap && <PirsReportHeatMap tenantId ={selectedTenantId} tenantName={selectedTenantName} month={monthNameValue} year={year} scoreType="PIRS"/> }
{/* </Dialog> */}
      </GridItem>
      </GridContainer>  
        
    </div>
     </Card> 
  );


}



Report.propTypes = {
  tmTenantsScoreByTypeRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantsScoreByType: state.tenantmonitoring.tenantsScoreByType,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Report);

