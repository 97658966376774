import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";




const useStyles = makeStyles(styles,styles1);

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
 
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const Notificationsettings = ({user, tmSaveNotificationSettingsRequest,tmNotificationSettingsByIdRequest,notificationSettingsById,tmEditNotificationSettingsRequest,editNotificationSettings}) => {
  const history = useHistory();
  const [input, setInput] = React.useState('');
  const [userId, setUserId] = React.useState(user.id);

  const getSaveNotificationSettings = async (notificationSettings) => {
    tmSaveNotificationSettingsRequest(notificationSettings);
    
    };
    // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");


// const [propertyName,setPropertyName] =React.useState("");
// const [city,setCity] =React.useState("");
// const [address1,setAddress1] =React.useState("");
// const [state,setState] =React.useState("");
// const [fipsCounty,setFipsCounty] =React.useState("");
// const [msa,setMsa] =React.useState("");
// const [buildingClass,setBuildingClass] =React.useState("");
// const [noOfParkingSpaces,setNoOfParkingSpaces] =React.useState("");
// const [propertyOccupancy,setPropertyOccupancy] =React.useState("");
// const [marketVaccancy,setMarketVaccancy] =React.useState("");
// const [yearBuilt,setYearBuilt] =React.useState("");
// const [subMarketVaccancy,setSubMarketVaccancy] =React.useState("");

const [emailId,setEmailId]=React.useState("");
const [mobileNumber,setMobileNumber]=React.useState("");
const [fundOverAllScoreEnabled,setFundOverAllScoreEnabled]=React.useState(true);
const [tenantBankruptcyEnabled,setTenantBankruptcyEnabled]=React.useState(true);

const [dataStatus,setDataStatus]=React.useState(false);

const [showButtonDisable,setShowButtonDisable]=React.useState(false);

const [userType,setUserType]=React.useState(user.mainRole);
// const [firstName,setFirstName]=React.useState("");
// const [lastName,setLastName]=React.useState("");
const [firstName,setFirstName]=React.useState(user.firstName);
const [lastName,setLastName]=React.useState(user.lastName);
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [id,setId]=React.useState("");

// check Box
const [checked, setChecked] = React.useState([true]);
  const [checkedOverallScore, setCheckedOverallScore] = React.useState([true]);
  const [checkedBankruptcy, setCheckedBankruptcy] = React.useState([true]);

  const [alert1,setAlert1] = React.useState(false);

  const handleToggle = (event) => {
  
    if(event.target.name==='overall_score'){
      setCheckedOverallScore(event.target.checked);
    }
    else if(event.target.name==='bankruptcy'){
      setCheckedBankruptcy(event.target.checked);
    }
    else{

    }
  };

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  
  return event.target.checked
  };


  useEffect(() => {
    
    setShowButtonDisable(false);

    setEmailId("");
    setMobileNumber("");
    setFundOverAllScoreEnabled("");
    setTenantBankruptcyEnabled("");

    getNotificationSettingsById(userId);

      setEmailId(notificationSettingsById.emailId);
      setMobileNumber(notificationSettingsById.mobileNumber);
      setId(notificationSettingsById.id);
      setFundOverAllScoreEnabled(notificationSettingsById.fundOverAllScoreEnabled);
      setTenantBankruptcyEnabled(notificationSettingsById.tenantBankruptcyEnabled)
    
    
      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
  
    },[dataStatus,notificationSettingsById!==""]);

    const getNotificationSettingsById = async (userId) => {
      await tmNotificationSettingsByIdRequest(userId);
     //alert("get"+userId);
       };
   //alert(JSON.stringify(user));
   const getEditNotificationSettings = async (notificationSettings) => {
     tmEditNotificationSettingsRequest(notificationSettings);
     };
     function hideAlert1(event) {
      setAlert1(false);
      setShowButtonDisable(true);
      }

  const submitForm = () => {

 const notificationSettings={

      id:id,
      emailId:emailId,
      mobileNumber:mobileNumber,
      fundOverAllScoreEnabled:fundOverAllScoreEnabled,
      tenantBankruptcyEnabled:tenantBankruptcyEnabled,
      userId:userId,
      userType:userType,
      firstName:firstName,
      lastName:lastName,
      organizationId:organizationId,
    };
    // alert(notificationSettings.id);
    if(notificationSettings.id=='null'){
      
     tmSaveNotificationSettingsRequest(notificationSettings);
     history.push( setAlert1(true),{ 
 
      //pathname: '/admin/notification-settings',
        //state:{ message:"Fund Saved Successfully" }
       });
    
  
    }
     //alert(JSON.stringify(notificationSettings));
   else{
    tmEditNotificationSettingsRequest(notificationSettings);
    history.push( setAlert1(true),{ 
 
    //pathname: '/admin/notification-settings',
      //state:{ message:"Fund Saved Successfully" }
     });
   
// history.push({ 
  
//   state:{ message:"Details Saved Successfully" }
//  });

   }
  };

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4><b>
              Notification Settings
           </b>
              </h4> 
            </CardHeader>
            <CardBody>
<br></br>

            

        
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Email Id"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                     labelText="Email Alert Recipients"
                     id="emailId"
                     value={emailId}
                     success={requiredState === "success"}
                     error={requiredState === "error"}
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                       onChange: event => {
                         if (verifyLength(event.target.value, 1)) {
                           setEmailId(event.target.value);
                         } else {
                          setEmailId("");
                           setrequiredState("error");
                         }
                         setrequired(event.target.value);
                        
                       },
                    
                       type: "text",
                       endAdornment:
                         requiredState === "error" ? (
                           <InputAdornment position="end">
                             <Close className={classes.danger} />
                           </InputAdornment>
                         ) : (
                           undefined
                         )
                     }}
                  />
                </GridItem>
                
              </GridContainer>

              
              <GridContainer>
                
                <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    labelText="Mobile"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
               
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    labelText="Example +15243567896,+14352345678"
                    id="mobileNumber"
                    value={mobileNumber}
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setMobileNumber(event.target.value);
                        } else {
                          setMobileNumber("");
                          setrequiredState("error");
                       }
                       setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
              

                 
                </GridItem>
                  {/* <CustomInput
                    labelText="Mobile"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    labelText="Example +15243567896,+14352345678"
                    id="mobileNumber"
                    value={mobileNumber}
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setMobileNumber(event.target.value);
                        } else {
                          setrequiredState("error");
                       }
                       setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem> */}
              
                
              </GridContainer>
              
             
              
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Enable Notification for:"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
            
    
                <GridItem xs={12} sm={12} md={3}>
                <br></br>
                <FormControlLabel
                
          control={
            
            <Checkbox
            value={fundOverAllScoreEnabled}
            tabIndex={-1}
            
            onClick={handleToggle}
            checked={fundOverAllScoreEnabled}
           
            defaultChecked={classes.uncheckedIcon}
          //  onChange={handleToggle}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
             //unchecked: classes.unchecked,
              root: classes.checkRoot
            }}
            //angel
            inputProps={{
              onChange: event => {
                if (event.target.checked) {
                  setFundOverAllScoreEnabled(true);
                } else {
                 
                  setFundOverAllScoreEnabled(false);
                }
                
              },
              
            }}
            />
          }
          classes={{ label: classes.label }}
          label="Overall Score"
          name="overall_score"
        />
                </GridItem>


                <GridItem xs={12} sm={12} md={3}>
                <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={tenantBankruptcyEnabled}
            tabIndex={-1}
            onClick={handleToggle}
            checked={tenantBankruptcyEnabled}
            defaultChecked={classes.uncheckedIcon}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              onChange: event => {
                if (event.target.checked) {
                  setTenantBankruptcyEnabled(true);
                } else {
                  setTenantBankruptcyEnabled(false);
                }
                
              },
              
            }}
            />
          }
         
          classes={{ label: classes.label }}
          label="Bankruptcy"
          name="bankruptcy"
          
        />
        
        </GridItem>
     
</GridContainer>
{/* <GridItem xs={12} sm={12} md={4}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Public Record"
        />
              
                </GridItem>
             
              <br></br>
              <h4><strong>News Category</strong></h4>
              <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Source"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Social Media"
        />
        </GridItem>

<GridItem xs={12} sm={12} md={4}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="News"
        />
              
                </GridItem>
                

                </GridContainer>

                <h6><strong>Categories</strong></h6>

                <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Bankruptcy & Restructuring"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Financial & Business Performance"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Partnerships & Alliances"
                />
              </div>

              
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Events"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="New Offerings"
                />
              </div> */}
<br></br>
<br></br>
              <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
       <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}} >
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>

<div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}
// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Saved Successfully!
</SweetAlert>
</div>
              

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
    }
  }
Notificationsettings.propTypes = {

  // notificationSettingsById: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     userId: PropTypes.string,
  //   }),
  // ).isRequired,


  loading: PropTypes.bool.isRequired,
  tmSaveNotificationSettingsRequest: PropTypes.func.isRequired,
  // tmFundListRequest: PropTypes.func.isRequired,
  tmNotificationSettingsByIdRequest: PropTypes.func.isRequired,
  tmEditNotificationSettingsRequest:PropTypes.func.isRequired,
  // tmAssetManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  notificationSettingsById: state.tenantmonitoring.notificationSettingsById,
  editNotificationSettings:state.tenantmonitoring.editNotificationSettings,
  //assetManagerList: state.tenantmonitoring.assetManagerList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default  connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notificationsettings);
