import React from 'react';
import styled from 'styled-components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const handleClick = (id,propertyName,fundName,fundManagerName,parentCallBack) => {
 
  const fund_object = {
    propertyId: id,
    propertyName: propertyName,
    fundName:fundName,
    fundManagerName:fundManagerName
  };
  parentCallBack(fund_object);

  
  };

const setDirection = (direction) => {
  const defaults = {
    horizontal: 'row nowrap',
    vertical: 'column nowrap',
  };
  return defaults[direction || 'horizontal'];
};

export const renderMenuItems = ({ items,parentCallBack,height }) => (

  
  
  items.map(
    (item, index) => <li key={ index } style={{float:'right',height:height+'px'}} onClick={()=>handleClick(item.id,item.propertyName,item.fundName,item.fundManagerName,parentCallBack)} >{ item.propertyName }{item.currentStatus==='up' ? <ArrowUpwardIcon/> : item.currentStatus==='down'?<ArrowDownwardIcon/>:<TrendingFlatIcon/>}</li>
  )
);

export const MenuBaseContainer = styled.ul`
list-style: none;
font-weight: bold;
float: right;
${props => (props.direction ? `flex-flow: ${setDirection(props.direction)}` : '')};
`;

const MenuBase = props => (
  <MenuBaseContainer { ...props }>
    { renderMenuItems(props) }
  </MenuBaseContainer>
);

export default MenuBase;