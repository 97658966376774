import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import UserTable from "views/User/UserTable.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import user from "store/ducks/auth";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  
};

const useStyles = makeStyles(styles);
 

// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const Userlist = ({
    userList, tmUserListRequest, tmUserListByOrgRequest,tmUserDeleteRequest,userDelete,tmUserEnableRequest,userEnable,
    tmUserDisableRequest,userDisable
  }) => {
    const location = useLocation();
    const [dataList, setDataList] = React.useState(userList);
    const [messageobj, setMessageobj] = React.useState("");
    const [userId, setUserId] = React.useState("");
    const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
   const [organisationName, setOrganisationName] = React.useState(location.state.organisationName);
    // const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    // const [disabled, setDisabled] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    const [dataStatus,setDataStatus]=React.useState(false);
    useEffect(() => {
 
      getUserList(organisationId);
      if(location.state){
        setMessageobj(location.state.message);
      }
      setTimeout(() => {
        setDataStatus(true);
      }, 1000);

     
      }, [messageobj,dataStatus]);

      const hideAlert = () => {
        setAlert(false);
        // history.push({ 
        //   pathname: '/admin/select-org',
        //   state: {requestFrom:"user"}
        //  });
      
      } 
   
      const [alert1,setAlert1] = React.useState(false);
      const [alert,setAlert] = React.useState(false);
      
      // const hideAlert = () => {
      //   setAlert(false);
      // }
      const hideAlert1 = () => {
        setAlert1(false);
      }
      
      // function hideAlert1(event) {
      //   history.push({ 
      //     pathname: '/admin/User/userlist'
      //    });
      //   }

    const getUserList = async (organisationId) => {
      await tmUserListByOrgRequest(organisationId);
      };


      // const deleteUser = async (userId) => {
      //   await tmUserDeleteRequest(userId);
     
      //   };
        const enableUser = async (userId) => {
          await tmUserEnableRequest(userId);
        
          };
          const disableUser = async (userId) => {
            await tmUserDisableRequest(userId);
           
            };
  
          //   const submit1 = (userId) => {
          //     setAlert1(true);
          // }
          const submit1 = (userId) => {
            
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui' 
                  style={{
                    backgroundColor: "#81A594 ",
                   fontWeight:"bold",
                    color:'#fff',
                  
                    textAlign: "center",
                   // alignItems: "center",
                    paddingTop: "20px",
                    height: "150px",
                    width: "300px",
                    }}>
                    <h1 >Are you sure?</h1>
                    <p fontWeight="bold">You want to Enable this User?</p>
                    <button  backgroundColor= "#000"
                       onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    <button
                       
                      onClick={() => {
                        enableUser(userId);
                        onClose();
                      }}
                    >
                    &nbsp; &nbsp; Yes &nbsp;&nbsp;
                    </button>
                  </div>
                );
              }
            });
       
          }
        
          const submit2 = (userId) => {
          
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui' 
                  style={{
                    backgroundColor: "#81A594 ",
                   fontWeight:"bold",
                    color:'#fff',
                  
                    textAlign: "center",
                   // alignItems: "center",
                    paddingTop: "20px",
                    height: "150px",
                    width: "300px",
                    }}>
                    <h1 >Are you sure?</h1>
                    <p fontWeight="bold">You want to Disable this User?</p>
                    <button  backgroundColor= "#000"
                       onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    <button
                       
                      onClick={() => {
                        disableUser(userId);
                        onClose();
                      }}
                    >
                    &nbsp; &nbsp; Yes &nbsp;&nbsp;
                    </button>
                  </div>
                );
              }
            });
        
          }
              //
                  
// 
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

const history = useHistory();

  
  const classes = useStyles();
  if(dataStatus){
  return (
    <GridContainer>

{/* <GridItem xs={12}>
     <OrgSelected orgName={organisationName}/>
     </GridItem> */}
     
      <GridItem xs={12}>
        <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >User List</text> <span style={{marginLeft:"640px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
     <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"tenant"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                  
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }
   <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
         
           onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please select Company
        </SweetAlert>
      
                     </div>

{/* <div>
  <SweetAlert
        info
        show={alert1}
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to enable user?"
        onConfirm={() => enableUser(userId)}
        onCancel={() => hideAlert1()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
        showConfirm
      >
       
      </SweetAlert>

</div> */}

{/* <GridItem xs={12}>
     <OrgSelected orgName={organisationName}/>
     </GridItem> */}
<GridItem style={{textAlign: "right" }} xs={12} sm={12}>
      <Button style={{ background: megaColor,color:"white",fontSize:"14px"}} size="sm" round  onClick={() => history.push({
  pathname: '/admin/userform',
 
  state: { organisationId: organisationId,organisationName:organisationName }
})}><b><AddCircleIcon/>&nbsp;Create New User</b></Button>
 </GridItem>
            <UserTable
              columns={[
                
             // {
            //     Header: "User Name",
            //     accessor: "login",
                
            //     //width: getColumnWidth(UserList, 'tenantName', 'Tenant'),
            //     Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification/', state :{tenantId : row.original.tenantId, contifyCompanyId:row.original.tenantContifyId, naics:row.original.naics, accountNumber : row.original.bankoAccountNumber,tenantName:row.original.tenantName} } }>{row.original.login}</Link>
             
              
            //   ),
             
            //     // Cell: ({ cell,row ,
            //     //   column: { tenantId } }) => (
                
            //     // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
            //  // }
            //  width: getColumnWidthNew(userList, 'accessor', 'username'),
            // },
            {
              Header: "User Name",
              accessor: "login",
              width: 50,
            },
            {
              Header: "First Name",
              accessor: "firstName",
              width: 50,  
            },
                
              {
                Header: "Last Name",
                accessor: "lastName",
                width: 50,
              },
              {
                Header: "Role",
                accessor: "role",
                width: 50,
              },
              
            //  
                {
                  Header: "Email",
                  
                  accessor: "email",
                  width: 50,
                  //width: getColumnWidthNew(userList, 'accessor', 'enabled'),
                },
                {
                  Header: "Actions",
                accessor: "actions",
                disableFilters: true,
                disableSortBy: true
                 },
        //         {
        //           Header: "Actions",
        //         //  Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/user-edit', state :{userId : row.original.id} } }><Edit style={{ color: 'green' }} /></Link>),
        //         Cell: ({ cell,row }) => (  <div className={classes.buttonGroup}>
        //           <Button color="info" size="sm" className={classes.firstButton}   onClick={() => {
        //   submit(row.original.id);
        // }}>Delete</Button>
        //        </div>),  
              
              ]}
              data={userList.map((prop, key) => {
                return {
                  id: prop.id,
                  login: prop.login,
                  firstName: prop.firstName,
                  lastName: prop.lastName,
                  activated: prop.activated,
                  enabled:prop.enabled,
                  role: prop.role,
                  email:prop.email,
                  actions: (
                    // we've added some custom button actions
                    <div>
                   <Link to={{pathname:'/admin/user-edit', state :{userId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Edit User">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                     
                      {/* use this button to remove the data row */}
                      {/* <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit(prop.id)}}
                        color="info"
                        className="remove"
                      >
                        < DeleteIcon/>
                      </Button>{" "} */}
                      {/* <Link to={{pathname:'/admin/enable-user', state :{userId : prop.id} } }>  */}
              
                      {prop.activated ? 
                       <Tooltip classes={{ tooltip: classes.customWidth }} title="Disable User">
                      <PersonAddDisabledIcon style={{ color: "skyblue" }}  onClick={() =>{submit2(prop.id)}}/>
                      </Tooltip> : 
                       <Tooltip classes={{ tooltip: classes.customWidth }} title="Enable User">
                      <PersonIcon  style={{ color: "skyblue" }} onClick={() =>{submit1(prop.id)}}/> 
                      </Tooltip>}
                      {/* <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit1(prop.id)}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="enable"

                      >
                        <PersonIcon />
                      </Button>{" "}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit2(prop.id)}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="disable"
                        disabled={disabled}
                      >
                        <PersonAddDisabledIcon />
                      </Button>{" "} */}
                      {/* </Link> */}
                    </div>
                  )
                };
              })}

            //  data={userList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({username: rowData.login, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


Userlist.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmUserListRequest: PropTypes.func.isRequired,
  tmUserListByOrgRequest: PropTypes.func.isRequired,
  tmUserDeleteRequest: PropTypes.func.isRequired,
  tmUserEnableRequest: PropTypes.func.isRequired,
  tmUserDisableRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  userList: state.tenantmonitoring.userList,
  userDelete: state.tenantmonitoring.userDelete,
  userDisable: state.tenantmonitoring.userDisable,
  userEnable: state.tenantmonitoring.userEnable,
  
  loading: state.tenantmonitoring.loading,
  tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Userlist);

