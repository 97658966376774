import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";


const useStyles = makeStyles(styles,styles1);






const Experience = ({}) => {

  const [input, setInput] = React.useState('');


    // type validation-jain
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");

const [requiredName, setrequiredName] = React.useState("");
const [requiredCompanyName, setrequiredCompanyName] = React.useState("");


const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredAddressName, setrequiredAddressName] = React.useState("");

const [requiredLocation, setrequiredLocation] = React.useState("");
const [requiredLocationName, setrequiredLocationName] = React.useState("");


// const [rquiredLongitude, setLongitude] = React.useState("");
// const [requiredNameLongitude, setrequiredNameLongitude] = React.useState("");

// const [rquiredLatitude, setLatitude] = React.useState("");
// const [requiredNameLatitude, setrequiredNameLatitude] = React.useState("");



// const [requiredContact, setContactName] = React.useState("");
// const [requiredContactName, setrequiredContactName] = React.useState("");

// const [requiredContactNum, setCantactNumber] = React.useState("");
// const [requiredContactNumber, setrequiredContactNumber] = React.useState("");

// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");

// const [requiredPhoneNo1, setPhoneNum1] = React.useState("");
// const [requiredNamePhone1, setrequiredNamePhone1] = React.useState("");

// const [requiredPhoneNo2, setPhoneNum2] = React.useState("");
// const [requiredNamePhone2, setrequiredNamePhone2] = React.useState("");

// const [requiredPhoneNo3, setPhoneNum3] = React.useState("");
// const [requiredNamePhone3, setrequiredNamePhone3] = React.useState("");









const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



// check Box
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };



  
  
//   const defaultProps = {
//     options: variant,
//     getOptionLabel: (option) => option.title,
//   };


//   const flatProps = {
//     options: variant.map((option) => option.title),
//   };
  


  const handleChange = (value) => {
 
  }

  

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};


// function that verifies if value contains only numbers
const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("error");
    }

    if (requiredCompanyName=== "") {
      setrequiredCompanyName("error");
    }
    if(requiredLocationName===""){
      setrequiredLocationName("error")
    }
    
      
    //   if (requiredNameLongitude === "") {
    //     setrequiredNameLongitude("error");
    //   }
    //   if (requiredNameLatitude === "") {
    //     setrequiredNameLatitude("error");
    //   }

    //   if (requiredProductName === "") {
    //     setrequiredProductName("error");
    //   }
    //   if (requiredContactName === "") {
    //     setrequiredContactName("error");
    //   }
    //   if (requiredContactNumber === "") {
    //     setrequiredContactNumber("error");
    //   }
    //   if (typeEmailState === "") {
    //     settypeEmailState("error");
    //   }
    //   if (requiredNamePhone1 === "") {
    //     setrequiredNamePhone1("error");
    //   }

    //   if (requiredNamePhone2 === "") {
    //     setrequiredNamePhone2("error");
    //   }
    //   if (requiredNamePhone3 === "") {
    //     setrequiredNamePhone3("error");
    //   }

      

      
  };







  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor, color:'white' }} icon>
            {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3>
             <b>Experience Details</b>
              </h3> 
            </CardHeader>
            <CardBody>
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                <CustomInput
    labelText="Company Name *"
    id="disabled"
    formControlProps={{
      fullWidth: true
    }}
    inputProps={{
      disabled: true
    }}
  />

              {/* <label><b>Company Name</b></label>      */}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText=""
                    id="images"

                    success={requiredCompanyName === "success"}
                    error={requiredCompanyName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredCompanyName("success");
                        } else {
                          setrequiredCompanyName("error");
                        }
                        setrequiredName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredCompanyName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem  xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Date Of Hire *"
                    id="disabled"

                    formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true
                      }}
                    />

                   
                </GridItem>
                <GridItem  xs={12} sm={12} md={4}>
                <TextField
    id="datetime-local"
    label="From"
    type="date"
    defaultValue="2021-01-01"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}

    inputProps={{
      onChange: event => {
        if (verifyLength(event.target.value, 0)) {
          //setLeaseEndDate(event.target.value);
        } else {
          setrequiredState("error");
        }
        setrequired(event.target.value);
      },
      type: "date",
      endAdornment:
     
        requiredState === "error" ? (
          <InputAdornment position="end">
            <Close className={classes.danger} />
          </InputAdornment>
        ) : (
          undefined
        )
    }}


  />
                </GridItem>



                <GridItem  xs={12} sm={12} md={4}>
                <TextField
    id="datetime-local"
    label="To"
    type="date"
    defaultValue="2021-01-01"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}

    inputProps={{
      onChange: event => {
        if (verifyLength(event.target.value, 0)) {
          //setLeaseEndDate(event.target.value);
        } else {
          setrequiredState("error");
        }
        setrequired(event.target.value);
      },
      type: "date",
      endAdornment:
     
        requiredState === "error" ? (
          <InputAdornment position="end">
            <Close className={classes.danger} />
          </InputAdornment>
        ) : (
          undefined
        )
    }}


  />
                </GridItem>
              </GridContainer>


              <GridContainer>
              <GridItem  xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Reason For Leaving *"
                    id="disabled"

                    formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true
                      }}
                    />

                   </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText=""
                    id="reason"

                  
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>



            
               




<GridContainer>
<GridItem  xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Company Location *"
                    id="disabled"

                    formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true
                      }}
                    />

                   </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText=""
                    id="location"

                    success={requiredLocationName === "success"}
                    error={requiredLocationName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value,1)) {
                            setrequiredLocationName("success");
                          } else {
                            setrequiredLocationName("error");
                          }
                          setrequiredLocation(event.target.value);
                        },
                        type: "text",
                        endAdornment:
                        requiredLocationName === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>

</GridContainer>
       
<br/>
<br/>
 <div className={classes.buttonGroup}>
                

                    <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
                  <b>SUBMIT</b>
                </Button>
              
              </div>

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
     
    </div>
  );
}
export default Experience;



