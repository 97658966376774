import React, { Component } from "react";
import Chart from "react-apexcharts";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";



import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

// class HeapMapTenant extends React.Component {
//   constructor(props) {

//     super(props);

const HeapMapTenant = ({user,tmTenantsByFundIdRangeRequest,tmTenantsScoreByRangeRequest,tenantsScoreByRange,tmTenantsCountByPropertyRangeRequest,tenantsCountByPropertyRange,tenantsByFundRange,fundId,selectedRangeFrom,selectedRangeTo,month,year}) =>{

  const classes = useStyles(); 
  const history = useHistory();
  const[organizationId,setOrganizatgionId]=React.useState("12345");
  
  const[dashboardSelectedTenantId,setDashboardSelectedTenantId]=React.useState("");
  const[dashboardSelectedTenantName,setDashboardSelectedTenantName]=React.useState("");
  const[heatMapData,setHeatMapData]=React.useState(tenantsScoreByRange);
  const[heatMapTenantVisible,setHeatMapTenantVisible]=React.useState(true);
  const[heatMapTenantRiskVisible,setHeatMapTenantRiskVisible]=React.useState(false);

  const [userId, setUserId] = React.useState(user.id);

  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
 // const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

      React.useEffect(() => {
  
  

        getTmDashboardTenantByRange(user.organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year);
        getTmDashboardTenantNamesByRange(user.organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year);


        //getTenantsCountByPropertyRange( propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo);


          //setFundDashboard(tmDashboardFund);
      //    if(fundDashboard){
      //     setIsLoadingPage(true);
      //    }
      
        }, [tenantsScoreByRange]);
  
        const getTmDashboardTenantByRange = async (organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year) => {
          await tmTenantsScoreByRangeRequest(organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year);
          setHeatMapData(tenantsScoreByRange);
        };

          const getTmDashboardTenantNamesByRange = async (organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year) => {
            await tmTenantsByFundIdRangeRequest(organizationId,fundId,selectedRangeFrom,selectedRangeTo,month,year);
            };

          //  const getTenantsCountByPropertyRange = async (propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo) => {
            //  await tmTenantsCountByPropertyRangeRequest(propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo);
          //       };


            const handleChange = (tenantId,tenantName) => {

             setHeatMapTenantVisible(false);
             setHeatMapTenantRiskVisible(true);

            //  history.push({
            //   pathname: '/admin/dashboard/',
            //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
            // })
            

             setDashboardSelectedTenantId(tenantId);
             setDashboardSelectedTenantName(tenantName);
            }

  const tenantData=  [
      {
         name: "Thyssenkrupp Materials NA, Inc.",
         data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 0
              },
              {
                  "x": "OCT",
                  "y": 0
              },
              {
                  "x": "NOV",
                  "y": 308
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      },
     
      {
        name: "Maurice Pincoffs Company, INC.",
        data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 386
              },
              {
                  "x": "OCT",
                  "y": 384
              },
              {
                  "x": "NOV",
                  "y": 385
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      }
      
  ]

    function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

    const chartOps = { 

     // series: tenantsScoreByRange,
      series:heatMapData,
     
      options: {
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                      labels: {
                       
                          style: {
                                    fontSize: '16px',
                                    fontWeight: 700,
                                  }
                                }
                              },
        chart: {
          height: 150,
          type: 'heatmap',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 400,
                  to: 450,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 3
        },
        title: {
          text: ""
        },
      },
    
    
    };
  




    return (
     
      <GridContainer>


    {heatMapTenantVisible && <GridItem xs={12} sm={12} md={12}>
<div id="chart">

     

<br/>
<br/>
<GridContainer>
<GridItem xs={12} sm={5} md={5}>
         <h5 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Selected Tenant Risk Scores by Month   
 
</b></h5>
         </GridItem>

       <GridItem xs={12} sm={7} md={7} className={classes.vertical} justify = "right">
              {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
           
             <br></br><br/>
                
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
             

  </GridItem>
  </GridContainer>
{/* <h3>{dashboardSelectedTenantName}</h3> */}
<br/>
<br/>
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={250}  />
</div>
    


</GridItem>
    }
<GridItem xs={12} sm={12} md={12}>

{heatMapTenantRiskVisible && <HeatMapTenantRisk tenantId={dashboardSelectedTenantId} tenantName={dashboardSelectedTenantName}/>}
<br/>
<br/>

  {heatMapTenantVisible && <div className={classes.buttonGroup}>
<Button color="transparent" className={classes.firstButton}>
                  <b>Tenant Names </b>
                </Button>
                {tenantsByFundRange.map(tenant => (

                <Button  style = {{color:'#00628B',backgroundColor:'#81A594'}} className={classes.middleButton} onClick={() => handleChange(tenant.id,tenant.name)}>
                <b> {tenant.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowUpwardIcon/></b>
                </Button>

))} 
  </div> }

 </GridItem>

   </GridContainer>
    
    )

}
HeapMapTenant.propTypes = {

  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByRangeRequest: PropTypes.func.isRequired,
  tmTenantsByFundIdRangeRequest: PropTypes.func.isRequired,
//  tmTenantsCountByPropertyRangeRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantsScoreByRange: state.tenantmonitoring.tenantsScoreByRange,
  tenantsByFundRange : state.tenantmonitoring.tenantsByFundRange,
  //tenantsCountByPropertyRange : state.tenantmonitoring.tenantsCountByPropertyRange,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeapMapTenant);

