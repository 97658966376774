
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CsvUpload from "components/CustomUpload/CsvUpload.js";
import TenantStatusTable from "views/Tenant/TenantStatusTable.js"
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import DashboardTable from "./DashboardTable";
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Tooltip } from "@material-ui/core";

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import ViewFailureList from "./ViewFailureList";
//import FailureCountReport from "./FailureCountReport";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const styles = {

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',
  },
    ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  }
  
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const DashboardList = ({
    tmDashboardUserListRequest,dashboardUserList,
    tmOrgInitialDataStatusRequest,orgInitialDataStatus,tmGenerateDashboardRequest,tmGenerateDashboardByUserRequest
  }) => {
    const history = useHistory();
    const location = useLocation();
    const [dataList, setDataList] = React.useState(dashboardUserList);
    const [messageobj, setMessageobj] = React.useState("");
    const [tenantId , setTenantId ] = React.useState("");
    const [propertyId, setPropertyId ] = React.useState("");
  //   const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //   const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  //  // const[companyName,setCompanyName] = React.useState("Megalytics");

  const [organisationId, setOrganisationId] = React.useState("");
  const [organisationName, setOrganisationName] = React.useState("");

  // const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
  // const [organisationName, setOrganisationName] = React.useState(location.state.organisationName);
  
   //const[failureCount,setFailureCount] = React.useState(location.state.failureCount);
   const [createdDate, setCreatedDate] = React.useState("");
  // const [organisationName, setOrganisationName ] = React.useState("");
   const [tenantName, setTenantName] = React.useState("");

   const[month, setMonth] = React.useState("");
   const[year, setYear] = React.useState("");
  const[ status, setStatus] = React.useState(true);
  const [dataStatus, setDataStatus] = React.useState(false);

  const [alert,setAlert] = React.useState(false);
  const [msg,setMsg] = React.useState("");
  const [msgAlert,setMsgAlert] = React.useState(false);
  const [apiStatusAlert,setApiStatusAlert] = React.useState(false);
  const [apiStatus,setApiStatus] = React.useState(false);
  const [scoreStatus,setScoreStatus] = React.useState(false);
  const [orgDashBoard,setOrgDashboard] = React.useState(true);
  const [userDdashBoard,setUserDashboard] = React.useState(false);
  const [disableDashboardGen,setDisableDashboardGen] = React.useState(false);
  React.useEffect(() => {
    console.log("location.state"+JSON.stringify(location.state));
    if(location.state){
      console.log("dataStatus"+dataStatus)
      setOrganisationId(location.state.organisationId);
      setOrganisationName(location.state.organisationName);
     
      
      // alert("usereffect st::"+organisationId);
      // setMessageobj(location.state.message);
      if(!dataStatus){
        getDahboardUserList(location.state.organisationId);
       
        getOrgInitialDataStatus(location.state.organisationId);
    //   getActiveLiensList(tenantId);
    //   getActiveLiensJudgmentScore(tenantId);
    //   getActiveJudgementList(tenantId);
    
      }
    
    }
  
    setTimeout(() => {
      setDataStatus(true);
      }, 3000);
  
  }, [dataStatus]);
  
  
  
  
      const getDahboardUserList = async(organisationId)=>{
        console.log("organisationId-bfr"+organisationId);
        await tmDashboardUserListRequest(organisationId)
        console.log("organisationId-aftr"+JSON.stringify(organisationId));
        //setFailureCount(tenantFailureStatusLogListByOrg);
      
          }
      

         

const hideAlert = () => {
  setAlert(false);
}

const hideMsgAlert = () => {
  setMsgAlert(false);
}

const hideStatusAlert = () => {
  setApiStatusAlert(false);
}
// const successAlert = () => {
//   setAlert(
//     <SweetAlert
//       success
//       style={{ display: "block", marginTop: "-100px" }}
//       title="Good job!"
//       onConfirm={() => hideAlert()}
//       onCancel={() => hideAlert()}
//       confirmBtnCssClass={classes.button + " " + classes.success}
//     >
//       You clicked the button!
//     </SweetAlert>
//   );
// };



          const checkOrgStatus=async(organisationId)=>{
            console.log("organisationId"+JSON.stringify(organisationId));
            if(orgInitialDataStatus){
              generateDashboard(organisationId);
             
                 }else{
              
                  setAlert(true);   
                 }
                
          }

          const generateDashboardByUserId=async(userId,fname,lname,action)=>{
            console.log("location.state"+JSON.stringify(location.state));
            console.log("hii");

      if(userDdashBoard){
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              
              <div className='custom-ui' 
              style={{
                backgroundColor: "#81A594 ",
               fontWeight:"bold",
                color:'#fff',
              
                textAlign: "center",
               // alignItems: "center",
                paddingTop: "20px",
                height: "150px",
                width: "300px",
                
                }}>
                  
                <h1 >Are you sure?</h1>
                <p fontWeight="bold">Do you want to {action} for {fname} &nbsp;{lname}?</p>
               
                <button  backgroundColor= "#000"
                   onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                <button 
                   
                  onClick={() => {
                   tmGenerateDashboardByUserRequest(userId);
                    onClose();
                    setApiStatusAlert(true);
                 
                  }}
                >
                &nbsp; &nbsp; Yes &nbsp;&nbsp;
                </button>
                </div>
          
           

            );
          }
        });
      }
            
          
          }

          const getOrgInitialDataStatus = async(organisationId)=>{
            await tmOrgInitialDataStatusRequest(organisationId);
           
          } 

          const generateDashboard = async(organisationId)=>{
            await tmGenerateDashboardRequest(organisationId);
            setApiStatusAlert(true);
            setDisableDashboardGen(true);
        //  if(populateInitialDataByOrg){
        //    console.log("populateInitialDataByOrg");
        //    console.log(populateInitialDataByOrg.hasScore);
        //   setScoreStatus(populateInitialDataByOrg.hasScore);
        //   console.log("scoreStatus"+scoreStatus);
        //   if(scoreStatus){
        //     setApiStatus("Initial data populated Successfully");
        //   }
        //   else{
        //   setApiStatus("Initial data population failed")
        //  }
        //  setApiStatusAlert(true);
        //   } 

          }

const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

// const getTenantInitialScore=(tenantId)=>{
//   tmTenantInitialScoreRequest(tenantId);
// }

// const getAllTenantInitialScore=()=>{
//   tmAllTenantsTrailingScoreRequest();
// }


const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

//const [showFailureCountReport, setShowFailureCountReport] = React.useState(false);
const [open, setOpen] = React.useState(false);
const [showViewFailureList, setShowViewFailureList] = React.useState(false);
const [modalValue, setModalValue] = React.useState("");  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
   

    

//   const handleClickOpenViewFailureList = (value,organisationId,month,year) => {
//     setShowViewFailureList(true);
//     setModalValue(true);
//     setOrganisationId(true);
//  setMonth(true);
//  setYear(true);

   
//   };

  

  // const handleCloseViewFailureList = () => {
  //   setShowViewFailureList(false);
    
  // };

 const getFailureList=(organisationId,month,year)=>{
    setShowViewFailureList(true);
  
         setOrganisationId(organisationId);
      setMonth(month);
      setYear(year);
      //alert(organisationId);
      // alert(month);
      // alert(year);

 }

 const setCheckedvalueFordashboard=(value)=>{
  console.log("location.state"+JSON.stringify(location.state));
  setUserDashboard(value);
  setOrgDashboard(!value);
 }
//  const submit = (organisationId) => {
   
//   confirmAlert({
   
//     //title: 'Fund',
//     message: 'Data already Generated',
//     buttons: [
//       {
//         label: 'OK'
      
//       },
    
//     ]
  
//   })

// };
 
  
  const classes = useStyles();

  if(dataStatus){
  return (
    <GridContainer>
      {/* <GridItem xs={12}>
     <OrgSelected orgName={organisationName}/>
     </GridItem> */}
      <GridItem xs={12}>
     
     
      

        <Card>
          <CardHeader style={{ background: megaColor,color:"white" ,fontSize:"17px"}} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b>{organisationName}- Dashboard List</b></h4>
            
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }

   
               <GridContainer className={classes.gridContainer}>
      
         <GridItem style={{textAlign: "right"}} xs={12} sm={12}>
         

         {orgDashBoard&& <Button disabled={disableDashboardGen} style={{ background: megaColor , color:"white"}} round onClick={()=> {checkOrgStatus(organisationId)}}
  
         > 
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="Please assign user to newly uploaded Funds and Properties before generating Dashboard">
                    <b>Generate Dashboard</b>  
                       </Tooltip> 
                  </Button>}
                   
            
         </GridItem>
       
   </GridContainer>
         
         
   <div>
<SweetAlert
success
show={alert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Score is being generated.Please try after some time !
</SweetAlert>

       
</div>
<div>
<SweetAlert
success
show={msgAlert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideMsgAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
{msg}
</SweetAlert>

       
</div>
<div>
<SweetAlert
success
show={apiStatusAlert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideStatusAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
<b> Dashboard is being populated .Please check after One hour.If still problem exist please contact system administrator.</b>
</SweetAlert>


</div>
  

                
                <FormControlLabel
          control={
            <Switch
            
           
              onChange={event => setCheckedvalueFordashboard(event.target.checked)}
            
              color="primary"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar,
               
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Individual user dashboard"
          
        />
                     
              

                
 
            <DashboardTable
              columns={[

                {
                  Header: "FirstName",
                  accessor: "firstName",
                  width: 50,
              
                },


          
         
           

          
            {
              Header: "LastName",
               accessor: "lastName",
               width: 50,
               
           },
    
           
           {
            Header: "Dashboard generated",
            accessor: "hasDashBoard",
            
       

          },  
                

              
                {
                  Header: "Actions",
                accessor: "actions",

            
            width: getColumnWidthNew(dashboardUserList, 'accessor', 'actions'),
      
            isVisible: false,
            width: 50,
            disableFilters: true,
            disableSortBy: true,



                


                 },


              ]}
              data={dashboardUserList.map((prop, key) => {

               
                return {
                  id: prop.id,
              
                firstName: prop.firstName,
                  lastName: prop.lastName,
                  hasDashBoard:prop.hasDashBoard,
                           
                  hasDashBoard:(


                    // render(){ if(prop.initialDataPopulated){
                      <div>
                    {/* //   return( */}
                  
                    {prop.hasDashBoard ? 
                       <Tooltip classes={{ tooltip: classes.customWidth }} title="True">
                    <DoneIcon style={{ color: "green" }} />
                    </Tooltip> : 
                     <Tooltip classes={{ tooltip: classes.customWidth }} title="False">
                    <CloseIcon  style={{ color: "red" }}/>
                    </Tooltip>}
  
                    </div>
                   
                    ),  
                    actions: (
                      // we've added some custom button actions
                      <div>
               
                
                        {prop.hasDashBoard ? 
                              
                         <Link
                         
                                           size="sm" color="info"
                                           onClick={() =>{generateDashboardByUserId(prop.id,prop.firstName,prop.lastName,"Regenerate Dasboard")}}
                                      >
                                        {userDdashBoard && "Regenerate Dashboard"}{!userDdashBoard && <span style={{  color:"grey"}} onClick={e => e.preventDefault()}>Regenerate Dashboard</span>}
                                       
                        </Link> : 
                         <Link
                          
                                           size="sm" color="info"
                                           onClick={() =>{generateDashboardByUserId(prop.id,prop.firstName,prop.lastName,"Generate Dasboard")}}
                                      >
                                         {userDdashBoard &&  "Generate Dashboard"}{!userDdashBoard && <span style={{  color:"grey"}}  onClick={e => e.preventDefault()}>Generate Dashboard</span>}
                                       
                        </Link>}
    
                      </div>
                    )  
 
                };
              })}


        
              //  data={tenantUploadStatusList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({month: rowData.month, year: rowData.year})
                  }
            }}}
            
            />
          </CardBody>
          
        </Card>
        
        </GridItem>
        
       
    </GridContainer>
    
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={8000} //8 secs
  /></div>
  )
}
}



DashboardList.propTypes = {
  dashboardUserList: PropTypes.arrayOf(
    PropTypes.shape({
      organisationId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmTenantListRequest: PropTypes.func.isRequired,
  tmTenantListByOrgRequest: PropTypes.func.isRequired,
  tmTenantDeleteRequest: PropTypes.func.isRequired,
  tmTenantInitialScoreRequest: PropTypes.func.isRequired,
  tmDashboardUserListRequest: PropTypes.func.isRequired,
  tmOrgInitialDataStatusRequest: PropTypes.func.isRequired,
  tmGenerateDashboardRequest:PropTypes.func.isRequired,
  tmGenerateDashboardByUserRequest:PropTypes.func.isRequired,
  
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  dashboardUserList: state.tenantmonitoring.dashboardUserList,
  tenantDelete: state.tenantmonitoring.tenantDelete,
  loading: state.tenantmonitoring.loading,
  populateInitialDataByOrg:state.tenantmonitoring.populateInitialDataByOrg,

  orgInitialDataStatus:state.tenantmonitoring.orgInitialDataStatus,
  
  // month:state.tenantmonitoring.month,
  // year:state.tenantmonitoring.year,
  //tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardList);




