import React from "react";
import {useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";
import SaveIcon from '@material-ui/icons/Save';
import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
//checkbox style change
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Badge from "components/Badge/Badge.js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import CardAvatar from "components/Card/CardAvatar.js";

import { useLocation } from "react-router-dom";


const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const UserProfile = ({tmUserSaveRequest,tmUserEditRequest,userEditObj,tmUserByIdRequest,user}) => {

  const history = useHistory();

const location = useLocation();

  const [input, setInput] = React.useState('');
  
  const saveUser = async (managedUserVM) => {
    tmUserSaveRequest(managedUserVM);
    };

  //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");



  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [requiredFirstName, setRequiredFirstName] = React.useState("");
const [requiredLastName, setRequiredLastName] = React.useState("");
const [requiredUserName, setRequiredUserName] = React.useState("");
const [requiredJobTitle, setrequiredJobTitle] = React.useState("");
const [requiredStateJobTitle, setrequiredStateJobTitle] = React.useState("");

const [requiredEmail, setRequiredEmail] = React.useState("");
const [requiredPassword, setRequiredPassword] = React.useState("");
const [requiredConfirmPassword, setRequiredConfirmPassword] = React.useState("");
const [requiredUserType, setRequiredUserType] = React.useState("");

// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");

const [firstName, setFirstName] = React.useState("");
const [lastName, setLastName] = React.useState("");
const [login, setLogin] = React.useState("");
const [password, setPassword] = React.useState("");
const [email, setEmail] = React.useState("");
const [mainRole, setMainRole] = React.useState("");
const [officeNumber, setOfficeNumber] = React.useState("");
const [activated, setActivated] = React.useState("");
const [jobTitle, setJobTitle]= React.useState("")
const [userId, setUserId] = React.useState(user.id);
const [organizationId, setOrganizationId] = React.useState("");

 const [organisationName, setOrganisationName] = React.useState("");

const [dataStatus,setDataStatus]=React.useState(false);
// const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);
const [messageobj, setMessageobj] = React.useState("");
const hideAlert = () => {
  setAlert(false);
}


useEffect(() => {

  getUserById(userId);

  
 if(userEditObj){
  
    setFirstName(userEditObj.firstName);
    setLastName(userEditObj.lastName);
    setLogin(userEditObj.login);
    setPassword(userEditObj.password);
    setEmail(userEditObj.email);
    setActivated(userEditObj.activated);
    setMainRole(userEditObj.role);
    setOfficeNumber(userEditObj.officeNumber);
    setPassword(userEditObj.password);
    setJobTitle(userEditObj.jobTitle);


  }
  


     setTimeout(() => {
      setDataStatus(true);
    }, 1000);

   
  },[dataStatus,messageobj]);


  const getUserById = async (userId)=> {
    console.log("userId"+JSON.stringify(userId));
    await tmUserByIdRequest(userId);

  }

 


  function hideAlert1(event) {
    history.push({ 
      pathname: '/admin/User/userlist'
     });
    }

const submitForm = () => {
  if(verifyLength(firstName,1)&&verifyLength(lastName,1)&&verifyLength(login,1)&&verifyLength(email,1)&&verifyEmail(email)&&verifyLength(mainRole,1))
  {
 
  const managedUserVM={
  
    id:userId,
    firstName: firstName,
    lastName:lastName,
    login:login,
    password:password,
    email:email,
    organizationId:organizationId,
   mainRole:mainRole,
   jobTitle:jobTitle,

   activated:activated,
   
};
// alert(JSON.stringify(userId))
// alert(JSON.stringify(firstName))
// alert(JSON.stringify(login))
// alert(JSON.stringify(email))

//managedUserVM
tmUserEditRequest(managedUserVM);
history.push( setAlert1(true),{ 
 
  pathname: '/admin/User/userlist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }
else{
  setAlert(true);
  if (typeEmailState === "value") {
    settypeEmailState("success");
  }
  if (requiredPassword === "value") {
    setRequiredPassword("success");
  }
  if (requiredFirstName === "value") {
    setRequiredFirstName("success");
  }
  if (requiredUserName === "value") {
    setRequiredUserName("success");
  }
  if (requiredLastName === "value") {
    setRequiredLastName("success");
  }
  if (requiredUserType === "value") {
    setRequiredUserType("success");
  }
 

}
    };

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };


  const handleSimple = event => {
    
    setMainRole(event.target.value);
   
  };
  
  
  const handleChange = (value) => {
   
  }

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  

// function that verifies if value contains only numbers
const verifyNumber = (value) => {
  var numberRex = new RegExp("^[0-9]+$");
  if ((value.numberRex) >= numberRex) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
  if (requiredPassword === "") {
    setRequiredPassword("error");
  }
  if (requiredFirstName === "") {
    setRequiredFirstName("error");
  }
  if (requiredUserName === "") {
    setRequiredUserName("error");
  }
  if (requiredLastName === "") {
    setRequiredLastName("error");
  }
  if (requiredUserType === "") {
    setRequiredUserType("error");
  }

  
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  console.log("verifyEmail"+value);
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  else{
    settypeEmailState("error");
  }
  return false;
};

// const typeClick = () => {
 
//   if (typeEmailState === "") {
//     settypeEmailState("error");
//   }
  
// };


  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px",fontWeight:"bold" }} icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3 style={{fontSize: "27px", fontWeight:"bold"}} >Edit User- Complete your Profile</h3>
            </CardHeader>
            <CardBody>
             
            <GridContainer>
           
           <GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="First Name *"
               id="firstName"
               value={firstName}

               success={requiredFirstName === "success"}
               error={requiredFirstName === "error"}
               //id="required"
               formControlProps={{
                 fullWidth: true
               }}

               inputProps={{
                 onChange: event => {
                   if (verifyLength(event.target.value, 1)) {
                     setFirstName(event.target.value);
                     setRequiredFirstName("success");
                   } else {
                     setRequiredFirstName("error");
                     setFirstName("");
                   }
                   setrequired(event.target.value);
                 },
                 type: "text",
                 endAdornment:
                   requiredFirstName === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}

             />
           </GridItem>
           <GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="Last Name *"
               id="lastName"
               value={lastName}
               success={requiredLastName === "success"}
               error={requiredLastName === "error"}
               formControlProps={{
                 fullWidth: true
               }}


               inputProps={{
                 onChange: event => {
                   if (verifyLength(event.target.value, 1)) {
                     setLastName(event.target.value);
                     setRequiredLastName("success");
                   } else {
                     setRequiredLastName("error");
                     setLastName("");
                   }
                   setrequired(event.target.value);
                 },
                 type: "text",
                 endAdornment:
                 requiredLastName === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}
             />
           </GridItem>
           
         </GridContainer>

         
         <GridContainer>
         <GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="User Name *"
               id="login"
               value={login}
               success={requiredUserName === "success"}
               error={requiredUserName === "error"}
               //id="required"
               formControlProps={{
                 fullWidth: true
               }}

               inputProps={{
                disabled: true
              }}
             />
           </GridItem>
           <GridItem xs={12} sm={12} md={6}>
           <CustomInput 
             labelText="Email *"

               success={typeEmailState === "success"}
               error={typeEmailState === "error"}
               id="email"
               value={email}
               formControlProps={{
                 fullWidth: true
               }}
               inputProps={{
                 onChange: event => {
                   setEmail(event.target.value);
                   if (verifyEmail(event.target.value)) {
                     setEmail(event.target.value);
                     settypeEmailState("success");
                   } else {
                     settypeEmailState("error");
                     
                   }
                   settypeEmail(event.target.value);
                 },
                 type: "email",
                 endAdornment:
                   typeEmailState === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}
             />
           </GridItem>


         
         
         </GridContainer>

         
         <GridContainer>
        
           <GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="Password *"
               id="password"

               success={requiredPassword === "success"}
               error={requiredPassword === "error"}
               formControlProps={{
                 fullWidth: true
               }}

               inputProps={{
                 onChange: event => {
                   if (verifyLength(event.target.value,1)) {
                     setPassword(event.target.value);
                     setRequiredPassword("success");
                   } else {
                     setRequiredPassword("error");
                     setPassword("");
                   }
                   setPassword(event.target.value);
                 },
                 type: "password",
                 endAdornment:
                 requiredPassword === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}

             />
           </GridItem>
        
        
           <GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="Confirm Password *"
               id="last-name"

               success={requiredConfirmPassword === "success"}
               error={requiredConfirmPassword === "error"}
               formControlProps={{
                 fullWidth: true
               }}

               inputProps={{
                 onChange: event => {
                   if (verifyLength(event.target.value, 1)) {
                     setRequiredConfirmPassword("success");
                     
                   } else {
                     setRequiredConfirmPassword("error");
                   }
                   setrequired(event.target.value);
                 },
                 type: "password",
                 endAdornment:
                
                 requiredConfirmPassword === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}
             />
           </GridItem>
         </GridContainer>
       
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
             <CustomInput
               labelText="Job Title"
               id="jobTitle"
               value={jobTitle}

               success={requiredStateJobTitle === "success"}
               error={requiredStateJobTitle === "error"}
               formControlProps={{
                 fullWidth: true
               }}

               inputProps={{
                 onChange: event => {
                   if (verifyLength(event.target.value, 0)) {
                     setJobTitle(event.target.value);
                     setrequiredStateJobTitle("success");
                   } else {
                     setrequiredStateJobTitle("error");
                   }
                   setrequiredJobTitle(event.target.value);
                 },
                 type: "text",
                 endAdornment:
                 requiredStateJobTitle === "error" ? (
                     <InputAdornment position="end">
                       <Close className={classes.danger} />
                     </InputAdornment>
                   ) : (
                     undefined
                   )
               }}
             />
           </GridItem>


              <GridItem xs={12} sm={12} md={6}>
              <CustomInput
               labelText="User Type *"
               id="mainRole"
               value={mainRole}

              
               formControlProps={{
                 fullWidth: true
               }}

   
               inputProps={{
                disabled: true
              }}
      />
   </GridItem>

          
</GridContainer>

<GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                </GridItem>
              
              </GridContainer>   
    
              
        <Button color="info" size="sm" className={classes.updateProfileButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
      <LibraryAddCheckIcon/> Update Profile  
        </Button>
       
  
            

              <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: "#33D7FF"}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>      
                     <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: "#33D7FF"}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
User Edited Successfully!
</SweetAlert>
</div>       
       
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="rose" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>

                 


              

            

            

             
      </form>
    </div>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}
UserProfile.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmUserSaveRequest: PropTypes.func.isRequired,
  tmUserEditRequest: PropTypes.func.isRequired,
  tmUserByIdRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  //userEdit: state.tenantmonitoring.userEdit,
  userEditObj: state.tenantmonitoring.userEditObj,
  //user: state.tenantmonitoring.user,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);