import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Link from '@material-ui/core/Link';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Heatmap from "components/Charts/Heatmap.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapFundRisk from "components/Charts/HeapMapFundRisk.js";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";
import HeatMapPropertyTenants from "components/Charts/HeatMapPropertyTenants.js";


import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);


const HeatMapPropertyRisk = ({user,propertyName,propertyId,organizationId,fundId,count,parentCallBack,tmTenantsRiskCountByPropertyRequest,tenantsRiskCountByProperty}) => {
  const history = useHistory();

  const [input, setInput] = React.useState('');
  const [showHeatMap, setShowHeatMap] = React.useState(true);
  const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
  const [showHeatMapPropertyRisk, setShowHeatMapPropertyRisk] = React.useState(true);
  const [showHeatMapTenantScore, setShowHeatMapTenantScore] = React.useState(false);
  const [tenantActive, setTenantActive] = React.useState(false);
  const [propertyActive, setTpropertyActive] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);

  const [userId, setUserId] = React.useState(user.id);

  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));

  
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  //const [riskList, setRiskList] = React.useState(count);

 


  const sendDataBack=(range)=>{
 
  parentCallBack(range);
    }
//   const enableTenant = (value) => {
    
//     setShowHeatMapTenant(true);
//     setShowHeatMapFundRisk(false);
//     setTenantActive(true);
//   }
  const handleChange = (range) => {
   
    //setShowHeatMapFundRisk(true);
    setShowHeatMapTenantScore(true);
    setShowHeatMapPropertyRisk(false);
  //  setPropertyActive(false);
  setPageStatus(false);
    setTenantActive(true);
  }

  
 
  const [value, setValue] = React.useState(null);
  const [lowRiskValues,setLowRiskValues] = React.useState([]);
  const [mediumLowRiskValues,setMediumLowRiskValues] = React.useState([]);
  const [mediumHighRiskValues,setMediumHighRiskValues] = React.useState([]);
  const [moderateHighRiskValues,setModerateHighRiskValues] = React.useState([]);
  const [highRiskValues,setHighRiskValues] = React.useState([]);


  React.useEffect(() => {
   // tmTenantsRiskCountByPropertyRequest
  //  setLowRiskValues(riskList.lowRiskValues);


  getTmPropertyRiskCount(propertyId,userId);
   
    if(pageStatus){
       setLowRiskValues(tenantsRiskCountByProperty.LOW_RISK);
       setMediumLowRiskValues(tenantsRiskCountByProperty.MEDIUM_LOW_RISK);
       setMediumHighRiskValues(tenantsRiskCountByProperty.MEDIUM_HIGH_RISK);
       setModerateHighRiskValues(tenantsRiskCountByProperty.MODERATE_HIGH_RISK);
       setHighRiskValues(tenantsRiskCountByProperty.HIGH_RISK);
       
    }
   
 
 
  }, [propertyId,userId,tenantsRiskCountByProperty]);

 

  const getTmPropertyRiskCount = async (propertyId,userId) => {
    await  tmTenantsRiskCountByPropertyRequest(propertyId,userId);
    setPageStatus(true);
  
    };

   
  const classes = useStyles(); 


if(pageStatus){


  return (
     <div>
     



     <GridContainer >

<br/>
<br/>
         

   


 <GridItem xs={12} sm={12} md={12} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h5> <b><font color="#00628B">{propertyName}</font></b></h5>

</GridItem>
         

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7> <b>Low Risk</b></h7>

</GridItem>
 <GridItem xs={10} sm={10} md={10}  >
            
 
 
 <div className={classes.buttonGroup}>
           
             {pageStatus&&lowRiskValues.map(lowRisk => (
               <Button color="success" style={{width:'60px',fontSize:'20px',color:'#000'}}  className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:401,rangeTo:500,month:lowRisk.monthName})}>

            
                  <b>{lowRisk.riskCount} </b>
               </Button>
              ))} 
      
      
             </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7><b> Medium Low Risk</b></h7>




</GridItem>

<GridItem xs={10} sm={10} md={10}  >
            
    
 
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
            {tenantsRiskCountByProperty&&mediumLowRiskValues.map(mediumLowRisk => (
              <Button style={{width:'10px',color:'#000',backgroundColor:'#32cd32',fontSize:'20px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:301,rangeTo:400,month:mediumLowRisk.monthName})}>
                 <b>{mediumLowRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7><b>Moderate High Risk</b></h7>
            
       

</GridItem>

 <GridItem xs={10} sm={10} md={10}  >
            
            <div className={classes.buttonGroup} style={{paddingbottom:'20px'}}>
           
            {tenantsRiskCountByProperty&& moderateHighRiskValues.map(moderateHighRisk => (
              <Button style={{width:'10px',color:'#000',backgroundColor:'#ffdf00',fontSize:'20px'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:201,rangeTo:300,month:moderateHighRisk.monthName})}>
                 <b>{moderateHighRisk.riskCount} </b>
              </Button>
              ))} 
            </div>
         

</GridItem>

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7><b> Medium High Risk</b></h7>
            
          
         

</GridItem>
  
 <GridItem  xs={10} sm={10} md={10} >
             

             <div className={classes.buttonGroup}>
          
             {tenantsRiskCountByProperty&& mediumHighRiskValues.map(mediumHighRisk => (
            
               <Button color="warning" style={{width:'60px',fontSize:'20px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:201,rangeTo:300, month:mediumHighRisk.monthName})} >
                 <b>{mediumHighRisk.riskCount} </b>
               </Button>             
      
    ))}
             </div>
            

 </GridItem>

 <GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7><b> High Risk</b></h7>
            
          
         

</GridItem>

  <GridItem  xs={10} sm={10} md={10}>
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="danger" style={{width:'60px',fontSize:'20px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack({rangeFrom:101,rangeTo:200,month:highrisk.monthName})} >
                 <b>{highrisk.riskCount} </b>
               </Button>
              
      
    ))}
             </div>

 </GridItem>

 

<GridItem xs={2} sm={2} md={2} style={{paddingTop:'20px',paddingLeft:'30px'}} >

<h7> </h7>
           
         
        

</GridItem>
  
  <GridItem  xs={10} sm={10} md={10} >
             

             <div className={classes.buttonGroup}>
          
             {highRiskValues&& highRiskValues.map(highrisk => (
            
               <Button color="transparent" style={{width:'60px',fontSize:'14px',color:'#000'}} className={classes.firstButton} onClick={() => sendDataBack()} >
                 <b>{highrisk.monthName} </b>
               </Button>
              
      
    ))}
             </div>
            

 </GridItem>

     
     <GridItem  xs={12} sm={12} md={12} >
         {showHeatMapTenantScore && <HeatMapPropertyTenants/>}
        </GridItem>
   
       
       

 
      </GridContainer>
      

    
    </div>
  );
}else{
    return(
 
      
      <div>
       
      </div>
    )
}
if(showHeatMapTenantScore){
  return(
  <div>
     Test  
  </div>
  )
}

}

HeatMapPropertyRisk.propTypes = {
    loading: PropTypes.bool.isRequired,
    tmTenantsRiskCountByPropertyRequest: PropTypes.func.isRequired, 
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tenantsRiskCountByProperty: state.tenantmonitoring.tenantsRiskCountByProperty,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HeatMapPropertyRisk);
  
  
