import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  }
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const MonitoredTenants = ({
    tmList, tmRequest, 
  }) => {
    const [dataList, setDataList] = React.useState(tmList);
    useEffect(() => {
      
      getTmList();
   
    }, []);

    const getTmList = async () => {
      await tmRequest();
      };
      
//  const getColumnWidth = (data, accessor, headerText) => {
//         const cellLength = Math.max(
//           ...data.map(row => {
//             let value = '';
      
//             if (typeof accessor === 'string') {
//               value = _.get(row, accessor);
//             } else {
//               value = accessor(row);
//             }
      
//             if (typeof value === 'number') return value.toString().length;
//             return (value || '').length;
//           }),
//           headerText.length
//         );
      
//         const magicSpacing = 12;
//         return cellLength * magicSpacing;
//       };
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}



  
  const classes = useStyles();
  
  return (
    <GridContainer>
     
      <GridItem xs={12}>
        <Card>
          <CardHeader style={{ background: megaColor,color:"white" }} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b> Tenant Monitoring Settings List</b></h4>
          </CardHeader>
          <CardBody>
            <MTTable
              columns={[
                
              {
                Header: "Contity ID",
                accessor: "tenantName",
                //width: getColumnWidth(tmList, 'tenantName', 'Tenant'),
                Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification/', state :{tenantId : row.original.tenantId, contifyCompanyId:row.original.tenantContifyId, naics:row.original.naics, accountNumber : row.original.bankoAccountNumber,tenantName:row.original.tenantName} } }>{row.original.tenantName}</Link>
             
              
              ),
             
                // Cell: ({ cell,row ,
                //   column: { tenantId } }) => (
                
                // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
             // }
             width: getColumnWidthNew(tmList, 'accessor', 'Tenant'),
            },
            {
              Header: "Credit Safe ID",
              accessor: "overAllTrackerScore",
              width: 50,  
            },
                
              {
                Header: "Cortera ID",
                accessor: "entityName",
                width: 50,
              },
            //   {
            //     Header: "Teant Id",
            //     accessor: "tenantId",
            //     width: 50,
                
            //   },
            //   {
            //     Header: "ContifyId",
            //     accessor: "tenantContifyId",
            //     width: 50,
                
            //   },
                {
                  Header: "Banko ID",
                  accessor: "entityType",
                  width: 50,
                },

                // {
                //   Header: "AccountNo",
                //   accessor: "bankoAccountNumber",
                //   width: 50,
                // },
                // {
                //   Header: "Property Name",
                //   accessor: "property",
                //   width: 50,
                //   width: getColumnWidthNew(tmList, 'property', 'Property'),
                // },
                
                // {
                //   Header: "Property Category",
                //   accessor: "propertyType",
                  
                //   isVisible: false,
                //   width: 50,
                // },

                {
                  Header: "naics",
                  accessor: "naics",
                  
                  isVisible: false,
                  width: 50,
                }
              ]}
              data={tmList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


MonitoredTenants.propTypes = {
  tmList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmList: state.tenantmonitoring.tmList,
  loading: state.tenantmonitoring.loading,
  tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonitoredTenants);

