import React from 'react';

const PrintReport = ({refPropWithAnotherName,reportsDetails}) => {

    return(
        <div ref={refPropWithAnotherName}>
        <h2 style={{color: "green"}}>{reportsDetails.tenantName}</h2>
        <table>
          <thead>
            <th>പേര്</th>
            <th>വിലാസം</th>
            <th>Email</th>
          </thead>
          <tr>
              <td>{reportsDetails.taxLiensAndJudgementCommentary}</td>
              
            </tr>
            <tr>
              <td>3</td>
              <td>Undefined</td>
              <td>No Email</td>
            </tr>
          <tbody>
          {reportsDetails.taxLienDetailList!=""&&reportsDetails.taxLienDetailList.map(liens=>(
            <tr>
              <td>{liens.filingType}</td>
              <td>{liens.filingNumber}</td>
              <td>${liens.amount}</td>
            </tr>))}
           
          </tbody>
        </table>
      </div>
     
    
    );
}
export default PrintReport;