import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
//import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useLocation } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { textAlign } from "@material-ui/system";
import Cautionaryreports from "views/Reports/Cautionaryreports";
import Civilsuitsreport from "views/Reports/Civilsuitsreport";
import Liensreport from "views/Reports/Liensreport";
import Link from '@material-ui/core/Link';
import Judgementreport from "views/Reports/Judgementreport";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: megaColor,
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  gridBorder:{
    border: 1, 
    borderColor: '#D3D3D3',
    borderStyle: 'solid'
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  vertical: { 
              padding:"10px"
             
            } ,
    customWidth: {
      maxWidth: 200,
      fontSize: "15px",
      backgroundColor: megaColor,
    },
h: {
      marginLeft:"0px",
    },
};

const useStyles = makeStyles(styles);

const DerogatoryReport = ({ loading,params,tmDerogatoryScoreCountRequest,derogatoryScoreCount,tmCreditSafeBankruptcyReportByTenantIdRequest,creditSafeBankruptcyReport,tmGetTenantPropertyDetailsRequest,tenantPropertyDetails }) =>{
// export default function Widgets() {
  const { tenantId,organizationId } = params;
 
  const history = useHistory();
  const location = useLocation();
  const[tenantName,setTenantName] =React.useState(location.state.tenantName);

  const [selectedTenantId, setSelectedTenantId] = React.useState(location.state.tenantId);
 // const [tenantId,setTenantId] = React.useState(location.state.tenantId);
  const [uccScore,setUccScore] = React.useState(location.state.uccScore);
 
const [suitsScore,setSuitsScore] = React.useState(location.state.suitsScore);
const [liensJudgementScore,setLiensJudgementScore] = React.useState(location.state.liensJudgementScore);


  const [filedDate, setFiledDate] = React.useState(location.state.filedDate);
  const [jurisdiction, setJurisdiction] = React.useState(location.state.jurisdiction);
  const [securedPartyName, setSecuredPartyName] = React.useState(location.state.securedPartyName);
  const [collateral, setCollateral] = React.useState(location.state.collateral);
  
  const [filingType, setFilingType] = React.useState(location.state.filingType);
  const [filingNumber, setFilingNumber] = React.useState(location.state.filingNumber);
  const [amount, setAmount] = React.useState(location.state.amount);
  const [plaintiff, setPlaintiff] = React.useState(location.state.plaintiff);

 

  
  const [uccFilingScore, setUccFilingScore] = React.useState(0);
  const [taxLiensJudgementScore, setTaxLiensJudgementScore] = React.useState(location.state.taxLiensJudgementScore);
  const [civilSuitsScore, setCivilSuitsScore] = React.useState(0);
  const [corporationFilingStatusScore, setCorporationFilingStatusScore] = React.useState(0);
  const [derogatoryScore, setDerogatoryScore] = React.useState(0);
  const [creditScore, setCreditScore] = React.useState(0);
  const [bankruptcyFilingScore, setBankruptcyFilingScore] = React.useState(10);
  const [buttonColor, setButtonColor] = React.useState("success");

  const [dataStatus, setDataStatus] = React.useState(false);


  const [uccCount, setUccCount] = React.useState(0);
  //const [taxLiensCount, setTaxLiensCount] = React.useState(0);
  const [civilSuitsCount, setCivilSuitsCount] = React.useState(0);
  const [judgmentCount, setJudgmentCount] = React.useState(0);
  const [liensCount, setTaxLiensCount] = React.useState(0);
  const [filingStatusCount, setFilingStatusCount] = React.useState(0);
  const [bankruptcy, setBankruptcy] = React.useState(false);
  
  const [totTaxLienceAmount, setTotTaxLienceAmount] = React.useState(0);
  const [totSuitsAmount, setTotSuitsAmount] = React.useState(0);
  const [totalJudgmentAmount, setTotalJudgmentAmount] = React.useState(0);
  const [totLiensAndJudgment, setTotLiensAndJudgment] = React.useState("");

    const [uccFilingScoreCSS, setUccFilingScoreCSS] = React.useState("");
    const [taxLiensJudgementScoreCSS, setTaxLiensJudgementScoreCSS] = React.useState("");
    const [civilSuitsScoreCSS, setCivilSuitsScoreCSS] = React.useState("");
    const [corporationFilingStatusScoreCSS, setCorporationFilingStatusScoreCSS] = React.useState("");
    const [bankruptcyFilingScoreCSS, setBankruptcyFilingScoreCSS] = React.useState("");
   

    const [uccFilingScoreDescription, setUccFilingScoreDescription] = React.useState("");
    const [civilSuitsScoreDescription, setCivilSuitsScoreDescription] = React.useState("");
    const [taxLiensJudgementScoreDescription, setTaxLiensJudgementScoreDescription] = React.useState("");
    const [corporationFilingStatusScoreDescription, setCorporationFilingStatusScoreDescription] = React.useState("");

 const [derogatoryScoreCSS, setDerogatoryScoreCSS] = React.useState("");
 const [month1, setMonth1] = React.useState(moment().subtract(1, "month").format('MMM').toLowerCase());

 const [month, setMonth] = React.useState(moment().subtract(1, "month").format('MMMM').toUpperCase());
 const [tenantAddress, setTenantAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const[propertyName,setPropertyName]=React.useState("");
  
 const [showLien, setShowLien] = React.useState("");
 const [showUcc, setShowUcc] = React.useState("");
   
 const [showSuits, setShowSuits] = React.useState("");
 const [status, setStatus] = React.useState("");
 const [year,setYear] = React.useState(moment().format('YYYY').toLowerCase());

 const[selectedFundId,setSelectedFundId]=React.useState("");
 const[selectedFundName,setSelectedFundName]=React.useState("");
 const[propertyId,setPropertyId]=React.useState();
 const[imageUrl,setImageUrl]=React.useState("");
 const[currentStatus,setCurrentStatus]=React.useState("");
 const[colorCode,setColorCode]=React.useState("");
 const[propertyAddress,setPropertyAddress]=React.useState("");
 const[score,setScore]=React.useState("");
 const[userId,setUserId]=React.useState("");
 
  React.useEffect(() => {
    setShowBankruptcyFiling(false);
    setShowDerog(true);
if(!dataStatus){
   getTmDastmDerogatoryScoreCount(tenantId);
   getTenantPropertyDetails(tenantId,month1,year);
  //  getCreditSafeBankoReportByTenant(tenantId);
  // getTmCreditScoreRequest(tenantId);
}
   
else{


  setUccCount(derogatoryScoreCount.uccCount);
  setTaxLiensCount(derogatoryScoreCount.liensCount)
  setCivilSuitsCount(derogatoryScoreCount.suitsCount)
  
  setTotTaxLienceAmount(derogatoryScoreCount.taxLiens);
  setTotSuitsAmount(derogatoryScoreCount.suits)

  setJudgmentCount(derogatoryScoreCount.judgmentCount);
  setTotLiensAndJudgment(derogatoryScoreCount.totLiensAndJudgment);

    setUccFilingScore(derogatoryScoreCount.uccFilingScore);
    setTaxLiensJudgementScore(derogatoryScoreCount.taxLiensJudgementScore);
    setCivilSuitsScore(derogatoryScoreCount.civilSuitsScore);
    setCorporationFilingStatusScore(derogatoryScoreCount.corporationFilingStatusScore);
    setDerogatoryScore(derogatoryScoreCount.derogatoryScore);
    setBankruptcy(derogatoryScoreCount.bankruptcy)
    
setCorporationFilingStatusScoreDescription(derogatoryScoreCount.filingStatusCommentary);
    //   // setUccCount(derogatoryScoreCount.uccCount);
    // setTaxLiensCount(tenantsScoreByType.liensCount)
    // setCivilSuitsCount(tenantsScoreByType.suitsCount)
    // // setFilingStatusCount(derogatoryScoreCount.filingStatusCount)
    // // setBankruptcy(derogatoryScoreCount.bankruptcy)
    // setTotTaxLienceAmount(tenantsScoreByType.taxLiens);
    // setTotSuitsAmount(tenantsScoreByType.suits)
    // setTotalJudgmentAmount(tenantsScoreByType.judgment)

    
    setUccFilingScoreCSS(derogatoryScoreCount.uccCss);
    setCivilSuitsScoreCSS(derogatoryScoreCount.suitsCss);
    setTaxLiensJudgementScoreCSS(derogatoryScoreCount.liensJudgmentCss);
    setCorporationFilingStatusScoreCSS(derogatoryScoreCount.filingStatusCss);
    setDerogatoryScoreCSS(derogatoryScoreCount.deroCss);

   
    // setCurrCreditOverallStatus(derogatoryScoreCount.currCreditOverallStatus);

    setUccFilingScoreDescription(derogatoryScoreCount.uccCommentary);
    setCivilSuitsScoreDescription(derogatoryScoreCount.suitsCommentary);
    setTaxLiensJudgementScoreDescription(derogatoryScoreCount.liensJudgmentCommentary);
    setCorporationFilingStatusScoreDescription(derogatoryScoreCount.filingStatusCommentary);
  
    setTenantAddress(tenantPropertyDetails.tenantAddress);
    setCity(tenantPropertyDetails.tenantCity);
    setState(tenantPropertyDetails.tenantState);
    setZip(tenantPropertyDetails.tenantZip);
    setPropertyName(tenantPropertyDetails.propertyName);
    setImageUrl(tenantPropertyDetails.imageUrl);
    setPropertyAddress(tenantPropertyDetails.propertyAddress);
    setCurrentStatus(tenantPropertyDetails.currentStatus);
    setScore(tenantPropertyDetails.propertyScore);
    setUserId(tenantPropertyDetails.userId);
    setPropertyId(tenantPropertyDetails.propertyId);
    setSelectedFundId(tenantPropertyDetails.fundId);
    setSelectedFundName(tenantPropertyDetails.fundName);
    setColorCode(tenantPropertyDetails.colorCode);

    if(derogatoryScoreCount.derogatoryScore==10 && derogatoryScoreCount.bankruptcyEnabled==true){
setShowBankruptcyFiling(true);
setShowDerog(false);
    }
    else if(derogatoryScoreCount.derogatoryScore==10 && derogatoryScoreCount.bankruptcyEnabled==false){
      setShowBankruptcyFiling(false);
setShowDerog(true);
          }


    if(derogatoryScoreCount.uccFilingScore==0 || derogatoryScoreCount.uccFilingScore=="" || derogatoryScoreCount.uccFilingScore==null || derogatoryScoreCount.noApiDataAvailableUcc==false){
     setUccFilingScore("NA");
     setUccFilingScoreCSS("#D3D3D3");
     setUccFilingScoreDescription("No Data Available");
    }
    
    if(derogatoryScoreCount.taxLiensJudgementScore==0 || derogatoryScoreCount.taxLiensJudgementScore=="" || derogatoryScoreCount.taxLiensJudgementScore==null || derogatoryScoreCount.noApiDataAvailableLiensJudg==false){
      setTaxLiensJudgementScore("NA");
      setTaxLiensJudgementScoreCSS("#D3D3D3");
      setTaxLiensJudgementScoreDescription("No Data Available");
    
     
    }
    
    if(derogatoryScoreCount.civilSuitsScore==0 || derogatoryScoreCount.civilSuitsScore=="" || derogatoryScoreCount.civilSuitsScore==null  || derogatoryScoreCount.noApiDataAvailableSuits==false){
      setCivilSuitsScore("NA");
      setCivilSuitsScoreCSS("#D3D3D3");
      setCivilSuitsScoreDescription("No Data Available")
    }
    
    if(derogatoryScoreCount.corporationFilingStatusScore==0 || derogatoryScoreCount.corporationFilingStatusScore=="" || derogatoryScoreCount.corporationFilingStatusScore==null || derogatoryScoreCount.noApiDataAvailableCfs==false){
      setCorporationFilingStatusScore("NA");
  
    }
    
    if(derogatoryScoreCount.derogatoryScore==0 || derogatoryScoreCount.derogatoryScore=="" || derogatoryScoreCount.derogatoryScore==null || derogatoryScoreCount.noApiDataAvailableDerog==false ||  derogatoryScoreCount.isBankruptcy==false){
      setDerogatoryScore("NA");
     
     
    }
    // if(derogatoryScoreCount.uccFilingScore==0 || derogatoryScoreCount.uccFilingScore=="" || derogatoryScoreCount.uccFilingScore==null ){
    //  setUccFilingScore("NA");
    //  setUccFilingScoreCSS("#D3D3D3");
    // }
    
    // if(derogatoryScoreCount.taxLiensJudgementScore==0 || derogatoryScoreCount.taxLiensJudgementScore=="" || derogatoryScoreCount.taxLiensJudgementScore==null ){
    //   setTaxLiensJudgementScore("NA");
    //   setTaxLiensJudgementScoreCSS("#D3D3D3");
    // }
    
    // if(derogatoryScoreCount.civilSuitsScore==0 || derogatoryScoreCount.civilSuitsScore=="" || derogatoryScoreCount.civilSuitsScore==null ){
    //   setCivilSuitsScore("NA");
    //   setCivilSuitsScoreCSS("#D3D3D3");
    // }
    
    // if(derogatoryScoreCount.corporationFilingStatusScore==0 || derogatoryScoreCount.corporationFilingStatusScore=="" || derogatoryScoreCount.corporationFilingStatusScore==null ){
    //   setCorporationFilingStatusScore("NA");
    //   setCorporationFilingStatusScoreCSS("#D3D3D3");
      
    // }
    
    // if(derogatoryScoreCount.derogatoryScore==0 || derogatoryScoreCount.derogatoryScore=="" || derogatoryScoreCount.derogatoryScore==null ){
    //   setDerogatoryScore("NA");
    //   setDerogatoryScoreCSS("#D3D3D3");
    // }
    


    if(liensCount>0 && judgmentCount==0){

      setShowLien( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenLiensreport}>{liensCount}</Link> Liens   and {judgmentCount} Judgements with a total value of  <CurrencyFormat value={totLiensAndJudgment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b>);
      
    }else if(liensCount==0 && judgmentCount>0){

     setShowLien( <b>The company has {liensCount} Liens   and <Link component="button"
     variant="body2" onClick={handleClickOpenLiensreport}>{judgmentCount} </Link>  Judgements with a total value of  <CurrencyFormat value={totLiensAndJudgment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b>);
     
    }else if(liensCount>0 && judgmentCount>0){

      setShowLien( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenLiensreport}>{liensCount}</Link> Liens   and <Link component="button"
      variant="body2" onClick={handleClickOpenLiensreport}>{judgmentCount} </Link>  Judgements with a total value of  <CurrencyFormat value={totLiensAndJudgment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b>);
      
     }else{
      setShowLien(<b> The company has 0 Liens and 0 Judgements.</b> );
    }
     if(taxLiensJudgementScore=="NA"){
      setShowLien(<b>No Data Available</b> );
    }
    // if(judgementCount>0){
      
    //   setShowUcc( <b>The company has <Link component="button"
    //   variant="body2" onClick={handleClickOpenCautionaryreports}>{uccCount}</Link>  Cautionary UCC filings</b>);
    // }else{
    //   setShowUcc(  <b> The company has 0 Cautionary UCC filings </b>);
    // }

    if(civilSuitsCount>0){

      setShowSuits( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenCivilsuitsreport}>{civilSuitsCount}</Link>  Filings with a total value of  ${totSuitsAmount} in Civil suits</b>);

    //  setShowSuits( <b>The company has <Link to={{pathname:'/admin/civilsuitsreport/', state :{tenantId:tenantId,suitsScore:civilSuitsScore} } }>{civilSuitsCount}</Link>  Filings with a total value of  ${totSuitsAmount} in Civil suits</b>);
     //setShowSuits( <b>The company has<Link onClick={handleClickOpenCautionaryreports}> {civilSuitsCount}</Link>  Filings with a total value of  ${totSuitsAmount} in Civil suits</b>);
    }else { 
      setShowSuits(<b>The company has 0 Filings in Civil suits</b>);
    }
    if(civilSuitsScore=="NA"){
      setShowSuits(<b>No Data Available</b> );
    }

    

    if(uccCount>0){
      
      setShowUcc( <b>The company has <Link component="button"
      variant="body2" onClick={handleClickOpenCautionaryreports}>{uccCount}</Link>  Cautionary UCC filings</b>);
    }else {
      setShowUcc(  <b> The company has 0 Cautionary UCC filings </b>);
    }
    if(uccFilingScore=="NA"){
      setShowUcc(<b>No Data Available</b> );
    }

    if(corporationFilingStatusScore==450){
setStatus("Active")
    }else if(corporationFilingStatusScore==10){
      setStatus("Inactive");
    }
    }


   
    // if(uccFilingScore > 401 && uccFilingScore <= 501){
    //   setUccFilingScoreCSS("#4caf50")
    //  }
    //  else if(uccFilingScore > 301 && uccFilingScore < 400){
    //   setUccFilingScoreCSS("#32cd32")
    //  }else if(uccFilingScore > 201 && uccFilingScore <= 300){
    //   setUccFilingScoreCSS("#ffdf00")
    //  }else if(uccFilingScore > 101 && uccFilingScore <= 200){
    //   setUccFilingScoreCSS("#ff9800")
    // }else if(uccFilingScore => 0 && uccFilingScore <= 100){
    //   setUccFilingScoreCSS("#FF0000")
    // }


    // if(taxLiensJudgementScore > 401 && taxLiensJudgementScore <= 501){
    //   setTaxLiensJudgementScoreCSS("#4caf50")
    //  }else if(taxLiensJudgementScore > 301 && taxLiensJudgementScore < 400){
    //   setTaxLiensJudgementScoreCSS("#32cd32")
    //  }else if(taxLiensJudgementScore > 201 && taxLiensJudgementScore <= 300){
    //   setTaxLiensJudgementScoreCSS("#ffdf00")
    //  }else if(taxLiensJudgementScore > 101 && taxLiensJudgementScore <= 200){
    //   setTaxLiensJudgementScoreCSS("#ff9800")
    // }else if(taxLiensJudgementScore => 0 && taxLiensJudgementScore <= 100){
    //   setTaxLiensJudgementScoreCSS("#FF0000")
    // }


    //    if(taxLiensJudgementScore > 401 && taxLiensJudgementScore <= 500){
    //   setTaxLiensJudgementScoreCSS("#4caf50")
    //  }else if(taxLiensJudgementScore > 301 && taxLiensJudgementScore < 400){
    //   setTaxLiensJudgementScoreCSS("#32cd32")
    //  }else if(taxLiensJudgementScore > 201 && taxLiensJudgementScore <= 300){
    //   setTaxLiensJudgementScoreCSS("#ffdf00")
    //  }else if(taxLiensJudgementScore > 101 && taxLiensJudgementScore <= 200){
    //   setTaxLiensJudgementScoreCSS("#ff9800")
    // }else if(taxLiensJudgementScore => 0 && taxLiensJudgementScore <= 100){
    //   setTaxLiensJudgementScoreCSS("#FF0000")
    // }


    // if(civilSuitsScore > 401 && civilSuitsScore <= 501){
    //   setCivilSuitsScoreCSS("#4caf50")
    //  }else if(civilSuitsScore > 301 && civilSuitsScore < 400){
    //   setCivilSuitsScoreCSS("#32cd32")
    //  }else if(civilSuitsScore > 201 && civilSuitsScore <= 300){
    //   setCivilSuitsScoreCSS("#ffdf00")
    //  }else if(civilSuitsScore > 101 && civilSuitsScore <= 200){
    //   setCivilSuitsScoreCSS("#ff9800")
    // }else if(civilSuitsScore => 0 && civilSuitsScore <= 100){
    //   setCivilSuitsScoreCSS("#FF0000")
    // }


    // if(corporationFilingStatusScore > 401 && corporationFilingStatusScore <= 500){
    //   setCorporationFilingStatusScoreCSS("#4caf50")
    //  }else if(corporationFilingStatusScore > 301 && corporationFilingStatusScore < 400){
    //   setCorporationFilingStatusScoreCSS("#32cd32")
    //  }else if(corporationFilingStatusScore > 201 && corporationFilingStatusScore <= 300){
    //   setCorporationFilingStatusScoreCSS("#ffdf00")
    //  }else if(corporationFilingStatusScore > 101 && corporationFilingStatusScore <= 200){
    //   setCorporationFilingStatusScoreCSS("#ff9800")
    // }else if(corporationFilingStatusScore > 0 && corporationFilingStatusScore <= 100){
    //   setCorporationFilingStatusScoreCSS("#FF0000")
    // }



    if(bankruptcyFilingScore > 401 && bankruptcyFilingScore <= 500){
      setBankruptcyFilingScoreCSS("#4caf50")
     }else if(bankruptcyFilingScore > 301 && bankruptcyFilingScore < 400){
      setBankruptcyFilingScoreCSS("#32cd32")
     }else if(bankruptcyFilingScore > 201 && bankruptcyFilingScore <= 300){
      setBankruptcyFilingScoreCSS("#ffdf00")
     }else if(bankruptcyFilingScore > 101 && bankruptcyFilingScore <= 200){
      setBankruptcyFilingScoreCSS("#ff9800")
    }else if(bankruptcyFilingScore => 0 && bankruptcyFilingScore <= 100){
      setBankruptcyFilingScoreCSS("#FF0000")
    }

    setTimeout(() => {
      setDataStatus(true);
    }, 3000);


  }, [dataStatus,derogatoryScoreCount]);


  // const getTmCreditScoreRequest = async (tenantId) => {
       
  //   await tmCreditScoreRequest(tenantId);
   
   
  //  setUccFilingScore(creditScoreData.uccFilingScore);
  //  setTaxLiensJudgementScore(creditScoreData.taxLiensJudgementScore);
  //  setCivilSuitsScore(creditScoreData.civilSuitsScore);
  //  setCorporationFilingStatusScore(creditScoreData.corporationFilingStatusScore);
  //  setDerogatoryScore(creditScoreData.derogatoryScore);
  //  setCreditScore(creditScoreData.creditScore);
  //  setBankruptcyFilingScore(creditScoreData.bankruptcyFilingScore);
  //   };

    // const [openCautionaryreports, setOpenCautionaryreports] = React.useState(false);
  const [showCautionaryreports, setShowCautionaryreports] = React.useState(false);
  const [showCivilsuitsreport, setShowCivilsuitsreport] = React.useState(false);
  const [showLiensreport, setShowLiensreport] = React.useState(false);
  const [showJudgementreport, setShowJudgementreport] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [prev1Year, setPrev1Year] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());

const [showBankruptcyFiling,setShowBankruptcyFiling] = React.useState(true);
const [showDerog,setShowDerog] = React.useState(true);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      

    const handleClickOpenCautionaryreports = () => {
  
      setShowCautionaryreports(true);
      setShowCivilsuitsreport(false);
      setShowLiensreport(false);
      setShowJudgementreport(false);
    };
    const handleClickOpenCivilsuitsreport = () => {
      setShowCautionaryreports(false);
      setShowCivilsuitsreport(true);
      setShowLiensreport(false);
      setShowJudgementreport(false);
    };
    const handleClickOpenLiensreport = () => {
      setShowCautionaryreports(false);
      setShowCivilsuitsreport(false);
      setShowLiensreport(true);
      setShowJudgementreport(false);
    };
    const handleClickOpenJudgementreport = () => {
      setShowCautionaryreports(false);
      setShowCivilsuitsreport(false);
      setShowLiensreport(false);
      setShowJudgementreport(true);
    };


    const handleCloseCautionaryreports = () => {
      setShowCautionaryreports(false);
    };

    const handleCloseCivilsuitsreport = () => {
      setShowCivilsuitsreport(false);
    };


    const handleCloseLiensreport = () => {
      setShowLiensreport(false);
    };

    const handleCloseJudgementreport = () => {
      setShowJudgementreport(false);
    };


  const getTmDastmDerogatoryScoreCount= async (tenantId) => {
    await tmDerogatoryScoreCountRequest(tenantId);

    //setDataStatus(true);
    

    };
    const getTenantPropertyDetails=async (tenantId,month,year) => {
      await tmGetTenantPropertyDetailsRequest(tenantId,month,year);
      console.log("tenantPropertyDetails"+JSON.stringify(tenantPropertyDetails));
      // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    }
    // const getCreditSafeBankoReportByTenant = async (tenantId)=>{
  
    //   await  tmCreditSafeBankruptcyReportByTenantIdRequest(tenantId)
    //    console.log("creditSafeBankruptcyReport"+JSON.stringify(creditSafeBankruptcyReport));
      
    //     }

    const enableProperty = () => {
  
      history.push({ 
        pathname: '/admin/monitored-tenants-property-details',
        state:{fundId:selectedFundId,fundName:selectedFundName,propertyName:propertyName,propertyId:propertyId,propertyAddress:propertyAddress,score:score,currentStatus:currentStatus,colorCode:colorCode,imageUrl:imageUrl,userId:userId}
       });
      // <Redirect to="/property-details"/>
    
    }
    const enableTenant = () => {
      
      history.push({ 
        pathname: '/admin/monitored-tenant-risk',
        state:{tenantId:tenantId,userId:userId,tenantName:tenantName,city:city,state:state,zip:zip,tenantAddress:tenantAddress}
       });
      // <Redirect to="/property-details"/>
    
    }
  const classes = useStyles();

  if(dataStatus){
  return (
 
    <div>
      <Tooltip classes={{ tooltip: classes.customWidth }} title=" Click here to go to Dashboard" >
      <h3 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px",cursor:'pointer'}} onClick={enableTenant}>Tenant Name: {tenantName}</h3>
</Tooltip>
      <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px" }}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>
      <h4 style={{color: megaColor, fontWeight:'bold',fontSize:"18px",cursor:'pointer'}} onClick={enableProperty}><b>Property Name:&nbsp;{propertyName}</b></h4>

 <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px" }}>Derogatory Score - [{month}&nbsp;{prev1Year}]</h4>
 
 
     <GridContainer className={classes.gridBorder}>

       
      
<GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center">
<h6 style={{ color: megaColor, fontWeight:'bold' }}></h6>  
<Button round  color={buttonColor} style={{color: "#000000",backgroundColor: derogatoryScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{derogatoryScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
 
</GridItem>


<GridItem xs={12} sm={9} md={9} className={classes.vertical} style={{paddingLeft:'300px'}}justify = "right">
                
                <div className={classes.buttonGroup} style={{paddingLeft:'30px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'#FF0000'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'#4caf50'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>102 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>

  </GridItem>


</GridContainer>

       

     {showDerog&& <GridContainer className={classes.gridBorder}>

   
    

      <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>

        <b style={{color:megaColor}}>     Cautionary UCC     </b>  
          
        </GridItem>

   

         <GridItem xs={12} sm={3} md={3} className={classes.vertical}  justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
        
        <Button round  style={{color:"#000000",backgroundColor: uccFilingScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{uccFilingScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
      
        </GridItem>
       
  
   
        <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '#D3D3D3',paddingTop:'45px'}}>
           
         
    
     {showUcc}
       <Dialog fullWidth={true}
  maxWidth={'lg'} open={showCautionaryreports} onClose={handleCloseCautionaryreports} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <GridItem align="right">
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseCautionaryreports}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
</GridItem>
            {showCautionaryreports && <Cautionaryreports year={prev1Year} month1={month1} tenantId ={selectedTenantId} uccScore={uccFilingScore} uccFilingScoreCSS={uccFilingScoreCSS} />}
        
            </Dialog>
        
       {/* <b> The company has {uccCount} Cautionary UCC filings </b> */}

   
  </GridItem>




  <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
  <b style={{color:megaColor}}>    Tax Liens & Judgement    </b>
           
          </GridItem>
  
        
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical}  justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: taxLiensJudgementScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{taxLiensJudgementScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
           {showLien}
           <Dialog fullWidth={true}
  maxWidth={'xl'} open={showLiensreport} onClose={handleCloseLiensreport}  aria-labelledby="form-dialog-title">
    
        <GridItem align="right">
       
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseLiensreport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
        
        
            </DialogActions>
        
            </GridItem>
            {showLiensreport && <Liensreport year={prev1Year} month1={month1} tenantId ={selectedTenantId} liensJudgementScore={taxLiensJudgementScore} taxLiensJudgementScoreCSS={taxLiensJudgementScoreCSS}/>}
            {/* filedDate={filedDate} jurisdiction={jurisdiction} securedPartyName={securedPartyName} collateral={collateral} */}
            </Dialog>
 
            {/* <Dialog fullWidth={true}
  maxWidth={'lg'} open={showJudgementreport} onClose={handleCloseJudgementreport}  aria-labelledby="form-dialog-title">
    
        <GridItem align="right">
       
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseJudgementreport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
        
        
            </DialogActions>
        
            </GridItem>
            {showJudgementreport && <Judgementreport  tenantId ={selectedTenantId} taxLiensJudgementScore={taxLiensJudgementScore}/>}
            {/* filedDate={filedDate} jurisdiction={jurisdiction} securedPartyName={securedPartyName} collateral={collateral} */}
            {/* </Dialog> */} 
    </GridItem>
  


    <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
    <b style={{color:megaColor}}>Civil Suits      </b>      
              
          </GridItem>
  
         
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: civilSuitsScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{civilSuitsScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
        
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
        {showSuits}

        <Dialog fullWidth={true}
  maxWidth={'lg'} open={showCivilsuitsreport} onClose={handleCloseCivilsuitsreport} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
       <GridItem align="right">
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseCivilsuitsreport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
            {/* <Button onClick={handleCloseCivilsuitsreport} color="info">
            Close
            </Button> */}
            </DialogActions>
            </GridItem>
            {showCivilsuitsreport && <Civilsuitsreport year={prev1Year} month1={month1} tenantId ={selectedTenantId} suitsScore={civilSuitsScore} civilSuitsScoreCSS={civilSuitsScoreCSS} />}
            {/* filedDate={filedDate} jurisdiction={jurisdiction} securedPartyName={securedPartyName} collateral={collateral} */}
            </Dialog>
    </GridItem>

    <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
        
     <b style={{color:megaColor}}> Corporation Filing Status    </b>       
          </GridItem>
  
  
           <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:'center'}}>
          
          <Button round  style={{color:"#000000",backgroundColor: corporationFilingStatusScoreCSS,fontSize:'20px'}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{corporationFilingStatusScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem>
         
     
          <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'45px'}}>
  <b> {corporationFilingStatusScoreDescription} </b>

     
    </GridItem>




 
        
      </GridContainer>}
      {showBankruptcyFiling&&  <GridContainer className={classes.gridBorder}>
  <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'50px',textAlign:"center"}}>
        
     <b style={{color:megaColor}}> Bankruptcy Filing  </b>       
          </GridItem> 
  
  
        <GridItem xs={12} sm={3} md={3} className={classes.vertical} justify = "center" style={{ border: '1px solid #D3D3D3',paddingTop:'30px',textAlign:"center"}}>
          
          <Button round  style={{backgroundColor: bankruptcyFilingScoreCSS,color:"#000000",fontSize:"20px"}} > &nbsp;&nbsp;&nbsp;&nbsp; <b>{bankruptcyFilingScore} </b>&nbsp;&nbsp;&nbsp;&nbsp; </Button>
        
          </GridItem> 
       
    
     
     <GridItem xs={12} sm={6} md={6} className={classes.vertical} style={{ border: '1px solid #D3D3D3',paddingTop:'50px'}}>
          <b>The company filed chapter 11 bankruptcy
</b>
     
    </GridItem> 

</GridContainer>}
    </div>
  
  );
}else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
}

DerogatoryReport.propTypes={
  loading: PropTypes.bool.isRequired,
  tmDerogatoryScoreCountRequest: PropTypes.func.isRequired,
  tmCreditSafeBankruptcyReportByTenantIdRequest: PropTypes.func.isRequired,
  tmGetTenantPropertyDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  derogatoryScoreCount :state.tenantmonitoring.derogatoryScoreCount,
  creditSafeBankruptcyReport: state.tenantmonitoring.creditSafeBankruptcyReport,
  tenantPropertyDetails: state.tenantmonitoring.tenantPropertyDetails,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DerogatoryReport);
