
export const Types = {
    LIST_REQUEST: 'tenantmonitoring/LIST_REQUEST',
    LIST_SUCCESS: 'tenantmonitoring/SIGN_SUCCESS',

    TENANT_MONITORING_LIST_BY_FUND_REQUEST:'tenantmonitoring/TENANT_MONITORING_LIST_BY_FUND_REQUEST',
    TENANT_MONITORING_LIST_BY_FUND_SUCCESS:'tenantmonitoring/TENANT_MONITORING_LIST_BY_FUND_SUCCESS',

    TENANT_MONITORING_LIST_BY_PROPERTY_REQUEST:'tenantmonitoring/TENANT_MONITORING_LIST_BY_PROPERTY_REQUEST',
    TENANT_MONITORING_LIST_BY_PROPERTY_SUCCESS:'tenantmonitoring/TENANT_MONITORING_LIST_BY_PROPERTY_SUCCESS',
   

    LIST_NOTIFICATION_REQUEST: 'tenantmonitoring/LIST_NOTIFICATION_REQUEST',
    LIST_NOTIFICATION_SUCCESS: 'tenantmonitoring/LIST_NOTIFICATION_SUCCESS',
    LIST_BANKO_NOTIFICATION_REQUEST: 'tenantmonitoring/LIST_BANKO_NOTIFICATION_REQUEST',
    LIST_BANKO_NOTIFICATION_SUCCESS: 'tenantmonitoring/LIST_BANKO_NOTIFICATION_SUCCESS',
    DASHBOARD_REQUEST: 'tenantmonitoring/DASHBOARD_REQUEST',
    DASHBOARD_SUCCESS: 'tenantmonitoring/DASHBOARD_SUCCESS',
    PIRS_REQUEST: 'tenantmonitoring/PIRS_REQUEST',
    PIRS_SUCCESS: 'tenantmonitoring/PIRS_SUCCESS',
    CREDIT_SCORE_REQUEST: 'tenantmonitoring/CREDIT_SCORE_REQUEST',
    SCORE_COMMENTARY_REQUEST: 'tenantmonitoring/SCORE_COMMENTARY_REQUEST',
    SCORE_COMMENTARY_SUCCESS: 'tenantmonitoring/SCORE_COMMENTARY_SUCCESS',
    CREDIT_SCORE_REQUEST: 'tenantmonitoring/CREDIT_SCORE_REQUEST',
    CREDIT_SCORE_SUCCESS: 'tenantmonitoring/CREDIT_SCORE_SUCCESS',
    LIST_LEXIS_NEXIS_NOTIFICATION_REQUEST: 'tenantmonitoring/LIST_LEXIS_NEXIS_NOTIFICATION_REQUEST',
    LIST_LEXIS_NEXIS_NOTIFICATION_SUCCESS: 'tenantmonitoring/LIST_LEXIS_NEXIS_NOTIFICATION_SUCCESS',
    DEROGATORY_SCORE_COUNT_REQUEST:'tenantmonitoring/DEROGATORY_SCORE_COUNT_REQUEST',
    DEROGATORY_SCORE_COUNT_SUCCESS:'tenantmonitoring/DEROGATORY_SCORE_COUNT_SUCCESS',
    FUND_RISK_COUNT_REQUEST:'tenantmonitoring/FUND_RISK_COUNT_REQUEST',
    FUND_RISK_COUNT_SUCCESS:'tenantmonitoring/FUND_RISK_COUNT_SUCCESS',
    TENANTS_SCORE_BY_RANGE_REQUEST:'tenantmonitoring/TENANTS_SCORE_BY_RANGE_REQUEST',
    TENANTS_SCORE_BY_RANGE_SUCCESS:'tenantmonitoring/TENANTS_SCORE_BY_RANGE_SUCCESS',
    TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_REQUEST:'tenantmonitoring/TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_REQUEST',
    TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_SUCCESS:'tenantmonitoring/TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_SUCCESS',
    TENANTS_SCORE_BY_TYPE_REQUEST:'tenantmonitoring/TENANTS_SCORE_BY_TYPE_REQUEST',
    TENANTS_SCORE_BY_TYPE_SUCCESS:'tenantmonitoring/TENANTS_SCORE_BY_TYPE_SUCCESS',
    TENANTS_COUNT_BY_FUND_REQUEST:'tenantmonitoring/TENANTS_COUNT_BY_FUND_REQUEST',
    TENANTS_COUNT_BY_FUND_SUCCESS:'tenantmonitoring/TENANTS_COUNT_BY_FUND_SUCCESS',
    SCORE_STATUS_REQUEST:'tenantmonitoring/SCORE_STATUS_REQUEST',
    SCORE_STATUS_SUCCESS:'tenantmonitoring/SCORE_STATUS_SUCCESS',
    TENANT_OVERALL_SCORE_BY_PROPERTY_REQUEST:'tenantmonitoring/TENANT_OVERALL_SCORE_BY_PROPERTY_REQUEST',
    TENANT_OVERALL_SCORE_BY_PROPERTY_SUCCESS:'tenantmonitoring/TENANT_OVERALL_SCORE_BY_PROPERTY_SUCCESS',
    FUNDS_BY_ORG_REQUEST:'tenantmonitoring/FUNDS_BY_ORG_REQUEST',
    FUNDS_BY_ORG_SUCCESS:'tenantmonitoring/FUNDS_BY_ORG_SUCCESS',
    TENANTS_BY_FUNDID_RANGE_REQUEST:'tenantmonitoring/TENANTS_BY_FUNDID_RANGE_REQUEST',
    TENANTS_BY_FUNDID_RANGE_SUCCESS:'tenantmonitoring/TENANTS_BY_FUNDID_RANGE_SUCCESS',
    TENANTS_RISK_COUNT_BY_FUND_REQUEST:'tenantmonitoring/TENANTS_RISK_COUNT_BY_FUND_REQUEST',
    TENANTS_RISK_COUNT_BY_FUND_SUCCESS:'tenantmonitoring/TENANTS_RISK_COUNT_BY_FUND_SUCCESS',
   
    PROPERTY_BY_ORGID_REQUEST:'tenantmonitoring/PROPERTY_BY_ORGID_REQUEST',
    PROPERTY_BY_ORGID_SUCCESS:'tenantmonitoring/PROPERTY_BY_ORGID_SUCCESS',

    PROPERTY_BY_FUND_REQUEST:'tenantmonitoring/PROPERTY_BY_FUND_REQUEST',
    PROPERTY_BY_FUND_SUCCESS:'tenantmonitoring/PROPERTY_BY_FUND_SUCCESS',

    PROPERTY_BY_TENANT_ORGID_REQUEST:'tenantmonitoring/PROPERTY_BY_TENANT_ORGID_REQUEST',
    PROPERTY_BY_TENANT_ORGID_SUCCESS:'tenantmonitoring/PROPERTY_BY_TENANT_ORGID_SUCCESS',
    PROPERTY_BY_ORG_REQUEST: 'tenantmonitoring/PROPERTY_BY_ORG_REQUEST',
    PROPERTY_BY_ORG_SUCCESS:'tenantmonitoring/PROPERTY_BY_ORG_SUCCESS',
    PROPERTY_DASHBOARD_BY_FUND_REQUEST: 'tenantmonitoring/PROPERTY_DASHBOARD_BY_FUND_REQUEST',
    PROPERTY_DASHBOARD_BY_FUND_SUCCESS:'tenantmonitoring/PROPERTY_DASHBOARD_BY_FUND_SUCCESS',

    PROPERTY_DASHBOARD_COUNT_REQUEST:'tenantmonitoring/PROPERTY_DASHBOARD_COUNT_REQUEST',
    PROPERTY_DASHBOARD_COUNT_SUCCESS:'tenantmonitoring/PROPERTY_DASHBOARD_COUNT_SUCCESS',

    TENANTS_COUNT_BY_PROPERTY_REQUEST:'tenantmonitoring/TENANT_COUNT_BY_PROPERTY_REQUEST',
    TENANTS_COUNT_BY_PROPERTY_SUCCESS:'tenantmonitoring/TENANT_COUNT_BY_PROPERTY_SUCCESS',
    TENANTS_RISK_COUNT_BY_PROPERTY_REQUEST:'tenantmonitoring/TENANTS_RISK_COUNT_BY_PROPERTY_REQUEST',
    TENANTS_RISK_COUNT_BY_PROPERTY_SUCCESS:'tenantmonitoring/TENANTS_RISK_COUNT_BY_PROPERTY_SUCCESS',

    TENANTS_BY_PROPERTY_REQUEST:'tenantmonitoring/TENANTS_BY_PROPERTY_REQUEST',
    TENANTS_BY_PROPERTY_SUCCESS:'tenantmonitoring/TENANTS_BY_PROPERTY_SUCCESS',

    TENANTS_BY_PROPERTY_RANGE_REQUEST:'tenantmonitoring/TENANTS_BY_PROPERTY_RANGE_REQUEST',
    TENANTS_BY_PROPERTY_RANGE_SUCCESS:'tenantmonitoring/TENANTS_BY_PROPERTY_RANGE_SUCCESS',

    TENANTS_MONTH_PIRS_REQUEST:'tenantmonitoring/TENANTS_MONTH_PIRS_REQUEST',
    TENANTS_MONTH_PIRS_SUCCESS:'tenantmonitoring/TENANTS_MONTH_PIRS_SUCCESS',


//jain

    FUND_SAVE_REQUEST:'tenantmonitoring/FUND_SAVE_REQUEST',
    FUND_SAVE_SUCCESS:'tenantmonitoring/FUND_SAVE_SUCCESS',
    FUND_EDIT_REQUEST:'tenantmonitoring/FUND_EDIT_REQUEST',
    FUND_EDIT_SUCCESS:'tenantmonitoring/FUND_EDIT_SUCCESS',
    FUND_DELETE_REQUEST:'tenantmonitoring/FUND_DELETE_REQUEST',
    FUND_DELETE_SUCCESS:'tenantmonitoring/FUND_DELETE_SUCCESS',
    FUND_LIST_REQUEST:'tenantmonitoring/FUND_LIST_REQUEST',
    FUND_LIST_SUCCESS:'tenantmonitoring/FUND_LIST_SUCCESS',

    FUND_DASHBOARD_COUNT_REQUEST:'tenantmonitoring/FUND_DASHBOARD_COUNT_REQUEST',
    FUND_DASHBOARD_COUNT_SUCCESS:'tenantmonitoring/FUND_DASHBOARD_COUNT_SUCCESS',

    COMPANY_SAVE_REQUEST:'tenantmonitoring/COMPANY_SAVE_REQUEST',
    COMPANY_SAVE_SUCCESS:'tenantmonitoring/COMPANY_SAVE_SUCCESS',
    COMPANY_EDIT_REQUEST:'tenantmonitoring/COMPANY_EDIT_REQUEST',
    COMPANY_EDIT_SUCCESS:'tenantmonitoring/COMPANY_EDIT_SUCCESS',
    COMPANY_DELETE_REQUEST:'tenantmonitoring/COMPANY_DELETE_REQUEST',
    COMPANY_DELETE_SUCCESS:'tenantmonitoring/COMPANY_DELETE_SUCCESS',
    COMPANY_LIST_REQUEST:'tenantmonitoring/COMPANY_LIST_REQUEST',
    COMPANY_LIST_SUCCESS:'tenantmonitoring/COMPANY_LIST_SUCCESS',

    PROPERTY_SAVE_REQUEST:'tenantmonitoring/PROPERTY_SAVE_REQUEST',
    PROPERTY_SAVE_SUCCESS:'tenantmonitoring/PROPERTY_SAVE_SUCCESS',
    PROPERTY_EDIT_REQUEST:'tenantmonitoring/PROPERTY_EDIT_REQUEST',
    PROPERTY_EDIT_SUCCESS:'tenantmonitoring/PROPERTY_EDIT_SUCCESS',
    PROPERTY_DELETE_REQUEST:'tenantmonitoring/PROPERTY_DELETE_REQUEST',
    PROPERTY_DELETE_SUCCESS:'tenantmonitoring/PROPERTY_DELETE_SUCCESS',
    PROPERTY_LIST_REQUEST:'tenantmonitoring/PROPERTY_LIST_REQUEST',
    PROPERTY_LIST_SUCCESS:'tenantmonitoring/PROPERTY_LIST_SUCCESS',

    TENANT_SAVE_REQUEST:'tenantmonitoring/TENANT_SAVE_REQUEST',
    TENANT_SAVE_SUCCESS:'tenantmonitoring/TENANT_SAVE_SUCCESS',
    TENANT_EDIT_REQUEST:'tenantmonitoring/TENANT_EDIT_REQUEST',
    TENANT_EDIT_SUCCESS:'tenantmonitoring/TENANT_EDIT_SUCCESS',
    TENANT_DELETE_REQUEST:'tenantmonitoring/TENANT_DELETE_REQUEST',
    TENANT_DELETE_SUCCESS:'tenantmonitoring/TENANT_DELETE_SUCCESS',
    TENANT_LIST_REQUEST:'tenantmonitoring/TENANT_LIST_REQUEST',
    TENANT_LIST_SUCCESS:'tenantmonitoring/TENANT_LIST_SUCCESS',

    UPLOAD_TENANT_REQUEST:'tenantmonitoring/UPLOAD_TENANT_REQUEST',
    UPLOAD_TENANT_SUCCESS:'tenantmonitoring/UPLOAD_TENANT_SUCCESS',

    USER_LIST_REQUEST:'tenantmonitoring/USER_LIST_REQUEST',
    USER_LIST_SUCCESS:'tenantmonitoring/USER_LIST_SUCCESS',
    USER_SAVE_REQUEST:'tenantmonitoring/USER_SAVE_REQUEST',
    USER_SAVE_SUCCESS:'tenantmonitoring/USER_SAVE_SUCCESS',

    USER_EDIT_REQUEST:'tenantmonitoring/USER_EDIT_REQUEST',
    USER_EDIT_SUCCESS:'tenantmonitoring/USER_EDIT_SUCCESS',

    USER_DELETE_REQUEST:'tenantmonitoring/USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS:'tenantmonitoring/USER_DELETE_SUCCESS',

    USER_ENABLE_REQUEST:'tenantmonitoring/USER_ENABLE_REQUEST',
    USER_ENABLE_SUCCESS:'tenantmonitoring/USER_ENABLE_SUCCESS',
    USER_DISABLE_REQUEST:'tenantmonitoring/USER_DISABLE_REQUEST',
    USER_DISABLE_SUCCESS:'tenantmonitoring/USER_DISABLE_SUCCESS',

    USER_NAME_ROLE_REQUEST:'tenantmonitoring/USER_NAME_ROLE_REQUEST',
    USER_NAME_ROLE_SUCCESS:'tenantmonitoring/USER_NAME_ROLE_SUCCESS',

    CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_REQUEST:'tenantmonitoring/CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_REQUEST',
    CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_SUCCESS:'tenantmonitoring/CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_SUCCESS',

    ACTIVE_TAXLIEN_JUDGMENT_DETAILS_REQUEST:'tenantmonitoring/ACTIVE_TAXLIEN_JUDGMENT_DETAILS_REQUEST',
    ACTIVE_TAXLIEN_JUDGMENT_DETAILS_SUCCESS:'tenantmonitoring/ACTIVE_TAXLIEN_JUDGMENT_DETAILS_SUCCESS',



    //Angel
    CONTIFY_COMPANY_SEARCH_REQUEST:'tenantmonitoring/CONTIFY_COMPANY_SEARCH_REQUEST',
    CONTIFY_COMPANY_SEARCH_SUCCESS:'tenantmonitoring/CONTIFY_COMPANY_SEARCH_SUCCESS',
    CREDITSAFE_COMPANY_LIST_REQUEST:'tenantmonitoring/CREDITSAFE_COMPANY_LIST_REQUEST',
    CREDITSAFE_COMPANY_LIST_SUCCESS:'tenantmonitoring/CREDITSAFE_COMPANY_LIST_SUCCESS',
    CORTERA_BUSINESS_SEARCH_REQUEST:'tenantmonitoring/CORTERA_BUSINESS_SEARCH_REQUEST',
    CORTERA_BUSINESS_SEARCH_SUCCESS:'tenantmonitoring/CORTERA_BUSINESS_SEARCH_SUCCESS',

    TLO_COMPANY_SEARCH_REQUEST:'tenantmonitoring/TLO_COMPANY_SEARCH_REQUEST',
    TLO_COMPANY_SEARCH_SUCCESS:'tenantmonitoring/TLO_COMPANY_SEARCH_SUCCESS',

    FUND_LIST_BY_ORG_REQUEST:'tenantmonitoring/FUND_LIST_BY_ORG_REQUEST',
    FUND_LIST_BY_ORG_SUCCESS:'tenantmonitoring/FUND_LIST_BY_ORG_SUCCESS',
    TENANT_LIST_BY_ORG_REQUEST:'tenantmonitoring/TENANT_LIST_BY_ORG_REQUEST',
    TENANT_LIST_BY_ORG_SUCCESS:'tenantmonitoring/TENANT_LIST_BY_ORG_SUCCESS',
    PROPERTY_LIST_BY_ORG_REQUEST:'tenantmonitoring/PROPERTY_LIST_BY_ORG_REQUEST',
    PROPERTY_LIST_BY_ORG_SUCCESS:'tenantmonitoring/PROPERTY_LIST_BY_ORG_SUCCESS',
    USER_LIST_BY_ORG_REQUEST:'tenantmonitoring/USER_LIST_BY_ORG_REQUEST',
    USER_LIST_BY_ORG_SUCCESS:'tenantmonitoring/USER_LIST_BY_ORG_SUCCESS',
    COMPANY_LIST_BY_ORG_REQUEST:'tenantmonitoring/COMPANY_LIST_BY_ORG_REQUEST',
    COMPANY_LIST_BY_ORG_SUCCESS:'tenantmonitoring/COMPANY_LIST_BY_ORG_SUCCESS',

    FUNDS_BY_ORG_COMPANY_ADMIN_REQUEST:'tenantmonitoring/FUNDS_BY_ORG_COMPANY_ADMIN_REQUEST',
    FUNDS_BY_ORG_COMPANY_ADMIN_SUCCESS:'tenantmonitoring/FUNDS_BY_ORG_COMPANY_ADMIN_SUCCESS',


//jain5/03/2021
  FUND_MANAGER_LIST_REQUEST:'tenantmonitoring/FUND_MANAGER_LIST_REQUEST',
  FUND_MANAGER_LIST_SUCCESS:'tenantmonitoring/FUND_MANAGER_LIST_SUCCESS',

  ASSET_MANAGER_LIST_REQUEST:'tenantmonitoring/ASSET_MANAGER_LIST_REQUEST',
  ASSET_MANAGER_LIST_SUCCESS:'tenantmonitoring/ASSET_MANAGER_LIST_SUCCESS',

  //SCS

  FUND_PROPERTY_DASHBOARD_REQUEST:'tenantmonitoring/FUND_PROPERTY_DASHBOARD_REQUEST',
  FUND_PROPERTY_DASHBOARD_SUCCESS:'tenantmonitoring/FUND_PROPERTY_DASHBOARD_SUCCESS',

  COMPANY_ADMIN_DASHBOARD_REQUEST:'tenantmonitoring/COMPANY_ADMIN_DASHBOARD_REQUEST',
  COMPANY_ADMIN_DASHBOARD_SUCCESS:'tenantmonitoring/ COMPANY_ADMIN_DASHBOARD_SUCCESS',

  TENANT_INITIAL_SCORE_REQUEST:'tenantmonitoring/TENANT_INITIAL_SCORE_REQUEST',
  TENANT_INITIAL_SCORE_SUCCESS:'tenantmonitoring/TENANT_INITIAL_SCORE_SUCCESS',

  FUND_BY_ID_REQUEST:'tenantmonitoring/FUND_BY_ID_REQUEST',
  FUND_BY_ID_SUCCESS:'tenantmonitoring/FUND_BY_ID_SUCCESS',

  COMPANY_BY_ID_REQUEST:'tenantmonitoring/COMPANY_BY_ID_REQUEST',
  COMPANY_BY_ID_SUCCESS:'tenantmonitoring/COMPANY_BY_ID_SUCCESS',

  TENANT_BY_ID_REQUEST:'tenantmonitoring/TENANT_BY_ID_REQUEST',
  TENANT_BY_ID_SUCCESS:'tenantmonitoring/TENANT_BY_ID_SUCCESS',  

  USER_BY_ID_REQUEST:'tenantmonitoring/USER_BY_ID_REQUEST',
  USER_BY_ID_SUCCESS:'tenantmonitoring/USER_BY_ID_SUCCESS',  

  PROPERTY_BY_ID_REQUEST:'tenantmonitoring/PROPERTY_BY_ID_REQUEST',
  PROPERTY_BY_ID_SUCCESS:'tenantmonitoring/PROPERTY_BY_ID_SUCCESS' ,

  PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_REQUEST:'tenantmonitoring/PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_REQUEST',
  PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_SUCCESS:'tenantmonitoring/PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_SUCCESS',

  CAUTIONARY_DETAILS_BY_TENANT_ID_REQUEST:'tenantmonitoring/CAUTIONARY_DETAILS_BY_TENANT_ID_REQUEST',
  CAUTIONARY_DETAILS_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ CAUTIONARY_DETAILS_BY_TENANT_ID_SUCCESS',

  ACTIVE_SUIT_DETAILS_BY_TENANT_ID_REQUEST:'tenantmonitoring/ACTIVE_SUIT_DETAILS_BY_TENANT_ID_REQUEST',
  ACTIVE_SUIT_DETAILS_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ ACTIVE_SUIT_DETAILS_BY_TENANT_ID_SUCCESS',


  ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_REQUEST:'tenantmonitoring/ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_REQUEST',
  ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_SUCCESS',


  ACTIVE_LIENS_DETAILS_BY_TENANT_ID_REQUEST:'tenantmonitoring/ACTIVE_LIENS_DETAILS_BY_TENANT_ID_REQUEST',
  ACTIVE_LIENS_DETAILS_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ ACTIVE_LIENS_DETAILS_BY_TENANT_ID_SUCCESS',

  ACTIVE_UCC_DETAILS_BY_TENANT_ID_REQUEST:'tenantmonitoring/ACTIVE_UCC_DETAILS_BY_TENANT_ID_REQUEST',
  ACTIVE_UCC_DETAILS_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ACTIVE_UCC_DETAILS_BY_TENANT_ID_SUCCESS',


  TENANTS_COUNT_BY_PROPERTY_RANGE_REQUEST:'tenantmonitoring/TENANTS_COUNT_BY_PROPERTY_RANGE_REQUEST',
  TENANTS_COUNT_BY_PROPERTY_RANGE_SUCCESS:'tenantmonitoring/TENANTS_COUNT_BY_PROPERTY_RANGE_SUCCESS',

  PROPERTY_COUNT_BY_ORG_REQUEST:'tenantmonitoring/PROPERTY_COUNT_BY_ORG_REQUEST',
  PROPERTY_COUNT_BY_ORG_SUCCESS:'tenantmonitoring/PROPERTY_COUNT_BY_ORG_SUCCESS',

  FUND_COUNT_BY_ORG_REQUEST:'tenantmonitoring/FUND_COUNT_BY_ORG_REQUEST',
  FUND_COUNT_BY_ORG_SUCCESS:'tenantmonitoring/FUND_COUNT_BY_ORG_SUCCESS',

  ALL_TENANTS_TRAILING_SCORE_REQUEST:'tenantmonitoring/ALL_TENANTS_TRAILING_SCORE_REQUEST',
  ALL_TENANTS_TRAILING_SCORE_SUCCESS:'tenantmonitoring/ALL_TENANTS_TRAILING_SCORE_SUCCESS',

  ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_REQUEST:'tenantmonitoring/ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_REQUEST',
ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_SUCCESS:'tenantmonitoring/ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_SUCCESS',



MONITORED_TENANTS_BY_FUND_REQUEST:'tenantmonitoring/MONITORED_TENANTS_BY_FUND_REQUEST',
MONITORED_TENANTS_BY_FUND_SUCCESS:'tenantmonitoring/MONITORED_TENANTS_BY_FUND_SUCCESS',


MONITORED_TENANTS_BY_PROPERTY_REQUEST:'tenantmonitoring/MONITORED_TENANTS_BY_PROPERTY_REQUEST',
MONITORED_TENANTS_BY_PROPERTY_SUCCESS:'tenantmonitoring/MONITORED_TENANTS_BY_PROPERTY_SUCCESS',


MONITORED_TENANTS_BY_COMPANY_ADMIN_REQUEST:'tenantmonitoring/MONITORED_TENANTS_BY_COMPANY_ADMIN_REQUEST',
MONITORED_TENANTS_BY_COMPANY_ADMIN_SUCCESS:'tenantmonitoring/MONITORED_TENANTS_BY_COMPANY_ADMIN_SUCCESS',


SAVE_NOTIFICATION_SETTINGS_REQUEST:'tenantmonitoring/SAVE_NOTIFICATION_SETTINGS_REQUEST',
SAVE_NOTIFICATION_SETTINGS_SUCCESS:'tenantmonitoring/SAVE_NOTIFICATION_SETTINGS_SUCCESS',

EDIT_NOTIFICATION_SETTINGS_REQUEST:'tenantmonitoring/EDIT_NOTIFICATION_SETTINGS_REQUEST',
EDIT_NOTIFICATION_SETTINGS_SUCCESS:'tenantmonitoring/EDIT_NOTIFICATION_SETTINGS_SUCCESS',

NOTIFICATION_SETTINGS_BY_ID_REQUEST:'tenantmonitoring/NOTIFICATION_SETTINGS_BY_ID_REQUEST',
NOTIFICATION_SETTINGS_BY_ID_SUCCESS:'tenantmonitoring/NOTIFICATION_SETTINGS_BY_ID_SUCCESS',

MONITORED_TENANTS_NEWS_REQUEST:'tenantmonitoring/MONITORED_TENANTS_NEWS_REQUEST',
MONITORED_TENANTS_NEWS_SUCCESS:'tenantmonitoring/MONITORED_TENANTS_NEWS__SUCCESS',


POPULATE_INITIAL_DATA_FOR_TENANT_REQUEST:'tenantmonitoring/POPULATE_INITIAL_DATA_FOR_TENANT_REQUEST',
POPULATE_INITIAL_DATA_FOR_TENANT_SUCCESS:'tenantmonitoring/POPULATE_INITIAL_DATA_FOR_TENANT_SUCCESS',

TENANT_UPLOAD_STATUS_BY_ORG_ID_REQUEST:'tenantmonitoring/TENANT_UPLOAD_STATUS_BY_ORG_ID_REQUEST',
TENANT_UPLOAD_STATUS_BY_ORG_ID_SUCCESS:'tenantmonitoring/TENANT_UPLOAD_STATUS_BY_ORG_ID_SUCCESS',



TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_REQUEST:'tenantmonitoring/TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_REQUEST',
TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_SUCCESS:'tenantmonitoring/TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_SUCCESS',

ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_REQUEST:'tenantmonitoring/ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_REQUEST',
ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_SUCCESS:'tenantmonitoring/ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_SUCCESS',

ORGANIZATION_SCORE_EXECUTION_LOG_LIST_REQUEST:'tenantmonitoring/ORGANIZATION_SCORE_EXECUTION_LOG_LIST_REQUEST',
ORGANIZATION_SCORE_EXECUTION_LOG_LIST_SUCCESS:'tenantmonitoring/ORGANIZATION_SCORE_EXECUTION_LOG_LIST_SUCCESS',

SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_REQUEST:'tenantmonitoring/SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_REQUEST',
SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_SUCCESS:'tenantmonitoring/SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_SUCCESS',

DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_REQUEST:'tenantmonitoring/DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_REQUEST',
DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_SUCCESS:'tenantmonitoring/DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_SUCCESS',

ORG_INITIAL_DATA_STATUS_REQUEST:'tenantmonitoring/ORG_INITIAL_DATA_STATUS_REQUEST',
ORG_INITIAL_DATA_STATUS_SUCCESS:'tenantmonitoring/ORG_INITIAL_DATA_STATUS_SUCCESS',


POPULATE_INITIAL_DATA_BY_ORG_REQUEST:'tenantmonitoring/POPULATE_INITIAL_DATA_BY_ORG_REQUEST',
POPULATE_INITIAL_DATA_BY_ORG_SUCCESS:'tenantmonitoring/POPULATE_INITIAL_DATA_BY_ORG_SUCCESS',

GET_BANKO_REPORT_BY_TENANT_REQUEST:'tenantmonitoring/GET_BANKO_REPORT_BY_TENANT_REQUEST',
GET_BANKO_REPORT_BY_TENANT_SUCCESS:'tenantmonitoring/GET_BANKO_REPORT_BY_TENANT_SUCCESS',

FUNDS_WITH_USER_REQUEST:'tenantmonitoring/FUNDS_WITH_USER_REQUEST',
FUNDS_WITH_USER_SUCCESS:'tenantmonitoring/FUNDS_WITH_USER_SUCCESS',

PROPERTY_WITH_USER_REQUEST: 'tenantmonitoring/PROPERTY_WITH_USER_REQUEST',
PROPERTY_WITH_USER_SUCCESS:'tenantmonitoring/PROPERTY_WITH_USER_SUCCESS',

GET_USER_BY_LOGIN_AND_EMAIL_REQUEST:'tenantmonitoring/GET_USER_BY_LOGIN_AND_EMAIL_REQUEST',
GET_USER_BY_LOGIN_AND_EMAIL_SUCCESS:'tenantmonitoring/GET_USER_BY_LOGIN_AND_EMAIL_SUCCESS',

CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_REQUEST:'tenantmonitoring/CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_REQUEST',
CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_SUCCESS:'tenantmonitoring/CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_SUCCESS',


PROPERTY_COUNT_BY_ORG_AND_USER_REQUEST:'tenantmonitoring/PROPERTY_COUNT_BY_ORG_AND_USER_REQUEST',
PROPERTY_COUNT_BY_ORG_AND_USER_SUCCESS:'tenantmonitoring/PROPERTY_COUNT_BY_ORG_AND_USER_SUCCESS',


UPLOAD_PIRS_LOG_LIST_REQUEST:'tenantmonitoring/UPLOAD_PIRS_LOG_LIST_REQUEST',
UPLOAD_PIRS_LOG_LIST_SUCCESS:'tenantmonitoring/UPLOAD_PIRS_LOG_LIST_SUCCESS',

UPLOAD_PIRS_CSV_REQUEST:'tenantmonitoring/UPLOAD_PIRS_CSV_REQUEST',
UPLOAD_PIRS_CSV_SUCCESS:'tenantmonitoring/UPLOAD_PIRS_CSV_SUCCESS',

PIRS_LIST_REQUEST:'tenantmonitoring/PIRS_LIST_REQUEST',
PIRS_LIST_SUCCESS:'tenantmonitoring/PIRS_LIST_SUCCESS',

GENERATE_DASHBOARD_REQUEST:'tenantmonitoring/GENERATE_DASHBOARD_REQUEST',
GENERATE_DASHBOARD_SUCCESS:'tenantmonitoring/GENERATE_DASHBOARD_SUCCESS',

DASHBOARD_USER_LIST_REQUEST:'tenantmonitoring/DASHBOARD_USER_LIST_REQUEST',
DASHBOARD_USER_LIST_SUCCESS:'tenantmonitoring/DASHBOARD_USER_LIST_SUCCESS',

GENERATE_DASHBOARD_BY_USER_REQUEST:'tenantmonitoring/GENERATE_DASHBOARD_BY_USER_REQUEST',
GENERATE_DASHBOARD_BY_USER_SUCCESS:'tenantmonitoring/GENERATE_DASHBOARD_BY_USER_SUCCESS',


TENANT_LIST_BY_RISK_REQUEST:'tenantmonitoring/TENANT_LIST_BY_RISK_REQUEST',
TENANT_LIST_BY_RISK_SUCCESS:'tenantmonitoring/TENANT_LIST_BY_RISK_SUCCESS',

TENANT_SCORE_LIST_BY_RISK_REQUEST:'tenantmonitoring/TENANT_SCORE_LIST_BY_RISK_REQUEST',
TENANT_SCORE_LIST_BY_RISK_SUCCESS:'tenantmonitoring/TENANT_SCORE_LIST_BY_RISK_SUCCESS',


FUND_DASHBOARD_GRID_VIEW_REQUEST:'tenantmonitoring/FUND_DASHBOARD_GRID_VIEW_REQUEST',
FUND_DASHBOARD_GRID_VIEW_SUCCESS:'tenantmonitoring/FUND_DASHBOARD_GRID_VIEW_SUCCESS',

COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_REQUEST:'tenantmonitoring/COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_REQUEST',
COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_SUCCESS:'tenantmonitoring/COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_SUCCESS',

GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST:'tenantmonitoring/GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST',
GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS:'tenantmonitoring/GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS',

GET_COMPANY_ADMIN_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST:'tenantmonitoring/GET_COMPANY_ADMIN_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST',
GET_COMPANY_ADMIN_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS:'tenantmonitoring/GET_COMPANY_ADMIN_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS',

PROPERTY_DASHBOARD_GRID_VIEW_REQUEST:'tenantmonitoring/PROPERTY_DASHBOARD_GRID_VIEW_REQUEST',
PROPERTY_DASHBOARD_GRID_VIEW_SUCCESS:'tenantmonitoring/PROPERTY_DASHBOARD_GRID_VIEW_SUCCESS',

PROPERTY_IMAGE_UPLOAD_REQUEST:'tenantmonitoring/PROPERTY_IMAGE_UPLOAD_REQUEST',
PROPERTY_IMAGE_UPLOAD_SUCCESS:'tenantmonitoring/PROPERTY_IMAGE_UPLOAD_SUCCESS',

FUND_IMAGE_UPLOAD_REQUEST:'tenantmonitoring/FUND_IMAGE_UPLOAD_REQUEST',
FUND_IMAGE_UPLOAD_SUCCESS:'tenantmonitoring/FUND_IMAGE_UPLOAD_SUCCESS',



FUND_APEX_CHART_DATA_REQUEST:'tenantmonitoring/FUND_APEX_CHART_DATA_REQUEST',
FUND_APEX_CHART_DATA_SUCCESS:'tenantmonitoring/FUND_APEX_CHART_DATA_SUCCESS',


FUND_PROPERTY_APEX_CHART_DATA_REQUEST:'tenantmonitoring/FUND_PROPERTY_APEX_CHART_DATA_REQUEST',
FUND_PROPERTY_APEX_CHART_DATA_SUCCESS:'tenantmonitoring/FUND_PROPERTY_APEX_CHART_DATA_SUCCESS',


PROPERTY_APEX_CHART_DATA_REQUEST:'tenantmonitoring/PROPERTY_APEX_CHART_DATA_REQUEST',
PROPERTY_APEX_CHART_DATA_SUCCESS:'tenantmonitoring/PROPERTY_APEX_CHART_DATA_SUCCESS',

GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_REQUEST:'tenantmonitoring/GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_REQUEST',
GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_SUCCESS:'tenantmonitoring/GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_SUCCESS',

GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_REQUEST:'tenantmonitoring/GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_REQUEST',
GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_SUCCESS:'tenantmonitoring/GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_SUCCESS',

GET_TENANT_BAR_GRAPH_DATA_REQUEST:'tenantmonitoring/GET_TENANT_BAR_GRAPH_DATA_REQUEST',
GET_TENANT_BAR_GRAPH_DATA_SUCCESS:'tenantmonitoring/GET_TENANT_BAR_GRAPH_DATA_SUCCESS',

GET_PROPERTY_TENANT_LINE_CHART_REQUEST:'tenantmonitoring/GET_PROPERTY_TENANT_LINE_CHART_REQUEST',
GET_PROPERTY_TENANT_LINE_CHART_SUCCESS:'tenantmonitoring/GET_PROPERTY_TENANT_LINE_CHART_SUCCESS',

GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_REQUEST:'tenantmonitoring/GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_REQUEST',
GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_SUCCESS:'tenantmonitoring/GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_SUCCESS',


GET_PROPERTY_TENANT_SEARCH_REQUEST:'tenantmonitoring/GET_PROPERTY_TENANT_SEARCH_REQUEST',
GET_PROPERTY_TENANT_SEARCH_SUCCESS:'tenantmonitoring/GET_PROPERTY_TENANT_SEARCH_SUCCESS',

GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_REQUEST:'tenantmonitoring/GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_REQUEST',
GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_SUCCESS:'tenantmonitoring/GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_SUCCESS',

GET_TENANT_LIST_BASED_ON_LOW_RISK_REQUEST:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_LOW_RISK_REQUEST',
GET_TENANT_LIST_BASED_ON_LOW_RISK_SUCCESS:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_LOW_RISK_SUCCESS',

GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_REQUEST:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_REQUEST',
GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_SUCCESS:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_SUCCESS',

GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_REQUEST:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_REQUEST',
GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_SUCCESS:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_SUCCESS',

GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_REQUEST:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_REQUEST',
GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_SUCCESS:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_SUCCESS',

GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST',
GET_TENANT_LIST_BASED_ON_HIGH_RISK_SUCCESS:'tenantmonitoring/GET_TENANT_LIST_BASED_ON_HIGH_RISK_SUCCESS',

// GET_CREDIT_SCORE_COMPANY_LIST_REQUEST:'tenantmonitoring/GET_CREDIT_SCORE_COMPANY_LIST_REQUEST',
// GET_CREDIT_SCORE_COMPANY_LIST_SUCCESS:'tenantmonitoring/GET_CREDIT_SCORE_COMPANY_LIST_SUCCESS',


GET_BANKRUPTCY_DETAILS_OF_USER_REQUEST:'tenantmonitoring/GET_BANKRUPTCY_DETAILS_OF_USER_REQUEST',
GET_BANKRUPTCY_DETAILS_OF_USER_SUCCESS:'tenantmonitoring/GET_BANKRUPTCY_DETAILS_OF_USER_SUCCESS',

COMPANY_SEARCH_REQUEST:'tenantmonitoring/COMPANY_SEARCH_REQUEST',
COMPANY_SEARCH_SUCCESS:'tenantmonitoring/COMPANY_SEARCH_SUCCESS',


COMPANY_SEARCH_LIST_SAVE_REQUEST:'tenantmonitoring/COMPANY_SEARCH_LIST_SAVE_REQUEST',
COMPANY_SEARCH_LIST_SAVE_SUCCESS:'tenantmonitoring/COMPANY_SEARCH_LIST_SAVE_SUCCESS',

PROPERTY_SEARCH_REQUEST:'tenantmonitoring/PROPERTY_SEARCH_REQUEST',
PROPERTY_SEARCH_SUCCESS:'tenantmonitoring/PROPERTY_SEARCH_SUCCESS',

PROPERTY_SEARCH_LIST_SAVE_REQUEST:'tenantmonitoring/PROPERTY_SEARCH_LIST_SAVE_REQUEST',
PROPERTY_SEARCH_LIST_SAVE_SUCCESS:'tenantmonitoring/PROPERTY_SEARCH_LIST_SAVE_SUCCESS',

SEND_EMAIL_REQUEST:'tenantmonitoring/SEND_EMAIL_REQUEST',
SEND_EMAIL_SUCCESS:'tenantmonitoring/SEND_EMAIL_SUCCESS',

TOTAL_TENANT_COUNT_IN_RISK_COUNT_REQUEST:'tenantmonitoring/TOTAL_TENANT_COUNT_IN_RISK_COUNT_REQUEST',
TOTAL_TENANT_COUNT_IN_RISK_COUNT_SUCCESS:'tenantmonitoring/TOTAL_TENANT_COUNT_IN_RISK_COUNT_SUCCESS',

GENERATE_FUND_INITIAL_REPORT_REQUEST:'tenantmonitoring/GENERATE_FUND_INITIAL_REPORT_REQUEST',
GENERATE_FUND_INITIAL_REPORT_SUCCESS:'tenantmonitoring/GENERATE_FUND_INITIAL_REPORT_SUCCESS',

GENERATE_PROPERTY_INITIAL_REPORT_REQUEST:'tenantmonitoring/GENERATE_PROPERTY_INITIAL_REPORT_REQUEST',
GENERATE_PROPERTY_INITIAL_REPORT_SUCCESS:'tenantmonitoring/GENERATE_PROPERTY_INITIAL_REPORT_SUCCESS',

GENERATE_TENANT_INITIAL_REPORT_REQUEST:'tenantmonitoring/GENERATE_TENANT_INITIAL_REPORT_REQUEST',
GENERATE_TENANT_INITIAL_REPORT_SUCCESS:'tenantmonitoring/GENERATE_TENANT_INITIAL_REPORT_SUCCESS',


GENERATE_SCORE_FOR_FUND_REQUEST:'tenantmonitoring/GENERATE_SCORE_FOR_FUND_REQUEST',
GENERATE_SCORE_FOR_FUND_SUCCESS:'tenantmonitoring/GENERATE_SCORE_FOR_FUND_SUCCESS',


GET_COMMENTARY_FROM_COMPANY_REPORT_REQUEST:'tenantmonitoring/GET_COMMENTARY_FROM_COMPANY_REPORT_REQUEST',
GET_COMMENTARY_FROM_COMPANY_REPORT_SUCCESS:'tenantmonitoring/GET_COMMENTARY_FROM_COMPANY_REPORT_SUCCESS',

CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_REQUEST:'tenantmonitoring/CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_REQUEST',
CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_SUCCESS:'tenantmonitoring/CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_SUCCESS',

CURRENT_MONTH_FUND_SCORE_BY_FUNDID_REQUEST:'tenantmonitoring/CURRENT_MONTH_FUND_SCORE_BY_FUNDID_REQUEST',
CURRENT_MONTH_FUND_SCORE_BY_FUNDID_SUCCESS:'tenantmonitoring/CURRENT_MONTH_FUND_SCORE_BY_FUNDID_SUCCESS',

PROPERTY_CITY_AND_STATE_BY_PROPERTYID_REQUEST:'tenantmonitoring/PROPERTY_CITY_AND_STATE_BY_PROPERTYID_REQUEST',
PROPERTY_CITY_AND_STATE_BY_PROPERTYID_SUCCESS:'tenantmonitoring/PROPERTY_CITY_AND_STATE_BY_PROPERTYID_SUCCESS',

NOTIFICATION_LOG_LIST_REQUEST:'tenantmonitoring/NOTIFICATION_LOG_LIST_REQUEST',
NOTIFICATION_LOG_LIST_SUCCESS:'tenantmonitoring/NOTIFICATION_LOG_LIST_SUCCESS',

TENANT_PROPERTY_DETAILS_REQUEST:'tenantmonitoring/TENANT_PROPERTY_DETAILS_REQUEST',
TENANT_PROPERTY_DETAILS_SUCCESS:'tenantmonitoring/TENANT_PROPERTY_DETAILS_SUCCESS',

CONTIFY_TOPICS_REQUEST:'tenantmonitoring/CONTIFY_TOPICS_REQUEST',
CONTIFY_TOPICS_SUCCESS:'tenantmonitoring/CONTIFY_TOPICS_SUCCESS',

GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST:'tenantmonitoring/GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST',
GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS:'tenantmonitoring/GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS',

PARENT_SAVE_REQUEST:'tenantmonitoring/PARENT_SAVE_REQUEST',
PARENT_SAVE_SUCCESS:'tenantmonitoring/PARENT_SAVE_SUCCESS',

PARENT_LIST_BY_ORG_REQUEST:'tenantmonitoring/PARENT_LIST_BY_ORG_REQUEST',
PARENT_LIST_BY_ORG_SUCCESS:'tenantmonitoring/PARENT_LIST_BY_ORG_SUCCESS',

PARENT_BY_ID_REQUEST:'tenantmonitoring/PARENT_BY_ID_REQUEST',
PARENT_BY_ID_SUCCESS:'tenantmonitoring/PARENT_BY_ID_SUCCESS', 

PARENT_EDIT_REQUEST:'tenantmonitoring/PARENT_EDIT_REQUEST',
PARENT_EDIT_SUCCESS:'tenantmonitoring/PARENT_EDIT_SUCCESS',

PARENT_DELETE_REQUEST:'tenantmonitoring/PARENT_DELETE_REQUEST',
PARENT_DELETE_SUCCESS:'tenantmonitoring/PARENT_DELETE_SUCCESS',

PARENT_BY_ORG_REQUEST: 'tenantmonitoring/PARENT_BY_ORG_REQUEST',
PARENT_BY_ORG_SUCCESS: 'tenantmonitoring/PARENT_BY_ORG_SUCCESS',

UNLINK_PARENT_REQUEST:'tenantmonitoring/UNLINK_PARENT_REQUEST',
UNLINK_PARENT_SUCCESS:'tenantmonitoring/UNLINK_PARENT_SUCCESS',

GENERATE_PARENT_INITIAL_REPORT_REQUEST:'tenantmonitoring/GENERATE_PARENT_INITIAL_REPORT_REQUEST',
GENERATE_PARENT_INITIAL_REPORT_SUCCESS:'tenantmonitoring/GENERATE_PARENT_INITIAL_REPORT_SUCCESS',

PARENT_CREDIT_SCORE_REQUEST: 'tenantmonitoring/PARENT_CREDIT_SCORE_REQUEST',
PARENT_CREDIT_SCORE_SUCCESS: 'tenantmonitoring/PARENT_CREDIT_SCORE_SUCCESS',

PARENT_DEROGATORY_SCORE_COUNT_REQUEST:'tenantmonitoring/PARENT_DEROGATORY_SCORE_COUNT_REQUEST',
PARENT_DEROGATORY_SCORE_COUNT_SUCCESS:'tenantmonitoring/PARENT_DEROGATORY_SCORE_COUNT_SUCCESS',

PARENT_MONTH_PIRS_REQUEST:'tenantmonitoring/PARENT_MONTH_PIRS_REQUEST',
PARENT_MONTH_PIRS_SUCCESS:'tenantmonitoring/PARENT_MONTH_PIRS_SUCCESS',

MONITORED_PARENTS_NEWS_REQUEST:'tenantmonitoring/MONITORED_PARENTS_NEWS_REQUEST',
MONITORED_PARENTS_NEWS_SUCCESS:'tenantmonitoring/MONITORED_PARENTS_NEWS_SUCCESS',

CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_REQUEST:'tenantmonitoring/CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_REQUEST',
CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_SUCCESS:'tenantmonitoring/CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_SUCCESS',

GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST:'tenantmonitoring/GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST',
GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS:'tenantmonitoring/GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS',

ACTIVE_UCC_DETAILS_BY_PARENT_ID_REQUEST:'tenantmonitoring/ACTIVE_UCC_DETAILS_BY_PARENT_ID_REQUEST',
ACTIVE_UCC_DETAILS_BY_PARENT_ID_SUCCESS:'tenantmonitoring/ACTIVE_UCC_DETAILS_BY_PARENT_ID_SUCCESS',

ACTIVE_LIENS_DETAILS_BY_PARENT_ID_REQUEST:'tenantmonitoring/ACTIVE_LIENS_DETAILS_BY_PARENT_ID_REQUEST',
ACTIVE_LIENS_DETAILS_BY_PARENT_ID_SUCCESS:'tenantmonitoring/ACTIVE_LIENS_DETAILS_BY_PARENT_ID_SUCCESS',

ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_REQUEST:'tenantmonitoring/ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_REQUEST',
ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_SUCCESS:'tenantmonitoring/ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_SUCCESS',

ACTIVE_SUIT_DETAILS_BY_PARENT_ID_REQUEST:'tenantmonitoring/ACTIVE_SUIT_DETAILS_BY_PARENT_ID_REQUEST',
ACTIVE_SUIT_DETAILS_BY_PARENT_ID_SUCCESS:'tenantmonitoring/ACTIVE_SUIT_DETAILS_BY_PARENT_ID_SUCCESS',

NOTIFICATION_LOG_SEARCH_LIST_REQUEST:'tenantmonitoring/NOTIFICATION_LOG_SEARCH_LIST_REQUEST',
NOTIFICATION_LOG_SEARCH_LIST_SUCCESS:'tenantmonitoring/NOTIFICATION_LOG_SEARCH_LIST_SUCCESS',

NOTIFICATION_LOG_SEARCH_LIST_REQUEST:'tenantmonitoring/NOTIFICATION_LOG_SEARCH_LIST_REQUEST',
NOTIFICATION_LOG_SEARCH_LIST_SUCCESS:'tenantmonitoring/NOTIFICATION_LOG_SEARCH_LIST_SUCCESS',

NEWS_FTL_REQUEST:'tenantmonitoring/NEWS_FTL_REQUEST',
NEWS_FTL_SUCCESS:'tenantmonitoring/NEWS_FTL_SUCCESS',

DEROGATORY_FTL_REQUEST:'tenantmonitoring/DEROGATORY_FTL_REQUEST',
DEROGATORY_FTL_SUCCESS:'tenantmonitoring/DEROGATORY_FTL_SUCCESS',

CREDIT_FTL_REQUEST:'tenantmonitoring/CREDIT_FTL_REQUEST',
CREDIT_FTL_SUCCESS:'tenantmonitoring/CREDIT_FTL_SUCCESS',

TENANT_SCORE_FTL_REQUEST:'tenantmonitoring/TENANT_SCORE_FTL_REQUEST',
TENANT_SCORE_FTL_SUCCESS:'tenantmonitoring/TENANT_SCORE_FTL_SUCCESS',

SUITS_FTL_REQUEST:'tenantmonitoring/SUITS_FTL_REQUEST',
SUITS_FTL_SUCCESS:'tenantmonitoring/SUITS_FTL_SUCCESS',

PIRS_FTL_REQUEST:'tenantmonitoring/PIRS_FTL_REQUEST',
PIRS_FTL_SUCCESS:'tenantmonitoring/PIRS_FTL_SUCCESS',

ACTIVE_STATUS_FTL_REQUEST:'tenantmonitoring/ACTIVE_STATUS_FTL_REQUEST',
ACTIVE_STATUS_FTL_SUCCESS:'tenantmonitoring/ACTIVE_STATUS_FTL_SUCCESS',

PROPERTY_OVERALL_SCORE_FTL_REQUEST:'tenantmonitoring/PROPERTY_OVERALL_SCORE_FTL_REQUEST',
PROPERTY_OVERALL_SCORE_FTL_SUCCESS:'tenantmonitoring/PROPERTY_OVERALL_SCORE_FTL_SUCCESS',

FUND_OVERALL_SCORE_FTL_REQUEST:'tenantmonitoring/FUND_OVERALL_SCORE_FTL_REQUEST',
FUND_OVERALL_SCORE_FTL_SUCCESS:'tenantmonitoring/FUND_OVERALL_SCORE_FTL_SUCCESS',

CAUTIONARY_UCC_MONTHLY_FTL_REQUEST:'tenantmonitoring/CAUTIONARY_UCC_MONTHLY_FTL_REQUEST',
CAUTIONARY_UCC_MONTHLY_FTL_SUCCESS:'tenantmonitoring/CAUTIONARY_UCC_MONTHLY_FTL_SUCCESS',

CAUTIONARY_UCC_INITIAL_POPULATE_FTL_REQUEST:'tenantmonitoring/CAUTIONARY_UCC_INITIAL_POPULATE_FTL_REQUEST',
CAUTIONARY_UCC_INITIAL_POPULATE_FTL_SUCCESS:'tenantmonitoring/CAUTIONARY_UCC_INITIAL_POPULATE_FTL_SUCCESS',

LIENS_MONTHLY_FTL_REQUEST:'tenantmonitoring/LIENS_MONTHLY_FTL_REQUEST',
LIENS_MONTHLY_FTL_SUCCESS:'tenantmonitoring/LIENS_MONTHLY_FTL_SUCCESS',

LIENS_INITIAL_POPULATE_FTL_REQUEST:'tenantmonitoring/LIENS_INITIAL_POPULATE_FTL_REQUEST',
LIENS_INITIAL_POPULATE_FTL_SUCCESS:'tenantmonitoring/LIENS_INITIAL_POPULATE_FTL_SUCCESS',

JUDGEMENT_MONTHLY_FTL_REQUEST:'tenantmonitoring/JUDGEMENT_MONTHLY_FTL_REQUEST',
JUDGEMENT_MONTHLY_FTL_SUCCESS:'tenantmonitoring/JUDGEMENT_MONTHLY_FTL_SUCCESS',

JUDGEMENT_INITIAL_POPULATE_FTL_REQUEST:'tenantmonitoring/JUDGEMENT_INITIAL_POPULATE_FTL_REQUEST',
JUDGEMENT_INITIAL_POPULATE_FTL_SUCCESS:'tenantmonitoring/JUDGEMENT_INITIAL_POPULATE_FTL_SUCCESS',

TENANT_COMMENTARY_SAVE_REQUEST:'tenantmonitoring/TENANT_COMMENTARY_SAVE_REQUEST',
TENANT_COMMENTARY_SAVE_SUCCESS:'tenantmonitoring/TENANT_COMMENTARY_SAVE_SUCCESS',

COMMENTARY_LIST_REQUEST:'tenantmonitoring/COMMENTARY_LIST_REQUEST',
COMMENTARY_LIST_SUCCESS:'tenantmonitoring/COMMENTARY_LIST_SUCCESS',

TENANT_REPORTS_DETAILS_REQUEST:'tenantmonitoring/TENANT_REPORTS_DETAILS_REQUEST',
TENANT_REPORTS_DETAILS_SUCCESS:'tenantmonitoring/TENANT_REPORTS_DETAILS_SUCCESS',

GENERATE_TENANT_REPORT_REQUEST:'tenantmonitoring/GENERATE_TENANT_REPORT_REQUEST',
GENERATE_TENANT_REPORT_SUCCESS:'tenantmonitoring/GENERATE_TENANT_REPORT_SUCCESS',

GENERATE_MONTHLY_PDF_REPORT_REQUEST:'tenantmonitoring/GENERATE_MONTHLY_PDF_REPORT_REQUEST',
GENERATE_MONTHLY_PDF_REPORT_SUCCESS:'tenantmonitoring/GENERATE_MONTHLY_PDF_REPORT_SUCCESS',

GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_REQUEST:'tenantmonitoring/GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_REQUEST',
GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_SUCCESS:'tenantmonitoring/GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_SUCCESS',

RISK_LIST_GRAPH_MONTHLY_REPORT_REQUEST:'tenantmonitoring/RISK_LIST_GRAPH_MONTHLY_REPORT_REQUEST',
RISK_LIST_GRAPH_MONTHLY_REPORT_SUCCESS:'tenantmonitoring/RISK_LIST_GRAPH_MONTHLY_REPORT_SUCCESS',

GRAPH_MONTHLY_SAVE_REQUEST:'tenantmonitoring/GRAPH_MONTHLY_SAVE_REQUEST',
GRAPH_MONTHLY_SAVE_SUCCESS:'tenantmonitoring/GRAPH_MONTHLY_SAVE_SUCCESS',

GENERATE_MONTHLY_FUND_REPORT_REQUEST:'tenantmonitoring/GENERATE_MONTHLY_FUND_REPORT_REQUEST',
GENERATE_MONTHLY_FUND_REPORT_SUCCESS:'tenantmonitoring/GENERATE_MONTHLY_FUND_REPORT_SUCCESS',

CLEAN_UP_SCORE_REQUEST:'tenantmonitoring/CLEAN_UP_SCORE_REQUEST',
CLEAN_UP_SCORE_SUCCESS:'tenantmonitoring/CLEAN_UP_SCORE_SUCCESS',

CLEAN_UP_NEWS_REQUEST:'tenantmonitoring/CLEAN_UP_NEWS_REQUEST',
CLEAN_UP_NEWS_SUCCESS:'tenantmonitoring/CLEAN_UP_NEWS_SUCCESS',

TENANT_NEWS_SETTINGS_REQUEST:'tenantmonitoring/TENANT_NEWS_SETTINGS_REQUEST',
TENANT_NEWS_SETTINGS_SUCCESS:'tenantmonitoring/TENANT_NEWS_SETTINGS_SUCCESS',


EDIT_TENANT_NEWS_SETTINGS_REQUEST:'tenantmonitoring/EDIT_TENANT_NEWS_SETTINGS_REQUEST',
EDIT_TENANT_NEWS_SETTINGS_SUCCESS:'tenantmonitoring/EDIT_TENANT_NEWS_SETTINGS_SUCCESS',

TENANT_NEWS_SETTINGS_BY_ID_REQUEST:'tenantmonitoring/TENANT_NEWS_SETTINGS_BY_ID_REQUEST',
TENANT_NEWS_SETTINGS_BY_ID_SUCCESS:'tenantmonitoring/TENANT_NEWS_SETTINGS_BY_ID_SUCCESS',

PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_REQUEST:'tenantmonitoring/PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_REQUEST',
PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_SUCCESS:'tenantmonitoring/PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_SUCCESS',

};

 

 

 
 
  /**
   * Action Creators
   */
  export const Creators = {

    tmLexisNexisNotificationRequest: () => ({
     
      type: Types.LIST_LEXIS_NEXIS_NOTIFICATION_REQUEST
     
    }),
 
    tmLexisNexisNotificationSuccess: data => ({
      type: Types.LIST_LEXIS_NEXIS_NOTIFICATION_SUCCESS,
      payload: { data },
    }),

    tmNotificationRequest: (contifyId, tenantId, naics,start, end) => ({
      type: Types.LIST_NOTIFICATION_REQUEST,
      payload: {contifyId, tenantId, naics, start, end },
     
    }),
    tmNotificationSuccess: data => ({
      type: Types.LIST_NOTIFICATION_SUCCESS,
      payload: { data },
    }),

    tmDashboardRequest: (organizationId,userId,pageSize,pageNum) => ({
      type: Types.DASHBOARD_REQUEST,
      payload: {organizationId,userId,pageSize,pageNum},
     
    }),
    tmDashboardSuccess: data => ({
      type: Types.DASHBOARD_SUCCESS,
      payload: { data },
    }),


   
    tmScoreCommentaryRequest: (score,scoreType) => ({
      type: Types.SCORE_COMMENTARY_REQUEST,
      payload: {score,scoreType},
     
    }),

    tmScoreCommentarySuccess: data => ({
      type: Types.SCORE_COMMENTARY_SUCCESS,
      payload: { data },
    }),




    tmPirsRequest: (naicsCode) => ({
      type: Types.PIRS_REQUEST,
      payload: {naicsCode},
     
    }),
    tmPirsSuccess: data => ({
      type: Types.PIRS_SUCCESS,
      payload: { data },
    }),


    tmBankoNotificationRequest: (accountNumber, tenantId,start, end) => ({
      type: Types.LIST_BANKO_NOTIFICATION_REQUEST,
    //  payload: {accountNumber, tenantId, start, end },
      payload: {accountNumber, tenantId, start, end },
     
    }),
    tmBankoNotificationSuccess: data => ({
      type: Types.LIST_BANKO_NOTIFICATION_SUCCESS,
      payload: { data },
    }),

    tmRequest: (start,end) => ({
      type: Types.LIST_REQUEST,
      payload: { start, end },
     
    }),
 
    tmSuccess: data => ({
      type: Types.LIST_SUCCESS,
      payload: { data },
    }),
    tmTenantMonitoringListByFundRequest: (userId) => ({
      type: Types.TENANT_MONITORING_LIST_BY_FUND_REQUEST,
      payload: {userId},
     
    }),
 
    tmTenantMonitoringListByFundSuccess: data => ({
      type: Types.TENANT_MONITORING_LIST_BY_FUND_SUCCESS,
      payload: { data },
    }),

    tmTenantMonitoringListByPropertyRequest: (userId) => ({
      type: Types.TENANT_MONITORING_LIST_BY_PROPERTY_REQUEST,
      payload: {userId },
     
    }),
 
    tmTenantMonitoringListByPropertySuccess: data => ({
      type: Types.TENANT_MONITORING_LIST_BY_PROPERTY_SUCCESS,
      payload: { data },
    }),

    tmCreditScoreRequest: (tenantId) => ({
      type: Types.CREDIT_SCORE_REQUEST,
      payload: { tenantId },
     
    }),
 
    tmCreditScoreSuccess: data => ({
      type: Types.CREDIT_SCORE_SUCCESS,
      payload: { data },
    }),

    tmDerogatoryScoreCountRequest: (tenantId) => ({
      type: Types.DEROGATORY_SCORE_COUNT_REQUEST,
      payload: {tenantId},
     
    }),
    tmDerogatoryScoreCountSuccess: data => ({
      type: Types.DEROGATORY_SCORE_COUNT_SUCCESS,
      payload: { data },
    }),
    tmFundRiskCountRequest: (organizationId,fundId) => ({
      type: Types.FUND_RISK_COUNT_REQUEST,
      payload: {organizationId,fundId},
     
    }),
    tmFundRiskCountSuccess: data => ({
      type: Types.FUND_RISK_COUNT_SUCCESS,
      payload: { data },
    }),

    tmTenantsScoreByRangeRequest: (organisationId,fundId,rangeFrom,rangeTo,month,year) => ({
      type: Types.TENANTS_SCORE_BY_RANGE_REQUEST,
      payload: {organisationId,fundId,rangeFrom,rangeTo,month,year},
     
    }),
    tmTenantsScoreByRangeSuccess: data => ({
      type: Types.TENANTS_SCORE_BY_RANGE_SUCCESS,
      payload: { data },
    }),
    tmTenantsOverAlCredPirsDeroScoreRequest: (tenantId,month,year,userId) => ({
      type: Types.TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_REQUEST,
      payload: {tenantId,month,year,userId},
     
    }),
    tmTenantsOverAlCredPirsDeroScoreSuccess: data => ({
      type: Types.TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_SUCCESS,
      payload: { data },
    }),
    tmTenantsScoreByTypeRequest: (tenantId,month,year,type) => ({
      type: Types.TENANTS_SCORE_BY_TYPE_REQUEST,
      payload: {tenantId,month,year,type},
     
    }),
    tmTenantsScoreByTypeSuccess: data => ({
      type: Types.TENANTS_SCORE_BY_TYPE_SUCCESS,
      payload: { data },
    }),

    tmTenantsScoreByPIRSRequest: (tenantId,month,year) => ({
      type: Types.TENANTS_MONTH_PIRS_REQUEST,
      payload: {tenantId,month,year},
     
    }),
    tmTenantsScoreByPIRSSuccess: data => ({
      type: Types.TENANTS_MONTH_PIRS_SUCCESS,
      payload: { data },
    }),




   
    tmTenantsCountByFundRequest: (fundId,organisationId) => ({
      type: Types.TENANTS_COUNT_BY_FUND_REQUEST,
      payload: {fundId,organisationId},
     
    }),
    tmTenantsCountByFundSuccess: data => ({
      type: Types.TENANTS_COUNT_BY_FUND_SUCCESS,
      payload: { data },
    }),
    tmScoreStatusRequest: (id,type) => ({
      type: Types.SCORE_STATUS_REQUEST,
      payload: {id,type},
     
    }),
    tmScoreStatusSuccess: data => ({
      type: Types.SCORE_STATUS_SUCCESS,
      payload: { data },
    }),
    tmTenantOverAllScoreByPropertyRequest: (id,type) => ({
      type: Types.TENANT_OVERALL_SCORE_BY_PROPERTY_REQUEST,
      payload: {id,type},
     
    }),
    tmTenantOverAllScoreByPropertySuccess: data => ({
      type: Types.TENANT_OVERALL_SCORE_BY_PROPERTY_SUCCESS,
      payload: { data },
    }),

    tmFundByOrgIdRequest: (orgId,userId,pageSize,pageNum) => ({
      type: Types.FUNDS_BY_ORG_REQUEST,
      payload: { orgId,userId,pageSize,pageNum },
    }),
    tmFundByOrgIdSuccess: data => ({
      type: Types.FUNDS_BY_ORG_SUCCESS,
      payload: { data },
    }),

    tmFundsByOrgCompanyAdminRequest: (organisationId,pageSize,pageNum) => ({
      type: Types.FUNDS_BY_ORG_COMPANY_ADMIN_REQUEST,
      payload: { organisationId,pageSize,pageNum },
    }),
    tmFundsByOrgCompanyAdminSuccess: data => ({
      type: Types.FUNDS_BY_ORG_COMPANY_ADMIN_SUCCESS,
      payload: { data },
    }),


    tmTenantsByFundIdRangeRequest: (orgId,fundId,rangeFrom,rangeTo,month,year) => ({
      type: Types.TENANTS_BY_FUNDID_RANGE_REQUEST,
      payload: { orgId,fundId,rangeFrom,rangeTo,month,year },
    }),
    tmTenantsByFundIdRangeSuccess: data => ({
      type: Types.TENANTS_BY_FUNDID_RANGE_SUCCESS,
      payload: { data },
    }),

    tmTenantsRiskCountByFundRequest: (organizationId,fundId) => ({
      type: Types.TENANTS_RISK_COUNT_BY_FUND_REQUEST,
      payload: { organizationId,fundId },
    }),
    tmTenantsRiskCountByFundSuccess: data => ({
      type: Types.TENANTS_RISK_COUNT_BY_FUND_SUCCESS,
      payload: { data },
    }),
    tmPropertyByOrgIdRequest: (organisationId) => ({
      type: Types.PROPERTY_BY_ORG_REQUEST,
      payload: { organisationId },
    }),
    tmPropertyByOrgIdSuccess: data => ({
      type: Types.PROPERTY_BY_ORG_SUCCESS,
      payload: { data },
    }),
    tmTenantCountByPropertyRequest: (propertyId) => ({
      type: Types.TENANTS_COUNT_BY_PROPERTY_REQUEST,
      payload: { propertyId},
    }),
    tmTenantCountByPropertySuccess: data => ({
      type: Types.TENANTS_COUNT_BY_PROPERTY_SUCCESS,
      payload: { data },
    }),
    tmTenantsRiskCountByPropertyRequest: (propertyId,userId) => ({
      type: Types.TENANTS_RISK_COUNT_BY_PROPERTY_REQUEST,
      payload: { propertyId,userId},
    }),
    tmTenantsRiskCountByPropertySuccess: data => ({
      type: Types.TENANTS_RISK_COUNT_BY_PROPERTY_SUCCESS,
      payload: { data },
    }),


  tmProperyTenantsByOrganizationRequest: (organizationId) => ({
    type: Types.PROPERTY_BY_TENANT_ORGID_REQUEST,
    payload: { organizationId},
  }),
  tmProperyTenantsByOrganizationSuccess: data => ({
    type: Types.PROPERTY_BY_TENANT_ORGID_SUCCESS,
    payload: { data },
  }),



  tmProperyByFundRequest: (organisationId,fundId,pageSize,pageNum) => ({
    type: Types.PROPERTY_BY_FUND_REQUEST,
    payload: {organisationId,fundId,pageSize,pageNum},
  }),
  tmProperyByFundSuccess: data => ({
    type: Types.PROPERTY_BY_FUND_SUCCESS,
    payload: { data },
  }),



  tmPropertyDashboardByFundRequest: (organisationId,userId,pageSize,pageNum) => ({
    type: Types.PROPERTY_DASHBOARD_BY_FUND_REQUEST,
    payload: { organisationId,userId,pageSize,pageNum},
  }),
  tmPropertyDashboardByFundSuccess: data => ({
    type: Types.PROPERTY_DASHBOARD_BY_FUND_SUCCESS,
    payload: { data },
  }),

  tmTenantsScoreByPropertyRangeRequest: (organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum) => ({
   
    type: Types.TENANTS_BY_PROPERTY_REQUEST,
    payload: {organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum},
   
  }),
  tmTenantsScoreByPropertyRangeSuccess: data => ({
    type: Types.TENANTS_BY_PROPERTY_SUCCESS,
    payload: { data },
  }),

  tmTenantNameScoreByPropertyRangeRequest: (organizationId,propertyId,rangeFrom,rangeTo,month,year,pageSize,pageNum) => ({
    type: Types.TENANTS_BY_PROPERTY_RANGE_REQUEST,
    payload: {organizationId,propertyId,rangeFrom,rangeTo,month,year,pageSize,pageNum},
   
  }),
  tmTenantNameScoreByPropertyRangeSuccess: data => ({
    type: Types.TENANTS_BY_PROPERTY_RANGE_SUCCESS,
    payload: { data },
  }),

//jain
tmFundSaveRequest: (fund) => ({
    type: Types.FUND_SAVE_REQUEST,
    payload: {fund},
  }),
  tmFundSaveSuccess: data => ({
    type: Types.FUND_SAVE_SUCCESS,
    payload: { data },
  }),

  tmFundEditRequest: (fund) => ({
    type: Types.FUND_EDIT_REQUEST,
    payload: {fund},
  }),
  tmFundEditSuccess: data => ({
    type: Types.FUND_EDIT_SUCCESS,
    payload: { data },
  }),

  tmFundDeleteRequest: (fundId) => ({
    type: Types.FUND_DELETE_REQUEST,
    payload: { fundId},
  }),
  tmFundDeleteSuccess: data => ({
    type: Types.FUND_DELETE_SUCCESS,
    payload: { data },
  }),
  tmFundListRequest: (organisationId) => ({
    type: Types.FUND_LIST_REQUEST,
    payload: {organisationId},
  }),
  tmFundListSuccess: data => ({
    type: Types.FUND_LIST_SUCCESS,
    payload: { data },
  }),



  tmCompanySaveRequest: (company) => ({
    type: Types.COMPANY_SAVE_REQUEST,
    payload: {company},
  }),
  tmCompanySaveSuccess: data => ({
    type: Types.COMPANY_SAVE_SUCCESS,
    payload: { data },
  }),

tmCompanyEditRequest: (company) => ({
    type: Types.COMPANY_EDIT_REQUEST,
    payload: {company},
  }),
  tmCompanyEditSuccess: data => ({
    type: Types.COMPANY_EDIT_SUCCESS,
    payload: { data },
  }),

  tmCompanyDeleteRequest: (companyId) => ({
    type: Types.COMPANY_DELETE_REQUEST,
    payload: {companyId},
  }),
  tmCompanyDeleteSuccess: data => ({
    type: Types.COMPANY_DELETE_SUCCESS,
    payload: { data },
  }),
  tmCompanyListRequest: (organisationId,propertyId) => ({
    type: Types.COMPANY_LIST_REQUEST,
    payload: {organisationId,propertyId},
  }),
  tmCompanyListSuccess: data => ({
    type: Types.COMPANY_LIST_SUCCESS,
    payload: { data },
  }),



  tmPropertySaveRequest: (property) => ({
    type: Types.PROPERTY_SAVE_REQUEST,
    payload: {property},
  }),
  tmPropertySaveSuccess: data => ({
    type: Types.PROPERTY_SAVE_SUCCESS,
    payload: { data },
  }),
  tmPropertyEditRequest: (property) => ({
    type: Types.PROPERTY_EDIT_REQUEST,
    payload: {property},
  }),
  tmPropertyEditSuccess: data => ({
    type: Types.PROPERTY_EDIT_SUCCESS,
    payload: { data },
  }),
  tmPropertyDeleteRequest: (propertyId) => ({
    type: Types.PROPERTY_DELETE_REQUEST,
    payload: {propertyId},
  }),
  tmPropertyDeleteSuccess: data => ({
    type: Types.PROPERTY_DELETE_SUCCESS,
    payload: { data },
  }),
  tmPropertyListRequest: (organisationId,fundId,userId) => ({
    type: Types.PROPERTY_LIST_REQUEST,
    payload: {organisationId,fundId,userId},
  }),
  tmPropertyListSuccess: data => ({
    type: Types.PROPERTY_LIST_SUCCESS,
    payload: { data },
  }),




  tmTenantSaveRequest: (tenant) => ({
    type: Types.TENANT_SAVE_REQUEST,
    payload: {tenant},
  }),
  tmTenantSaveSuccess: data => ({
    type: Types.TENANT_SAVE_SUCCESS,
    payload: { data },
  }),
  tmTenantEditRequest: (tenant) => ({
    type: Types.TENANT_EDIT_REQUEST,
    payload: {tenant},
  }),
  tmTenantEditSuccess: data => ({
    type: Types.TENANT_EDIT_SUCCESS,
    payload: { data },
  }),
  tmTenantDeleteRequest: (tenantId) => ({
    type: Types.TENANT_DELETE_REQUEST,
    payload: {tenantId},
  }),
  tmTenantDeleteSuccess: data => ({
    type: Types.TENANT_DELETE_SUCCESS,
    payload: { data },
  }),
  tmTenantListRequest: (organisationId,propertyId) => ({
    type: Types.TENANT_LIST_REQUEST,
    payload: {organisationId,propertyId},
  }),
  tmTenantListSuccess: data => ({
    type: Types.TENANT_LIST_SUCCESS,
    payload: { data },
  }),



  tmUploadTenantRequest: (file,organizationId,userId) => ({
    type: Types.UPLOAD_TENANT_REQUEST,
    payload: {file,organizationId,userId},
   
  }),
  tmUploadTenantSuccess: data => ({
    type: Types.UPLOAD_TENANT_SUCCESS,
    payload: { data },
  }),

  tmPropertyImageUploadRequest: (file,propertyId) => ({
    type: Types.PROPERTY_IMAGE_UPLOAD_REQUEST,
    payload: {file,propertyId},
    
  }),
  tmPropertyImageUploadSuccess: data => ({
    type: Types.PROPERTY_IMAGE_UPLOAD_SUCCESS,
    payload: { data },
  }),

  tmFundImageUploadRequest: (file,fundId) => ({
    type: Types.FUND_IMAGE_UPLOAD_REQUEST,
    payload: {file,fundId},
    
  }),
  tmFundImageUploadSuccess: data => ({
    type: Types.FUND_IMAGE_UPLOAD_SUCCESS,
    payload: { data },
  }),
  
  tmUserSaveRequest: (managedUserVM) => ({
    type: Types.USER_SAVE_REQUEST,
    payload: {managedUserVM},
  }),
  tmUserSaveSuccess: data => ({
    type: Types.USER_SAVE_SUCCESS,
    payload: { data },
  }),
  tmUserListRequest: () => ({
    type: Types.USER_LIST_REQUEST,
    payload: {},
  }),
  tmUserListSuccess: data => ({
    type: Types.USER_LIST_SUCCESS,
    payload: { data },
  }),
  tmUserNameRoleRequest: (login) => ({
    type: Types.USER_NAME_ROLE_REQUEST,
    payload: {},
  }),
  tmUserNameRoleSuccess: data => ({
    type: Types.USER_NAME_ROLE_SUCCESS,
    payload: { data },
  }),
  tmUserEditRequest: (user) => ({
    type: Types.USER_EDIT_REQUEST,
    payload: {user},
  }),
  tmUserEditSuccess: data => ({
    type: Types.USER_EDIT_SUCCESS,
    payload: { data },
  }),

  tmUserDeleteRequest: (userId) => ({
    type: Types.USER_DELETE_REQUEST,
    payload: {userId},
  }),
  tmUserDeleteSuccess: data => ({
    type: Types.USER_DELETE_SUCCESS,
    payload: { data },
  }),

  tmUserEnableRequest: (userId) => ({
    type: Types.USER_ENABLE_REQUEST,
    payload: {userId},
  }),
  tmUserEnableSuccess: data => ({
    type: Types.USER_ENABLE_SUCCESS,
    payload: { data },
  }),
  tmUserDisableRequest: (userId) => ({
    type: Types.USER_DISABLE_REQUEST,
    payload: {userId},
  }),
  tmUserDisableSuccess: data => ({
    type: Types.USER_DISABLE_SUCCESS,
    payload: { data },
  }),


  tmContifyCompanyInsightSearchDataRequest: (salesTriggerId,startDate,endDate,tenantId) => ({
    type: Types.ONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_REQUEST,
    payload: {salesTriggerId,startDate,endDate,tenantId},
   
  }),
  tmContifyCompanyInsightSearchDataSuccess: data => ({
    type: Types.  CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_SUCCESS,
    payload: { data },
  }),
  tmActiveLiensJudgmentDetailsRequest: (tenantId) => ({
    type: Types.ACTIVE_TAXLIEN_JUDGMENT_DETAILS_REQUEST,
    payload: {tenantId},
   
  }),
  tmActiveLiensJudgmentDetailsSuccess: data => ({
    type: Types.  ACTIVE_TAXLIEN_JUDGMENT_DETAILS_SUCCESS,
    payload: { data },
  }),

  tmContifyCompanySearchRequest: (contifyCompanySearchRequest) => ({
    type: Types.CONTIFY_COMPANY_SEARCH_REQUEST,
    payload: {contifyCompanySearchRequest},
  }),
  tmContifyCompanySearchSuccess: data => ({
    type: Types.CONTIFY_COMPANY_SEARCH_SUCCESS,
    payload: { data },
  }),
  tmCreditSafeCompanyListRequest: (creditSafeCompanyRequest) => ({
    type: Types.CREDITSAFE_COMPANY_LIST_REQUEST,
    payload: {creditSafeCompanyRequest},
  }),
 tmCreditSafeCompanyListResultSuccess: data => ( {
    type: Types.CREDITSAFE_COMPANY_LIST_SUCCESS,
    payload: { data },
  }),
  tmCorteraBusinessSearchRequest: (corteraBusinessList) => ({
    type: Types.CORTERA_BUSINESS_SEARCH_REQUEST,
    payload: {corteraBusinessList},
  }),
  tmCorteraBusinessSearchSuccess: data => ({
    type: Types.CORTERA_BUSINESS_SEARCH_SUCCESS,
    payload: { data },
  }),

  tmTloCompanySearchRequest: (tloCompanySearchRequest) => ({
    type: Types.TLO_COMPANY_SEARCH_REQUEST,
    payload: {tloCompanySearchRequest},
  }),
  tmTloCompanySearchSuccess: data => ({
    type: Types.TLO_COMPANY_SEARCH_SUCCESS,
    payload: { data },
  }),

  
  tmFundListByOrgRequest: (organisationId) => ({
    type: Types.FUND_LIST_BY_ORG_REQUEST,
    payload: {organisationId},
  }),
  tmFundListByOrgSuccess: data => ({
    type: Types.FUND_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmTenantListByOrgRequest: (organisationId) => ({
    type: Types.TENANT_LIST_BY_ORG_REQUEST,
    payload: {organisationId},
  }),
  tmTenantListByOrgSuccess: data => ({
    type: Types.TENANT_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmPropertyListByOrgRequest: (organisationId) => ({
    type: Types.PROPERTY_LIST_BY_ORG_REQUEST,
    payload: {organisationId},
  }),
  tmPropertyListByOrgSuccess: data => ({
    type: Types.PROPERTY_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmUserListByOrgRequest: (organisationId) => ({
    type: Types.USER_LIST_BY_ORG_REQUEST,
    payload: {organisationId},
  }),
  tmUserListByOrgSuccess: data => ({
    type: Types.USER_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmCompanyListByOrgRequest: () => ({
    type: Types.COMPANY_LIST_BY_ORG_REQUEST,
    payload: {},
  }),
  tmCompanyListByOrgSuccess: data => ({
    type: Types.COMPANY_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmFundManagerListRequest: (organizationId) => ({
    type: Types.FUND_MANAGER_LIST_REQUEST,
    payload: {organizationId},
  }),
  tmFundManagerListSuccess: data => ({
    type: Types.FUND_MANAGER_LIST_SUCCESS,
    payload: { data },
  }),
  tmAssetManagerListRequest: (organizationId) => ({
    type: Types.ASSET_MANAGER_LIST_REQUEST,
    payload: {organizationId},
  }),
  tmAssetManagerListSuccess: data => ({
    type: Types.ASSET_MANAGER_LIST_SUCCESS,
    payload: { data },
  }),
  tmFundPropertyDashboardRequest: (fundId,userId,pageSize,pageNum) => ({
    type: Types.FUND_PROPERTY_DASHBOARD_REQUEST,
    payload: {fundId,userId,pageSize,pageNum},
  }),
  tmFundPropertyDashboardSuccess: data => ({
    type: Types.FUND_PROPERTY_DASHBOARD_SUCCESS,
    payload: { data },
  }),
  tmCompanyAdminDashboardRequest: (organizationId,userId,pageNum,pageSize) => ({
    type: Types.COMPANY_ADMIN_DASHBOARD_REQUEST,
    payload: {organizationId,userId,pageNum,pageSize},
  }),

  tmCompanyAdminDashboardSuccess: data => ({
    type: Types.COMPANY_ADMIN_DASHBOARD_SUCCESS,
    payload: { data },
  }),
  tmTenantInitialScoreRequest: (tenantId) => ({
    type: Types.TENANT_INITIAL_SCORE_REQUEST,
    payload: {tenantId},
  }),
  tmTenantInitialScoreSuccess: data => ({
    type: Types.TENANT_INITIAL_SCORE_SUCCESS,
    payload: { data },
  }),

  tmFundByIdRequest: (fundId) => ({
    type: Types.FUND_BY_ID_REQUEST,
    payload: {fundId},
  }),
  tmFundByIdSuccess: data => ({
    type: Types.FUND_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmCompanyByIdRequest: (companyId) => ({
    type: Types. COMPANY_BY_ID_REQUEST,
        payload: {companyId},
  }),
  tmCompanyByIdSuccess: data => ({
    type: Types.COMPANY_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmTenantByIdRequest: (tenantId) => ({
    type: Types. TENANT_BY_ID_REQUEST,
        payload: {tenantId},
  }),
  tmTenantByIdSuccess: data => ({
    type: Types.TENANT_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmUserByIdRequest: (userId) => ({
    type: Types. USER_BY_ID_REQUEST,
        payload: {userId},
  }),
  tmUserByIdSuccess: data => ({
    type: Types.USER_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmPropertyByIdRequest: (propertyId) => ({
    type: Types. PROPERTY_BY_ID_REQUEST,
        payload: {propertyId},
  }),
  tmPropertyByIdSuccess: data => ({
    type: Types.PROPERTY_BY_ID_SUCCESS,
    payload: { data },
  }),
  tmPropertyListOfOrgByAssetManagerRequest: (organisationId,userId,pageSize,pageNum) => ({
    type: Types. PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_REQUEST,
        payload: {organisationId,userId,pageSize,pageNum},
  }),
  tmPropertyListOfOrgByAssetManagerSuccess: data => ({
    type: Types.PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_SUCCESS,
    payload: { data },
  }),

  tmActiveLiensDetailsRequest: (tenantId,month,year) => ({
    type: Types.ACTIVE_LIENS_DETAILS_BY_TENANT_ID_REQUEST,
    payload: {tenantId,month,year},
   
  }),
  tmActiveLiensDetailsSuccess: data => ({
    type: Types.ACTIVE_LIENS_DETAILS_BY_TENANT_ID_SUCCESS,
    payload: { data },
  }),
  tmActiveUccDetailsRequest: (tenantId,month,year) => ({
    type: Types.ACTIVE_UCC_DETAILS_BY_TENANT_ID_REQUEST,
    payload: {tenantId,month,year},
   
  }),
  tmActiveUccDetailsSuccess: data => ({
    type: Types.ACTIVE_UCC_DETAILS_BY_TENANT_ID_SUCCESS,
    payload: { data },
  }),

  tmFundDashboardCountRequest: (organisationId,userId) => ({
    type: Types. FUND_DASHBOARD_COUNT_REQUEST,
        payload: {organisationId,userId},
  }),
  tmFundDashboardCountSuccess: data => ({
    type: Types.FUND_DASHBOARD_COUNT_SUCCESS,
    payload: { data },
  }),

  tmPropertyDashboardCountRequest: (organisationId,fundId) => ({
    type: Types. PROPERTY_DASHBOARD_COUNT_REQUEST,
        payload: {organisationId,fundId},
  }),
  tmPropertyDashboardCountSuccess: data => ({
    type: Types.PROPERTY_DASHBOARD_COUNT_SUCCESS,
    payload: { data },
  }),

  tmTenantsCountByPropertyRangeRequest: (propertyId,rangeFrom,year,month,organisationId,rangeTo) => ({
    type: Types. TENANTS_COUNT_BY_PROPERTY_RANGE_REQUEST,
        payload: {propertyId,rangeFrom,year,month,organisationId,rangeTo},
  }),
  tmTenantsCountByPropertyRangeSuccess: data => ({
    type: Types.TENANTS_COUNT_BY_PROPERTY_RANGE_SUCCESS,
    payload: { data },
  }),

  tmPropertyCountByOrgRequest: (organisationId,userId) => ({
    type: Types. PROPERTY_COUNT_BY_ORG_REQUEST,
        payload: {organisationId,userId},
  }),
  tmPropertyCountByOrgSuccess: data => ({
    type: Types.PROPERTY_COUNT_BY_ORG_SUCCESS,
    payload: { data },
  }),

  tmFundCountByOrgRequest: (organisationId) => ({
    type: Types. FUND_COUNT_BY_ORG_REQUEST,
        payload: {organisationId},
  }),
  tmFundCountByOrgSuccess: data => ({
    type: Types.FUND_COUNT_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmAllTenantsTrailingScoreRequest: () => ({
    type: Types. ALL_TENANTS_TRAILING_SCORE_REQUEST,
    payload: {},
    }),
    tmAllTenantsTrailingScoreSuccess: data => ({
    type: Types.ALL_TENANTS_TRAILING_SCORE_SUCCESS,
    payload: { data },
    }),

    tmActiveTaxLienJudgmentScoreByTenantIdRequest: (tenantId) => ({
      type: Types.ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_REQUEST,
      payload: {tenantId},
      }),
      tmActiveTaxLienJudgmentScoreByTenantIdSuccess: data => ({
      type: Types.ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_SUCCESS,
      payload: { data },
      }),
      tmActiveJudgmentDetailsRequest: (tenantId,month,year) => ({
        type: Types.ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_REQUEST,
        payload: {tenantId,month,year},
       
      }),
      tmActiveJudgmentDetailsSuccess: data => ({
        type: Types.ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_SUCCESS,
        payload: { data },
      }),
      tmActiveSuitDetailsRequest: (tenantId,month,year) => ({
        type: Types.ACTIVE_SUIT_DETAILS_BY_TENANT_ID_REQUEST,
        payload: {tenantId,month,year},
       
      }),
      tmActiveSuitDetailsSuccess: data => ({
        type: Types.ACTIVE_SUIT_DETAILS_BY_TENANT_ID_SUCCESS,
        payload: { data },
      }),


      tmMonitoredTenantsByFundRequest: (userId,dateFrom,dateTo) => ({
        type: Types.MONITORED_TENANTS_BY_FUND_REQUEST,
        payload: {userId,dateFrom,dateTo},
       
      }),
      tmMonitoredTenantsByFundSuccess: data => ({
        type: Types.MONITORED_TENANTS_BY_FUND_SUCCESS,
        payload: { data },
      }),


      tmMonitoredTenantsByPropertyRequest: (userId,dateFrom,dateTo) => ({
        type: Types.MONITORED_TENANTS_BY_PROPERTY_REQUEST,
        payload: {userId,dateFrom,dateTo},
       
      }),
      tmMonitoredTenantsByPropertySuccess: data => ({
        type: Types.MONITORED_TENANTS_BY_PROPERTY_SUCCESS,
        payload: { data },
      }),

      tmMonitoredTenantsByCompanyAdminRequest: (organizationId,dateFrom,dateTo) => ({
        type: Types.MONITORED_TENANTS_BY_COMPANY_ADMIN_REQUEST,
        payload: {organizationId,dateFrom,dateTo},
       
      }),
      tmMonitoredTenantsByCompanyAdminSuccess: data => ({
        type: Types.MONITORED_TENANTS_BY_COMPANY_ADMIN_SUCCESS,
        payload: { data },
      }),
     
     
      tmSaveNotificationSettingsRequest: (notificationSettings) => ({
        type: Types.SAVE_NOTIFICATION_SETTINGS_REQUEST,
        payload: {notificationSettings},
       
      }),
      tmSaveNotificationSettingsSuccess: data => ({
        type: Types.SAVE_NOTIFICATION_SETTINGS_SUCCESS,
        payload: { data },
      }),

      tmEditNotificationSettingsRequest: (notificationSettings) => ({
        type: Types.EDIT_NOTIFICATION_SETTINGS_REQUEST,
        payload: {notificationSettings},
       
      }),
      tmEditNotificationSettingsSuccess: data => ({
        type: Types.EDIT_NOTIFICATION_SETTINGS_SUCCESS,
        payload: { data },
      }),
      
   
      tmNotificationSettingsByIdRequest: (userId) => ({
        type: Types.NOTIFICATION_SETTINGS_BY_ID_REQUEST,
        payload: {userId},
       
      }),
      tmNotificationSettingsByIdSuccess: data => ({
        type: Types.NOTIFICATION_SETTINGS_BY_ID_SUCCESS,
        payload: { data },
      }),

       
      tmMonitoredTenantsNewsRequest: (tenantId,salesTriggers,startDate,endDate) => ({
        type: Types.MONITORED_TENANTS_NEWS_REQUEST,
        payload: {tenantId,salesTriggers,startDate,endDate},
       
      }),
      tmMonitoredTenantsNewsSuccess: data => ({
        type: Types.MONITORED_TENANTS_NEWS_SUCCESS,
        payload: { data },
      }),

         tmPopulateInitialDataForTenantRequest: (tenantId) => ({
        type: Types.POPULATE_INITIAL_DATA_FOR_TENANT_REQUEST,
        payload: {tenantId},
       
      }),
      tmPopulateInitialDataForTenantSuccess: data => ({
        type: Types.POPULATE_INITIAL_DATA_FOR_TENANT_SUCCESS,
        payload: { data },
      }),
     
      tmTenantUploadStatusByOrgIdRequest: (organisationId) => ({
        type: Types.TENANT_UPLOAD_STATUS_BY_ORG_ID_REQUEST,
        payload: {organisationId},
       
      }),
      tmTenantUploadStatusByOrgIdSuccess: data => ({
        type: Types.TENANT_UPLOAD_STATUS_BY_ORG_ID_SUCCESS,
        payload: { data },
      }),
     

      tmTenantFailureStatusLogListByOrgRequest: (organisationId) => ({
        type: Types.TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_REQUEST,
        payload: {organisationId},
       
      }),
      tmTenantFailureStatusLogListByOrgSuccess: data => ({
        type: Types.TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_SUCCESS,
        payload: { data },
      }),


      tmOrganizationScoreExecutionLogByOrgRequest: (organisationId) => ({
        type: Types.ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_REQUEST,
        payload: {organisationId},
       
      }),
      tmOrganizationScoreExecutionLogByOrgSuccess: data => ({
        type: Types.ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_SUCCESS,
        payload: { data },
            }),

      tmOrganizationScoreExecutionLogListRequest: (organisationId,month,year) => ({
        type: Types.ORGANIZATION_SCORE_EXECUTION_LOG_LIST_REQUEST,
        payload: {organisationId,month,year},
       
      }),
      tmOrganizationScoreExecutionLogListSuccess: data => ({
        type: Types.ORGANIZATION_SCORE_EXECUTION_LOG_LIST_SUCCESS,
        payload: { data },
      }),
     
     
      tmScoreExecutionLogListByTenantIdRequest: (tenantId,month,year) => ({
        type: Types.SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_REQUEST,
        payload: {tenantId,month,year},
       
      }),
      tmScoreExecutionLogListByTenantIdSuccess: data => ({
        type: Types.SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_SUCCESS,
        payload: { data },
      }),

      tmDistinctTenantsFromScoreExecutionLogRequest: (organisationId,month,year) => ({
        type: Types.DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_REQUEST,
        payload: {organisationId,month,year},
       
      }),
      tmDistinctTenantsFromScoreExecutionLogSuccess: data => ({
        type: Types.DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_SUCCESS,
        payload: { data },
      }),

      tmOrgInitialDataStatusRequest: (organisationId) => ({
        type: Types.ORG_INITIAL_DATA_STATUS_REQUEST,
        payload: {organisationId},
       
      }),
      tmOrgInitialDataStatusSuccess: data => ({
        type: Types.ORG_INITIAL_DATA_STATUS_SUCCESS,
        payload: { data },
      }),

      tmPopulateInitialDataByOrgRequest: (organisationId) => ({
        type: Types.POPULATE_INITIAL_DATA_BY_ORG_REQUEST,
        payload: {organisationId},
       
      }),
      tmPopulateInitialDataByOrgSuccess: data => ({
        type: Types.POPULATE_INITIAL_DATA_BY_ORG_SUCCESS,
        payload: { data },
      }),

      tmGetBankoReportByTenantRequest: (tenantId) => ({
        type: Types.GET_BANKO_REPORT_BY_TENANT_REQUEST,
        payload: {tenantId},
       
      }),
      tmGetBankoReportByTenantSuccess: data => ({
        type: Types.GET_BANKO_REPORT_BY_TENANT_SUCCESS,
        payload: { data },
      }),

      tmFundListWithUserRequest: (organisationId) => ({
        type: Types.FUNDS_WITH_USER_REQUEST,
        payload: {organisationId},
      }),
      tmFundListWithUserSuccess: data => ({
        type: Types.FUNDS_WITH_USER_SUCCESS,
        payload: { data },
      }),

      tmPropertyWithUserRequest: (organisationId) => ({
        type: Types.PROPERTY_WITH_USER_REQUEST,
        payload: { organisationId },
      }),
      tmPropertyWithUserSuccess: data => ({
        type: Types.PROPERTY_WITH_USER_SUCCESS,
        payload: { data },
      }),
      tmGetUserByLoginAndEmailRequest: ( login,email) => ({
        type: Types.GET_USER_BY_LOGIN_AND_EMAIL_REQUEST,
        payload: { login,email },
      }),
      tmGetUserByLoginAndEmailSuccess: data => ({
        type: Types.GET_USER_BY_LOGIN_AND_EMAIL_SUCCESS,
        payload: { data },
      }),
     
      tmChangePasswordWithUserIdAndPasswordRequest: ( userId,password) => ({
        type: Types.CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_REQUEST,
        payload: { userId,password },
      }),
      tmChangePasswordWithUserIdAndPasswordSuccess: data => ({
        type: Types.CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_SUCCESS,
        payload: { data },
      }),


      tmPropertyCountByOrgAndUserRequest: (organisationId,userId) => ({
        type: Types.PROPERTY_COUNT_BY_ORG_AND_USER_REQUEST,
        payload: { organisationId,userId},
      }),
      tmPropertyCountByOrgAndUserSuccess: data => ({
        type: Types.PROPERTY_COUNT_BY_ORG_AND_USER_SUCCESS,
        payload: { data },
      }),

      tmUploadPirsLogListRequest: () => ({
        type: Types.UPLOAD_PIRS_LOG_LIST_REQUEST,
        payload: {},
      }),
      tmUploadPirsLogListSuccess: data => ({
        type: Types.UPLOAD_PIRS_LOG_LIST_SUCCESS,
        payload: { data },
      }),


     
  tmUploadPirsCsvRequest: (file,year,month) => ({
    type: Types.UPLOAD_PIRS_CSV_REQUEST,
    payload: {file,year,month},
   
  }),
  tmUploadPirsCsvSuccess: data => ({
    type: Types.UPLOAD_PIRS_CSV_SUCCESS,
    payload: { data },
  }),

  tmPirsListRequest: (month,year) => ({
    type: Types.PIRS_LIST_REQUEST,
    payload: {month,year},
   
  }),
  tmPirsListSuccess: data => ({
    type: Types.PIRS_LIST_SUCCESS,
    payload: { data },
  }),

  tmGenerateDashboardRequest: (organisationId) => ({
    type: Types.GENERATE_DASHBOARD_REQUEST,
    payload: {organisationId},
   
  }),
  tmGenerateDashboardSuccess: data => ({
    type: Types.GENERATE_DASHBOARD_SUCCESS,
    payload: { data },
  }),


  tmDashboardUserListRequest: (organisationId) => ({
    type: Types.DASHBOARD_USER_LIST_REQUEST,
    payload: {organisationId},
   
  }),
  tmDashboardUserListSuccess: data => ({
    type: Types.DASHBOARD_USER_LIST_SUCCESS,
    payload: { data },
  }),

  tmGenerateDashboardByUserRequest: (userId) => ({
    type: Types.GENERATE_DASHBOARD_BY_USER_REQUEST,
    payload: {userId},
   
  }),
  tmGenerateDashboardByUserSuccess: data => ({
    type: Types.GENERATE_DASHBOARD_BY_USER_SUCCESS,
    payload: { data },
  }),

  tmTenantListByRiskRequest: ( propertyId,category,year,month,organizationId,userId,pageNum,pageSize) => ({
    type: Types.TENANT_LIST_BY_RISK_REQUEST,
    payload: { propertyId,category,year,month,organizationId,userId,pageNum,pageSize},
   
  }),
  tmTenantListByRiskSuccess: data => ({
    type: Types.TENANT_LIST_BY_RISK_SUCCESS,
    payload: { data },
  }),

  tmTenantScoreListByRiskRequest: (organizationId,propertyId,userId,category,year,month,pageNum,pageSize) => ({
    type: Types.TENANT_SCORE_LIST_BY_RISK_REQUEST,
    payload: { organizationId,propertyId,userId,category,year,month,pageNum,pageSize},
   
  }),
  tmTenantScoreListByRiskSuccess: data => ({
    type: Types.TENANT_SCORE_LIST_BY_RISK_SUCCESS,
    payload: { data },
  }),

  tmFundDashboardGridViewRequest: (orgId,userId) => ({
    type: Types.FUND_DASHBOARD_GRID_VIEW_REQUEST,
    payload: { orgId,userId },
  }),
  tmFundDashboardGridViewSuccess: data => ({
    type: Types.FUND_DASHBOARD_GRID_VIEW_SUCCESS,
    payload: { data },
  }),

  tmCompanyAdminFundDashboardGridViewRequest: (organizationId,userId,pageSize,pageNum) => ({
    type: Types.COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_REQUEST,
    payload: { organizationId,userId,pageSize,pageNum },
  }),
  tmCompanyAdminFundDashboardGridViewSuccess: data => ({
    type: Types.COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_SUCCESS,
    payload: { data },
  }),    


  tmGetFundPropertyGridViewDashboardRequest: (fundId,userId,pageSize,pageNum) => ({
    type: Types.GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST,
    payload: {fundId,userId,pageSize,pageNum},
  }),
  tmGetFundPropertyGridViewDashboardSuccess: data => ({
    type: Types.GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS,
    payload: { data },
  }),

  tmPropertyDashboardGridViewRequest: (organisationId,userId,pageSize,pageNum) => ({
    type: Types.PROPERTY_DASHBOARD_GRID_VIEW_REQUEST,
        payload: {organisationId,userId,pageSize,pageNum},
  }),
  tmPropertyDashboardGridViewSuccess: data => ({
    type: Types.PROPERTY_DASHBOARD_GRID_VIEW_SUCCESS,
    payload: { data },
  }),
  tmGetBankrutcyDetailsOfUserRequest: (userId) => ({
    type: Types. GET_BANKRUPTCY_DETAILS_OF_USER_REQUEST,
        payload: {userId},
  }),
  tmGetBankrutcyDetailsOfUserSuccess: data => ({
    type: Types.GET_BANKRUPTCY_DETAILS_OF_USER_SUCCESS,
    payload: { data },
  }),

  tmFundApexChartDataRequest: (fundId,month,year,organizationId,userId) => ({
    type: Types.FUND_APEX_CHART_DATA_REQUEST,
        payload: {fundId,month,year,organizationId,userId},
  }),
  tmFundApexChartDataSuccess: data => ({
    type: Types.FUND_APEX_CHART_DATA_SUCCESS,
    payload: { data },
  }),

  tmFundPropertyApexChartDataRequest: (propertyId,month,year,organizationId,userId) => ({
    type: Types.FUND_PROPERTY_APEX_CHART_DATA_REQUEST,
        payload: {propertyId,month,year,organizationId,userId},
  }),
  tmFundPropertyApexChartDataSuccess: data => ({
    type: Types.FUND_PROPERTY_APEX_CHART_DATA_SUCCESS,
    payload: { data },
  }),

  tmPropertyApexChartDataRequest: (propertyId,month,year,organizationId,userId) => ({
    type: Types.PROPERTY_APEX_CHART_DATA_REQUEST,
        payload: {propertyId,month,year,organizationId,userId},
  }),
  tmPropertyApexChartDataSuccess: data => ({
    type: Types.PROPERTY_APEX_CHART_DATA_SUCCESS,
    payload: { data },
  }),

  tmGetTenantScoreGraphDataByPropertyIdAndUserIdRequest: (userId,propertyId) => ({
    type: Types.GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_REQUEST,
        payload: {userId,propertyId},
  }),
  tmGetTenantScoreGraphDataByPropertyIdAndUserIdSuccess: data => ({
    type: Types.GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_SUCCESS,
  }),
  tmCompanySearchListSaveRequest: (rentRollSearchResultItems,userId) => ({
    type: Types. COMPANY_SEARCH_LIST_SAVE_REQUEST,
        payload: {rentRollSearchResultItems,userId},
  }),
  tmCompanySearchListSaveSuccess: data => ({
    type: Types.COMPANY_SEARCH_LIST_SAVE_SUCCESS,
    payload: { data },
  }),

  tmCompanySearchRequest: (companySearchRequest) => ({
    type: Types.COMPANY_SEARCH_REQUEST,
    payload: {companySearchRequest},
  }),
  tmCompanySearchSuccess: data => ( {
    type: Types.COMPANY_SEARCH_SUCCESS,
    payload: { data },
  }),

  tmPropertySearchRequest: (propertyCompanySearchrequest) => ({
    type: Types. PROPERTY_SEARCH_REQUEST,
        payload: {propertyCompanySearchrequest},
  }),
  tmPropertySearchSuccess: data => ({
    type: Types.PROPERTY_SEARCH_SUCCESS,
    payload: { data },
  }),

  tmPropertySearchListSaveRequest: (rentRollSearchResultItems,userId) => ({
    type: Types. PROPERTY_SEARCH_LIST_SAVE_REQUEST,
        payload: {rentRollSearchResultItems,userId},
  }),
  tmPropertySearchListSaveSuccess: data => ({
    type: Types.PROPERTY_SEARCH_LIST_SAVE_SUCCESS,
    payload: { data },
  }),
  
  tmGetTenantRiskCountByPropertyGridViewRequest: (propertyId,userId) => ({
    type: Types.GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_REQUEST,
        payload: {propertyId,userId},
  }),
  tmGetTenantRiskCountByPropertyGridViewSuccess: data => ({
    type: Types.GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_SUCCESS,
    payload: { data },
  }),

  tmGetTenantBarGraphDataRequest: (propertyId) => ({
    type: Types.GET_TENANT_BAR_GRAPH_DATA_REQUEST,
        payload: {propertyId},
  }),
  tmGetTenantBarGraphDataSuccess: data => ({
    type: Types.GET_TENANT_BAR_GRAPH_DATA_SUCCESS,
    payload: { data },
  }),
  tmSendEmailRequest: () => ({
    type: Types. SEND_EMAIL_REQUEST,
        payload: {},
  }),
  tmSendEmailSuccess: data => ({
    type: Types.SEND_EMAIL_SUCCESS,
    payload: { data },
  }),
  
  tmTotalTenantCountInRiskCountRequest: (propertyId) => ({
    type: Types. TOTAL_TENANT_COUNT_IN_RISK_COUNT_REQUEST,
        payload: {propertyId},
  }),
  tmTotalTenantCountInRiskCountSuccess: data => ({
    type: Types.TOTAL_TENANT_COUNT_IN_RISK_COUNT_SUCCESS,
    payload: { data },
  }),
  
  tmGenarateFundInitialReportRequest: (fundId) => ({
    type: Types. GENERATE_FUND_INITIAL_REPORT_REQUEST,
        payload: {fundId},
  }),
  tmGenarateFundInitialReportSuccess: data => ({
    type: Types.GENERATE_FUND_INITIAL_REPORT_SUCCESS,
    payload: { data },
  }),
  
  
  tmGenaratePropertyIdInitialReportRequest: (propertyId) => ({
    type: Types. GENERATE_PROPERTY_INITIAL_REPORT_REQUEST,
        payload: {propertyId},
  }),
  tmGenaratePropertyInitialReportSuccess: data => ({
    type: Types.GENERATE_PROPERTY_INITIAL_REPORT_SUCCESS,
    payload: { data },
  }),
  
  tmGenarateTenantInitialReportRequest: (tenantId) => ({
    type: Types. GENERATE_TENANT_INITIAL_REPORT_REQUEST,
        payload: {tenantId},
  }),
  tmGenarateTenantInitialReportSuccess: data => ({
    type: Types.GENERATE_TENANT_INITIAL_REPORT_SUCCESS,
    payload: { data },
  }),

  tmGenarateScoreForFundRequest: (fundId) => ({
    type: Types.GENERATE_SCORE_FOR_FUND_REQUEST,
        payload: {fundId},
  }),
  tmGenarateScoreForFundSuccess: data => ({
    type: Types.GENERATE_SCORE_FOR_FUND_SUCCESS,
    payload: { data },
  }),
  
  
  tmGetPropertyTenantLineChartRequest: (propertyId) => ({
    type: Types.GET_PROPERTY_TENANT_LINE_CHART_REQUEST,
        payload: {propertyId},
  }),
  tmGetPropertyTenantLineChartSuccess: data => ({
    type: Types.GET_PROPERTY_TENANT_LINE_CHART_SUCCESS,
    payload: { data },
  }),

  tmGetPropertyTenantLineChartPaginationRequest: (propertyId,pageNum,pageSize) => ({
    type: Types.GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_REQUEST,
        payload: {propertyId,pageNum,pageSize},
  }),
  tmGetPropertyTenantLineChartPaginationSuccess: data => ({
    type: Types.GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_SUCCESS,
    payload: { data },
  }),
  
  tmGetPropertyTenantSearchRequest: (searchTerm,propertyId,pageNum,pageSize) => ({
    type: Types.GET_PROPERTY_TENANT_SEARCH_REQUEST,
        payload: {searchTerm,propertyId,pageNum,pageSize},
  }),
  tmGetPropertyTenantSeachSuccess: data => ({
    type: Types.GET_PROPERTY_TENANT_SEARCH_SUCCESS,
    payload: { data },
  }),

  tmGetPreviousMonthTenantRiskCountRequest: (propertyId,userId) => ({
    type: Types.GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_REQUEST,
        payload: {propertyId,userId},
  }),
  tmGetPreviousMonthTenantRiskCountSuccess: data => ({
    type: Types.GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_SUCCESS,
    payload: { data },
  }),

  tmGetTenantListBasedOnLowRiskRequest: (searchTerm,propertyId,userId,month,pageNum,pageSize) => ({
    type: Types.GET_TENANT_LIST_BASED_ON_LOW_RISK_REQUEST,
        payload: {searchTerm,propertyId,userId,month,pageNum,pageSize},
  }),
  tmGetTenantListBasedOnLowRiskSuccess: data => ({
    type: Types.GET_TENANT_LIST_BASED_ON_LOW_RISK_SUCCESS,
    payload: { data },
  }),

  tmGetTenantListBasedOnMediumLowRiskRequest: (searchTerm,propertyId,userId,month,pageNum,pageSize) => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_REQUEST,
        payload: {searchTerm,propertyId,userId,month,pageNum,pageSize},
  }),
  tmGetTenantListBasedOnMediumLowRiskSuccess: data => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_SUCCESS,
    payload: { data },
  }),

  tmGetTenantListBasedOnMediumHighRiskRequest: (searchTerm,propertyId,userId,month,pageNum,pageSize) => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_REQUEST,
        payload: {searchTerm,propertyId,userId,month,pageNum,pageSize},
  }),
  tmGetTenantListBasedOnMediumHighRiskSuccess: data => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_SUCCESS,
    payload: { data },
  }), 
  
  
  tmGetTenantListBasedOnModerateHighRiskRequest: (searchTerm,propertyId,userId,month,pageNum,pageSize) => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_REQUEST,
        payload: {searchTerm,propertyId,userId,month,pageNum,pageSize},
  }),
  tmGetTenantListBasedOnModerateHighRiskSuccess: data => ({
    type: Types.GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_SUCCESS,
    payload: { data },
  }), 

  tmGetTenantListBasedOnHighRiskRequest: (searchTerm,propertyId,userId,month,pageNum,pageSize) => ({
    type: Types.GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST,
        payload: {searchTerm,propertyId,userId,month,pageNum,pageSize},
  }),
  tmGetTenantListBasedOnHighRiskSuccess: data => ({
    type: Types.GET_TENANT_LIST_BASED_ON_HIGH_RISK_SUCCESS,
    payload: { data },
  }),

  tmGetCommentaryFromCompanyReportRequest: (tenantId,month,year) => ({
    type: Types.GET_COMMENTARY_FROM_COMPANY_REPORT_REQUEST,
        payload: {tenantId,month,year},
  }),
  tmGetCommentaryFromCompanyReportSuccess: data => ({
    type: Types.GET_COMMENTARY_FROM_COMPANY_REPORT_SUCCESS,
    payload: { data },
  }),

  tmCreditSafeBankruptcyReportByTenantIdRequest: (tenantId) => ({
    type: Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_REQUEST,
        payload: {tenantId},
  }),
  tmCreditSafeBankruptcyReportByTenantIdSuccess: data => ({
    type: Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_SUCCESS,
    payload: { data },
  }),
  tmCurrentMonthFundScoreByFundIdRequest: (fundId) => ({
    type: Types.CURRENT_MONTH_FUND_SCORE_BY_FUNDID_REQUEST,
        payload: {fundId},
  }),
  tmCurrentMonthFundScoreByFundIdSuccess: data => ({
    type: Types.CURRENT_MONTH_FUND_SCORE_BY_FUNDID_SUCCESS,
    payload: { data },
  }),
  tmPropertyCityAndStateByPropertyIdRequest: (propertyId) => ({
    type: Types.PROPERTY_CITY_AND_STATE_BY_PROPERTYID_REQUEST,
        payload: {propertyId},
  }),
  tmPropertyCityAndStateByPropertyIdSuccess: data => ({
    type: Types.PROPERTY_CITY_AND_STATE_BY_PROPERTYID_SUCCESS,
    payload: { data },
  }),

  tmGetNotificationLogListRequest: (pageNum,pageSize) => ({
    type: Types.NOTIFICATION_LOG_LIST_REQUEST,
        payload: {pageNum,pageSize},
  }),
  tmGetNotificationLogListSuccess: data => ({
    type: Types.NOTIFICATION_LOG_LIST_SUCCESS,
    payload: { data },
  }),

  tmGetTenantPropertyDetailsRequest: (tenantId,month,year) => ({
    type: Types.TENANT_PROPERTY_DETAILS_REQUEST,
        payload: {tenantId,month,year},
  }),
  tmGetTenantPropertyDetailsSuccess: data => ({
    type: Types.TENANT_PROPERTY_DETAILS_SUCCESS,
    payload: { data },
  }),
  
  tmGetContifyTopicsRequest: () => ({
    type: Types.CONTIFY_TOPICS_REQUEST,
        // payload: {},
  }),
  tmGetContifyTopicsSuccess: data => ({
    type: Types.CONTIFY_TOPICS_SUCCESS,
    payload: { data },
  }),

  tmGetCompanyInsightSearchListUsingTopicRequest: (tenantId,selectedValues,startDate,endDate) => ({
    type: Types.GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST,
        payload: {tenantId,selectedValues,startDate,endDate},
  }),
  tmGetCompanyInsightSearchListUsingTopicSuccess: data => ({
    type: Types.GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS,
    payload: { data },
  }),
  
  tmParentSaveRequest: (parent) => ({
    type: Types.PARENT_SAVE_REQUEST,
    payload: {parent},
  }),
  tmParentSaveSuccess: data => ({
    type: Types.PARENT_SAVE_SUCCESS,
    payload: { data },
  }),

  tmParentListByOrgRequest: (organisationId) => ({
    type: Types.PARENT_LIST_BY_ORG_REQUEST,
    payload: {organisationId},
  }),
  tmParentListByOrgSuccess: data => ({
    type: Types.PARENT_LIST_BY_ORG_SUCCESS,
    payload: { data },
  }),

  tmParentByIdRequest: (parentId) => ({
    type: Types. PARENT_BY_ID_REQUEST,
        payload: {parentId},
  }),
  tmParentByIdSuccess: data => ({
    type: Types.PARENT_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmParentEditRequest: (parent) => ({
    type: Types.PARENT_EDIT_REQUEST,
    payload: {parent},
  }),
  tmParentEditSuccess: data => ({
    type: Types.PARENT_EDIT_SUCCESS,
    payload: { data },
  }),

  tmParentDeleteRequest: (parentId) => ({
    type: Types.PARENT_DELETE_REQUEST,
    payload: {parentId},
  }),
  tmParentDeleteSuccess: data => ({
    type: Types.PARENT_DELETE_SUCCESS,
    payload: { data },
  }),

  tmParentByOrgIdRequest: (organisationId) => ({
    type: Types.PARENT_BY_ORG_REQUEST,
    payload: { organisationId },
  }),
  tmParentByOrgIdSuccess: data => ({
    type: Types.PARENT_BY_ORG_SUCCESS,
    payload: { data },
  }),
  tmUnlinkParentRequest: (tenantId) => ({
    type: Types.UNLINK_PARENT_REQUEST,
    payload: {tenantId},
  }),
  tmUnlinkParentSuccess: data => ({
    type: Types.UNLINK_PARENT_SUCCESS,
    payload: { data },
  }),

  tmGenarateParentInitialReportRequest: (parentId) => ({
    type: Types. GENERATE_PARENT_INITIAL_REPORT_REQUEST,
        payload: {parentId},
  }),
  tmGenarateParentInitialReportSuccess: data => ({
    type: Types.GENERATE_PARENT_INITIAL_REPORT_SUCCESS,
    payload: { data },
  }),

  tmParentCreditScoreRequest: (parentId) => ({
    type: Types.PARENT_CREDIT_SCORE_REQUEST,
    payload: { parentId },
   
  }),

  tmParentCreditScoreSuccess: data => ({
    type: Types.PARENT_CREDIT_SCORE_SUCCESS,
    payload: { data },
  }),

  tmParentDerogatoryScoreCountRequest: (parentId) => ({
    type: Types.PARENT_DEROGATORY_SCORE_COUNT_REQUEST,
    payload: {parentId},
   
  }),
  tmParentDerogatoryScoreCountSuccess: data => ({
    type: Types.PARENT_DEROGATORY_SCORE_COUNT_SUCCESS,
    payload: { data },
  }),

  tmParentScoreByPIRSRequest: (parentId,month,year) => ({
    type: Types.PARENT_MONTH_PIRS_REQUEST,
    payload: {parentId,month,year},
   
  }),
  tmParentsScoreByPIRSSuccess: data => ({
    type: Types.PARENT_MONTH_PIRS_SUCCESS,
    payload: { data },
  }),

  tmMonitoredParentsNewsRequest: (parentId,salesTriggers,startDate,endDate) => ({
    type: Types.MONITORED_PARENTS_NEWS_REQUEST,
    payload: {parentId,salesTriggers,startDate,endDate},
   
  }),
  tmMonitoredParentsNewsSuccess: data => ({
    type: Types.MONITORED_PARENTS_NEWS_SUCCESS,
    payload: { data },
  }),

  tmCreditSafeBankruptcyReportByParentIdRequest: (parentId) => ({
    type: Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_REQUEST,
        payload: {parentId},
  }),
  tmCreditSafeBankruptcyReportByParentIdSuccess: data => ({
    type: Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_SUCCESS,
    payload: { data },
  }),

  tmGetParentCompanyInsightSearchListUsingTopicRequest: (parentId,selectedValues,startDate,endDate) => ({
    type: Types.GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST,
        payload: {parentId,selectedValues,startDate,endDate},
  }),
  tmGetParentCompanyInsightSearchListUsingTopicSuccess: data => ({
    type: Types.GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS,
    payload: { data },
  }),

  tmActiveUccDetailsByParentIdRequest: (parentId,month,year) => ({
    type: Types.ACTIVE_UCC_DETAILS_BY_PARENT_ID_REQUEST,
    payload: {parentId,month,year},
   
  }),
  tmActiveUccDetailsByParentIdSuccess: data => ({
    type: Types.ACTIVE_UCC_DETAILS_BY_PARENT_ID_SUCCESS,
    payload: { data },
  }),
  
  tmActiveLiensDetailsByParentIdRequest: (parentId,month,year) => ({
    type: Types.ACTIVE_LIENS_DETAILS_BY_PARENT_ID_REQUEST,
    payload: {parentId,month,year},
   
  }),
  tmActiveLiensDetailsByParentIdSuccess: data => ({
    type: Types.ACTIVE_LIENS_DETAILS_BY_PARENT_ID_SUCCESS,
    payload: { data },
  }),

  tmActiveJudgmentDetailsByParentIdRequest: (parentId,month,year) => ({
    type: Types.ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_REQUEST,
    payload: {parentId,month,year},
   
  }),
  tmActiveJudgmentDetailsByParentIdSuccess: data => ({
    type: Types.ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_SUCCESS,
    payload: { data },
  }),

  tmActiveSuitDetailsByParentIdRequest: (parentId,month,year) => ({
    type: Types.ACTIVE_SUIT_DETAILS_BY_PARENT_ID_REQUEST,
    payload: {parentId,month,year},
   
  }),
  tmActiveSuitDetailsByParentIdSuccess: data => ({
    type: Types.ACTIVE_SUIT_DETAILS_BY_PARENT_ID_SUCCESS,
    payload: { data },
  }),

  tmGetNotificationLogSearchListRequest: (organizationId,userId,type,category,alerts,dateFrom,dateTo,pageNum,pageSize) => ({
    type: Types.NOTIFICATION_LOG_SEARCH_LIST_REQUEST,
        payload: {organizationId,userId,type,category,alerts,dateFrom,dateTo,pageNum,pageSize},
  }),
  tmGetNotificationLogSearchListSuccess: data => ({
    type: Types.NOTIFICATION_LOG_SEARCH_LIST_SUCCESS,
    payload: { data },
  }),

  tmGetNewsFtlRequest: (organisationId,userId,category,tenantId,contifyId) => ({
    type: Types.NEWS_FTL_REQUEST,
        payload: {organisationId,userId,category,tenantId,contifyId},
  }),
  tmGetNewsFtlSuccess: data => ({
    type: Types.NEWS_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetDerogatoryFtlRequest: (userId,month,year) => ({
    type: Types.DEROGATORY_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetDerogatoryFtlSuccess: data => ({
    type: Types.DEROGATORY_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetCreditFtlRequest: (userId,month,year) => ({
    type: Types.CREDIT_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetCreditFtlSuccess: data => ({
    type: Types.CREDIT_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetTenantScoreFtlRequest: (userId,month,year) => ({
    type: Types.TENANT_SCORE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetTenantScoreFtlSuccess: data => ({
    type: Types.TENANT_SCORE_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetSuitsFtlRequest: (userId,month,year) => ({
    type: Types.SUITS_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetSuitsFtlSuccess: data => ({
    type: Types.SUITS_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetPirsFtlRequest: (userId,month,year) => ({
    type: Types.PIRS_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetPirsFtlSuccess: data => ({
    type: Types.PIRS_FTL_SUCCESS,
    payload: { data },
  }),
  
   tmGetActiveStatusFtlRequest: (userId,month,year) => ({
    type: Types.ACTIVE_STATUS_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetActiveStatusFtlSuccess: data => ({
    type: Types.ACTIVE_STATUS_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetPropertyOverallScoreFtlRequest: (userId,month,year) => ({
    type: Types.PROPERTY_OVERALL_SCORE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetPropertyOverallScoreFtlSuccess: data => ({
    type: Types.PROPERTY_OVERALL_SCORE_FTL_SUCCESS,
    payload: { data },
  }),
  
  tmGetFundOverallScoreFtlRequest: (userId,month,year) => ({
    type: Types.FUND_OVERALL_SCORE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetFundOverallScoreFtlSuccess: data => ({
    type: Types.FUND_OVERALL_SCORE_FTL_SUCCESS,
    payload: { data },
  }),

  tmGetCautionaryUccMonthlyFtlRequest: (userId,prevMonth,year) => ({
    type: Types.CAUTIONARY_UCC_MONTHLY_FTL_REQUEST,
        payload: {userId,prevMonth,year},
  }),
  tmGetCautionaryUccMonthlyFtlSuccess: data => ({
    type: Types.CAUTIONARY_UCC_MONTHLY_FTL_SUCCESS,
    payload: { data },
  }),

  tmGetCautionaryUccInitialPopulateFtlRequest: (userId,month,year) => ({
    type: Types.CAUTIONARY_UCC_INITIAL_POPULATE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetCautionaryUccInitialPopulateFtlSuccess: data => ({
    type: Types.CAUTIONARY_UCC_INITIAL_POPULATE_FTL_SUCCESS,
    payload: { data },
  }),
  

  tmGetLiensMonthlyFtlRequest: (userId,prevMonth,year) => ({
    type: Types.LIENS_MONTHLY_FTL_REQUEST,
        payload: {userId,prevMonth,year},
  }),
  tmGetLiensMonthlyFtlSuccess: data => ({
    type: Types.LIENS_MONTHLY_FTL_SUCCESS,
    payload: { data },
  }),

  tmGetLiensInitialPopulateFtlRequest: (userId,month,year) => ({
    type: Types.LIENS_INITIAL_POPULATE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetLiensInitialPopulateFtlSuccess: data => ({
    type: Types.LIENS_INITIAL_POPULATE_FTL_SUCCESS,
    payload: { data },
  }),

  tmGetJudgementMonthlyFtlRequest: (userId,prevMonth,year) => ({
    type: Types.JUDGEMENT_MONTHLY_FTL_REQUEST,
        payload: {userId,prevMonth,year},
  }),
  tmGetJudgementMonthlyFtlSuccess: data => ({
    type: Types.JUDGEMENT_MONTHLY_FTL_SUCCESS,
    payload: { data },
  }),

  tmGetJudgementInitialPopulateFtlRequest: (userId,month,year) => ({
    type: Types.JUDGEMENT_INITIAL_POPULATE_FTL_REQUEST,
        payload: {userId,month,year},
  }),
  tmGetJudgementInitialPopulateFtlSuccess: data => ({
    type: Types.JUDGEMENT_INITIAL_POPULATE_FTL_SUCCESS,
    payload: { data },
  }),

  tmTenantCommentarySaveRequest: (tenantCommentary) => ({
    type: Types.TENANT_COMMENTARY_SAVE_REQUEST,
    payload: {tenantCommentary},
  }),
  tmTenantCommentarySaveSuccess: data => ({
    type: Types.TENANT_COMMENTARY_SAVE_SUCCESS,
    payload: { data },
  }),
  
  tmCommentaryListRequest: (organisationId,userId,tenantId,pageSize,pageNum) => ({
    type: Types.COMMENTARY_LIST_REQUEST,
    payload: {organisationId,userId,tenantId,pageSize,pageNum},
  }),
  tmCommentaryListSuccess: data => ({
    type: Types.COMMENTARY_LIST_SUCCESS,
    payload: { data },
  }),

  tmGetTenantReportsDetailsRequest: (organisationId,tenantId,businessToken) => ({
    type: Types.TENANT_REPORTS_DETAILS_REQUEST,
    payload: {organisationId,tenantId,businessToken},
  }),
  tmGetTenantReportsDetailsSuccess: data => ({
    type: Types.TENANT_REPORTS_DETAILS_SUCCESS,
    payload: { data },
  }),
  
  tmGenerateTenantReportRequest: (tenantId,businessToken,creditSafeId) => ({
    type: Types.GENERATE_TENANT_REPORT_REQUEST,
    payload: {tenantId,businessToken,creditSafeId},
  }),
  tmGenerateTenantReportSuccess: data => ({
    type: Types.GENERATE_TENANT_REPORT_SUCCESS,
    payload: { data },
  }),

  tmGenerateMonthlyPdfReportRequest: (organisationId) => ({
    type: Types.GENERATE_MONTHLY_PDF_REPORT_REQUEST,
    payload: {organisationId},
  }),
  tmGenerateMonthlyPdfReportSuccess: data => ({
    type: Types.GENERATE_MONTHLY_PDF_REPORT_SUCCESS,
    payload: { data },
  }),

  tmGenerateMonthlyFundReportRequest: (organisationId,fundId) => ({
    type: Types.GENERATE_MONTHLY_FUND_REPORT_REQUEST,
    payload: {organisationId,fundId},
  }),
  tmGenerateMonthlyFundReportSuccess: data => ({
    type: Types.GENERATE_MONTHLY_FUND_REPORT_SUCCESS,
    payload: { data },
  }),
  
  tmGenerateMonthlyPropertyDetailsPdfReportRequest: (organisationId,propertyId,userId) => ({
    type: Types.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_REQUEST,
    payload: {organisationId,propertyId,userId},
  }),
  tmGenerateMonthlyPropertyDetailsPdfReportSuccess: data => ({
    type: Types.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_SUCCESS,
    payload: { data },
  }),

  tmRiskListGraphMonthlyReportRequest: (organisationId,propertyId,userId) => ({
    type: Types.RISK_LIST_GRAPH_MONTHLY_REPORT_REQUEST,
    payload: {organisationId,propertyId,userId},
  }),
  tmRiskListGraphMonthlyReportSuccess: data => ({
    type: Types.RISK_LIST_GRAPH_MONTHLY_REPORT_SUCCESS,
    payload: { data },
  }),

  tmGraphMonthlySaveRequest: (organisationId,propertyId,userId,tenantId,dataUrl) => ({
    type: Types.GRAPH_MONTHLY_SAVE_REQUEST,
    payload: {organisationId,propertyId,userId,tenantId,dataUrl},
  }),
  tmGraphMonthlySaveSuccess: data => ({
    type: Types.GRAPH_MONTHLY_SAVE_SUCCESS,
    payload: { data },
  }),
  
  tmCleanUpScoreRequest: (organisationId) => ({
    type: Types.CLEAN_UP_SCORE_REQUEST,
    payload: {organisationId},
  }),
  tmCleanUpScoreSuccess: data => ({
    type: Types.CLEAN_UP_SCORE_SUCCESS,
    payload: { data },
  }),

  tmCleanUpNewsRequest: (organisationId,dateRange) => ({
    type: Types.CLEAN_UP_NEWS_REQUEST,
    payload: {organisationId,dateRange},
  }),
  tmCleanUpNewsSuccess: data => ({
    type: Types.CLEAN_UP_NEWS_SUCCESS,
    payload: { data },
  }),

  tmSaveTenantNewsSettingsRequest: (tenantNewsSettings) => ({
    type: Types.TENANT_NEWS_SETTINGS_REQUEST,
    payload: {tenantNewsSettings},
   
  }),
  tmSaveTenantNewsSettingsSuccess: data => ({
    type: Types.TENANT_NEWS_SETTINGS_SUCCESS,
    payload: { data },
  }),
  
  tmEditTenantNewsSettingsRequest: (tenantNewsSettings) => ({
    type: Types.EDIT_TENANT_NEWS_SETTINGS_REQUEST,
    payload: {tenantNewsSettings},
   
  }),
  tmEditTenantNewsSettingsSuccess: data => ({
    type: Types.EDIT_TENANT_NEWS_SETTINGS_SUCCESS,
    payload: { data },
  }),
  
  tmTenantNewsSettingsByIdRequest: (tenantId) => ({
    type: Types.TENANT_NEWS_SETTINGS_BY_ID_REQUEST,
    payload: {tenantId},
   
  }),
  tmTenantNewsSettingsByIdSuccess: data => ({
    type: Types.TENANT_NEWS_SETTINGS_BY_ID_SUCCESS,
    payload: { data },
  }),

  tmPropertyDashboardGridViewByFundIdRequest: (fundId,userId,pageSize,pageNum) => ({
    type: Types.PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_REQUEST,
        payload: {fundId,userId,pageSize,pageNum},
  }),
  tmPropertyDashboardGridViewByFundIdSuccess: data => ({
    type: Types.PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_SUCCESS,
    payload: { data },
  }),
};
  
  

  

  /**
   * Reducers
   */
  const INITIAL_STATE = {
    tmList: [],
    tmNotificationList: [],
    tmDashBoardFundList: [],
    tmBankoNotificationList: [],
    tmLexisNexisNotificationList: [],
    tmDashboardFund:[],
    tmPropertyDashboard:[],
    tmFundPropertyDashboard:[],
    tmCompanyAdminDashboard:[],
 
    scoreCommentary:"",
    data: [],
    pirsData : "",
    creditScoreData :"",
    derogatoryScoreCount:"",
    fundRiskCount:"",
    tenantsScoreByRange:[],
    tenantsOverAlCredPirsDeroScore:[],
    tenantsScoreByType:"",
    tenantsCountByFund:"",
    scoreStatus:"",
    tenantOverAllScoreByProperty:"",
    fundsByOrg:[],
    propertyHeatMap:[],
    tenantsByFundRange:[],
    tenantsRiskCountByFund:"",
    tenantsCountByProperty:"",
    tenantsRiskCountByProperty:"",
    tenantsRiskCount:"",
    propertyByOrg:[],
    tmPropertyByFund:[],
    tenantPIRSMap:"",
    loading: false,
    error: null,
    tenantScoreByPropertyRange:[],
    tenantNamesByPropertyRange:[],
    tenantsCountByPropertyRange:"",

    fundSave:"",
    fundEditObj:"",
    fundDelete:"",
    fundList:[],
    fund:"",
    fundDasboardCount:"",
    fundCountByOrg:"",
    fundListWithUser:[],

    companySave:"",
    companyEditObj:"",
    companyDelete:"",
    companyList:[],
    company:"",
    companyAdminFunds:[],
   

    propertySave:"",
    propertyEditObj:"",
    propertyDelete:"",
    propertyList:[],
    property:"",
    propertyListAssetManager:[],
    propertyDasboardCount:"",
    propertyCountByOrg:"",
    propertyCountByOrgAndUser:"",
    propertyWithUser:[],

    tenantSave:"",
    tenantEditObj:"",
    tenantDelete:"",
    tenantList:[],
    uploadTenantStatus:"",
    tenant:"",
    tmListByFund:[],
    tmListByProperty:[],
    userList:[],
    userSave:"",
    userEditObj:"",
    userDelete:"",

    userEnable:"",
    userDisable:"",

    user:"",
    userByLoginAndEmail:"",

    insightSearchData:[],
    liensJudgmentDetailsDTO:"",

    contifyCompanySearchList:[],
    creditSafeCompanyList:[],
    corteraBusinessList:[],
    tloCompanySearchList:[],

    fundManagerList:[],
    assetManagerList:[],
    liensList:[],
    judgmentList:[],
    suitList:[],
    uccList:[],

    tenantsTrailingScoreList:[],
    taxLienJudgmentScore:"",


    monitoredTenantsByFund:[],
    monitoredTenantsByProperty:[],
    monitoredTenantsByCompanyAdmin:[],

    saveNotificationSettings:"",
    editNotificationSettings:"",
    notificationSettingsById:"",

    orgInitialDataStatus:"",
    changePassword:"",



    populateInitialDataForTenant:"",
    tenantUploadStatusList:[],
    tenantFailureStatusLogListByOrg:[],
    organizationForExecutionLogByOrg:[],
    organizationScoreExecutionLogList:[],
    scoreExecutionLogListByTenantId:[],
    distinctTenantsFromScoreExecutionLog:[],
    populateInitialDataByOrg:"",
    bankoReportList:[],

    uploadPirsLogList:[],
    uploadPirsCsvStatus:"",
    pirsList:[],
    dashboardUserList:[],
    tenantListByRisk:[],
    tenantScoreListByRisk:[],
    fundDashboarGridView:[],
    companyAdminFundDashboarGridView:[],
    fundPropertyGridViewDashboard:[],
    propertyDashboardGridView:[],
    propertyImageUpload:"",
    fundImageUpload:"",

    fundApexChartData:"",
    fundPropertyApexChartData:"",
    propertyApexChartData:"",
    tenantScoreGraphDataByPropertyIdAnduserId:[],
    tenantRiskCountByPropertyGridView:"",
    tenantBarGraphData:"",
    propertyTenantLineChart:[],
    propertyTenantLineChartPagination:"",
    propertyTenantSearch:"",
    previousMonthTenantRiskCount:"",
    tenantListBasedOnLowRisk:"",
    tenantListBasedOnMediumLowRisk:"",
    tenantListBasedOnMediumHighRisk:"",
    tenantListBasedOnModerateHighRisk:"",
    tenantListBasedOnHighRisk:"",
    // creditScoreCompanyList:[],

    bankruptcyDetailsOfUser:[],
    companySearchListSave:[],
    propertySearch:"",
    propertySearchListSave:[],
    companySearch:"",
    sendEmail:"",
    tenantCountInRiskCount:"",
    generateFundInitialReport:"",
    generatePropertyInitialReport:"",
    generateTenantInitialReport:"",
    generateFundScore:"",
    commentaryFromCompanyReport:"",
    creditSafeBankruptcyReport:[],
    currentMonthFundScore:"",
    propertyCityAndState:"",
    notificationLog:"",
    tenantPropertyDetails:"",
    contifyTopics:[],
    contifyListUsingTopic:[],
    
    parentSave:"",
    parentList:[],
    parentEditObj:"",
    parentDelete:"",
    parentByOrg:[],
    unlinkParent:"",
    generateParentInitialReport:"",
    parentCreditScoreData:"",
    parentDerogatoryScoreCount:"",
    parentPIRSMap:"",
    tmParentNotificationList:[],
    creditSafeParentBankruptcyReport:[],
    parentContifyListUsingTopic:[],
    parentUccList:[],
    parentLiensList:[],
    parentJudgmentList:[],
    parentSuitList:[],
    notificationLogSearchList:"",
    newsFtl:"",
    derogatoryFtl:"",
    creditFtl:"",
    tenantScoreFtl:"",
    suitsFtl:"",
    pirsFtl:"",
    activeStatusFtl:"",
    propertyOverallScoreFtl:"",
    fundOverallScoreFtl:"",
    cautionaryUccMonthlyFtl:"",
    cautionaryUccInitialPopulateFtl:"",
    liensMonthlyFtl:"",
    liensInitialPopulateFtl:"",
    judgementMonthlyFtl:"",
    judgementInitialPopulateFtl:"",
    commentary:"",
    commentaryList:"",
    reportsDetails:"",
    generateReports:"",
    generateMonthlyPdfReport:"",
    generateMonthlyPropertyDetailsPdfReport:"",
    riskListGraphMonthlyReport:[],
    graphSaveMonthlyReport:"",
    monthlyFundReport:"",
    cleanUpScore:"",
    cleanUpNews:"",
    saveTenantNewsSettings:"",
    editTenantNewsSettings:"",
    tenantNewsSettingsById:"",
    propertyDashboardGridViewByFundId:[],
  };
 
  export default function tenantmonitoring(state = INITIAL_STATE, action) {

    switch (action.type) {
      case Types.LIST_REQUEST:

        return { ...state, loading: true, error: null };
       
        case Types.LIST_SUCCESS:
        return {
          ...state,
           tmList: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.TENANT_MONITORING_LIST_BY_FUND_REQUEST:

          return { ...state, loading: true, error: null };
         
          case Types.TENANT_MONITORING_LIST_BY_FUND_SUCCESS:
          return {
            ...state,
             tmListByFund: action.payload.data,
             loading: false,
           // loggedUser: action.payload.data.user,
          };

          case Types.TENANT_MONITORING_LIST_BY_PROPERTY_REQUEST:

            return { ...state, loading: true, error: null };
           
            case Types.TENANT_MONITORING_LIST_BY_PROPERTY_SUCCESS:
            return {
              ...state,
               tmListByProperty: action.payload.data,
               loading: false,
             // loggedUser: action.payload.data.user,
            };

        case Types.DASHBOARD_REQUEST:
        return {
          ...state,
           loading: true,
        };

        case Types.DASHBOARD_SUCCESS:
        return {
          ...state,
          tmDashboardFund: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.PIRS_REQUEST:
        return { ...state, loading: true, error: null };

        case Types.SCORE_COMMENTARY_REQUEST:
        return { ...state, loading: true, error: null };

        case Types.SCORE_COMMENTARY_SUCCESS:
       
        return {
          ...state,
       
           scoreCommentary: action.payload.data,
           loading: false,
         
        };
        case Types.DEROGATORY_SCORE_COUNT_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.DEROGATORY_SCORE_COUNT_SUCCESS:
       
          return {
            ...state,
             derogatoryScoreCount: action.payload.data,
             loading: false,
           
          };
          case Types.FUND_RISK_COUNT_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.FUND_RISK_COUNT_SUCCESS:
         
          return {
            ...state,
            fundRiskCount: action.payload.data,
             loading: false,
           
          };
       
          case Types.TENANTS_SCORE_BY_RANGE_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_SCORE_BY_RANGE_SUCCESS:
           
          return {
            ...state,
            tenantsScoreByRange: action.payload.data,
             loading: false,
           
          };

          case Types.TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_SUCCESS:
         
          return {
            ...state,
            tenantsOverAlCredPirsDeroScore: action.payload.data,
             loading: false,
           
          };
          case Types.TENANTS_SCORE_BY_TYPE_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_SCORE_BY_TYPE_SUCCESS:
         
          return {
            ...state,
            tenantsScoreByType: action.payload.data,
             loading: false,
           
          };

          case Types.TENANTS_COUNT_BY_FUND_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_COUNT_BY_FUND_SUCCESS:
           
          return {
            ...state,
            tenantsCountByFund: action.payload.data,
             loading: false,
           
          };
          case Types.SCORE_STATUS_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.SCORE_STATUS_SUCCESS:
           
          return {
            ...state,
            scoreStatus: action.payload.data,
             loading: false,
           
          };
          case Types.TENANT_OVERALL_SCORE_BY_PROPERTY_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANT_OVERALL_SCORE_BY_PROPERTY_SUCCESS:
         
          return {
            ...state,
            tenantOverAllScoreByProperty: action.payload.data,
             loading: false,
           
          };
          case Types.FUNDS_BY_ORG_REQUEST:
         
          return { ...state, loading: true, error: null };
 
          case Types.FUNDS_BY_ORG_SUCCESS:
          return {
            ...state,
            fundsByOrg: action.payload.data,
             loading: false,
           
          };

          case Types.TENANTS_BY_FUNDID_RANGE_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_BY_FUNDID_RANGE_SUCCESS:
          return {
            ...state,
            tenantsByFundRange: action.payload.data,
             loading: false,
           
          };
          case Types.TENANTS_RISK_COUNT_BY_FUND_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_RISK_COUNT_BY_FUND_SUCCESS:
          return {
            ...state,
            tenantsRiskCountByFund: action.payload.data,
             loading: false,
           
          };
          case Types.PROPERTY_BY_ORG_REQUEST:
          console.log("Reach ing");
          return { ...state, loading: true, error: null };
 
          case Types.PROPERTY_BY_ORG_SUCCESS:
          return {
            ...state,
            propertyByOrg: action.payload.data,
             loading: false,
           
          };

          case Types.TENANTS_COUNT_BY_PROPERTY_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_COUNT_BY_PROPERTY_SUCCESS:
          return {
            ...state,
            tenantsCountByProperty: action.payload.data,
             loading: false,
           
          };
          case Types.TENANTS_RISK_COUNT_BY_PROPERTY_REQUEST:
          return { ...state, loading: true, error: null };
 
          case Types.TENANTS_RISK_COUNT_BY_PROPERTY_SUCCESS:
          return {
            ...state,
            tenantsRiskCountByProperty: action.payload.data,
             loading: false,
           
          };

         
        case Types.PIRS_SUCCESS:
        return {
          ...state,
          pirsData: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

         case Types.CREDIT_SCORE_REQUEST:
        return { ...state, loading: true, error: null };
       

        case Types.CREDIT_SCORE_SUCCESS:
        return {
          ...state,
          creditScoreData: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };




        case Types.LIST_NOTIFICATION_REQUEST:
        return { ...state, loading: true, error: null };
       
        case Types.LIST_NOTIFICATION_SUCCESS:
        return {
          ...state,
          tmNotificationList: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.LIST_BANKO_NOTIFICATION_REQUEST:
       
        return { ...state, loading: true, error: null };
       
        case Types.LIST_BANKO_NOTIFICATION_SUCCESS:
        return {
          ...state,
          tmBankoNotificationList: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.LIST_LEXIS_NEXIS_NOTIFICATION_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.LIST_LEXIS_NEXIS_NOTIFICATION_SUCCESS:
        return {
          ...state,
          tmLexisNexisNotificationList: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.PROPERTY_BY_TENANT_ORGID_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.PROPERTY_BY_TENANT_ORGID_SUCCESS:
        return {
          ...state,
          propertyHeatMap: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.PROPERTY_DASHBOARD_BY_FUND_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.PROPERTY_DASHBOARD_BY_FUND_SUCCESS:
        return {
          ...state,
          tmPropertyDashboard: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.PROPERTY_BY_FUND_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.PROPERTY_BY_FUND_SUCCESS:
        return {
          ...state,
          tmPropertyByFund: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };
       

        case Types.TENANTS_MONTH_PIRS_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.TENANTS_MONTH_PIRS_SUCCESS:
        return {
          ...state,
          tenantPIRSMap: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.TENANTS_BY_PROPERTY_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.TENANTS_BY_PROPERTY_SUCCESS:
        return {
          ...state,
          tenantScoreByPropertyRange: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        case Types.TENANTS_BY_PROPERTY_RANGE_REQUEST:

        return {
          ...state, loading: true, error: null };
       
        case Types.TENANTS_BY_PROPERTY_RANGE_SUCCESS:
        return {
          ...state,
          tenantNamesByPropertyRange: action.payload.data,
           loading: false,
         // loggedUser: action.payload.data.user,
        };

        //jain

case Types.FUND_SAVE_REQUEST:

          return {
            ...state, loading: true, error: null };
         
          case Types.FUND_SAVE_SUCCESS:
          return {
            ...state,
            fundSave: action.payload.data,
             loading: false,
           // loggedUser: action.payload.data.user,
          };

          case Types.FUND_EDIT_REQUEST:

          return {
            ...state, loading: true, error: null };
         
          case Types.FUND_EDIT_SUCCESS:
          return {
            ...state,
            fundEditObj: action.payload.data,
             loading: false,
           // loggedUser: action.payload.data.user,
          };

          case Types.FUND_DELETE_REQUEST:

            return {
              ...state, loading: true, error: null };
           
            case Types.FUND_DELETE_SUCCESS:
            return {
              ...state,
              fundDelete: action.payload.data,
               loading: false,
             // loggedUser: action.payload.data.user,
            };

            case Types.FUND_LIST_REQUEST:
         
            return { ...state, loading: true, error: null };
   
            case Types.FUND_LIST_SUCCESS:
            return {
              ...state,
              fundList: action.payload.data,
               loading: false,
             
            };


            case Types.COMPANY_SAVE_REQUEST:

              return {
                ...state, loading: true, error: null };
             
              case Types.COMPANY_SAVE_SUCCESS:
              return {
                ...state,
                companySave: action.payload.data,
                 loading: false,
               // loggedUser: action.payload.data.user,
              };
 
              case Types.COMPANY_EDIT_REQUEST:

                return {
                  ...state, loading: true, error: null };
               
                case Types.COMPANY_EDIT_SUCCESS:
                return {
                  ...state,
                  companyEditObj: action.payload.data,
                   loading: false,
                 // loggedUser: action.payload.data.user,
                };

                case Types.COMPANY_DELETE_REQUEST:

                  return {
                    ...state, loading: true, error: null };
                 
                  case Types.COMPANY_DELETE_SUCCESS:
                  return {
                    ...state,
                    companyDelete: action.payload.data,
                     loading: false,
                   // loggedUser: action.payload.data.user,
                  };
                  case Types.COMPANY_LIST_REQUEST:
         
                    return { ...state, loading: true, error: null };
           
                    case Types.COMPANY_LIST_SUCCESS:
                    return {
                      ...state,
                      companyList: action.payload.data,
                       loading: false,
                     
                    };


                  case Types.PROPERTY_SAVE_REQUEST:

              return {
                ...state, loading: true, error: null };
             
              case Types.PROPERTY_SAVE_SUCCESS:
              return {
                ...state,
                propertySave: action.payload.data,
                 loading: false,
               // loggedUser: action.payload.data.user,
              };
 
     
              case Types.PROPERTY_EDIT_REQUEST:

                return {
                  ...state, loading: true, error: null };
               
                case Types.PROPERTY_EDIT_SUCCESS:
                return {
                  ...state,
                  propertyEditObj: action.payload.data,
                   loading: false,
                 // loggedUser: action.payload.data.user,
                };

                case Types.PROPERTY_DELETE_REQUEST:

                  return {
                    ...state, loading: true, error: null };
                 
                  case Types.PROPERTY_DELETE_SUCCESS:
                  return {
                    ...state,
                    propertyDelete: action.payload.data,
                     loading: false,
                   // loggedUser: action.payload.data.user,
                  };
                  case Types.PROPERTY_LIST_REQUEST:
         
                  return { ...state, loading: true, error: null };
         
                  case Types.PROPERTY_LIST_SUCCESS:
                  return {
                    ...state,
                    propertyList: action.payload.data,
                     loading: false,
                   
                  };



                  case Types.TENANT_SAVE_REQUEST:

                    return {
                      ...state, loading: true, error: null };
                   
                    case Types.TENANT_SAVE_SUCCESS:
                    return {
                      ...state,
                      tenantSave: action.payload.data,
                       loading: false,
                     // loggedUser: action.payload.data.user,
                    };

                    case Types.TENANT_EDIT_REQUEST:

                      return {
                        ...state, loading: true, error: null };
                     
                      case Types.TENANT_EDIT_SUCCESS:
                      return {
                        ...state,
                        tenantEditObj: action.payload.data,
                         loading: false,
                       // loggedUser: action.payload.data.user,
                      };

                      case Types.TENANT_DELETE_REQUEST:

                        return {
                          ...state, loading: true, error: null };
                       
                        case Types.TENANT_DELETE_SUCCESS:
                        return {
                          ...state,
                          tenantDelete: action.payload.data,
                           loading: false,
                         // loggedUser: action.payload.data.user,
                        };

                        case Types.TENANT_LIST_REQUEST:
         
                          return { ...state, loading: true, error: null };
                 
                          case Types.TENANT_LIST_SUCCESS:
                          return {
                            ...state,
                          tenantList: action.payload.data,
                             loading: false,
                           
                          };


                        case Types.UPLOAD_TENANT_REQUEST:

                          return {
                            ...state, loading: true, error: null };
                         
                          case Types.UPLOAD_TENANT_SUCCESS:
                          return {
                            ...state,
                            uploadTenantStatus: action.payload.data,
                             loading: false,
                           // loggedUser: action.payload.data.user,
                          };

                          case Types.PROPERTY_IMAGE_UPLOAD_REQUEST:

                            return { 
                              ...state, loading: true, error: null };
                            
                            case Types.PROPERTY_IMAGE_UPLOAD_SUCCESS:
                            return {
                              ...state,
                              propertyImageUpload: action.payload.data,
                               loading: false,
                             // loggedUser: action.payload.data.user,
                            };
                            case Types.FUND_IMAGE_UPLOAD_REQUEST:

                              return { 
                                ...state, loading: true, error: null };
                              
                              case Types.FUND_IMAGE_UPLOAD_SUCCESS:
                              return {
                                ...state,
                                fundImageUpload: action.payload.data,
                                 loading: false,
                               // loggedUser: action.payload.data.user,
                              };
  
                              

        case Types.USER_SAVE_REQUEST:

          return {
            ...state, loading: true, error: null };
         
          case Types.USER_SAVE_SUCCESS:
          return {
            ...state,
            userSave: action.payload.data,
             loading: false,
           // loggedUser: action.payload.data.user,
          };

                          case Types.USER_LIST_REQUEST:
         
                            return { ...state, loading: true, error: null };
                   
                            case Types.USER_LIST_SUCCESS:
                            return {
                              ...state,
                          userList: action.payload.data,
                               loading: false,
                             
                            };

                            case Types.USER_NAME_ROLE_REQUEST:
         
                              return { ...state, loading: true, error: null };
                     
                              case Types.USER_NAME_ROLE_SUCCESS:
                              return {
                                ...state,
                            user: action.payload.data,
                                 loading: false,
                               
                              };

                           case Types.USER_EDIT_REQUEST:
         
                              return { ...state, loading: true, error: null };
                     
                              case Types.USER_EDIT_SUCCESS:
                              return {
                                ...state,
                            userEditObj: action.payload.data,
                                 loading: false,
                               
                              };

                              case Types.USER_DELETE_REQUEST:
         
                                return { ...state, loading: true, error: null };
                       
                                case Types.USER_DELETE_SUCCESS:
                                return {
                                  ...state,
                              userDelete: action.payload.data,
                                   loading: false,
                                 
                                };

                                case Types.USER_ENABLE_REQUEST:
         
                                  return { ...state, loading: true, error: null };
                         
                                  case Types.USER_ENABLE_SUCCESS:
                                  return {
                                    ...state,
                                userEnable: action.payload.data,
                                     loading: false,
                                   
                                  };
                                  case Types.USER_DISABLE_REQUEST:
         
                                    return { ...state, loading: true, error: null };
                           
                                    case Types.USER_DISABLE_SUCCESS:
                                    return {
                                      ...state,
                                  userDisable: action.payload.data,
                                       loading: false,
                                     
                                    };
                             
                            case Types.CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_REQUEST:

                              return {
                                ...state, loading: true, error: null };
                             
                              case Types.   CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_:
                              return {
                                ...state,
                                insightSearchData: action.payload.data,
                                 loading: false,
                               // loggedUser: action.payload.data.user,
                              };

                              case Types.ACTIVE_TAXLIEN_JUDGMENT_DETAILS_REQUEST:

                              return {
                                ...state, loading: true, error: null };
                             
                              case Types.   ACTIVE_TAXLIEN_JUDGMENT_DETAILS_SUCCESS:
                              return {
                                ...state,
                                liensJudgmentDetailsDTO: action.payload.data,
                                 loading: false,
                               // loggedUser: action.payload.data.user,
                              };

                              case Types.CONTIFY_COMPANY_SEARCH_REQUEST:

                                return {
                                  ...state, loading: true, error: null };
                               
                                case Types.CONTIFY_COMPANY_SEARCH_SUCCESS:
                                return {
                                  ...state,
                                  contifyCompanySearchList: action.payload.data,
                                   loading: false,
                                 // loggedUser: action.payload.data.user,
                                };

                              
                                case Types.CREDITSAFE_COMPANY_LIST_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.CREDITSAFE_COMPANY_LIST_SUCCESS:
                                 
                                  return {
                       
                                    ...state,
                                    creditSafeCompanyList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
                                  case Types.CORTERA_BUSINESS_SEARCH_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.CORTERA_BUSINESS_SEARCH_SUCCESS:
                                  return {
                                    ...state,
                                    corteraBusinessList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };

                                case Types.TLO_COMPANY_SEARCH_REQUEST:

                                return {
                                  ...state, loading: true, error: null };
                               
                                case Types.TLO_COMPANY_SEARCH_SUCCESS:
                                return {
                                  ...state,
                                  tloCompanySearchList: action.payload.data,
                                   loading: false,
                                 
                                };

                                  case Types.FUND_LIST_BY_ORG_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.FUND_LIST_BY_ORG_SUCCESS:
                                  return {
                                    ...state,
                                    fundList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
                                  case Types.TENANT_LIST_BY_ORG_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.TENANT_LIST_BY_ORG_SUCCESS:
                                  return {
                                    ...state,
                                    tenantList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
                                  case Types.PROPERTY_LIST_BY_ORG_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.PROPERTY_LIST_BY_ORG_SUCCESS:
                                  return {
                                    ...state,
                                    propertyList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
                                  case Types.USER_LIST_BY_ORG_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.USER_LIST_BY_ORG_SUCCESS:
                                  return {
                                    ...state,
                                    userList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
                                  case Types.COMPANY_LIST_BY_ORG_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.COMPANY_LIST_BY_ORG_SUCCESS:
                                  return {
                                    ...state,
                                    companyList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
               

                                  case Types.FUND_MANAGER_LIST_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.FUND_MANAGER_LIST_SUCCESS:
                                  return {
                                    ...state,
                                    fundManagerList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };
               

                                  case Types.ASSET_MANAGER_LIST_REQUEST:

                                  return {
                                    ...state, loading: true, error: null };
                                 
                                  case Types.ASSET_MANAGER_LIST_SUCCESS:
                                  return {
                                    ...state,
                                    assetManagerList: action.payload.data,
                                     loading: false,
                                   // loggedUser: action.payload.data.user,
                                  };

                                  case Types.FUND_PROPERTY_DASHBOARD_REQUEST:

                                    return {
                                      ...state, loading: true, error: null };
                                   
                                    case Types.FUND_PROPERTY_DASHBOARD_SUCCESS:
                                    return {
                                      ...state,
                                      tmFundPropertyDashboard: action.payload.data,
                                       loading: false,
                                     // loggedUser: action.payload.data.user,
                                    };

                                    case Types.COMPANY_ADMIN_DASHBOARD_REQUEST:

                                      return {
                                        ...state, loading: true, error: null };
                                     
                                      case Types.COMPANY_ADMIN_DASHBOARD_SUCCESS:
                                      return {
                                        ...state,
                                        tmCompanyAdminDashboard: action.payload.data,
                                         loading: false,
                                       // loggedUser: action.payload.data.user,
                                      };

                                      case Types.TENANT_INITIAL_SCORE_REQUEST:

                                        return {
                                          ...state, loading: true, error: null };
                                       
                                        case Types.TENANT_INITIAL_SCORE_SUCCESS:
                                        return {
                                          ...state,
                                          data: action.payload.data,
                                           loading: false,
                                         // loggedUser: action.payload.data.user,
                                        };

                                        case Types.FUND_BY_ID_REQUEST:

                                          return {
                                            ...state, loading: true, error: null };
                                         
                                          case Types.FUND_BY_ID_SUCCESS:
                                          return {
                                            ...state,
                                            fundEditObj: action.payload.data,
                                             loading: false,
                                           // loggedUser: action.payload.data.user,
                                          };
                                          case Types.COMPANY_BY_ID_REQUEST:

                                            return {
                                              ...state, loading: true, error: null };
                                           
                                            case Types.COMPANY_BY_ID_SUCCESS:
                                            return {
                                              ...state,
                                              companyEditObj: action.payload.data,
                                               loading: false,
                                             // loggedUser: action.payload.data.user,
                                            };
                                            case Types.TENANT_BY_ID_REQUEST:

                                            return {
                                              ...state, loading: true, error: null };
                                           
                                            case Types.TENANT_BY_ID_SUCCESS:
                                            return {
                                              ...state,
                                              tenantEditObj: action.payload.data,
                                               loading: false,
                                             // loggedUser: action.payload.data.user,
                                            };
                                            case Types.USER_BY_ID_REQUEST:

                                            return {
                                              ...state, loading: true, error: null };
                                           
                                            case Types.USER_BY_ID_SUCCESS:
                                            return {
                                              ...state,
                                              userEditObj: action.payload.data,
                                               loading: false,
                                             // loggedUser: action.payload.data.user,
                                            };
                                            case Types.PROPERTY_BY_ID_REQUEST:

                                            return {
                                              ...state, loading: true, error: null };
                                           
                                            case Types.PROPERTY_BY_ID_SUCCESS:
                                            return {
                                              ...state,
                                              propertyEditObj: action.payload.data,
                                               loading: false,
                                             // loggedUser: action.payload.data.user,
                                            };

                                            case Types.FUNDS_BY_ORG_COMPANY_ADMIN_REQUEST:

                                              return {
                                                ...state, loading: true, error: null };
                                             
                                              case Types.FUNDS_BY_ORG_COMPANY_ADMIN_SUCCESS:
                                              return {
                                                ...state,
                                                companyAdminFunds: action.payload.data,
                                                 loading: false,
                                               // loggedUser: action.payload.data.user,
                                              };
 
                                              case Types.PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_REQUEST:

                                                return {
                                                  ...state, loading: true, error: null };
                                               
                                                case Types.PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_SUCCESS:
                                                return {
                                                  ...state,
                                                  propertyListAssetManager: action.payload.data,
                                                   loading: false,
                                                 // loggedUser: action.payload.data.user,
                                                };

                                                case Types.ACTIVE_LIENS_DETAILS_BY_TENANT_ID_REQUEST:

                                                  return {
                                                    ...state, loading: true, error: null };
                                                 
                                                  case Types.ACTIVE_LIENS_DETAILS_BY_TENANT_ID_SUCCESS:
                                                  return {
                                                    ...state,
                                                    liensList: action.payload.data,
                                                     loading: false,
                                                   // loggedUser: action.payload.data.user,
                                                  };
   
                                                case Types.FUND_DASHBOARD_COUNT_REQUEST:

                                                  return {
                                                    ...state, loading: true, error: null };
                                                 
                                                  case Types.FUND_DASHBOARD_COUNT_SUCCESS:
                                                  return {
                                                    ...state,
                                                    fundDasboardCount: action.payload.data,
                                                     loading: false,
                                                   // loggedUser: action.payload.data.user,
                                                  };
     
                                                  case Types.PROPERTY_DASHBOARD_COUNT_REQUEST:

                                                  return {
                                                    ...state, loading: true, error: null };
                                                 
                                                  case Types.PROPERTY_DASHBOARD_COUNT_SUCCESS:
                                                  return {
                                                    ...state,
                                                    propertyDasboardCount: action.payload.data,
                                                     loading: false,
                                                   // loggedUser: action.payload.data.user,
                                                  };
     
                 
                                                  case Types.TENANTS_COUNT_BY_PROPERTY_RANGE_REQUEST:

                                                    return {
                                                      ...state, loading: true, error: null };
                                                   
                                                    case Types.TENANTS_COUNT_BY_PROPERTY_RANGE_SUCCESS:
                                                    return {
                                                      ...state,
                                                      tenantsCountByPropertyRange: action.payload.data,
                                                       loading: false,
                                                     // loggedUser: action.payload.data.user,
                                                    };
       
                                                    case Types.PROPERTY_COUNT_BY_ORG_REQUEST:

                                                      return {
                                                        ...state, loading: true, error: null };
                                                     
                                                      case Types.PROPERTY_COUNT_BY_ORG_SUCCESS:
                                                      return {
                                                        ...state,
                                                        propertyCountByOrg: action.payload.data,
                                                         loading: false,
                                                       // loggedUser: action.payload.data.user,
                                                      };
                   
                                                      case Types.FUND_COUNT_BY_ORG_REQUEST:

                                                        return {
                                                          ...state, loading: true, error: null };
                                                       
                                                        case Types.FUND_COUNT_BY_ORG_SUCCESS:
                                                        return {
                                                          ...state,
                                                          fundCountByOrg: action.payload.data,
                                                           loading: false,
                                                         // loggedUser: action.payload.data.user,
                                                        };


                                                     
               
case Types.ALL_TENANTS_TRAILING_SCORE_REQUEST:

  return {
  ...state, loading: true, error: null };
  case Types.ALL_TENANTS_TRAILING_SCORE_SUCCESS:
  return {
  ...state,
  tenantsTrailingScoreList: action.payload.data,
  loading: false,
  // loggedUser: action.payload.data.user,
  };

  case Types.ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_REQUEST:

    return {
    ...state, loading: true, error: null };
    case Types.ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_SUCCESS:
    return {
    ...state,
    taxLienJudgmentScore: action.payload.data,
    loading: false,
    // loggedUser: action.payload.data.user,
    };

    case Types.ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_REQUEST:

      return {
      ...state, loading: true, error: null };
      case Types.ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_SUCCESS:
      return {
      ...state,
      judgmentList: action.payload.data,
      loading: false,
      // loggedUser: action.payload.data.user,
      };

      case Types.ACTIVE_SUIT_DETAILS_BY_TENANT_ID_REQUEST:
        return {
        ...state, loading: true, error: null };
        case Types.ACTIVE_SUIT_DETAILS_BY_TENANT_ID_SUCCESS:
        return {
        ...state,
        suitList: action.payload.data,
        loading: false,
        // loggedUser: action.payload.data.user,
        };
        case Types.ACTIVE_UCC_DETAILS_BY_TENANT_ID_REQUEST:

          return {
          ...state, loading: true, error: null };
          case Types.ACTIVE_UCC_DETAILS_BY_TENANT_ID_SUCCESS:
          return {
          ...state,
          uccList: action.payload.data,
          loading: false,
          // loggedUser: action.payload.data.user,
          };

        case Types.MONITORED_TENANTS_BY_FUND_REQUEST:
          return {
          ...state, loading: true, error: null };
          case Types.MONITORED_TENANTS_BY_FUND_SUCCESS:
          return {
          ...state,
          monitoredTenantsByFund: action.payload.data,
          loading: false,
          // loggedUser: action.payload.data.user,
          };


          case Types.MONITORED_TENANTS_BY_PROPERTY_REQUEST:
            return {
            ...state, loading: true, error: null };
            case Types.MONITORED_TENANTS_BY_PROPERTY_SUCCESS:
            return {
            ...state,
            monitoredTenantsByProperty: action.payload.data,
            loading: false,
            // loggedUser: action.payload.data.user,
            };

            case Types.MONITORED_TENANTS_BY_COMPANY_ADMIN_REQUEST:
              return {
              ...state, loading: true, error: null };
              case Types.MONITORED_TENANTS_BY_COMPANY_ADMIN_SUCCESS:
              return {
              ...state,
              monitoredTenantsByCompanyAdmin: action.payload.data,
              loading: false,
              // loggedUser: action.payload.data.user,
              };
           
           
              case Types.SAVE_NOTIFICATION_SETTINGS_REQUEST:
                return {
                ...state, loading: true, error: null };
                case Types.SAVE_NOTIFICATION_SETTINGS_SUCCESS:
                return {
                ...state,
                saveNotificationSettings: action.payload.data,
                loading: false,
                // loggedUser: action.payload.data.user,
                };
             

                case Types.EDIT_NOTIFICATION_SETTINGS_REQUEST:
                  return {
                  ...state, loading: true, error: null };
                  case Types. EDIT_NOTIFICATION_SETTINGS_SUCCESS:
                  return {
                  ...state,
                  editNotificationSettings: action.payload.data,
                  loading: false,
                  // loggedUser: action.payload.data.user,
                  };

                  case Types.NOTIFICATION_SETTINGS_BY_ID_REQUEST:
                    return {
                    ...state, loading: true, error: null };
                    case Types. NOTIFICATION_SETTINGS_BY_ID_SUCCESS:
                    return {
                    ...state,
                    notificationSettingsById: action.payload.data,
                    loading: false,
                    // loggedUser: action.payload.data.user,
                    };
                
                    case Types.MONITORED_TENANTS_NEWS_REQUEST:
                      return {
                      ...state, loading: true, error: null };
                      case Types.MONITORED_TENANTS_NEWS_SUCCESS:
                      return {
                      ...state,
                      tmNotificationList: action.payload.data,
                      loading: false,
                      // loggedUser: action.payload.data.user,
                      };

                      case Types.POPULATE_INITIAL_DATA_FOR_TENANT_REQUEST:
                        return {
                        ...state, loading: true, error: null };
                        case Types.POPULATE_INITIAL_DATA_FOR_TENANT_SUCCESS:
                        return {
                        ...state,
                        populateInitialDataForTenant: action.payload.data,
                        loading: false,
                        // loggedUser: action.payload.data.user,
                        };
                        case Types.TENANT_UPLOAD_STATUS_BY_ORG_ID_REQUEST:
                          return {
                          ...state, loading: true, error: null };
                          case Types.TENANT_UPLOAD_STATUS_BY_ORG_ID_SUCCESS:
                          return {
                          ...state,
                          tenantUploadStatusList: action.payload.data,
                          loading: false,
                          // loggedUser: action.payload.data.user,
                          };

                          case Types.TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_REQUEST:
                          return {
                          ...state, loading: true, error: null };
                          case Types.TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_SUCCESS:
                          return {
                          ...state,
                          tenantFailureStatusLogListByOrg: action.payload.data,
                          loading: false,
                          // loggedUser: action.payload.data.user,
                          };
                         

                          case Types.ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_REQUEST:
                          return {
                          ...state, loading: true, error: null };
                          case Types.ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_SUCCESS:
                          return {
                          ...state,
                          organizationForExecutionLogByOrg: action.payload.data,
                          loading: false,
                          // loggedUser: action.payload.data.user,
                          };

                          case Types.ORGANIZATION_SCORE_EXECUTION_LOG_LIST_REQUEST:
                            return {
                            ...state, loading: true, error: null };
                            case Types.ORGANIZATION_SCORE_EXECUTION_LOG_LIST_SUCCESS:
                            return {
                            ...state,
                            organizationScoreExecutionLogList: action.payload.data,
                            loading: false,
                            // loggedUser: action.payload.data.user,
                            };

                            case Types.SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_REQUEST:
                              return {
                              ...state, loading: true, error: null };
                              case Types.SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_SUCCESS:
                              return {
                              ...state,
                              scoreExecutionLogListByTenantId: action.payload.data,
                              loading: false,
                              // loggedUser: action.payload.data.user,
                              };

                              case Types.DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_REQUEST:
                              return {
                              ...state, loading: true, error: null };
                              case Types.DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_SUCCESS:
                              return {
                              ...state,
                              distinctTenantsFromScoreExecutionLog: action.payload.data,
                              loading: false,
                              // loggedUser: action.payload.data.user,
                              };

                              case Types.ORG_INITIAL_DATA_STATUS_REQUEST:
                                return {
                                ...state, loading: true, error: null };
                                case Types.ORG_INITIAL_DATA_STATUS_SUCCESS:
                                return {
                                ...state,
                                orgInitialDataStatus: action.payload.data,
                                loading: false,
                                // loggedUser: action.payload.data.user,
                                };


                                case Types.POPULATE_INITIAL_DATA_BY_ORG_REQUEST:
                                  return {
                                  ...state, loading: true, error: null };
                                  case Types.POPULATE_INITIAL_DATA_BY_ORG_SUCCESS:
                                  return {
                                  ...state,
                                  populateInitialDataByOrg: action.payload.data,
                                  loading: false,
                                  // loggedUser: action.payload.data.user,
                                  };
                               

                                  case Types.GET_BANKO_REPORT_BY_TENANT_REQUEST:
                                    return {
                                    ...state, loading: true, error: null };
                                    case Types.GET_BANKO_REPORT_BY_TENANT_SUCCESS:
                                    return {
                                    ...state,
                                    bankoReportList: action.payload.data,
                                    loading: false,
                                    // loggedUser: action.payload.data.user,
                                    };

                                    case Types.UPLOAD_PIRS_LOG_LIST_REQUEST:
                                      return {
                                      ...state, loading: true, error: null };
                                      case Types.UPLOAD_PIRS_LOG_LIST_SUCCESS:
                                      return {
                                      ...state,
                                      uploadPirsLogList: action.payload.data,
                                      loading: false,
                                      // loggedUser: action.payload.data.user,
                                      };
                                 
                                    case Types.FUNDS_WITH_USER_REQUEST:

                                      return {
                                        ...state, loading: true, error: null };
                                     
                                      case Types.FUNDS_WITH_USER_SUCCESS:
                                      return {
                                        ...state,
                                        fundListWithUser: action.payload.data,
                                         loading: false,
                                       // loggedUser: action.payload.data.user,
                                      };

                                      case Types.PROPERTY_WITH_USER_REQUEST:
                                        console.log("Reach ing");
                                        return { ...state, loading: true, error: null };
                               
                                        case Types.PROPERTY_WITH_USER_SUCCESS:
                                        return {
                                          ...state,
                                          propertyWithUser: action.payload.data,
                                           loading: false,
                                         
                                        };
                                        case Types.GET_USER_BY_LOGIN_AND_EMAIL_REQUEST :
                                         
                                          return { ...state, loading: true, error: null };
                                 
                                          case Types.GET_USER_BY_LOGIN_AND_EMAIL_SUCCESS:
                                          return {
                                            ...state,
                                            userByLoginAndEmail: action.payload.data,
                                             loading: false,
                                           
                                          };
                             
                                         
                                          case Types.CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_REQUEST :
                                         
                                            return { ...state, loading: true, error: null };
                                   
                                            case Types.CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_SUCCESS:
                                            return {
                                              ...state,
                                              changePassword: action.payload.data,
                                               loading: false,
                                             
                                            };
                                            case Types.PROPERTY_COUNT_BY_ORG_AND_USER_REQUEST :
                                         
                                              return { ...state, loading: true, error: null };
                                     
                                              case Types.PROPERTY_COUNT_BY_ORG_AND_USER_SUCCESS:
                                              return {
                                                ...state,
                                                propertyCountByOrgAndUser: action.payload.data,
                                                 loading: false,
                                               
                                              };

                                               
                                              case Types.UPLOAD_PIRS_CSV_REQUEST:

                                                return {
                                                  ...state, loading: true, error: null };
                                               
                                                case Types.UPLOAD_PIRS_CSV_SUCCESS:
                                                return {
                                                  ...state,
                                                  uploadPirsCsvStatus: action.payload.data,
                                                   loading: false,
                                                 // loggedUser: action.payload.data.user,
                                                };

                                                case Types.PIRS_LIST_REQUEST:

                                                return {
                                                  ...state, loading: true, error: null };
                                               
                                                case Types.PIRS_LIST_SUCCESS:
                                                return {
                                                  ...state,
                                                  pirsList: action.payload.data,
                                                   loading: false,
                                                 // loggedUser: action.payload.data.user,
                                                };

                                                case Types.GENERATE_DASHBOARD_REQUEST:

                                                  return {
                                                    ...state, loading: true, error: null };
                                                 
                                                  case Types.GENERATE_DASHBOARD_SUCCESS:
                                                  return {
                                                    ...state,
                                                    data: action.payload.data,
                                                     loading: false,
                                                   // loggedUser: action.payload.data.user,
                                                  };

                                                  case Types.DASHBOARD_USER_LIST_REQUEST:

                                                    return {
                                                      ...state, loading: true, error: null };
                                                   
                                                    case Types.DASHBOARD_USER_LIST_SUCCESS:
                                                    return {
                                                      ...state,
                                                      dashboardUserList: action.payload.data,
                                                       loading: false,
                                                     // loggedUser: action.payload.data.user,
                                                    };

                                                    case Types.GENERATE_DASHBOARD_BY_USER_REQUEST:

                                                      return {
                                                        ...state, loading: true, error: null };
                                                     
                                                      case Types.GENERATE_DASHBOARD_BY_USER_SUCCESS:
                                                      return {
                                                        ...state,
                                                        data: action.payload.data,
                                                         loading: false,
                                                   
                                                      };


                                                      case Types.TENANT_LIST_BY_RISK_REQUEST:

                                                        return {
                                                          ...state, loading: true, error: null };
                                                       
                                                        case Types.TENANT_LIST_BY_RISK_SUCCESS:
                                                        return {
                                                          ...state,
                                                      tenantListByRisk: action.payload.data,
                                                           loading: false,
                                                         // loggedUser: action.payload.data.user,
                                                        };

                                                        case Types.TENANT_SCORE_LIST_BY_RISK_REQUEST:

                                                          return {
                                                            ...state, loading: true, error: null };
                                                         
                                                          case Types.TENANT_SCORE_LIST_BY_RISK_SUCCESS:
                                                          return {
                                                            ...state,
                                                        tenantScoreListByRisk: action.payload.data,
                                                             loading: false,
                                                           // loggedUser: action.payload.data.user,
                                                          };
                                                         
                                                          case Types.FUND_DASHBOARD_GRID_VIEW_REQUEST:
         
                                                            return { ...state, loading: true, error: null };
                                                   
                                                            case Types.FUND_DASHBOARD_GRID_VIEW_SUCCESS:
                                                            return {
                                                              ...state,
                                                              fundDashboarGridView: action.payload.data,
                                                               loading: false,
                                                             
                                                            };


                                                            case Types.COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_REQUEST:
         
                                                            return { ...state, loading: true, error: null };
                                                   
                                                            case Types.COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_SUCCESS:
                                                            return {
                                                              ...state,
                                                              companyAdminFundDashboarGridView: action.payload.data,
                                                               loading: false,
                                                             
                                                            };

                                                            case Types.GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST:
         
                                                            return { ...state, loading: true, error: null };
                                                   
                                                            case Types.GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_SUCCESS:
                                                            return {
                                                              ...state,
                                                              fundPropertyGridViewDashboard: action.payload.data,
                                                               loading: false,
                                                             
                                                            };

                                                            case Types.PROPERTY_DASHBOARD_GRID_VIEW_REQUEST:
         
                                                              return { ...state, loading: true, error: null };
                                                     
                                                              case Types.PROPERTY_DASHBOARD_GRID_VIEW_SUCCESS:
                                                              return {
                                                                ...state,
                                                                propertyDashboardGridView: action.payload.data,
                                                                 loading: false,
                                                               
                                                              };

                                                              case Types.FUND_APEX_CHART_DATA_REQUEST:
          
                                                                return { ...state, loading: true, error: null };
                                                        
                                                                case Types.FUND_APEX_CHART_DATA_SUCCESS:
                                                                return {
                                                                  ...state,
                                                               fundApexChartData: action.payload.data,
                                                                   loading: false,
                                                                 
                                                                };
                                              
                                                        
                                                                case Types.FUND_PROPERTY_APEX_CHART_DATA_REQUEST:
          
                                                                  return { ...state, loading: true, error: null };
                                                          
                                                                  case Types.FUND_PROPERTY_APEX_CHART_DATA_SUCCESS:
                                                                  return {
                                                                    ...state,
                                                                 fundPropertyApexChartData: action.payload.data,
                                                                     loading: false,
                                                                   
                                                                  };
                                    
                                            
                                                                  case Types.PROPERTY_APEX_CHART_DATA_REQUEST:
          
                                                                    return { ...state, loading: true, error: null };
                                                            
                                                                    case Types.PROPERTY_APEX_CHART_DATA_SUCCESS:
                                                                    return {
                                                                      ...state,
                                                                   propertyApexChartData: action.payload.data,
                                                                       loading: false,
                                                                     
                                                                    };
                                      

                                                                    case Types.GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_REQUEST:
          
                                                                      return { ...state, loading: true, error: null };
                                                              
                                                                      case Types.GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_SUCCESS:
                                                                      return {
                                                                        ...state,
                                                                     tenantScoreGraphDataByPropertyIdAnduserId: action.payload.data,
                                                                         loading: false,
                                                                       
                                                                      };

                                                                      case Types.GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_REQUEST:
          
                                                                        return { ...state, loading: true, error: null };
                                                                
                                                                        case Types.GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_SUCCESS:
                                                                        return {
                                                                          ...state,
                                                                          tenantRiskCountByPropertyGridView: action.payload.data,
                                                                           loading: false,
                                                                         
                                                                        };
                                        

                                                                      case Types.GET_TENANT_BAR_GRAPH_DATA_REQUEST:
          
                                                                        return { ...state, loading: true, error: null };
                                                                
                                                                        case Types.GET_TENANT_BAR_GRAPH_DATA_SUCCESS:
                                                                        return {
                                                                          ...state,
                                                                          tenantBarGraphData: action.payload.data,
                                                                           loading: false,
                                                                         
                                                                        };
                                                                        

                                                                      case Types.GET_PROPERTY_TENANT_LINE_CHART_REQUEST:
          
                                                                        return { ...state, loading: true, error: null };
                                                                
                                                                        case Types.GET_PROPERTY_TENANT_LINE_CHART_SUCCESS:
                                                                        return {
                                                                          ...state,
                                                                          propertyTenantLineChart: action.payload.data,
                                                                           loading: false,
                                                                         
                                                                        };

                                                                        case Types.GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_REQUEST:
          
                                                                          return { ...state, loading: true, error: null };
                                                                  
                                                                          case Types.GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_SUCCESS:
                                                                          return {
                                                                            ...state,
                                                                            propertyTenantLineChartPagination: action.payload.data,
                                                                             loading: false,
                                                                           
                                                                          };
                                                                        
                                                                          case Types.GET_PROPERTY_TENANT_SEARCH_REQUEST:
          
                                                                            return { ...state, loading: true, error: null };
                                                                    
                                                                            case Types.GET_PROPERTY_TENANT_SEARCH_SUCCESS:
                                                                            return {
                                                                              ...state,
                                                                              propertyTenantSearch: action.payload.data,
                                                                               loading: false,
                                                                             
                                                                            };

                                                                            case Types.GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_REQUEST:
          
                                                                              return { ...state, loading: true, error: null };
                                                                      
                                                                              case Types.GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_SUCCESS:
                                                                              return {
                                                                                ...state,
                                                                                previousMonthTenantRiskCount: action.payload.data,
                                                                                 loading: false,
                                                                               
                                                                              };


                                                                            case Types.GET_TENANT_LIST_BASED_ON_LOW_RISK_REQUEST:
          
                                                                              return { ...state, loading: true, error: null };
                                                                      
                                                                              case Types.GET_TENANT_LIST_BASED_ON_LOW_RISK_SUCCESS:
                                                                              return {
                                                                                ...state,
                                                                                tenantListBasedOnLowRisk: action.payload.data,
                                                                                 loading: false,
                                                                               
                                                                              };

                                                                              case Types.GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_REQUEST:
          
                                                                                return { ...state, loading: true, error: null };
                                                                        
                                                                                case Types.GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_SUCCESS:
                                                                                return {
                                                                                  ...state,
                                                                                  tenantListBasedOnMediumLowRisk: action.payload.data,
                                                                                   loading: false,
                                                                                 
                                                                                };

                                                                                case Types.GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_REQUEST:
          
                                                                                  return { ...state, loading: true, error: null };
                                                                          
                                                                                  case Types.GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_SUCCESS:
                                                                                  return {
                                                                                    ...state,
                                                                                    tenantListBasedOnMediumHighRisk: action.payload.data,
                                                                                     loading: false,
                                                                                   
                                                                                  };

                                                                                  case Types.GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_REQUEST:
          
                                                                                    return { ...state, loading: true, error: null };
                                                                            
                                                                                    case Types.GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_SUCCESS:
                                                                                    return {
                                                                                      ...state,
                                                                                      tenantListBasedOnModerateHighRisk: action.payload.data,
                                                                                       loading: false,
                                                                                     
                                                                                    };
                                                                              
                                                                                    case Types.GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST:
          
                                                                                      return { ...state, loading: true, error: null };
                                                                              
                                                                                      case Types.GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST:
                                                                                      return {
                                                                                        ...state,
                                                                                        tenantListBasedOnHighRisk: action.payload.data,
                                                                                         loading: false,
                                                                                       
                                                                                      };
                                                                                    
                                                                          
                                                        

                                                              case Types.GET_BANKRUPTCY_DETAILS_OF_USER_REQUEST:
         
                                                                return { ...state, loading: true, error: null };
                                                       
                                                                case Types.GET_BANKRUPTCY_DETAILS_OF_USER_SUCCESS:
                                                                return {
                                                                  ...state,
                                                                  bankruptcyDetailsOfUser: action.payload.data,
                                                                   loading: false,
                                                                 
                                                                };
                                           
                                                                case Types.COMPANY_SEARCH_LIST_SAVE_REQUEST:
         
                                                                return { ...state, loading: true, error: null };
                                                       
                                                                case Types.COMPANY_SEARCH_LIST_SAVE_SUCCESS:
                                                                return {
                                                                  ...state,
                                                                  companySearchListSave: action.payload.data,
                                                                   loading: false,
                                                                 
                                                                };

                                                                case Types.PROPERTY_SEARCH_REQUEST:
         
                                                                return { ...state, loading: true, error: null };
                                                       
                                                                case Types.PROPERTY_SEARCH_SUCCESS:
                                                                return {
                                                                  ...state,
                                                                  propertySearch: action.payload.data,
                                                                   loading: false,
                                                                 
                                                                };

                                                                case Types.PROPERTY_SEARCH_LIST_SAVE_REQUEST:
         
                                                                  return { ...state, loading: true, error: null };
                                                         
                                                                  case Types.PROPERTY_SEARCH_LIST_SAVE_SUCCESS:
                                                                  return {
                                                                    ...state,
                                                                    propertySearchListSave: action.payload.data,
                                                                     loading: false,
                                                                   
                                                                  };
  
                                                                  case Types.COMPANY_SEARCH_REQUEST:

                                                                    return {
                                                                      ...state, loading: true, error: null };
                                                                   
                                                                    case Types.COMPANY_SEARCH_SUCCESS:
                                                                   
                                                                    return {
                                                         
                                                                      ...state,
                                                                      companySearch: action.payload.data,
                                                                       loading: false,
                                                                     // loggedUser: action.payload.data.user,
                                                                    };
                                                                    case Types.SEND_EMAIL_REQUEST:

                                                                      return {
                                                                        ...state, loading: true, error: null };
                                                                     
                                                                      case Types.SEND_EMAIL_SUCCESS:
                                                                     
                                                                      return {
                                                           
                                                                        ...state,
                                                                        sendEmail: action.payload.data,
                                                                         loading: false,
                                                                       // loggedUser: action.payload.data.user,
                                                                      };

                                                                      case Types.TOTAL_TENANT_COUNT_IN_RISK_COUNT_REQUEST:

                                                                        return {
                                                                          ...state, loading: true, error: null };
                                                                       
                                                                        case Types.TOTAL_TENANT_COUNT_IN_RISK_COUNT_SUCCESS:
                                                                       
                                                                        return {
                                                             
                                                                          ...state,
                                                                          tenantCountInRiskCount: action.payload.data,
                                                                           loading: false,
                                                                         // loggedUser: action.payload.data.user,
                                                                        };
                                                                        case Types.GENERATE_FUND_INITIAL_REPORT_REQUEST:

                                                                        return {
                                                                          ...state, loading: true, error: null };
                                                                       
                                                                        case Types.GENERATE_FUND_INITIAL_REPORT_SUCCESS:
                                                                       
                                                                        return {
                                                             
                                                                          ...state,
                                                                          generateFundInitialReport: action.payload.data,
                                                                           loading: false,
                                                                         // loggedUser: action.payload.data.user,
                                                                        };
                                                                      

                                                                        case Types.GENERATE_TENANT_INITIAL_REPORT_REQUEST:

                                                                          return {
                                                                            ...state, loading: true, error: null };
                                                                         
                                                                          case Types.GENERATE_TENANT_INITIAL_REPORT_SUCCESS:
                                                                         
                                                                          return {
                                                               
                                                                            ...state,
                                                                            generateTenantInitialReport: action.payload.data,
                                                                             loading: false,
                                                                           // loggedUser: action.payload.data.user,
                                                                          };
                                                                        
                                                  
                                                                          case Types.GENERATE_PROPERTY_INITIAL_REPORT_REQUEST:

                                                                            return {
                                                                              ...state, loading: true, error: null };
                                                                           
                                                                            case Types.GENERATE_PROPERTY_INITIAL_REPORT_SUCCESS:
                                                                           
                                                                            return {
                                                                 
                                                                              ...state,
                                                                              generatePropertyInitialReport: action.payload.data,
                                                                               loading: false,
                                                                             // loggedUser: action.payload.data.user,
                                                                            };
                                                                          
                                                                            case Types.GENERATE_SCORE_FOR_FUND_REQUEST:

                                                                              return {
                                                                                ...state, loading: true, error: null };
                                                                             
                                                                              case Types.GENERATE_SCORE_FOR_FUND_SUCCESS:
                                                                             
                                                                              return {
                                                                   
                                                                                ...state,
                                                                                generateFundScore: action.payload.data,
                                                                                 loading: false,
                                                                               // loggedUser: action.payload.data.user,
                                                                              };
                                                                            
                                                                              case Types.GET_COMMENTARY_FROM_COMPANY_REPORT_REQUEST:

                                                                                return {
                                                                                  ...state, loading: true, error: null };
                                                                               
                                                                                case Types.GET_COMMENTARY_FROM_COMPANY_REPORT_SUCCESS:
                                                                               
                                                                                return {
                                                                     
                                                                                  ...state,
                                                                                  commentaryFromCompanyReport: action.payload.data,
                                                                                   loading: false,
                                                                                 // loggedUser: action.payload.data.user,
                                                                                };

                                                                                case Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_REQUEST:

                                                                                return {
                                                                                  ...state, loading: true, error: null };
                                                                               
                                                                                case Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_SUCCESS:
                                                                               
                                                                                return {
                                                                     
                                                                                  ...state,
                                                                                  creditSafeBankruptcyReport: action.payload.data,
                                                                                   loading: false,
                                                                                 // loggedUser: action.payload.data.user,
                                                                                };
                                                                              
                                                                                case Types.CURRENT_MONTH_FUND_SCORE_BY_FUNDID_REQUEST:

                                                                                  return {
                                                                                    ...state, loading: true, error: null };
                                                                                 
                                                                                  case Types.CURRENT_MONTH_FUND_SCORE_BY_FUNDID_SUCCESS:
                                                                                 
                                                                                  return {
                                                                       
                                                                                    ...state,
                                                                                    currentMonthFundScore: action.payload.data,
                                                                                     loading: false,
                                                                                   // loggedUser: action.payload.data.user,
                                                                                  };

                                                                                  case Types.PROPERTY_CITY_AND_STATE_BY_PROPERTYID_REQUEST:

                                                                                    return {
                                                                                      ...state, loading: true, error: null };
                                                                                   
                                                                                    case Types.PROPERTY_CITY_AND_STATE_BY_PROPERTYID_SUCCESS:
                                                                                   
                                                                                    return {
                                                                         
                                                                                      ...state,
                                                                                      propertyCityAndState: action.payload.data,
                                                                                       loading: false,
                                                                                     // loggedUser: action.payload.data.user,
                                                                                    };

                                                                                    case Types.NOTIFICATION_LOG_LIST_REQUEST:

                                                                                      return {
                                                                                        ...state, loading: true, error: null };
                                                                                     
                                                                                      case Types.NOTIFICATION_LOG_LIST_SUCCESS:
                                                                                     
                                                                                      return {
                                                                           
                                                                                        ...state,
                                                                                        notificationLog: action.payload.data,
                                                                                         loading: false,
                                                                                       // loggedUser: action.payload.data.user,
                                                                                      };
                                                                              
                                                                                      case Types.TENANT_PROPERTY_DETAILS_REQUEST:

                                                                                        return {
                                                                                          ...state, loading: true, error: null };
                                                                                       
                                                                                        case Types.TENANT_PROPERTY_DETAILS_SUCCESS:
                                                                                       
                                                                                        return {
                                                                             
                                                                                          ...state,
                                                                                          tenantPropertyDetails: action.payload.data,
                                                                                           loading: false,
                                                                                         // loggedUser: action.payload.data.user,
                                                                                        };
                                                                                        case Types.CONTIFY_TOPICS_REQUEST:

                                                                                          return {
                                                                                            ...state, loading: true, error: null };
                                                                                         
                                                                                          case Types.CONTIFY_TOPICS_SUCCESS:
                                                                                         
                                                                                          return {
                                                                               
                                                                                            ...state,
                                                                                            contifyTopics: action.payload.data,
                                                                                             loading: false,
                                                                                           // loggedUser: action.payload.data.user,
                                                                                          };

                                                                                          case Types.GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST:

                                                                                            return {
                                                                                              ...state, loading: true, error: null };
                                                                                           
                                                                                            case Types.GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS:
                                                                                           
                                                                                            return {
                                                                                 
                                                                                              ...state,
                                                                                              contifyListUsingTopic: action.payload.data,
                                                                                               loading: false,
                                                                                             // loggedUser: action.payload.data.user,
                                                                                            };
                                                                                          
                                                                                            case Types.PARENT_SAVE_REQUEST:

                                                                                              return {
                                                                                                ...state, loading: true, error: null };
                                                                                             
                                                                                              case Types.PARENT_SAVE_SUCCESS:
                                                                                              return {
                                                                                                ...state,
                                                                                                parentSave: action.payload.data,
                                                                                                 loading: false,
                                                                                               // loggedUser: action.payload.data.user,
                                                                                              };
                                                                                              case Types.PARENT_LIST_BY_ORG_REQUEST:

                                                                                                return {
                                                                                                  ...state, loading: true, error: null };
                                                                                               
                                                                                                case Types.PARENT_LIST_BY_ORG_SUCCESS:
                                                                                                return {
                                                                                                  ...state,
                                                                                                  parentList: action.payload.data,
                                                                                                   loading: false,
                                                                                                 // loggedUser: action.payload.data.user,
                                                                                                };
                                                                                                case Types.PARENT_BY_ID_REQUEST:

                                                                                                  return {
                                                                                                    ...state, loading: true, error: null };
                                                                                                 
                                                                                                  case Types.PARENT_BY_ID_SUCCESS:
                                                                                                  return {
                                                                                                    ...state,
                                                                                                    parentEditObj: action.payload.data,
                                                                                                     loading: false,
                                                                                                   // loggedUser: action.payload.data.user,
                                                                                                  };

                                                                                                  case Types.PARENT_EDIT_REQUEST:

                                                                                                    return {
                                                                                                      ...state, loading: true, error: null };
                                                                                                   
                                                                                                    case Types.PARENT_EDIT_SUCCESS:
                                                                                                    return {
                                                                                                      ...state,
                                                                                                      parentEditObj: action.payload.data,
                                                                                                       loading: false,
                                                                                                     // loggedUser: action.payload.data.user,
                                                                                                    };

                                                                                                    case Types.PARENT_DELETE_REQUEST:

                                                                                                      return {
                                                                                                        ...state, loading: true, error: null };
                                                                                                     
                                                                                                      case Types.PARENT_DELETE_SUCCESS:
                                                                                                      return {
                                                                                                        ...state,
                                                                                                        parentDelete: action.payload.data,
                                                                                                         loading: false,
                                                                                                       // loggedUser: action.payload.data.user,
                                                                                                      };

                                                                                                      case Types.PARENT_BY_ORG_REQUEST:
                                                                                                        return { ...state, loading: true, error: null };
                                                                                               
                                                                                                        case Types.PARENT_BY_ORG_SUCCESS:
                                                                                                        return {
                                                                                                          ...state,
                                                                                                          parentByOrg: action.payload.data,
                                                                                                           loading: false,
                                                                                                         
                                                                                                        };
                                                                                                        case Types.UNLINK_PARENT_REQUEST:

                                                                                                          return {
                                                                                                            ...state, loading: true, error: null };
                                                                                                         
                                                                                                          case Types.UNLINK_PARENT_SUCCESS:
                                                                                                          return {
                                                                                                            ...state,
                                                                                                            unlinkParent: action.payload.data,
                                                                                                             loading: false,
                                                                                                           // loggedUser: action.payload.data.user,
                                                                                                          };

                                                                                                          case Types.GENERATE_PARENT_INITIAL_REPORT_REQUEST:

                                                                                                            return {
                                                                                                              ...state, loading: true, error: null };
                                                                                                           
                                                                                                            case Types.GENERATE_PARENT_INITIAL_REPORT_SUCCESS:
                                                                                                           
                                                                                                            return {
                                                                                                 
                                                                                                              ...state,
                                                                                                              generateParentInitialReport: action.payload.data,
                                                                                                               loading: false,
                                                                                                             // loggedUser: action.payload.data.user,
                                                                                                            };

                                                                                                            case Types.PARENT_CREDIT_SCORE_REQUEST:
                                                                                                              return { ...state, loading: true, error: null };
                                                                                                             
                                                                                                      
                                                                                                              case Types.PARENT_CREDIT_SCORE_SUCCESS:
                                                                                                              return {
                                                                                                                ...state,
                                                                                                                parentCreditScoreData: action.payload.data,
                                                                                                                 loading: false,
                                                                                                               // loggedUser: action.payload.data.user,
                                                                                                              };

                                                                                                              case Types.PARENT_DEROGATORY_SCORE_COUNT_REQUEST:
                                                                                                                return { ...state, loading: true, error: null };
                                                                                                       
                                                                                                                case Types.PARENT_DEROGATORY_SCORE_COUNT_SUCCESS:
                                                                                                             
                                                                                                                return {
                                                                                                                  ...state,
                                                                                                                   parentDerogatoryScoreCount: action.payload.data,
                                                                                                                   loading: false,
                                                                                                                 
                                                                                                                };

                                                                                                                case Types.PARENT_MONTH_PIRS_REQUEST:

                                                                                                                  return {
                                                                                                                    ...state, loading: true, error: null };
                                                                                                                 
                                                                                                                  case Types.PARENT_MONTH_PIRS_SUCCESS:
                                                                                                                  return {
                                                                                                                    ...state,
                                                                                                                    parentPIRSMap: action.payload.data,
                                                                                                                     loading: false,
                                                                                                                   // loggedUser: action.payload.data.user,
                                                                                                                  };

                      case Types.MONITORED_PARENTS_NEWS_REQUEST:
                      return {
                      ...state, loading: true, error: null };
                      case Types.MONITORED_PARENTS_NEWS_SUCCESS:
                      return {
                      ...state,
                      tmParentNotificationList: action.payload.data,
                      loading: false,
                      // loggedUser: action.payload.data.user,
                      };

                      case Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_REQUEST:

                      return {
                      ...state, loading: true, error: null };
                      case Types.CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_SUCCESS:
                                                                               
                      return {
                                                                     
                      ...state,
                      creditSafeParentBankruptcyReport: action.payload.data,
                      loading: false,
                      
                     };

                     case Types.GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST:

                      return {
                        ...state, loading: true, error: null };
                     
                      case Types.GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_SUCCESS:
                     
                      return {
           
                        ...state,
                        parentContifyListUsingTopic: action.payload.data,
                         loading: false,
                       // loggedUser: action.payload.data.user,
                      };

                      case Types.ACTIVE_UCC_DETAILS_BY_PARENT_ID_REQUEST:

          return {
          ...state, loading: true, error: null };
          case Types.ACTIVE_UCC_DETAILS_BY_PARENT_ID_SUCCESS:
          return {
          ...state,
          parentUccList: action.payload.data,
          loading: false,
          // loggedUser: action.payload.data.user,
          };

          case Types.ACTIVE_LIENS_DETAILS_BY_PARENT_ID_REQUEST:

            return {
              ...state, loading: true, error: null };
           
            case Types.ACTIVE_LIENS_DETAILS_BY_PARENT_ID_SUCCESS:
            return {
              ...state,
              parentLiensList: action.payload.data,
               loading: false,
             // loggedUser: action.payload.data.user,
            };
                                                                              
            case Types.ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_REQUEST:

              return {
              ...state, loading: true, error: null };
              case Types.ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_SUCCESS:
              return {
              ...state,
              parentJudgmentList: action.payload.data,
              loading: false,
              // loggedUser: action.payload.data.user,
              };

              case Types.ACTIVE_SUIT_DETAILS_BY_PARENT_ID_REQUEST:
        return {
        ...state, loading: true, error: null };
        case Types.ACTIVE_SUIT_DETAILS_BY_PARENT_ID_SUCCESS:
        return {
        ...state,
        parentSuitList: action.payload.data,
        loading: false,
        // loggedUser: action.payload.data.user,
        };
case Types.NOTIFICATION_LOG_SEARCH_LIST_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.NOTIFICATION_LOG_SEARCH_LIST_SUCCESS:
                                                                                     
return {
                                                                           
...state,
notificationLogSearchList: action.payload.data,
loading: false,
};

case Types.NEWS_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.NEWS_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
newsFtl: action.payload.data,
loading: false,
};

case Types.DEROGATORY_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.DEROGATORY_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
derogatoryFtl: action.payload.data,
loading: false,
};

case Types.CREDIT_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.CREDIT_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
creditFtl: action.payload.data,
loading: false,
};

case Types.TENANT_SCORE_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.TENANT_SCORE_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
tenantScoreFtl: action.payload.data,
loading: false,
};
case Types.SUITS_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.SUITS_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
suitsFtl: action.payload.data,
loading: false,
};

case Types.PIRS_FTL_REQUEST:

return {
  ...state, loading: true, error: null };
                                                                                     
  case Types.PIRS_FTL_SUCCESS:
                                                                                     
return {
                                                                           
...state,
pirsFtl: action.payload.data,
loading: false,
};

case Types.ACTIVE_STATUS_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.ACTIVE_STATUS_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
activeStatusFtl: action.payload.data,
loading: false,
};

case Types.PROPERTY_OVERALL_SCORE_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.PROPERTY_OVERALL_SCORE_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
propertyOverallScoreFtl: action.payload.data,
loading: false,
};

case Types.FUND_OVERALL_SCORE_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.FUND_OVERALL_SCORE_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
fundOverallScoreFtl: action.payload.data,
loading: false,
};

case Types.CAUTIONARY_UCC_MONTHLY_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.CAUTIONARY_UCC_MONTHLY_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
cautionaryUccMonthlyFtl: action.payload.data,
loading: false,
};

case Types.CAUTIONARY_UCC_INITIAL_POPULATE_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.CAUTIONARY_UCC_INITIAL_POPULATE_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
cautionaryUccInitialPopulateFtl: action.payload.data,
loading: false,
};

case Types.LIENS_MONTHLY_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.LIENS_MONTHLY_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
liensMonthlyFtl: action.payload.data,
loading: false,
};

case Types.LIENS_INITIAL_POPULATE_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.LIENS_INITIAL_POPULATE_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
liensInitialPopulateFtl: action.payload.data,
loading: false,
};

case Types.JUDGEMENT_MONTHLY_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.JUDGEMENT_MONTHLY_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
judgementMonthlyFtl: action.payload.data,
loading: false,
};

case Types.JUDGEMENT_INITIAL_POPULATE_FTL_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.JUDGEMENT_INITIAL_POPULATE_FTL_SUCCESS:                                                                                     
return {
                                                                           
...state,
judgementInitialPopulateFtl: action.payload.data,
loading: false,
};

case Types.TENANT_COMMENTARY_SAVE_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.TENANT_COMMENTARY_SAVE_SUCCESS:                                                                                     
return {
                                                                           
...state,
commentary: action.payload.data,
loading: false,
};

case Types.COMMENTARY_LIST_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.COMMENTARY_LIST_SUCCESS:                                                                                     
return {
                                                                           
...state,
commentaryList: action.payload.data,
loading: false,
};

case Types.TENANT_REPORTS_DETAILS_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.TENANT_REPORTS_DETAILS_SUCCESS:                                                                                     
return {
                                                                           
...state,
reportsDetails: action.payload.data,
loading: false,
};

case Types.GENERATE_TENANT_REPORT_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.GENERATE_TENANT_REPORT_SUCCESS:                                                                                     
return {
                                                                           
...state,
generateReports: action.payload.data,
loading: false,
};

case Types.GENERATE_MONTHLY_PDF_REPORT_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.GENERATE_MONTHLY_PDF_REPORT_SUCCESS:                                                                                     
return {
                                                                           
...state,
generateMonthlyPdfReport: action.payload.data,
loading: false,
};

case Types.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_SUCCESS:                                                                                     
return {
                                                                           
...state,
generateMonthlyPropertyDetailsPdfReport: action.payload.data,
loading: false,
};

case Types.RISK_LIST_GRAPH_MONTHLY_REPORT_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.RISK_LIST_GRAPH_MONTHLY_REPORT_SUCCESS:                                                                                     
return {
                                                                           
...state,
riskListGraphMonthlyReport: action.payload.data,
loading: false,
};

case Types.GRAPH_MONTHLY_SAVE_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.GRAPH_MONTHLY_SAVE_SUCCESS:                                                                                     
return {
                                                                           
...state,
graphSaveMonthlyReport: action.payload.data,
loading: false,
};

case Types.GENERATE_MONTHLY_FUND_REPORT_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.GENERATE_MONTHLY_FUND_REPORT_SUCCESS:                                                                                     
return {
                                                                           
...state,
monthlyFundReport: action.payload.data,
loading: false,
};

case Types.CLEAN_UP_SCORE_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.CLEAN_UP_SCORE_SUCCESS:                                                                                     
return {
                                                                           
...state,
cleanUpScore: action.payload.data,
loading: false,
};

case Types.CLEAN_UP_NEWS_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.CLEAN_UP_NEWS_SUCCESS:                                                                                     
return {
                                                                           
...state,
cleanUpNews: action.payload.data,
loading: false,
};

case Types.TENANT_NEWS_SETTINGS_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.TENANT_NEWS_SETTINGS_SUCCESS:                                                                                     
return {
                                                                           
...state,
saveTenantNewsSettings: action.payload.data,
loading: false,
};

case Types.EDIT_TENANT_NEWS_SETTINGS_REQUEST:
return {
  ...state, loading: true, error: null };                                                                                    
  case Types.EDIT_TENANT_NEWS_SETTINGS_SUCCESS:                                                                                     
return {
                                                                           
...state,
editTenantNewsSettings: action.payload.data,
loading: false,
};

case Types.TENANT_NEWS_SETTINGS_BY_ID_REQUEST:
  return {
  ...state, loading: true, error: null };
  case Types. TENANT_NEWS_SETTINGS_BY_ID_SUCCESS:
  return {
  ...state,
  tenantNewsSettingsById: action.payload.data,
  loading: false,
  // loggedUser: action.payload.data.user,
  };
case Types.PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_REQUEST:
return { ...state, loading: true, error: null };
case Types.PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_SUCCESS:
return {
...state,
propertyDashboardGridViewByFundId: action.payload.data,
loading: false,
     
    };  
                                                 default:
                                                    return state;
                                                };
                                               

                                               
  }
  