
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
  import moment from 'moment';
  import CurrencyFormat from 'react-currency-format';

  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
  import IconButton from '@material-ui/core/IconButton';
  import Collapse from '@material-ui/core/Collapse';
  import clsx from 'clsx';
  import CardActions from '@material-ui/core/CardActions';
  import CardContent from '@material-ui/core/CardContent';
  import Typography from '@material-ui/core/Typography';
  import ExpandLessIcon from '@material-ui/icons/ExpandLess';
  import Box from '@material-ui/core/Box';

  const useStyles = makeStyles((theme) => ({
  rows:{
    borderBottom: "1px solid black",
  }
  
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];




const Civilsuitsreport = ({tmActiveSuitDetailsRequest,suitList,suitsScore,civilSuitsScoreCSS,tenantId,filedDate,filingType,filingNumber,amount,plaintiff,month1,year}) => {

  const [input, setInput] = React.useState('');
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



const [name, setName] = React.useState("");
const [legalName, setLegalName] = React.useState("");
const [dba,setDba]=React.useState("");
const [feinNumber,setFeinNumber]=React.useState("");
const [address1,setAddress1]=React.useState("");
const [address2,setAddress2]=React.useState("");
const [notificationEmail,setNotificationEmail]=React.useState("");
const [numberOfLocations,setNumberOfLocations]=React.useState("");
const [useOfSpace,setuseOfSpace]=React.useState("");
const [countryId,setcountryId]=React.useState("");
const [propertyName,setpropertyName]=React.useState("");
const [city,setCity]=React.useState("");
const [state,setState]=React.useState("");
const [annualRent,setAnnualRent]=React.useState("");
const [zip,setZip]=React.useState("");
const [leaseStartDate,setLeaseStartDate]=React.useState("");
const [leaseEndDate,setLeaseEndDate]=React.useState("");
const [ phnNo1,setPhnNo1]=React.useState("");
const [ phnNo2,setphnNo2]=React.useState("");
const [contifyId,setContifyId]=React.useState("");
const [creditSafeId,setCreditSafeId]=React.useState("");
const [corteraId,setCorteraId]=React.useState("");
const [bankoId,setBankoId]=React.useState("");
// const [tenantId,setTenantId] = React.useState(location.state.tenantId);
// const [suitsScore,setSuitsScore] = React.useState(location.state.suitsScore);

const [dataStatus, setDataStatus] = React.useState(false);
const [month,setMonth] = React.useState(moment().subtract(1, 'months').format("MMM").toLowerCase());
// const [year,setYear] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());

const [expanded, setExpanded] = React.useState(false);
const [showPopup, setShowPopup] = React.useState(false);
const [showExpandMoreIcon,setShowExpandMoreIcon]=React.useState(false);
const [showExpandLessIcon,setShowExpandLessIcon]=React.useState(false);
const [selectedIndex, setSelectedIndex] = useState(0);

React.useEffect(() => {
  setShowExpandMoreIcon(true);
  if(!dataStatus){
    getActiveSuitsList(tenantId,month1,year);
   
    console.log("suit"+suitList)
  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);
  
  }, [dataStatus]);

  const getActiveSuitsList=async(tenantId,month,year)=>{
    await tmActiveSuitDetailsRequest(tenantId,month,year)

    
      }

      const handleExpandMoreClick = (id,index) => {
        setSelectedIndex(index);
        setShowPopup(true);
        setShowExpandMoreIcon(false);
        setShowExpandLessIcon(true);
      };

      const handleExpandLessClick = (id,index) => {
        setSelectedIndex(index);
        setShowPopup(false);
        setShowExpandLessIcon(false);
        setShowExpandMoreIcon(true);
      };

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
         
              <h2 style={{fontWeight:"bold",fontSize:'35px',paddingLeft:"50px",color: megaColor}} className={classes.cardIconTitle}>
             <b>Civil Suits Report</b> 
              </h2> 
        
          <div>
         <GridContainer>
             <GridItem xs={12} sm={12} md={12}>
          
             <h3 style={{fontWeight:"bold",fontSize:'28px',paddingLeft:"50px"}}><b>Civil Suits Score</b>&nbsp;&nbsp;<Button style={{color:"#FFFFFF",backgroundColor: civilSuitsScoreCSS,fontSize:'20px'}}>{suitsScore}</Button></h3> 

             </GridItem>
           
           </GridContainer>
           </div>
   {/* <h4><b>New Civil Suits</b></h4> */}
   <h5 style={{fontWeight:"bold",fontSize:'30px',paddingLeft:"50px"}}><b>Active Suits</b></h5>
           
   
   <GridContainer>

              <GridItem xs={2} >
                 {/* <label><b>Date Filed</b></label> */}
                 <label><strong><h5 style={{fontWeight:"bold",color:megaColor,paddingLeft:"50px"}}><b>Date Filed</b></h5></strong></label>
                </GridItem>

                <GridItem xs={2} >
                <label><h5 style={{fontWeight:"bold",color:megaColor}}><b>Filing Type</b></h5></label>
                </GridItem>

               
                <GridItem xs={2} >
                <label><h5 style={{fontWeight:"bold",color:megaColor}}><b>Case Number</b></h5></label>
                </GridItem>


                <GridItem xs={1} style={{textAlign:"right"}}>
                <label><h5 style={{fontWeight:"bold",color:megaColor}}><b>Amount</b></h5></label>
                </GridItem>

                <GridItem xs={3} >
                <label><h5 style={{fontWeight:"bold",color:megaColor,paddingRight:"30px"}}><b>Plaintiff</b></h5></label>
                </GridItem>
             
                <GridItem xs={2}>

                </GridItem>
              

</GridContainer>
{ suitList.map((civil,i) => (<GridContainer >
 <GridItem xs={12} sm={2}>


            
               <b style={{fontWeight:"bold",paddingLeft:"50px"}}>{Moment(civil.filedDate).format('MM-DD-YYYY')}<br/><br/></b>        
  

   </GridItem>
  
  <GridItem xs={12} sm={2}>
 
            <b>{civil.filingType}<br/><br/></b>
 
  </GridItem>

  <GridItem xs={12} sm={2}>

            <b>{civil.filingNumber}<br/><br/></b> 
   
  </GridItem>
  <GridItem xs={12} sm={1} style={{textAlign:"right"}}>

            <b><CurrencyFormat value={civil.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/><br/></b>

  </GridItem>

  <GridItem xs={12} sm={3}>

            <b style={{fontWeight:"bold",paddingRight:"30px"}}>{civil.plaintiff}<br/><br/></b>

  </GridItem>
  <GridItem xs={12} sm={2}>
           
           
            <div style={{display:"flex",justifyContent:"center"}}>
          {showExpandMoreIcon&&  <ExpandMoreIcon

onClick={e => handleExpandMoreClick(e.target.id, i)}
      // onClick={handleExpandMoreClick(e,i)}
    
    >
    
    </ExpandMoreIcon>}

    {showExpandLessIcon&& 
  <ExpandLessIcon
  onClick={e => handleExpandLessClick(e.target.id, i)}
// onClick={handleExpandLessClick}

>

</ExpandLessIcon>}

  </div>

   {/* {showExpandLessIcon&&  */}
   {/* <div style={{display:"flex",justifyContent:"center"}}>
   <ExpandLessIcon
className={clsx(classes.expand, {
[classes.expandOpen]: expanded,
})}
onClick={handleExpandMoreClick}
aria-expanded={expanded}
aria-label="show more"
>

</ExpandLessIcon>
<Collapse in={expanded} timeout="auto" unmountOnExit>

</Collapse>
</div> */}
</GridItem>
{showPopup&&selectedIndex == i && <GridItem xs={12} sm={12} style={{paddingLeft:"50px",paddingRight:"50px"}}>
<Box borderTop={0} borderLeft={1}>
  
  <div >
    <h5 style={{textAlign:"center",fontWeight:"bold",fontSize:'18px'}}>Court Information</h5>
  </div>
  <hr style={{borderColor:"#D3D3D3"}}/>

  <div style={{display:"flex",justifyContent:"space-around"}}>

<div style={{display:"flex", justifyContent:"center",width:"100%"}}>
  <table style={{width:"100%",borderCollapse:"collapse"}}>

  <tr style={{width:"100%",borderBottom:"1px solid #D3D3D3"}}>
      <td style={{width:"50%",display:"flex",justifyContent:"left"}}>Name</td>
      <td style={{width:"50%"}}>{civil.courtName}</td>
      <td >Action Type</td>
      <td>{civil.actionType}</td>
      </tr>
     
      <tr style={{width:"100%",borderBottom:"1px solid #D3D3D3"}}>
      <td style={{width:"50%",display:"flex",justifyContent:"left"}}>Address</td>
      <td style={{width:"50%"}}>{civil.courtAddress.simpleValue}</td>
      <td>City, state</td>
      <td>{civil.courtAddress.city},{civil.courtAddress.province}</td>
      </tr>
      <tr style={{width:"100%",borderBottom:"1px solid #D3D3D3"}}>
      <td style={{width:"50%",display:"flex",justifyContent:"left"}}>ZIP</td>
      <td style={{width:"50%"}}>{civil.courtAddress.postalCode}</td>
      <td>phone</td>
      <td>{civil.courtTelephone}</td>
    </tr>
  </table>
  </div>

  </div>

  </Box>
  <br></br>
     </GridItem>}
  
</GridContainer>))}


<br></br>
<br></br>


              <Clearfix />
          
        </GridItem>
       
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

Civilsuitsreport.propTypes = {
    tenantList: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.string,
      }),
    ).isRequired,
  
    loading: PropTypes.bool.isRequired,
    tmActiveSuitDetailsRequest: PropTypes.func.isRequired,

    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    suitList: state.tenantmonitoring.suitList,
    loading: state.tenantmonitoring.loading,
    //suitsScore:state.tenantmonitoring.suitsScore,
 
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Civilsuitsreport);
