
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import moment from 'moment';
import Moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from '@material-ui/core/NativeSelect';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  }
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const MonitoredTenantsByProperty = ({
    user,monitoredTenantsByProperty, tmMonitoredTenantsByPropertyRequest, 
  }) => {
   
    
    const [dataList, setDataList] = React.useState(monitoredTenantsByProperty);
    const [dataStatus, setDataStatus] = React.useState(false);
    const [userId, setUserId] = React.useState(user.id);
    const [selectedPeriod, setSelectedPeriod] = React.useState("");

    const todayNow = moment().format("D MMM YYYY"); 
  
    const month = moment().subtract(1, 'months').format("D MMM YYYY");


    useEffect(() => {
      
    if(!dataStatus){
      const dateTo = moment().format("D MMM YYYY"); 
      const  dateFrom= moment().subtract(1, 'years').format("D MMM YYYY");
      getTmMonitoredTenantsByProperty(userId,dateFrom,dateTo);

      setSelectedPeriod(month +"    -     "+ todayNow)
    }
      setTimeout(()=>{    
        setDataStatus(true);
       },5000);
   
    }, []);

    const getTmMonitoredTenantsByProperty= async (userId,dateFrom,dateTo) => {
     
      await tmMonitoredTenantsByPropertyRequest(userId,dateFrom,dateTo);
 
      };
      const handleChange = (event) => {
   
        const todayNow = moment().format("D MMM YYYY"); 
        const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
        const month = moment().subtract(1, 'months').format("D MMM YYYY");
        const quarter = moment().subtract(3, 'months').format("D MMM YYYY");
        const sixMonths = moment().subtract(6, 'months').format("D MMM YYYY");
        const year = moment().subtract(1, 'years').format("D MMM YYYY");
      
       // const todayNow = moment().format("yyyy-MM-dd"); 
       // const sevenDays = moment().subtract(7, 'days').format("yyyy-MM-dd"); 
       // const month = moment().subtract(1, 'months').format("yyyy-MM-dd");
       // const quarter = moment().subtract(3, 'months').format("yyyy-MM-dd");
       // const sixMonths = moment().subtract(6, 'months').format("yyyy-MM-dd");
       // const year = moment().subtract(1, 'years').format("yyyy-MM-dd");
       
         switch (event.target.value) {
           case "7 days":
             setSelectedPeriod(sevenDays +"    -    "+ todayNow)
             getTmMonitoredTenantsByProperty(userId,sevenDays,todayNow);
             return ;
             break;
           case "Month":
            setSelectedPeriod(month +"    -     "+ todayNow)
            getTmMonitoredTenantsByProperty(userId,month,todayNow);
             return <button>Login</button>;
             break;
             case "Quarter":
             setSelectedPeriod(quarter +"    -    "+ todayNow)
             getTmMonitoredTenantsByProperty(userId,quarter,todayNow);
             return ;
             break;
           case "Six Months":
            setSelectedPeriod(sixMonths +"    -    "+ todayNow)
            getTmMonitoredTenantsByProperty(userId,sixMonths,todayNow);
             return ;
             break;  
           case "Year":
            setSelectedPeriod(year +"    -    "+ todayNow)
            getTmMonitoredTenantsByProperty(userId,year,todayNow);
             return ;
             break;  
           default:
             return null;
         }
        
      
       
       } 

// const getColumnWidth = (data, accessor, headerText) => {
//   if (typeof accessor === 'string' || accessor instanceof String) {
//     accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
//   }
//   const minWidth = 200;
//   const magicSpacing = 10;
//   const cellLength = Math.max(
//     ...data.map(row => (`${accessor(row)}` || '').length),
//     headerText.length,
//   );
//   return Math.min(minWidth, cellLength * magicSpacing);
// };

// const getColumnWidthNew = (rows, accessor, headerText) => {
//   const maxWidth = 400
//   const magicSpacing = 10
//   const cellLength = Math.max(
//     ...rows.map(row => (`${row[accessor]}` || '').length),
//     headerText.length,
//   )
//   return Math.min(maxWidth, cellLength * magicSpacing)
// }



  
  const classes = useStyles();
 if(dataStatus){ 
  return (
    
    <GridContainer>
     

                 <GridItem xs={4} sm={4}></GridItem>
     
      <GridItem xs={12}>
        <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} i icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b>Monitored Tenants</b></h4>
          </CardHeader>

          {/* <GridContainer  className={classes.gridContainer}>
      
          <GridItem xs={6} sm={8}  align="right" >
    
    <FormControl className={classes.selectFormControl}>
    <InputLabel 

      htmlFor="simple-select"
      className={classes.selectLabel}
    >
      Show Last&nbsp;&nbsp;&nbsp;&nbsp;
    </InputLabel>
    <Select
      MenuProps={{
        className: classes.selectMenu
      }}
      classes={{
        select: classes.select
      }}
      defaultValue={'Month'}
      onChange={handleChange}
      inputProps={{
        name: "simpleSelect",
        id: "simple-select"
      }}
    >
      <MenuItem
        disabled
        classes={{
          root: classes.selectMenuItem
        }}
        value="Show Last"
      >
        Show Last&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value="7 days"
      >
        7 days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value="Month"
      >
        Month&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value="Quarter"
      >
        Quarter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value="Six Months"
      >
    Six Months&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value="Year"
      >
  Year&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
    </Select>
  </FormControl>
</GridItem>
    

    
<GridItem xs={4} sm={4} >

   
<h5 style={{paddingTop:"10px"}}>  <b><font color="#659EC7">{selectedPeriod}</font></b> 
</h5> 
</GridItem>
  
</GridContainer> */}
          <CardBody>
            <MTTable
              columns={[
                
              {
                Header: "Tenant",
                accessor: "tenantName",
                //width: getColumnWidth(tmList, 'tenantName', 'Tenant'),
                Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification-am/', state :{tenantId : row.original.tenantId, contifyCompanyId:row.original.tenantContifyId, naics:row.original.naics, accountNumber : row.original.bankoAccountNumber,tenantName:row.original.tenantName,propertyName:row.original.propertyName,propertyId:row.original.propertyId,fundName:row.original.fundName,colorCode:row.original.colorCode,currentStatus:row.original.scoreStatus,organisationId:row.original.organizationId} } }>{row.original.tenantName}</Link>
             
              
              ),
             
                // Cell: ({ cell,row ,
                //   column: { tenantId } }) => (
                
                // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
             // }
             //width: getColumnWidthNew(tmListByProperty, 'accessor', 'Tenant'),
            },
            {
              Header: "Tracker Score",
              accessor: "overAllTrackerScore",
              align:'center',
              color: megaColor,
              width: 50,  
            },
                
              // {
              //   Header: "Entity Name",
              //   accessor: "entity",
              //   width: 50,
              // },
              {
                Header: "Teant Id",
                accessor: "tenantId",
                width: 50,
                
              },
              {
                Header: "ContifyId",
                accessor: "tenantContifyId",
                width: 50,
                
              },
                {
                  Header: "Entity Type",
                  accessor: "entityType",
                  width: 50,
                },
                {
                  Header: "Parent",
                  accessor: "entity",
                  // Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/parentNotification-am/', state :{parentId : row.original.parentId, contifyCompanyId:row.original.tenantContifyId, naics:row.original.naics, accountNumber : row.original.bankoAccountNumber,parentName:row.original.parentName,propertyName:row.original.propertyName,propertyId:row.original.propertyId,fundName:row.original.fundName,colorCode:row.original.colorCode,currentStatus:row.original.scoreStatus} } }>{row.original.entity}</Link>
              
                  // ),
                },
                // {
                //   Header: "AccountNo",
                //   accessor: "bankoAccountNumber",
                //   width: 50,
                // },
                {
                  Header: "Property Name",
                  accessor: "propertyName",
                  width: 50,
                 // width: getColumnWidthNew(tmListByProperty, 'property', 'Property'),
                },
                
                {
                  Header: "Property Category",
                  accessor: "propertyCategory",
                  
                  isVisible: false,
                  width: 50,
                },

                {
                  Header: "naics",
                  accessor: "naics",
                  
                  isVisible: false,
                  width: 50,
                }
              ]}
              data={monitoredTenantsByProperty}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={3000} //3 secs
  /></div>
  )
}
}


MonitoredTenantsByProperty.propTypes = {
  monitoredTenantsByProperty: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmMonitoredTenantsByPropertyRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  monitoredTenantsByProperty: state.tenantmonitoring.monitoredTenantsByProperty,
  loading: state.tenantmonitoring.loading,
  tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonitoredTenantsByProperty);




