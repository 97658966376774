import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CompanyTable from "views/Company/CompanyTable.js";
import { useHistory } from 'react-router-dom';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfigureReport from "./ConfigureReport";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import { NavigateBeforeSharp } from "@material-ui/icons";
import ViewCompanyDetails from "./ViewCompanyDetails";
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  }
};



const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const CompanyList = ({
    companyList, tmCompanyListRequest, tmCompanyListByOrgRequest,tmCompanyDeleteRequest,companyDelete,tmAllTenantsTrailingScoreRequest,tenantsTrailingScoreList,tmGenerateTenantReportRequest,generateReports
  }) => {
    const history = useHistory();
    const location = useLocation();
    const [dataList, setDataList] = React.useState(companyList);
    const [messageobj, setMessageobj] = React.useState("");
    const [companyId, setCompanyId ] = React.useState("");
    // const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    const [alert1, setAlert1] = React.useState(false);
    const [openConfigureApiAccess, setOpenConfigureApiAccess] = React.useState(false);
    const [organisationName, setOrganisationName] = React.useState("");
    const [organisationId, setOrganisationId] = React.useState("");


    const[name,setName] = React.useState("");
    const[address1,setAddress] = React.useState("");
    const[city,setCity] = React.useState("");
    const[state,setState] = React.useState("");
    const[mobileNumber,setMobileNumber] = React.useState("");
    const[email,setEmail] = React.useState("");
    const[postalCode,setPostalCode] = React.useState("");
    const[address2,setAddress2] = React.useState("");

    const[country,setCountry] = React.useState("");

    const [generateAlert,setGenerateAlert] = React.useState(false);
    const [generateAlertStarted,setGenerateAlertStarted] = React.useState(false);
    const [generateAlertFailed,setGenerateAlertFailed] = React.useState(false);
    const [loader,setLoader]= React.useState(false);

   
    const [showViewCompanyDetails, setShowViewCompanyDetails] = React.useState(false);


    const hideGenerateAlertFailed = () => {
      setGenerateAlertFailed(false);
    }
    const hideGenerateAlertStarted = () => {
      setGenerateAlertStarted(false);
    }

    const hideGenerateAlert = () => {
      setGenerateAlert(false);
    }

    const getTmGenarateTenantReport = async (tenantId,tloId,creditSafeId) => {
      await tmGenerateTenantReportRequest(tenantId,tloId,creditSafeId);

    }
    // const [alert2,setAlert2] = React.useState(false);
  
    // const hideAlert2 = () => {
    //   setAlert2(false);
    // }
    useEffect(() => {
      
      getCompanyList();
      if(location.state){
        setMessageobj(location.state.message);
      }
     
     
      }, [messageobj]);

    const deleteCompany = async (companyId) => {
      await tmCompanyDeleteRequest(companyId);
      history.push({ 
 
        pathname: '/admin/companylist',
        // state:{ message:"Fund Saved Successfully" }
       });
      

      };



      const getCompanyList = async () => {
        await tmCompanyListByOrgRequest();
        };

        const handleClickOpenConfigureApiAccess= (orgId,orgName) => {
   setOrganisationId(orgId)
   setOrganisationName(orgName);
      setOpenConfigureApiAccess(true);
         
        };

        const handleCloseConfigureApiAccess=()=>{
          setOpenConfigureApiAccess(false);
        }

        const getViewCompanyDetails=(organisationId,name,
          email,address2,country,mobileNumber,city,state,postalCode,address1,
          )=>{
            setShowViewCompanyDetails(true);
      
            setOrganisationId(organisationId);
            setName(name);
          
             setEmail(email);
             setAddress2(address2);
             setCountry(country);
             
           
             setMobileNumber(mobileNumber);
           
             setCity(city);
             setState(state);
             setPostalCode(postalCode);
             setAddress(address1);
       
      }
      
    
        const submit = (companyId) => {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                
                <div className='custom-ui' 
                style={{
                  backgroundColor: "#81A594 ",
                 fontWeight:"bold",
                  color:'#fff',
                
                  textAlign: "center",
                 // alignItems: "center",
                  paddingTop: "20px",
                  height: "150px",
                  width: "300px",
                  
                  }}>
                    
                  <h1 >Are you sure?</h1>
                  <p fontWeight="bold">You want to Delete this Company?</p>
                 
                  <button  backgroundColor= "#000"
                     onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                  <button 
                     
                    onClick={() => {
                      deleteCompany(companyId)
                      onClose();
                      
                   
                    }}
                  >
                  &nbsp; &nbsp; Yes &nbsp;&nbsp;
                  </button>
                  </div>
            
             

              );
            }
          });
        }
// // 
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}
const [open, setOpen] = React.useState(false);
    
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


const handleCloseViewCompanyDetails = () => {
  setShowViewCompanyDetails(false);
};

const tloAndCreditsafeReport=(tenantId,tloId,creditSafeId)=>{
  getTmGenarateTenantReport(tenantId,tloId,creditSafeId)
  setGenerateAlertStarted(true);
  setGenerateAlert(false);
  setGenerateAlertFailed(false);

  setTimeout(() => {
    console.log("generateReports"+generateReports)
    if(generateReports===true){
      setGenerateAlert(true);
      setGenerateAlertStarted(false);
      setGenerateAlertFailed(false);
    }else{
      setGenerateAlertFailed(true);
      setGenerateAlert(false);
      setGenerateAlertStarted(false);
    }
   
  }, 3000);

}
  const classes = useStyles();
  
  return (

    
    <GridContainer>
{/*       
       <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem> */}
     
      <GridItem xs={12}>
     
     
        <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b>Company List</b></h4>
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
          message={messageobj}
          close
          icon={AddAlert}
          color="info"
          />
          }

          <GridContainer>

          <GridItem>
               
               <Dialog  fullWidth={true} maxWidth={'xl'} 
 open={showViewCompanyDetails} onClose={handleCloseViewCompanyDetails}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewCompanyDetails}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem  align="justify">
          {showViewCompanyDetails && <ViewCompanyDetails  organisationId ={organisationId} name={name} 
         city={city} state={state} address1={address1} 
         email={email} address2={address2} country={country} mobileNumber={mobileNumber} postalCode={postalCode} 
         
         
        />}
       
       </GridItem>  
       </Dialog>
               </GridItem> 
          </GridContainer>
            <CompanyTable
              columns={[
                
              {
                Header: "Company Name",
                accessor: "name",
            
                Cell: ({ cell,row }) => (<Link to={{ state :{organisationId : row.original.organisationId, name:row.original.name} } } 
                  onClick={() => getViewCompanyDetails(row.original.organisationId,row.original.name,
                     row.original.email,row.original.address2,row.original.country,row.original.mobileNumber, row.original.city,row.original.state,row.original.postalCode,row.original.address1,)}
                 >{row.original.name}</Link>),





                
             width: getColumnWidthNew(companyList, 'accessor', 'Tenant'),
            },
            
            
             
                {
                  Header: "Address 1",
                  accessor: "address1",
                  width: 50,
                },
             
                {
                  Header: "City",
                  accessor: "city",
                  width: 50,
                },
                // {
                //   Header: "State",
                //   accessor: "state",
                //   width: 50,
                // },
                // {
                //   Header: "PostalCode",
                //   accessor: "postalCode",
                //   width: 50,
                // },

               
                {
                  Header: "Actions",
                accessor: "actions",
                isVisible: false,
                width: 50,
                disableFilters: true,
                disableSortBy: true,
                 },
                
              ]}


              data={companyList.map((prop, key) => {
                return {
                  id: prop.id,
                  name: prop.name,
                 
                  email: prop.email,
                  address2: prop.address2,
                  country: prop.country,
                  mobileNumber: prop.mobileNumber,
                 
                  city: prop.city,
                  state: prop.state,
                  postalCode:prop.postalCode,
                  address1: prop.address1,
                  
                  actions: (
                    // we've added some custom button actions
                    <div >
                   <Link to={{pathname:'/admin/company-edit', state :{companyId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="edit"
                      >
                          <Tooltip classes={{ tooltip: classes.customWidth }} title="Edit">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                      <Link to={{pathname:'/admin/userlist', state : {organisationId : prop.id,organisationName : prop.name} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="user"
                      >
                          <Tooltip classes={{ tooltip: classes.customWidth }} title="Create User">
                        <PersonAddIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit(prop.id)}}
                        color="info"
                        className="remove"
                      >
                            <Tooltip classes={{ tooltip: classes.customWidth }} title="Delete Company">
                        <DeleteIcon/>
                        </Tooltip>
                      </Button>{" "}
                      <Link to={{pathname:'/admin/tenant-upload',   state : { organisationId : prop.id,organisationName : prop.name } } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        size="md"
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="upload"
                      >
                           <Tooltip classes={{ tooltip: classes.customWidth }} title="Upload list">
                        <PublishIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      <Link to={{pathname:'/admin/populate-initial-score',  state : { organisationId : prop.id,organisationName : prop.name }} }>
                     
                      <Button
                        justIcon
                        round
                        simple
                       
                       // size="md"
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="score"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Populate Initial Score">
                        <AssignmentTurnedInIcon/>
                        </Tooltip>
                      </Button>{" "}
                    
                      </Link>
                      {/* <Link to={{pathname:'/admin/configure-report',  state : { organisationId : prop.id,organisationName : prop.name }} }> */}
                      <Link to={{pathname:'/admin/dashboard-list',  state : { organisationId : prop.id,organisationName : prop.name }} }>
                     
                     <Button
                       justIcon
                       round
                       simple
                      
                      // size="md"
                       //   onClick={() => 
                       //   alert("You've pressed the edit button on colmun id: " + prop.id)
                       // }
                       color="info"
                       className="score"
                     >
                        <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Dashboard">
                       <DashboardIcon/>
                       </Tooltip>
                     </Button>{" "}
                   
                     </Link>
                      <Link>
                     
                     <Button
                       justIcon
                       round
                       simple
                      
                      // size="md"
                       //   onClick={() => 
                       //   alert("You've pressed the edit button on colmun id: " + prop.id)
                       // }
                       color="info"
                       className="score"
                     >
                        <Tooltip classes={{ tooltip: classes.customWidth }} title="Configure API access">
                       <SettingsIcon   onClick={() => handleClickOpenConfigureApiAccess(prop.id,prop.name )}/>
                       </Tooltip>
                     </Button>{" "}
                   
                     </Link>

                     {/* <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{tloAndCreditsafeReport(prop.id,prop.tloId,prop.creditSafeId)}}
                        color="info"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Report">
                         <AssignmentIcon/>
                        </Tooltip>
                      </Button>{" "} */}
                   

                     {/* {prop.reportGenerationStatus==='SUCCESS' ? */}
                      <Link to={{pathname:'/admin/pdf-download', state :{organisationId:prop.id,organisationName:prop.name} } }>
                      <Button
                        justIcon
                        round
                        simple
                        color="info"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="View Report">
                         <DescriptionIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      
                    </div>
                  )
                };
              })}
             // data={companyList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
      <div>
      <GridContainer className={classes.gridContainer}>
            <GridItem>
       <Dialog  fullWidth={true}
  maxWidth={'sm'} open={openConfigureApiAccess} onClose={handleCloseConfigureApiAccess}  aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogActions>
          {/* <Button onClick={handleCloseConfigureApiAccess} color="info" style={{fontSize:15}}>
           Close
          </Button> */}
          <Button 
          justIcon
          round
          simple
          onClick={handleCloseConfigureApiAccess}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
        </DialogActions>
        <DialogContent>
        {/*<label>Comany Name:</label>
          <TextField id="standard-basic"/>
          <br></br>
          <label>Company URL:</label>
          <TextField id="standard-basic"/>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            type="email"
            fullWidth
          /> */}



{openConfigureApiAccess && <ConfigureReport  organisationName ={organisationName} organisationId={organisationId} />}

    
        </DialogContent>
       
      </Dialog>
      </GridItem>
      
      </GridContainer>
       </div>


  
    </GridContainer>
  );
}


CompanyList.propTypes = {
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmCompanyListRequest: PropTypes.func.isRequired,
  tmCompanyListByOrgRequest: PropTypes.func.isRequired,
  tmCompanyDeleteRequest: PropTypes.func.isRequired,

  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  companyList: state.tenantmonitoring.companyList,
  companyDelete: state.tenantmonitoring.companyDelete,
  loading: state.tenantmonitoring.loading,
  tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyList);



