import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import bailoutImg from "assets/img/pirs/bailout.png";
import businessRemote from "assets/img/pirs/businessRemote.png";
import TMHeatMapTenantRisk from "components/Charts/TMHeatMapTenantRisk.js";
import Badge from "components/Badge/Badge.js";
import changeInEmployment from "assets/img/pirs/changeInEmployment.png";
import consumerConfidence from "assets/img/pirs/consumerConfidence.png";
import degreeOfOpenness from "assets/img/pirs/degreeOfOpenness.png";
import directImpact from "assets/img/pirs/directImpact.png";
import GovernmentBailOut from "assets/img/pirs/GovernmentBailOut.png";
import indirectImpact from "assets/img/pirs/indirectImpact.png";
import openess from "assets/img/pirs/openess.png";
import operationalmitigation from "assets/img/pirs/operationalmitigation.png";
import outlookForRecovery from "assets/img/pirs/outlookForRecovery.png";
import supplychain from "assets/img/pirs/supplychain.png";
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react";
import { Toll } from "@material-ui/icons";
import moment from 'moment';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  imgPadding :{
  paddingLeft : "20px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  }
};

const useStyles = makeStyles(styles);

//export default function Widgets() {

  const PirsReportHeatMap = ({user,tenantName,tenantAddress,tenantId,month,month1,year,scoreType,tmTenantsScoreByPIRSRequest,tenantPIRSMap,propertyName,propertyId,category,fundId,fundName,selectedRangeFrom,selectedRangeTo}) =>{
    const history = useHistory();

    // const [bailoutMlScore, setBailoutMlScore] = React.useState("");
    // const [changeInJobs, setChangeInJobs] = React.useState("");
    // const [consumerConfidenceScore, setConsumerConfidenceScore] = React.useState("");
    // const [indirectImpactMlScore, setIndirectImpactMlScore] = React.useState("");
    // const [mlDirectScore, setMlDirectScore] = React.useState("");
    // const [recoveryMlScore, setRecoveryMlScore] = React.useState("");
    // const [openness, setOpenness] = React.useState("");
    // const [percentRemote, setPercentRemote] = React.useState("");
    // const [overallPirsMlScore, setOverallPirsMlScore] = React.useState("224");
    // const [supplyChainConstraint, setSupplyChainConstraint] = React.useState("");
    // const [operationalMitigationMlScore, setOperationalMitigationMlScore] = React.useState("");
 

    // const [bailoutMlScore, setBailoutMlScore] = React.useState("250");
    // const [changeInJobs, setChangeInJobs] = React.useState("250");
    // const [consumerConfidenceScore, setConsumerConfidenceScore] = React.useState("323");
    // const [indirectImpactMlScore, setIndirectImpactMlScore] = React.useState("175");
    // const [mlDirectScore, setMlDirectScore] = React.useState("286");
    // const [recoveryMlScore, setRecoveryMlScore] = React.useState("200");
    // const [openness, setOpenness] = React.useState("275");
    // const [percentRemote, setPercentRemote] = React.useState("250");
    // const [overallPirsMlScore, setOverallPirsMlScore] = React.useState("224");
    // const [supplyChainConstraint, setSupplyChainConstraint] = React.useState("370");
    // const [operationalMitigationMlScore, setOperationalMitigationMlScore] = React.useState("325");


    const [bailoutMlScore, setBailoutMlScore] = React.useState("");
    const [changeInJobs, setChangeInJobs] = React.useState("");
    const [consumerConfidenceScore, setConsumerConfidenceScore] = React.useState("");
    const [indirectImpactMlScore, setIndirectImpactMlScore] = React.useState("");
    const [mlDirectScore, setMlDirectScore] = React.useState("");
    const [recoveryMlScore, setRecoveryMlScore] = React.useState("");
    const [openness, setOpenness] = React.useState("");
    const [percentRemote, setPercentRemote] = React.useState("");
    const [overallPirsMlScore, setOverallPirsMlScore] = React.useState("");
    const [supplyChainConstraint, setSupplyChainConstraint] = React.useState("");
    const [operationalMitigationMlScore, setOperationalMitigationMlScore] = React.useState("");
      

    const [bailoutMlScoreCSS, setBailoutMlScoreCSS] = React.useState("");
    const [changeInJobsCSS, setChangeInJobsCSS] = React.useState("");
    const [consumerConfidenceScoreCSS, setConsumerConfidenceScoreCSS] = React.useState("");
    const [indirectImpactMlScoreCSS, setIndirectImpactMlScoreCSS] = React.useState("");
    const [mlDirectScoreCSS, setMlDirectScoreCSS] = React.useState("");
    const [recoveryMlScoreCSS, setRecoveryMlScoreCSS] = React.useState("");
    const [opennessCSS, setOpennessCSS] = React.useState("");
    const [percentRemoteCSS, setPercentRemoteCSS] = React.useState("");
    const [overallPirsMlScoreCSS, setOverallPirsMlScoreCSS] = React.useState("");
    const [supplyChainConstraintCSS, setSupplyChainConstraintCSS] = React.useState("");
    const [operationalMitigationMlScoreCSS, setOperationalMitigationMlScoreCSS] = React.useState("");
    const [dataStatus, setDataStatus] = React.useState(false);
    const[showBackNavigation,setShowBackNavigation]=React.useState(false);

    const [consumerConfidenceIPIScore, setConsumerConfidenceIPIScore] = React.useState("");
    const [consumerConfidencePMIScore, setConsumerConfidencePMIScore] = React.useState("");

    const [userId, setUserId] = React.useState(user.id);
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

   // const [year, setYear] = React.useState(year);

   const[hideValuesWith0,setHideValuesWith0] = React.useState(false);
const[hideOperationalMitigationMlScore,setHideOperationalMitigationMlScore] = React.useState(false);
const[hideBailoutMlScore,seHideBailoutMlScore] = React.useState(false);
const[hideChangeInJobs,setHideChangeInJobs] = React.useState(false);
const[hideConsumerConfidenceScore,setHideConsumerConfidenceScore] = React.useState(false);
const[hideIndirectImpactMlScore,setHideIndirectImpactMlScore] = React.useState(false);
const[hideRecoveryMlScore,setHideRecoveryMlScore] = React.useState(false);
const[hideOpenness,setHideOpenness] = React.useState(false);
const[hidePercentRemote,setHidePercentRemote] = React.useState(false);
const[hideOverallPirsMlScore,setHideOverallPirsMlScore] = React.useState(false);
const[hideSupplyChainConstraint,setHideSupplyChainConstraint] = React.useState(false);

const[hideConsumerConfidenceScoreIPI,setHideConsumerConfidenceScoreIPI] = React.useState(false);
const[hideConsumerConfidenceScorePMI,setHideConsumerConfidenceScorePMI] = React.useState(false);

// const [tenantAddress, setTenantAddress] = React.useState("");
const [city, setCity] = React.useState("");
const [state, setState] = React.useState("");
const [zip, setZip] = React.useState("");
const [prev1Year, setPrev1Year] = React.useState(moment().subtract(1, "month").format('YYYY').toLowerCase());
const [naicsCode, setNaicsCode] = React.useState("");
const [description, setDescription] = React.useState("");

   function handleClickBack(event) {
  
       
    setShowBackNavigation(true);

  }

  function handleClickFundDashboard(event) {
    
  // setShowBackNavigation(true);
   history.push({ 
     pathname: '/dashboard'
    });
   
  }
 
    React.useEffect(() => {
      setHideValuesWith0(true);
      setHideOperationalMitigationMlScore(true);
      setHideChangeInJobs(true);
      seHideBailoutMlScore(true);
      setHideConsumerConfidenceScore(true);
      setHideIndirectImpactMlScore(true);
      setHideRecoveryMlScore(true);
      setHideOpenness(true);
      setHidePercentRemote(true);
      setHideSupplyChainConstraint(true);
      setHideConsumerConfidenceScoreIPI(true);
      setHideConsumerConfidenceScorePMI(true);
     
    if(!dataStatus && month!=""){
      getTmTenantScoreByPIRS(tenantId,month1,year,scoreType);
    
   
}else{
  setBailoutMlScore(tenantPIRSMap.bailoutMlScore);
  setChangeInJobs(tenantPIRSMap.changeInJobs);
  setConsumerConfidenceScore(tenantPIRSMap.consumerConfidence);
  setIndirectImpactMlScore(tenantPIRSMap.indirectImpactMlScore);
  setMlDirectScore(tenantPIRSMap.mlDirectScore);
  setRecoveryMlScore(tenantPIRSMap.recoveryMlScore);
  setOpenness(tenantPIRSMap.openness);
  setPercentRemote(tenantPIRSMap.percentRemote);
  setOverallPirsMlScore(tenantPIRSMap.overallPirsMlScore);
  setSupplyChainConstraint(tenantPIRSMap.supplyChainConstraint);
  setOperationalMitigationMlScore(tenantPIRSMap.operationalMitigationMlScore);
  setConsumerConfidenceIPIScore(tenantPIRSMap.consumerConfidenceIPI);
  setConsumerConfidencePMIScore(tenantPIRSMap.consumerConfidencePMI);
  setNaicsCode(tenantPIRSMap.naicsCode);
  setDescription(tenantPIRSMap.desription);
  // setTenantAddress(tenantPIRSMap.tenantAddress);
    setCity(tenantPIRSMap.city);
    setState(tenantPIRSMap.state);
    setZip(tenantPIRSMap.zip);

 if(bailoutMlScore > 0 && bailoutMlScore <= 100){
  setBailoutMlScoreCSS("#FF0000")
 }else if(bailoutMlScore > 101 && bailoutMlScore < 200){
  setBailoutMlScoreCSS("#ff9800")
 }else if(bailoutMlScore > 201 && bailoutMlScore <= 300){
  setBailoutMlScoreCSS("#ffdf00")
 }else if(bailoutMlScore > 301 && bailoutMlScore <= 400){
  setBailoutMlScoreCSS("#32cd32")
}else if(bailoutMlScore > 401 && bailoutMlScore <= 500){
  setBailoutMlScoreCSS("#4caf50")
}
else if(bailoutMlScore==0){
  seHideBailoutMlScore(false);
}

if(changeInJobs > 0 && changeInJobs <= 100){
  setChangeInJobsCSS("#FF0000")
 }else if(changeInJobs > 101 && changeInJobs <= 200){
  setChangeInJobsCSS("#ff9800")
 }else if(changeInJobs > 201 && changeInJobs <= 300){
  setChangeInJobsCSS("#ffdf00")
 }else if(changeInJobs > 301 && changeInJobs <= 400){
  setChangeInJobsCSS("#32cd32")
}else if(changeInJobs > 401 && changeInJobs <= 500){
  setChangeInJobsCSS("#4caf50")
}
else if(changeInJobs==0){
  setHideChangeInJobs(false);
}


if(consumerConfidenceScore > 0 && consumerConfidenceScore <= 100){
  setConsumerConfidenceScoreCSS("#FF0000")
 }else if(consumerConfidenceScore > 101 && consumerConfidenceScore <= 200){
  setConsumerConfidenceScoreCSS("#ff9800")
 }else if(consumerConfidenceScore > 201 && consumerConfidenceScore <= 300){
  setConsumerConfidenceScoreCSS("#ffdf00")
 }else if(consumerConfidenceScore > 301 && consumerConfidenceScore <= 400){
  setConsumerConfidenceScoreCSS("#32cd32")
}else if(consumerConfidenceScore > 401 && consumerConfidenceScore <= 500){
  setConsumerConfidenceScoreCSS("#4caf50")
}
else if(consumerConfidenceScore==0){
  setHideConsumerConfidenceScore(false);
}

if(consumerConfidenceIPIScore > 0 && consumerConfidenceIPIScore <= 100){
  setConsumerConfidenceScoreCSS("#FF0000")
 }else if(consumerConfidenceIPIScore > 101 && consumerConfidenceIPIScore <= 200){
  setConsumerConfidenceScoreCSS("#ff9800")
 }else if(consumerConfidenceIPIScore > 201 && consumerConfidenceIPIScore <= 300){
  setConsumerConfidenceScoreCSS("#ffdf00")
 }else if(consumerConfidenceIPIScore > 301 && consumerConfidenceIPIScore <= 400){
  setConsumerConfidenceScoreCSS("#32cd32")
}else if(consumerConfidenceIPIScore > 401 && consumerConfidenceIPIScore <= 500){
  setConsumerConfidenceScoreCSS("#4caf50")
}
else if(consumerConfidenceIPIScore==0){
  setHideConsumerConfidenceScoreIPI(false);
}

if(consumerConfidencePMIScore > 0 && consumerConfidencePMIScore <= 100){
  setConsumerConfidenceScoreCSS("#FF0000")
 }else if(consumerConfidencePMIScore > 101 && consumerConfidencePMIScore <= 200){
  setConsumerConfidenceScoreCSS("#ff9800")
 }else if(consumerConfidencePMIScore > 201 && consumerConfidencePMIScore <= 300){
  setConsumerConfidenceScoreCSS("#ffdf00")
 }else if(consumerConfidencePMIScore > 301 && consumerConfidencePMIScore <= 400){
  setConsumerConfidenceScoreCSS("#32cd32")
}else if(consumerConfidencePMIScore > 401 && consumerConfidencePMIScore <= 500){
  setConsumerConfidenceScoreCSS("#4caf50")
}
else if(consumerConfidencePMIScore==0){
  setHideConsumerConfidenceScorePMI(false);
}

if(indirectImpactMlScore > 0 && indirectImpactMlScore <= 100){
  setIndirectImpactMlScoreCSS("#FF0000")
 }else if(indirectImpactMlScore > 101 && indirectImpactMlScore <= 200){
  setIndirectImpactMlScoreCSS("#ff9800")
 }else if(indirectImpactMlScore > 201 && indirectImpactMlScore <= 300){
  setIndirectImpactMlScoreCSS("#ffdf00")
 }else if(indirectImpactMlScore > 301 && indirectImpactMlScore <= 400){
  setIndirectImpactMlScoreCSS("#32cd32")
}else if(indirectImpactMlScore > 401 && indirectImpactMlScore <= 500){
  setIndirectImpactMlScoreCSS("#4caf50")
}
else if(indirectImpactMlScore==0){
  setHideIndirectImpactMlScore(false);
}

if(mlDirectScore > 0 && mlDirectScore <= 100){
  setMlDirectScoreCSS("#FF0000")
 }else if(mlDirectScore > 101 && mlDirectScore <= 200){
  setMlDirectScoreCSS("#ff9800")
 }else if(mlDirectScore > 201 && mlDirectScore <= 300){
  setMlDirectScoreCSS("#ffdf00")
 }else if(mlDirectScore > 301 && mlDirectScore <= 400){
  setMlDirectScoreCSS("#32cd32")
}else if(mlDirectScore > 401 && mlDirectScore <= 500){
  setMlDirectScoreCSS("#4caf50")
}
else if(mlDirectScore==0){
  setHideValuesWith0(false);
}


if(recoveryMlScore > 0 && recoveryMlScore <= 100){
  setRecoveryMlScoreCSS("#FF0000")
 }else if(recoveryMlScore > 101 && recoveryMlScore <= 200){
  setRecoveryMlScoreCSS("#ff9800")
 }else if(recoveryMlScore > 201 && recoveryMlScore <= 300){
  setRecoveryMlScoreCSS("#ffdf00")
 }else if(recoveryMlScore > 301 && recoveryMlScore <= 400){
  setRecoveryMlScoreCSS("#32cd32")
}else if(recoveryMlScore > 401 && recoveryMlScore <= 500){
  setRecoveryMlScoreCSS("#4caf50")
}
else if(recoveryMlScore==0){
  setHideRecoveryMlScore(false);
}

if(openness > 0 && openness <= 100){
  setOpennessCSS("#FF0000")
 }else if(openness > 101 && openness <= 200){
  setOpennessCSS("#ff9800")
 }else if(openness > 201 && openness <= 300){
  setOpennessCSS("#ffdf00")
 }else if(openness > 301 && openness <= 400){
  setOpennessCSS("#32cd32")
}else if(openness > 401 && openness <= 500){
  setOpennessCSS("#4caf50")
}
else if(openness==0){
  setHideOpenness(false);
}

if(percentRemote > 0 && percentRemote <= 100){
  setPercentRemoteCSS("#FF0000")
 }else if(percentRemote > 101 && percentRemote <= 200){
  setPercentRemoteCSS("#ff9800")
 }else if(percentRemote > 201 && percentRemote <= 300){
  setPercentRemoteCSS("#ffdf00")
 }else if(percentRemote > 301 && percentRemote <= 400){
  setPercentRemoteCSS("#32cd32")
}else if(percentRemote > 401 && percentRemote <= 500){
  setPercentRemoteCSS("#4caf50")
}
else if(percentRemote==0){
  setHidePercentRemote(false);
}

if(overallPirsMlScore > 0 && overallPirsMlScore <= 100){
  setOverallPirsMlScoreCSS("#FF0000")
 }else if(overallPirsMlScore > 101 && overallPirsMlScore <= 200){
  setOverallPirsMlScoreCSS("#ff9800")
 }else if(overallPirsMlScore > 201 && overallPirsMlScore <= 300){
  setOverallPirsMlScoreCSS("#ffdf00")
 }else if(overallPirsMlScore > 301 && overallPirsMlScore <= 400){
  setOverallPirsMlScoreCSS("#32cd32")
}else if(overallPirsMlScore > 401 && overallPirsMlScore <= 500){
  setOverallPirsMlScoreCSS("#4caf50")
}


if(operationalMitigationMlScore > 0 && operationalMitigationMlScore <= 100){
  setOperationalMitigationMlScoreCSS("#FF0000")
 }else if(operationalMitigationMlScore > 101 && operationalMitigationMlScore <= 200){
  setOperationalMitigationMlScoreCSS("#ff9800")
 }else if(operationalMitigationMlScore > 201 && operationalMitigationMlScore <= 300){
  setOperationalMitigationMlScoreCSS("#ffdf00")
 }else if(operationalMitigationMlScore > 301 && operationalMitigationMlScore <= 400){
  setOperationalMitigationMlScoreCSS("#32cd32")
}else if(operationalMitigationMlScore > 401 && operationalMitigationMlScore <= 500){
  setOperationalMitigationMlScoreCSS("#4caf50")
}
else if(operationalMitigationMlScore==0){
  setHideOperationalMitigationMlScore(false);
}

if(supplyChainConstraint > 0 && supplyChainConstraint <= 100){
  setSupplyChainConstraintCSS("#FF0000")
 }else if(supplyChainConstraint > 101 && supplyChainConstraint <= 200){
  setSupplyChainConstraintCSS("#ff9800")
 }else if(supplyChainConstraint > 201 && supplyChainConstraint <= 300){
  setSupplyChainConstraintCSS("#ffdf00")
 }else if(supplyChainConstraint > 301 && supplyChainConstraint <= 400){
  setSupplyChainConstraintCSS("#32cd32")
}else if(supplyChainConstraint > 401 && supplyChainConstraint <= 500){
  setSupplyChainConstraintCSS("#4caf50")
}
else if(supplyChainConstraint==0){
  setHideSupplyChainConstraint(false);
}


}
setTimeout(() => {
  setDataStatus(true);
}, 1000);


}, [tenantId,month,year,dataStatus,tenantPIRSMap]);

   
    const getTmTenantScoreByPIRS = (tenantId,month,year,scoreType) => {
   
     tmTenantsScoreByPIRSRequest(tenantId,month,year);
     setBailoutMlScore(tenantPIRSMap.bailoutMlScore);
     setChangeInJobs(tenantPIRSMap.changeInJobs);
     setConsumerConfidenceScore(tenantPIRSMap.consumerConfidence);
     setIndirectImpactMlScore(tenantPIRSMap.indirectImpactMlScore);
     setMlDirectScore(tenantPIRSMap.mlDirectScore);
     setRecoveryMlScore(tenantPIRSMap.recoveryMlScore);
     setOpenness(tenantPIRSMap.openness);
     setPercentRemote(tenantPIRSMap.percentRemote);
     setOverallPirsMlScore(tenantPIRSMap.overallPirsMlScore);
     setSupplyChainConstraint(tenantPIRSMap.supplyChainConstraint);
     setOperationalMitigationMlScore(tenantPIRSMap.operationalMitigationMlScore);

     setConsumerConfidenceIPIScore(tenantPIRSMap.consumerConfidenceIPI);
      setConsumerConfidencePMIScore(tenantPIRSMap.consumerConfidencePMI);
     //setDataStatus(true);
     
     
    };
    const classes = useStyles();
    if(dataStatus){
  return (

  
    <div>
 
 {!showBackNavigation&&
   
      <GridContainer>
    
    <GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>



<Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> 
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>


</GridItem>


       <GridItem xs={12} sm={12} md={12}>
       <h3 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'25px' }}>Tenant Name: {tenantName}</h3>
      <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px",paddingLeft:'25px'}}>Industry: {description}({naicsCode})</h4>
       <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px",paddingLeft:'25px'}}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>

<h3 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{paddingLeft:'30px' }} >
             <b>Industry Risk Score : <font style={{color:overallPirsMlScoreCSS,fontSize:'30px',width:'150px'}}>{overallPirsMlScore}</font> - [{month1}&nbsp;{year}]</b>
           </h3>
     
       </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
          
            
          {/* {hideValuesWith0&&<GridItem xs={12} sm={2} md={2} >
              <Card pricing plain>
                <CardBody pricing plain>
                  
                  <div >
                  <Tooltip classes={{ tooltip: classes.customWidth }} title="The Direct Impact is based on economic, employment and government restrictions that impact an industry" >
                  <img src={directImpact} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: mlDirectScoreCSS,fontSize:'32px'}}>
                    <b>{mlDirectScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}} >
                 <b>DIRECT IMPACT</b> 
                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
            
            {hideOperationalMitigationMlScore&&<GridItem xs={12} sm={2} lg={2}>
              <Card pricing plain>
                <CardBody pricing plain>
                  
                  <div>
                  
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="The oprational mitigation score that the industry has available to mitigate the impact of restrictions" >
                   <img src={operationalmitigation} alt="Logo" width="80" height="80"/>
                   </Tooltip>
               
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: operationalMitigationMlScoreCSS,fontSize:'32px'}}>
                    <b>{operationalMitigationMlScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                <b>  OPERATIONAL MITIGATION</b>

                  </h6>
                 
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
            

            {hideIndirectImpactMlScore&&<GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                
                  <div >
                  <Tooltip classes={{ tooltip: classes.customWidth }} title="The Indirect Impact score takes into account other factors that may impact the industry in a positive or negative way." > 
                  <img src={indirectImpact} alt="Logo" width="80" height="80"/>
                  </Tooltip> 
                  </div>
               
                  <h5  className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: indirectImpactMlScoreCSS,fontSize:'32px'}}>
                    <b>{indirectImpactMlScore}</b>
                  </h5>
             
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                <b>  INDIRECT IMPACT </b>

                  </h6>
                
                  
                </CardBody>
              </Card>
            </GridItem>}
            

            {hideRecoveryMlScore&& <GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                 
                  <div>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The industries probability of recovery from initial and ongoing pandemic measures." >
                  <img src={outlookForRecovery} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: recoveryMlScoreCSS,fontSize:'32px'}}>
                    <b>{recoveryMlScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                 <b>OUTLOOK OF RECOVERY</b> 

                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}

            {hideBailoutMlScore&& <GridItem xs={12} sm={2} lg={2} >
              <Card pricing plain>
                <CardBody pricing plain>
               
                  <div  style={styles.imgPadding}>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="Stimulus funding available for the industry" >
                  <img src={GovernmentBailOut} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: bailoutMlScoreCSS,fontSize:'32px'}}>
                    <b>{bailoutMlScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                 <b>GOVERNMENT BAILOUT</b> 

                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}


 <GridItem xs={12} sm={12} lg={12}>
 <h3 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{paddingLeft:'30px' }}>
                    <b>Direct Impact Score Components</b>
                  </h3>
 
 </GridItem> */}




          
            
 {/* {hideOpenness&&<GridItem xs={12} sm={2} lg={2}>
              <Card pricing plain>
                <CardBody pricing plain>
                
                  <div>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The openness score is based on government restrictions by state that impact an industry">
                  <img src={degreeOfOpenness} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: opennessCSS,fontSize:'32px'}}>    
       <b>{openness} </b> 
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                  <b>Degree of Openness</b>
                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}


            {hidePercentRemote&&<GridItem xs={12} sm={2} lg={2}>
              <Card pricing plain>
                <CardBody pricing plain>
                 
                  <div >
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Core Business Remote score looks at the ability of the industry workforce to work remote " >
                  <img src={businessRemote} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: percentRemoteCSS,fontSize:'32px'}}>
                    <b>{percentRemote}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                  <b>  % CORE BUSINESS REMOTE</b>


                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>} */}


            {hideChangeInJobs&& <GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                
                  <div >
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Change in Employment score is based on the monthly employment change for the industry " >
                  <img src={changeInEmployment} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: changeInJobsCSS,fontSize:'32px'}}>
                    <b>{changeInJobs}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                  <b> CHANGE IN EMPLOYMENT</b>


                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
            

            

            {/* {hideSupplyChainConstraint&& <GridItem xs={12} sm={3} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                
                  <div >
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Supply Chain Constraints score looks at upstream and downstream supply chain industries" >
                  <img src={supplychain} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: supplyChainConstraintCSS,fontSize:'32px'}}>
                    <b>{supplyChainConstraint}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                  <b>  Supply Chain</b>


                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
             */}

            {hideConsumerConfidenceScore&&<GridItem xs={12} sm={2} lg={2} >
              <Card pricing plain>
                <CardBody pricing plain>
                 
                  <div>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Consumer Confidence Index measures consumer attitudes and buying intentions. 
                    The Industrial Production Index (IPI) measures real output in the manufacturing sector. 
                     The Purchasing Managers' Index (PMI) is an index of the prevailing direction of economic trends in the manufacturing and service 
                     sectors that summarizes whether market conditions, as viewed by purchasing managers, are expanding, staying the same, or contracting." >
                  <img src={consumerConfidence} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: consumerConfidenceScoreCSS,fontSize:'32px'}}>
                    <b>{consumerConfidenceScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                 <b> CCI
</b>

                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
            
            {hideConsumerConfidenceScoreIPI&&<GridItem xs={12} sm={2} lg={2} >
              <Card pricing plain>
                <CardBody pricing plain>
                  {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
                  <div>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Consumer Confidence Index measures consumer attitudes and buying intentions. The Industrial Production Index (IPI) measures real output in the manufacturing sector.  The Purchasing Managers' Index (PMI) is an index of the prevailing direction of economic trends in the manufacturing and service sectors that summarizes whether market conditions, as viewed by purchasing managers, are expanding, staying the same, or contracting.">
                  <img src={consumerConfidence} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: consumerConfidenceScoreCSS,fontSize:'32px'}}>
                    <b>{consumerConfidenceIPIScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                 <b> IPI
</b>

                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}

            {hideConsumerConfidenceScorePMI&&<GridItem xs={12} sm={2} lg={2} >
              <Card pricing plain>
                <CardBody pricing plain>
                  {/* <h6 className={classes.cardCategory}>Freelancer</h6> */}
                  <div>
                    <Tooltip classes={{ tooltip: classes.customWidth }} title="The Consumer Confidence Index measures consumer attitudes and buying intentions. The Industrial Production Index (IPI) measures real output in the manufacturing sector.  The Purchasing Managers' Index (PMI) is an index of the prevailing direction of economic trends in the manufacturing and service sectors that summarizes whether market conditions, as viewed by purchasing managers, are expanding, staying the same, or contracting.">
                  <img src={consumerConfidence} alt="Logo" width="80" height="80"/>
                  </Tooltip>
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`} style={{color: consumerConfidenceScoreCSS,fontSize:'32px'}}>
                    <b>{consumerConfidencePMIScore}</b>
                  </h5>
                  <h6 className={`${classes.cardTitle}`} style={{fontSize:'18px'}}>
                 <b> PMI
</b>

                  </h6>
                  
                  
                </CardBody>
              </Card>
            </GridItem>}
            
          </GridContainer>
        </GridItem>
        
      </GridContainer>
      }

         {showBackNavigation&& <TMHeatMapTenantRisk source={"property"} labelStatus={true}  tenantName={tenantName} tenantId={tenantId} propertyName={propertyName} propertyId={propertyId} month={month} year={year} category={category} fundId={fundId} fundName={fundName} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} tenantAddress={tenantAddress} city={city} state={state} zip={zip}/>}
    </div>
  );
}else{
  return(
    <div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={4000} //3 secs
    /></div>
  )
}
}


PirsReportHeatMap.propTypes={
  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByPIRSRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantPIRSMap :state.tenantmonitoring.tenantPIRSMap,
  
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PirsReportHeatMap);


