
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];










// const Liensreport = ({tmActiveLiensJudgmentDetailsRequest,liensJudgmentDetailsDTO,tmActiveLiensDetailsRequest,liensList}) => {
  const Judgementreport = ({tmActiveLiensDetailsRequest,liensList,tmActiveTaxLienJudgmentScoreByTenantIdRequest,taxLienJudgmentScore,tmActiveJudgmentDetailsRequest,judgmentList,
    filedDate,filingType,filingNumber,amount,plaintiff}) => {

  const [input, setInput] = React.useState('');


  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



const [name, setName] = React.useState("");
const [legalName, setLegalName] = React.useState("");
const [dba,setDba]=React.useState("");
const [feinNumber,setFeinNumber]=React.useState("");
const [address1,setAddress1]=React.useState("");
const [address2,setAddress2]=React.useState("");
const [notificationEmail,setNotificationEmail]=React.useState("");
const [numberOfLocations,setNumberOfLocations]=React.useState("");
const [useOfSpace,setuseOfSpace]=React.useState("");
const [countryId,setcountryId]=React.useState("");
const [propertyName,setpropertyName]=React.useState("");
const [city,setCity]=React.useState("");
const [state,setState]=React.useState("");
const [annualRent,setAnnualRent]=React.useState("");
const [zip,setZip]=React.useState("");
const [leaseStartDate,setLeaseStartDate]=React.useState("");
const [leaseEndDate,setLeaseEndDate]=React.useState("");
const [ phnNo1,setPhnNo1]=React.useState("");
const [ phnNo2,setphnNo2]=React.useState("");
const [contifyId,setContifyId]=React.useState("");
const [creditSafeId,setCreditSafeId]=React.useState("");
const [corteraId,setCorteraId]=React.useState("");
const [bankoId,setBankoId]=React.useState("");
const [taxLiens,setTaxLiens]=React.useState("");
const [judgments,setJudgments]=React.useState("");
const [activeLiensJudgmentScore,setActiveLiensJudgmentScore]=React.useState("");
const [tenantId,setTenantId] = React.useState(location.state.tenantId);
const [month,setMonth] = React.useState(location.state.month);
const [year,setYear] = React.useState(location.state.year);
const [dataStatus, setDataStatus] = React.useState(false);
//const [status, setStatus] = React.useState("");

const [showJudgementreport, setShowJudgementreport] = React.useState(true);


// const [modal, setModal] = React.useState(false);
// const [modalValue, setModalValue] = React.useState("");



React.useEffect(() => {
   
  if(!dataStatus){
  //getActiveLiensList(tenantId);

  getActiveJudgementList(tenantId);
  getActiveLiensJudgmentScore(tenantId);


 
}
setTimeout(() => {
  setDataStatus(true);
  }, 2000);

}, [dataStatus]);



  const getActiveLiensList=async(tenantId,month,year)=>{
await tmActiveLiensDetailsRequest(tenantId,month,year)
setTaxLiens(liensList);

  }

  const getActiveLiensJudgmentScore=async(tenantId)=>{
    await tmActiveTaxLienJudgmentScoreByTenantIdRequest(tenantId)
    setActiveLiensJudgmentScore(taxLienJudgmentScore);
   
    }

    const getActiveJudgmentDetails=async(tenantId)=>{
      await tmActiveJudgmentDetailsRequest(tenantId)
      setJudgments(judgmentList);
    }

      const getActiveJudgementList=async(tenantId)=>{
        await tmActiveJudgmentDetailsRequest(tenantId)
        setJudgments(judgmentList);
      
          }


  

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
    
  };



  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};


// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
              <b>
                
              </b>
              </h3> 
            </CardHeader>
            <CardBody>
          <div>
           {/* <h4><strong>Liens & Judgement Score</strong></h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button color="success">500</Button> */}
        
             <h3><strong>Liens & Judgement Score</strong></h3>
          
            <h2 style={{ color: megaColor, paddingTop:'10px' }}><b>{taxLienJudgmentScore}</b></h2>
    </div>



              
<h4  style={{ color: 'black' }}><b>Active Judgements</b></h4>

              
<GridContainer>
                <GridItem xs={12} sm={2}>
                <label><h5 style={{ color: megaColor }}><b>Date Filed</b></h5></label>
                </GridItem>

                <GridItem xs={12} sm={3}>
                  <label><h5 style={{ color: megaColor }}><b>Filing Type</b></h5></label>
                </GridItem>
               
                <GridItem xs={12} sm={2}>
                <label><h5 style={{ color: megaColor }}><b>Case Number</b></h5></label>
                </GridItem>


            
              
                <GridItem xs={12} sm={2}>
                <label><h5 style={{ color: megaColor }}><b>Amount</b></h5></label>
                </GridItem>

                <GridItem xs={12} sm={3}>
                <label><h5 style={{ color: megaColor }}><b>Plaintiff</b></h5></label>
                </GridItem>




                    <GridItem xs={12} sm={2}>
                    {judgmentList.map(judgment => (
            
            
            <b>{Moment(judgment .filedDate).format('MM-DD-YYYY')}<br/><br/></b>        
            
   
 ))}
                </GridItem>

                <GridItem xs={12} sm={3}>
                { judgmentList.map(judgment=> (
            
            
            <b>{judgment.filingType}<br/><br/></b>
           
  
 ))}
                </GridItem>
               
                <GridItem xs={12} sm={2}>
                { judgmentList.map(judgment => (
            
            
            <b>{judgment.filingNumber}<br/><br/></b> 
           
  
 ))}
                </GridItem>


            
              
                <GridItem xs={12} sm={2}>
                { judgmentList.map(judgment => (
            
            
            <b>{judgment.amount}<br/><br/></b>
           
  
 ))}
                </GridItem>

                <GridItem xs={12} sm={3}>
                { judgmentList.map(judgment => (
            
            
            <b>{judgment.plaintiff}<br/><br/></b>
           
  
 ))}
                </GridItem>

                   

            
              
              
                  
                

            
              
                
               
               

                    

         
                
             
</GridContainer>                
               
              


    

              


              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
  }
  Judgementreport.propTypes = {
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmActiveLiensDetailsRequest: PropTypes.func.isRequired,
  tmActiveTaxLienJudgmentScoreByTenantIdRequest: PropTypes.func.isRequired,
  tmActiveJudgmentDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  liensList: state.tenantmonitoring.liensList,
  loading: state.tenantmonitoring.loading,
  tenantId: state.tenantmonitoring.tenantId,
  taxLienJudgmentScore :state.tenantmonitoring.taxLienJudgmentScore,
  judgmentList:state.tenantmonitoring.judgmentList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Judgementreport);

