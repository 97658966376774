import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import { grey } from "@material-ui/core/colors";





const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
 
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const ConfigureReport = ({tmCompanyByIdRequest,companyEditObj,user,tmCompanyEditRequest,organisationName,organisationId}) => {
  const history = useHistory();
  const location = useLocation();
  const [input, setInput] = React.useState('');
  const [userId, setUserId] = React.useState(user.id);


    // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");




const [dataStatus,setDataStatus]=React.useState(false);
const [userType,setUserType]=React.useState(user.mainRole);
// const [firstName,setFirstName]=React.useState("");
// const [lastName,setLastName]=React.useState("");

const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [id,setId]=React.useState("");



  const [checkedCortera, setCheckedCortera] = React.useState(false);
  const [checkedContify, setCheckedContify] = React.useState(false);
  const [checkedCreditsafe, setCheckedCreditsafe] = React.useState(false);
  const [checkedBanko, setCheckedBanko] = React.useState(false);

  const [name, setName] = React.useState("");
const [address1,setAddress1]=React.useState("");
const [firstName, setFirstName] = React.useState("");
const [address2,setAddress2]=React.useState("");
const [lastName, setLastName] = React.useState("");
const [postalCode, setPostalCode] = React.useState("");
const [officePhone,setOfficePhone]=React.useState("");
const [city,setCity]=React.useState("");
const [mobileNumber,setMobileNumber]=React.useState("");
const [state,setState]=React.useState("");
const [email,setEmail]=React.useState("");
const [country,setCountry]=React.useState("");
const [alert,setAlert] = React.useState(false);
const [msg,setMsg] = React.useState("");

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const setConfigureValue= (event) => {
  
    if(event.target.name==='contify'){
      setCheckedContify(event.target.checked);
    }
    else if(event.target.name==='cortera'){
      setCheckedCortera(event.target.checked);
    }
    else if(event.target.name==='creditSafe'){
      setCheckedCreditsafe(event.target.checked);
    }
    else if(event.target.name==='banko'){
      setCheckedBanko(event.target.checked);
    }
  };
  const notify = () =>toast.info('API access configured successfully!', {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    const showMsg=()=>{
      setMsg("API access configured successfully !");
    }
  toast.configure()

  useEffect(() => {

if(!dataStatus){
  getCompanyById(organisationId);
}
else{
  setCheckedCreditsafe(companyEditObj.creditSafeEnabled);
  setCheckedContify(companyEditObj.contifyEnabled);
  setCheckedCortera(companyEditObj.corteraEnabled);
  setCheckedBanko(companyEditObj.bankoEnabled)
  setName(companyEditObj.name);
  setAddress1(companyEditObj.address1);
  setFirstName(companyEditObj.firstName);
  setAddress2(companyEditObj.address2);
  setLastName(companyEditObj.lastName);
  setPostalCode(companyEditObj.postalCode);
  setOfficePhone(companyEditObj.officePhone);
  setCity(companyEditObj.city);
  setMobileNumber(companyEditObj.mobileNumber);
  setState(companyEditObj.state);
  setEmail(companyEditObj.email);
  setCountry(companyEditObj.country);
}

     
      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
  
    },[dataStatus]);

 
    const getCompanyById = async (organisationId) => {
    
      await tmCompanyByIdRequest(organisationId);
           
      };

  const submitForm = () => {
   const  company={
      id:organisationId,
      name:organisationName,
address1:address1,
firstName:firstName,
address2:address2,
lastName:lastName,
postalCode:postalCode,
officePhone:officePhone,
city:city,
mobileNumber:mobileNumber,
state:state,
email:email,
country:country,
      creditSafeEnabled:checkedCreditsafe,
      contifyEnabled:checkedContify,
      corteraEnabled:checkedCortera,
      bankoEnabled:checkedBanko
    }

    tmCompanyEditRequest(company);
// notify();
showMsg();
  }

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ backgroundColor: megaColor }} >
<h3 align="center" style={{ color: whiteColor }} className={classes.cardIconTitle}>
<b>{msg}</b>
           </h3></div>
      
        <h4 style={{ color: megaColor }} className={classes.cardIconTitle}>
              <b>{organisationName}-</b> Configure API Access
           </h4>

            
           <div>
     
   
   </div>
        
            <GridContainer>
          
            
    
                <GridItem xs={12} sm={12} md={6}>
                <br></br>
                
                <FormControlLabel
          control={
            <Switch
              checked={checkedCreditsafe}
              disabled="true"
              onChange={event => setCheckedCreditsafe(event.target.checked)}
              value={checkedCreditsafe}
              color="primary"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar,
               
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="CreditSafe"
          name="creditSafe"
        />
                </GridItem>
            
                <GridItem xs={12} sm={12} md={6}>
                <br></br>
                
                <FormControlLabel
          control={
            <Switch
              checked={checkedCortera}
             
              onChange={event => setCheckedCortera(event.target.checked)}
              value={checkedCortera}
              color="primary"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          }
          classes={{
            label: classes.label
          }}
        label="Cortera"
          name="cortera"
        />
                </GridItem>
              
                
              </GridContainer>

              
            
              
             
              
              <GridContainer>
            
               
                <GridItem xs={12} sm={12} md={6}>
                <br></br>
                
                <FormControlLabel
          control={
            <Switch
              checked={checkedContify}
              onChange={event => setCheckedContify(event.target.checked)}
              value={checkedContify}
              color="primary"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Contify"
          name="contify"
        />
            </GridItem>

    
               
                <GridItem xs={12} sm={12} md={6}>
                <br></br>
                
                <FormControlLabel
          control={
            <Switch
              checked={checkedBanko}
              onChange={event => setCheckedBanko(event.target.checked)}
              value={checkedBanko}
              color="primary"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Banko"
          name="banko"
        />
            </GridItem>
               
               
     
</GridContainer>

<br></br>
<br></br>
              <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>



                     <div></div>
<Clearfix />         

        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={9000} //3 secs
      /></div>
      )
    }
  }
  ConfigureReport.propTypes = {

  // notificationSettingsById: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     userId: PropTypes.string,
  //   }),
  // ).isRequired,


  loading: PropTypes.bool.isRequired,
  tmSaveNotificationSettingsRequest: PropTypes.func.isRequired,
  // tmFundListRequest: PropTypes.func.isRequired,
  tmNotificationSettingsByIdRequest: PropTypes.func.isRequired,
  tmEditNotificationSettingsRequest:PropTypes.func.isRequired,
  tmCompanyByIdRequest:PropTypes.func.isRequired,
  tmCompanyEditRequest:PropTypes.func.isRequired,
  // tmAssetManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  notificationSettingsById: state.tenantmonitoring.notificationSettingsById,
  editNotificationSettings:state.tenantmonitoring.editNotificationSettings,
  companyEditObj:state.tenantmonitoring.companyEditObj
  //assetManagerList: state.tenantmonitoring.assetManagerList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default  connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigureReport);
