import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import PropertyTable from "views/Property/PropertyTable.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import DeleteIcon from '@material-ui/icons/Delete';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Tooltip } from "@material-ui/core";

import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import CsvUpload from "components/CustomUpload/CsvUpload";
import PropertyImageUpload from "components/CustomUpload/PropertyImageUpload";
import PictureUpload from "components/CustomUpload/PictureUpload";
import PublishIcon from '@material-ui/icons/Publish';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
};

const useStyles = makeStyles(styles);

// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const PropertyList = ({
    propertyList, tmPropertyListRequest, tmPropertyListByOrgRequest,propertyByOrg,tmPropertyDeleteRequest,propertyDelete,tmGenaratePropertyIdInitialReportRequest,generatePropertyInitialReport,
    tmRiskListGraphMonthlyReportRequest,riskListGraphMonthlyReport,tmGraphMonthlySaveRequest
  }) => {
    const location = useLocation();
    const [dataList, setDataList] = React.useState(propertyByOrg);
    const [messageobj, setMessageobj] = React.useState("");
    const [propertyId, setPropertyId ] = React.useState("");
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    const [alert,setAlert] = React.useState(false);
      // const [alert1,setAlert1] = React.useState(false);
      const [dataStatus,setDataStatus]=React.useState(false);

      const [alert2,setAlert2] = React.useState(false);
      const [showCompanyName,setShowCompanyName]=React.useState(true);

      const [open, setOpen] = React.useState(false);
      const[showPictureUpload, setShowPictureUpload] = React.useState(false);
      
        const handleClickOpen = () => {
          setOpen(true);
        };
        const handleClickOpenShowPictureUpload = () => {
          setShowPictureUpload(true);
        };
        const handleCloseShowPictureUpload = () => {
          setShowPictureUpload(false);
        };
     
        const[showCsvUpload, setShowCsvUpload] = React.useState(false);
        
         
          const handleClickOpenShowCsvUpload = () => {
            setShowCsvUpload(true);
          };
          const handleCloseShowCsvUpload = () => {
            setShowCsvUpload(false);
          };
      
      const hideAlert2 = () => {
        setAlert2(false);
      }

    // const hideAlert1 = () => {
    //   setAlert1(false);
    // }
    const history = useHistory();
    useEffect(() => {
      if(organisationId==""||organisationId==null||organisationId==undefined){
        setShowCompanyName(false);
        setAlert(true);
        
      }
      //console.log(propertyId);
      getpropertyList(organisationId);
      if(location.state){
        setMessageobj(location.state.message);
        // setPropertyId(location.state.propertyId);
      }
      setTimeout(() => {
        setDataStatus(true);
      }, 1000);
     
      }, [messageobj,dataStatus]);
  
   
      const hideAlert = () => {
        setAlert(false);
        history.push({ 
          pathname: '/admin/select-org',
          state: {requestFrom:"property"}

         });
      
      } 
      

    const getpropertyList = async (organisationId) => {
      await tmPropertyListByOrgRequest(organisationId);
      };
      

      const deleteProperty = async (propertyId) => {
        await tmPropertyDeleteRequest(propertyId);
       
        };
        const propertyInitialReport = (propertyId) => {
          getTmGenaratePropertyInitialReportRequest(propertyId);
          console.log("propertyId"+propertyId);
          setAlert2(true);
        }
        const getTmGenaratePropertyInitialReportRequest = async (propertyId) => {
          await tmGenaratePropertyIdInitialReportRequest(propertyId);
        
        }

      //   const submit = (propertyId) => {
      //     setAlert1(true);
      // }
  
        
        const submit = (propertyId) => {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                
                <div className='custom-ui' 
                style={{
                  backgroundColor: "#81A594 ",
                 fontWeight:"bold",
                  color:'#fff',
                
                  textAlign: "center",
                 // alignItems: "center",
                  paddingTop: "20px",
                  height: "150px",
                  width: "300px",
                  
                  }}>
                    
                  <h1 >Are you sure?</h1>
                  <p fontWeight="bold">You want to Delete this Property?</p>
                 
                  <button  backgroundColor= "#000"
                     onClick={onClose}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                  <button 
                     
                    onClick={() => {
                      deleteProperty(propertyId)
                      onClose();
                      
                   
                    }}
                  >
                  &nbsp; &nbsp; Yes &nbsp;&nbsp;
                  </button>
                  </div>
            
             

              );
            }
          });
        }
            //alert("Confirm");
    
        
  

//  const getColumnWidth = (data, accessor, headerText) => {
//         const cellLength = Math.max(
//           ...data.map(row => {
//             let value = '';
      
//             if (typeof accessor === 'string') {
//               value = _.get(row, accessor);
//             } else {
//               value = accessor(row);
//             }
      
//             if (typeof value === 'number') return value.toString().length;
//             return (value || '').length;
//           }),
//           headerText.length
//         );
      
//         const magicSpacing = 12;
//         return cellLength * magicSpacing;
//       };
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}
const [urlList,setUrlList]=React.useState([]);


const handleClickOpenPdf2 = (propertyId,organisationId,userId) => {
      
  getRiskListGraphMonthlyReports(organisationId,propertyId,userId);
  // console.log("riskListGraphMonthlyReport"+riskListGraphMonthlyReport);
    if(riskListGraphMonthlyReport!=null || riskListGraphMonthlyReport.length >0){
    graphList(riskListGraphMonthlyReport,organisationId,propertyId,userId);
  }

};

const getRiskListGraphMonthlyReports = async (organisationId,propertyId,userId) => {
  await tmRiskListGraphMonthlyReportRequest(organisationId,propertyId,userId);

  };

  var now = moment();
  var monthArray=[];
  var yearArray=[];

  let currMonthName1 = moment().subtract(1, "month").format('MMM');
  let prevMonthName1 = moment().subtract(2, "month").format('MMM');


  let currMonthName2 = moment().subtract(1, "month").format('MMM').toLowerCase();
  let prevMonthName2 = moment().subtract(2, "month").format('MMM').toLowerCase();

   
  var currMonthName  =  moment().subtract(1, "month").format('MMM');
  var prev1MonthName  = moment().subtract(2, "month").format('MMM');
  var prev2MonthName  = moment().subtract(3, "month").format('MMM');
  var prev3MonthName  = moment().subtract(4, "month").format('MMM');
  var prev4MonthName  = moment().subtract(5, "month").format('MMM');
  var prev5MonthName  = moment().subtract(6, "month").format('MMM');
  var prev6MonthName  = moment().subtract(7, "month").format('MMM');
  var prev7MonthName  = moment().subtract(8, "month").format('MMM');
  var prev8MonthName  = moment().subtract(9, "month").format('MMM');
  var prev9MonthName  = moment().subtract(10, "month").format('MMM')
  var prev10MonthName  = moment().subtract(11, "month").format('MMM');
  var prev11MonthName  = moment().subtract(12, "month").format('MMM');

  var currYear  = moment().subtract(1, "month").format('YYYY').toString().slice(-2);
  var prev1MonthYear  = moment().subtract(2, "month").format('YYYY').toString().slice(-2);
  var prev2MonthYear  = moment().subtract(3, "month").format('YYYY').toString().slice(-2);
  var prev3MonthYear  = moment().subtract(4, "month").format('YYYY').toString().slice(-2);
  var prev4MonthYear  = moment().subtract(5, "month").format('YYYY').toString().slice(-2);
  var prev5MonthYear  = moment().subtract(6, "month").format('YYYY').toString().slice(-2);
  var prev6MonthYear  = moment().subtract(7, "month").format('YYYY').toString().slice(-2);
  var prev7MonthYear  = moment().subtract(8, "month").format('YYYY').toString().slice(-2);
  var prev8MonthYear  = moment().subtract(9, "month").format('YYYY').toString().slice(-2);
  var prev9MonthYear  = moment().subtract(10, "month").format('YYYY').toString().slice(-2);
  var prev10MonthYear  = moment().subtract(11, "month").format('YYYY').toString().slice(-2);
  var prev11MonthYear  = moment().subtract(12, "month").format('YYYY').toString().slice(-2);


  const month1=[prev11MonthName+"-"+prev11MonthYear,prev10MonthName+"-"+prev10MonthYear,prev9MonthName+"-"+prev9MonthYear,prev8MonthName+"-"+prev8MonthYear,prev7MonthName+"-"+prev7MonthYear,prev6MonthName+"-"+prev6MonthYear,prev5MonthName+"-"+prev5MonthYear,prev4MonthName+"-"+prev4MonthYear,prev3MonthName+"-"+prev3MonthYear,prev2MonthName+"-"+prev2MonthYear,prev1MonthName+"-"+prev1MonthYear,currMonthName+"-"+currYear];


  const ChartJsImage = require('chartjs-to-image');
  var myLineChart;
  const[chartValue, setChartValue]=React.useState("");
  const[url1, setUrl1]=React.useState("");

  const[chartDataStatus, setChartDataStatus]=React.useState(false);

  let shotUrl;
  var tenantId="";

  const getBase64Image = async (url) => {
    fetch(url).then(r => r.blob()).then(blob => {
      var reader = new FileReader();
      reader.onload = function() {
        // var b64 = reader.result.replace(/^data:.+;base64,/, '');
           var b64 = reader.result.replace(/^data:text.+;/, 'data:image/jpeg;');
          //  setTimeout(() => {
            setChartValue(b64);
            // }, 2000);
           
           console.log("chartValue"+chartValue);
      };
      reader.readAsDataURL(blob);
    });
  }
  const graphList = (riskListGraphMonthlyReport1,organisationId,propertyId,userId) => {
    // urlList.pop();
    let arryList=[];
    {riskListGraphMonthlyReport1.map(list=>(
      myLineChart = new ChartJsImage(),
      myLineChart.setConfig({
        type: 'line',
        data: { labels: month1, 
        datasets: [{label: "HISTORICAL TREND",data: list.data,fill: false}] },
        options: {
          scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false
                }   
            }]
        }
        }
      }),
      // getBase64Image(myLineChart.getUrl()),
      // tenantId=list.tenantId,

      shotUrl= async () => {
      const a= await myLineChart.getShortUrl()
      // const chart_objects = {
      //   tenantId: list.tenantId,
      //   url: a
      //   // rememberMe:false
      // };

      // arryList.push(chart_objects)
      console.log("chart_objcts...."+JSON.stringify(a))
      tmGraphMonthlySaveRequest(organisationId,propertyId,userId,list.tenantId,a)

            // console.log("urlList......"+arryList)

  },
       shotUrl()
     
      // setTimeout(() => {
      //   setUrl1(myLineChart.getUrl()),
      //   setDataStatus(true);
      //   if (!dataStatus){
  
      //     getGraphMonthlySave(organisationId,propertyId,userId,url1,list.tenantId)
      //     }
      // }, 5000)
  

    ))}
  //   setTimeout(() => {
  //   setChartDataStatus(true)
  // }, 5000)
  //   setUrlList(arryList)
    
    // console.log("chartDataStatus............"+chartDataStatus)
    // if(chartDataStatus){
    //   console.log("urlList1111111111......."+JSON.stringify(arryList))
    // getGraphMonthlySave(organisationId,propertyId,userId,arryList)
    // }
    }
    const getGraphMonthlySave = async (organizationId,propertyId,userId,urlList) => {
      await tmGraphMonthlySaveRequest(organizationId,propertyId,userId,urlList);
      
      };
  const classes = useStyles();
  if(dataStatus){
  return (
    <GridContainer>
     
     {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem> */}
      <GridItem xs={12}>
        <Card >
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Property List</text> {showCompanyName && <span style={{marginLeft:"580px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>    
   
             <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"property"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </span>}
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
          message={messageobj}
          close
          icon={AddAlert}
          color="info"
        />
         }
            <PropertyTable
              columns={[
                
              {
                Header: "Property Name",
                accessor: "propertyName",
                //width: getColumnWidth(propertyList, 'tenantName', 'Tenant'),
                // Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification/', state :{tenantId : row.original.tenantId, 
                //   contifyCompanyId:row.original.tenantContifyId, 
                //   naics:row.original.naics,
                //    accountNumber : row.original.bankoAccountNumber,
                //    tenantName:row.original.tenantName} } }>{row.original.propertyName}</Link>
             
              
              // ),
             
                // Cell: ({ cell,row ,
                //   column: { tenantId } }) => (
                
                // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
             // }
             width: getColumnWidthNew(propertyByOrg, 'accessor', 'Property Name'),
            },
            {
              Header: "Property Category",
              accessor: "category",
              width: 50,  
            },

            {
              Header: "Fund Name",
              accessor: "fundName",
              width: 50,  
            },

            {
              Header: "Asset Manager",
              accessor: "assetManagerName",
              width: 50,  
            },

            {
              Header: "Address",
              accessor: "address1",
              width: 50,  
            },
           
             
                 {
                  Header: "Actions",
                 accessor: "actions",
                 disableFilters: true,
                 disableSortBy: true
                 },
              ]}

              data={propertyByOrg.map((prop, key) => {
                return {
                  id: prop.id,
                  //propertyId:prop.id,
                  propertyName: prop.propertyName,
                  category: prop.category,
                  address1: prop.address1,
                  fundName: prop.fundName,
                  assetManagerName:prop.assetManagerName,
                  organisationId: prop.organisationId,
                  userId: prop.userId,

                  actions: (
                    // we've added some custom button actions
                    <div>
                   <Link to={{pathname:'/admin/property-edit', state :{propertyId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{}}
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="warning"
                        className="edit"
                      >
                       <Tooltip classes={{ tooltip: classes.customWidth }} title="Edit Property">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      </Link>
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{submit(prop.id)}}
                        color="info"
                        className="remove"
                      >
                       <Tooltip classes={{ tooltip: classes.customWidth }} title="Delete Property">
                        <DeleteIcon/>
                        </Tooltip>
                      </Button>{" "}

                      <Link to={{pathname:'/admin/property-image-upload',state : { propertyId : prop.id} } }>
                      <Button
                      justIcon
                        round
                        simple

                     
                        //onClick={() =>{handleClickOpenShowPictureUpload(prop.id)}}
                      onClick={handleClickOpenShowPictureUpload}   
                        size="md"
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="info"
                        className="upload"
                      >
                           <Tooltip classes={{ tooltip: classes.customWidth }} title="Upload Image">
                        <PublishIcon />
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                      <Link to={{pathname:'/admin/propertylist', state :{fundId : prop.id} } }>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{propertyInitialReport(prop.id)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate Initial Report">
                         <DashboardIcon/>
                        </Tooltip>
                      </Button>{" "}

                      <Button
                        justIcon
                        round
                        simple
                        onClick={() =>{handleClickOpenPdf2(prop.id,prop.organisationId,prop.userId)}}
                        color="info"
                        className="remove"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Generate graph image">
                         <DashboardIcon/>
                        </Tooltip>
                      </Button>{" "}
                      </Link>

                     
                  
                    </div>
                  )
                };
              })}

             // data={propertyByOrg}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
        </Card>
      </GridItem>
      <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
         
           onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please select Company
        </SweetAlert>
      
                     </div>
                     {/* <div>
  <SweetAlert
        warning
        show={alert1}
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to delete property?"
        onConfirm={() =>deleteProperty(propertyId)}
        onCancel={() => hideAlert1()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
        showConfirm
      >
       
      </SweetAlert>

</div> */}
     <div>
<SweetAlert
success
show={alert2}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert2()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
  {generatePropertyInitialReport}
{/* Generated Fund Initial Report. */}
</SweetAlert>

       </div>
    </GridContainer>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


PropertyList.propTypes = {
  propertyByOrg: PropTypes.arrayOf(
    PropTypes.shape({
      PropertyId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmPropertyListRequest: PropTypes.func.isRequired,
  tmPropertyListByOrgRequest: PropTypes.func.isRequired,
  tmPropertyDeleteRequest: PropTypes.func.isRequired,
  tmGenaratePropertyIdInitialReportRequest: PropTypes.func.isRequired,
  tmRiskListGraphMonthlyReportRequest: PropTypes.func.isRequired,
  tmGraphMonthlySaveRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  propertyByOrg: state.tenantmonitoring.propertyByOrg,
  loading: state.tenantmonitoring.loading,
  // propertyByOrg:state.tenantmonitoring.propertyByOrg,
  propertyDelete:state.tenantmonitoring.propertyDelete,
  generatePropertyInitialReport: state.tenantmonitoring.generatePropertyInitialReport,
  riskListGraphMonthlyReport: state.tenantmonitoring.riskListGraphMonthlyReport,
  tenantId: state.tenantId,
  organisationId: state.organisationId,
  propertyId:state.propertyId,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyList);



