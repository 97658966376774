import React, { Component } from "react";
import Chart from "react-apexcharts";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";
import TMHeatMapTenantRisk from "components/Charts/TMHeatMapTenantRisk.js";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBaseTenant from 'components/MenuBase/SideMenuBaseTenant.js';
import TMHeatMapRisk1 from "components/Charts/TMHeatMapRisk1.js";


import moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import { getTenantsCountByPropertyRange } from "store/sagas/tenantmonitoring";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import Badge from "components/Badge/Badge.js";
import Link from '@material-ui/core/Link';
import Pagination from '@material-ui/lab/Pagination';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';


import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
const useStyles = makeStyles(styles);



// class HeapMapTenant extends React.Component {
//   constructor(props) {

//     super(props);

const HeatMapPropertyTenant = ({user, tmTenantsScoreByPropertyRangeRequest,tmTenantNameScoreByPropertyRangeRequest,tenantScoreByPropertyRange,tmTenantsCountByPropertyRangeRequest,tenantNamesByPropertyRange,fundId,fundName,propertyId,propertyName,selectedRangeFrom,selectedRangeTo,month,year,category,backNavigation,tmTenantListByRiskRequest,tenantListByRisk,tmTenantScoreListByRiskRequest,tenantScoreListByRisk,tmPropertyCityAndStateByPropertyIdRequest,propertyCityAndState}) =>{


  const classes = useStyles(); 
  const history = useHistory();
 
  
  const[dashboardSelectedTenantId,setDashboardSelectedTenantId]=React.useState();
  const[dashboardSelectedTenantName,setDashboardSelectedTenantName]=React.useState();
  const[tenantAddress,setTenantAddress] = React.useState();
  const[city,setCity] = React.useState();
  const[state,setState] = React.useState();
  const[zip,setZip] = React.useState();
  const[heatMapData,setHeatMapData]=React.useState("");
  const[heatMapTenantVisible,setHeatMapTenantVisible]=React.useState(true);
  const[heatMapTenantRiskVisible,setHeatMapTenantRiskVisible]=React.useState(false);
  const[showBackNavigation,setShowBackNavigation]=React.useState(false);
//   const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
//   const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);
//   const [month, setMonth] = React.useState("");
const [directNavigation, setDirectNavigation] = React.useState(backNavigation);
const [year1, setYear1] = React.useState(year);
//const [monthNameValue, setMonthNameValue] = React.useState(month);

  const [userId, setUserId] = React.useState(user.id);
  // const [category, setCategory] = React.useState("");
  
  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  const [pageSize, setPageSize] = React.useState(10);
const [pageNum, setPageNum] = React.useState(0);
const [totalCount, setTotalCount] = React.useState(50);
const [page, setPage] = React.useState(1);
const [dataStatus, setDataStatus] = React.useState(false);

const [month1, setMonth1] = React.useState(month);
var now = moment();
var monthArray=[];
var yearArray=[];

 //var currMonthName  = month;
 const[propertyCity,setPropertyCity]=React.useState("");
 const[propertyState,setPropertyState]=React.useState("");
 


 

// var currMonthName  = moment().format('MMM').toLowerCase();
// var currYear  = moment().format('YYYY');



// var prev1MonthName  = moment().subtract(1, "month").format('MMM').toLowerCase();
// var prev1Year  = moment().subtract(1, "month").format('YYYY').toLowerCase();
// var prev2MonthName  = moment().subtract(2, "month").format('MMM').toLowerCase();
// var prev2Year  = moment().subtract(2, "month").format('YYYY').toLowerCase();
// var prev3MonthName  = moment().subtract(3, "month").format('MMM').toLowerCase();;
// var prev3Year  = moment().subtract(3, "month").format('YYYY').toLowerCase();
// var prev4MonthName  = moment().subtract(4, "month").format('MMM').toLowerCase();;
// var prev4Year  = moment().subtract(4, "month").format('YYYY').toLowerCase();
// var prev5MonthName  = moment().subtract(5, "month").format('MMM').toLowerCase();;
// var prev5Year  = moment().subtract(5, "month").format('YYYY').toLowerCase();


  function handleClickBack(event) {
   
   
     setShowBackNavigation(true);
     setHeatMapTenantVisible(false);
     setDirectNavigation(true);
     //setDirectNavigation(true);

   }

   function handleClickFundDashboard(event) {
         
    setShowBackNavigation(true);
    history.push({ 
      pathname: '/dashboard'
     });
    
   }

   const enableTenantBack = (range) => {

    const {rangeFrom,rangeTo,month,year}=range;
    setShowBackNavigation(false);
            //  setSelectedRangeFrom(range.rangeFrom);
            //  setSelectedRangeTo(range.rangeTo);
            //  setMonth(range.month.substr(0,3));
            //  setYear(range.year); 
            
           }

  const enableTenant = (value) => {
  //  setShowBackNavigation(true);
    setDashboardSelectedTenantId(value.tenantId); 
    setDashboardSelectedTenantName(value.tenantName);
    setTenantAddress(value.tenantAddress);
    setCity(value.city);
    setState(value.state);
    setZip(value.zip);
    setHeatMapTenantVisible(false);
    setHeatMapTenantRiskVisible(true);
    setDirectNavigation(true);
    setShowBackNavigation(false);
    // setPropertyName(value.propertyName);
    //  setShowHeatMapFundRisk(true);
    //  setHeatMapPropertyVisible(false);
  }


  
  
  React.useEffect(() => {

    if(backNavigation){
       
      setDirectNavigation(false);
    }
    if(!dataStatus){
      getPropertyCityAndStateByPropertyId(propertyId);

      getTmDashboardTenantByPropertyRange(user.organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year1,pageSize,pageNum);
    // getTmDashboardPropertyTenantNamesByRange(user.organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year1,pageSize,pageNum);

    getTmTenantListByRisk(propertyId,category,year1,month,user.organizationId,userId,pageNum,pageSize);

    //getTmTenantScoreListByRisk(user.organizationId,propertyId,userId,category,year1,month,pageNum,pageSize);

      //  getTmTenantsCountByPropertyRange( propertyId,selectedRangeFrom,year1,month,user.organizationId,selectedRangeTo);
          //setFundDashboard(tmDashboardFund);
      //    if(fundDashboard){
      //     setIsLoadingPage(true);
      //    }
     
    }

    setTimeout(() => {
      setDataStatus(true);
      }, 2000);
        }, [dataStatus]);




        const  handlePageChange =(event, value) => {
             
          setPage(value);

          getTmTenantListByRisk(propertyId,category,year1,month,user.organizationId,userId,value-1,pageSize); 
          getTmDashboardTenantByPropertyRange(user.organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year1,pageSize,value-1);
          //getTmTenantScoreListByRisk(user.organizationId,propertyId,userId,category,year,month,value-1,pageSize); 
         
          setTimeout(() => {
           // setDataStatus(true);
            }, 2000);
        
            console.log(tenantListByRisk.resultSize);
              };
  
              const getPropertyCityAndStateByPropertyId = async (propertyId) => {
                await tmPropertyCityAndStateByPropertyIdRequest(propertyId);
                console.log("propertyCityAndState"+propertyCityAndState);
                setPropertyCity(propertyCityAndState.propertyCity);
                setPropertyState(propertyCityAndState.propertyState);
                   };

        const getTmDashboardTenantByPropertyRange = async (organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum) => {
    
          await tmTenantsScoreByPropertyRangeRequest(organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum);
        //  setHeatMapData(tenantScoreByPropertyRange);
        };
const getTmTenantListByRisk = async (propertyId,category,year,month,organizationId,userId,pageNum,pageSize) => {
         await tmTenantListByRiskRequest(propertyId,category,year,month,organizationId,userId,pageNum,pageSize);
         setCity()
            };

            // const getTmTenantScoreListByRisk = async (organizationId,propertyId,userId,category,year,month,pageNum,pageSize) => {
            //   await tmTenantScoreListByRiskRequest(organizationId,propertyId,userId,category,year,month,pageNum,pageSize);
            //      };
            
        //   const getTmDashboardPropertyTenantNamesByRange = async (organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum) => {
        //  await tmTenantNameScoreByPropertyRangeRequest(organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum);
        //     };

            const getTmTenantsCountByPropertyRange = async (propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo,pageSize,pageNum) => {
              await tmTenantsCountByPropertyRangeRequest(propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo,pageSize,pageNum);
                 };

            const handleChange = (tenantId,tenantName,tenantAddress,city,state,zip) => {
            
             setHeatMapTenantVisible(false);
             setHeatMapTenantRiskVisible(true);

            //  history.push({
            //   pathname: '/admin/dashboard/',
            //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
            // })
            
             setDashboardSelectedTenantName(tenantName);
             setDashboardSelectedTenantId(tenantId);
             setTenantAddress(tenantAddress);
             setCity(city);
             setState(state);
             setZip(zip);
            }

            function handleClickHome(event) {
              // event.preventDefault();
              history.push({ 
               pathname: '/'
              });
              
             }
            function handleClickRiskDashboard(event) {
              // event.preventDefault();
              history.push({ 
               pathname: '/dashboard'
              });
              
             }

            //  const  handlePageChange =(event, value) => {
             
            //   setPage(value);
              

            //   //getTmDashboardTenantByPropertyRange(user.organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year1,pageSize,value-1);
            //   //getTmDashboardPropertyTenantNamesByRange(user.organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year1,pageSize,value-1);
            //   getTmTenantListByRisk(propertyId,category,year,month,user.organizationId,userId,pageSize,value-1);
            //   getTmTenantScoreListByRisk(user.organizationId,propertyId,userId,category,year,month,pageSize,value-1);

            //   setTimeout(() => {
            //    // setDataStatus(true);
            //     }, 6000);
            //   console.log(tenantListByRisk.resultSize);
                  
            //       };

  const tenantData=  [
      {
         name: "Thyssenkrupp Materials NA, Inc.",
         data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 0
              },
              {
                  "x": "OCT",
                  "y": 0
              },
              {
                  "x": "NOV",
                  "y": 308
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      },
     
      {
        name: "Maurice Pincoffs Company, INC.",
        data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 386
              },
              {
                  "x": "OCT",
                  "y": 384
              },
              {
                  "x": "NOV",
                  "y": 385
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      }
      
  ]

    // function generateData(count, yrange) {
    //   var i = 0;
    //   var series = [];
    //   while (i < count) {
    //     var x = (i + 1).toString();
    //     var y =
    //       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
    //     series.push({
    //       x: x,
    //       y: y
    //     });
    //     i++;
    //   }
    //   return series;
    // }

    const chartOps = { 

      series: tenantScoreByPropertyRange,
    //  series:heatMapData,
     
      options: {
        yaxis: {
          labels: {
            show: false,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                    position: 'top',
                    labels: {
                      show: true,
                      
                        style: {
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  colors: ['black','black','black','black','black','black','black','black','black','black','black','black',],
                                  
                                }
                              }
                              },
        chart: {
          height: 150,
          type: 'heatmap',
          toolbar:{
            show:false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              var el = event.target;
              var dataPointIndex = parseInt(el.getAttribute("j"));
             // var seriesName = config.globals.seriesNames[seriesIndex];

             // var scoreCategory;
              var monthNameVar;
            
             



             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
        },
        plotOptions: {
          
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 401,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 301,
                  to: 400,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 201,
                  to: 300,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 101,
                  to: 200,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 0,
                  to: 100,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
          showForNullSeries: true,
          show : false,
          // formatter: function(seriesName, opts) {
          //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
          // }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 3
        },
        title: {
          text: ' '
        },
      },
    
    
    };
  



if(dataStatus){
    return (
        <div>
 {!directNavigation&&
      <GridContainer>



<GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>
   <Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> <ArrowBackIosSharpIcon fontSize="inherit" className={classes.icon} />
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>

</GridItem>

<GridItem xs={12} sm={12} md={12}>
         <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>{propertyName} - Tenants Risk Score by Month
</b></h3>
         </GridItem>
         <GridItem xs={12} sm={12} md={12}>
         <h3 style={{ color: megaColor,paddingLeft:'18px' }}> <b>{propertyCity}&nbsp;{propertyState}
</b></h3>
         </GridItem>

<GridItem xs={12} sm={5} md={5}>
        
         </GridItem>
         <GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight:"120px",paddingTop:'10px',textAlign:"right"}} >


<div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
                </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>

 </GridItem>
 
 <GridItem xs={12} sm={12} md={3}></GridItem>
   <GridItem xs={12} sm={12} md={9} style={{paddingRight:"100px",marginTop:"15px"}}>
        <h4 style={{ color: megaColor }}> <b>The arrow indicates current month’s performance of the tenants vs 1 year ago</b></h4>
         <h4 style={{ color: megaColor,paddingRight:"4px",paddingTop:"10px" }}> <b style={{paddingLeft:"10px"}}><TrendingFlatIcon /> : Remains the same.</b> <b style={{paddingLeft:"30px"}}><ArrowUpwardIcon /> : Improvement.</b><b  style={{paddingLeft:"30px"}}><ArrowDownwardIcon/> : Declined.</b></h4>
        
                  </GridItem>

      <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}}>
        {heatMapTenantVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>TENANTS</div></b></h5>
    <SideMenuBaseTenant style ={{height:tenantListByRisk.listHeight+'px'}} items={ tenantListByRisk.tenantList } parentCallBack={enableTenant} height={tenantListByRisk.lineHeight}/>
       </div>}
     </GridItem>

    {heatMapTenantVisible && <GridItem xs={12} sm={9} md={9} >
     
      <GridItem style={{marginLeft:"-39px"}}>
    
    <div id="chart">
      
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={tenantListByRisk.chartHeight} />
</div>
    
</GridItem>
<GridItem xs={12} sm={3} md={3}></GridItem>


<GridItem xs={12} sm={9} md={9} className={classes.vertical} style={{paddingLeft:"33px",paddingTop:'10px',textAlign:"left"}} >

 <div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tenantListByRisk.totalPages} page={page}  onChange={handlePageChange} size="large" />

 
 </div> 

</GridItem>
</GridItem>

    }


    {/* {heatMapTenantRiskVisible && <HeatMapTenantRisk source={"property"} labelStatus={true} tenantName={dashboardSelectedTenantName} tenantId={dashboardSelectedTenantId}/>} */}
 <GridItem xs={12} sm={12} md={12}>

 {/* {heatMapTenantRiskVisible && <TMHeatMapTenantRisk source={"property"} labelStatus={true} fundId={fundId} fundName={fundName} tenantName={dashboardSelectedTenantName} tenantId={dashboardSelectedTenantId} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year}/>} */}
 <br/>
 <br/>

  {heatMapTenantVisible && <div className={classes.buttonGroup}>
 {/* <Button color="transparent" className={classes.firstButton}>
                   <b>Tenant Names </b>
                 </Button>
                {heatMapTenantVisible&&tenantNamesByPropertyRange.map(tenant => (

                <Button  style = {{color:'#00628B',backgroundColor:'#81A594'}} className={classes.middleButton} onClick={() => handleChange(tenant.id,tenant.name)}>
                <b> {tenant.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowUpwardIcon/></b>
                </Button> */}

  </div> 
 }
 </GridItem>

   </GridContainer>
 }

 {heatMapTenantRiskVisible&& <TMHeatMapTenantRisk source={"property"} labelStatus={true} fundId={fundId} fundName={fundName} tenantName={dashboardSelectedTenantName} tenantAddress={tenantAddress} city={city} state={state} zip={zip} tenantId={dashboardSelectedTenantId} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} category={category} year={year}/>}
 {showBackNavigation&& <TMHeatMapRisk1 fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} parentCallBack ={enableTenantBack} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year} category={category} backNavigation={true}/> }

{/* {showBackNavigation&& <TMHeatMapRisk1 propertyName={propertyName} propertyId={propertyId} parentCallBack ={enableTenantBack} fundId={fundId} fundName={fundName}  category={category}  backNavigation={true}/> } */}

 </div>  
    )
  }else{
    return(
      <div align="center">
      <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={3000} />
      </div>
    )

  }

  
}
HeatMapPropertyTenant.propTypes = {
 
  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByPropertyRangeRequest: PropTypes.func.isRequired,
  tmTenantNameScoreByPropertyRangeRequest: PropTypes.func.isRequired,
  tmTenantsCountByPropertyRangeRequest: PropTypes.func.isRequired,
  tmTenantListByRiskRequest: PropTypes.func.isRequired,
  tmTenantScoreListByRiskRequest: PropTypes.func.isRequired,
  tmPropertyCityAndStateByPropertyIdRequest: PropTypes.func.isRequired,


  
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantScoreByPropertyRange: state.tenantmonitoring.tenantScoreByPropertyRange,
  tenantNamesByPropertyRange:state.tenantmonitoring.tenantNamesByPropertyRange,
  tenantsCountByPropertyRange:state.tenantmonitoring.tenantsCountByPropertyRange,
  tenantListByRisk:state.tenantmonitoring.tenantListByRisk,
  tenantScoreListByRisk:state.tenantmonitoring.tenantScoreListByRisk, 
  propertyCityAndState: state.tenantmonitoring.propertyCityAndState,


  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeatMapPropertyTenant);

