import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
//checkbox style change
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import { useLocation } from "react-router-dom";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useHistory } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

// import { confirmAlert } from 'react-confirm-alert'; 
// import 'react-confirm-alert/src/react-confirm-alert.css';

import Badge from "components/Badge/Badge.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  
}));

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const Userform = ({tmUserSaveRequest}) => {
  const history = useHistory();

const location = useLocation();

  const [input, setInput] = React.useState('');

  //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");





  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");

const [requiredName, setrequiredFirstName] = React.useState("");
const [requiredStateFirstName, setrequiredStateFirstName] = React.useState("");


const [requiredLastName, setrequiredLastName] = React.useState("");
const [requiredStateLastName, setrequiredStateLastName] = React.useState("");

const [requiredJobTitle, setrequiredJobTitle] = React.useState("");
const [requiredStateJobTitle, setrequiredStateJobTitle] = React.useState("");

const [requiredPassword, setrequiredPassword] = React.useState("");
const [requiredStatePassword, setrequiredStatePassword] = React.useState("");

const [requiredConfirm, setrequiredConfirm] = React.useState("");
const [requiredStateConfirm, setrequiredStateConfirm] = React.useState("");
const [requiredUserType, setRequiredUserType] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");

const [firstName, setFirstName] = React.useState("");
const [lastName, setLastName] = React.useState("");
const [login, setLogin] = React.useState("");
const [password, setPassword] = React.useState("");
const [email, setEmail] = React.useState("");
const [mainRole, setMainRole] = React.useState("");
const [jobTitle, setJobTitle]= React.useState("");
// const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [organizationId, setOrganisationId] = React.useState(location.state.organisationId);
const [organisationName, setOrganisationName] = React.useState(location.state.organisationName);
 const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
//const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
const [dataStatus,setDataStatus]=React.useState(false);
// const [officeNumber, setOfficeNumber] = React.useState("");
// const [mobileNumber, setMobileNumber] = React.useState("");

//sweet alert
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}

React.useEffect(() => {
  setTimeout(() => {
    setDataStatus(true);
  }, 2000);
},[dataStatus]);

function hideAlert1(event) {
  history.push({ 
    pathname: '/admin/User/userlist'
   });
  }

function handleClickUserlist(event) {
  // event.preventDefault();
  history.push({ 
   pathname: '/admin/User/userlist'
  });
  
 }

 function handleClickHome(event) {
   // event.preventDefault();
   history.push({ 
    pathname: '/'
   });
   
  }

  const saveUser = async (managedUserVM) => {
    tmUserSaveRequest(managedUserVM);
    };

const submitForm = () => {

 if(verifyLength(firstName,1)&&verifyLength(lastName,1)&&verifyLength(login,1)&&verifyLength(password,1)&&verifyLength(email,1)&&verifyLength(mainRole,1)&&verifyEmail(email,1))
 {
  const managedUserVM={
  
    firstName: firstName,
    lastName:lastName,
    login:login,
    password:password,
    email:email,
    organizationId:organizationId,
   mainRole:mainRole,
   jobTitle:jobTitle,
  //  officeNumber:officeNumber,
  //  mobileNumber:mobileNumber
  }
  tmUserSaveRequest(managedUserVM);
  clearAll();
  history.push( setAlert1(true),{ 
 
    pathname: '/admin/userlist',
    // state:{ message:"Fund Saved Successfully" }
   });
    }else{ 
      
      setAlert(true);
      if (requiredState === "") {
        setrequiredState("error");
      }
    
      if (requiredStateFirstName === "") {
        setrequiredStateFirstName("error");
      }
    
    
      if (requiredStateLastName === "") {
        setrequiredStateLastName("error");
      }
    
      if (requiredStatePassword === "") {
        setrequiredStatePassword("error");
      }
      if (typeEmailState === "") {
        settypeEmailState("error");
      }
      if (typeEmailState === "") {
         setRequiredUserType("error");
      }

   
    }
  };
  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };


  const handleSimple = event => {
    
    setMainRole(event.target.value);
  };

  const clearAll = () => {
    setFirstName("");
    setLastName("");
    setLogin("");
    setPassword("");
    setEmail("");
    setMainRole("");
    setJobTitle("");
   

  }
  
  
  const handleChange = (value) => {
    
  }

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }

  if (requiredStateFirstName === "") {
    setrequiredStateFirstName("error");
  }


  if (requiredStateLastName === "") {
    setrequiredStateLastName("error");
  }

  if (requiredStatePassword === "") {
    setrequiredStatePassword("error");
  }

  if (requiredStateConfirm === "") {
    setrequiredStateConfirm("error");
  }
  if (requiredStateJobTitle === "") {
    setrequiredStateJobTitle("error");
  }
  

  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  console.log("verifyEmail"+value);
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

// const typeClick = () => {
 
//   if (typeEmailState === "") {
//     settypeEmailState("error");
//   }
  
// };


  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
      {/* <GridItem xs={12}>
     <OrgSelected orgName={organisationName}/>
     </GridItem>  */}
         <GridItem xs={12} sm={12} md={12}> 
      {/* <OrgSelected orgName={selectedOrganization}/> */} 
      {/* <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="#" onClick={handleClickHome} className={classes.link}>
        <HomeIcon className={classes.icon} />
      Home
      </Link>
      <Link
        color="inherit"
        href="#"
        onClick={handleClickUserlist}
        className={classes.link}
      >
        <WhatshotIcon className={classes.icon} />
       User List
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
       Create User
      </Typography>
    </Breadcrumbs> */}
    <br/>
    <br/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} icon>
          <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Create User</text> <span style={{marginLeft:"610px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
         <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"createUser"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                
            </CardHeader>
            <CardBody>

            {/* <h5><strong>Create {organisationName} Users</strong></h5> */}
   
              <GridContainer>
           
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name *"
                    id="firstName"

                    success={requiredStateFirstName === "success"}
                    error={requiredStateFirstName === "error"}
                    //id="required"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setFirstName(event.target.value);
                          setrequiredStateFirstName("success");
                        } else {
                          setrequiredStateFirstName("error");
                        }
                        setrequiredFirstName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredStateFirstName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="User Name *"
                    id="login"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    //id="required"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setLogin(event.target.value);
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name *"
                    id="lastName"

                    success={requiredStateLastName === "success"}
                    error={requiredStateLastName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setLastName(event.target.value);
                          setrequiredStateLastName("success");
                        } else {
                          setrequiredStateLastName("error");
                        }
                        setrequiredLastName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredStateLastName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="System Generated Password"
        />
                </GridItem> */}
                 <GridItem xs={12} sm={12} md={6}>
                <CustomInput 
                  labelText="Email *"

                    success={typeEmailState === "success"}
                    error={typeEmailState === "error"}
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          setEmail(event.target.value);
                          settypeEmailState("success");
                        } else {
                          settypeEmailState("error");
                        }
                        settypeEmail(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                        typeEmailState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>

              </GridContainer>

              
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Office Number"
                    id="officeNumber"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Password *"
                    id="password"

                    success={requiredStatePassword === "success"}
                    error={requiredStatePassword === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setPassword(event.target.value);
                          setrequiredStatePassword("success");
                        } else {
                          setrequiredStatePassword("error");
                        }
                        setrequiredPassword(event.target.value);
                      },
                      type: "password",
                      endAdornment:
                        requiredStatePassword=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Confirm Password"
                    id="last-name"

                    success={requiredStateConfirm === "success"}
                    error={requiredStateConfirm === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredStateConfirm("success");
                        } else {
                          setrequiredStateConfirm("error");
                        }
                        setrequiredConfirm(event.target.value);
                      },
                      type: "password",
                      endAdornment:
                     
                        requiredStateConfirm === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Mobile Number"
                    id="mobileNumber"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem> */}
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Confirm Password"
                    id="last-name"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "password",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem> */}
              </GridContainer>
            
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Job Title"
                    id="jobTitle"

                    success={requiredStateJobTitle === "success"}
                    error={requiredStateJobTitle === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setJobTitle(event.target.value);
                          setrequiredStateJobTitle("success");
                        } else {
                          setrequiredStateJobTitle("error");
                        }
                        setrequiredJobTitle(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateJobTitle === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>


            

                   <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            success={requiredUserType === "success"}
            error={requiredUserType === "error"}
          >
            User Type*
          </InputLabel>
          <Select
            success={requiredUserType === "success"}
            error={requiredUserType === "error"}
            formControlProps={{
              fullWidth: true
            }}


            inputProps={{
              onChange: event => {
                if (verifyLength(event.target.value, 1)) {
                  setMainRole(event.target.value);
                  setRequiredUserType("success");
                } else {
                  setRequiredUserType("error");
                  setMainRole("");
                }
                setrequiredState(event.target.value);
              },
              type: "text",
              endAdornment:
                requiredUserType === "error" ? (
                  <InputAdornment position="end">
                    <Close className={classes.danger} />
                  </InputAdornment>
                ) : (
                  undefined
                ),
                name: "mainRole",
                id: "mainRole"
            }}
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={mainRole}
            onChange={handleSimple}
            // inputProps={{
            //   name: "mainRole",
            //   id: "mainRole"
            // }}
          >
      
           
            <MenuItem value="ASSET_MANAGER">
             ASSET MANAGER
            </MenuItem>
            <MenuItem value="FUND_MANAGER">
             FUND MANAGER
            </MenuItem>
            <MenuItem value="COMPANY_ADMIN">
             MANAGEMENT
            </MenuItem>
          </Select>
        </FormControl>
        </GridItem>

     
                 {/* <GridItem xs={12} sm={12} md={6}>
                 <FormControlLabel
           control={
             <Checkbox
               tabIndex={-1}
               // onClick={() => handleToggle(22)}
               // checked={checked.indexOf(22) !== -1 ? true : false}
               checkedIcon={<Check className={classes.checkedIcon} />}
               icon={<Check className={classes.uncheckedIcon} />}
               classes={{
                 checked: classes.checked,
                 root: classes.checkRoot
               }}
             />
           }
           classes={{ label: classes.label }}
           label="Enabled"
         />
                 </GridItem> */}
</GridContainer>

              

              <GridContainer>
 

                


                <GridItem xs={12} sm={12} md={12}>
      <br/>
      <br/>
      <br/>
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>
              </GridContainer>

              <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>
                     <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
User Saved Successfully!
</SweetAlert>
</div>

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}
Userform.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPuserSaveRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Userform);