import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TMAMHeatMapPropertyTenant from "components/Charts/TMAMHeatMapPropertyTenant.js";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBaseProperty from 'components/MenuBase/SideMenuBaseProperty.js';
import Card from "components/Card/Card.js";

import SideMenuBaseAssetManager from 'components/MenuBase/SideMenuBaseAssetManager.js';

// import TMAMHeatMapPropertyRisk from "components/Charts/TMAMHeatMapPropertyRisk.js";
import TMAMHeatMapRisk1 from "components/Charts/TMAMHeatMapRisk1.js";

import TMHeatMapRisk1 from "components/Charts/TMHeatMapRisk1.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Pagination from '@material-ui/lab/Pagination';
import { getPropertyCountByOrgAndUser } from "store/sagas/tenantmonitoring";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import TMAMNoDashboardConfigured from "./TMAMNoDashboardConfigured";

const useStyles = makeStyles(styles);

const TMHeatMapAssetManager = ({user,tmProperyByFundRequest,tmPropertyByFund,tmFundPropertyDashboard,organizationId,tmPropertyDashboardByFundRequest,
  tmFundPropertyDashboardRequest,propertyByOrg,tmProperyTenantsByOrganizationRequest,propertyHeatMap,fundId,tmPropertyDashboard,fundName ,tmPropertyCountByOrgAndUserRequest,propertyCountByOrgAndUser,
  tmPropertyListOfOrgByAssetManagerRequest,propertyListAssetManager}) =>{
  const classes = useStyles(); 
  
  const history = useHistory();
  const[heatMapPropertyVisible,setHeatMapPropertyVisible]=React.useState(true);
  const[heatMapPropertyRiskVisible,setHeatMapPropertyRiskVisible]=React.useState(false);
  const[heatMapPropertyTenantVisible,setHeatMapPropertyTenantVisible]=React.useState(false);
  const[propertyId,setPropertyId]=React.useState("");
  const[propertyName,setPropertyName]=React.useState("");
  const [selectedFundId, setSelectedFundId] = React.useState("");
  const [selectedFundName, setSelectedFundName] = React.useState("");
  const [selectedFundManagerName, setSelectedFundManagerName] = React.useState("");

  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);


  const [pageSize, setPageSize] = React.useState(10);
  const [pageNum, setPageNum] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState("");
  const [page, setPage] = React.useState(1);

  const[showHeatMapFundRisk,setShowHeatMapFundRisk]= React.useState(false);

  const [userId, setUserId] = React.useState(user.id);

  //const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

  // const [showHeatMap, setShowHeatMap] = React.useState(true);
  // const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  // const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
   const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  // const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);

  const[ noDashboardConfigured, setNoDashboardConfigured]= React.useState(true);

  const [month, setMonth] = React.useState("");

  const [year, setYear] = React.useState(2020);
  const [dataStatus, setDataStatus] = React.useState(false);

  const enableProperty = (value) => {
   
    setPropertyId(value.propertyId); 
    setPropertyName(value.propertyName);
    setSelectedFundName(value.fundName);
    setSelectedFundManagerName(value.fundManagerName);
    setShowHeatMapFundRisk(true);
    setHeatMapPropertyVisible(false);
    setHeatMapPropertyTenantVisible(false);
    
  }


      React.useEffect(() => {
      if(totalCount<=0){
        setNoDashboardConfigured(true);
      }

        if(!dataStatus){

          
         
          getTmDashboardPropertyMap(user.organizationId,userId,pageSize,pageNum); 
          console.log("tmPropertyDashboard"+JSON.stringify(tmPropertyDashboard));
          getTmDashboardPropertyList(user.organizationId,userId,pageSize,pageNum); 
          
         // getTmPropertyCountByOrgAndUser(user.organizationId,user.id);
        }
      //   else{
      // //alert(propertyListAssetManager.resultSize);
      // console.log("tmPropertyDashboard"+JSON.stringify(tmPropertyDashboard));
      //   }
          setTimeout(() => {
            setDataStatus(true);
            }, 3000);
         
        }, [dataStatus]);


        const enableTenant = (range) => {
   
          const {rangeFrom,rangeTo,month,year}=range;
          setSelectedRangeFrom(range.rangeFrom);
          setSelectedRangeTo(range.rangeTo);
          setMonth(range.month.substr(0,3));
          setYear(range.year); 
          setHeatMapPropertyRiskVisible(false);
          setHeatMapPropertyVisible(false);
          setShowHeatMapFundRisk(false);
          setHeatMapPropertyTenantVisible(true);
         
         
         // setTenantActive(true);
       //   setHeadingText("Selected Tenant Risk Scores by Month");
         // setShowHeatMapTenantRisk(false);
        }
  
        const getTmDashboardPropertyMap = async (organisationId,userId,pageSize,pageNum) => {
          await tmPropertyDashboardByFundRequest(organisationId,userId,pageSize,pageNum);
          };

          const getTmDashboardPropertyList = async (organisationId,userId,pageSize,pageNum) => {
            await tmPropertyListOfOrgByAssetManagerRequest(organisationId,userId,pageSize,pageNum);
            };

            const  handlePageChange =(event, value) => {
             
              setPage(value);
              getTmDashboardPropertyList(user.organizationId,userId,pageSize,value-1);
               
              getTmDashboardPropertyMap(user.organizationId,userId,pageSize,value-1); 
              setTimeout(() => {
               // setDataStatus(true);
                }, 6000);
              console.log(propertyListAssetManager.resultSize);
                  
                  };

            const getTmPropertyCountByOrgAndUser= async (organisationId,userId) => {
              await tmPropertyCountByOrgAndUserRequest(organisationId,userId);
         
              };


            const handleChange = (propertyId,propertyName) => {
            
              setHeatMapPropertyVisible(false);
              setHeatMapPropertyRiskVisible(true);
              setPropertyId(propertyId);
              setPropertyName(propertyName);
             //  history.push({
             //   pathname: '/admin/dashboard/',
             //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
             // })
             
 
            //  setDashboardSelectedTenantId(tenantId);
             }

   
    function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

   const chartOps = {


      series: tmPropertyDashboard,
      options: {
        yaxis: {
          labels: {
            show: false,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '14px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                    position: 'top',
                    labels: {
                      show: true,
                      
                        style: {
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  colors: ['black','black','black','black','black','black','black','black','black','black','black','black',],
                                  
                                }
                              }
                              },
        
        chart: {
          height: 250,
          type: 'heatmap',
          legend: {
            show: false,
          },
          toolbar:{
            show:false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
     
        },
        
        plotOptions: {
          heatmap: {
            xaxis:{
              position: 'top',  
          },
            yaxis: {
              labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 300,
                style: {
                    colors: [],
                    fontSize: '50px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 400,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
          showForNullSeries: true,
          show : false,
          // formatter: function(seriesName, opts) {
          //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
          // }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 1
        },
        title: {
          text: ''
        },
      },
    
    
    };
  
    if(dataStatus){



    return (
      
      <Card>     


      <div id="chart">
    
      <GridItem >
<Button color="info" size="sm" round className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}  onClick={() =>history.push({ 
                           pathname: '/admin/dashboardmanager',
                        
                        
                         }) }>
                      
       Grid View 
        </Button>
</GridItem>   
   {heatMapPropertyVisible&&
      <GridContainer >


 <GridItem xs={12} sm={5} md={5}>
        {heatMapPropertyVisible&&   <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Property Risk Score by Month </b></h3>}
         </GridItem>



         {heatMapPropertyVisible&&  <GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight:"105px",paddingTop:'10px',textAlign:"right"}} >
             {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
       
              <br></br>  
              <br></br>
                <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
               </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>
             

  </GridItem>
    }


        <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}}>
        {heatMapPropertyVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>PROPERTY NAME</div></b></h5>
      <SideMenuBaseProperty style ={{height:propertyListAssetManager.listHeight+'px'}} items={ propertyListAssetManager.propertyList } parentCallBack={enableProperty} height={propertyListAssetManager.lineHeight}/>
       </div>}
     </GridItem>
 {/* <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}}>
        {heatMapPropertyVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>PROPERTY NAME</div></b></h5>
      <SideMenuBaseProperty style ={{height:"200px"}} items={ propertyListAssetManager.propertyList } parentCallBack={enableProperty} height={propertyListAssetManager.lineHeight}/>
       </div>}
     </GridItem> */}


     {heatMapPropertyVisible &&    <GridItem xs={12} sm={9} md={9} style={{marginLeft:"-37px"}}>
  
  
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={propertyListAssetManager.chartHeight} />
    
</GridItem>}
<GridItem xs={12} sm={3} md={3}></GridItem>


<GridItem xs={12} sm={9} md={9} className={classes.vertical} style={{paddingLeft:"30px",paddingTop:'10px',textAlign:"left"}} >
<div >
{heatMapPropertyVisible && propertyListAssetManager.totalPages>1&& <Pagination variant="outlined" color="primary" 
shape="rounded" count={propertyListAssetManager.totalPages} page={page}  onChange={handlePageChange} size="large" />}



</div> 
</GridItem>

<br/>
<br/>
<br/>
  

    



    

 </GridContainer>


}

<div>



{showHeatMapFundRisk && <TMAMHeatMapRisk1 propertyName={propertyName} propertyId={propertyId} parentCallBack ={enableTenant} selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/> }

 {/* {heatMapPropertyRiskVisible && <TMAMHeatMapPropertyRisk propertyId={propertyId} propertyName={propertyName} parentCallBack ={enableTenant}/>} */}
 {heatMapPropertyTenantVisible && <TMAMHeatMapPropertyTenant propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year}  />}


 {noDashboardConfigured && <TMAMNoDashboardConfigured/>}

 </div>
 </div>


 </Card> 


    )
  }else{
    return(
        <div align="center">
        <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={2000} //3 secs
      /></div>
    )
      }
    


}


TMHeatMapAssetManager.propTypes = {
  loading: PropTypes.bool.isRequired,
  tmPropertyDashboardByFundRequest: PropTypes.func.isRequired,
  tmProperyTenantsByOrganizationRequest: PropTypes.func.isRequired,
  tmPropertyByOrgIdRequest: PropTypes.func.isRequired,
  tmFundPropertyDashboardRequest: PropTypes.func.isRequired,
  tmPropertyListOfOrgByAssetManagerRequest: PropTypes.func.isRequired,
  tmPropertyCountByOrgAndUserRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  propertyHeatMap: state.tenantmonitoring.propertyHeatMap,
  tmPropertyByFund: state.tenantmonitoring.tmPropertyByFund,
  tmPropertyDashboard: state.tenantmonitoring.tmPropertyDashboard,
  tmFundPropertyDashboard: state.tenantmonitoring.tmFundPropertyDashboard,
  propertyListAssetManager: state.tenantmonitoring.propertyListAssetManager,
  propertyCountByOrgAndUser: state.tenantmonitoring.propertyCountByOrgAndUser,
  propertyByOrg: state.tenantmonitoring.propertyByOrg,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TMHeatMapAssetManager);

