import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Assignment from "@material-ui/icons/Assignment";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import ListSelectionContify from 'components/ListSelection/ListSelection.js';
import { Hidden } from '@material-ui/core';
import { megaColor } from 'assets/jss/material-dashboard-pro-react';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


 

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CustomTabs from "components/CustomTabs/CustomTabs.js";





import SearchIcon from '@material-ui/icons/Search';
import CustomTabforOrderPopup from 'components/CustomTabs/CustomTabforOrderPopup';
import AlphabeticalSearch from './AlphabeticalSearch';
import CategorySearch from './CategorySearch';

// import Box from '@mui/material/Box';



 const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },
 
];




const IndustrySearch = ({loading,tmContifyCompanySearchRequest,contifyCompanySearchList,sendDataBack}) => {

  const [input, setInput] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [companyUrl, setCompanyUrl] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [postCode, setPostCode] = React.useState('');

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };




  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



const [name,setName] =React.useState("");
const [type,setType] =React.useState("");
const [comments,setComments] =React.useState("");
const [userId,setUserId] =React.useState(""); 
const [country,setCountry] =React.useState("");
const [dataStatus,setDataStatus] =React.useState(false);
const [companySearchRequest,setCompanySearchRequest] =React.useState("");

// const [showButton, setShowButton] = React.useState(true);
const [hideButton, setHideButton] = React.useState(true);

const [myOptions, setMyOptions] = useState([])


  const handleChange = (value) => {
  
  }
  const recieveContifySelection = (value) => {
  
    sendDataBack(value);
    
  }


  const getDataFromAPI = () => {
    console.log("Options Fetched from API")
  
    fetch('http://dummy.restapiexample.com/api/v1/employees').then((response) => {
      return response.json()
    }).then((res) => {
      console.log(res.data)
      for (var i = 0; i < res.data.length; i++) {
        myOptions.push(res.data[i].employee_name)
      }
      setMyOptions(myOptions)
    })
  }

  const submitForm = () => {
    setHideButton(false);
    
    const companySearchRequest1={
      companyName:companyName,
      companyUrl:companyUrl,
      state:state,
      city:city,
      address:address,
      postCode:postCode,
    
    
    }
  
    setCompanySearchRequest(companySearchRequest1);
    
    tmContifyCompanySearchRequestA(companySearchRequest1).then((data)=>{
      //setDataStatus(true);
   
     setTimeout(() => {
      
     
 
       setDataStatus(true);
       
      
      setHideButton(true);
      },2000);
      
    });
   
  };
  
   

  React.useEffect(() => {
    

    console.log("java..........1.........."+dataStatus);  
    console.log("list"+contifyCompanySearchList);
   
    }, [dataStatus]);
    
  
    const tmContifyCompanySearchRequestA = async (CompanySearchRequest) => {
      // await tmCreditSafeCompanyListRequest(companyListRequest);
       await tmContifyCompanySearchRequest(CompanySearchRequest)
       
       
       
      };

    //   class Navbar extends React.Component{
    //     render() {
    //         return (
    //             <div>
    //               <ul id="nav">
    //                 <li><a href="#">Search</a></li>
    //                 <li><a href="#">Alphabetical</a></li>
    //                 <li><a href="#">Category</a></li>
    //                 <li><a href="#">Naics Code</a></li>
    //               </ul>
    //             </div>
    //         );
    //     }
    // }
    

    
      const handleCancel = e => {
        setCompanyName("");
        setCompanyUrl("");
        setAddress("");
        setState("");
        setCity("");
        setPostCode("");
        setDataStatus(null);
   
        setTimeout(() => {
          e.target.value=null;
          }, 1000);
        //  setProgress(0); 
          };

  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

  const [value, setValue] = React.useState(null);


  

  const classes = useStyles(); 

  return (
    <div >
      <form>
      <GridContainer>
         
        <GridItem xs={12} sm={12} md={12}>
       

      {/* <Card> */}
       <div style={{  marginTop: '60px',paddingLeft:"40px" }}>
      {/* <h3>Greetings from GeeksforGeeks!</h3> */}
    
     <Autocomplete
        style={{ width: "90%" }}
        freeSolo
        autoComplete
        autoHighlight
        options={myOptions}
        
        renderInput={(params) => (
       
          <TextField {...params}
            onChange={getDataFromAPI}
            // variant="outlined"
            
           label="Search here"
          />
        )}
        
      />
    </div>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
    {/* </Card> */}
      {/* <Router>
      <Navbar />
      <Switch>
        <Route path='/search' exact component={Search} />
        <Route path='/alphabetical' component={Alphabetical} />
        <Route path='/category' component={Category} />
        <Route path='/naics-code' component={NaicsCode} />
      
      </Switch>
    </Router>
           */}
           

  
             


              <Clearfix /> 
            
        </GridItem>
      
        <br></br><br></br>
              
        <br></br><br></br>
      </GridContainer>
      </form>
    </div>
  );
}

IndustrySearch.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmContifyCompanySearchRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  contifyCompanySearchList: state.tenantmonitoring.contifyCompanySearchList,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndustrySearch);

