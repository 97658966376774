import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfOne from './PdfOne';
import PdfReport from './PdfReport';
import ReactPDF from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { set } from "date-fns";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import PdfMonthlyReport from "./PdfMonthlyReport";
import { Link } from 'react-router-dom';
import MonthlyReport from "./ReportLatest.pdf";
import MonthlyFundReport from "./MonthlyFundReport";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";

const styles = {

  app: {
    textAlign: "center"
  },
  downloadLink: {
    fontSize: "large",
    fontWeight: "bold",
    textAlign:"center",
    paddingLeft:"686px"

  }
  
};
const useStyles = makeStyles(styles);

const MonthlyFundReportDownload = ({user,tmGenerateMonthlyFundReportRequest,monthlyFundReport}) =>{


  const location = useLocation();
  const [dataStatus, setDataStatus] = React.useState(false);
  const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
  // const [organisationId, setOrganisationId] = React.useState(user.organizationId);
const[userId,setUserId]=React.useState(user.id);
const[fundId, setFundId] =React.useState(location.state.fundId);
const[fundName, setFundName] = React.useState(location.state.fundName);
const[imageUrl,setImageUrl] = React.useState(location.state.imageUrl);
 
  React.useEffect(() => {
   
    // if(!dataStatus){
      // setDataStatus(false);
      // setImageUrl(require(location.state.imageUrl));
   
     getMonthlyFundPdfReports(organisationId,fundId);

  setTimeout(() => {
    setDataStatus(true);
  }, 500);

 
    }, [dataStatus]);

    const getMonthlyFundPdfReports = async (organisationId,fundId) => {
      await tmGenerateMonthlyFundReportRequest(organisationId,fundId);
      };

      const classes = useStyles();

      if(dataStatus&&monthlyFundReport!==""){
    return(

      <div className={classes.app}>

<div className={classes.downloadLink}>

{/* <a href={MonthlyReport} target="_blank" 
                        rel="noreferrer" >
               Print</a> */}
        <PDFDownloadLink
          document={<MonthlyFundReport monthlyFundReport={monthlyFundReport} fundName={fundName} fundId={fundId} imageUrl={location.state.imageUrl}/>}
   
          fileName={fundName  + ".pdf"}
        >
          {/* {({ blob, url, loading, error }) =>
            loading ? "Loading..." : <Button color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
            <PrintIcon/>  &nbsp;&nbsp;Print  
            </Button>
          } */}
        <Button color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
            <PrintIcon/>  &nbsp;&nbsp;Print  
            </Button>
       
        </PDFDownloadLink>
      </div>
     

      <div>
            <div style={{margin: "auto",fontSize:"2rem", color:"#000",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={10000} //3 secs
      />
            </div>
            <PDFViewer showToolbar={false} width={800} height={820} style={{border:"none",backgroundColor:"transparent"}} >
            <MonthlyFundReport monthlyFundReport={monthlyFundReport} fundName={fundName} fundId={fundId} imageUrl={location.state.imageUrl} organisationId={organisationId} />
            </PDFViewer>
        </div>


     {/* {monthlyFundReport!=="" ?  <div><PDFViewer width={800} height={820} showToolbar={false}>
     
      <MonthlyFundReport monthlyFundReport={monthlyFundReport} fundName={fundName} fundId={fundId} imageUrl={location.state.imageUrl}/>

      </PDFViewer></div> :  <div align="center">
            <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={500}
                width={100}
                timeout={3000} //3 secs
              /></div>} */}

    
    </div>

);
    }else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
    }

    MonthlyFundReportDownload.propTypes = {

      loading: PropTypes.bool.isRequired,
      tmGenerateMonthlyFundReportRequest: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    };
    
    const mapStateToProps = state => ({
      user: state.auth.loggedUser,
      monthlyFundReport: state.tenantmonitoring.monthlyFundReport,
 
    });              
    
    const mapDispatchToProps = dispatch => bindActionCreators(
      {
    
        ...TMActions,
      },
      dispatch,
    );
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps,
    )(MonthlyFundReportDownload);