// import React from "react";
// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";


// // @material-ui/icons
// import PermIdentity from "@material-ui/icons/PermIdentity";
// import Close from "@material-ui/icons/Close";

// // core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// import Heatmap from "components/Charts/Heatmap.js";

// import HeatMapProperty from "components/Charts/HeapMapProperty.js";

// import HeatMapTenant from "components/Charts/HeapMapTenant.js";
// import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

// import avatar from "assets/img/faces/marc.jpg";
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

// import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


// import FormControl from "@material-ui/core/FormControl";
// // import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";


// const useStyles = makeStyles(styles);

// const top100Films = [
//   { title: 'Mathew John Baynton', year: 1994 },
//   { title: 'Thomas Stewart Baker ', year: 1972 },
//   { title: 'Benjamin Thomas', year: 1974 },
//   { title: 'Steven Berkoff', year: 2008 },
//   { title: 'James Curry', year: 1957 },

// ];




// const Fund = ({}) => {

//   const [input, setInput] = React.useState('');

//   const defaultProps = {
//     options: top100Films,
//     getOptionLabel: (option) => option.title,
//   };

// //   //email validation
// // const [typeEmail, settypeEmail] = React.useState("");
// // const [typeEmailState, settypeEmailState] = React.useState("");


//   // type validation
// const [required, setrequired] = React.useState("");
// const [requiredState, setrequiredState] = React.useState("");
// // const [typeEmail, settypeEmail] = React.useState("");
// // const [typeEmailState, settypeEmailState] = React.useState("");
// const [number, setnumber] = React.useState("");
// const [numberState, setnumberState] = React.useState("");
// const [url, seturl] = React.useState("");
// const [urlState, seturlState] = React.useState("");
// const [equalTo, setequalTo] = React.useState("");
// const [whichEqualTo, setwhichEqualTo] = React.useState("");
// const [equalToState, setequalToState] = React.useState("");

// // range validation
// const [minLength, setminLength] = React.useState("");
// const [minLengthState, setminLengthState] = React.useState("");
// const [maxLength, setmaxLength] = React.useState("");
// const [maxLengthState, setmaxLengthState] = React.useState("");
// const [range, setrange] = React.useState("");
// const [rangeState, setrangeState] = React.useState("");
// const [minValue, setminValue] = React.useState("");
// const [minValueState, setminValueState] = React.useState("");
// const [maxValue, setmaxValue] = React.useState("");
// const [maxValueState, setmaxValueState] = React.useState("");

// const [fundName, setFundName] = React.useState("");




  
//   const handleChange = (value) => {
//     alert(value);
//   }

//   const submitForm = () => {


//   };

//   const flatProps = {
//     options: top100Films.map((option) => option.title),
//   };

//   // function that verifies if a string has a given length or not
// const verifyLength = (value, length) => {
//   if (value.length >= length) {
//     return true;
//   }
//   return false;
// };

//   const [value, setValue] = React.useState(null);


//   // function that returns true if value is email, false otherwise
// // const verifyEmail = value => {
// //   var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// //   if (emailRex.test(value)) {
// //     return true;
// //   }
// //   return false;
// // };

// // const typeClick = () => {
 
// //   if (typeEmailState === "") {
// //     settypeEmailState("error");
// //   }
  
// // };

//   const classes = useStyles(); 

//   return (
//     <div>
//       <form>
//       <GridContainer>
//         <GridItem xs={12} sm={12} md={12}>
//           <Card>
//         <CardHeader style={{ background: '#00628B', color:'white' }} icon>
//             {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
//               <CardIcon color="info">
//                 <PermIdentity />
//               </CardIcon>
//               <h3>
//              <b>  Create Fund</b>
//               </h3> 
//             </CardHeader>
//             <CardBody>

           
//             <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                 <CustomInput
//                     labelText=" Fund Name"
//                     id="property-name"

//                     success={requiredState === "success"}
//                     error={requiredState === "error"}
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyLength(event.target.value, 1)) {
//                          setFundName(event.target.value);
//                           setrequiredState("success");
//                         } else {
//                           setrequiredState("error");
//                         }
//                         setrequired(event.target.value);
//                       },
//                       type: "text",
//                       endAdornment:
//                         requiredState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}

//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Fund Type"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>
  
//               <GridContainer>
//               <GridItem xs={12} sm={12} md={6}>
//                 {<Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Fund Manager" margin="normal" />}
//       /> }
//                 </GridItem>
//                 {/* <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Legal Name"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem> */}

// <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Country"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 {/* <GridItem xs={12} sm={12} md={6}>
//                 <CustomInput 
//                   labelText="Notification Email"

//                     success={typeEmailState === "success"}
//                     error={typeEmailState === "error"}
//                     id="typeemail"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                     inputProps={{
//                       onChange: event => {
//                         if (verifyEmail(event.target.value)) {
//                           settypeEmailState("success");
//                         } else {
//                           settypeEmailState("error");
//                         }
//                         settypeEmail(event.target.value);
//                       },
//                       type: "email",
//                       endAdornment:
//                         typeEmailState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}
//                   />
//                 </GridItem> */}
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Comments"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
              
              
//               {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 2"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="# of Locations"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer> */}

// {/* 
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 3"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem> 


//                 <GridItem xs={12} sm={12} md={6}>
//         <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//             Use of Space
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//       </GridItem>

//                 </GridContainer>
//  */}

                   

//                 {/* <CustomDropdown
//         buttonText="Search for state"
//         dropdownList={[
//           // "Action",
//           // "Another action",
//           // "Something else here",
//           // {divider: true},
//           // "Separated link",
//           // {divider: true},
//           // "One more separated link",
//         ]} */}
      
              

           

                
     
                
//                 {/* { <GridItem xs={12} sm={12} md={4}>
//                   <CustomInput
//                     labelText="Country"
//                     id="country"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem> } */}

// {/* <GridContainer>

     
//                  <GridItem xs={12} sm={12} md={6}>  

//                 {<Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Country" margin="normal" />}
//       /> }
//                  </GridItem> 

//                  <GridItem xs={12} sm={12} md={6}>  

//                 {<Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Search for Property" margin="normal" />}
//       /> }
//                  </GridItem> 

//  </GridContainer>
              

              
//               {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
//                   <Autocomplete
//                   onInputChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//         {...defaultProps}
//         id="zipcode"
//         debug
    
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="ZipCode" margin="normal" />}
//       />
//                 </GridItem>
//               </GridContainer> */}


// {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="City"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="State"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
// </GridContainer>

//               {/* <Button color="rose" className={classes.updateProfileButton}>
//                 Update Profile
//               </Button> */}

// <br/>
// <br/>
//  <div className={classes.buttonGroup}>
//                 <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton} onClick={submitForm}>
//                   <b>Save</b>
//                 </Button>

//                     <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
//                   <b>Cancel</b>
//                 </Button>
              
//               </div>
              
              

//               <Clearfix /> 
//             </CardBody>
//           </Card>
//         </GridItem>
       
//       </GridContainer>
//       </form>

//     </div>
//   );
// }
// export default Fund;



import React,{useEffect,useState} from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import { saveFund } from "store/sagas/tenantmonitoring";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import { useHistory } from "react-router-dom";

import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import { useLocation } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; 
// import 'react-confirm-alert/src/react-confirm-alert.css';


import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

 import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Badge from "components/Badge/Badge.js";
import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  }
}));

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },




];




const Fund = ({ user,tmFundSaveRequest,tmFundManagerListRequest,fundManagerList }) => {
  const history = useHistory();

  const location = useLocation();
  const [input, setInput] = React.useState('');
  const [dataList, setDataList] = React.useState(fundManagerList);
  const [userId, setUserId] = React.useState("");
  const [dataStatus,setDataStatus]=React.useState(false);
  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };



  useEffect(() => {
      
    getFundManagerList(organizationId);


    setTimeout(() => {
      setDataStatus(true);
    }, 2000);
  },[dataStatus]);
  




 
  



  const gettmFundById = async () => {
    await tmFundManagerListRequest();
    
    };

  const getFundManagerList = async (organizationId) => {
    await tmFundManagerListRequest(organizationId);
    
    };

  const allFundManagerProps = {
  
    options: fundManagerList,
    getOptionLabel: (option) => option.firstName+" "+option.lastName
  };


  const saveFund = async (fund) => {
    tmFundSaveRequest(fund);
    
    };


    




//   //email validation
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation-jain
const [requiredFundNme, setrequiredFundNme] = React.useState("");
const [requiredStateFundNme, setrequiredStateFundNme] = React.useState("");
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [requiredFundMnger, setrequiredFundMnger] = React.useState("");
const [requiredStateFundMnger, setrequiredStateFundMnger] = React.useState("");


// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



//sweet alert
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}
// const hideAlert1 = () => {
//   setAlert1(false);
// }






const [name,setName] =React.useState("");
const [type,setType] =React.useState("");
const [comments,setComments] =React.useState("");

const [country,setCountry] =React.useState("");
const [managerRole,setManagerRole ]=React.useState("");
// const [propertyList,setPropertyList]=React.useState("");
const [currentStatus,setCurrentStatus]=React.useState("");
const [fundManagerId,setFundManagerId]=React.useState("");
const [roleName,setRoleName]=React.useState("");
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));





const handleChange = (value) => {
  };

  const selectedFundManager = (fundManager) => {

    setUserId(fundManager.id);
    setFundManagerId(fundManager.id);
   
  
  };

  function handleClickFundList(event) {
   // event.preventDefault();
   history.push({ 
    pathname: '/admin/fundlist'
   });
   
  }
  function hideAlert1(event) {
  history.push({ 
    pathname: '/admin/fundlist'
   });
  }
  function handleClickHome(event) {
    // event.preventDefault();
    history.push({ 
     pathname: '/'
    });
    
   }
   


   

  const submitForm = () => {
    
if(verifyLength(name,1)&&verifyLength(fundManagerId,1))
{
  
const fund={
  
  name:name,
  type:type,
  comments:comments,
  userId:fundManagerId,
  countryName:country,
  roleName:managerRole,
  organizationId:organizationId,
  // propertyList:propertyList,
  currentStatus:currentStatus,
  fundManagerId:fundManagerId,
  roleName:roleName
};

tmFundSaveRequest(fund);

clearAll();
history.push( setAlert1(true),{ 
 
  pathname: '/admin/fundlist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }



 else{
   setAlert(true);
   if (requiredStateFundNme === "") {
    setrequiredStateFundNme("error");
  }
  if (requiredStateFundMnger === "") {
    setrequiredStateFundMnger("error");
  }
  
 }

};



  const clearAll = () => {
    setName("");
    setType("");
    setComments("");
    setUserId("");
    setCountry("");
    setOrganizationId("");
    setManagerRole("");
    setCurrentStatus("");
    setFundManagerId("");
    setRoleName("");
  }
 
  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  


  const [value, setValue] = React.useState(null);


  // function that returns true if value is email, false otherwise
// const verifyEmail = value => {
//   var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (emailRex.test(value)) {
//     return true;
//   }
//   return false;
// };

const typeClick = () => {
 
  // if (typeEmailState === "") {
  //   settypeEmailState("error");
  // }

  if (requiredState === "") {
    setrequiredState("error");
  }
  if (requiredStateFundNme === "") {
    setrequiredStateFundNme("error");
  }
  if (requiredStateFundMnger === "") {
    setrequiredStateFundMnger("error");
  }
  
};
        

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
      {/*<GridItem xs={12} sm={12} md={12}>
      <OrgSelected orgName={selectedOrganization}/>
  <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="#" onClick={handleClickHome} className={classes.link}>
        <HomeIcon className={classes.icon} />
      Home
      </Link>
      <Link
        color="inherit"
        href="#"
        onClick={handleClickFundList}
        className={classes.link}
      >
        <WhatshotIcon className={classes.icon} />
       Fund List
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
       Create Fund
      </Typography>
    </Breadcrumbs>
    <br/>
    <br/>
  </GridItem>*/}
     
     {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem>  */}
     
        <GridItem xs={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Create Fund</text> <span style={{marginLeft:"600px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
    <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"createFund"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                      {/* </Link> */}

            </CardHeader>
            <CardBody>

           
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText=" Fund Name *"
                    id="property-name"

                    success={requiredStateFundNme === "success"}
                    error={requiredStateFundNme === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setName(event.target.value);
                          setrequiredStateFundNme("success")
                        } else {
                          setrequiredStateFundNme("error");
                          setName("");
                        }
                        setrequiredFundNme(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateFundNme  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Fund Type"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setType(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
              </GridContainer>
  
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                   success={requiredStateFundMnger === "success"}
                   error={requiredStateFundMnger === "error"}   
                  onChange={(event, newValue) => {
                    // handleChange(newValue);
                    selectedFundManager(newValue);
                   
                  
          }}
          inputProps={{
            onChange: value => {
              if (verifyLength(value, 1)) {
                setFundManagerId(value)
                setrequiredStateFundMnger("success");
              } else {
                setrequiredStateFundMnger("error");
                setFundManagerId("");
              }
              setrequiredStateFundMnger(value);
            },
            type: "text",
            endAdornment:
           
            requiredStateFundMnger === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                <Close className={classes.success} />
              </InputAdornment>
              )
          }}
          
                  {...allFundManagerProps}
        id="fundId"

       
        debug
        size='medium'
        
        renderInput={(params) => <TextField  success={requiredStateFundMnger === "success"}
        error={requiredStateFundMnger === "error"} {...params} onChange={({ target }) => setInput(target.value)} label="Fund Manager *" margin="normal" />}
      /> }




                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Legal Name"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem> */}

<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setCountry(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <br></br>
              <br></br>
              <GridContainer>
                
                 <GridItem xs={12} sm={12} md={12}>
<label>Add Comments:   </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<TextField style={{width: "80%"}}
          id="outlined-multiline-flexible"
          label=""
          multiline
          rowsMax={8}
          value={value}
          // onChange={handleChange}
          variant="outlined"



          inputProps={{
            onChange: event => {
              if (verifyLength(event.target.value, 1)) {
                setComments(event.target.value);
              } else {
                setrequiredState("error");
              }
              setrequired(event.target.value);
            },
            type: "text",
            endAdornment:
              requiredState === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                undefined
              )
          }}
        />

                  

     

        
                </GridItem>        

<br></br>
<br></br>
<br></br>
                <GridItem xs={12} sm={12} md={12}>
      
              <div className={classes.buttonGroup}>
                <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
                <SaveIcon/>  &nbsp;&nbsp;Save  
                </Button>

                {/* <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
                  <b>Cancel</b>
                </Button> */}
                {/* <Button color="info" size="sm" className={classes.middleButton}>
                  Middle
                </Button>
                <Button color="info" size="sm" className={classes.lastButton}>
                  Right
                </Button> */}
              </div>
        
         
        </GridItem>  

              </GridContainer>

           
           
              <div>
           
 
         <SweetAlert
          error
        show={alert}
        style={{ display: "block", marginTop: "-100px" }}
         onConfirm={() => hideAlert()}
         confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
         confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
         // onCancel={() => hideAlert()}
        confirmBtnCssClass={
          classes.button + " " + classes.error
        }
      >
        Please fill all mandatory fields
      </SweetAlert>
    
                   </div>
                   <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}
// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Fund Saved Successfully!
</SweetAlert>
</div>
         


              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}

Fund.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmFundSaveRequest: PropTypes.func.isRequired,
  tmFundManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  fundManagerList: state.tenantmonitoring.fundManagerList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fund);