
import React,{useEffect,useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from "components/Accordion/Accordion.js";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Property from 'views/Property/Property';
import Fund from 'views/Fund/Fund';
import Tenant from 'views/Tenant/Tenant';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Divider from '@material-ui/core/Divider';

import { Tooltip } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import SaveIcon from '@material-ui/icons/Save';

import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TouchAppIcon from '@material-ui/icons/TouchApp';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import AttachFileIcon from '@material-ui/icons/AttachFile';

import Datetime from "react-datetime";
import Moment from 'moment';

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import { Grid } from '@material-ui/core';
import IndustryModalPage from "./IndustryModalPage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  testLabel: {
    color: "#000000 !important"
}

}));


const OrderFormAccordion = ({tmPropertySaveRequest,propertyByOrg,tmPropertyByOrgIdRequest,tmPropertyListByOrgRequest,tmPropertyEditRequest,propertyEditObj,tmPropertyByIdRequest,property
 }) => {


  const history = useHistory();
  const location = useLocation();


  const classes = useStyles();
  const [value0, setValue0] = React.useState("");
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openPremium, setOpenPremium] = React.useState(false);
  const [openAdvantage, setOpenAdvantage] = React.useState(false);
  const [openBasic, setOpenBasic] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [leaseType,setLeaseType]=React.useState("");
 const[value1,setValue1] = React.useState("No1");
 const[value2,setValue2] = React.useState("Private");
 const[value3,setValue3] = React.useState("No");

 const [fields, setFields] = React.useState([{ value: null }]);
 const [category,setCategory] =React.useState("");
 const [buildingClass,setBuildingClass] =React.useState("");
 const [selected, setSelected] = React.useState("");
 const [marketArea,setMarketArea] =React.useState("");

  const [leaseStartDate,setLeaseStartDate]=React.useState("");
const [leaseEndDate,setLeaseEndDate]=React.useState("");

const [showPropertyLookup,setShowPropertyLookup] = React.useState(false);
const [showPropertyAccordion,setShowPropertyAccordion] = React.useState(false);
const [showGaurantor,setShowGaurantor] = React.useState(false);
const [showAdditionalBaseRent, setShowAdditionalBaseRent] = React.useState(false);
const [showAddBaseRentAbatement, setShowAdditionalBaseRentAbatement] = React.useState(false);
const [showAddRecoverableExpense, setShowAddRecoverableExpense] = React.useState(false);
const [showAddRecoverableExpenseAbatement, setShowAddRecoverableExpenseAbatement] = React.useState(false);
const [showAddTenantAllowance, setShowAddTenantAllowance] = React.useState(false);
const [showAddLeaseCommissions, setShowAddLeaseCommissions] = React.useState(false);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));




const [propertyName,setPropertyName] =React.useState("");
const [city,setCity] =React.useState("");
const [address1,setAddress1] =React.useState("");
const [state,setState] =React.useState("");
const [zipCode,setZipCode] =React.useState("");
const [countyName,setCountyName] =React.useState("");
const [fipsCounty,setFipsCounty] =React.useState("");
const [msa,setMsa] =React.useState("");

const [rentableBuildingArea,setRentableBuildingArea] =React.useState("");
const [leedCertified,setLeedCertified] =React.useState("");
const [energyStar,setEnergyStar] =React.useState("");
const [tenancy,setTenancy] =React.useState("");
const [noOfParkingSpaces,setNoOfParkingSpaces] =React.useState("");
const [propertyOccupancy,setPropertyOccupancy] =React.useState("");
const [marketVaccancy,setMarketVaccancy] =React.useState("");
const [yearBuilt,setYearBuilt] =React.useState("");
const [subMarketVaccancy,setSubMarketVaccancy] =React.useState("");
const[id,setId]= React.useState("");
const [input, setInput] = React.useState('');

const [dataStatus,setDataStatus]=React.useState(false);

const [propertyId,setPropertyId] =React.useState(""); 

const [openSelectNaics, setOpenSelectNaics] = React.useState(false);
const [openBaseRent, setOpenBaseRent] = React.useState(false);

const [showUpload, setShowUpload] = React.useState(false);

const [requiredTenantName, setrequiredTenantName] = React.useState("");
const[tenantName,setTenantName] = React.useState("");

const [requiredLegalEntity, setrequiredLegalEntity] = React.useState("");
const[legalEntity,setLegalEntity] = React.useState("");



const handleClickOpenSelectNaics = () => {
  setOpenSelectNaics(true);

};

const handleCloseSelectNaics = () => {
  setOpenSelectNaics(false);

};

const handleClickOpenBaseRent = () => {
  setOpenBaseRent(true);

};

const handleCloseBaseRent = () => {
  setOpenBaseRent(false);

};
const allPropertyProps = {

  options: propertyByOrg,
   getOptionLabel: (option) => option.propertyName,
 };

 const selectedProperty = (value) => {
  setPropertyId(propertyId);
};

const changeSelectOptionHandler = (event) => {
  setCategory(event.target.value);
console.log(event.target.value);

};

const setValueForBuildingClass= event => {
  setBuildingClass(event.target.value);
};



  const setValueForMarketArea= event => {
     setMarketArea(event.target.value);
   
   };
const industrial = [
  "Select...",
  "Distribution Warehouse",
  "Flex space",
  "Industrial- Business Park",
  "Industrial Condo",
  "Manufacturing",
  "Office Showroom",
  "R&D",
  "Refrigerated/Cold Storage",
  "Self/Mini-Storage Facillity",
  "Truck Terminal/Hub/Transit",
  "Warehouse",
  "Other"
];
const office = ["Select...","Institutional/Government",
"Medical Office",
"Office Building",
"Office- Business Park",
"Office Condo",
"Office- R&D",
"Office- Warehouse",
"Other"];
const retail = ["Select...","Anchor",
  "Community Center",
  "Day Care Facillity/Nursery",
  "Free Standing Building",
  "Neighborhood Center",
  "Outlet Center",
  "Post Office",
  "Regional Center/Mall",
  "Restaurant",
  "Retail Pad",
 "Service/Gas Station",
  "Street Retail",
  "Strip Center",
  "Speciality Center",
  "Theme/Festival Center",
  "Vehicle Related",
  "Other"];

  const land = ["Select..."," Commercial/Other","Industrial","Leased Land","Mutlifamily",
"Office","Residential","Retail","Retail-Pad","Other"];
 
const healthCare = ["Select..."," Acute Care Hospital","Other"];

const mixedUse = ["Select...","  Neighborhood Commercial Zoing","Main Street Residential/Commercial",
"Urban Residential / Commercial","Office Convenience","Office/ Residential","Shopping Mall Conversion",
"Retail District Retrofit","Live / Work"];

const other = ["Select..."," Golf Related","Marina","Religious Facillity","Special Purpose",
" Sport & Entertainment","Theatre/Performing Arts","Other"];

let [type, setType] = React.useState(null);
let options = null;
if (category === "Industrial") {
type = industrial;
} else if (category === "Office") {
type = office;
} else if (category === "Retail") {
type = retail;
} else if (category === "Land") {
type = land;
}
else if (category === "Health Care") {
type = healthCare;
}
else if (category === "Mixed Use") {
type = mixedUse;
}
else if (category === "Other") {
type = other;
}

const typeClick = () => {

  if (requiredTenantName === "") {
    setrequiredTenantName("error");
  }


  if (requiredLegalEntity === "") {
    setrequiredLegalEntity("error");
  }

  // if (requiredAddressName === "") {
  //   setrequiredAddressName("error");
  // }


  // if (typeEmailState === "") {
  //   settypeEmailState("error");
  // }


  // if (requiredCityName === "") {
  //   setrequiredCityName("error");
  // }

  // if (requiredStateName=== "") {
  //   setrequiredStateName("error");
  // }
  // if (requiredCountryName=== "") {
  //   setrequiredCountryName("error");
  // }
  

  // if (requiredProperty=== "") {
  //   setrequiredProperty("error");
  // }



  // if (numberState === "") {
  //   setnumberState("error");
  // }

  // if (rentState=== "") {
  //   setrentState("error");
  // }

  // if (phoneNumState=== "") {
  //   setPhoneNumState("error");
  // }

  // if (phoneNumTwoState=== "") {
  //   setPhoneNumTwoState("error");
  // }



  // if (urlState === "") {
  //   seturlState("error");
  // }
  // if (equalToState === "") {
  //   setequalToState("error");
  // }
};

useEffect(() => {
 


  getPropertyListByOrg(organisationId);
  getPropertyById(propertyId);
  if(propertyEditObj){
    setPropertyName(propertyEditObj.propertyName);
    setCity(propertyEditObj.city);
    setAddress1(propertyEditObj.address1);
    setState(propertyEditObj.state);
    setCountyName(propertyEditObj.countyName);
    setFipsCounty(propertyEditObj.fipsCounty);
    setMsa(propertyEditObj.msa);
    setRentableBuildingArea(propertyEditObj.rentableBuildingArea);
    setNoOfParkingSpaces(propertyEditObj.noOfParkingSpaces);
    setPropertyOccupancy(propertyEditObj.propertyOccupancy);
    setMarketVaccancy(propertyEditObj.marketVaccancy);
    setYearBuilt(propertyEditObj.yearBuilt);
    setSubMarketVaccancy(propertyEditObj.subMarketVaccancy);
    setZipCode(propertyEditObj.zipCode);
    setTenancy(propertyEditObj.tenancy);
    setOrganisationId(propertyEditObj.organisationId);
    setPropertyId(propertyEditObj.propertyId);
   
  
    setCategory(propertyEditObj.category);
   
    setBuildingClass(propertyEditObj.buildingClass);
    // setLabelType(propertyEditObj.type);
     setType(propertyEditObj.type);
    // setSelected(propertyEditObj.type);
   
    setMarketArea(propertyEditObj.marketArea);
    
setLeedCertified(propertyEditObj.leedCertified);
setEnergyStar(propertyEditObj.energyStar);
// if(propertyEditObj.marketArea==="CBD"){
// setShowFieldCBD(true);
// setSubMarketArea(propertyEditObj.subMarketArea);
// setSubMarketOccupancy(propertyEditObj.subMarketOccupancy);
// }else if(propertyEditObj.marketArea!=="CBD"){
// setShowFieldCBD(false);
// }
  //  getAssetManagerList(organizationId);
    // getFundListByOrg(organizationId);
  }

  

  }, [dataStatus]);


  const getPropertyListByOrg = async (organisationId) => {
     await tmPropertyByOrgIdRequest(organisationId);
  
      };

  // const getpropertyList = async (organisationId) => {
  //   await tmPropertyListByOrgRequest(organisationId);
  //   };
    

  const getPropertyById = async (propertyId)=>{
      
    await  tmPropertyByIdRequest(propertyId);
   // setDataStatus(true);
 
  }
  setTimeout(() => {
    setDataStatus(true);
  }, 1000);


const setValueForType= event => {
  console.log(event.target.value);
  setSelected(event.target.value);
  
  
  };
function handleChangeAdd(i, event) {
  const values = [...fields];
  values[i].value = event.target.value;
  setFields(values);
}

function handleAdditionalBaseRent() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAdditionalBaseRent(true);
}
function handleAddBaseRentAbatement() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAdditionalBaseRentAbatement(true);
}

function handleAddRecoverableExpense() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAddRecoverableExpense(true);
}
function handleAddRecoverableExpenseAbatement() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAddRecoverableExpenseAbatement(true);
}
function handleAddTenantAlowance() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAddTenantAllowance(true);
}
function handleAddLeaseCommissions() {
  const values = [...fields];
  values.push({ value: null });
  setFields(values);
  setShowAddLeaseCommissions(true);
}

function handleRemove(i) {
  const values = [...fields];
  values.splice(i, 1);
  setFields(values);
}
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleSimple = event => {
    setLeaseType(event.target.value);
  };

  const handleChange = (event) => {
    if(event.target.value){
    setValue0(event.target.value);
    setShowUpload(false);}

  setShowUpload(true);
  };


  const handleChangeValue1 = (event) => {
    if(value1==="Yes1"){   
    setValue1(event.target.value);
    setShowPropertyLookup(false);
    setShowPropertyAccordion(false);
    }
    else if(value1!=="Yes1"){
      setValue1(event.target.value);
      setShowPropertyLookup(true);
      setShowPropertyAccordion(true);
    }
  };

  
  const handleChangeValue2 = (event) => {
    setValue2(event.target.value);
  };

  const handleChangeValue3 = (event) => {
    if(value3==="Yes2"){
    setValue3(event.target.value);
    setShowGaurantor(false);
    }
    else if(value3!=="Yes2"){
      setValue3(event.target.value);
      setShowGaurantor(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

   const handleClickOpenPremium = () => {
    setOpenPremium(true);
    setOpenAdvantage(false);
    setOpenBasic(false);
    
  };
  const handleClickOpenAdvantage = () => {
    setOpenPremium(false);
    setOpenAdvantage(true);
    setOpenBasic(false);
    
  };
  const handleClickOpenBasic = () => {
    setOpenPremium(false);
    setOpenAdvantage(false);
    setOpenBasic(true);
    
  };

  const handleClosePremium = () => {
    setOpenPremium(false);
  };

  const handleCloseAdvantage = () => {
    setOpenAdvantage(false);
  };
  const handleCloseBasic = () => {
    setOpenBasic(false);
  };


  const handleClose = () => {
    setOpen(false);
  };


  const setSelectedLeaseStartDate=(value)=>{
    console.log("value"+value);
   value=Moment(value).format('MM-DD-YYYY');
  
   setLeaseStartDate(value);
  
   console.log("value"+value);
  //return value;
  }
  
  const setSelectedLeaseEndDate=(value)=>{
    console.log("value"+value);
   value=Moment(value).format('MM-DD-YYYY');
  
   setLeaseEndDate(value);
  
   console.log("value"+value);
  //return value;
  }

  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  
  
  return (
    <div className={classes.root}>
       <form>
      <h5 style={{color: megaColor, fontSize:"30px",fontWeight: "bold"}}>VIABILITY ASSESSMENT REQUEST</h5>
      <label style={{fontSize:"20px"}}>Fill out and submit</label>
      <Card>
      <CardBody>
        <br></br>
        <br></br>
        <h4 style={{fontSize:"23px",fontWeight:"bold",paddingLeft:"30px"}}>Select report type <b style={{color:"red"}}>*</b></h4>
        <h5 style={{fontSize:"17px",fontWeight:"bold",color:"red",textAlign: "right",paddingRight:"180px"}}>* Mandatory</h5>

<br></br>


        <GridContainer>
                <GridItem xs={3}> 
               <text style={{paddingLeft:"80px",fontSize:"18px"}}>Premium Assessment</text><Icon onClick={handleClickOpenPremium}><InfoOutlinedIcon style={{color: megaColor}}/></Icon>

               <RadioGroup aria-label="gender" name="gender1" value={value0} onChange={handleChange}>

              <FormControlLabel value="premiumAssessment"  control={<Radio style={{color: "#81A594",paddingBottom:"430px"}} />}  style={{textAlign: "justify",paddingRight:"10px",paddingLeft:"50px"}}  label="A Premium assessment should be ordered when you have financial statements available. It will also complete the lease analysis as well as the industry risk, third party credit reports, background checks and global watch lists. For a complete list of all the components, click on the three line icon next to Megalytics on the top bar in the right hand corner of the screen. The premium assessment provides you with the most informative and comprehensive report. The pricing is variable based on Gross Rent Amount and the lowest price is $500." />              <br></br>
                 {/* <label style={{paddingLeft:"100px",fontSize:"18px"}} >A Premium assessment should be ordered when you have financial statements available. It will also complete the lease analysis as well as the industry risk, third party credit reports, background checks and global watch lists. For a complete list of all the components, click on the three line icon next to Megalytics on the top bar in the right hand corner of the screen. The premium assessment provides you with the most informative and comprehensive report. The pricing is variable based on Gross Rent Amount and the lowest price is $500.</label> */}

   </RadioGroup>

       <div>
         
       <Dialog fullWidth={true}
  maxWidth={'lg'} open={openPremium} onClose={handleClosePremium} aria-labelledby="form-dialog-title">
      
      
      <GridItem align="right" paddingTop="20px">
          <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleClosePremium}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
       <DialogTitle id="form-dialog-title"><h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Premium Assessment</h3></DialogTitle> 
       <br></br>
       <br></br>
        <DialogContent>
        <h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}} align="justify">A premium assessment should be ordered when you have financial statements available. It will also complete the lease analysis as well as the industry risk, third party credit reports, background checks and global watch lists. The premium assessment provides you with the most informative and comprehensive report. The pricing is variable based on Gross Rent Amount and the lowest price is $500.</h4>

<br></br>
<h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}}>For our Premium Assessments these include:</h4>

<ul style={{paddingLeft:"130px"}}>

<li style={{fontSize:"25px"}} ><h4 style={{fontSize:"22px"}}>Proprietary scoring for up to twenty major components and over 50 sub-components</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Credit report on business and/or individuals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>International financial analysis/credit report</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Financial-statement analysis with essential ratios and trends</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Business Performance Benchmarking based on locale, industry, sales class</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Background checks on business and/or individuals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Secretary of State Filings, business-registration status</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Global watchlist check on business and principal executives; Patriot Act Compliance</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Adverse media sources for business and principal executives</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Criminal records on owners and principals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Tenant overview</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Company SWOT analysis, when available</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry profile and industry score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Wall Street analyst reports and summaries, when available</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Business startup and cessation rate</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Local economy and market analysis with competition map</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry trends at national and local levels</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>News on industry, tenant, and management</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Social media score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Lease analysis: breakeven, cash flow, and risk-adjusted NER</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Red flags and positive points</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Lease security options</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>CEO/CFO interview /questions</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Guarantor analysis – corporate and individual</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Different methodologies/scoring/analysis for non-profits, banking, insurance, law firms, start ups</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Risk Synopsis (2-4 pages)</h4></li>
</ul>
<br></br>
<h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Turnaround Time: 2 Business Days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price: Variable by Gross Rent Amount</h3>
         
          </DialogContent>
        
            </Dialog>
            </div>
            </GridItem>
          

                <GridItem xs={3} >
                <text style={{paddingLeft: "55px",fontSize:"18px"}}>Advantage Assessment</text><Icon onClick={handleClickOpenAdvantage}><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
<div>
<RadioGroup aria-label="gender" name="gender1" value={value0} onChange={handleChange}>

              <FormControlLabel value="advantageAssessment" control={<Radio style={{color: "#81A594",paddingBottom:"430px"}} />}  style={{textAlign: "justify",paddingRight:"18px",paddingLeft:"22px"}} label="An Advantage assessment should be ordered when you don’t have financial statements available but there is a need for a fairly robust assessment given it is a large renewing tenant, a material tenant in an acquisition or a high profile prospect. The advantage assessment will include a full blown lease analysis, third party credits reports, and industry risk analysis, background checks and global watch lists. For a complete list of all the components as well as the price, please click on the three line icon next to Megalytics on the top bar in the right hand corner of the screen.
" />
</RadioGroup>
</div>
<div>
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openAdvantage} onClose={handleCloseAdvantage} aria-labelledby="form-dialog-title">
      
      <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseAdvantage}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
          <DialogTitle id="form-dialog-title"><h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Advantage Assessment</h3></DialogTitle> 
      
       <br></br>
       <br></br>
        <DialogContent>
        <h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}} align="justify">An Advantage assessment should be ordered when you don’t have financial statements available but there is a need for a fairly robust assessment given it is a large renewing tenant, a material tenant in an acquisition or a high profile prospect. The advantage assessment will include a full blown lease analysis, third party credits reports, and industry risk analysis, background checks and global watch lists.</h4>

<br></br>
<h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}}>Our Advantage Assessments include:</h4>

<ul style={{paddingLeft:"130px"}}>

<li style={{fontSize:"25px"}} ><h4 style={{fontSize:"22px"}}>Proprietary scoring for six to ten major components, plus 25 subset data components</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Credit report on business and/or individuals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>International credit report/financial analysis</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Background checks on business and/or individuals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Secretary of State Filings, business-registration status Tenant overview</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Tenant overview</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry profile and industry score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Business startup and cessation rate</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Check business-registration status</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Global watchlist check on business and principal executives</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Patriot Act Compliance</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Adverse media sources for business and principal executives</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Criminal records on owners and principals</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Lease analysis: breakeven, cash flow, and risk-adjusted NER</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Red flags and positive points</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>News on industry, tenant, and management</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Social media score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Wall Street analyst reports and summaries, when available</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Local economy and market analysis with competition map</h4></li>
</ul>
<br></br>
<h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Turnaround Time: 2 Business Days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price: $800</h3>
         
        </DialogContent>
      
      </Dialog>
       </div>
       </GridItem>

              
       <GridItem xs={3} >
       <text style={{fontSize:"18px",paddingLeft: "55px"}}>Basic Assessment</text><Icon onClick={handleClickOpenBasic}><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
<div>
<RadioGroup aria-label="gender" name="gender1" value={value0} onChange={handleChange}>

              <FormControlLabel value="basicAssessment" control={<Radio style={{color: "#81A594",paddingBottom:"430px"}}  />} style={{textAlign: "justify", paddingRight:"19px",paddingLeft:"22px"}}  label="A Basic assessment should be ordered when you are looking for a quick go/no go on a tenant before you complete the LOI. It can also be used for smaller leases. It provides background checks including liens, lawsuits, judgements and bankruptcies, checks 1200 global watch lists, checks 30,000 adverse media sources, scores the risk of an industry and includes a third party credit report. For a complete list of all the components as well as the price, please click on the three line icon next to Megalytics on the top bar in the right hand corner of the screen.
" />
</RadioGroup>
              </div>
             


<div>
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openBasic} onClose={handleCloseBasic} aria-labelledby="form-dialog-title">
       <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseBasic}
          color="info"
          className="close" >

          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
      
          <DialogTitle id="form-dialog-title"><h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Basic Assessment</h3></DialogTitle> 
      
      <br></br>
      <br></br>
        <DialogContent>
        <h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}} align="justify">A Basic assessment should be ordered when you are looking for a quick go/no go on a tenant before you complete the LOI. It can also be used for smaller leases. It provides background checks including liens, lawsuits, judgements and bankruptcies, checks 1200 global watch lists, checks 30,000 adverse media sources, scores the risk of an industry and includes a third party credit report.</h4>

<br></br>
<h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}}>Our Basic Assessments include:
</h4>
<ul style={{paddingLeft:"130px"}}>

<li style={{fontSize:"25px"}} ><h4 style={{fontSize:"22px"}}>Scoring on up to Six Factors</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Tenant Profile</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Credit Report on business</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry Score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry Profile</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Global Watchlists</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Adverse Media</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Patriot Act Compliance</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>News Feeds and Social Media</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Background Checks</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Secretary of State Filings, business-registration status</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Red flags and positive points</h4></li>


</ul>
<br></br>
<h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Turnaround Time: 1 Business Day&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price: $400</h3>
   

        </DialogContent>
      
      </Dialog>
       </div>
       </GridItem>

       <GridItem xs={3} >
       <text style={{fontSize:"18px",paddingLeft:"37px"}}>UnBundled</text><Icon onClick={handleClickOpenBasic}><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
<div>
<RadioGroup aria-label="gender" name="gender1" value={value0} onChange={handleChange}>

              <FormControlLabel value="basicAssesment"  control={<Radio style={{color: "#81A594",paddingBottom:"430px"}} />}  style={{textAlign: "justify", paddingRight:"50px",paddingLeft:"6px"}}  label="A Basic assessment should be ordered when you are looking for a quick go/no go on a tenant before you complete the LOI. It can also be used for smaller leases. It provides background checks including liens, lawsuits, judgements and bankruptcies, checks 1200 global watch lists, checks 30,000 adverse media sources, scores the risk of an industry and includes a third party credit report. For a complete list of all the components as well as the price, please click on the three line icon next to Megalytics on the top bar in the right hand corner of the screen.
" />
</RadioGroup>
              </div>
             


<div>
       <Dialog  fullWidth={true}
  maxWidth={'lg'} open={openBasic} onClose={handleCloseBasic}  aria-labelledby="form-dialog-title">
       <GridItem align="right" paddingTop="20px">
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseBasic}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
          </GridItem>
      
          <DialogTitle id="form-dialog-title"><h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>UnBundled</h3></DialogTitle> 
      
      <br></br>
      <br></br>
        <DialogContent>
        <h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}} align="justify">A Basic assessment should be ordered when you are looking for a quick go/no go on a tenant before you complete the LOI. It can also be used for smaller leases. It provides background checks including liens, lawsuits, judgements and bankruptcies, checks 1200 global watch lists, checks 30,000 adverse media sources, scores the risk of an industry and includes a third party credit report.</h4>

<br></br>
<h4 style={{fontSize:"22px",paddingLeft:"50px",paddingRight:"50px"}}>Our Basic Assessments include:
</h4>
<ul style={{paddingLeft:"130px"}}>

<li style={{fontSize:"25px"}} ><h4 style={{fontSize:"22px"}}>Scoring on up to Six Factors</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Tenant Profile</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Credit Report on business</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry Score</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Industry Profile</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Global Watchlists</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Adverse Media</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Patriot Act Compliance</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>News Feeds and Social Media</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Background Checks</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Secretary of State Filings, business-registration status</h4></li>
<li style={{fontSize:"25px"}}><h4 style={{fontSize:"22px"}}>Red flags and positive points</h4></li>


</ul>
<br></br>
<h3 style={{fontWeight:"bold",paddingLeft:"50px",paddingRight:"50px"}}>Turnaround Time: 1 Business Day&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price: $400</h3>
   

        </DialogContent>
      
      </Dialog>
       </div>
       </GridItem>

           
  
              </GridContainer>
              <br></br>
              <GridItem style={{textAlign: "right",paddingRight:"60px"}}>
              <Button  id="send" size="sm" type="submit" 
        style={{fontSize:17, fontWeight: "bold",color:"white",backgroundColor:megaColor }}>
          <SaveIcon/>Submit Order
        </Button>
        </GridItem>
              <br></br>
              <br></br>
<GridContainer>
 
<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"40px",fontSize:"20px",paddingTop:"40px",paddingRight:"20px"}}>
Is there a property?
</GridItem>
<GridItem xs={12} sm={12} md={1}  style={{paddingTop:"30px"}}>
<RadioGroup aria-label="gender" name="gender1" value={value1} onChange={handleChangeValue1}>
<FormControlLabel value="Yes1"  control={<Radio style={{color: "#81A594"}}  />} label="Yes" />
</RadioGroup>
</GridItem>
<GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
<RadioGroup aria-label="gender" name="gender1" value={value1} onChange={handleChangeValue1}>

<FormControlLabel value="No1"  control={<Radio style={{color: "#81A594"}} />} label="No" />
</RadioGroup>

</GridItem>
{showPropertyLookup &&<GridItem xs={12} sm={12} md={3} style={{paddingBottom:"50px",paddingTop:"5px"}}>


{<Autocomplete
                
               
                  onChange={(event, newValue) => {
                    
                    selectedProperty(newValue);
                    
                  }}
                 
        {...allPropertyProps}
        id="propertyName"
        
        debug
        size='medium'
        renderInput={(params) => <TextField  {...params} onChange={({ target }) => setInput(target.value)} label="Property Lookup " margin="normal" />}
      /> }
{/* <CustomInput
        labelText={<text style={{color:"black",fontSize:"18px"}}>Property Lookup</text>}
        id="float"
        formControlProps={{
          fullWidth: true
        }}
      /> */}

</GridItem>}
<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"40px",fontSize:"20px",paddingTop:"40px",paddingRight:"2px"}}>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company is
</GridItem>
<GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}} >
<RadioGroup aria-label="gender" name="gender1" value={value2} onChange={handleChangeValue2}>
<FormControlLabel value="Private"  control={<Radio style={{color: "#81A594"}}  />} label="Private" />
</RadioGroup>
</GridItem>
<GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
<RadioGroup aria-label="gender" name="gender1" value={value2} onChange={handleChangeValue2}>
<FormControlLabel value="Public"  control={<Radio style={{color: "#81A594"}} />} label="
&nbsp;&nbsp;&nbsp;&nbsp;Public" /></RadioGroup>
</GridItem>
<GridItem xs={12} sm={12} md={1} style={{paddingRight:"20px",paddingTop:"30px"}}>
<Tooltip classes={{ tooltip: classes.customWidth }} title="Private mode requires you to upload fiancial statements(Preferably last 3 years).If available,LOI,organization chart/corporate structure and any other relevant information can be uploaded as well.">
<Icon><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
</Tooltip>

</GridItem>






</GridContainer>

<br></br>
<br></br>

<GridContainer>
  <GridItem style={{paddingLeft:"50px",paddingRight:"60px"}}>
  <Accordion
  active={0}
  collapses={[


    {
     title: <GridContainer style={{paddingLeft:"20px"}}>  {showPropertyAccordion &&<h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Property</h4>} </GridContainer>,
    
      content:  
   
      <form>
          {showPropertyAccordion && 
      <GridContainer >
      <GridItem xs={12} sm={12} md={6} >
      <CustomInput
        labelText={<text style={{color:"black",fontSize:"14px"}}>Property Name</text>}
        id="float"
       value={propertyName}
        formControlProps={{
          fullWidth: true
        }}
       

      />
      </GridItem>
       <GridItem xs={12} sm={12} md={5}>
       <CustomInput
         labelText={<text style={{color:"black",fontSize:"14px"}}>MSA</text>}
         id="float"
         value={msa}
         formControlProps={{
           fullWidth: true
         }}
       />
       </GridItem>
       <GridItem  xs={12} sm={12} md={1} style={{paddingTop:"32px"}} >


       <Tooltip classes={{ tooltip: classes.customWidth }} title="Metropolitan Statistical Area">
       <Icon><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
       </Tooltip>
       </GridItem>
      
<GridItem xs={12} sm={12} md={6}>
<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Address</text>}
  id="float"
  value={address1}
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={2} style={{paddingTop:"27px"}}>
  <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /><text>LEED Certified</text>
               </GridItem>

               <GridItem xs={12} sm={12} md={2} style={{paddingTop:"27px"}}>
  <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /><text>Energy Star</text>
               </GridItem>

               <GridItem xs={12} sm={12} md={2}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Year Built</text>}
   id="float"
   value={yearBuilt}
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>

 <br></br>
 <GridItem>
 <label>Note: Please type and select the zip code from the dropdown list.</label>

 </GridItem>

 <br></br>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Zip/Postal Code</text>}
   id="float"
   value={zipCode}
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Country FIPS</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>

 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>City</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>State</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Rentable Building Area</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}># of Parking Space</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
<br></br><br></br>
 <GridItem xs={12} sm={12} md={6}>
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            <text style={{color:"black",fontSize:"14px"}}>Country</text>
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            defaultValue={"1"}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               United States
            </MenuItem>
            <MenuItem value="2">
            Canada
            </MenuItem>
           
 <MenuItem value="3">
            United Kingdom
            </MenuItem>
            <MenuItem value="4">
            Japan
            </MenuItem>
            <MenuItem value="5">
            South Korea
            </MenuItem>
            <MenuItem value="6">
         
            Germany
            </MenuItem>
            <MenuItem value="7">
            Australia
            </MenuItem>
            <MenuItem value="8">
            Luxembourg
            </MenuItem>
            <MenuItem value="9">
            Cayman Islands
            </MenuItem>
            <MenuItem value="10">
            India
            </MenuItem>
          </Select>
        </FormControl>
 
 </GridItem>
 
 
 <GridItem xs={12} sm={12} md={6} >

<FormControl fullWidth className={classes.selectFormControl}>
         <InputLabel
           htmlFor="simple-select"
           className={classes.selectLabel}
         >
           <text style={{color:"black",fontSize:"14px"}}>Tenancy</text>
         
         </InputLabel>
         <Select
           MenuProps={{
             className: classes.selectMenu
           }}
           classes={{
             select: classes.select
           }}
           value={leaseType}
           onChange={handleSimple}
           inputProps={{
             name: "simpleSelect",
             id: "simple-select"
           }}
         >
              <MenuItem value="1">
              Single Tenant New

           </MenuItem>
           <MenuItem value="2">
           Multi-Tenant
           </MenuItem>

        
         
         </Select>
       </FormControl>
</GridItem>

 
  

 <br></br>
 <br></br>
 <br></br>
 <GridItem>
 <hr style={{ backgroundColor:" #fff",
        borderTop: "2px dashed #81A594"}}/>
        </GridItem>

<GridItem xs={12} sm={12} md={6}>
               
               <FormControl fullWidth className={classes.selectFormControl}>
              
               <label for="field1">Property Category</label>
         {/** Bind changeSelectOptionHandler to onChange method of select.
          * This method will trigger every time different
          * option is selected.
          */}
          
         <select 
          style={{height:"35px", border: "none" ,backgroundColor:"#f7f7f7", disableUnderline:"false",
          fontFamily: "Arial, Helvetica, sans-serif",fontSize:"14px"
         }}
      
         value={category}
         onChange={changeSelectOptionHandler}>
           <option>Select...</option>
           <option>Industrial</option>
           <option>Office</option>
           <option>Retail</option>
           <option>Land</option>
           <option>Health Care</option>
           <option>Mixed Use</option>
           <option>Other</option>
           
         </select>
      
         </FormControl>
         </GridItem>

         <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
           <text style={{color:"black",fontSize:"14px"}}>BuildingClass</text>

          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={buildingClass}
            onChange={setValueForBuildingClass}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="A">
            A
            </MenuItem>
            <MenuItem value="B">
           B
            </MenuItem>
            <MenuItem value="C">
            C
            </MenuItem>
            <MenuItem value="NA">
           NA
            </MenuItem>
          </Select>
        </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
               
               <FormControl fullWidth className={classes.selectFormControl}>
               <label for="field1">Property Type</label>
        
          <select 
        style={{height:"35px", border: "none" ,backgroundColor:"#f7f7f7", disableUnderline:"false",
        fontFamily: "Arial, Helvetica, sans-serif",fontSize:"14px"
       }}
      //  value={type} 
      //  onSelect={setValueForType}
       onChange={setValueForType}
     
          >
        {options}
          </select>
          </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
          <text style={{color:"black",fontSize:"14px"}}>Market Area</text>

          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
           // value={marketArea}
            onChange={setValueForMarketArea}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            <MenuItem value="CBD">
             CBD(Central Business District)
            </MenuItem>
  
          
            <MenuItem value="Metro">
            Metro
            </MenuItem>
            <MenuItem value="Suburban">
            Suburban
            </MenuItem>
            <MenuItem value="Other">
            Other
            </MenuItem>
          </Select>
        </FormControl>
                </GridItem>
      
                <GridItem xs={12} sm={12} md={6} >
      <CustomInput
        labelText={<text style={{color:"black",fontSize:"14px"}}>Property Occupancy %</text>}
        id="float"
       
        formControlProps={{
          fullWidth: true
        }}
       

      />
      </GridItem>

      <GridItem xs={12} sm={12} md={6} >
      <CustomInput
        labelText={<text style={{color:"black",fontSize:"14px"}}>Market Vacancy %</text>}
        id="float"
       
        formControlProps={{
          fullWidth: true
        }}
       

      />
      </GridItem>

      <GridItem xs={12} sm={12} md={6} style={{paddingTop:"10px"}}>
                <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
          <text style={{color:"black",fontSize:"14px"}}>Ownership</text>

          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
           // value={marketArea}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            <MenuItem value="LongTerm">
           LongTerm
            </MenuItem>
  
          
            <MenuItem value="Flip">
          Flip
            </MenuItem>
           
          </Select>
        </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6} >
      <CustomInput
        labelText={<text style={{color:"black",fontSize:"14px"}}>Sub market Vacancy %</text>}
        id="float"
       
        formControlProps={{
          fullWidth: true
        }}
       

      />
      </GridItem>

</GridContainer>}
 </form>
    
 
            },
            





    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Tenant Details</h4>,
      
      content: 
    
      <GridContainer>
      <GridItem xs={12} sm={12} md={6} >
      <CustomInput
        labelText={<text style={{color:"black",fontSize:"14px"}}>Name of Tenant<b style={{color:"red"}}>*</b></text>}
        id="float"
       
                    // success={requiredTenantName === "success"}
                    // error={requiredTenantName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    // inputProps={{
                    //   onChange: event => {
                    //     if (verifyLength(event.target.value, 1)) {
                    //       setTenantName(event.target.value);
                    //       setrequiredTenantName("success");
                    //     } else {
                    //       setrequiredTenantName("error");
                    //     }
                    //     setrequiredTenantName(event.target.value);
                    //   },
                    //   type: "text",
                    //   endAdornment:
                     
                    //   requiredTenantName  === "error" ? (
                    //       <InputAdornment position="end">
                    //         <Close className={classes.danger} />
                    //       </InputAdornment>
                    //     ) : (
                    //       undefined
                    //     )
                    // }}


      />
      </GridItem>
       <GridItem xs={12} sm={12} md={6}>
       <CustomInput
         labelText={<text style={{color:"black",fontSize:"14px"}}>Legal Signing Entity<b style={{color:"red"}}>*</b></text>}
         id="float"
        //  success={requiredLegalEntity === "success"}
        //  error={requiredLegalEntity === "error"}
         formControlProps={{
           fullWidth: true
         }}
        //  inputProps={{
        //    onChange: event => {
        //      if (verifyLength(event.target.value, 1)) {
        //        setLegalEntity(event.target.value);
        //        setrequiredLegalEntity("success");
        //      } else {
        //        setrequiredLegalEntity("error");
        //      }
        //      setrequiredLegalEntity(event.target.value);
        //    },
        //    type: "text",
        //    endAdornment:
          
        //    requiredLegalEntity  === "error" ? (
        //        <InputAdornment position="end">
        //          <Close className={classes.danger} />
        //        </InputAdornment>
        //      ) : (
        //        undefined
        //      )
        //  }}

       />
       </GridItem>
      
<GridItem xs={12} sm={12} md={6}>
<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Tenant Current Address</text>}
  id="float"
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}># of Locations</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <br></br>
 <GridItem>
 <label>Note: Please type and select the zip code from the dropdown list.</label>

 </GridItem>

 <br></br>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Zip</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
<GridItem></GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>City</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>State</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
<text style={{fontSize:"16px"}}><b>Customer Concentration</b></text>
 </GridItem>
<GridItem xs={12} sm={12} md={6}></GridItem>
 <GridItem xs={12} sm={12} md={1} style={{fontSize:"17px",paddingTop:"30px",paddingBottom:"50px"}}>
   <text>The top</text>
   </GridItem>
 
 <GridItem xs={12} sm={12} md={2} style={{paddingBottom:"50px",paddingTop:"0px"}} >  
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
             <text style={{color:"black",fontSize:"14px"}}># of customers</text>
          
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               1
            </MenuItem>
            <MenuItem value="2">
            2
            </MenuItem>
            <MenuItem value="3">
            3
            </MenuItem>
            <MenuItem value="4">
            4
            </MenuItem>
            <MenuItem value="5">
            5
            </MenuItem>
            <MenuItem value="6">
         
            6
            </MenuItem>
            <MenuItem value="7">
            7
            </MenuItem>
            <MenuItem value="8">
            8
            </MenuItem>
            <MenuItem value="9">
            9
            </MenuItem>
            <MenuItem value="10">
            10
            </MenuItem>
          </Select>
        </FormControl>
 
 </GridItem>
 <GridItem xs={12} sm={12} md={2} style={{fontSize:"17px",paddingTop:"30px",paddingBottom:"50px"}}>
   <text>customers accounted</text>
 </GridItem>
 <GridItem xs={12} sm={12} md={1} style={{fontSize:"17px",paddingTop:"30px",paddingBottom:"50px"}}>
   for
 </GridItem>
 <br></br><br></br>
 <GridItem xs={12} sm={12} md={6}>
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            <text style={{color:"black",fontSize:"14px"}}>Country</text>
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            defaultValue={"1"}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               United States
            </MenuItem>
            <MenuItem value="2">
            Canada
            </MenuItem>
           
 <MenuItem value="3">
            United Kingdom
            </MenuItem>
            <MenuItem value="4">
            Japan
            </MenuItem>
            <MenuItem value="5">
            South Korea
            </MenuItem>
            <MenuItem value="6">
         
            Germany
            </MenuItem>
            <MenuItem value="7">
            Australia
            </MenuItem>
            <MenuItem value="8">
            Luxembourg
            </MenuItem>
            <MenuItem value="9">
            Cayman Islands
            </MenuItem>
            <MenuItem value="10">
            India
            </MenuItem>
          </Select>
        </FormControl>
 
 </GridItem>
 
 
 <GridItem xs={12} sm={12} md={1} style={{paddingBottom:"60px",paddingTop:"0px"}}>
 <CustomInput
   labelText= {<text style={{color:"black",fontSize:"14px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={3} style={{fontSize:"17px",paddingTop:"45px",paddingBottom:"60px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of the company's revenue in  </GridItem>
 <GridItem xs={12} sm={12} md={2} style={{paddingBottom:"55px",paddingTop:"15px"}}>

<FormControl fullWidth className={classes.selectFormControl}>
         <InputLabel
           htmlFor="simple-select"
           className={classes.selectLabel}
         >
           <text style={{color:"black",fontSize:"14px"}}>Year</text>
         
         </InputLabel>
         <Select
           MenuProps={{
             className: classes.selectMenu
           }}
           classes={{
             select: classes.select
           }}
           value={leaseType}
           onChange={handleSimple}
           inputProps={{
             name: "simpleSelect",
             id: "simple-select"
           }}
         >
              <MenuItem value="1">
              2021
           </MenuItem>
           <MenuItem value="2">
           2020
           </MenuItem>
          
<MenuItem value="3">
           2019
           </MenuItem>
        
         
         </Select>
       </FormControl>
</GridItem>

 
  

 <br></br>
 <br></br>
 <br></br>
 <GridItem>
 <hr style={{ backgroundColor:" #fff",
        borderTop: "2px dashed #81A594"}}/>
</GridItem>

<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"50px",fontSize:"16px",paddingTop:"10px",paddingRight:"20px"}}>
Is there a guarantor?
</GridItem>
<GridItem xs={12} sm={12} md={1} >
<RadioGroup aria-label="gender" name="gender1" value={value3} onChange={handleChangeValue3}>
<FormControlLabel value="Yes2"  control={<Radio style={{color: "#81A594"}}  />} label="Yes" />
</RadioGroup>
</GridItem>
<GridItem xs={12} sm={12} md={1}>
<RadioGroup aria-label="gender" name="gender1" value={value3} onChange={handleChangeValue3}>

<FormControlLabel value="No2"  control={<Radio style={{color: "#81A594"}} />} label="No" />
</RadioGroup>

</GridItem>
</GridContainer>
 


            },

         

{
      title: <GridContainer style={{paddingLeft:"20px"}}> {showGaurantor && <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Gaurantor Details</h4>}</GridContainer>,
      
      content: 

      <form>
{showGaurantor && <GridContainer>
<GridItem xs={12} sm={12} md={2} >
<RadioGroup aria-label="gender" name="gender1" value={value3} onChange={handleChange}>
<FormControlLabel value="Individual"  control={<Radio style={{color: "#81A594"}}  />} label="Individual" />
</RadioGroup>
</GridItem>
<GridItem xs={12} sm={12} md={2}>
<RadioGroup aria-label="gender" name="gender1" value={value3} onChange={handleChange}>

<FormControlLabel value="Business"  control={<Radio style={{color: "#81A594"}} />} label="Business" />
</RadioGroup>

</GridItem>
<GridItem></GridItem>
<GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Name</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Address</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem>
 <label>Note: Please type and select the zip code from the dropdown list.</label>

 </GridItem>

 <br></br>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Zip</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            <text style={{color:"black",fontSize:"14px"}}>Country</text>
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            defaultValue={"1"}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               United States
            </MenuItem>
            <MenuItem value="2">
            Canada
            </MenuItem>
           
 <MenuItem value="3">
            United Kingdom
            </MenuItem>
            <MenuItem value="4">
            Japan
            </MenuItem>
            <MenuItem value="5">
            South Korea
            </MenuItem>
            <MenuItem value="6">
         
            Germany
            </MenuItem>
            <MenuItem value="7">
            Australia
            </MenuItem>
            <MenuItem value="8">
            Luxembourg
            </MenuItem>
            <MenuItem value="9">
            Cayman Islands
            </MenuItem>
            <MenuItem value="10">
            India
            </MenuItem>
          </Select>
        </FormControl>
 
 </GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>city</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
 <GridItem xs={12} sm={12} md={3}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>state</text>}
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 </GridItem>
</GridContainer>}
        </form>
},

           
            
            
    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Industry</h4>,
      content:
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>PRIMARY NAICS</text>}
   id="disabled"
   formControlProps={{ fullWidth: true}} inputProps={{disabled: false}}
 />
 </GridItem>
 <GridItem xs={1} style={{paddingTop:"30px"}}>
<Tooltip classes={{ tooltip: classes.customWidth }} title="Click to Lookup">
<Icon onClick={handleClickOpenSelectNaics}><TouchAppIcon /></Icon>
</Tooltip>
<div>
         
         <Dialog fullWidth={true}
    maxWidth={'lg'} open={openSelectNaics} onClose={handleCloseSelectNaics} aria-labelledby="form-dialog-title">
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <GridItem align="right" paddingTop="20px">
            <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseSelectNaics}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
            </GridItem>
          <DialogContent>
         <IndustryModalPage/>
            </DialogContent>
          
              </Dialog>
              </div>

</GridItem>
<GridItem xs={1} style={{paddingTop:"34px",paddingLeft:"5px"}}>

<Icon><CloseIcon/></Icon>


</GridItem>
<Tooltip classes={{ tooltip: classes.customWidth }} title="If NAICS is unknown,you may provide a description of services to detemine industry">

 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Industry Description</text>}

   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 
 </GridItem>
 </Tooltip>

 <GridItem xs={12} sm={12} md={4}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>SECONDARY NAICS</text>}
   id="disabled"
   formControlProps={{ fullWidth: true}} inputProps={{disabled: false}}
 />
 </GridItem>
 <GridItem xs={1} style={{paddingTop:"30px"}}>
<Tooltip classes={{ tooltip: classes.customWidth }} title="Click to Lookup">
<Icon onClick={handleClickOpenSelectNaics}><TouchAppIcon/></Icon>
</Tooltip>
<div>
         
         <Dialog fullWidth={true}
    maxWidth={'lg'} open={openSelectNaics} onClose={handleCloseSelectNaics} aria-labelledby="form-dialog-title">
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <GridItem align="right" paddingTop="20px">
            <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseSelectNaics}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
            </GridItem>
          <DialogContent>
         <IndustryModalPage/>
            </DialogContent>
          
              </Dialog>
              </div>
</GridItem>
<GridItem xs={1} style={{paddingTop:"34px",paddingLeft:"5px"}}>

<Icon><CloseIcon/></Icon>


</GridItem>
<GridItem xs={12} sm={12} md={4}><label>Note: If NAICS is unknown, you may provide a description of services to determine industry.</label></GridItem>
<GridItem xs={12} sm={12} md={4}><label>Note: To select NAICS, click on the
<TouchAppIcon/>
icon.</label></GridItem>

       
        </GridContainer>
        </form>
    },
    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>LOI/Lease Details</h4>,
      content:
      <form>
        <GridContainer>
  <GridItem xs={12} sm={12} md={3}>
  <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /><text>No lease provided for assessment</text>
               </GridItem>
               <GridItem></GridItem>
               <GridItem xs={12} sm={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
              <text style={{color:"black",fontSize:"14px"}}>Lease Type</text>

          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
             Single Net
            </MenuItem>
            <MenuItem value="2">
           Double Net
            </MenuItem>
            <MenuItem value="3">
            Triple Net
            </MenuItem>
            <MenuItem value="4">
            Absolute Triple Net
            </MenuItem>
            <MenuItem value="5">
            Gross
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
             <text style={{color:"black",fontSize:"14px"}}>Lease terms source</text>
         
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               Order Input Form
            </MenuItem>
            <MenuItem value="2">
            Email
            </MenuItem>
            <MenuItem value="3">
            Draft LOI
            </MenuItem>
            <MenuItem value="4">
            Signed LOI
            </MenuItem>
            <MenuItem value="5">
            Draft Contract
            </MenuItem>
            <MenuItem value="6">
         
            Final Contract
            </MenuItem>
            <MenuItem value="7">
            Final Deal Terms
            </MenuItem>
            <MenuItem value="5">
            Offering Memorandum
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
             <text style={{color:"black",fontSize:"14px"}}>Lease analysis</text>
         
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               Contraction
            </MenuItem>
            <MenuItem value="2">
            Expansion
            </MenuItem>
            <MenuItem value="3">
        
New
            </MenuItem>
            <MenuItem value="4">
            Relocation
            </MenuItem>
            <MenuItem value="5">





Renewal
            </MenuItem>
            <MenuItem value="6">
         
            Acquisition
            </MenuItem>
            <MenuItem value="7">
          
Rent Relief
            </MenuItem>
            <MenuItem value="8">
            Early Buyout
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Payment History</text>}

   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Lease Square feet</text>}

  
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <CustomInput

labelText={<text style={{color:"black",fontSize:"14px"}}>Space/Suite</text>}

  
   id="float"
   formControlProps={{
     fullWidth: true
   }}
 />
 
 </GridItem>
 <GridItem xs={12} sm={12} md={6}>
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            >
             <text style={{color:"black",fontSize:"14px"}}>Tenant Use of Space</text>
         
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               Headquarters
            </MenuItem>
            <MenuItem value="2">
            Regional Office
            </MenuItem>
            <MenuItem value="3">
            Sales Office
            </MenuItem>
            <MenuItem value="4">
            Back Office
            </MenuItem>
            <MenuItem value="5">
            Retail Store
            </MenuItem>
            <MenuItem value="6">
            Manufacturing
            </MenuItem>
            <MenuItem value="7">
            Movie Theatre
            </MenuItem>
            <MenuItem value="8">
           
             Fitness Centre                  
            </MenuItem>
            <MenuItem value="9">
            Co-Working                
          </MenuItem>
          <MenuItem value="10">
           
          Medical Diagnostic Centre                
          </MenuItem>
          <MenuItem value="11">
           
          
Dentist Office               
          </MenuItem>
          <MenuItem value="12">
           
         
Doctors Office               
          </MenuItem>
          <MenuItem value="13">
           
          Chiropractors               
          </MenuItem>
          <MenuItem value="14">
           
          
Physical Therapy Clinic                
          </MenuItem>
          <MenuItem value="15">
           
          Urgent Care Clinic                
          </MenuItem>
          <MenuItem value="16">
           
          Radiology Center               
           </MenuItem>
           <MenuItem value="17">
           Pharmacy           
           </MenuItem>
           <MenuItem value="18">
       
Medical Offices             
           </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
<GridItem xs={12} sm={12} md={6}></GridItem>

<GridItem xs={12} sm={12} md={3}>



    <FormControl fullWidth>
    <CustomInput
        timeFormat={false}
        dateFormat="MM-DD-YYYY"
   labelText={<text style={{color:"black",fontSize:"14px"}}>Lease Start Date</text>}

    
        onChange={setSelectedLeaseStartDate}
      />
    </FormControl>

</GridItem>

<GridItem xs={12} sm={12} md={3} >  


 <CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Lease Term Months</text>}

   id="disabled"
   
   formControlProps={{
     fullWidth: true
   }}
 />
 
 
</GridItem>

<GridItem xs={12} sm={12} md={2} >  


<CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Rent Start Date</text>}


  id="disabled"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem  xs={12} sm={12} md={1} style={{paddingTop:"32px"}} ><Tooltip classes={{ tooltip: classes.customWidth }} title="This is automatically calculated with lease start date and base rent abatement months.Ifbase rent abatement months are not given,then the rent start date will be same as the lease start date.">
<Icon><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
</Tooltip></GridItem>

<GridItem xs={12} sm={12} md={2} >  


<CustomInput
   labelText={<text style={{color:"black",fontSize:"14px"}}>Lease End Date</text>}

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem  xs={12} sm={12} md={1} style={{paddingTop:"32px"}}><Tooltip classes={{ tooltip: classes.customWidth }} title="This is automatically calculated with lease start date and lease term months.">
<Icon><InfoOutlinedIcon style={{color: megaColor}}/></Icon>
</Tooltip></GridItem>

        </GridContainer>
    
    </form>
    },
    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Base Rent</h4>,
      content:

<form>
      <GridContainer>
<GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Base Rent</text><Icon onClick={handleClickOpenBaseRent}><InfoOutlinedIcon style={{color: megaColor}}/></Icon></GridItem><br/><br/><br/><br/>
<div>
         
         <Dialog fullWidth={true}
    maxWidth={'lg'} open={openBaseRent} onClose={handleCloseBaseRent} aria-labelledby="form-dialog-title">
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <GridItem align="right" paddingTop="20px">
            <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseBaseRent}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
            </GridItem>
          <DialogContent>
            <GridContainer variant="outlined">

          <img src="/home/angel/projects/tm-web/src/assets/img/baseRentPopup.png"
            width="200" height="40" />
</GridContainer>
            </DialogContent>
          
              </Dialog>
              </div>
<GridItem  xs={12} sm={12} md={2} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"2px"}}>Lease square feet</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Months</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={3} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Base rent $/SF/Annum</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={3} ><text style={{fontSize:"17px",paddingLeft:"10px"}}>Annual increase %</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"2px"}}>Annual increase$/SF</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>


<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px"}} >  

<CustomInput

  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter sq.feet</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}
 

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}
 
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
{/* <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon />
             </GridItem> */}
<br></br><br></br><br></br><br></br>
<GridItem>
  {fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAdditionalBaseRent && <GridContainer>
           <GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px"}} >  

<CustomInput

  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter sq.feet</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}
 

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}
 
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 
      <Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Base Rent">
  <AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAdditionalBaseRent()}/>
  </Tooltip>
      </GridItem>
<br></br><br></br><br></br>
<GridItem><hr style={{ borderTop: "2px dashed #81A594"}}/></GridItem>


        <br></br><br></br><br></br>
        <GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Base Rent Abatement</text></GridItem><br/><br/><br/><br/>

        <GridItem  xs={12} sm={12} md={6} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"250px"}}>Months</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={6} ><text style={{fontSize:"17px",paddingLeft:"230px"}}>Abatement %</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>



<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={6} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
{/* <GridItem xs={12} sm={12} md={6}><text style={{fontSize:"17px", color:"red"}}>Base rent abatement months should not be greater than or equal to lease term months</text></GridItem> */}
     
<GridItem xs={12} sm={12} md={6}></GridItem>

<GridItem>

  {fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAddBaseRentAbatement &&  <GridContainer>
            <GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={5} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 

<Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Base Rent Abatement">
<AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAddBaseRentAbatement()}/>
  </Tooltip>

      </GridItem>




      </GridContainer>
      </form>
    },

    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Recoverable Expenses</h4>,
      content:
      <form>
      <GridContainer>
        <br></br><br></br><br></br>
<GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Recoverable Expenses</text></GridItem><br/><br/><br/><br/>
<GridItem  xs={12} sm={12} md={2} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"2px"}}>Lease square feet</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Months</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={3} ><text style={{fontSize:"17px",paddingLeft:"60px"}}>Expense</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={3} ><text style={{fontSize:"17px",paddingLeft:"30px"}}>Rate $/SF/Annum</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"5px"}}>Annual increase %</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>


<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px"}} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter sq.feet</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={3}  style={{paddingLeft:"30px",paddingTop:"11px"}} >
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
           <text style={{color:"black",fontSize:"14px"}}>Types of Expenses</text>

          
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               CAM
            </MenuItem>
            <MenuItem value="2">
            Electric
            </MenuItem>
            <MenuItem value="3">
            Elevator Maintenance
            </MenuItem>
            <MenuItem value="4">
            HVAC
            </MenuItem>
            <MenuItem value="5">
            Insurance
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<br></br><br></br><br></br>
<GridItem>
  
{fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAddRecoverableExpense &&  <GridContainer>
              <GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px"}} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter sq.feet</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={3}  style={{paddingLeft:"30px",paddingTop:"11px"}} >
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
           <text style={{color:"black",fontSize:"14px"}}>Types of Expenses</text>

          
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={leaseType}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="1">
               CAM
            </MenuItem>
            <MenuItem value="2">
            Electric
            </MenuItem>
            <MenuItem value="3">
            Elevator Maintenance
            </MenuItem>
            <MenuItem value="4">
            HVAC
            </MenuItem>
            <MenuItem value="5">
            Insurance
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 
  
  
  
  
  
  <Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Recoverable Expenses">
  <AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAddRecoverableExpense()}/>

  </Tooltip></GridItem>

<br></br><br></br>
<GridItem><hr style={{ borderTop: "2px dashed #81A594"}}/></GridItem>


        <br></br><br></br><br></br>
        <GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Recoverable Expense Abatement</text></GridItem><br/><br/><br/><br/>

        <GridItem  xs={12} sm={12} md={6} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"250px"}}>Months</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={6} ><text style={{fontSize:"17px",paddingLeft:"230px"}}>Abatement %</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>



<GridItem xs={12} sm={12} md={3} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}


  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={6} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}

  
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
     
<br></br><br></br><br></br><br></br>

<GridItem>
{fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAddRecoverableExpenseAbatement &&  <GridContainer>
            <GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}

  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
<GridItem xs={12} sm={12} md={3} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>

<GridItem xs={12} sm={12} md={5} >  

<CustomInput
  labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}
 
  id="float"
  
  formControlProps={{
    fullWidth: true
  }}
/>
</GridItem>
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 
  
  
  
  <Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Recoverable Expense Abatement">
  <AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAddRecoverableExpenseAbatement()}/>
  </Tooltip></GridItem>
<br></br><br></br><br></br>
      </GridContainer>
      </form>
    
    },

    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Tenant Allowances and Lease Commissions</h4>,
      
      content: 
      <form>
      <GridContainer>
      <br></br><br></br><br></br>
<GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Tenant Allowance</text></GridItem><br/><br/><br/><br/>
<GridItem  xs={12} sm={12} md={1} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"2px"}}>Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Month &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; amount(Landlord)</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"40px"}}>Amount per SF(Landlord)</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} style={{paddingTop:"11px"}}><text style={{fontSize:"17px",paddingLeft:"5px",paddingBottom:"50px"}}>Reusable</text>

 <Icon><Tooltip classes={{ tooltip: classes.customWidth }} title="This indicates the percentage of the tenant improvements that could be reused by the next tenant.">
<InfoOutlinedIcon style={{color: megaColor}}/></Tooltip></Icon> 
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={3} ><text style={{fontSize:"17px",paddingLeft:"70px"}}> Amount Contributed &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  by Tenant</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>

<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px",paddingTop:"11px"}} >  


      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          <text style={{color:"black",fontSize:"14px"}}>Type</text>
      
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={leaseType}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
             <MenuItem value="1">
             Tenant Improvement
          </MenuItem>
          <MenuItem value="2">
          Legal Fees
          </MenuItem>
          <MenuItem value="3">
Moving Fees
          </MenuItem>
          <MenuItem value="4">
          Other Concessions
          </MenuItem>
         
        </Select>
      </FormControl>
  
</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter #</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>


<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem>
  
{fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAddTenantAllowance &&  <GridContainer>
              <GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px",paddingTop:"11px"}} >  


<FormControl fullWidth className={classes.selectFormControl}>
  <InputLabel
    htmlFor="simple-select"
    className={classes.selectLabel}
  >
    <text style={{color:"black",fontSize:"14px"}}>Type</text>

  </InputLabel>
  <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={leaseType}
    onChange={handleSimple}
    inputProps={{
      name: "simpleSelect",
      id: "simple-select"
    }}
  >
       <MenuItem value="1">
       Tenant Improvement
    </MenuItem>
    <MenuItem value="2">
    Legal Fees
    </MenuItem>
    <MenuItem value="3">
Moving Fees
    </MenuItem>
    <MenuItem value="4">
    Other Concessions
    </MenuItem>
   
  </Select>
</FormControl>

</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter #</text>}

id="float"

formControlProps={{
fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

id="float"

formControlProps={{
fullWidth: true
}}
/>
</GridItem>


<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter $</text>}

id="float"

formControlProps={{
fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}

id="float"

formControlProps={{
fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter$</text>}

id="float"

formControlProps={{
fullWidth: true
}}
/>
</GridItem>
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 
  
  
  
  <Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Tenant Allowances">
  <AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAddTenantAlowance()}/>

  </Tooltip></GridItem>

<br></br><br></br>
<GridItem><hr style={{ borderTop: "2px dashed #81A594"}}/></GridItem>


      <br></br><br></br><br></br>
      <GridItem><text style={{fontSize:"19px", color:"#81A594"}}>Lease Commissions</text></GridItem><br/><br/><br/><br/>

<GridItem><b style={{color:"#81A594"}}>*</b><text style={{fontsize:"18px"}}>If lease commissions section is not completed, we will assume a 6% lease commission rate, with 3% allocated to the tenant’s broker and 3% allocated to the lessor’s broker.</text></GridItem>

<br/><br/>
<GridItem  xs={12} sm={12} md={1} style={{paddingLeft:"30px"}}><text style={{fontSize:"18px",paddingLeft:"2px"}}>Type</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Rate Type</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"50px"}}>Month paid</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={1} ><text style={{fontSize:"17px",paddingLeft:"5px"}}>Months</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"2px"}}>Percentage</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"2px"}}>Total amount($)</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>
<GridItem  xs={12} sm={12} md={2} ><text style={{fontSize:"17px",paddingLeft:"2px"}}>Amount per SF($)</text>
<hr style={{ borderTop: "3px solid #81A594"}}/></GridItem>




<GridItem xs={12} sm={12} md={1} style={{paddingLeft:"30px",paddingTop:"11px"}}>  

<FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          <text style={{color:"black",fontSize:"14px"}}>Type</text>
     
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={leaseType}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
             <MenuItem value="1">
             Tenant Improvement
          </MenuItem>
          <MenuItem value="2">
          Legal Fees
          </MenuItem>
          <MenuItem value="3">
Moving Fees
          </MenuItem>
          <MenuItem value="4">
          Other Concessions
          </MenuItem>
         
        </Select>
      </FormControl>
      </GridItem>
<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px",paddingTop:"11px"}} >  

<FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          <text style={{color:"black",fontSize:"14px"}}>Rate Type</text>
      
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={leaseType}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
             <MenuItem value="1">
             Tenant Improvement
          </MenuItem>
          <MenuItem value="2">
          Legal Fees
          </MenuItem>
          <MenuItem value="3">
Moving Fees
          </MenuItem>
          <MenuItem value="4">
          Other Concessions
          </MenuItem>
         
        </Select>
      </FormControl>
</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Month Paid</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>N/A</text>}

id="disabled"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>N/A</text>}

id="disabled"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
   
<br></br><br></br><br></br>

<GridItem>
  
{fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            {showAddLeaseCommissions &&  <GridContainer>
              <GridItem xs={12} sm={12} md={1} style={{paddingLeft:"30px",paddingTop:"11px"}}>  

<FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          <text style={{color:"black",fontSize:"14px"}}>Type</text>
     
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={leaseType}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
             <MenuItem value="1">
             Tenant Improvement
          </MenuItem>
          <MenuItem value="2">
          Legal Fees
          </MenuItem>
          <MenuItem value="3">
Moving Fees
          </MenuItem>
          <MenuItem value="4">
          Other Concessions
          </MenuItem>
         
        </Select>
      </FormControl>
      </GridItem>
<GridItem xs={12} sm={12} md={2} style={{paddingLeft:"30px",paddingTop:"11px"}} >  

<FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          <text style={{color:"black",fontSize:"14px"}}>Rate Type</text>
      
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={leaseType}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
             <MenuItem value="1">
             Tenant Improvement
          </MenuItem>
          <MenuItem value="2">
          Legal Fees
          </MenuItem>
          <MenuItem value="3">
Moving Fees
          </MenuItem>
          <MenuItem value="4">
          Other Concessions
          </MenuItem>
         
        </Select>
      </FormControl>
</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Month Paid</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>From</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>To</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={2} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>Enter %</text>}

id="float"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>N/A</text>}

id="disabled"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>

<GridItem xs={12} sm={12} md={1} >  

<CustomInput
labelText={<text style={{color:"black",fontSize:"14px"}}>N/A</text>}

id="disabled"

formControlProps={{
  fullWidth: true
}}
/>
</GridItem>
   
           <GridItem xs={12} sm={12} md={1} style={{paddingTop:"30px"}}>
             <CloseIcon onClick={() => handleRemove(idx)}/>
             </GridItem>
             </GridContainer>}
           
          </div>
        );
      })} 
  
  
  
  
  
  <Tooltip classes={{ tooltip: classes.customWidth }} title="Additional Lease Commission">
  <AddCircleIcon style={{color:megaColor,fontSize:"50px"}} onClick={() => handleAddLeaseCommissions()}/>

  </Tooltip></GridItem>

    </GridContainer>
    </form>
    },
    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Documents to upload</h4>,
      content:
      <GridContainer>
        <br></br>
        {showUpload && <GridItem align="right">
          
          <label>Note: Please select report type to enable upload button </label>  
          <Button  id="send" size="md" type="submit" 
        style={{fontSize:14, fontWeight: "bold", borderRadius: 2,backgroundColor:lyticsColor}}>
         <AttachFileIcon/> UPLOAD
        </Button>
        </GridItem>}
      </GridContainer>
    },
    {
      title: <h4 style={{fontSize:"20px",color:"#81A594",fontWeight:"bold"}}>Additional Comments</h4>,
      content:
      <GridContainer>
        <br></br><br></br>
       <GridItem xs={12} sm={12} md={2}>
<text>Comments</text>
</GridItem>
<GridItem xs={12} sm={12} md={12} >  

<CustomInput

// labelText="N/A"
id="float"

formControlProps={{
  fullWidth: true
}}
/>
       </GridItem>

      </GridContainer>
    }
  ]}
/>
      
      </GridItem>
      <GridItem style={{textAlign: "right",paddingRight:"60px"}}>
              <Button  id="send" size="sm" type="submit" 
        style={{fontSize:17, fontWeight: "bold",color:"white",backgroundColor:megaColor }}>
         <SaveIcon/> Submit Order
        </Button>
        </GridItem>
</GridContainer>


      <br></br>
      <br></br>
      <Clearfix/>
        </CardBody>
       
      </Card>
     
      </form>
    </div>
  );
}
OrderFormAccordion.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertySaveRequest: PropTypes.func.isRequired,
  tmPropertyByOrgIdRequest: PropTypes.func.isRequired,
  tmPropertyEditRequest: PropTypes.func.isRequired,
  tmPropertyByIdRequest: PropTypes.func.isRequired,
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  
  // fundList: state.tenantmonitoring.fundList,
  propertyEditObj: state.tenantmonitoring.propertyEditObj,
  loading: state.tenantmonitoring.loading,
  propertyByOrg: state.tenantmonitoring.propertyByOrg,
  // assetManagerList: state.tenantmonitoring.assetManagerList,
  // fundListWithUser:state.tenantmonitoring.fundListWithUser,
  //  propertyId:state.tenantmonitoring.propertyId,
  // propertyEdit: state.tenantmonitoring.propertyEdit,
  // property: state.tenantmonitoring.property,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderFormAccordion);