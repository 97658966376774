
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Heatmap from "components/Charts/Heatmap.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import SaveIcon from '@material-ui/icons/Save';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";

// import { saveFund } from "store/sagas/tenantmonitoring";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import { LocalDining } from "@material-ui/icons";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Badge from "components/Badge/Badge.js";

import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
}));

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },




];




const FundEdit = ({ loading,tmFundSaveRequest,tmFundEditRequest,fundEditObj,tmFundByIdRequest,fund,tmFundManagerListRequest,fundManagerList}) => {

  const history = useHistory();

const location = useLocation();
  const [input, setInput] = React.useState('');

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };
  const saveFund = async (fund) => {
    tmFundSaveRequest(fund);
    };


const [requiredFundNme, setrequiredFundNme] = React.useState("");
const [requiredStateFundNme, setrequiredStateFundNme] = React.useState(""); 
const [requiredStateFundMnger, setrequiredStateFundMnger] = React.useState("");
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");
const [dataList, setDataList] = React.useState(fundManagerList);
// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



const [name,setName] =React.useState("");
const [type,setType] =React.useState("");
const [comments,setComments] =React.useState("");
const [userId,setUserId] =React.useState(""); 
const [countryName,setCountryName] =React.useState("");
const [managerRole,setManagerRole ]=React.useState("");
const [currentStatus,setCurrentStatus]=React.useState("");
const [fundManagerId,setFundManagerId]=React.useState("");
const [fundManagerName,setFundManagerName]=React.useState("");
const [roleName,setRoleName]=React.useState("");
const [dataStatus,setDataStatus]=React.useState(false);
const [dataComplete,setDataComplete]=React.useState(false);
const [fundId , setFundId ] = React.useState(location.state.fundId);
const [fundEditData ,setFundEditData] = React.useState("");
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
const[id,setId]=React.useState("");
const [labelFundManagerName,setLabelFundManagerName]=React.useState(fundEditObj.fundManagerName);
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}
  const handleChange = (value) => {
   
  }
   const selectedFundManager = (fundManager) => {
console.log("fundManager"+JSON.stringify(fundManager));
    if(fundManager!=null){
    setUserId(fundManager.id);
    setFundManagerId(fundManager.id);
    setLabelFundManagerName("");
    }
  };



  useEffect(() => {
    

    async function getFundById(fundId) {
      await tmFundByIdRequest(fundId);
      if(fundEditObj.name){
      setName(fundEditObj.name);
      setType(fundEditObj.type);
      setCountryName(fundEditObj.countryName);
      setComments(fundEditObj.comments); 
      setFundManagerName(fundEditObj.fundManagerName); 
      setLabelFundManagerName(fundEditObj.fundManagerName);
      setFundManagerId(fundEditObj.fundManagerId);
      setUserId(fundEditObj.fundManagerId);
     
      //  setDataStatus(true);
      }
    };

    setName("");
    setType("");
    setCountryName("");
    setComments(""); 
    setFundManagerName(""); 
    setLabelFundManagerName("");
    
  
  //  alert(JSON.stringify(fundEditObj));
    getFundById(fundId);

    setTimeout(() => {
      setDataStatus(true);
    }, 1000);

    
    getFundManagerList(organizationId);
    }, [dataStatus]);
    
   
  //   const getFundById = async (fundId) => {
  //    await tmFundByIdRequest(fundId);
  //  };

    const getFundManagerList = async (organizationId) => {
      await tmFundManagerListRequest(organizationId);
        
    };

      const allFundManagerProps = {
  
        options: fundManagerList,
        getOptionLabel: (option) => option.firstName+" "+option.lastName
      };
    
      function hideAlert1(event) {
        history.push({ 
          pathname: '/admin/fundlist'
         });
        }    
  const submitForm = () => {

    if(verifyLength(name,1,name)&&verifyLength(fundManagerId,1,fundManagerId))
{
const fund={

  id:fundId,
  name:name,
  type:type,
  comments:comments,
  userId:userId,
  countryName:countryName,
  roleName:managerRole,
  organizationId:organizationId,
  currentStatus:currentStatus,
  fundManagerId:fundManagerId,
  roleName:roleName,
  fundManagerName:fundManagerName,
};

tmFundEditRequest(fund);


history.push( setAlert1(true),{ 
 
  pathname: '/admin/fundlist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }
else{
  setAlert(true);
  if (requiredStateFundNme === "value") {
    setrequiredStateFundNme("success");
  }
  if (requiredStateFundMnger === "value") {
    setrequiredStateFundMnger("success");
  }
   
}
  };
 
 
  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length,param) => {
    console.log(param);
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  

  const [value, setValue] = React.useState(null);


  const typeClick = () => {
 
    // if (typeEmailState === "") {
    //   settypeEmailState("error");
    // }
  
    if (requiredState === "") {
      setrequiredState("error");
    }
    if (requiredStateFundNme === "") {
      setrequiredStateFundNme("error");
    }
    if (requiredStateFundMnger === "") {
      setrequiredStateFundMnger("error");
    }
   
    
  };




  const classes = useStyles(); 
if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>

      {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem>  */}
     
        <GridItem xs={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white" ,fontSize:"17px"}} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Edit Fund</text> <span style={{marginLeft:"650px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
  <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"editFund"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                    
            </CardHeader>
            <CardBody>

           
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText=" Fund Name *"
                    id="name"
                    value={name}
                    success={requiredStateFundNme === "success"}
                    error={requiredStateFundNme === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1,"fund")) {
                          setName(event.target.value);
                          setrequiredStateFundNme("success")
                        } else {
                          setName("");
                          setrequiredStateFundNme("error");
                       }
                       setrequiredFundNme(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateFundNme  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Fund Type"
                    id="last-name"
                    value={type}

                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0,"type")) {
                          setType(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
              </GridContainer>
  
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              {<Autocomplete
                   success={requiredStateFundMnger === "success"}
                   error={requiredStateFundMnger === "error"}   
                  onChange={(event, newValue) => {
                    // handleChange(newValue);
                    selectedFundManager(newValue);
                   
                  
          }}
          inputProps={{
            onChange: value => {
              if (verifyLength(value, 1)) {
                setFundManagerId(value)
                setrequiredStateFundMnger("success");
              } else {
                setrequiredStateFundMnger("error");
                setFundManagerId("");
              }
              setrequiredStateFundMnger(value);
            },
            type: "text",
            endAdornment:
           
            requiredStateFundMnger === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                undefined
              )
          }}
          
                  {...allFundManagerProps}
        id="fundId"

       
        debug
        size='medium'
        
        renderInput={(params) => <TextField  success={requiredStateFundMnger === "success"}
        error={requiredStateFundMnger === "error"} defaultValue={labelFundManagerName} {...params} onChange={({ target }) => setInput(target.value)} label={labelFundManagerName} margin="normal" />}
      /> }
               




                </GridItem>

<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="last-name"
                    value={countryName}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0,"country")) {
                          setCountryName(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <br></br>
              <br></br>
              <GridContainer>
                
                 <GridItem xs={12} sm={12} md={12}>
<label>Add Comments:   </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<TextField style={{width: "80%"}}
          id="outlined-multiline-flexible"
          label=""
          multiline
          rowsMax={4}
          value={comments}
          // onChange={handleChange}
          variant="outlined"



          inputProps={{
            onChange: event => {
              if (verifyLength(event.target.value, 0,"comments")) {
                setComments(event.target.value);
              } else {
                setComments("");
                setrequiredState("error");
              }
              setrequired(event.target.value);
            },
            type: "text",
            endAdornment:
              requiredState === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                undefined
              )
          }}
        />

                  

     

        
                </GridItem>        

<br></br>
<br></br>
<br></br>
                <GridItem xs={12} sm={12} md={12}>
      
              <div className={classes.buttonGroup}>
                <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
                <SaveIcon/>  &nbsp;&nbsp;Save  
                </Button>

              </div>
        
         
        </GridItem>  

              </GridContainer>

              <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
           confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>   
                     <div>         
                     <SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
//  btnSize="lg"
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}
// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Fund Edited Successfully!
</SweetAlert>
</div>
              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
}
FundEdit.propTypes = {
 
  tmFundSaveRequest: PropTypes.func.isRequired,
  tmFundEditRequest: PropTypes.func.isRequired,
  tmFundByIdRequest: PropTypes.func.isRequired,
  tmFundManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  fundEditObj: state.tenantmonitoring.fundEditObj,
  fundManagerList: state.tenantmonitoring.fundManagerList
 // fund: state.tenantmonitoring.fund
  
  
});              



const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundEdit);