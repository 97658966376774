import React,{useEffect,useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import PropTypes from 'prop-types';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import { Link } from 'react-router-dom';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import NFTable from "components/NFTable/NFTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import Moment from 'moment';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check"
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  }
};

const useStyles = makeStyles(styles);

  const Notification = ({
    tmNotificationList,
    tmLexisNexisNotificationList, 
    tmNotificationRequest, 
    tmLexisNexisNotificationRequest
  }) => {
    const [dataList, setDataList] = React.useState(tmNotificationList);
    useEffect(() => {
      
      getTmNotificationList();
      getLexisNexisNotificationList();

    }, []);

    const getTmNotificationList = async () => {
      await tmNotificationRequest();
      };
      const getLexisNexisNotificationList = async () => {
     
        await tmLexisNexisNotificationRequest();
        };



  // const [data, setData] = React.useState(
  //   dataTable.dataRows.map((prop, key) => {
  //     return {
  //       id: key,
  //       name: prop[0],
  //       position: prop[1],
  //       office: prop[2],
  //       age: prop[3]

  //     };
  //   })
  // );
  const classes = useStyles();
  
  return (
    <GridContainer>
      <GridItem xs={12}>

      <div>
      <CustomTabs
       //  headerColor="info"
        tabs={[
          // {
          //   tabName: "News ( 10 )" ,
          //   tabIcon: AnnouncementOutlinedIcon,
          //   tabContent: (
              
          
          //   <NFTable
          //     columns={[
          //     //  {
          //     //   id: "checkbox", 
          //     //   Header: <Checkbox />,
          //     //     maxWidth:40,
          //     //     sortable: false,
          //     //     Cell: (<Checkbox  />)
          //     //   },

              

          //     {
          //       id: "checkbox",
          //       accessor: "",
          //       filterable: false,
          //       sortable: false,
          //       width: 45,
          //       Cell: ( rowInfo ) => {
          //           return (
          //               <Checkbox
          //                   type="checkbox"
          //                   className="checkbox"
          //                 // checked={this.state.selected[rowInfo.original.title.props.children] === true}
          //                 //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
          //               />
          //           );
          //       },
          //       Header: title => {
          //           return (
          //               <Checkbox
          //                   type="checkbox"
          //                   className="checkbox"
          //                   // checked={this.state.selectAll === 1}
          //                   // ref={input => {
          //                   //     if (input) {
          //                   //         input.indeterminate = this.state.selectAll === 2;
          //                   //     }
          //                   // }}
          //                   // onChange={() => this.toggleSelectAll()}
          //               />
          //           );
          //       },
               
          //   },
             
     
          //       {
          //         Header: "Date",
          //         accessor: data => {
          //           return Moment(data.created_date)
          //           .locale('en')
          //             .format(" MMM D, YYYY")
          //         }
        
          //     },
          //       {
          //         Header: "Category",
          //         accessor: "category"
          //       },
          //       {
          //         Header: "Source Name",
          //         accessor: "source_name"
          //       },
                
          //       {
          //         Header: "Company Name",
          //         accessor: "company_name"
          //       },
          //       {
          //         Header: "Title",
          //         accessor: "title"
          //       }
          //     ]}
          //     data={tmNotificationList}
          //   />
       
          //   )
          // },
          {
            tabName: "Bankruptcy (10)",
            tabIcon: AccountBalanceOutlinedIcon,
            tabContent: (
                  
            <MTTable
            columns={[
              {
                Header: "Date",
                accessor: data => {
                  return Moment(data.created_date)
                  .locale('en')
                    .format(" MMM D, YYYY")
                }
      
            },
              {
                Header: "Category",
                accessor: "category"
              },
              {
                Header: "Source Name",
                accessor: "source_name",
                
              },
              
              {
                Header: "Company Name",
                accessor: "company_name"
              },
              {
                Header: "Title",
                accessor: "title",
             
              }
            ]}
            data={tmNotificationList}
          />
            )
          },
          {

            tabName: "Derogatory (8)",
            tabIcon: LocalLibraryOutlinedIcon,
            tabContent: (
                 
            <MTTable
            columns={[
          
              {
                Header: "Company Name",
                accessor: "companyName"
              },
              {
                Header: "Source Name",
                accessor: "bipSeleid"
              },
              
              {
                Header: "BK",
                accessor: "bkySeleid"
              },
              {
                Header: "Title",
                accessor: "created_date"
              },
              {
                Header: "Last Updated Date",
                accessor: data => {
                  return Moment(data.created_date)
                  .locale('en')
                    .format(" MMMM D, YYYY")
                }
      
            },
            ]}
            data={tmLexisNexisNotificationList}
          />
            )
          },

          {
            tabName: "Credit Report ( 10 )" ,
            tabIcon: AnnouncementOutlinedIcon,
            tabContent: (
              
          
            <NFTable
              columns={[
              //  {
              //   id: "checkbox", 
              //   Header: <Checkbox />,
              //     maxWidth:40,
              //     sortable: false,
              //     Cell: (<Checkbox  />)
              //   },

              

              {
                id: "checkbox",
                accessor: "",
                filterable: false,
                sortable: false,
                width: 45,
                Cell: ( rowInfo ) => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                          // checked={this.state.selected[rowInfo.original.title.props.children] === true}
                          //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
                        />
                    );
                },
                Header: title => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                            // checked={this.state.selectAll === 1}
                            // ref={input => {
                            //     if (input) {
                            //         input.indeterminate = this.state.selectAll === 2;
                            //     }
                            // }}
                            // onChange={() => this.toggleSelectAll()}
                        />
                    );
                },
               
            },
             
     
                {
                  Header: "Date",
                  accessor: data => {
                    return Moment(data.created_date)
                    .locale('en')
                      .format(" MMM D, YYYY")
                  }
        
              },
                {
                  Header: "Category",
                  accessor: "category"
                },
                {
                  Header: "Source Name",
                  accessor: "source_name"
                },
                
                {
                  Header: "Company Name",
                  accessor: "company_name"
                },
                {
                  Header: "Title",
                  accessor: "title"
                }
              ]}
              data={tmNotificationList}
            />
       
            )
          },

          {
            tabName: "Pandemic Risk report" ,
            tabIcon: AnnouncementOutlinedIcon,
            tabContent: (
              
          
            <NFTable
              columns={[
              //  {
              //   id: "checkbox", 
              //   Header: <Checkbox />,
              //     maxWidth:40,
              //     sortable: false,
              //     Cell: (<Checkbox  />)
              //   },

              

              {
                id: "checkbox",
                accessor: "",
                filterable: false,
                sortable: false,
                width: 45,
                Cell: ( rowInfo ) => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                          // checked={this.state.selected[rowInfo.original.title.props.children] === true}
                          //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
                        />
                    );
                },
                Header: title => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                            // checked={this.state.selectAll === 1}
                            // ref={input => {
                            //     if (input) {
                            //         input.indeterminate = this.state.selectAll === 2;
                            //     }
                            // }}
                            // onChange={() => this.toggleSelectAll()}
                        />
                    );
                },
               
            },
             
     
                {
                  Header: "Date",
                  accessor: data => {
                    return Moment(data.created_date)
                    .locale('en')
                      .format(" MMM D, YYYY")
                  }
        
              },
                {
                  Header: "Category",
                  accessor: "category"
                },
                {
                  Header: "Source Name",
                  accessor: "source_name"
                },
                
                {
                  Header: "Company Name",
                  accessor: "company_name"
                },
                {
                  Header: "Title",
                  accessor: "title"
                }
              ]}
              data={tmNotificationList}
            />
       
            )
          },
          {
            tabName: "News ( 10 )" ,
            tabIcon: AnnouncementOutlinedIcon,
            tabContent: (
              
          
            <NFTable
              columns={[
              //  {
              //   id: "checkbox", 
              //   Header: <Checkbox />,
              //     maxWidth:40,
              //     sortable: false,
              //     Cell: (<Checkbox  />)
              //   },

              

              {
                id: "checkbox",
                accessor: "",
                filterable: false,
                sortable: false,
                width: 45,
                Cell: ( rowInfo ) => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                          // checked={this.state.selected[rowInfo.original.title.props.children] === true}
                          //   onChange={() => this.toggleRow(rowInfo.original.title.props.children)}
                        />
                    );
                },
                Header: title => {
                    return (
                        <Checkbox
                            type="checkbox"
                            className="checkbox"
                            // checked={this.state.selectAll === 1}
                            // ref={input => {
                            //     if (input) {
                            //         input.indeterminate = this.state.selectAll === 2;
                            //     }
                            // }}
                            // onChange={() => this.toggleSelectAll()}
                        />
                    );
                },
               
            },
             
     
                {
                  Header: "Date",
                  accessor: data => {
                    return Moment(data.created_date)
                    .locale('en')
                      .format(" MMM D, YYYY")
                  }
        
              },
                {
                  Header: "Category",
                  accessor: "category"
                },
                {
                  Header: "Source Name",
                  accessor: "source_name"
                },
                
                {
                  Header: "Company Name",
                  accessor: "company_name"
                },
                {
                  Header: "Title",
                  accessor: "title"
                }
              ]}
              data={tmNotificationList}
            />
       
            )
          },
        ]}
      />
    </div>
        {/* <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Notification</h4>
          </CardHeader>
          <CardBody>
            <MTTable
              columns={[
                {
                  Header: "Tenant",
                  accessor: "name",
                  // Cell: ({ cell }) => {
                  //   const test = cell.getCellProps()
                  //   cell.getCellProps = () => {
                  //     return {
                  //       ...test,
                  //     }
                  //   }
                   
                  //   return <>{cell.value}</>
                  Cell: ({ cell }) => (<Link to={{pathname:'/editpage/'+`${cell.id}`, state :{data : cell} } }>{cell.value}   <Badge color="warning">15</Badge></Link>),
               // }
              },
                {
                  Header: "Guarantor/Parent",
                  accessor: "position"
                },
                {
                  Header: "Property",
                  accessor: "office"
                },
                
                {
                  Header: "Category",
                  accessor: "age"
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card> */}
      </GridItem>
    </GridContainer>
  );
}

Notification.propTypes = {
  tmNotificationList: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number,
    }),
  ).isRequired,

  // tmLexisNexisNotificationList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     companyId: PropTypes.number,
  //   }),
  // ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmNotificationRequest: PropTypes.func.isRequired,
  tmLexisNexisNotificationRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmNotificationList: state.tenantmonitoring.tmNotificationList,
  tmLexisNexisNotificationList:state.tenantmonitoring.tmLexisNexisNotificationList,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);

