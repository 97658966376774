import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import People from '@material-ui/icons/People';
import PictureUpload from 'components/CustomUpload/PictureUpload.js';



const useStyles = makeStyles(styles,styles1);

const uomvalues = [
  
 
  { title: 'Inch'},
  { title: 'g'},
  { title: 'kg' },
  { title: 'ml' },
  { title: 'l' },
  { title: 'cm' },
  { title: 'm' },
  { title: 'nos' },
];

const variant = [
  
 
    { title: 'Inch'},
    { title: 'g'},
    { title: 'kg' },
    { title: 'ml' },
    { title: 'l' },
    { title: 'cm' },
    { title: 'm' },
    { title: 'nos' },
  ];
  


const Employeedetails = ({}) => {

  const [input, setInput] = React.useState('');


    // type validation-jain

    const [requiredName, setName] = React.useState("");
    const [requiredStateName, setrequiredStateName ]= React.useState("");
    

const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");

const [requiredfirst, setfirst] = React.useState("");
const [requiredFirstName, setrequiredFirstName] = React.useState("");


const [requiredmob, setMobnumber] = React.useState("");
const [requiredMobileNumber, setrequiredMobileNumber] = React.useState("");

const [requiredland, setLandNumber] = React.useState("");
const [requiredLandNumber, setrequiredLandNumber] = React.useState("");

const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredStateAddress, setrequiredStateAddress] = React.useState("");

// const [critical, setcritical] = React.useState("");
// const [numberCritical, setnumberCritical] = React.useState("");


// const [selling, setselling] = React.useState("");
// const [numberSelling, setnumberSelling] = React.useState("");

// const [mrp, setmrp] = React.useState("");
// const [numbermrp, setnumbermrp] = React.useState("");

// const [dealer, setdealer] = React.useState("");
// const [numberDealer, setnumberDealer] = React.useState("");


const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");

const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



// check Box
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };



  const defaultProps = {
    options: uomvalues,
    getOptionLabel: (option) => option.title,
  };


  const flatProps = {
    options: uomvalues.map((option) => option.title),
  };
  

//   const defaultProps = {
//     options: variant,
//     getOptionLabel: (option) => option.title,
//   };


//   const flatProps = {
//     options: variant.map((option) => option.title),
//   };
  


  const handleChange = (value) => {
  
  }

  

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};


// function that verifies if value contains only numbers
const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  


  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("error");
    }

    if (requiredStateName === "") {
        setrequiredStateName("error");
      }
    if (requiredFirstName === "") {
        setrequiredFirstName("error");
      }
      if (requiredMobileNumber=== "") {
        setrequiredMobileNumber("error");
      }
      if (requiredLandNumber === "") {
        setrequiredLandNumber("error");
      }
      if (requiredStateAddress === "") {
        setrequiredStateAddress("error");
      }

      if (typeEmailState === "") {
        settypeEmailState("error");
      }

    //   if (numberCritical === "") {
    //     setnumberCritical("error");
    //   }

    //   if (numberSelling === "") {
    //     setnumberSelling("error");
    //   }
     
    //   if (numbermrp === "") {
    //     setnumbermrp("error");
    //   }
     
    //   if (numberDealer === "") {
    //     setnumberDealer("error");
    //   }
     
  };







  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor, color:'white' }} icon>
            {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3>
             <b> Employee Details</b>
              </h3> 
            </CardHeader>
            <CardBody>
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText=" Name *"
                    id="name"

                    success={requiredStateName === "success"}
                    error={requiredStateName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredStateName("success");
                        } else {
                          setrequiredStateName("error");
                        }
                        setName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredStateName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name *"
                    id="firstname"

                    success={requiredFirstName === "success"}
                    error={requiredFirstName=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value, 1)) {
                            setrequiredFirstName("success");
                          } else {
                            setrequiredFirstName("error");
                          }
                          setfirst(event.target.value);
                        },
                        type: "text",
                        endAdornment:
                          requiredFirstName=== "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
              </GridContainer>

              
              

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText=" Last Name "
                    id="lastname"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address1 *"
                    id="firstname"

                    success={requiredStateAddress === "success"}
                    error={requiredStateAddress=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value, 1)) {
                            setrequiredStateAddress("success");
                          } else {
                            setrequiredStateAddress("error");
                          }
                          setrequiredAddress(event.target.value);
                        },
                        type: "text",
                        endAdornment:
                          requiredStateAddress=== "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
              </GridContainer>




              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address2"
                    id="address2"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email"
                    id="email"

                    success={typeEmailState === "success"}
                    error={typeEmailState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                        onChange: event => {
                          if (verifyEmail(event.target.value)) {
                            settypeEmailState("success");
                          } else {
                            settypeEmailState("error");
                          }
                          settypeEmail(event.target.value);
                        },
                        type: "email",
                        endAdornment:
                        typeEmailState=== "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                  />
                </GridItem>
              </GridContainer>









              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Mobile Number *"
                    id="mobno"

                    success={requiredMobileNumber=== "success"}
                    error={requiredMobileNumber === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setrequiredMobileNumber("success");
                        } else {
                          setrequiredMobileNumber("error");
                        }
                        setMobnumber(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredMobileNumber=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Landline Number"
                    id="landno"

                   

                    success={requiredLandNumber=== "success"}
                    error={requiredLandNumber=== "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setrequiredLandNumber("success");
                        } else {
                          setrequiredLandNumber("error");
                        }
                        setLandNumber(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        requiredLandNumber=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>




              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Location"
                    id="location"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="uom"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Manage" margin="normal" />}
      /> }
                </GridItem>
                



              </GridContainer>






              
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="uom"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Role" margin="normal" />}
      /> }
                </GridItem>


                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="uom"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Type" margin="normal" />}
      /> }
                </GridItem>

                
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Upload Photo"
                    id="uploadpic"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                        // endAdornment: (<InputAdornment position="end"><PictureUpload /></InputAdornment>)
                        endAdornment: (<InputAdornment position="end"><People/></InputAdornment>)
                      }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                {<Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
        {...defaultProps}
        id="uom"
        debug
        size='medium'
        renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="Qualification" margin="normal" />}
      /> }
                </GridItem>
                
<GridContainer>

</GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Area Of Interest"
                    id="areaof interest"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setrequiredState("success");
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>

              </GridContainer>



            
               


       
<br/>
<br/>
 <div className={classes.buttonGroup}>
                

                    <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
                  <b>SUBMIT</b>
                </Button>
              
              </div>

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
     
    </div>
  );
}
export default Employeedetails;



