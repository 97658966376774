
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import CsvUpload from "components/CustomUpload/CsvUpload.js";
import TenantStatusTable from "views/Tenant/TenantStatusTable.js"
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import PopulateTable from 'views/Company/PopulateTable.js'
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import ViewFailureList from "./ViewFailureList";
//import FailureCountReport from "./FailureCountReport";

const styles = {

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',
  },
    ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right",
  },
  
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const PopulateInitialScore = ({
    tmOrganizationScoreExecutionLogByOrgRequest,organizationForExecutionLogByOrg, tmTenantListRequest, tmTenantListByOrgRequest,tmTenantInitialScoreRequest,tmAllTenantsTrailingScoreRequest,tenantsTrailingScoreList,tmTenantDeleteRequest,tenantDelete,
    tmOrgInitialDataStatusRequest,orgInitialDataStatus,tmPopulateInitialDataByOrgRequest,populateInitialDataByOrg
  }) => {
    const history = useHistory();
    const location = useLocation();
    const [dataList, setDataList] = React.useState(organizationForExecutionLogByOrg);
    const [messageobj, setMessageobj] = React.useState("");
    const [tenantId , setTenantId ] = React.useState("");
    const [propertyId, setPropertyId ] = React.useState("");
  //   const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //   const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  //  // const[companyName,setCompanyName] = React.useState("Megalytics");

  const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
  const [organisationName, setOrganisationName] = React.useState(location.state.organisationName);
  
   //const[failureCount,setFailureCount] = React.useState(location.state.failureCount);
   const [createdDate, setCreatedDate] = React.useState("");
  // const [organisationName, setOrganisationName ] = React.useState("");
   const [tenantName, setTenantName] = React.useState("");

   const[month, setMonth] = React.useState("");
   const[year, setYear] = React.useState("");
  const[ status, setStatus] = React.useState(true);
  const [dataStatus, setDataStatus] = React.useState(false);

  const [alert,setAlert] = React.useState(false);
  const [apiStatusAlert,setApiStatusAlert] = React.useState(false);
  const [apiStatus,setApiStatus] = React.useState(false);
  const [scoreStatus,setScoreStatus] = React.useState(false);
const [disableDataPopulation,setDisableDataPopulation] = React.useState(false);
  React.useEffect(() => {
   
    if(!dataStatus){
      getOrganizationForExecutionLogByOrg(organisationId);
     
      getOrgInitialDataStatus(organisationId);
  //   getActiveLiensList(tenantId);
  //   getActiveLiensJudgmentScore(tenantId);
  //   getActiveJudgementList(tenantId);
  
    }
    setTimeout(() => {
      setDataStatus(true);
      }, 2000);
  
  }, [dataStatus]);
  
  
    // useEffect(() => {
      
    //   // getTenantList(organisationId);
    //   getOrganizationForExecutionLogByOrg(organisationId);
    //  getOrgInitialDataStatus(organisationId);
    //   //setStatus(true);
    //  // getOrganizationScoreExecutionLogList(organisationId,month,year)
    //   if(location.state){
    //     setMessageobj(location.state.message);
    //   }
     
     
    //   }, [messageobj]);
  
      const getOrganizationForExecutionLogByOrg = async(organisationId)=>{
        await tmOrganizationScoreExecutionLogByOrgRequest(organisationId)
        console.log("organisationId"+JSON.stringify(organisationId));
        //setFailureCount(tenantFailureStatusLogListByOrg);
      
          }
      

         

const hideAlert = () => {
  setAlert(false);
}

const hideStatusAlert = () => {
  setApiStatusAlert(false);
}
// const successAlert = () => {
//   setAlert(
//     <SweetAlert
//       success
//       style={{ display: "block", marginTop: "-100px" }}
//       title="Good job!"
//       onConfirm={() => hideAlert()}
//       onCancel={() => hideAlert()}
//       confirmBtnCssClass={classes.button + " " + classes.success}
//     >
//       You clicked the button!
//     </SweetAlert>
//   );
// };



          const checkOrgStatus=async(organisationId)=>{
            console.log("organisationId"+JSON.stringify(organisationId));
            if(orgInitialDataStatus){
            
              setAlert(true);
                 }else{
                  getPopulateInitialDataByOrg(organisationId);
                 
                 }
                
          }

          const getOrgInitialDataStatus = async(organisationId)=>{
            await tmOrgInitialDataStatusRequest(organisationId);
           
          } 

          const getPopulateInitialDataByOrg = async(organisationId)=>{
            await tmPopulateInitialDataByOrgRequest(organisationId);
            setApiStatusAlert(true);
            setDisableDataPopulation(true);
        //  if(populateInitialDataByOrg){
        //    console.log("populateInitialDataByOrg");
        //    console.log(populateInitialDataByOrg.hasScore);
        //   setScoreStatus(populateInitialDataByOrg.hasScore);
        //   console.log("scoreStatus"+scoreStatus);
        //   if(scoreStatus){
        //     setApiStatus("Initial data populated Successfully");
        //   }
        //   else{
        //   setApiStatus("Initial data population failed")
        //  }
        //  setApiStatusAlert(true);
        //   } 

          }

const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

// const getTenantInitialScore=(tenantId)=>{
//   tmTenantInitialScoreRequest(tenantId);
// }

// const getAllTenantInitialScore=()=>{
//   tmAllTenantsTrailingScoreRequest();
// }


const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

//const [showFailureCountReport, setShowFailureCountReport] = React.useState(false);
const [open, setOpen] = React.useState(false);
const [showViewFailureList, setShowViewFailureList] = React.useState(false);
const [modalValue, setModalValue] = React.useState("");  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
   

    

//   const handleClickOpenViewFailureList = (value,organisationId,month,year) => {
//     setShowViewFailureList(true);
//     setModalValue(true);
//     setOrganisationId(true);
//  setMonth(true);
//  setYear(true);

   
//   };

  

  // const handleCloseViewFailureList = () => {
  //   setShowViewFailureList(false);
    
  // };

 const getFailureList=(organisationId,month,year)=>{
    setShowViewFailureList(true);
  
         setOrganisationId(organisationId);
      setMonth(month);
      setYear(year);
      //alert(organisationId);
      // alert(month);
      // alert(year);

 }
//  const submit = (organisationId) => {
   
//   confirmAlert({
   
//     //title: 'Fund',
//     message: 'Data already Generated',
//     buttons: [
//       {
//         label: 'OK'
      
//       },
    
//     ]
  
//   })

// };
 
  
  const classes = useStyles();

  if(dataStatus){
  return (
    <GridContainer>
      {/* <GridItem xs={12}>
     <OrgSelected orgName={organisationName}/>
     </GridItem> */}
      <GridItem xs={12}>
     
     
      

        <Card>
          <CardHeader style={{ background: megaColor,color:"white" ,fontSize:"17px"}} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b>{organisationName}- Initial Data Population</b></h4>
            
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }

   
               <GridContainer className={classes.gridContainer}>
      
         <GridItem style={{textAlign: "right"}} xs={12} sm={12}>
         

         <Button disabled={disableDataPopulation} style={{ background: megaColor , color:"white"}} round onClick={()=> {checkOrgStatus(organisationId)}}
        //  onClick={(hasIntialData=false) ? getOrgInitialDataStatus(organisationId) : 'Data already generated' }

        //  onClick={(hasInitialData==true ? 
        //    alert('Data already generated')
        //  :
       
        //   getOrgInitialDataStatus(organisationId))}
         > 
                  
                  <b>Populate  initial score</b></Button>
                    
                {/* {orgInitialDataStatus==false ? 'score generate' : 'data already generated'}       */}
                {/* (getPopulateInitialDataByOrg(organisationId)) */}
         </GridItem>
       
   </GridContainer>
         
         
   <div>
<SweetAlert
success
show={alert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Data Already Generated!
</SweetAlert>

       
</div>
<div>
<SweetAlert
success
show={apiStatusAlert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideStatusAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
<b> Score is being populated .Please check after One hour.If still problem exist please contact system administrator.</b>
</SweetAlert>
</div>
  
                
              

                
 
            <PopulateTable
              columns={[

                {
                  Header: "Date & Time",
                  accessor: "createdDate",
              
                },


          
         
           

          
            {
              Header: "Month",
               accessor: "month",
               width: 50,
               
           },
           {
            Header: "Year",
             accessor: "year",
             width: 50,
             
         },
           
              
                

              
                {
                  Header: "Actions",
                accessor: "actions",
    //             Cell: ({ cell,row }) => (
    //               <Link
    
    //   onClick={() => handleClickOpenViewFailureList(row.original.organisationId,row.original.month,row.original.year)}
    // >
    // {cell.value}
    // </Link>
    //              ),
            
            width: getColumnWidthNew(organizationForExecutionLogByOrg, 'accessor', 'actions'),
      
                  sortable: false,
                  filterable: false,



                  Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/view-failure-list', state :{ organisationId : organisationId, month:row.original.month, year:row.original.year} } } onClick={() => getFailureList(row.original.organisationId,row.original.month,row.original.year)}>View Execution Log</Link>),


  //                 Cell: ({ cell,row }) => (
  //                   <Link 
       
  //       onClick={() => getFailureList(row.original.organisationId,row.original.month,row.original.year)}
  //     >
  //  View Failure List
  //     </Link>
  //                  ),
                 },


              ]}
              data={organizationForExecutionLogByOrg.map((prop, key) => {

               
                return {
                  id: prop.id,
                  // organisationId: prop.organisationId,
                  // organisationName: prop.organisationName,
                  month: prop.month,
                  year: prop.year,
                  createdDate: prop.createdDate,
             
                  createdDate:(
                   <div>
                   {Moment(createdDate.createdDate).format("MM-DD-YYYY  hh:mm:ss")}
                 </div>

               )

    //               hasIntialData:(


    //                 <div>
             
                
    //  //  {prop.hasIntialData ? onClick={submit} : onClick={getOrgInitialDataStatus(organisationId)}}
                 
    //             </div>
                 
    //              ),
                    
                //   actions: (
                //     // we've added some custom button actions
                //     <div>
                //    {/* <Link to={{pathname:'/admin/view-failure-list', state :{tenantId : prop.id} } }> */}
                //       <Button
                //         size="sm" color="info"
                //         onClick={getFailureList}
                //       >
                //       View Faiure List
                //       </Button>{" "}

                      
                //       {/* </Link> */}
                      
                // {/* use this button to remove the data row */}
                //    {/* //  <Button */}
                // {/* //         justIcon
                // //         round
                // //         simple
                // //         onClick={() =>{submit(prop.id)}}
                // //         color="info"
                // //         className="remove"
                // //       >
                // //         <DeleteIcon/>
                // //       </Button>{" "} */}
                //   </div>
                //   )
                };
              })}


        
              //  data={tenantUploadStatusList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({month: rowData.month, year: rowData.year})
                  }
            }}}
            
            />
          </CardBody>
          
        </Card>
        
        </GridItem>
        
       
    </GridContainer>
    
  );
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={8000} //8 secs
  /></div>
  )
}
}



PopulateInitialScore.propTypes = {
  organizationForExecutionLogByOrg: PropTypes.arrayOf(
    PropTypes.shape({
      organisationId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmTenantListRequest: PropTypes.func.isRequired,
  tmTenantListByOrgRequest: PropTypes.func.isRequired,
  tmTenantDeleteRequest: PropTypes.func.isRequired,
  tmTenantInitialScoreRequest: PropTypes.func.isRequired,
  tmOrganizationScoreExecutionLogByOrgRequest: PropTypes.func.isRequired,
  tmOrgInitialDataStatusRequest: PropTypes.func.isRequired,
  tmPopulateInitialDataByOrgRequest:PropTypes.func.isRequired,
  
  
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  organizationForExecutionLogByOrg: state.tenantmonitoring.organizationForExecutionLogByOrg,
  tenantDelete: state.tenantmonitoring.tenantDelete,
  loading: state.tenantmonitoring.loading,
  populateInitialDataByOrg:state.tenantmonitoring.populateInitialDataByOrg,

  orgInitialDataStatus:state.tenantmonitoring.orgInitialDataStatus,
  
  // month:state.tenantmonitoring.month,
  // year:state.tenantmonitoring.year,
  //tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopulateInitialScore);




