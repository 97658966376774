// import React from "react";
// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";

// // @material-ui/icons
// import PermIdentity from "@material-ui/icons/PermIdentity";
// import Close from "@material-ui/icons/Close";

// // core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// import Heatmap from "components/Charts/Heatmap.js";

// import HeatMapProperty from "components/Charts/HeapMapProperty.js";

// import HeatMapTenant from "components/Charts/HeapMapTenant.js";
// import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

// import avatar from "assets/img/faces/marc.jpg";
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

// //import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

// import FormControl from "@material-ui/core/FormControl";
// // import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";



// const useStyles = makeStyles(styles);

// const top100Films = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'The Godfather', year: 1972 },
//   { title: 'The Godfather: Part II', year: 1974 },
//   { title: 'The Dark Knight', year: 2008 },
//   { title: '12 Angry Men', year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: 'Pulp Fiction', year: 1994 },
//   { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
//   { title: 'The Good, the Bad and the Ugly', year: 1966 },
//   { title: 'Fight Club', year: 1999 },
//   { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
//   { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
//   { title: 'Forrest Gump', year: 1994 },
//   { title: 'Inception', year: 2010 },
//   { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: 'Goodfellas', year: 1990 },
//   { title: 'The Matrix', year: 1999 },
//   { title: 'Seven Samurai', year: 1954 },
//   { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
//   { title: 'City of God', year: 2002 },
//   { title: 'Se7en', year: 1995 },
//   { title: 'The Silence of the Lambs', year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: 'Life Is Beautiful', year: 1997 },
//   { title: 'The Usual Suspects', year: 1995 },
//   { title: 'Léon: The Professional', year: 1994 },
//   { title: 'Spirited Away', year: 2001 },
//   { title: 'Saving Private Ryan', year: 1998 },
//   { title: 'Once Upon a Time in the West', year: 1968 },
//   { title: 'American History X', year: 1998 },
//   { title: 'Interstellar', year: 2014 },
//   { title: 'Casablanca', year: 1942 },
//   { title: 'City Lights', year: 1931 },
//   { title: 'Psycho', year: 1960 },
//   { title: 'The Green Mile', year: 1999 },
//   { title: 'The Intouchables', year: 2011 },
//   { title: 'Modern Times', year: 1936 },
//   { title: 'Raiders of the Lost Ark', year: 1981 },
//   { title: 'Rear Window', year: 1954 },
//   { title: 'The Pianist', year: 2002 },
//   { title: 'The Departed', year: 2006 },
//   { title: 'Terminator 2: Judgment Day', year: 1991 },
//   { title: 'Back to the Future', year: 1985 },
//   { title: 'Whiplash', year: 2014 },
//   { title: 'Gladiator', year: 2000 },
//   { title: 'Memento', year: 2000 },
//   { title: 'The Prestige', year: 2006 },
//   { title: 'The Lion King', year: 1994 },
//   { title: 'Apocalypse Now', year: 1979 },
//   { title: 'Alien', year: 1979 },
//   { title: 'Sunset Boulevard', year: 1950 },
//   { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
//   { title: 'The Great Dictator', year: 1940 },
//   { title: 'Cinema Paradiso', year: 1988 },
//   { title: 'The Lives of Others', year: 2006 },
//   { title: 'Grave of the Fireflies', year: 1988 },
//   { title: 'Paths of Glory', year: 1957 },
//   { title: 'Django Unchained', year: 2012 },
//   { title: 'The Shining', year: 1980 },
//   { title: 'WALL·E', year: 2008 },
//   { title: 'American Beauty', year: 1999 },
//   { title: 'The Dark Knight Rises', year: 2012 },
//   { title: 'Princess Mononoke', year: 1997 },
//   { title: 'Aliens', year: 1986 },
//   { title: 'Oldboy', year: 2003 },
//   { title: 'Once Upon a Time in America', year: 1984 },
//   { title: 'Witness for the Prosecution', year: 1957 },
//   { title: 'Das Boot', year: 1981 },
//   { title: 'Citizen Kane', year: 1941 },
//   { title: 'North by Northwest', year: 1959 },
//   { title: 'Vertigo', year: 1958 },
//   { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
//   { title: 'Reservoir Dogs', year: 1992 },
//   { title: 'Braveheart', year: 1995 },
//   { title: 'M', year: 1931 },
//   { title: 'Requiem for a Dream', year: 2000 },
//   { title: 'Amélie', year: 2001 },
//   { title: 'A Clockwork Orange', year: 1971 },
//   { title: 'Like Stars on Earth', year: 2007 },
//   { title: 'Taxi Driver', year: 1976 },
//   { title: 'Lawrence of Arabia', year: 1962 },
//   { title: 'Double Indemnity', year: 1944 },
//   { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
//   { title: 'Amadeus', year: 1984 },
//   { title: 'To Kill a Mockingbird', year: 1962 },
//   { title: 'Toy Story 3', year: 2010 },
//   { title: 'Logan', year: 2017 },
//   { title: 'Full Metal Jacket', year: 1987 },
//   { title: 'Dangal', year: 2016 },
//   { title: 'The Sting', year: 1973 },
//   { title: '2001: A Space Odyssey', year: 1968 },
//   { title: "Singin' in the Rain", year: 1952 },
//   { title: 'Toy Story', year: 1995 },
//   { title: 'Bicycle Thieves', year: 1948 },
//   { title: 'The Kid', year: 1921 },
//   { title: 'Inglourious Basterds', year: 2009 },
//   { title: 'Snatch', year: 2000 },
//   { title: '3 Idiots', year: 2009 },
//   { title: 'Monty Python and the Holy Grail', year: 1975 },
// ];




// const Company = ({}) => {

//   const [input, setInput] = React.useState('');


//   // type validation
// const [required, setrequired] = React.useState("");
// const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
// const [number, setnumber] = React.useState("");
// const [numberState, setnumberState] = React.useState("");
// const [url, seturl] = React.useState("");
// const [urlState, seturlState] = React.useState("");
// const [equalTo, setequalTo] = React.useState("");
// const [whichEqualTo, setwhichEqualTo] = React.useState("");
// const [equalToState, setequalToState] = React.useState("");

// // range validation
// const [minLength, setminLength] = React.useState("");
// const [minLengthState, setminLengthState] = React.useState("");
// const [maxLength, setmaxLength] = React.useState("");
// const [maxLengthState, setmaxLengthState] = React.useState("");
// const [range, setrange] = React.useState("");
// const [rangeState, setrangeState] = React.useState("");
// const [minValue, setminValue] = React.useState("");
// const [minValueState, setminValueState] = React.useState("");
// const [maxValue, setmaxValue] = React.useState("");
// const [maxValueState, setmaxValueState] = React.useState("");



//   const defaultProps = {
//     options: top100Films,
//     getOptionLabel: (option) => option.title,
//   };

  
//   const handleChange = (value) => {
//     alert(value);
//   }

//   const flatProps = {
//     options: top100Films.map((option) => option.title),
//   };

//   // function that verifies if a string has a given length or not
// const verifyLength = (value, length) => {
//   if (value.length >= length) {
//     return true;
//   }
//   return false;
// };

// // function that verifies if value contains only numbers
// const verifyNumber = value => {
//   var numberRex = new RegExp("^[0-9]+$");
//   if (numberRex.test(value)) {
//     return true;
//   }
//   return false;
// };

// const typeClick = () => {
//   if (requiredState === "") {
//     setrequiredState("error");
//   }
//   if (typeEmailState === "") {
//     settypeEmailState("error");
//   }
//   if (numberState === "") {
//     setnumberState("error");
//   }
//   if (urlState === "") {
//     seturlState("error");
//   }
//   if (equalToState === "") {
//     setequalToState("error");
//   }
// };


//   const [value, setValue] = React.useState(null);

//   const classes = useStyles(); 

//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12} sm={12} md={12}>
//           <Card>
         


//             <CardHeader style={{ background: '#00628B', color:'white' }} icon>
//             {/* <CardHeader style={{background:'#00628B',color:"white"}} icon> */}
//               <CardIcon color="info">
//                 <PermIdentity />
//               </CardIcon>
//               <h3>
//              <b>  Create New Company</b>
//               </h3> 
//             </CardHeader>
//             <CardBody>

//             <h5><strong>Main Contact</strong></h5>
   
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Company Name"
//                     id="property-name"

//                     success={requiredState === "success"}
//                     error={requiredState === "error"}
//                     //id="required"
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyLength(event.target.value, 1)) {
//                           setrequiredState("success");
//                         } else {
//                           setrequiredState("error");
//                         }
//                         setrequired(event.target.value);
//                       },
//                       type: "text",
//                       endAdornment:
//                         requiredState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}

//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 1"
//                     id="last-name"

//                     success={requiredState === "success"}
//                     error={requiredState === "error"}
//                     //id="required"
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyLength(event.target.value, 1)) {
//                           setrequiredState("success");
//                         } else {
//                           setrequiredState("error");
//                         }
//                         setrequired(event.target.value);
//                       },
//                       type: "text",
//                       endAdornment:
//                         requiredState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}

//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="First Name"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 2"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Last Name"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="ZIP code"
//                     id="last-name"

//                     success={numberState === "success"}
//                     error={numberState === "error"}
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyNumber(event.target.value)) {
//                           setnumberState("success");
//                         } else {
//                           setnumberState("error");
//                         }
//                         setnumber(event.target.value);
//                       },
//                       type: "number",
//                       endAdornment:
//                         numberState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}

//                   />
//                 </GridItem>
//               </GridContainer>

              
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Office Number"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="City"
//                     id="last-name"

//                     success={requiredState === "success"}
//                     error={requiredState === "error"}
//                     formControlProps={{
//                       fullWidth: true
//                     }}

//                     inputProps={{
//                       onChange: event => {
//                         if (verifyLength(event.target.value, 1)) {
//                           setrequiredState("success");
//                         } else {
//                           setrequiredState("error");
//                         }
//                         setrequired(event.target.value);
//                       },
//                       type: "text",
//                       endAdornment:
                     
//                         requiredState === "error" ? (
//                           <InputAdornment position="end">
//                             <Close className={classes.danger} />
//                           </InputAdornment>
//                         ) : (
//                           undefined
//                         )
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Mobile Number"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//           <InputLabel
//             htmlFor="simple-select"
//             className={classes.selectLabel}
//           >
//            State
//           </InputLabel>
//           <Select
//             // MenuProps={{
//             //   className: classes.selectMenu
//             // }}
//             // classes={{
//             //   select: classes.select
//             // }}
//             // value={simpleSelect}
//             // onChange={handleSimple}
//             // inputProps={{
//             //   name: "simpleSelect",
//             //   id: "simple-select"
//             // }}
//           >
//             <MenuItem
//               // disabled
//               // classes={{
//               //   root: classes.selectMenuItem
//               // }}
//             >
//               Single Select
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="2"
//             >
//               1
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="3"
//             >
//              2
//             </MenuItem>
//             <MenuItem
//               // classes={{
//               //   root: classes.selectMenuItem,
//               //   selected: classes.selectMenuItemSelected
//               // }}
//               // value="4"
//             >
//              3
//             </MenuItem>
//           </Select>
//         </FormControl>
//       </GridItem>
             
//                 </GridContainer>


           

                
     
                
//                 {/* <GridItem xs={12} sm={12} md={4}>
//                   <CustomInput
//                     labelText="Country"
//                     id="country"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem> */}
//                 {/* <GridItem xs={12} sm={12} md={6}> */}
//                 {/* <Autocomplete
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);
//                   }}
//         {...defaultProps}
//         id="zipcode1"
//         debug
//         size='medium'
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="ZipCode" margin="normal" />}
//       /> */}
//                 {/* </GridItem> */}
              

              
//               {/* <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
//                   <Autocomplete
//                   onInputChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//                   onChange={(event, newValue) => {
//                     handleChange(newValue);

//                   }}
//         {...defaultProps}
//         id="zipcode"
//         debug
    
//         renderInput={(params) => <TextField {...params} onChange={({ target }) => setInput(target.value)} label="ZipCode" margin="normal" />}
//       />
//                 </GridItem>
//               </GridContainer> */}


// <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Email"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
// </GridContainer>

//               {/* <Button color="rose" className={classes.updateProfileButton}>
//                 Update Profile
//               </Button> */}

//               <h4><strong>Billing Contact Same as above</strong></h4>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="First Name"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 1"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Last Name"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Address 2"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Office Number"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="ZIP code"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>


//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Mobile Number"
//                     id="property-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="City"
//                     id="last-name"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>
//               </GridContainer>

//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={6}>
//                   <CustomInput
//                     labelText="Email"
//                     id="city"
//                     formControlProps={{
//                       fullWidth: true
//                     }}
//                   />
//                 </GridItem>

//                 <GridItem xs={12} sm={12} md={6}>

//                 {/* <CustomDropdown
//         buttonText="Search for state"
//         dropdownList={[
//           // "Action",
//           // "Another action",
//           // "Something else here",
//           // {divider: true},
//           // "Separated link",
//           // {divider: true},
//           // "One more separated link",
//         ]}
//       /> */}
//                 </GridItem>
// </GridContainer>



// <br/>
// <br/>
//  <div className={classes.buttonGroup}>
//                 <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
//                   <b>Save</b>
//                 </Button>

//                     <Button size="lg" style={{ background: '#00628B',color:"white" }}  className={classes.firstButton}>
//                   <b>Cancel</b>
//                 </Button>
              
//               </div>

//               <Clearfix />
//             </CardBody>
//           </Card>
//         </GridItem>
        
//       </GridContainer>
     
//     </div>
//   );
// }
// export default Company;

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';


//import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


//  import PhoneInput from 'react-phone-number-input'
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,  
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";



import { useLocation } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; 
// import 'react-confirm-alert/src/react-confirm-alert.css';
import SweetAlert from "react-bootstrap-sweetalert";
// import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";







const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];




const Company = ({tmCompanySaveRequest}) => {
  const history = useHistory();
  
  const location = useLocation();

  const [input, setInput] = React.useState('');

  //email validation-jain
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");
const [typeEmailTwo, settypeEmailTwo] = React.useState("");
const [typeEmailStateTwo, settypeEmailStateTwo] = React.useState("");


  // type validation-jain
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");

const [requiredName, setrequiredName] = React.useState("");
const [requiredStateName, setrequiredStateName] = React.useState("");

const [requiredAddress, setrequiredAddress] = React.useState("");
const [requiredStateAddress, setrequiredStateAddress] = React.useState("");

const [requiredFirstName, setrequiredFirstName] = React.useState("");
const [requiredStateFirstName, setrequiredStateFirstName] = React.useState("");

const [requiredbillingNme, setbillingNme] = React.useState("");
const [requiredBillingName, setrequiredBillingName] = React.useState("");

const [billingAddress, setbillingAddress] = React.useState("");
const [requiredBillingAddress, setrequiredBillingAddress] = React.useState("");

//Number validation-jain


const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");

const [requiredMobNum, setrequiredMobNum] = React.useState("");
const [requiredStateMobNum, setrequiredStateMobNum] = React.useState("");

const [requiredOfficeNum, setrequiredOfficeNum] = React.useState("");
const [requiredStateOfficeNum, setrequiredStateOfficeNum] = React.useState("");

const [requiredOfficeNumTwo, setrequiredOfficeNumTwo] = React.useState("");
const [requiredStateOfficeNumTwo, setrequiredStateOfficeNumTwo] = React.useState("");

const [requiredMobNumTwo, setrequiredMobNumTwo] = React.useState("");
const [requiredStateMobNumTwo, setrequiredStateMobNumTwo] = React.useState("");



// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");

const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");

//sweet alert
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}

const [name, setName] = React.useState("");
const [address1,setAddress1]=React.useState("");
const [firstName, setFirstName] = React.useState("");
const [address2,setAddress2]=React.useState("");
const [lastName, setLastName] = React.useState("");
const [postalCode, setPostalCode] = React.useState("");
const [officePhone,setOfficePhone]=React.useState("");
const [city,setCity]=React.useState("");
const [mobileNumber,setMobileNumber]=React.useState("");
const [state,setState]=React.useState("");
const [email,setEmail]=React.useState("");
const [country,setCountry]=React.useState("");

const [billingName, setBillingName] = React.useState("");
const [billingAddress1,setBillingAddress1]=React.useState("");
const [billingLastName, setBillingLastName] = React.useState("");
const [billingAddress2,setBillingAddress2]=React.useState("");
const [billingOfficeNumber,setBillingOfficeNumber]=React.useState("");
const [billingZipCode,setBillingZipCode]=React.useState("");
const [billingMobileNumber,setBillingMobileNumber]=React.useState("");
const [billingCity,setBillingCity]=React.useState("");
const [billingEmail,setBillingEmail]=React.useState("");
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

const [appUrl,setAppUrl]=React.useState("");
const [requiredAppUrl,setRequiredAppUrl]=React.useState("");
  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
 
  };

  function handleClickCompanyList(event) {
    // event.preventDefault();
    history.push({ 
     pathname: '/admin/companylist'
    });
    
   }
   function hideAlert1(event) {
    history.push({ 
      pathname: '/admin/companylist'
     });
    }
   function handleClickHome(event) {
     // event.preventDefault();
     history.push({ 
      pathname: '/'
     });
     
    }


  const submitForm = () => {

    // if((name!="")&&(address1!="")&&(firstName!="")&&(postalCode!="")&&(officePhone!="")&&(mobileNumber!="")&&(email!="")){
      if(verifyLength(name,1)&&verifyLength(address1,1)&&verifyLength(firstName,1)&&verifyLength(postalCode,1)&&verifyLength(officePhone,1)&&verifyLength(mobileNumber,1)&&verifyEmail(email,1)&&verifyLength(appUrl,1))
      {
    const company={


 name:name,
address1:address1,
address1:address2,
firstName:firstName,
lastName:lastName,
address2:address2,
postalCode:postalCode,
officePhone:officePhone,
city:city,
mobileNumber:mobileNumber,
state:state,
country:country,
email:email,
appUrl:appUrl,



};
tmCompanySaveRequest(company);
history.push( setAlert1(true),{ 
 
  pathname: '/admin/companylist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }
    else{
      setAlert(true);
      if(requiredStateName===""){
        setrequiredStateName("error");
      }
      if (requiredStateAddress==="") {
        setrequiredStateAddress("error");
      }
    
      if (requiredStateFirstName === "") {
        setrequiredStateFirstName("error");
      }
      if (typeEmailState === "") {
        settypeEmailState("error");
      }
      if (numberState === "") {
        setnumberState("error");
      }

      if (requiredStateMobNum === "") {
        setrequiredStateMobNum("error");
      }
    
      if (requiredStateOfficeNum === "") {
        setrequiredStateOfficeNum("error");
      }
      if (requiredAppUrl === "") {
        setRequiredAppUrl("error");
      }
      
    }
  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if ((value!=null)&&(value.length >= length)) {
      return true;
    }
    return false;
  };
  

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

 

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (requiredStateName === "") {
    setrequiredStateName("error");
  }

  if (requiredStateAddress === "") {
    setrequiredStateAddress("error");
  }

  if (requiredStateFirstName === "") {
    setrequiredStateFirstName("error");
  }

  if (requiredStateMobNum === "") {
    setrequiredStateMobNum("error");
  }

  if (requiredStateOfficeNum === "") {
    setrequiredStateOfficeNum("error");
  }

  if (requiredStateOfficeNumTwo === "") {
    setrequiredStateOfficeNumTwo("error");
  }
  if (requiredStateMobNumTwo === "") {
    setrequiredStateMobNumTwo("error");
  }

  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (typeEmailStateTwo === "") {
    settypeEmailStateTwo("error");
  }

  if ( requiredBillingName=== "") {
    setrequiredBillingName("error");
  }

  if ( requiredBillingAddress=== "") {
    setrequiredBillingAddress("error");
  }



  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

// const typeClick = () => {
 
//   if (typeEmailState === "") {
//     settypeEmailState("error");
//   }
  
// };


  const [value, setValue] = React.useState(null);
  const [mobile,setMobile]= React.useState(null);

  const classes = useStyles(); 

  return (
    <div>
      <form>
      <GridContainer>
      {/* <GridItem xs={12} sm={12} md={12}>
      <OrgSelected orgName={selectedOrganization}/>
      <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="#" onClick={handleClickHome} className={classes.link}>
        <HomeIcon className={classes.icon} />
      Home
      </Link>
      <Link
        color="inherit"
        href="#"
        onClick={handleClickCompanyList}
        className={classes.link}
      >
        <WhatshotIcon className={classes.icon} />
       Company List
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
       Create Company
      </Typography>
    </Breadcrumbs>
    <br/>
    <br/>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white", fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4><b>
             Create New Company</b>
              </h4> 
            </CardHeader>
            <CardBody>

            {/* <h5><strong>Main Contact</strong></h5> */}
   
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Company Name *" 
                    id="float"

                   success={requiredStateName === "success"}
                    error={requiredStateName === "error"}
                    //id="required"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                           setName(event.target.value);
                           setrequiredStateName("success");
                        } else {
                          setrequiredStateName("error");
                        }
                        setrequiredName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredStateName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 1 *"
                    id="last-name"

                    success={requiredStateAddress === "success"}
                    error={requiredStateAddress === "error"}
                    //id="required"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setAddress1(event.target.value);
                          setrequiredStateAddress("success");
                        } else {
                          setrequiredStateAddress("error");
                        }
                        setrequiredAddress(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredStateAddress === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name*"
                    id="property-name"
                    success={requiredStateFirstName=== "success"}
                    error={requiredStateFirstName === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setFirstName(event.target.value);
                          setrequiredStateFirstName("success");
                        } else {
                          setrequiredStateFirstName("error");
                        }
                        setrequiredFirstName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                      requiredStateFirstName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}


                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 2"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setAddress2(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="property-name"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setLastName(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Zip Code *"
                    id="last-name"

                    success={numberState === "success"}
                    error={numberState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value,1)) {
                          setPostalCode(event.target.value);
                          setnumberState("success");
                        } else {
                          setnumberState("error");
                        }
                        setnumber(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        numberState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>

              
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
              {/* <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            success={requiredStateOfficeNum === "success"}
            error={requiredStateOfficeNum === "error"}
          >
           Office Number *:Example +15243567896,+14352345678
          </InputLabel>                <CustomInput
          //  country="US"
           international
           withCountryCallingCode
           value={officePhone}
           onChange={setOfficePhone}
      
      //  international={false}
      // countryCallingCodeEditable={false}
      // defaultCountry="US"
     
      success={requiredStateOfficeNum === "success"}
      error={requiredStateOfficeNum === "error"}
      
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: event => {
          if (verifyLength(event.target.value,1)) {
            setOfficePhone(event.target.value);
            setrequiredStateOfficeNum("success");
          } else {
            setrequiredStateOfficeNum("error");
            setOfficePhone("");
          }
          setrequiredOfficeNum(event.target.value);
        },
        type: "text",
        endAdornment:
        requiredStateOfficeNum === "error" ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : (
            undefined
          )
      }}
/> */}
                  <CustomInput
                   labelText="Office Number *:Example +15243567896,+14352345678"
                   
                    id="property-name"
                    
                    // value={officePhone}
                    success={requiredStateOfficeNum === "success"}
                    error={requiredStateOfficeNum === "error"}
                    
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setOfficePhone(event.target.value);
                          setrequiredStateOfficeNum("success");
                        } else {
                          setrequiredStateOfficeNum("error");
                          setOfficePhone("");
                        }
                        setrequiredOfficeNum(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                      requiredStateOfficeNum === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                    />
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                   labelText="Mobile Number *:Example +15243567896,+14352345678"
        

                    
                    id="mobileNumber"
                    // value={mobileNumber}
                    success={requiredStateMobNum === "success"}
                    error={requiredStateMobNum === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value,1)) {
                          setMobileNumber(event.target.value);
                          setrequiredStateMobNum("success");
                        } else {
                          setrequiredStateMobNum("error");
                          setMobileNumber("");
                        }
                        setrequiredMobNum(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                      requiredStateMobNum === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                  </FormControl>
                </GridItem>
              
               
              </GridContainer>


              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="City"
                    id="last-name"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setCity(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
<CustomInput
                  labelText="State"
                    id="state"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value, 0)){
                          setState(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
            
               </GridContainer>
                
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="country"

                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setCountry(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput 
                  labelText="Email *"

                    success={typeEmailState === "success"}
                    error={typeEmailState === "error"}
                    id="typeemail"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          setEmail(event.target.value);
                          settypeEmailState("success");
                        } else {
                          settypeEmailState("error");
                        }
                        settypeEmail(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                        typeEmailState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
</GridContainer>
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Url"
                    id="appUrl"

                    success={requiredAppUrl === "success"}
                    error={requiredAppUrl === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setAppUrl(event.target.value);
                        } else {
                          setRequiredAppUrl("error");
                        }
                        setRequiredAppUrl(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                      requiredAppUrl === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
          
</GridContainer>

              {/* <Button color="rose" className={classes.updateProfileButton}>
                Update Profile
              </Button> */}

              {/* <h4><strong>Billing Contact Same as above</strong></h4>


              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="property-name"

                    success={requiredBillingName === "success"}
                    error={requiredBillingName === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setBillingName(event.target.value);
                          setrequiredBillingName("success");
                        } else {
                          setrequiredBillingName("error");
                        }
                        setbillingNme(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredBillingName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 1"
                    id="last-name"
                    success={requiredBillingAddress === "success"}
                    error={requiredBillingAddress === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setBillingAddress1(event.target.value);
                          setrequiredBillingAddress("success");
                        } else {
                          setrequiredBillingAddress("error");
                        }
                        setbillingAddress(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredBillingAddress=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="property-name"
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setBillingLastName(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Address 2"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setBillingAddress2(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>


              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Office Number"
                    id="property-name"

                    success={requiredStateOfficeNumTwo === "success"}
                    error={requiredStateOfficeNumTwo === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}


                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setBillingOfficeNumber(event.target.value);
                          setrequiredStateOfficeNumTwo("success");
                        } else {
                          setrequiredStateOfficeNumTwo("error");
                        }
                        setrequiredOfficeNumTwo(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                      requiredStateOfficeNumTwo=== "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="ZIP code"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setBillingZipCode(event.target.value);
                        } else {
                          setnumberState("error");
                        }
                        setnumber(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                        numberState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>


              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Mobile Number"
                    id="property-name"

                    success={requiredStateMobNumTwo === "success"}
                    error={requiredStateMobNumTwo === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyNumber(event.target.value)) {
                          setBillingMobileNumber(event.target.value);
                          setrequiredStateMobNumTwo("success");
                        } else {
                          setrequiredStateMobNumTwo("error");
                        }
                        setrequiredMobNumTwo(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                      requiredStateMobNumTwo  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="City"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}



                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setBillingCity(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput 
                  labelText="Email *"

                  success={typeEmailStateTwo === "success"}
                  error={typeEmailStateTwo === "error"}
                    id="typeemail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          setBillingEmail(event.target.value);
                          settypeEmailStateTwo("success");
                        } else {
                          settypeEmailStateTwo("error");
                        }
                        settypeEmailTwo(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                      typeEmailStateTwo  === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem> */}

<br></br>
<br></br>
<br></br>
 <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        
      </div>

 
</GridItem>
                



<div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>

                     <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" ,backgroundColor: "#00acc1", fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Company Saved Successfully!
</SweetAlert>
</div>

         

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}
Company.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmPropertySaveRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Company);