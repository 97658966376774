import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";


import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

// import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";


const useStyles = makeStyles(styles);


const Tenantmonitoringsettings = ({}) => {

  const [input, setInput] = React.useState('');


  //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");

  const [value, setValue] = React.useState(null);


  const [checked, setChecked] = React.useState([24, 22]);
  const [selectedEnabled, setSelectedEnabled] = React.useState("b");

  
  // export default function RegularForms() {
  //   const [checked, setChecked] = React.useState([24, 22]);
  //   const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  //   const [selectedValue, setSelectedValue] = React.useState(null);
  //   const handleChange = event => {
  //     setSelectedValue(event.target.value);
  //   };
    const handleChangeEnabled = event => {
      setSelectedEnabled(event.target.value);
    };
    const handleToggle = value => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };



    
    

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }



    // function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
 
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  
};
 
  const classes = useStyles(); 

  return (
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4 ><b>
             Tenant Monitoring Settings</b>
              </h4> 
            </CardHeader>
            <CardBody>
   
            <form>
              <GridContainer>
              {/* <GridItem xs={4} sm={4}>
                  <FormLabel> 
                  <label>
                    Contify ID
                    </label>
                  </FormLabel>
                </GridItem> */}
                <GridItem xs={4} sm={4}>
  <CustomInput
    labelText="Search Contify ID"
    id="material"
    // formControlProps={{
    //   fullWidth: true
    // }}
    // inputProps={{
    //   endAdornment: (<InputAdornment position="end"><VpnKeyIcon/></InputAdornment>)
    // }}
  />


   <Icon onClick={handleClickOpen}><VpnKeyIcon /></Icon>
  
     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            // type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
   </GridItem>


   <GridItem xs={4} sm={4}>
  <CustomInput
    labelText="Search Credit safe ID"
    id="material"
    // formControlProps={{
    //   fullWidth: true
    // }}
    // inputProps={{
    //   endAdornment: (<InputAdornment position="end"><VpnKeyIcon/></InputAdornment>)
    // }}
  />
 
  <Icon onClick={handleClickOpen}><VpnKeyIcon/></Icon>
 
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            // type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
</GridItem>
</GridContainer>

              <GridContainer>
              {/* <GridItem xs={4} sm={4}>
                  <FormLabel> 
                  Credit Safe ID
                  </FormLabel>
                </GridItem> */}
                <GridItem xs={4} sm={4}>
  <CustomInput
    labelText="Search Cortera ID"
    id="material"
    // formControlProps={{
    //   fullWidth: true
    // }}
    // inputProps={{
    //   endAdornment: (<InputAdornment position="end"><VpnKeyIcon/></InputAdornment>)
    // }}
  />
  <Icon onClick={handleClickOpen}><VpnKeyIcon/></Icon>

  
     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            // type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
</GridItem>
{/* </GridContainer> */}

              {/* <GridContainer> */}
              {/* <GridItem xs={4} sm={4}>
                  <FormLabel>
                 Cortera ID
                  </FormLabel>
                </GridItem> */}
                <GridItem xs={4} sm={4}>
  <CustomInput
    labelText="Search Banko ID"
    id="material"
    // formControlProps={{
    //   fullWidth: true
    // }}
    // inputProps={{
    //   endAdornment: (<InputAdornment position="end"><VpnKeyIcon/></InputAdornment>)
    // }}
  />

<Icon onClick={handleClickOpen}><VpnKeyIcon/></Icon>

     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search"
            variant="outlined"
            // type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
</GridItem>
</GridContainer>

              </form>
            
             
              <form>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Enable Contify ID"
                />
              </div>

<div>
<FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(3)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Enable Banko Monitoring"
                    />
</div>


<div>
<FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(3)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Enable SBM Monitoring"
                    />
</div>
              </form>


              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <CustomInput
                    labelText="Type"
                    id="property-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  /> */}
                </GridItem>
                </GridContainer>

                <form>

                <div>
<FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(3)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Enable Notification"
                    />
</div>

                </form>
                <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {/* <CustomInput
                    labelText="Notification Email"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  /> */}

<CustomInput 
                  labelText="Notification Email"

                    success={typeEmailState === "success"}
                    error={typeEmailState === "error"}
                    id="typeemail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          settypeEmailState("success");
                        } else {
                          settypeEmailState("error");
                        }
                        settypeEmail(event.target.value);
                      },
                      type: "email",
                      endAdornment:
                        typeEmailState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
                </GridItem>



                <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" className={classes.firstButton}>
          Save
        </Button>

        <Button color="info" size="sm" className={classes.firstButton}>
          Cancel
        </Button>
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>
            
            </GridContainer>
              
             


              
              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}
export default Tenantmonitoringsettings;
