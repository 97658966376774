
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import FundTable from "views/Fund/FundTable.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import PublishIcon from '@material-ui/icons/Publish';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import NotificationLogTable from "./NotificationLogTable";
import Moment from 'moment';
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Datetime from "react-datetime";
import { Multiselect } from "multiselect-react-dropdown";
import HTMLRenderer from 'react-html-renderer';
import ReactReadMoreReadLess from "react-read-more-read-less";
import NewsFtl from "views/FtlPages/NewsFtl";
import DerogatoryScoreFtl from "views/FtlPages/DerogatoryScoreFtl";
import CreditScoreFtl from "views/FtlPages/CreditScoreFtl";
import LiensFtl from "views/FtlPages/LiensFtl";
import JudgementScoreFtl from "views/FtlPages/JudgementScoreFtl";
import CautionaryUccScoreFtl from "views/FtlPages/CautionaryUccScoreFtl";
import CautionaryUccScoreInitialPopulateFtl from "views/FtlPages/CautionaryUccScoreInitialPopulateFtl";
import LiensInitialPopulateFtl from "views/FtlPages/LiensInitialPopulateFtl";
import JudgementScoreInitialPopulateFtl from "views/FtlPages/JudgementScoreInitialPopulateFtl";
import TenantScoreFtl from "views/FtlPages/TenantScoreFtl";
import ActiveStatusFtl from "views/FtlPages/ActiveStatusFtl";
import FundOverallScoreFtl from "views/FtlPages/FundOverallScoreFtl";
import PirsScoreFtl from "views/FtlPages/PirsScoreFtl";
import PropertyOverallScoreFtl from "views/FtlPages/PropertyOverallScoreFtl";
import SuitScoreFtl from "views/FtlPages/SuitScoreFtl";
import { BatteryAlertSharp } from "@material-ui/icons";
import DoneIcon from '@material-ui/icons/Done';
import { isNonNullChain } from "typescript";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff'
  }
};


const useStyles = makeStyles(theme => ({

  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'inline-block',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  dateBox: { // To change search box element look
    border: "none",
    fontSize: 18,
    // minHeight: "100%",
    height:"85px",
    // minHeight: "100%",
    // height:"55px",
    // paddingTop:"15px",
    // paddingLeft:"10px",
    // overflow:"scroll",
    // overflowX:"hidden"
    },
   
readMoreLessMore:{
  color: "#000",
  fontWeight:"bold",
}
 
}));

// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});
// const organizationNames = [
  
//   { name: 'Megalytics'},
//   { name: 'AREP'},
//   { name: 'ken Ashley' },
 
//  ];
//  const categoryNames = [
  
//   { name: 'Property Score'},
//   { name: 'Credit Score'},
//   { name: 'Derogatory Score' },
//   { name: 'Pirs Score' },
//   { name: 'Cauitionary Ucc' },
//   { name: 'Liens' },
//   { name: 'Judgement' },
 
//  ];

 const types = [
  
  { name: 'Notification'},
  { name: 'News'},
  
 ];

//  const user = [
  
//   { name: 'tsevier'},
//   { name: 'jchesley'},
//   { name: 'pschulman' },
 
//  ];

 const alertsList = [
  
  { name: 'Property Overall Score Notification'},
  { name: 'Fund Overall Score Notification'},
  { name: 'Credit Score Notification'},
  { name: 'Derogatory Score Notification'},
  { name: 'PIRS Score Notification'},
  { name: 'CautionaryUcc Alert'},
  { name: 'Liens Alert'},
  { name: 'Suits Score Notification'},
  { name: 'Judgment Alert'},
  { name: 'Active Status Notification'},
  { name: 'Tenant Score Notification'},
  

 
 ];

 const dateFromList = [
  
  { date: '01-06-2022'},
   
 ];
 
 const dateToList = [
  
  { date: '04-07-2022'},
 
 ];
  const NotificationLog = ({
    notificationLog,tmGetNotificationLogListRequest,tmCompanyListByOrgRequest,companyList,tmUserListByOrgRequest,userList,tmGetContifyTopicsRequest,contifyTopics,
    tmGetNotificationLogSearchListRequest,notificationLogSearchList
  }) => {
    
    const location = useLocation();
    // const [dataList, setDataList] = React.useState(notificationLogList);
    const [messageobj, setMessageobj] = React.useState("");
    
    // const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    const [alert,setAlert] = React.useState(false);
    const [dataStatus,setDataStatus]=React.useState(false);
    const [alert1,setAlert1] = React.useState(false);
    const [alert2,setAlert2] = React.useState(false);
    const [alert3,setAlert3] = React.useState(false);
    const [showCompanyName,setShowCompanyName]=React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNum, setPageNum] = React.useState(0);
    const [showViewContent, setShowViewContent] = React.useState(false);
    const [showViewNotificationContent, setShowViewNotificationContent] = React.useState(false);
    // const [date, setDate] = React.useState("");
    const [page, setPage] = React.useState(1);

    const [organisationId, setOrganisationId] = React.useState("");
    
    const [tenantId, setTenantId ] = React.useState("");
    const [userId, setUserId ] = React.useState("");
    const [contifyObjectId,setContifyObjectId]=React.useState("");
    const[date,setDate] = React.useState("");
    const[date1,setDate1] = React.useState("");
    const[mailTo,setMailTo] = React.useState("");
    const[mailToUser,setMailToUser] = React.useState("");
    const[notificationEvent,setNotificationEvent] = React.useState("");
    const[status,setStatus] = React.useState("");
    const[content,setContent] = React.useState("");
    
    const [requiredOrganization,setRequiredOrganization]= React.useState("");
    const [organizationId,setOrganizationId]=React.useState(null);
    const [organisation, setOrganisation] = React.useState("");

    const [requiredCategory,setRequiredCategory]= React.useState("");
    const [category,setCategory]=React.useState([]);
    const [categoryId, setCategoryId] = React.useState("");

    const [requiredType,setRequiredType]= React.useState("");
    const [type,setType]=React.useState(null);

    const [requiredUserName,setRequiredUserName]= React.useState("");
    const [userName,setUserName]=React.useState("");
    const [userNameId, setUserNameId] = React.useState(null);

    const [requiredAlerts,setRequiredAlerts]= React.useState("");
    const [alerts,setAlerts]=React.useState([]);
    const [alertId, setAlertId] = React.useState("");

    const [requiredDateFrom,setRequiredDateFrom]= React.useState("");
    const [dateFrom,setDateFrom]=React.useState(null);
    const [dateFromId, setDateFromId] = React.useState("");

    const [requiredDateTo,setRequiredDateTo]= React.useState("");
    const [dateTo,setDateTo]=React.useState(null);
    const [dateToId, setDateToId] = React.useState("");

    const [positive,setPositive]=React.useState("");
    const [negative,setNegative]=React.useState("");
    const [both,setBoth]=React.useState("");

    const[positiveChecked,setCheckedPositive]=React.useState(false);
    const[negativeChecked,setCheckedNegative]=React.useState(false);
    const[bothChecked,setCheckedBoth]=React.useState(false);
    
    const[topicId,setTopicId]=React.useState("");
const[topicName,setTopicName]=React.useState("");

const [loading, setLoading] = useState(false);

const [activeStatusFtl, setActiveStatusFtl] = useState(false);
const [cautionaryUccScoreFtl, setCautionaryUccScoreFtl] = useState(false);
const [cautionaryUccScoreInitialPopulateFtl, setCautionaryUccScoreInitialPopulateFtl] = useState(false);
const [creditScoreFtl, setCreditScoreFtl] = useState(false);
const [derogatoryScoreFtl, setDerogatoryScoreFtl] = useState(false);
const [fundOverallScoreFtl, setFundOverallScoreFtl] = useState(false);
const [judgementScoreFtl, setJudgementScoreFtl] = useState(false);
const [judgementScoreInitialPopulateFtl, setJudgementScoreInitialPopulateFtl] = useState(false);
const [liensFtl, setLiensFtl] = useState(false);
const [liensInitialPopulateFtl, setLiensInitialPopulateFtl] = useState(false);
const [newsFtl, setNewsFtl] = useState(false);
const [pirsScoreFtl, setPirsScoreFtl] = useState(false);
const [propertyOverallScoreFtl, setPropertyOverallScoreFtl] = useState(false);
const [suitScoreFtl, setSuitScoreFtl] = useState(false);
const [tenantScoreFtl, setTenantScoreFtl] = useState(false);

const[showNewsCategory,setShowNewsCategory]=React.useState(false);
const[showNotificationAlert,setShowNotificationAlert]=React.useState(false);
const[showList,setShowList]=React.useState(false);
const[showNewsSearchList,setShowNewsSearchList]=React.useState(false);
const[showAlertsSearchList,setShowAlertsSearchList]=React.useState(false);
const [showSearchList, setShowSearchList] = React.useState(false);
const [id, setId] = React.useState("");
    // const hideAlert1 = () => {
    //   setAlert1(false);
    // }
      const history = useHistory();

      const handlePageChange =(event, value) => {
  
        setPage(value);
        // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
        getNotificationLogList(value-1,pageSize);
  
    
        setTimeout(() => {
        
        }, 500);
        
      
        };

        const handleSearchPageChange =(event, value) => {
  
          setPage(value);

          if(type=="Notification"){
           
            getNotificationLogSearchList(organizationId,userNameId,type,null,selectedAlertValues,dateFrom,dateTo,value-1,pageSize);
      
          }else if(type=="News"){
          
              getNotificationLogSearchList(organizationId,userNameId,type,selectedValues,null,dateFrom,dateTo,value-1,pageSize);
             
          }
        
          // getPropertyTenantLineChartPaginationRequest(propertyId,value-1,pageSize);
          // getNotificationLogSearchList(organizationId,userNameId,type,category,dateFrom,dateTo,value-1,pageSize);
    
      
          setTimeout(() => {
          
          }, 500);
          
        
          };
    
    useEffect(() => {
      // if(organisationId==""||organisationId==null||organisationId==undefined){
      //   setShowCompanyName(false);
      //   setAlert(true);
      // }

      // setOrganizationId("");
      // setOrganisation("");
      // setUserNameId("");
      // setUserName("");
      setLoading(false);
      setShowNewsCategory(false);
      getCompanyList();
      getContifyTopics();
      getNotificationLogList(pageNum,pageSize);
      setShowNewsSearchList(false);
      setShowAlertsSearchList(false);
      setShowSearchList(false);
      setShowList(true);
    setTimeout(() => {
      setDataStatus(true);
    }, 2000);
   
   
    }, [dataStatus]);

   

    const getContifyTopics=async () => {
      await tmGetContifyTopicsRequest();
      setTopicId(contifyTopics.topicId);
      setTopicName(contifyTopics.name);
     
         }

    const getCompanyList = async () => {
      await tmCompanyListByOrgRequest();
      };
      
      const getUserList = async (organizationId) => {
        await tmUserListByOrgRequest(organizationId);
        };



const hideAlert = () => {
  setAlert(false);
  history.push({ 
    pathname: '/admin/select-org',
    state: {requestFrom:"fund"}
   });

}


const hideAlert2 = () => {
  setAlert2(false);
}

const hideAlert3 = () => {
    setAlert3(false);
  }



    
    const getNotificationLogList = async (pageNum,pageSize) => {
      await tmGetNotificationLogListRequest(pageNum,pageSize);
      console.log("notificationLog"+notificationLog);
      
      };

      const getNotificationLogSearchList = async (organizationId,userId,type,category,alerts,dateFrom,dateTo,pageNum,pageSize) => {
  
        await tmGetNotificationLogSearchListRequest(organizationId,userId,type,category,alerts,dateFrom,dateTo,pageNum,pageSize);
        console.log("notificationLogSearchList"+notificationLogSearchList);
        
        };

        const notificationLogSearch = (event) => {
          if(organizationId!=null){
            getNotificationLogSearchList(organizationId,null,null,null,null,null,null,pageNum,pageSize);
            setLoading(true);
            setTimeout(() => {
              
              setShowSearchList(true);
              setLoading(false);
          
            }, 3000);
            
            setShowList(false);
            setShowAlertsSearchList(false);
            setShowNewsSearchList(false);
          }
          if(organizationId!=null&&userNameId!=null){
            getNotificationLogSearchList(organizationId,userNameId,null,null,null,null,null,pageNum,pageSize);
            setLoading(true);
            setTimeout(() => {
              
              setShowSearchList(true);
              setLoading(false);
          
            }, 3000);
            
            setShowList(false);
            setShowAlertsSearchList(false);
            setShowNewsSearchList(false);
          }
          if(organizationId!=null&&userNameId!=null&&type!=null){
         
            if(type=="Notification"){
           
              getNotificationLogSearchList(organizationId,userNameId,type,null,selectedAlertValues,null,null,pageNum,pageSize);
              setLoading(true);
              setTimeout(() => {
                
                 setShowAlertsSearchList(true);
                 setShowSearchList(false);
                 setShowList(false);
                 setShowNewsSearchList(false);
                setLoading(false);
            
              }, 3000);
            
              setShowList(false);
              setShowNewsSearchList(false);
              setShowSearchList(false);
             
              
            }else if(type=="News"){
            
                getNotificationLogSearchList(organizationId,userNameId,type,selectedValues,null,null,null,pageNum,pageSize);
                setLoading(true);
                setTimeout(() => {
                  setShowNewsSearchList(true);
                  setShowSearchList(false);
                  setShowList(false);
                  setShowAlertsSearchList(false);
                  setLoading(false);
                 
                }, 3000);
                // setShowSearchList(true);
                setShowList(false);
                setShowAlertsSearchList(false);
                setShowSearchList(false);
              }
          }

          if(organizationId==null&&userNameId==null&&type!=null){
          
            if(type=="Notification"){
              getNotificationLogSearchList(null,null,type,null,selectedAlertValues,null,null,pageNum,pageSize);
              setLoading(true);
              setTimeout(() => {
                
                setShowAlertsSearchList(true);
                setShowList(false);
                setShowNewsSearchList(false);
                setShowSearchList(false);
                setLoading(false);
            
              }, 3000);
            
              setShowList(false);
              setShowNewsSearchList(false);
              setShowSearchList(false);
            }else if(type=="News"){
            
                getNotificationLogSearchList(null,null,type,selectedValues,null,null,null,pageNum,pageSize);
                setLoading(true);
                setTimeout(() => {
                  setShowNewsSearchList(true);
                  setShowList(false);
                  setShowAlertsSearchList(false);
                  setShowSearchList(false);
                  setLoading(false);
                 
                }, 3000);
                // setShowSearchList(true);
                setShowList(false);
                setShowAlertsSearchList(false);
                setShowSearchList(false);
              }
          }

          if(organizationId!=null&&userNameId!=null&&type!=null&&dateFrom!=null&&dateTo!=null){
         
            if(type=="Notification"){
           
              getNotificationLogSearchList(organizationId,userNameId,type,null,selectedAlertValues,dateFrom,dateTo,pageNum,pageSize);
              setLoading(true);
              setTimeout(() => {
                
                 setShowAlertsSearchList(true);
                 setShowSearchList(false);
                 setShowList(false);
                 setShowNewsSearchList(false);
                setLoading(false);
            
              }, 3000);
            
              setShowList(false);
              setShowNewsSearchList(false);
              setShowSearchList(false);
             
              
            }else if(type=="News"){
            
                getNotificationLogSearchList(organizationId,userNameId,type,selectedValues,null,dateFrom,dateTo,pageNum,pageSize);
                setLoading(true);
                setTimeout(() => {
                  setShowNewsSearchList(true);
                  setShowSearchList(false);
                  setShowList(false);
                  setShowAlertsSearchList(false);
                  setLoading(false);
                 
                }, 3000);
                // setShowSearchList(true);
                setShowList(false);
                setShowAlertsSearchList(false);
                setShowSearchList(false);
              }
          }
          if(organizationId==null&&userNameId==null&&type==null&&dateFrom!=null&&dateTo!=null){
         
              getNotificationLogSearchList(null,null,null,null,null,dateFrom,dateTo,pageNum,pageSize);
              setLoading(true);
              setTimeout(() => {
                
                 setShowAlertsSearchList(false);
                 setShowSearchList(true);
                 setShowList(false);
                 setShowNewsSearchList(false);
                setLoading(false);
            
              }, 3000);
            
              setShowList(false);
              setShowNewsSearchList(false);
              setShowAlertsSearchList(false);
             
          }
        //   if(type=="Notification"){
        //   getNotificationLogSearchList(organizationId,userNameId,type,null,selectedAlertValues,dateFrom,dateTo,pageNum,pageSize);
        //   setLoading(true);
        //   setTimeout(() => {
            
        //     setShowAlertsSearchList(true);
        //     setLoading(false);
        
        //   }, 3000);
        
        //   setShowList(false);
        //   setShowNewsSearchList(false);
        //   setShowSearchList(false);
        // }else if(type=="News"){
        
        //     getNotificationLogSearchList(organizationId,userNameId,type,selectedValues,null,dateFrom,dateTo,pageNum,pageSize);
        //     setLoading(true);
        //     setTimeout(() => {
        //       setShowNewsSearchList(true);
        //       setLoading(false);
             
        //     }, 3000);
        //     // setShowSearchList(true);
        //     setShowList(false);
        //     setShowAlertsSearchList(false);
        //     setShowSearchList(false);
        //   }
        };
        
       
        const notificationLogSearchReset = e => {

          window.location.reload(false);
      
        };

        const getViewContent=(date1,id,organisationId,userId,category,tenantId,contifyObjectId,alertsType,type,notificationEvent)=>{
         
          // setTimeout(() => {
           console.log("userId"+userId);
           setId(id);
           setDate1(date1);
          setOrganisationId(organisationId);
          setUserId(userId);
          setCategory(category);
          setTenantId(tenantId);
          setContifyObjectId(contifyObjectId);
          setAlerts(alertsType);
          setNotificationEvent(notificationEvent);   
          setType(type);
         

          console.log("date"+date1);
   

        // }, 3000);
          if(type=="News"){
                  setShowViewContent(true);
                  }
                  if(type=="Notification"){
        
                    if(notificationEvent=="MONTHLY GENERATION"){
                    
                      setShowViewNotificationContent(true);
                    if(alertsType=="Property Overall Score Notification"){
        
                      
                      setPropertyOverallScoreFtl(true);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
         
                    }else if(alertsType=="Fund Overall Score Notification"){
        
                      setFundOverallScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Credit Score Notification"){
        
                      setCreditScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Derogatory Score Notification"){
                     
                      setDerogatoryScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="PIRS Score Notification"){
        
                      setPirsScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="CautionaryUcc Alert"){
        
                      setCautionaryUccScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Liens Alert"){
        
                      setLiensFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Suits Score Notification"){
        
                      setSuitScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Judgment Alert"){
        
                      setJudgementScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Active Status Notification"){
        
                      setActiveStatusFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Tenant Score Notification"){
        
                      setTenantScoreFtl(true);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
                    }
                  }
        
                  if(notificationEvent=="INITIAL POPULATE"){
                    setShowViewNotificationContent(true);
        
                   
                   if(alertsType=="CautionaryUcc Alert"){
        
                      setCautionaryUccScoreFtl(false);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(true);
        
                    }else if(alertsType=="Liens Alert"){
        
                      setLiensFtl(false);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensInitialPopulateFtl(true);
                      setJudgementScoreFtl(false);
                      setJudgementScoreInitialPopulateFtl(false);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }else if(alertsType=="Judgment Alert"){
        
                      setJudgementScoreFtl(false);
                      setPropertyOverallScoreFtl(false);
                      setActiveStatusFtl(false);
                      setFundOverallScoreFtl(false);
                      setCreditScoreFtl(false);
                      setDerogatoryScoreFtl(false);
                      setPirsScoreFtl(false);
                      setSuitScoreFtl(false);
                      setTenantScoreFtl(false);
                      setLiensFtl(false);
                      setLiensInitialPopulateFtl(false);
                      setJudgementScoreInitialPopulateFtl(true);
                      setCautionaryUccScoreFtl(false);
                      setCautionaryUccScoreInitialPopulateFtl(false);
        
                    }
                  }
                  }
                 
                 
                  
                  
            }
        
   
    const handleCloseViewContent = () => {
      setShowViewContent(false);
    };

    const handleCloseViewNotificationContent = () => {
      setShowViewNotificationContent(false);
    };
const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};

const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}


const selectedOrganisation = (value) => {
 

    setRequiredOrganization("success");
    setOrganizationId(value.id);
    setOrganisation(value.name);
    getUserList(value.id);
    
  
};



// const selectedOrganisation = (value) => {
 
//   if(value===null){
   
//     setOrganizationId("");

//   }else{
//     setRequiredOrganization("success");
//     setOrganizationId(value.id);
//     console.log("organizationId"+organizationId);
//     setOrganisation(value.name);
    
//   }
// };
const selectedType = (value) => {

    setRequiredCategory("success");
    // setCategoryId(value.id);
    // setType(value.name);

    if(value.name=="News"){
      setType(value.name);
      setShowNewsCategory(true);
      setShowNotificationAlert(false);
    }else if(value.name=="Notification"){
      setType(value.name);
      setShowNotificationAlert(true);
      setShowNewsCategory(false);
    }else{

    }
    

};
const selectedCategory = (value) => {
 

    setRequiredCategory("success");
    setTopicId(value.id);
    setTopicName(value.name);
    
  
};
const selectedUser = (value) => {

    setRequiredUserName("success");
    setUserNameId(value.id);
    setUserName(value.username);
    
};

const selectedAlerts = (value) => {

  if(value==""){
    setAlertId("");
  }
    setRequiredAlerts("success");
    setAlertId(value.id);
    setAlerts(value.name);
  
};

const selectedDateFrom = (value) => {
 
    setRequiredDateFrom("success");
    setDateFromId(value.id);
    setDateFrom(value.name);
    

};
const selectedDateTo = (value) => {
 
    setRequiredDateTo("success");
    setDateToId(value.id);
    setDateTo(value.name);
    
};
const defaultProps = {
  options: companyList,
  getOptionLabel: (option) => option.name,
  };
  const defaultType = {
    options: types,
    getOptionLabel: (option) => option.name,
    };
  const defaultCategory = {
    options: contifyTopics,
    getOptionLabel: (option) => option.name,
    };

    const defaultUsers = {
      options: userList,
      getOptionLabel: (option) => option.username,
      };

      const defaultAlerts = {
        options: alertsList,
        getOptionLabel: (option) => option.name,
        };

        const defaultDateFrom = {
          options: dateFromList,
          getOptionLabel: (option) => option.date,
          };

          const defaultDateTo = {
            options: dateToList,
            getOptionLabel: (option) => option.date,
            };
    
            const handleToggle = (event) => {
  
              setCheckedPositive(event.target.checked);
            
            }; 
            const handleToggle1 = (event) => {
  
              setCheckedNegative(event.target.checked);
            
            }; 
            const handleToggle2 = (event) => {
  
              setCheckedBoth(event.target.checked);
            
            }; 
            const selectedStartDate=(value)=>{
           
             value=Moment(value).format('MM-DD-YYYY');
            
             setDateFrom(value);
        
            }

            const[selectedValues,setSelectedValues]=React.useState([]);
            const[selectedAlertValues,setSelectedAlertValues]=React.useState([]);

            const selectedTopics = (value) => {
              // console.log("value"+JSON.stringify(value));
            
              { value.map(liens => {
              //  setSelectedValues([]);
                if (selectedValues.indexOf(liens) === -1) {
                 if(!selectedValues.includes(liens.name)){
                selectedValues.push(liens.name);
                 }
            
          
                }
          
              })}
            };
           
            const handleRemove = (e, index) => {
              console.log("index"+JSON.stringify(index))
              let itemIndex=selectedValues.indexOf(index.name)
        
                  selectedValues.splice(itemIndex, 1);
            
              
            };


            const selectedAlert = (value) => {
              // console.log("value"+JSON.stringify(value));
            
              { value.map(liens => {
              //  setSelectedValues([]);
                if (selectedAlertValues.indexOf(liens) === -1) {
                 if(!selectedAlertValues.includes(liens.name)){
                  selectedAlertValues.push(liens.name);
                 }
              
                }
          
              })}
            };
            const handleAlertRemove = (e, index) => {
           
              let itemIndex=selectedAlertValues.indexOf(index.name)
        
              selectedAlertValues.splice(itemIndex, 1);
            
                
            };

            const colourStyles = {

              multiselectContainer: {   variant:"filled",
              backgroundColor:"#E8E8E8",
              borderRadius:"5px",
              // height:"86%",
              height:"55px",
              // overflow:"scroll"
            },
              
              searchBox: { // To change search box element look
                border: "none",
                fontSize: 18,
                // minHeight: "100%",
                height:"55px",
                paddingTop:"15px",
                paddingLeft:"10px",
                overflow:"scroll",
                overflowX:"hidden"
                },
                // placeholder: {
                //   textAlign:"right"
                // }
             
            }
            const setSelectedStartDate=(value)=>{
         
             value=Moment(value).format('MM-DD-YYYY');
            
             setDateFrom(value);
            
            //return value;
            }
            const setSelectedEndDate=(value)=>{
             
             value=Moment(value).format('MM-DD-YYYY');
            
             setDateTo(value);
      
            //return value;
            }
            
  const classes = useStyles();
  if(dataStatus){
  return (
   
    

    <GridContainer  >
    

      <GridItem xs={12}  >
        <Card >
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px"}} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
           
           
      <text style={{fontSize: "27px", fontWeight:"bold" ,textAlign: "left"}} >Notification Log</text> 

                    
          </CardHeader>
          <CardBody>

 {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }
   
   <GridItem xs={12} sm={12} md={12} style={{paddingLeft:"70px",paddingTop:"30px"}} >
<GridContainer   style={{paddingLeft:"40px",paddingBottom:"30px",paddingTop:"15px" ,backgroundColor:"#055C9D",borderRadius:"20px",width:"100%",  flexGrow: 1,height:"250px",
 
    boxShadow: "13px 13px 18px 8px rgba(0,0,0,0.3)",
 }}>

<GridContainer xs={12} sm={12} md={12}>
<GridItem xs={12} sm={12} md={4} >
    {<Autocomplete
    // value={organizationId}
    success={requiredOrganization === "success"}
    error={requiredOrganization === "error"} 
    onChange={(event, newValue) => {
      selectedOrganisation(newValue);
    // organization(newValue);
  
    }}
    inputProps={{
  
    type: "text",
    endAdornment:
    
    requiredOrganization === "error" ? (
    <InputAdornment position="end">
    <Close className={classes.danger} />
    </InputAdornment>
    ) : (
    <InputAdornment position="end">
    <Close className={classes.success} />
    </InputAdornment>
    )
    }}
    
    {...defaultProps}
    id="organisationId"

    
    debug
    size='medium'
    
    renderInput={(params) => <TextField success={requiredOrganization === "success"}variant="filled" 
    error={requiredOrganization === "error"} 
    {...params} onChange={({ target }) => setOrganizationId(target.value)} label="Organization" style={{backgroundColor:"white",borderRadius:"5px"}} margin="normal" />}
    /> }
    </GridItem>
   
   
 <GridItem xs={12} sm={12} md={4} >
 {<Autocomplete
 success={requiredUserName === "success"}
error={requiredUserName === "error"} 
 onChange={(event, newValue) => {
  selectedUser(newValue);

 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredUserName === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultUsers}
  id="user"

 
 debug
 size='medium'
 
 renderInput={(params) => <TextField success={requiredUserName === "success"}variant="filled"
 error={requiredUserName === "error"} 
 {...params} onChange={({ target }) => setUserNameId(target.value)} label="User" style={{backgroundColor:"white",borderRadius:"5px"}}   margin="normal" />}
 /> }
 </GridItem>

 <GridItem xs={12} sm={12} md={4} >
 {<Autocomplete
 success={requiredType === "success"}
error={requiredType === "error"} 
 onChange={(event, newValue) => {
  selectedType(newValue);

 
 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredType === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultType}
  id="type"

 
 debug
 size='medium'
 
 renderInput={(params) => <TextField success={requiredType === "success"}variant="filled"
 error={requiredType === "error"} 
 {...params} onChange={({ target }) => setType(target.value)} label="Type" style={{backgroundColor:"white",borderRadius:"5px"}}   margin="normal" />}
 /> }
 </GridItem>


</GridContainer>

<GridContainer xs={12} sm={12} md={12}>

{/* {showNewsCategory&&<GridItem xs={12} sm={12} md={4} >
 {<Autocomplete
 success={requiredCategory === "success"}
error={requiredCategory === "error"} 
 onChange={(event, newValue) => {
  selectedCategory(newValue);

 
 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredCategory === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultCategory}
  id="category"

 
 debug
 size='medium'
 
 renderInput={(params) => <TextField success={requiredCategory === "success"}variant="filled"
 error={requiredCategory === "error"} 
 {...params} onChange={({ target }) => setTopicName(target.value)} label="Category" style={{backgroundColor:"white",borderRadius:"5px"}}   margin="normal" />}
 /> }
 </GridItem>}
  */}

{showNewsCategory&&<GridItem xs={12} sm={12} md={4} style={{paddingTop:"16px"}}>
 {/* {<Autocomplete className={classes.select}
 success={requiredCategory === "success"}
error={requi'redCategory === "error"} 
 onChange={(event, newValue) => {
  selectedCategory(newValue);

 
 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredCategory === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultCategory}
  id="category"

 
 debug
 size='medium'
 
 renderInput={(params) =>  */}
 
 <Multiselect 
            
              options={contifyTopics}
              placeholder="Category"
              displayValue="name"
              showCheckbox={true}
              closeOnSelect={false}
              onSelect={selectedTopics}
              onRemove={handleRemove}
              avoidHighlightFirstOption={true}
              style={colourStyles}
             
           />
{/*  
  }
  /> } */}
 </GridItem>}

 {showNotificationAlert&&<GridItem xs={12} sm={12} md={4} style={{paddingTop:"16px"}}>
<Multiselect 
              
              options={alertsList}
              placeholder="Alerts"
              displayValue="name"
              showCheckbox={true}
              closeOnSelect={false}
              onSelect={selectedAlert}
              onRemove={handleAlertRemove}
              avoidHighlightFirstOption={true}
              style={colourStyles}
              
           />
 
 </GridItem>}
{/* {showNotificationAlert&&<GridItem xs={12} sm={12} md={4} >
 {<Autocomplete
 success={requiredAlerts === "success"}
error={requiredAlerts === "error"} 
 onChange={(event, newValue) => {
  selectedAlerts(newValue);

 
 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredAlerts === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultAlerts}
  id="alerts"

 
 debug
 size='medium'
 
 renderInput={(params) => <TextField success={requiredAlerts === "success"} variant="filled"
 error={requiredAlerts === "error"} 
 {...params} onChange={({ target }) => setAlertId(target.value)} label="Alerts" style={{backgroundColor:"white",borderRadius:"5px"}}   margin="normal" />}
 /> }
 </GridItem>} */}

<GridItem xs={12} sm={12} md={4} style={{paddingTop:"16px"}}>



    <FormControl  style={{outline: "none",height:"55px",display:"block",backgroundColor:"#E8E8E8", borderRadius:"5px"}}>
      <Datetime
      inputProps={{
        placeholder:"Select Date From",
        // className:'dateBox'
      }}
      className={'dateBox'}
        timeFormat={false}
        dateFormat="MM-DD-YYYY"
       
        onChange={setSelectedStartDate}
        >
          <div style={{height:"55px"}}>

          </div>
        </Datetime>
    </FormControl>

{/* <FormControl fullWidth>

variant="filled"
success={requiredDateFrom === "success"} 
error={requiredDateFrom === "error"} 
// onChange={({ target }) => setDateFrom(target.value)} 
label="Date From" 
InputLabelProps={{style: {fontSize: 16,color:"grey"}}}
style={{backgroundColor:"white", borderRadius:"5px",width:"100%"}}  
margin="normal" 
// type="date"
// InputLabelProps={{
//   shrink: true,
  
//   }}

// dateFormat="dd/mm/yyyy"
> <Datetime
        timeFormat={false}
        dateFormat="MM-DD-YYYY"
       
        onChange={setSelectedLeaseStartDate}
      /></FormControl> */}

    </GridItem>

    <GridItem xs={12} sm={12} md={4} style={{paddingTop:"16px"}}>

    <FormControl  style={{outline: "none",height:"55px",display:"block",backgroundColor:"#E8E8E8", borderRadius:"5px"}}>
      <Datetime
      inputProps={{
        placeholder:"Select Date To",
        // className:'dateBox'
      }}
      className={'dateBox'}
        timeFormat={false}
        dateFormat="MM-DD-YYYY"     
        onChange={setSelectedEndDate}
      />
    </FormControl>
{/* 
    <TextField 
    variant="filled"
    success={requiredDateTo === "success"} 
    error={requiredDateTo === "error"} 
    onChange={({ target }) => setDateTo(target.value)} 
    label="Date To" 
    InputLabelProps={{style: {fontSize: 16,color:"grey"}}}
    style={{backgroundColor:"white", borderRadius:"5px",width:"100%"}}  
    margin="normal"
    type="date"
    InputLabelProps={{
      shrink: true,
      }}
      /> */}
 
 </GridItem>
   
    {/* <GridItem xs={12} sm={12} md={4} >
 {<Autocomplete
 success={requiredDateTo === "success"}
error={requiredDateTo === "error"} 
 onChange={(event, newValue) => {
  selectedDateTo(newValue);
 console.log("")
 
 }}
 inputProps={{
 
 type: "text",
 endAdornment:
 
 requiredDateTo === "error" ? (
 <InputAdornment position="end">
 <Close className={classes.danger} />
 </InputAdornment>
 ) : (
 <InputAdornment position="end">
 <Close className={classes.success} />
 </InputAdornment>
 )
 }}
 
  {...defaultDateTo}
  id="category"

 
 debug
 size='medium'
 
 renderInput={(params) => <TextField success={requiredDateTo === "success"}variant="filled"
 error={requiredDateTo === "error"} 
 {...params} onChange={({ target }) => setDateTo(target.value)} label="Date To" style={{backgroundColor:"white",borderRadius:"5px"}}   margin="normal" />}
 /> }
 </GridItem> */}

</GridContainer>
<GridContainer xs={12} sm={12} md={12} style={{paddingLeft:"10px"}}>
{/* <GridItem xs={12} sm={12} md={3} style={{paddingTop:"5px"}}>
                <br></br>
                <FormControlLabel
               
          control={
            
            <Checkbox
            style={{color:"white"}}
            value={positive}
              tabIndex={-1}
              
              onClick={handleToggle}
              checked={positive}
             
              defaultChecked={classes.uncheckedIcon}
            //  onChange={handleToggle}
              // checkedIcon={<Check className={classes.checkedIcon} />}
              // icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
               //unchecked: classes.unchecked,
                root: classes.checkRoot
              }}
              //angel
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setPositive(true);
                  } else {
                    setPositive(false);
                  }
                  
                },
                
              }}
       
            />
          }
          classes={{ label: classes.label }}
          label="Positive"
          name="positive"
        />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{paddingTop:"5px"}}>
                <br></br>
                <FormControlLabel
               
          control={
            
            <Checkbox
            style={{color:"white"}}
            value={negative}
              tabIndex={-1}
              
              onClick={handleToggle1}
              checked={negative}
             
              defaultChecked={classes.uncheckedIcon}
            //  onChange={handleToggle}
              // checkedIcon={<Check className={classes.checkedIcon} />}
              // icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
               //unchecked: classes.unchecked,
                root: classes.checkRoot
              }}
              //angel
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setNegative(true);
                  } else {
                    setNegative(false);
                  }
                  
                },
                
              }}
       
            />
          }
          classes={{ label: classes.label }}
          label="Negative"
          name="negative"
        />
                </GridItem>

                <GridItem xs={12} sm={12} md={3} style={{paddingTop:"5px"}}>
                <br></br>
                <FormControlLabel
               
          control={
            
            <Checkbox
            style={{color:"white"}}
            value={both}
              tabIndex={-1}
              
              onClick={handleToggle2}
              checked={both}
             
              defaultChecked={classes.uncheckedIcon}
            //  onChange={handleToggle}
              // checkedIcon={<Check className={classes.checkedIcon} />}
              // icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
               //unchecked: classes.unchecked,
                root: classes.checkRoot
              }}
              //angel
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setBoth(true);
                  } else {
                    setBoth(false);
                  }
                  
                },
                
              }}
       
            />
          }
          classes={{ label: classes.label }}
          label="Both"
          name="both"
        />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={8}></GridItem>
                <GridItem xs={12} sm={12} md={2} paddingBottom="20px">
                <div style={{fontSize:"20", fontWeight: "bold",paddingTop:"15px"}}>
<Button color="info"   size="md"   style={{fontSize:"20", fontWeight: "bold",borderRadius:20}} className={classes.firstButton}  onClick={notificationLogSearch}>
  <SearchIcon/><b>SEARCH</b></Button>
</div>  
</GridItem>

<GridItem xs={12} sm={12} md={2} >
                <div style={{fontSize:"20", fontWeight: "bold",paddingTop:"15px"}}>
<Button color="info"   size="md"  type="reset" style={{fontSize:"20", fontWeight: "bold",borderRadius:20}} className={classes.firstButton}  onClick={notificationLogSearchReset}>
<b>RESET</b></Button>
</div>  
</GridItem>

</GridContainer>
 </GridContainer>
   </GridItem>

  
           {showList&& <NotificationLogTable
              columns={[
                {
                  Header: "Date",
                  accessor: "date",
                
                },
                {
                  Header: "UserName",
                  accessor: "mailToUser",
                
                },
                {
                  Header: "Email",
                  accessor: "mailTo",
                
                },

                {
                  Header: "Event",
                  accessor: "notificationEvent",
              
                },
                {
                  Header: "Type",
                  accessor: "type",
                 
                },
              
                
              {
                Header: "Category",
                accessor: "category",
                //width: getColumnWidth(tmList, 'tenantName', 'Tenant'),
                // Cell: ({ cell,row }) => (<Link to={{ state :{mailToUser : row.original.mailToUser, category:row.original.category} } } 
                //   onClick={() => getViewContent(row.original.organisationId,row.original.category,row.original.date,row.original.mailTo,
                //      row.original.mailToUser,row.original.notificationEvent,row.original.status,row.original.type,row.original.content)}
                //  >{row.original.category}</Link>),
              
            //  width: getColumnWidthNew(notificationLogList, 'accessor', 'Fund Name'),
            },
            
            {
              Header: "Alerts",
              accessor: "alerts",
            
            },
  
              {
                Header: "Status",
                accessor: "status",
              
              },

              // {
              //   Header: "Email",
              //   accessor: "sendingType",
              
              // },

              {
                Header: "Actions",
                accessor: "actions",
                // Cell: ({ cell,row }) => (<Link to={{ state :{mailToUser : row.original.mailToUser, category:row.original.category} } } 
                //     onClick={() => getViewContent(row.original.organisationId,row.original.category,row.original.date,row.original.mailTo,
                //        row.original.mailToUser,row.original.notificationEvent,row.original.status,row.original.type,row.original.content,row.original.alerts)}
                //    >View Message</Link>),

                Cell: ({ cell,row }) => (<Link to={{ state :{id: row.original.id,organisationId : row.original.organisationId, mailToUserId:row.original.mailToUserId} } } 
                  onClick={() => getViewContent(row.original.date1,row.original.id,row.original.organisationId,row.original.mailToUserId,row.original.category,
                                               row.original.tenantId,row.original.contifyObjectId,row.original.alerts,row.original.type,row.original.notificationEvent)}
                 >View Message</Link>),

              },
     
              ]}

              data={notificationLog.notificationLogList.map((prop, key) => {
                return {
                  id: prop.id,
                  organisationId: prop.organisationId,
                  mailToUserId: prop.mailToUserId,
                  category: prop.category,
                  tenantId: prop.tenantId,
                  contifyObjectId: prop.contifyObjectId,

                  date: prop.date,
                  date1: prop.date,
                  mailTo: prop.mailTo,
                  mailToUser: prop.mailToUser,
                  notificationEvent: prop.notificationEvent,
                  status: prop.status,
                  type: prop.type,
                  sendingType: prop.sendingType,
                  content: prop.content,
                  alerts: prop.alerts,
                  
                  
                  
                  date:(
                    <div>
                    {Moment(prop.date).format("MM-DD-YYYY")}
                  </div>
 
                ),
                
                mailTo:(
                  <div>
                   <ReactReadMoreReadLess
                charLimit={25}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={classes.readMoreLessMore}
                readLessClassName={classes.readMoreLessMore}
            >
              {prop.mailTo}
               </ReactReadMoreReadLess>
                </div>

              ),
              status:(
                <div>
                {prop.status ? 
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                <DoneIcon style={{ color: "green" }} />
                </Tooltip> : 
                 <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                <CloseIcon  style={{ color: "red" }}/>
                </Tooltip>}
                 
                </div>
               
                ),

                // sendingType:(
                //   <div>
                //   {prop.sendingType=="EMAIL" ? 
                //      <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                //   <DoneIcon style={{ color: "green" }} />
                //   </Tooltip> : 
                //    <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                //   <CloseIcon  style={{ color: "red" }}/>
                //   </Tooltip>}
                   
                //   </div>
                 
                //   ),

                  // status:(
                  //   <div>
                  //   {prop.status ? 
                  //      <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                  //   <DoneIcon style={{ color: "green" }} />
                  //   </Tooltip> : 
                  //    <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                  //   <CloseIcon  style={{ color: "red" }}/>
                  //   </Tooltip>}
                     
                  //   </div>
                   
                  //   ),
                };
              })}

              //data={fundList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />}

{showSearchList&& <NotificationLogTable
              columns={[
                
                {
                  Header: "Date",
                  accessor: "date",
                
                },
                {
                  Header: "UserName",
                  accessor: "mailToUser",
                
                },
                {
                  Header: "Email",
                  accessor: "mailTo",
                
                },

                {
                  Header: "Event",
                  accessor: "notificationEvent",
              
                },
                {
                  Header: "Type",
                  accessor: "type",
                 
                },
              
              
              {
                Header: "Status",
                accessor: "status",
              
              },

              {
                Header: "Actions",
                accessor: "actions",
              
                Cell: ({ cell,row }) => (<Link to={{ state :{id: row.original.id,organisationId : row.original.organisationId, mailToUserId:row.original.mailToUserId} } } 
                  onClick={() => getViewContent(row.original.date1,row.original.id,row.original.organisationId,row.original.mailToUserId,row.original.category,
                                               row.original.tenantId,row.original.contifyObjectId,row.original.alerts,row.original.type,row.original.notificationEvent)}
                 >View Message</Link>),

              },
              ]}

              data={notificationLogSearchList.notificationLogList.map((prop, key) => {
                return {
                  // id: prop.id,
                  id: prop.id,
                  organisationId: prop.organisationId,
                  mailToUserId: prop.mailToUserId,
                  category: prop.category,
                  tenantId: prop.tenantId,
                  contifyObjectId: prop.contifyObjectId,

                  date: prop.date,
                  date1: prop.date,
                  mailTo: prop.mailTo,
                  mailToUser: prop.mailToUser,
                  notificationEvent: prop.notificationEvent,
                  status: prop.status,
                  type: prop.type,
                  sendingType: prop.sendingType,
                  content: prop.content,
                  alerts: prop.alerts,
                  
                  date:(
                    <div>
                    {Moment(prop.date).format("MM-DD-YYYY")}
                  </div>
 
                ),
                mailTo:(
                  <div>
                   <ReactReadMoreReadLess
                charLimit={25}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={classes.readMoreLessMore}
                readLessClassName={classes.readMoreLessMore}
            >
              {prop.mailTo}
               </ReactReadMoreReadLess>
                </div>

              ),
              status:(
                <div>
                {prop.status ? 
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                <DoneIcon style={{ color: "green" }} />
                </Tooltip> : 
                 <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                <CloseIcon  style={{ color: "red" }}/>
                </Tooltip>}
                 
                </div>
               
                ),
                };
              })}

              //data={fundList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />}

  {showNewsSearchList&& <NotificationLogTable
              columns={[
                
                {
                  Header: "Date",
                  accessor: "date",
                
                },
                {
                  Header: "UserName",
                  accessor: "mailToUser",
                
                },
                {
                  Header: "Email",
                  accessor: "mailTo",
                
                },

                {
                  Header: "Event",
                  accessor: "notificationEvent",
              
                },
                {
                  Header: "Type",
                  accessor: "type",
                 
                },
              
                
              {
                Header: "Category",
                accessor: "category",
                //width: getColumnWidth(tmList, 'tenantName', 'Tenant'),
                // Cell: ({ cell,row }) => (<Link to={{ state :{mailToUser : row.original.mailToUser, category:row.original.category} } } 
                //   onClick={() => getViewContent(row.original.organisationId,row.original.category,row.original.date,row.original.mailTo,
                //      row.original.mailToUser,row.original.notificationEvent,row.original.status,row.original.type,row.original.content)}
                //  >{row.original.category}</Link>),
              
            //  width: getColumnWidthNew(notificationLogList, 'accessor', 'Fund Name'),
            },
            
        
              
              {
                Header: "Status",
                accessor: "status",
              
              },

              {
                Header: "Actions",
                accessor: "actions",
              
                Cell: ({ cell,row }) => (<Link to={{ state :{id: row.original.id,organisationId : row.original.organisationId, mailToUserId:row.original.mailToUserId} } } 
                  onClick={() => getViewContent(row.original.date1,row.original.id,row.original.organisationId,row.original.mailToUserId,row.original.category,
                                               row.original.tenantId,row.original.contifyObjectId,row.original.alerts,row.original.type,row.original.notificationEvent)}
                 >View Message</Link>),

              },
     
              ]}

              data={notificationLogSearchList.notificationLogList.map((prop, key) => {
                return {
                  // id: prop.id,
                  id: prop.id,
                  organisationId: prop.organisationId,
                  mailToUserId: prop.mailToUserId,
                  category: prop.category,
                  tenantId: prop.tenantId,
                  contifyObjectId: prop.contifyObjectId,

                  date: prop.date,
                  date1: prop.date,
                  mailTo: prop.mailTo,
                  mailToUser: prop.mailToUser,
                  notificationEvent: prop.notificationEvent,
                  status: prop.status,
                  type: prop.type,
                  sendingType: prop.sendingType,
                  content: prop.content,
                  alerts: prop.alerts,
                  
                  date:(
                    <div>
                    {Moment(prop.date).format("MM-DD-YYYY")}
                  </div>
 
                ),
                mailTo:(
                  <div>
                   <ReactReadMoreReadLess
                charLimit={25}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={classes.readMoreLessMore}
                readLessClassName={classes.readMoreLessMore}
            >
              {prop.mailTo}
               </ReactReadMoreReadLess>
                </div>

              ),
              status:(
                <div>
                {prop.status ? 
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                <DoneIcon style={{ color: "green" }} />
                </Tooltip> : 
                 <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                <CloseIcon  style={{ color: "red" }}/>
                </Tooltip>}
                 
                </div>
               
                ),

                };
              })}

              //data={fundList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />}

            
            {showAlertsSearchList&&<NotificationLogTable
              columns={[
                
                {
                  Header: "Date",
                  accessor: "date",
                
                },
                {
                  Header: "UserName",
                  accessor: "mailToUser",
                
                },
                {
                  Header: "Email",
                  accessor: "mailTo",
                
                },

                {
                  Header: "Event",
                  accessor: "notificationEvent",
              
                },
                {
                  Header: "Type",
                  accessor: "type",
                 
                },
              
            {
              Header: "Alerts",
              accessor: "alerts",
            
            },

              {
                Header: "Status",
                accessor: "status",
              
              },
              {
                Header: "Actions",
                accessor: "actions",
              
                Cell: ({ cell,row }) => (<Link to={{ state :{id: row.original.id,organisationId : row.original.organisationId, mailToUserId:row.original.mailToUserId} } } 
                  onClick={() => getViewContent(row.original.date1,row.original.id,row.original.organisationId,row.original.mailToUserId,row.original.category,
                                               row.original.tenantId,row.original.contifyObjectId,row.original.alerts,row.original.type,row.original.notificationEvent)}
                 >View Message</Link>),

              },
              // {
              //   Header: "Actions",
              //   accessor: "actions",
              //   Cell: ({ cell,row }) => (<Link to={{ state :{alerts : row.original.alerts, type:row.original.type} } } 
              //     onClick={() => getViewContent(row.original.date,row.original.alerts,row.original.type,row.original.notificationEvent,row.original.organisationId,row.original.category)}
              //    >View Message</Link>),

              // },
     
              ]}

              data={notificationLogSearchList.notificationLogList.map((prop, key) => {
                return {
                  // id: prop.id,
                  id: prop.id,
                  organisationId: prop.organisationId,
                  mailToUserId: prop.mailToUserId,
                  category: prop.category,
                  tenantId: prop.tenantId,
                  contifyObjectId: prop.contifyObjectId,

                  date: prop.date,
                  date1: prop.date,
                  mailTo: prop.mailTo,
                  mailToUser: prop.mailToUser,
                  notificationEvent: prop.notificationEvent,
                  status: prop.status,
                  type: prop.type,
                  sendingType: prop.sendingType,
                  content: prop.content,
                  alerts: prop.alerts,
                  
                  date:(
                    <div>
                    {Moment(prop.date).format("MM-DD-YYYY")}
                  </div>
 
                ),
                mailTo:(
                  <div>
                   <ReactReadMoreReadLess
                charLimit={25}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={classes.readMoreLessMore}
                readLessClassName={classes.readMoreLessMore}
            >
              {prop.mailTo}
               </ReactReadMoreReadLess>
                </div>

              ),
              status:(
                <div>
                {prop.status ? 
                   <Tooltip classes={{ tooltip: classes.customWidth }} title="SUCCESS">
                <DoneIcon style={{ color: "green" }} />
                </Tooltip> : 
                 <Tooltip classes={{ tooltip: classes.customWidth }} title="FAILED">
                <CloseIcon  style={{ color: "red" }}/>
                </Tooltip>}
                 
                </div>
               
                ),

                };
              })}

              //data={fundList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />}
            <br></br>
           {showList&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
 <div>
  <Pagination variant="outlined" color="primary"
 shape="rounded" count={notificationLog.totalPages} page={page} onChange={handlePageChange} size="large" />
 </div>
 
 </GridItem>}

 <GridItem>
               
               <Dialog  fullWidth={true} maxWidth={'md'} 
 open={showViewContent} onClose={handleCloseViewContent}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewContent}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem>

<DialogContent>
{/* {showViewContent &&<HTMLRenderer style={{paddingLeft:"0px"}}
    html={content}
    
           />} */}
          
           <NewsFtl notificationEvent={notificationEvent} organisationId={organisationId} type={type} category={category} date1={date1} userId={userId} tenantId={tenantId} contifyObjectId={contifyObjectId}/>
       </DialogContent>
       </GridItem>  
       </Dialog>
               </GridItem> 

               <GridItem>
               
               <Dialog  fullWidth={true} maxWidth={'md'} 
 open={showViewNotificationContent} onClose={handleCloseViewNotificationContent}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewNotificationContent}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem>

<DialogContent>
  {activeStatusFtl&&<ActiveStatusFtl userId={userId} date1={date1}/>}
  {cautionaryUccScoreFtl&&<CautionaryUccScoreFtl userId={userId} date1={date1}/>}
  {cautionaryUccScoreInitialPopulateFtl&&<CautionaryUccScoreInitialPopulateFtl userId={userId} date1={date1}/>}
  {creditScoreFtl&&<CreditScoreFtl userId={userId} date1={date1}/>}
  {derogatoryScoreFtl&&<DerogatoryScoreFtl userId={userId} date1={date1}/>}
  {fundOverallScoreFtl&&<FundOverallScoreFtl userId={userId} date1={date1}/>}
  {judgementScoreFtl&&<JudgementScoreFtl userId={userId} date1={date1}/>}
  {judgementScoreInitialPopulateFtl&&<JudgementScoreInitialPopulateFtl userId={userId} date1={date1}/>}
  {liensFtl&&<LiensFtl userId={userId} date1={date1}/>}
  {liensInitialPopulateFtl&&<LiensInitialPopulateFtl userId={userId} date1={date1}/>}
  {pirsScoreFtl&&<PirsScoreFtl userId={userId} date1={date1}/>}
  {propertyOverallScoreFtl&&<PropertyOverallScoreFtl userId={userId} date1={date1}/>}
  {suitScoreFtl&&<SuitScoreFtl userId={userId} date1={date1}/>}
  {tenantScoreFtl&&<TenantScoreFtl userId={userId} date1={date1}/>}
       </DialogContent>
       </GridItem>  
       </Dialog>
               </GridItem> 

            
                    <br></br>
           {showNewsSearchList&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
 <div>
  <Pagination variant="outlined" color="primary"
 shape="rounded" count={notificationLogSearchList.totalPages} page={page} onChange={handleSearchPageChange} size="large" />
 </div>
 
 </GridItem>}
 {showAlertsSearchList&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
 <div>
  <Pagination variant="outlined" color="primary"
 shape="rounded" count={notificationLogSearchList.totalPages} page={page} onChange={handleSearchPageChange} size="large" />
 </div>
 
 </GridItem>}
 {showSearchList&&<GridItem xs={12} sm={12} md={9} className={classes.vertical} style={{marginLeft:"400px",paddingTop:'10px',textAlign:"left",paddingBottom:"50px"}} >
 
 <div>
  <Pagination variant="outlined" color="primary"
 shape="rounded" count={notificationLogSearchList.totalPages} page={page} onChange={handleSearchPageChange} size="large" />
 </div>
 
 </GridItem>}
 {loading&&<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>}
          </CardBody>
        </Card>
      </GridItem>
      <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
          onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please select Company
        </SweetAlert>
      
                     </div>
                     {/* <div>
  <SweetAlert
        success
        show={alert1}
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to delete fund?"
        onConfirm={() =>deleteFund(fundId)}
        onCancel={() => hideAlert1()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
        showConfirm
      >
       
      </SweetAlert>

</div> */}
   
  

    </GridContainer>

    
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}


NotificationLog.propTypes = {
 
  loading: PropTypes.bool.isRequired,
  tmGetNotificationLogListRequest: PropTypes.func.isRequired,
  tmCompanyListByOrgRequest: PropTypes.func.isRequired,
  tmUserListByOrgRequest: PropTypes.func.isRequired,
  tmGetContifyTopicsRequest: PropTypes.func.isRequired,
  tmGetNotificationLogSearchListRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  notificationLog: state.tenantmonitoring.notificationLog,
  notificationLogSearchList: state.tenantmonitoring.notificationLogSearchList,
  fundDelete: state.tenantmonitoring.fundDelete,
  companyList: state.tenantmonitoring.companyList,
  userList: state.tenantmonitoring.userList,
  contifyTopics: state.tenantmonitoring.contifyTopics,
  loading: state.tenantmonitoring.loading,
  organisationId: state.organisationId,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationLog);




