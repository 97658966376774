import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags() {

  const fixedOptions = [];
  const [value, setValue] = React.useState([]);
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      value={value}
      onChange={(event, newValue) => {  
      setValue([
          ...fixedOptions,
          ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
        ]);
      }}
      options={top100Films}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            color="primary"
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            value={option.title}
          
          />
          {option.title}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="News Categories" placeholder="Select" />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
 	
  { title: 'Partnerships & Alliances', year: 2500 },
  { title: 'Management Changes', year: 2496 },
  { title: 'Awards & Recognitions', year: 2508 },
  { title: 'Events', year: 2504 },
  { title: 'Financial & Business Performance', year: 2498 },
  { title: "Mergers and Acquisitions Activities", year: 2497 },
  { title: 'Procurement & Sales', year: 2501 },
  { title: 'New Offerings', year: 2505 },
  { title: 'Cost Cutting', year: 2507 },
  { title: 'Regulatory & Legal', year: 3154 },
  { title: 'Business Expansion', year: 2499 },
  { title: 'Funding Activity', year: 2502 },
  { title: 'Operational Challenges', year: 3281 },
  { title: 'Competitive Landscape', year: 2503 },
  { title: 'Bankruptcy & Restructuring', year: 2506 },
]