/*eslint-disable*/
import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon  from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import image from "assets/img/bg17.jpg";
import logo3 from "assets/img/logo3.png";
import mega_11803_logo_notag_500 from "assets/img/mega_11803_logo_notag_500.jpg";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AuthActions } from '../../store/ducks/auth';
import { useHistory } from "react-router-dom";
import ForgotPassword from './ForgotPassword';

import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const style = {
  imagesStyles,
  loginPageStyle,
  textMuted: {
    color: "#6c757d"
  }
};

const useStyles = makeStyles(loginPageStyle,imagesStyles);


const Login = ({ signInRequest,loggedInUserRequest }) => {

  const history = useHistory();
  //const [loggedIn,setLoggedIn]

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
  });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
   const [showChangePassword, setShowChangePassword] = React.useState(false);
  // // const [showDerogatoryHeatMap, setShowDerogatoryHeatMap] = React.useState(false);
  const [showIndex, setShowIndex] = React.useState(false);

  const CryptoJS = require("crypto-js");

  const handleSubmit = (e) => {
    e.preventDefault();

  const keyBase64 = "o9szYIOq1rRMiouNhNvaq96lqUvCekxR";
var key = CryptoJS.enc.Base64.parse(keyBase64);
var srcs = CryptoJS.enc.Utf8.parse(password);
var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});

  signInRequest(email,encrypted.toString());

 loggedInUserRequest(email);
 history.push('/');
  };

  const [open, setOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClickOpenForgotPassword = () => {
    setShowForgotPassword(true);
  
  };


  const handleCloseForgotPassword = () => {
    setShowForgotPassword(false);
   
  };
  // const handleClickOpenDerogatoryReportHeatMAp = () => {
  //   setShowForgotPassword(false);
  //   setShowChangePassword(true);
    
  // };


  const classes = useStyles();
  return (
    <div>
     
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
        
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
       
                <form className={classes.form} onSubmit={handleSubmit}>
                <div  style={{height:'200px',paddingBottom:'5rem'}} className={classes.textCenter}>
                <img className={classes.imgCard} src={mega_11803_logo_notag_500} alt="Card-img"  width="250" height="160" />
               </div>
                  <CardHeader
                    color="white"
                    signup
                    image
                    className={classes.cardHeader}
                  >

                  </CardHeader>      
                          
                  <CardBody signup>
        
                    <CustomInput
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      
                      inputProps={{
                        onChange: (event) => setEmail(event.target.value),
                        placeholder: "Email...",
                    //    type: "email",
                        name:"username",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}

                    />
                    <CustomInput
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      
                      inputProps={{
                        onChange: (event) => setPassword(event.target.value),
                        placeholder: "Password",
                        type: "password",
                        name: "password",
                       
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockTwoToneIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  <div  className={classes.loginButton}>
                 
                  <Button  type="submit" size="lg" color="info" round><VpnKeyTwoToneIcon fontSize="large"></VpnKeyTwoToneIcon>Login</Button>
                  </div>
                  <div className={classes.textCenter}>
                    <Button simple color="info" size="lg" onClick={handleClickOpenForgotPassword}>
        
 
                      Forgot Password ?
                   
                    </Button>
                    <GridItem >
               
               <Dialog fullScreen
maxWidth={'lg'} open={showForgotPassword} onClose={handleCloseForgotPassword}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
     
<GridItem paddingLeft='30px' align="justify">
  
{showForgotPassword && <ForgotPassword/>} </GridItem>
          </Dialog>
               </GridItem>
              
                    
                  </div>
                </form>
              </Card>
            </GridItem>

            
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
           
              </div>
           
            </div>
          }
        />
      </div>
    </div>
  );
}


Login.propTypes = {
  signInRequest: PropTypes.func.isRequired,
  loggedInUserRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(Login);
