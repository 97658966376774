

import React,{useEffect,useState} from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';


import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Heatmap from "components/Charts/Heatmap.js";
import HeatMapProperty from "components/Charts/HeapMapProperty.js";
import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import avatar from "assets/img/faces/marc.jpg";
//import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import ContifyIdModalPage from "components/Popup/ContifyIdModalPage.js"
import CorteraIdModalPage from "components/Popup/CorteraIdModalPage.js"
import CreditSafeIdModalPage from "components/Popup/CreditSafeIdModalPage.js"


import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
import SaveIcon from '@material-ui/icons/Save';
import Check from "@material-ui/icons/Check";

import {
 whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import { useHistory } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useLocation } from "react-router-dom";

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import TextField from '@material-ui/core/TextField';

import Moment from 'moment';
// import DateRange from 'react-datepicker';

// import Alert from '@material-ui/lab/Alert';
import { warningBoxShadow } from "assets/jss/material-dashboard-pro-react";

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Badge from "components/Badge/Badge.js";
import Edit from "@material-ui/icons/Edit";

import CloseIcon from '@material-ui/icons/Close';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Switch from "@material-ui/core/Switch";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "50px"
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];






const CommentaryCreate = ({tmTenantCommentarySaveRequest}) => {
  const history = useHistory();
  const location = useLocation();


//sweet alert
const [alert1,setAlert1] = React.useState(false);
const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}

const [dataStatus,setDataStatus]=React.useState(false);

const [title, setTitle] = React.useState("");
const [requiredCity, setrequiredCity ]= React.useState("");
const [requiredCityName, setrequiredCityName] = React.useState("");
const [required, setrequired] = React.useState("");
const [requiredTitle, setrequiredTitle] = React.useState("");
const [requiredState, setrequiredState ]= React.useState("");
const [requiredStateName, setrequiredStateName] = React.useState("");
const [date,setDate]=React.useState("");
const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

const [tenantId,setTenantId]=React.useState(location.state.tenantId);
const [commentary , setCommentary] = React.useState("");
const [organisationName , setOrganisationName ] = React.useState("");
const [id,setId]= React.useState("");

const [richtext, setrichText] = React.useState("");
const [tenantName, setTenantName] = React.useState(location.state.tenantName);
const [date1,setDate1]=React.useState(moment().format('MM-DD-YYYY'))
  useEffect(() => {
    console.log("tenantId"+tenantId);
    console.log("tenantName"+tenantName);
    console.log("organisationId"+organisationId);
    setTimeout(() => {
      setDataStatus(true);
    }, 2000);
  },[dataStatus]);

  const submitForm = () => {

    // if(verifyLength(name!="")&&(legalName!="")&&(address1!="")&&(notificationEmail!="")&&(numberOfLocations!="")&&(annualRent!="")&&(phnNo1!="")&&(phnNo2!=""))
    if(verifyLength(title,1))
   {
const tenantCommentary={

title:title,
date:date,
commentary:commentary,
tenantId:tenantId,
tenantName:tenantName,
organisationId:organisationId,


};



tmTenantCommentarySaveRequest(tenantCommentary);
console.log("commentary"+JSON.stringify(tenantCommentary));
history.push( setAlert1(true),{ 
 
  pathname: '/admin/tenantlist',
  // state:{ message:"Fund Saved Successfully" }
 });
  }
 
else{
 
  setAlert(true);
  // setrequiredContifyId("error");
  // setrequiredCreditSafeId("error");
  if (requiredTitle === "") {
    setrequiredTitle("error");
  }


}
};

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if ((value!=null)&&(value.length >= length)) {
    return true;
  }
  return false;
};


// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};
const setSelectedLeaseStartDate=(value)=>{
  console.log("value"+value);
 value=Moment(value).format('MM-DD-YYYY');

 setDate(value);

 console.log("value"+value);
//return value;
}


     function hideAlert1(event) {
      history.push({ 
        pathname: '/admin/tenantlist'
       });
      }
     function handleClickHome(event) {
       // event.preventDefault();
       history.push({ 
        pathname: '/'
       });
       
      }

     

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    <div>
      <form>
      <GridContainer>
  
        <GridItem xs={12}>
          <Card>
         
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"16px" }} icon>
          <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <text style={{fontSize: "25px", fontWeight:"bold" ,textAlign: "left"}} >Create Commentary</text> <span style={{marginLeft:"560px"}}> <Badge  style={{alignSelf: 'flex-end'}} color="info"  > <h2 ><b>{selectedOrganization}</b></h2></Badge>     </span>
  <Button
                        justIcon
                        round
                        simple
                        onClick={() =>history.push({ 
                           pathname: '/admin/select-org',
                          state: {requestFrom:"createTenant"}
                        
                         }) }
                        //   onClick={() => 
                        //   alert("You've pressed the edit button on colmun id: " + prop.id)
                        // }
                        color="white"
                        className="edit"
                      >
                         <Tooltip classes={{ tooltip: classes.customWidth }} title="Change Company">
                        <Edit />
                        </Tooltip>
                      </Button>{" "}
                 
                  
            </CardHeader>
            <CardBody>

           
   
              <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
<br />
<InputLabel className={classes.label}>
Date
</InputLabel>

<FormControl fullWidth>
<Datetime
timeFormat={false}
dateFormat="MM-DD-YYYY"
defaultValue={date1}
onChange={setSelectedLeaseStartDate}
/>
</FormControl>

</GridItem>

                <GridItem xs={12} sm={12} md={6} style={{paddingTop:"13px"}}>
                  <CustomInput
                    labelText="Title"
                    id="title"
                    // value={name}

                    success={requiredTitle === "success"}
                    error={requiredTitle === "error"}

                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1 )) {
                          setTitle(event.target.value);
                          setrequiredTitle("success");
                        } else {
                          setrequiredTitle("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12} >


<CKEditor

editor={ClassicEditor}
data={richtext}
onReady={ editor => {
editor.setData("");

} }
config={ {
  placeholder: "Type the commentary here..."
} }
onChange={(event, editor) => {
  const data = editor.getData()
  setCommentary(data)
}}
>
</CKEditor>

</GridItem>
</GridContainer>    
<br></br>
<GridItem xs={12} sm={12} md={12}>
        <div className={classes.buttonGroup}>
          <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}}>
          <SaveIcon/>  &nbsp;&nbsp;Save  
          </Button>
          </div>
          </GridItem>     
        <Clearfix/>
        </CardBody>
        </Card>
        </GridItem>
              
              </GridContainer>

              <div>
           
 
           <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>
      
                     </div>


                     <div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Tenant Saved Successfully!
</SweetAlert>
</div>



              </form>
              </div>
  );
}else{
  return(
<div align="center">
  <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={3000} //3 secs
    /></div>
    )
}
}



      
CommentaryCreate.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmTenantCommentarySaveRequest: PropTypes.func.isRequired,
  tmParentByOrgIdRequest:PropTypes.func.isRequired,
  tmPropertyWithUserRequest:PropTypes.func.isRequired,

  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  parentByOrg: state.tenantmonitoring.parentByOrg,
  propertyWithUser: state.tenantmonitoring.propertyWithUser,
  
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentaryCreate);