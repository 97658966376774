import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBase from 'components/MenuBase/SideMenuBase.js';
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import ReactPaginate from 'react-paginate';
import HeatMapPropertyFM from "components/Charts/HeatMapPropertyFM.js";
import TMHeatMapPropertyFM from "components/Charts/TMHeatMapPropertyFM.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";

import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
 
//import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import Paginations from "components/Pagination/Pagination.js";

import Pagination from '@material-ui/lab/Pagination';
import { getFundDashboardCount } from "store/sagas/tenantmonitoring";

import { useLocation } from "react-router-dom";
 
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CloseIcon from '@material-ui/icons/Close';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "variables/charts.js";
//import { CardView } from 'react-card-with-image'

import CardMedia from '@material-ui/core/CardMedia';
  //const useStyles = makeStyles(styles);

  import property2 from "assets/img/property2.jpeg";
  import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from 'moment';
import TMHeatMapRisk1 from "./TMHeatMapRisk1";
import TMHeatMapRiskGridView from "./TMHeatMapRiskGridView";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import GaugeChart from 'react-gauge-chart'
import MTTable from "components/MTTable/MTTable";
import PropertyDetailsTable from "./PropertyDetailsTable";
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Tooltip } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchField from "react-search-field";

const useStyles = makeStyles((theme) => ({
    root: {
    width:"100%",
     height:"100%",
      // maxWidth: "400px",
   
    // paddingBottom:"150px",
      //background: "hsla(0, 0, 0, 0.7)"
       boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)"
     
    },

    
    
    title: {
      fontSize: 25,
      color:"#ffffff",
      textAlign:"center",
      paddingTop:"68px"
    },

   
    pos: {
    //textAlign:"right",
    backgroundColor:"#64bf6a",
    color:"#ffffff",
    fontSize:"18px",
    // marginLeft:"20px",
    // marginTop:"10px",
    paddingRight:"12px",
    paddingLeft:"12px",
     paddingTop:"8px",
   paddingBottom:"8px",
    },

  
  cardHorizontal: {
    display: "flex",
    flex: "1 1 auto"
  },
  horizontal: {
    display: "flex",
    //margin: "0 -10px",
    alignItem: "center",
    paddingLeft:"40px",
   paddingRight:"40px",
    paddingBottom:"40px",

//  paddingLeft:"40px",
   //justifyContent: "space-between",
   //flexWrap: "wrap",
//rowGap: "10px",
columnGap: "0.3em"
  
},
  cardTitles: {
    // paddingLeft:"10px",
    fontSize:"18px",
    marginLeft:"50px",
  
  },
  chart:{
    marginLeft:"70px",
   // marginTop:"0px"
 },
  cardTitle: {

    width: "100%",
    //  height: "5vw",
    objectFit: "cover",
   // position: "absolute",
  marginTop: "8px",
  marginLeft:"50px",
//  align:"center",
	// height:"299px",
	// width: "225px",
	background: "#fff",
	borderRadius: "3px",
	overflow: "hidden",
	boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)",
  transition: "all 0.2s ease-out",
  top: "-4px"
  },
  // hover: {
  //   transition: "all 0.2s ease-out",
  //   boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
  //   top: "-4px",
  //   border: "1px solid #cccccc",
  //   backgroundColor: "white"
  // }

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
 text:{
    display:"inline",
    marginLeft:"50px",
    // float:"center",
    },
    arrow:{
    display:"inline",
    
    // float:"center"
    }
 
  }));
  


const BranchDetailsDemo = ({user,loading}) =>{

const history = useHistory();
const location = useLocation();
const[dynamicHeight,setDynamicHeight]= React.useState("");






    // React.useEffect(() => {
    

    //   },[]);


          
    const goback=()=>{
      //  history.push("/admin/dashboard");
      history.back();
    } 
    var now = moment();
    var monthArray=[];
    var yearArray=[];
   
    var currMonthName  =  moment().subtract(1, "month").format('MMM').toUpperCase();
    var currYear  = moment().format('YYYY');
    var prev1MonthName  = moment().subtract(2, "month").format('MMM').toUpperCase();
    var prev1Year  = moment().subtract(1, "month").format('YYYY').toLowerCase();
    var prev2MonthName  = moment().subtract(3, "month").format('MMM').toUpperCase();
    var prev2Year  = moment().subtract(2, "month").format('YYYY').toLowerCase();
    var prev3MonthName  = moment().subtract(4, "month").format('MMM').toUpperCase();;
    var prev3Year  = moment().subtract(3, "month").format('YYYY').toLowerCase();
    var prev4MonthName  = moment().subtract(5, "month").format('MMM').toUpperCase();;
    var prev4Year  = moment().subtract(4, "month").format('YYYY').toLowerCase();
    var prev5MonthName  = moment().subtract(6, "month").format('MMM').toUpperCase();;
    var prev5Year  = moment().subtract(5, "month").format('YYYY').toLowerCase();
   
    var prev6MonthName  = moment().subtract(7, "month").format('MMM').toUpperCase();;
    var prev6Year  = moment().subtract(6, "month").format('YYYY').toLowerCase();
    var prev7MonthName  = moment().subtract(8, "month").format('MMM').toUpperCase();;
    var prev7Year  = moment().subtract(7, "month").format('YYYY').toLowerCase();
    var prev8MonthName  = moment().subtract(9, "month").format('MMM').toUpperCase();;
    var prev8Year  = moment().subtract(8, "month").format('YYYY').toLowerCase();
    var prev9MonthName  = moment().subtract(10, "month").format('MMM').toUpperCase();;
    var prev9Year  = moment().subtract(9, "month").format('YYYY').toLowerCase();
    var prev10MonthName  = moment().subtract(11, "month").format('MMM').toUpperCase();;
    var prev10Year  = moment().subtract(10, "month").format('YYYY').toLowerCase();
    var prev11MonthName  = moment().subtract(12, "month").format('MMM').toUpperCase();;
    var prev11Year  = moment().subtract(11, "month").format('YYYY').toLowerCase();
    

    const month1=[prev11MonthName+"-20",prev10MonthName+"-20",prev9MonthName+"-20",prev8MonthName+"-21",prev7MonthName+"-21",prev6MonthName+"-21",prev5MonthName+"-21",prev4MonthName+"-21",prev3MonthName+"-21",prev2MonthName+"-21",prev1MonthName+"-21",currMonthName+"-21"];
    const year1=[prev5Year,prev4Year,prev3Year,prev2Year,prev1Year,currYear];

  
    const lineChart = {

      series : [ {
        data : [ "389", "387", "382", "385", "382", "330", "324", "318", "312", "314", "314", "316" ],
      } ],
  
    options: {
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            fontSize: 15,
            fontColor: 'lightgrey'
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 15,
            fontColor: 'lightgrey',
            maxTicksLimit: 5,
            padding: 25,
          }
        }]
      },
      // title: {
      //   text: tenantName,
      // },
      chart: {
        height: 400,
        width: 800,
        type: 'line',
        
        dropShadow: {
          enabled: true,
          color: '#fff',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        }
        
      },
      
        
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 5,
        dashArray: 0,
      },
      markers: {
        size: 6,
        color: '#00008b'
    },
  
  
    
   
    grid: {
      // show: true,      // you can either change hear to disable all grids
      // type: 'line',
      row: {
          colors: ['#e5e5e5', 'transparent'],
          opacity: 0.5
      }, 
      // column: {
      //     colors: ['#f8f8f8', 'transparent'],
      // }, 
  
      xAxes: [{
        gridLines: {
          display: false,
        },
        lines: {
          show: true
        },
      
      }],
      // xaxis: {
      //   lines: {
      //     show: true
      //   }
      // }
      // grid: {
      //   show: true,      // you can either change hear to disable all grids
      //   type: 'line',
        
      //   xaxis: {
      //     lines: {
      //       show: true //or just here to disable only x axis grids
      //      }
      //    } 
        }, 
      xaxis: {
        
        categories:  month1,
        labels: {
          show: true,
          style: {
            fontSize: "13px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
              },
  
              axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#000000',
                height: 4,
                offsetX: 0,
                offsetY: 0
            },
              
              // tickColor:"#ffffff"    
      },
      yaxis: {
  
       
        show: true,
        labels: {
          show: true,
          style: {
            fontSize: "13px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
              },
      },
      
    },
    
    
    };


    const lineChart2 = {

        series : [ {
          data : [ "359", "287", "452", "245", "495", "355", "344", "318", "415", "284", "388", "430" ],
        } ],
    
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 15,
              fontColor: 'lightgrey'
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: 'lightgrey',
              maxTicksLimit: 5,
              padding: 25,
            }
          }]
        },
        // title: {
        //   text: tenantName,
        // },
        chart: {
          height: 400,
          width: 800,
          type: 'line',
          
          dropShadow: {
            enabled: true,
            color: '#fff',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false
          }
          
        },
        
          
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 5,
          dashArray: 0,
        },
        markers: {
          size: 6,
          color: '#00008b'
      },
    
    
      
     
      grid: {
        // show: true,      // you can either change hear to disable all grids
        // type: 'line',
        row: {
            colors: ['#e5e5e5', 'transparent'],
            opacity: 0.5
        }, 
        // column: {
        //     colors: ['#f8f8f8', 'transparent'],
        // }, 
    
        xAxes: [{
          gridLines: {
            display: false,
          },
          lines: {
            show: true
          },
        
        }],
        // xaxis: {
        //   lines: {
        //     show: true
        //   }
        // }
        // grid: {
        //   show: true,      // you can either change hear to disable all grids
        //   type: 'line',
          
        //   xaxis: {
        //     lines: {
        //       show: true //or just here to disable only x axis grids
        //      }
        //    } 
          }, 
        xaxis: {
          
          categories:  month1,
          labels: {
            show: true,
            style: {
              fontSize: "13px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
    
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#000000',
                  height: 4,
                  offsetX: 0,
                  offsetY: 0
              },
                
                // tickColor:"#ffffff"    
        },
        yaxis: {
    
         
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: "13px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
        },
        
      },
      
      
      };
    
      const lineChart3 = {

        series : [ {
          data : [ "280", "497", "325", "500", "480", "364", "244", "299", "450", "350", "394", "400" ],
        } ],
    
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 15,
              fontColor: 'lightgrey'
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: 'lightgrey',
              maxTicksLimit: 5,
              padding: 25,
            }
          }]
        },
        // title: {
        //   text: tenantName,
        // },
        chart: {
          height: 400,
          width: 800,
          type: 'line',
          
          dropShadow: {
            enabled: true,
            color: '#fff',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false
          }
          
        },
        
          
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 5,
          dashArray: 0,
        },
        markers: {
          size: 6,
          color: '#00008b'
      },
    
    
      
     
      grid: {
        // show: true,      // you can either change hear to disable all grids
        // type: 'line',
        row: {
            colors: ['#e5e5e5', 'transparent'],
            opacity: 0.5
        }, 
        // column: {
        //     colors: ['#f8f8f8', 'transparent'],
        // }, 
    
        xAxes: [{
          gridLines: {
            display: false,
          },
          lines: {
            show: true
          },
        
        }],
        // xaxis: {
        //   lines: {
        //     show: true
        //   }
        // }
        // grid: {
        //   show: true,      // you can either change hear to disable all grids
        //   type: 'line',
          
        //   xaxis: {
        //     lines: {
        //       show: true //or just here to disable only x axis grids
        //      }
        //    } 
          }, 
        xaxis: {
          
          categories:  month1,
          labels: {
            show: true,
            style: {
              fontSize: "13px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
    
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#000000',
                  height: 4,
                  offsetX: 0,
                  offsetY: 0
              },
                
                // tickColor:"#ffffff"    
        },
        yaxis: {
    
         
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: "13px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold"
            },
                },
        },
        
      },
      
      
      };
    
  
    const mixedChart = {
        series: [{
            name: 'Gross Advance',
            type: 'column',
            data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
          }, {
            name: 'NPA',
            type: 'line',
            data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
          }],
          options: {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              }
            },
            stroke: {
              width: [0, 4]
            },
            // title: {
            //   text: 'Branch'
            // },
       
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1]
            },
            labels: month1,            
            xaxis: {
              type: 'month'
            },
            yaxis: [{
              title: {
                text: 'Gross Advance',
                style:{
                    fontSize:"15px",
                }
              },
            
            }, {
              opposite: true,
              title: {
                text: 'NPA',
                style:{
                    fontSize:"15px",
                }
              }
            }]
          },
        
        
        };
      


    var gradChart ={
      series: [75],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -145,
            endAngle: 250,
             hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
        
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '26px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['High Risk'],
      },
    }
    



var gradChart2 ={
  series: [80],
  options: {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -160,
        endAngle: 250,
         hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
    
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: '#111',
            fontSize: '26px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#FFA500'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['MediumRisk'],
  },
}


var gradChart4 ={
  series: [90],
  options: {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -160,
        endAngle: 190,
         hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
    
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: '#111',
            fontSize: '26px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#FF0000'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['LowRisk']
  },
}


         
  
        

 
    return (
<Card>
<GridContainer >

<GridItem xs={12} sm={12} md={4} >
<h4 align="left" style={{fontWeight:"bold", marginLeft:"50px",marginTop:"50px",fontSize:"22px"}}>NPA Deep Dive</h4>  

</GridItem>
<GridItem xs={12} sm={12} md={8} ></GridItem>

 
      <GridItem xs={12} sm={12} md={2} style={{paddingTop:"50px"}} >
<Chart  options={gradChart.options} series={gradChart.series} type="radialBar" /> 
</GridItem>


<GridItem xs={12} sm={12} md={2} style={{paddingTop:"50px"}} >
<Chart  options={gradChart2.options} series={gradChart2.series} type="radialBar" /> 
</GridItem>



<GridItem xs={12} sm={12} md={2} style={{paddingTop:"50px"}} >
<Chart  options={gradChart4.options} series={gradChart4.series} type="radialBar" /> 
</GridItem>


<GridItem xs={12} sm={12} md={5}  style={{marginTop:"10px",paddingLeft:"80px"}}> 
<Chart  options={mixedChart.options} series={mixedChart.series} height="300" width="600" /> 
</GridItem>


<GridItem> <h3 style={{fontSize:"20px",fontWeight:"bold",marginLeft:"40px"}}>Deep Dive-Branches</h3>
</GridItem>
<GridContainer>

  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Adimaly [ABIN0008588]</h3>
   <Chart
        
        options={lineChart.options}
        series={lineChart.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Elappara [ABIN0008612]</h3>
   <Chart
        
        options={lineChart2.options}
        series={lineChart2.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Kattappana Adb [ABIN0005560]</h3>
   <Chart
        
        options={lineChart3.options}
        series={lineChart3.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>koottar [ABIN0007621]</h3>
   <Chart
        
        options={lineChart2.options}
        series={lineChart2.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Marayoor [ABIN0008644]</h3>
   <Chart
        
        options={lineChart3.options}
        series={lineChart3.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Munnar [ABIN0008650]</h3>
   <Chart
        
        options={lineChart.options}
        series={lineChart.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Nediyasala [ABIN0006457]</h3>
   <Chart
        
        options={lineChart3.options}
        series={lineChart3.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Pambanar [ABIN0006460]</h3>
   <Chart
        
        options={lineChart.options}
        series={lineChart.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
  <GridItem xs={12} sm={12} md={3} style={{marginLeft:"50px",marginRight:"30px"}}>
    <h3 style={{fontSize:"18px",fontWeight:"bold",marginLeft:"40px"}}>Thodupuzha [SBIN0008674]</h3>
   <Chart
        
        options={lineChart2.options}
        series={lineChart2.series}
       
        type="line"
        width="430"
        height="300"
        // lineThickness= "5"
        // marginLeft="300"
    
    
      />
  </GridItem>
</GridContainer> 

    </GridContainer>

    </Card>  

    );
       

}


BranchDetailsDemo.propTypes = {

    loading: PropTypes.bool.isRequired,
   
    // logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    // user: state.auth.loggedUser,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BranchDetailsDemo);
