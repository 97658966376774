import GridContainer from "components/Grid/GridContainer";
import React, { Component } from "react";
import Chart from "react-apexcharts";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

class HeapMapFundRisk extends React.Component  {
  
  constructor(props) {
    
   const sendDataBack=()=>{

    this.props.parentCallBack("test");
    }
    
    const classes = useStyles(); 
    super(props);
    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
    
   

    // series: [
    //   {
    //     name: "Series 1",
    //     data: [{
    //       x: 'W1',
    //       y: 22
    //     }, {
    //       x: 'W2',
    //       y: 29
    //     }, {
    //       x: 'W3',
    //       y: 13
    //     }, {
    //       x: 'W4',
    //       y: 32
    //     }]
    //   },
    //   {
    //     name: "Series 2",
    //     data: [{
    //       x: 'W1',
    //       y: 43
    //     }, {
    //       x: 'W2',
    //       y: 43
    //     }, {
    //       x: 'W3',
    //       y: 43
    //     }, {
    //       x: 'W4',
    //       y: 43
    //     }]

    function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

//<GridContainer>

  //    <GridItem>
    // <Breadcrumbs aria-label="breadcrumb">
     // <Link color="inherit" href="#" /*onClick={handleClickHome}*/ className={classes.link}>
    //<HomeIcon className={classes.icon} />
      //Home
     // </Link>
      //<Link
        //color="inherit"
        //href="#"
       // onClick={handleClickCompanyList}
        //className={classes.link}
     // >
       // <WhatshotIcon className={classes.icon} />
       //Company List
      //</Link>
     // <Typography color="textPrimary" className={classes.link}>
       // <GrainIcon className={classes.icon} />
       //Create Company
      //</Typography>
    //</Breadcrumbs>
    //<br/>
    //<br/> 
      // </GridItem>
       
  //</GridContainer>

    this.state = {

      
    
      // series: [{
      //     name: 'Jan',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Feb',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Mar',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Apr',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'May',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Jun',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Jul',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Aug',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   },
      //   {
      //     name: 'Sep',
      //     data: generateData(12, {
      //       min: -30,
      //       max: 55
      //     })
      //   }
      // ],


      series: [
        {
          name: "Low Risk",
          data: [{
            x: 'Jan',
            y: 2
          }, {
            x: 'Feb',
            y: 3
          }, {
            x: 'Mar',
            y: 5
          }, {
            x: 'Apr',
            y: 6
          },{
            x: 'May',
            y: 3
          },{
            x: 'Jun',
            y: 5
          },{
            x: 'Jul',
            y: 1
          },
          {
            x: 'Aug',
            y: 4
          },{
            x: 'Sep',
            y: 3
          },
          {
            x: 'Oct',
            y: 3
          },{
            x: 'Nov',
            y: 3
          },{
            x: 'Dec',
            y: 2
          }
        
        
        ]
        },
        {
          name: "Moderate Risk",
          data: [{
            x: 'Jan',
            y: 3
          }, {
            x: 'Feb',
            y: 2
          }, {
            x: 'Mar',
            y: 4
          }, {
            x: 'Apr',
            y: 5
          },{
            x: 'May',
            y: 6
          },{
            x: 'Jun',
            y: 5
          },{
            x: 'Jul',
            y: 5
          },
          {
            x: 'Aug',
            y: 3
          },{
            x: 'Sep',
            y: 6
          },
          {
            x: 'Oct',
            y: 3
          },{
            x: 'Nov',
            y: 5
          },{
            x: 'Dec',
            y: 6
          }
        
        
        ]
        },
        {
          name: "Medium Risk",
          data: [{
            x: 'Jan',
            y: 3
          }, {
            x: 'Feb',
            y: 4
          }, {
            x: 'Mar',
            y: 5
          }, {
            x: 'Apr',
            y: 5
          },{
            x: 'May',
            y: 6
          },{
            x: 'Jun',
            y: 6
          },{
            x: 'Jul',
            y: 3
          },
          {
            x: 'Aug',
            y: 5
          },{
            x: 'Sep',
            y: 6
          },
          {
            x: 'Oct',
            y: 6
          },{
            x: 'Nov',
            y: 4
          },{
            x: 'Dec',
            y: 5
          }
        
        
        ]
        },        {
          name: "Medium High Risk",
          data: [{
            x: 'Jan',
            y: 4
          }, {
            x: 'Feb',
            y: 6
          }, {
            x: 'Mar',
            y: 5
          }, {
            x: 'Apr',
            y: 5
          },{
            x: 'May',
            y: 4
          },{
            x: 'Jun',
            y: 5
          },{
            x: 'Jul',
            y: 4
          },
          {
            x: 'Aug',
            y: 4
          },{
            x: 'Sep',
            y: 6
          },
          {
            x: 'Oct',
            y: 6
          },{
            x: 'Nov',
            y: 7
          },{
            x: 'Dec',
            y: 6
          }
        
        
        ]
        },        {
          name: "High Risk",
          data: [{
            x: 'Jan',
            y: 4
          }, {
            x: 'Feb',
            y: 4
          }, {
            x: 'Mar',
            y: 2
          }, {
            x: 'Apr',
            y: 4
          },{
            x: 'May',
            y: 5
          },{
            x: 'Jun',
            y: 6
          },{
            x: 'Jul',
            y: 5
          },
          {
            x: 'Aug',
            y: 6
          },{
            x: 'Sep',
            y: 7
          },
          {
            x: 'Oct',
            y: 7
          },{
            x: 'Nov',
            y: 6
          },{
            x: 'Dec',
            y: 4
          }
        
        
        ]
        }
 
      ],
      options: {
        chart: {
          height: 350,
          type: 'heatmap',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              sendDataBack(); 
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
          series: [
            {
              name: "Series 1",
              data: [{
                x: 'Jan',
                y: 212
              }, {
                x: 'Feb',
                y: 290
              }, {
                x: 'Mar',
                y: 83
              }, {
                x: 'Apr',
                y: 320
              }]
            },
            {
              name: "Series 2",
              data: [{
                x: 'Jan',
                y: 430
              }, {
                x: 'Feb',
                y: 413
              }, {
                x: 'Mar',
                y: 45
              }, {
                x: 'Apr',
                y: 350
              }]
            }
          ]
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            shadeIntensity: 0.7,
            radius: 0,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 400,
                  to: 500,
                  name: 'Low Risk',
                  color: '#008000'
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderate Risk',
                  color: '#90ee90'
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium Risk',
                  color: '#FF8C00'
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderate High Risk',
                  color: '#ff8c00'
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#FF0000'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 1
        },
        title: {
          text: 'HeatMap '
        },
      },
    
    
    };
  }



  render() {
    return (
      

<div id="chart">
<Chart  options={this.state.options} series={this.state.series} type="heatmap" height={350} />
</div>
    )
}
}
export default HeapMapFundRisk;