import React,{useEffect,useState} from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "dashroutes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as OrdersActions } from 'store/ducks/orders';
import { Creators as AuthActions } from 'store/ducks/auth';

var ps;

const useStyles = makeStyles(styles);

const Dashboard = ({
  user,loggedUserObj, orders, getOrderRequest, logout, loading,rest,loggedInUserRequest,loggedUser
}) => {
  const [dataStatus, setDataStatus] = React.useState(false);
  const [mainRole, setMainRole] = React.useState("");
  const [userName, setUserName] = React.useState(loggedUser.username);
  const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

  const [url, setUrl] = React.useState("");
  useEffect(() => {
   // const user = JSON.parse(localStorage.getItem('@DeliveryApp:user'));

   loggedInUser(user.email);
   
   if(dataStatus && loggedUser){
  
   const userM = JSON.parse(localStorage.getItem('@DeliveryApp:user'));
   //setUserName(loggedUser.username);
    setMainRole(loggedUser.mainRole);

    if(loggedUser.mainRole==='ADMIN'){
      setUrl("/admin/companylist");
    }

    if(loggedUser.mainRole==='ASSET_MANAGER'){
      setUrl("/admin/dashboardmanager");
    }
    if(loggedUser.mainRole==='FUND_MANAGER'){
      setUrl("/admin/dashboard");
    }

    if(loggedUser.mainRole==='COMPANY_ADMIN'){
      setUrl("/admin/companyAdmin");
    }
   
  }
   
  }, [loggedUser,dataStatus]);


  const loggedInUser = async (email) => {
   
    await loggedInUserRequest(email);
    setDataStatus(true);
  
  };
  //const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/trackerLogo.png"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
  
   
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
    {mainRole &&
      <Sidebar
        routes={routes}
        logoText={"Megalytics"}
        logo={logo}
        image={image}
        user={loggedUser}
        userName={userName}
        mainRole={mainRole}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
       // bgColor={bgColor}
        bgColor="white"
        miniActive={miniActive}
        {...rest}
      />
    }
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          //brandText={getActiveRoute(routes)}
          brandText={user.organizationName}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to={url} />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/" to={url} />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
}


// Orders.propTypes = {
//   user: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//   }),
//   orders: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//     }),
//   ).isRequired,
//   loading: PropTypes.bool.isRequired,
//   getOrderRequest: PropTypes.func.isRequired,
//   logout: PropTypes.func.isRequired,
// };

const mapStateToProps = state => ({
  loggedUser: state.auth.loggedUser,
  user: state.auth.loggedUser,
  orders: state.orders.data,
  loading: state.orders.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...OrdersActions,
    ...AuthActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

