import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Assignment from "@material-ui/icons/Assignment";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import ListSelectionCortera from 'components/ListSelection/ListSelection.js';

import ListSelection from 'components/ListSelection/ListSelection.js';
import { megaColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },
 
];




const CorteraIdModalPage = ({tmCorteraBusinessSearchRequest,corteraBusinessList,sendDataBack}) => {

  const [input, setInput] = React.useState('');

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };




  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");



const [legalName,setLegalName] =React.useState("");
const [state,setState] =React.useState("");
const [city,setCity] =React.useState("");
const [address,setAddress] =React.useState("");
const [postCode,setPostCode] =React.useState("");

const [type,setType] =React.useState("");
const [comments,setComments] =React.useState("");
const [userId,setUserId] =React.useState(""); 
const [country,setCountry] =React.useState("");
const [dataStatus,setDataStatus] =React.useState(false);
const [corteraBusinessListRequest,setCorteraBusinessListRequest] =React.useState("");
  const handleChange = (value) => {
    
  }


  const recieveCorteraSelection = (value) => {
  
    sendDataBack(value);
    
  }


  const submitForm = () => {

const corteraBusinessListRequest1={

  legalName:legalName,
  city:city,
  state:state,
  address:address,
  postCode:postCode,
  // exact:false
}
setCorteraBusinessListRequest(corteraBusinessListRequest1);
tmCorteraBusinessSearchRequestR(corteraBusinessListRequest1).then((data)=>{
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);
 });
  };



  React.useEffect(() => {

    console.log("java..........1.........."+dataStatus);  
    console.log("list"+corteraBusinessList);
   
    }, [dataStatus]);



  const tmCorteraBusinessSearchRequestR = async (corteraBusinessListRequest) => {
    // await tmCreditSafeCompanyListRequest(companyListRequest);
     await tmCorteraBusinessSearchRequest(corteraBusinessListRequest);
   
    };

    const handleCancel = e => {
      setLegalName("");
      setAddress("");
      setState("");
      setCity("");
      setPostCode("");
      setDataStatus(null);
   
        setTimeout(() => {
          e.target.value=null;
          }, 1000);
        };



  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

  const [value, setValue] = React.useState(null);


  

  const classes = useStyles(); 

  return (
    <div >
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 style={{color:megaColor}}><b>Select Cortera ID</b></h3>
          <Card>
            
            <CardBody>

           
            <GridContainer>
            
<GridItem xs={12} sm={12} md={12}>
<CustomInput
                   labelText="LegalName:"
                    id="legalName"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setLegalName(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
              </GridContainer>


              <GridContainer>
           
<GridItem xs={12} sm={12} md={12}>
<CustomInput
                  labelText="Address:"
                    id="address"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setAddress(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
              </GridContainer>
              <GridContainer>
            
<GridItem xs={12} sm={12} md={4}>
<CustomInput
                   labelText="State:"
                    id="state"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setState(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
            
            
<GridItem xs={12} sm={12} md={4}>
<CustomInput
                   labelText="City:"
                    id="city"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setCity(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
             
          
<GridItem xs={12} sm={12} md={4}>
<CustomInput
                     labelText="Postal Code:"
                    id="postCode"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setPostCode(event.target.value);
                        
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
              </GridContainer>

              
  
              <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" style={{fontSize:15}} className={classes.firstButton} onClick={submitForm}>
        Search
        </Button>
        <Button   type="reset" color="info" size="sm" style={{fontSize:15}} className={classes.firstButton} onClick={handleCancel}>
        Clear
        </Button>

      </div>

       
</GridItem>
{dataStatus && <ListSelection resultSet={corteraBusinessList} parentCallBack={recieveCorteraSelection}/>} 

{/* <Table
      striped
      tableHead={["#","","Cortera ID","Tenant Name"]}
      tableData={[
        [
          "1",
          <Checkbox
            tabIndex={-1}
            onClick={() => this.handleToggle(1)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked
            }}
          />,
          "","","",""
        ],
        [
          "2",
          <Checkbox
            tabIndex={-1}
            onClick={() => this.handleToggle(2)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked
            }}
          />,
          "","","",""
        ],
        
      ]}
      customCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 5 is for classes.right, 6 is for classes.right
      customClassesForCells={[0, 5, 6]}
      customHeadCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 5 is for classes.right, 6 is for classes.right
      customHeadClassesForCells={[0, 5, 6]}
    /> */}
          
              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
      </form>
    </div>
  );
}

CorteraIdModalPage.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmCorteraBusinessSearchRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  corteraBusinessList: state.tenantmonitoring.corteraBusinessList,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorteraIdModalPage);
