
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green, grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Assignment from "@material-ui/icons/Assignment";
import AddAlert from "@material-ui/icons/AddAlert";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import PirsListTable from 'views/Pirs/PirsListTable.js';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";

  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];










// const Liensreport = ({tmActiveLiensJudgmentDetailsRequest,liensJudgmentDetailsDTO,tmActiveLiensDetailsRequest,liensList}) => {
  const PirsList = ({tmPirsListRequest,pirsList
    }) => {

  const [input, setInput] = React.useState('');
 // const [dataList, setDataList] = React.useState(tenantFailureStatusLogListByOrg);

  
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");
const [messageobj, setMessageobj] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const[failureCount,setFailureCount] = React.useState("");
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
 
const[id,setId] = React.useState("");
const [month,setMonth] = React.useState(location.state.month);
const[type,setType] = React.useState("");
const[status,setStatus] = React.useState("");
const [showViewLog, setShowViewLog] = React.useState(false);
const [dataStatus, setDataStatus] = React.useState(false);

const [year, setYear] = React.useState(location.state.year);




React.useEffect(() => {
   
  if(!dataStatus){
  
getPirsList(month,year);

  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus]);



  const getPirsList = async (month,year)=>{
  
await tmPirsListRequest(month,year)

console.log(JSON.stringify(pirsList));

  }

// 


  

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
    
  };

  // const handleCloseLiensreport = () => {
  //   setShowLiensreport(false);
  // };

  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }

    // const handleClickOpenViewLog = () => {

    //   setShowViewLog(true);
    // };
  
    const handleCloseViewLog = () => {
      setShowViewLog(false);
    };



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
  
    <GridContainer>
   <GridItem xs={12}>
   
     </GridItem> 

  
   <GridItem xs={12}>
  
  
   

     <Card>
       <CardHeader style={{ background: megaColor,color:"white" ,fontSize:"17px"}} icon>
         <CardIcon color="info">
           <Assignment />
         </CardIcon>
         <h4><b>PIRS List : {month} - {year}</b></h4>
         
       </CardHeader>
       <CardBody>
       {messageobj && <SnackbarContent
message={messageobj}
close
icon={AddAlert}
color="info"
/>
}


            <GridContainer className={classes.gridContainer}>
            <GridItem>
               
               <Dialog fullWidth={true} verticalAlign={'middle'} 
maxWidth={'md'} open={showViewLog} onClose={handleCloseViewLog}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      <GridItem align="right" paddingTop="20px">
    
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewLog}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem paddingLeft='30px' >
          {/* {showViewLog && <ViewLog  tenantId ={tenantId} month={month} year={year}/>} */}
          </GridItem>
          </Dialog>
               </GridItem>
      
</GridContainer>


{/* <h2 style={{ color: megaColor,fontSize:'22px', fontWeight:'bold' }}>Company Name:{selectedOrganization} -{month} {year}</h2> */}

         <div style={{ width:"70%"}}>   

         <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <label><h7 style={{color:megaColor}}>DI : <b>Direct Impact</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>OM : <b>Operational Mitigation</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>II : <b>Indirect Impact </b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>OOR : <b>Out look of recovery </b></h7></label>
                  </GridItem>
                  </GridContainer> 

                  <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>RMS  : <b>Recovery MLScore </b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>GB : <b>Government Bailout </b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>ON : <b>Openness</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>PR : <b>Percent Remote</b></h7></label>
                  </GridItem>
                  </GridContainer> 

                  <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>CIJ  : <b>Change In Jobs</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>SC : <b>Supply Chain</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>OPMS : <b>Overall PirsMLScore</b></h7></label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <label><h7  style={{color:megaColor}}>CS : <b>Consumer Confidence</b></h7></label>
                  </GridItem>
                  </GridContainer> 

         </div>    

         <PirsListTable
           columns={[

             {
               Header: "Naics Code",
               accessor: "naicsCode",
           
             },

             {
              Header: "Descrition",
              accessor: "desription",
          
            },

             {
              Header: "DI",
              accessor: "mlDirectScore",
          
            },
                
            {
              Header: "OM",
              accessor: "operationalMitigationMLScore",
          
            },
            {
              Header: "II",
              accessor: "indirectImpactMLScore",
          
            },
            {
              Header: "RS",
              accessor: "recoveryMLScore",
          
            },

            {
              Header: "GB",
              accessor: "bailoutMLScore",
          
            },
            {
              Header: "ON",
              accessor: "openness",
          
            },
            {
              Header: "PR",
              accessor: "percentRemote",
          
            },
            {
              Header: "CIJ",
              accessor: "changeInJobs",
          
            },
            {
              Header: "SC",
              accessor: "supplyChainConstraint",
          
            },
            {
              Header: "OPMS",
              accessor: "overallPIRSMLScore",
          
            },
            {
              Header: "CS",
              accessor: "consumerConfidence",
          
            },

           ]}
           data={pirsList.map((prop, key) => {

            
             return {
              naicsCode: prop.naicsCode,
              mlDirectScore:prop.mlDirectScore,
              operationalMitigationMLScore:prop.operationalMitigationMLScore,
              indirectImpactMLScore:prop.indirectImpactMLScore,
              recoveryMLScore:prop.recoveryMLScore,
              bailoutMLScore:prop.bailoutMLScore,
              openness:prop.openness,
              percentRemote:prop.percentRemote,
              changeInJobs:prop.changeInJobs,
              supplyChainConstraint:prop.supplyChainConstraint,
              overallPIRSMLScore:prop.overallPIRSMLScore,
              consumerConfidence:prop.consumerConfidence,
              desription:prop.desription
          
              
             };
           })}


     
           //  data={tenantUploadStatusList}

           getTdProps={(state, rowInfo, column, instance) => {
             // rowInfo contains a lot of information about the row (including index on both the
             // page and it's index within the data set), the rowInfo.original objet contains 
             // values in the cells of the row clicked.
             return {
               // You can define an onClick function here that will apply to all rows
               onClick: (e, handleOriginal) => {
     
                  const rowData = rowInfo.original
                  // You can interact with the row data here, to use it however you want
                  this.setState({month: rowData.month, year: rowData.year})
               }
         }}}
         
         />
       </CardBody>
       
     </Card>
     
     </GridItem>
     
    
 </GridContainer>
 
);
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

PirsList.propTypes = {
    organizationScoreExecutionLogList: PropTypes.arrayOf(
    PropTypes.shape({
      organisationId: PropTypes.string,
      month:PropTypes.string,
      year:PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmPirsListRequest: PropTypes.func.isRequired,
//   tmActiveTaxLienJudgmentScoreByTenantIdRequest: PropTypes.func.isRequired,
//   tmActiveJudgmentDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  distinctTenantsFromScoreExecutionLog: state.tenantmonitoring.distinctTenantsFromScoreExecutionLog,
     loading: state.tenantmonitoring.loading,
  pirsList: state.tenantmonitoring.pirsList,
//  month:state.tenantmonitoring.month,
//  year:state.tenantmonitoring.year,
//   taxLienJudgmentScore :state.tenantmonitoring.taxLienJudgmentScore,
//   judgmentList:state.tenantmonitoring.judgmentList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PirsList);

