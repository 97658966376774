import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import TenantNotification from "views/TenantNotification/TenantNotification.js";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useUrlSearchParams } from "use-url-search-params";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Instructions from "components/Instruction/Instruction.js";
import { useAppendSearchParam } from "use-url-search-params-hooks";
import PirsCsvUpload from "components/CustomUpload/PirsCsvUpload.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
import OrgSelected from 'components/OrgSelected/OrgSelected.js';
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Moment from 'moment';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import DownloadLink from "react-download-link";
import PirsUploadLogTable from "views/Pirs/PirsUploadLogTable.js"
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tooltip } from "@material-ui/core";
const styles = {

  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',
  },
    ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "right",
  },
  customWidth: {
    maxWidth: 200,
    fontSize: "15px",
    backgroundColor: megaColor,
  },
  rootStyle: {
    borderRadius: 15,
    backgroundColor:"#fff"
  }
  
};

const useStyles = makeStyles(styles);
// var params = new URLSearchParams();
//         params.set("page", pageNumber.toString());
//         params.set("size", size.toString());
//         params.set("sortProperty", sortBy);
//         params.set("sortDirection", sortDirection);
//         params.set("searchTerm", searchTerm);
// const initial = { page: "1", size:"20", };
// const [params, setParams] = useUrlSearchParams({ page: 1,size:10, sortProperty});

  const PirsUploadLog = ({tmUploadPirsLogListRequest,uploadPirsLogList,
    tmTenantUploadStatusByOrgIdRequest,tenantUploadStatusList, tmTenantListRequest, tmTenantListByOrgRequest,tmTenantInitialScoreRequest,tmAllTenantsTrailingScoreRequest,tenantsTrailingScoreList,tmTenantDeleteRequest,tenantDelete
  }) => {
  
    const location = useLocation();
    const [dataList, setDataList] = React.useState(tenantUploadStatusList);
    const [messageobj, setMessageobj] = React.useState("");
    const [tenantId , setTenantId ] = React.useState("");
    const [propertyId, setPropertyId ] = React.useState("");
    // const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //   const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  //  // const[companyName,setCompanyName] = React.useState("Megalytics");

  
  // const[failureCount,setFailureCount] = React.useState(location.state.failureCount);
  const [organisationId, setOrganisationId] = React.useState("");
  const [organisationName, setOrganisationName] = React.useState("");

  
  //  const [organisationName, setOrganisationName] = React.useState("gfdgfdgfd");
   const [uploadedDate, setUploadedDate] = React.useState("");
   const [organizationId, setOrganizationId] = React.useState("");
   const [tenantName, setTenantName] = React.useState("");
   const [sampleCsv, setsampleCsv] = React.useState(require("assets/csv/pirs.csv"));
  // localStorage.setItem( 'selectedOrg', organisationId) 
  // setOrganisationId(localStorage.getItem( 'selectedOrg' ) );
    useEffect(() => {
      // alert("usereffect 1::"+organisationId);
      getUploadPirsLogList();
      if(location.state){
        setOrganisationId(location.state.organisationId);
        setOrganisationName(location.state.organisationName);
        setOrganizationId(location.state.organisationId);
        
        // alert("usereffect st::"+organisationId);
        setMessageobj(location.state.message);
      }
    //  alert("usereffect::"+organisationId);
     
      }, [messageobj]);
  
   
      const getTenantUploadStatusByOrgId = async (organisationId)=>{
        await tmTenantUploadStatusByOrgIdRequest(organisationId);
        
      
      }

      const getUploadPirsLogList = async ()=>{
        await  tmUploadPirsLogListRequest();
        
      
      }

    const getTenantList = async (organisationId) => {
      await tmTenantListByOrgRequest(organisationId);
    //  alert(organisationId);
      };


      const deleteTenant = async (tenantId) => {
        await tmTenantDeleteRequest(tenantId);
        };

  
  //angel

  const submit = (tenantId) => {
   
      confirmAlert({
       
        //title: 'Fund',
        message: 'Failure Count',
        // buttons: [
        //   {
        //     // label: 'Yes',
        //     //onClick: () => alert('Click Yes'),
        //    // onClick: () => deleteTenant(tenantId)
        //   },
        //   // {
        //   //   label: 'No',
        //   //   //onClick: () => alert('Click No')
        //   // }
        // ]
      
      })
      //alert("Confirm");

    };


const getColumnWidth = (data, accessor, headerText) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const minWidth = 200;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...data.map(row => (`${accessor(row)}` || '').length),
    headerText.length,
  );
  return Math.min(minWidth, cellLength * magicSpacing);
};




const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

const [showFailureCountReport, setShowFailureCountReport] = React.useState(false);
const [open, setOpen] = React.useState(false);
  const[showCsvUpload, setShowCsvUpload] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClickOpenShowCsvUpload = () => {
      setShowCsvUpload(true);
    };
    const handleCloseShowCsvUpload = () => {
      setShowCsvUpload(false);
    };
  
    // const handleClickOpenFailureCountReport = (organisationId) => {
    //   setOrganisationId(organisationId);
    //   // alert(organisationId);
    //   setShowFailureCountReport(true);
     
    // };

  const handleClickOpenFailureCountReport = () => {
   
    // alert(organisationId);
    setShowFailureCountReport(true);
   
  };

  

  const handleCloseFailureCountReport = () => {
    setShowFailureCountReport(false);
  };

// uploadTenant = (e) => {
// e.preventDefault();
// this.setState({
//   selectedFile:e.target.files[0]
// });
// tmUploadTenantRequest(this.state.selectedFile,'5f8ee03be74296262abdeb65','5f71f8a1bc82180272f8eed3')
// };
  
  const classes = useStyles();


  return (
    <GridContainer>
       {/* <GridItem xs={12}>
     <OrgSelected orgName={selectedOrganization}/>
     </GridItem> */}
     
      <GridItem xs={12}>
     
     
      

        <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4><b>{organisationName}&nbsp;PIRS</b></h4>
            
          </CardHeader>
          <CardBody>
          {messageobj && <SnackbarContent
  message={messageobj}
  close
  icon={AddAlert}
  color="info"
/>
 }
 <GridItem align="right" style={{fontWeight: "bold", paddingTop: '15px'}}>
<Link to={sampleCsv} target="_blank" download>Download Sample PIRS CSV</Link>
</GridItem>
{/* <DownloadLink
    label="Download Sample CSV"
    filename="sample.txt"
    exportFile={() => sampleCsv}
/> */}
<Button  style={{fontSize:14, fontWeight: "bold"}}
                onClick={handleClickOpenShowCsvUpload}        round color="info" size="sm" type="submit">upload
                      </Button>

                      <GridItem paddingLeft='30px' align="justify">
               
               <Dialog fullWidth={true}  classes={{paper: classes.rootStyle }}
maxWidth={'md'} open={showCsvUpload} onClose={handleCloseShowCsvUpload}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      <GridItem align="right">
    
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseShowCsvUpload}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem paddingLeft='30px' align="justify">
  
           <PirsCsvUpload/>      </GridItem>
          </Dialog>
               </GridItem>
              

               {/* <GridContainer className={classes.gridContainer}> */}
      
         {/* <GridItem style={{textAlign: "right"}} xs={12} sm={12}>
         

         <Button style={{ background: megaColor,color:"white"}} round  onClick={() => history.push('/admin/tenant')}><b>Create New Tenant</b></Button>
       
                    
                      
              
         </GridItem> */}
        
   {/* </GridContainer> */}
                 {/* <CsvUpload organisationId ={organisationId}/> */}

                 <GridItem paddingLeft='30px' align="justify">
               
                 <Dialog fullWidth={true}
  maxWidth={'md'} open={showFailureCountReport} onClose={handleCloseFailureCountReport} fullWidth={true} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <GridItem align="right">
      
          <DialogActions>
          <Button 
            justIcon
            round
            simple
            onClick={handleCloseFailureCountReport}
            color="info"
            className="close" >
            <CloseIcon/>
            </Button>
           
            </DialogActions>
</GridItem>
<GridItem paddingLeft='30px' align="justify">
            {/* {showFailureCountReport && <FailureCountReport  organisationId ={organisationId} />} */}
        </GridItem>
            </Dialog>
                 </GridItem>
        
         
 {/* if(initialDataPopulated===true){
   true;
 } */}
            <PirsUploadLogTable
              columns={[

                {
                  Header: "Date",
                   accessor: "uploadedDate",
                //   uploadedDate:{Moment(uploadedDate).format('MM-DD-YYYY')},
                   width: 50,
                   
               },

                
               {
                Header: "Month",
                 accessor: "month",
                 width: 50,
                 
             },
           
             {
              Header: "Year",
               accessor: "year",
               width: 50,
               
           },
          
            {
              Header: "Time",
               accessor: "uploadedTime",
               width: 50,
               
           },
           {
            Header: "Actions",
            accessor: "actions",
            disableFilters: true,
            disableSortBy: true,
            width: 50,
          },

           
         



              ]}
              data={uploadPirsLogList.map((prop, key) => {

               
                return {
                  id: prop.id,
              
                  uploadedDate: prop.uploadedDate,
                  uploadedTime:prop.uploadPirsLogList,
                  year:prop.year,
                  month:prop.month,
                 uploadedDate:(
                   <div>
                     {/* {Moment(uploadedDate.uploadedDate).format("MM-DD-YYYY  hh:mm:ss")} */}
                     {Moment(uploadedDate.uploadedDate).format("Do MMM YYYY")}
                   </div>

                 ),

                 uploadedTime:(
                  <div>
                    {/* {Moment(uploadedDate.uploadedDate).format("MM-DD-YYYY  hh:mm:ss")} */}
                    {Moment(uploadedDate.uploadedDate).format("LTS")}
                  </div>

                ),

                actions: ( <div> 
                   <Link to={{pathname:'/admin/view-pirs-list', state :{ month:prop.month, year:prop.year} }}> 
                   <Button
                       justIcon
                       round
                       simple
                      
                      // size="md"
                       //   onClick={() => 
                       //   alert("You've pressed the edit button on colmun id: " + prop.id)
                       // }
                       color="info"
                       className="score"
                     >
                        <Tooltip classes={{ tooltip: classes.customWidth }} title="View PIRS">
                       <VisibilityIcon/>
                       </Tooltip>
                       </Button>{" "}
                   </Link>

                </div>)
                
                };
              })}


        
              //  data={tenantUploadStatusList}

              getTdProps={(state, rowInfo, column, instance) => {
                // rowInfo contains a lot of information about the row (including index on both the
                // page and it's index within the data set), the rowInfo.original objet contains 
                // values in the cells of the row clicked.
                return {
                  // You can define an onClick function here that will apply to all rows
                  onClick: (e, handleOriginal) => {
        
                     const rowData = rowInfo.original
                     // You can interact with the row data here, to use it however you want
                     this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
                  }
            }}}
            
            />
          </CardBody>
          
        </Card>
        
        </GridItem>
        
       
    </GridContainer>
    
  );
}


PirsUploadLog.propTypes = {
    tenantUploadStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmTenantListRequest: PropTypes.func.isRequired,
  tmTenantListByOrgRequest: PropTypes.func.isRequired,
  tmTenantDeleteRequest: PropTypes.func.isRequired,
  tmTenantInitialScoreRequest: PropTypes.func.isRequired,
  tmTenantUploadStatusByOrgIdRequest: PropTypes.func.isRequired,
  tmUploadPirsLogListRequest:PropTypes.func.isRequired,
 
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantUploadStatusList: state.tenantmonitoring.tenantUploadStatusList,
  tenantDelete: state.tenantmonitoring.tenantDelete,
  uploadPirsLogList:state.tenantmonitoring.uploadPirsLogList,
  loading: state.tenantmonitoring.loading,

  //tenantId: state.tenantId
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PirsUploadLog);



