import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// core components
import Button from "components/CustomButtons/Button.js";

import React, { Component } from "react";
import Chart from "react-apexcharts";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
// import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PublishIcon from '@material-ui/icons/Publish';
import { useLocation } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { isConstructorDeclaration } from "typescript";
import axios ,{get} from 'axios';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import AddIcon from "@material-ui/icons/Add";
import { Fab} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form as FinalForm, Field } from "react-final-form";
import CircularProgress from '@material-ui/core/CircularProgress';
//  import { FileUpload } from '@welcome-ui/file-upload'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

const useStyles = makeStyles({
  root: {
    width: '31%',
  },
});

const CsvUpload =({uploadPirsCsvStatus,tmUploadPirsCsvRequest})=>{
  const location = useLocation();

  
  const classes = useStyles(); 
        const[userId,setUserId]=React.useState("");
        const [state,setState]=React.useState("");
        const [alert,setAlert] = React.useState(false);
        const [progress, setProgress] = React.useState(100);
        const [startDate, setStartDate] = React.useState(new Date());
        const [month, setMonth] = React.useState("");
        const [year, setYear] = React.useState("");
// const[status,setStatus] = React.useState(uploadPirsCsvStatus);

       
   
        React.useEffect(() => {
          setProgress(0);
        
      
       
        }, []);


    
      const handleCancel = e => {
      setState("");
          e.target.value=null
       setProgress(0); 
        };

        const setDate=(value)=>{
            console.log(value);
           const date=Moment(value).format('MM-DD-YYYY');
            const year=Moment(value).format('YYYY');
            const month=Moment(value).format('M');
          setMonth(month);
          setYear(year);
          setStartDate(value);
            
        }


const hideAlert = () => {
  setAlert(false);
}
    const onFormSubmit=(e)=>{

      if(state!==""){
        fileUpload(state.file);
      setProgress(100);
        e.preventDefault();
    }
    else if(state===""){
      setProgress(0);
      e.preventDefault();
    }
     
      }

      const onChange=(e)=> {
        setState({file:e.target.files[0]})
        
      }
     const fileUpload=(file)=>{
   
      uploadPirsCsvFile(file,year,month)
      
       
  
      } 

      const uploadPirsCsvFile = async (file,year,month) => {
       

        await tmUploadPirsCsvRequest(file,year,month)
       setProgress(100);
        
        // setAlert(true);
        // console.log("organizationId"+JSON.stringify(organizationId));
      };
     

    return (
      <div align="center" >
       
      <form onSubmit={onFormSubmit} >
        <div>
      <font style={{fontSize: "27px", fontWeight:"bold"}}>UPLOAD PIRS FILE</font>
      </div>
      <br></br>
      <br></br>
      {/* <CustomFileInput onChange={onChange}/> */}
        {/* <input type="file" onChange={onChange} /> */}
        {/* style={{
    padding: '10px',
    background: 'red',
    display: 'table',
    color: '#fff'
     }} */}

<div>

  <label style={{color: "black"}}> <b>Select Year And Month</b></label>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; 
<DatePicker
      selected={startDate}
      onChange={(date) => setDate(date)}
      dateFormat="MM/yyyy"
      showMonthYearPicker
     
    />
<br></br>
      <br></br>


      <label for="actual-btn" style={{align: "center", backgroundColor: "red",
  color: "white",
  padding: "0.5rem",
  fontSize:17, fontWeight: "bold" ,borderRadius: 10
 }}>Choose File</label>  
         <input
          //  style={{ display: "block" }}
          id="actual-btn" 
          style={{width:'200px',height: '60px'
          }}
        hidden
          type="file"
          onChange={onChange}
         
        />

     
        
      {/* <span  id="file-chosen" >No file chosen</span> */}
      
        {/* <label class="file"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <input
          //  style={{ display: "block" }}
          
          style={{width:'180px',height: '30px', 
          color: 'red'}}
          id="actual-btn"
       
          // name="choose-file"
          type="file"
          onChange={onChange}
         
        />
       </label> */}
       &nbsp;&nbsp;&nbsp;

       <Button     id="send" color="info" size="sm" type="submit" 
        style={{fontSize:14, fontWeight: "bold", borderRadius: 2}}>
          Upload 
        </Button>

    
        </div>
     <br></br>

         
        
        <Button
                type="reset"
                  size="sm"
                  color="info"
                  style={{fontSize:14, fontWeight: "bold"}}
                  // className={`ts ${submitting ? "loading" : ""} button`}
                  // disabled={submitting}
                   onClick={handleCancel}
                >
                  Reset
                </Button>
              
              
                <div>
                <CircularProgress variant="determinate" value={progress} />
                </div>
                <div align="center">
                   
                   <b>{uploadPirsCsvStatus}</b>
                  
         
         </div>
        <br />
        <br />
        
                      {/* <Button
                        round color="info" size="sm" type="submit">upload
                      </Button> */}
                 
      </form>
     
       
       <div>
       <SweetAlert
info
show={alert}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
{/* <b>The data is being uploaded.Please verify after some time.If still problem exist please contact system administrator </b> */}
<b>{uploadPirsCsvStatus}</b>
</SweetAlert>
         </div>
       </div>

       
     
   )
  
}

CsvUpload.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmUploadPirsCsvRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  uploadPirsCsvStatus:state.tenantmonitoring.uploadPirsCsvStatus
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CsvUpload);
