import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Timeline from "components/Timeline/Timeline.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
//import { Link } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CTTable from "components/CTTable/CTTable.js";
import moment from 'moment';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Instructions from "components/Instruction/Instruction.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// core components
import styles1 from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check"
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';


import ContifyCategories from "components/ConntifyCategories/ContifyCategories.js";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Paginations from "components/Pagination/Pagination.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Heading from "components/Heading/Heading.js";
import { useAppendSearchParam } from 'use-url-search-params-hooks';
import ReactSelectMaterialUi from "react-select-material-ui";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

import { useLocation } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor,
  infoColor,
} from "assets/jss/material-dashboard-pro-react.js";
import { textAlign } from "@material-ui/system";

import { createTrue } from "typescript";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import { Multiselect } from "multiselect-react-dropdown";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: megaColor,
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  gridBorder:{
    border: 1, 
    borderColor: '#D3D3D3',
    borderStyle: 'solid'
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  vertical: { 
              padding:"10px"
             
            } ,
    customWidth: {
      maxWidth: 200,
      fontSize: "15px",
      backgroundColor: megaColor,
    },
h: {
      marginLeft:"0px",
    },
};
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const News = ({ loading,tmNotificationList,tmMonitoredTenantsNewsRequest,tenantId,tmGetTenantPropertyDetailsRequest,tenantPropertyDetails,tmGetContifyTopicsRequest,contifyTopics,tmGetCompanyInsightSearchListUsingTopicRequest,contifyListUsingTopic}) =>{
// export default function Widgets() {
  const location = useLocation();
   
  const[tenantName,setTenantName] =React.useState(location.state.tenantName);

  // const location = useLocation();
  // const[tenantName,setTenantName] =React.useState(location.state.tenantName);


//   const [tenantId, setTenantId] = React.useState(location.state.tenantId);
  const [dataStatus, setDataStatus] = React.useState(false);
  const [salesTriggers, setSalesTriggers] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [modalValue, setModalValue] = React.useState(""); 
  const [sourceUrl, setSourceUrl] = React.useState(""); 
  const [url, setUrl] = React.useState(""); 
  const [title, setTitle] = React.useState(""); 
  const [summary,setSummary]=React.useState("");
  const [selectedPeriod, setSelectedPeriod] = React.useState("");
  const [showNoDataFound, setShowNoDataFound] = React.useState(true);
  const [showDataFound, setShowDataFound] = React.useState(true);
  const [showLast,setShowLast] = React.useState(true);
  const [showContifyList,setShowContifyList] = React.useState(false);
  const [showList,setShowList] = React.useState(true);

  const [checked, setChecked] = React.useState([]);
 
  const [alert,setAlert] = React.useState(false);

  const history = useHistory();
  const [tenantAddress, setTenantAddress] = React.useState("");
  const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const[propertyName,setPropertyName]=React.useState("");
    const [year,setYear] = React.useState(moment().format('YYYY').toLowerCase());

    const[selectedFundId,setSelectedFundId]=React.useState("");
 const[selectedFundName,setSelectedFundName]=React.useState("");
 const[propertyId,setPropertyId]=React.useState();
 const[imageUrl,setImageUrl]=React.useState("");
 const[currentStatus,setCurrentStatus]=React.useState("");
 const[colorCode,setColorCode]=React.useState("");
 const[propertyAddress,setPropertyAddress]=React.useState("");
 const[score,setScore]=React.useState("");
 const[userId,setUserId]=React.useState("");
    const [month1, setMonth1] = React.useState(moment().subtract(1, "month").format('MMM').toLowerCase());
const[topics,setTopics] = React.useState([]);

const[topicId,setTopicId]=React.useState("");
const[topicName,setTopicName]=React.useState("");
const[selectedValues,setSelectedValues]=React.useState([]);
const[selectedValues1,setSelectedValues1]=React.useState([]);

const[selectedTopic,setSelectedTopic]=React.useState("");
const [input, setInput] = React.useState('');

const[topicIds,setTopicIds]=React.useState([]);
const[topicNames,setTopicNames]=React.useState([]);
  const handleChange = (event) => {
   

    const todayNow = moment().format("D MMM YYYY"); 
    const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
    const month = moment().subtract(1, 'months').format("D MMM YYYY");
    const quarter = moment().subtract(3, 'months').format("D MMM YYYY");
    const sixMonths = moment().subtract(6, 'months').format("D MMM YYYY");
    const year = moment().subtract(1, 'years').format("D MMM YYYY");


      
   
  
     switch (event.target.value) {
       case "7 days":
         setSelectedPeriod(sevenDays +"    -    "+ todayNow)
         getTmList(tenantId,salesTriggers,sevenDays,todayNow);
        
    
         return ;
         break;
       
       case "Month":
        setSelectedPeriod(month +"    -     "+ todayNow)
        getTmList(tenantId,salesTriggers,month,todayNow);
        return;
        //  return <button>Login</button>;
         break;
         case "Quarter":
         setSelectedPeriod(quarter +"    -    "+ todayNow)
         getTmList(tenantId,salesTriggers,quarter,todayNow);
         return ;
         break;
       case "Six Months":
        setSelectedPeriod(sixMonths +"    -    "+ todayNow)
        getTmList(tenantId,salesTriggers,sixMonths,todayNow);
         return ;
         break;  
       case "Year":
        setSelectedPeriod(year +"    -    "+ todayNow)
        getTmList(tenantId,salesTriggers,year,todayNow);
         return ;
         break;  
         
       default:
         return null;
     }
    
 
   
   }
 
  //  const BootstrapInput = withStyles((theme) => ({
  //   root: {
  //     'label + &': {
  //       marginTop: theme.spacing(5),
  //     },
  //   },
  //   input: {
  //     borderRadius: 8,
  //     position: 'relative',
  //     backgroundColor: theme.palette.background.paper,
  //     border: '1px solid #ced4da',
  //     borderHeight: 3,
  //     fontSize: 16,
  //     padding: '10px 26px 10px 70px',
  //     transition: theme.transitions.create(['border-color', 'box-shadow']),
  //     // Use the system font instead of the default Roboto font.
  
  //     fontFamily: [
  //       '-apple-system',
  //       'BlinkMacSystemFont',
  //       '"Segoe UI"',
  //       'Roboto',
  //       '"Helvetica Neue"',
  //       'Arial',
  //       'sans-serif',
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(','),
  //     '&:focus': {
  //       borderRadius: 8,
  //       borderColor: '#80bdff',
  //       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  //     },
  //   },
  // }))(InputBase);
  
  // const useStyles = makeStyles((theme) => ({
  //   margin: {
  //     margin: theme.spacing(1),
  //   },
  // }));

  
   
 

  useEffect(() => {
    setShowContifyList(false);
      if(tmNotificationList.length>0){
     // setShowCompanyName(false);
  // setTopics(tmNotificationList.topics);

   setShowNoDataFound(false);
     setShowDataFound(true);
     setShowLast(true);
     setShowList(true);
      
    }
  else{
    setShowNoDataFound(true);
     setShowDataFound(false); 
     setShowLast(true);
     setShowList(false);
     setTopics(tmNotificationList.category);
     console.log("category"+tmNotificationList.category);
    // setAlert(true);
  
  }
  if(!dataStatus){
    getTenantPropertyDetails(tenantId,month1,year);
    getContifyTopics();
  const todayNow = moment().format("D MMM YYYY"); 
    
  const month = moment().subtract(1, 'months').format("D MMM YYYY");

  const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
  getTmList(tenantId,salesTriggers,month,todayNow);   

  
    //  }
 
   
    setSelectedPeriod(month +"    -     "+ todayNow)
    
   // setSelectedPeriod(sevenDays +"    -    "+ todayNow)
  }
    setTimeout(() => {
      setDataStatus(true);
    
    }, 2000);
 
  }, [dataStatus,tmNotificationList]);
  

  const getTmList = async (tenantId,salesTriggers,todayNow,month) => {

    
    await tmMonitoredTenantsNewsRequest(tenantId,salesTriggers,todayNow,month);
    if(tmNotificationList.length>0){
      setShowNoDataFound(false);
      setShowDataFound(true); 
      setShowLast(true);
      setShowList(true);
      setShowContifyList(false);
     }
     else{
     
      setShowNoDataFound(false);
      setShowDataFound(true); 
      
      // setTopics(tmNotificationList.category);
      // console.log("category"+tmNotificationList.category);
     }
    };

    const getTenantPropertyDetails=async (tenantId,month,year) => {
      await tmGetTenantPropertyDetailsRequest(tenantId,month,year);
      setTenantAddress(tenantPropertyDetails.tenantAddress);
      setCity(tenantPropertyDetails.tenantCity);
      setState(tenantPropertyDetails.tenantState);
      setZip(tenantPropertyDetails.tenantZip);
      setPropertyName(tenantPropertyDetails.propertyName);
      setImageUrl(tenantPropertyDetails.imageUrl);
      setPropertyAddress(tenantPropertyDetails.propertyAddress);
      setCurrentStatus(tenantPropertyDetails.currentStatus);
      setScore(tenantPropertyDetails.propertyScore);
      setUserId(tenantPropertyDetails.userId);
      setPropertyId(tenantPropertyDetails.propertyId);
      setSelectedFundId(tenantPropertyDetails.fundId);
      setSelectedFundName(tenantPropertyDetails.fundName);
      setColorCode(tenantPropertyDetails.colorCode);
      // console.log("tenantPropertyDetails"+JSON.stringify(tenantPropertyDetails));
      // setYearsInBusiness(commentaryFromCompanyReport.commentaryText);
    }

    const getContifyTopics=async () => {
      await tmGetContifyTopicsRequest();
      setTopicId(contifyTopics.topicId);
      setTopicName(contifyTopics.name);
      console.log("topicId"+topicId);
      console.log("topicName"+topicName);
         }
    
         const getContifyListUsingTopics=async (tenantId,selectedValues,todayNow,month) => {
          await tmGetCompanyInsightSearchListUsingTopicRequest(tenantId,selectedValues,todayNow,month);
        console.log("contifyListUsingTopic"+JSON.stringify(contifyListUsingTopic));

        }
    const hideAlert = () => {
      setAlert(false);
      history.push({ 
        pathname: '/admin/monitored-tenants-by-fund',
        // state: {requestFrom:"fund"}
       });
    
    }
    const showModal = (value,url,title,summary) => {
  
        setModal(true);
        setModalValue(value);
        setUrl(url)
        setTitle(title);
        setSummary(summary);
      }; 

     
      const todayNow = moment().format("D MMM YYYY"); 
    
  const month = moment().subtract(1, 'months').format("D MMM YYYY");
    
    
    //   const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     page,
    //     prepareRow,
    //     state,
    //     visibleColumns,
    //     nextPage,
    //     pageOptions,
    //     pageCount,
    //     previousPage,
    //     canPreviousPage,
    //     canNextPage,
    //     setPageSize,
    //     gotoPage
    //   } = useTable(
    //     {
    //       columns,
    //       data,
    //       defaultColumn, // Be sure to pass the defaultColumn option
    //       filterTypes,
    //       initialState: { pageSize: 10, pageIndex: 0, hiddenColumns: ["tenantId","tenantContifyId","naics"] }
          
    //     },
    //     useFilters, // useFilters!
    //     useSortBy,
    //     usePagination
    //   );
    // const SourceUrl = () => {
    //   return (
    //     <div>
    //       <h2>Source</h2>
    //     </div>
    //   );
    // };

    const enableTenant = () => {
      
      history.push({ 
        pathname: '/admin/monitored-tenant-risk',
        state:{tenantId:tenantId,userId:userId,tenantName:tenantName,city:city,state:state,zip:zip,tenantAddress:tenantAddress}
       });
      // <Redirect to="/property-details"/>
    
    }
    const enableProperty = () => {
  
      history.push({ 
        pathname: '/admin/monitored-tenants-property-details',
        state:{fundId:selectedFundId,fundName:selectedFundName,propertyName:propertyName,propertyId:propertyId,propertyAddress:propertyAddress,score:score,currentStatus:currentStatus,colorCode:colorCode,imageUrl:imageUrl,userId:userId}
       });
      // <Redirect to="/property-details"/>
    
    }

    const handleOnchange = () => {
      console.log("inside handle chnage");
      // setTopicId(value.topicId);
      // setTopicName(value.name);
    alert("topicId"+topicId);
      alert("topicName"+topicName);
      getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
    };
    const allTopicsProps = {
  
      options: contifyTopics,
      getOptionLabel: (option) => option.name,
    };

    const selectedTopics = (value) => {
    // console.log("value"+JSON.stringify(value));
  
    { value.map(liens => {
    //  setSelectedValues([]);
      if (selectedValues.indexOf(liens) === -1) {
       if(!selectedValues.includes(liens.name)){
      selectedValues.push(liens.name);
       }
      console.log("selectedValues"+JSON.stringify(selectedValues))
      const todayNow = moment().format("D MMM YYYY"); 
      const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
      const month = moment().subtract(1, 'months').format("D MMM YYYY");
      const quarter = moment().subtract(3, 'months').format("D MMM YYYY");
      const sixMonths = moment().subtract(6, 'months').format("D MMM YYYY");
      const year = moment().subtract(1, 'years').format("D MMM YYYY");
  
      console.log("selectedPeriod"+selectedPeriod);
  
        if(selectedPeriod==sevenDays +"    -    "+ todayNow){

          getContifyListUsingTopics(tenantId,selectedValues,todayNow,sevenDays);
          setShowContifyList(true);
          setShowList(false);
          
        }else if(selectedPeriod==month +"    -    "+ todayNow){

          getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
          setShowContifyList(true);
          setShowList(false);

        }else if(selectedPeriod==quarter +"    -    "+ todayNow){

          getContifyListUsingTopics(tenantId,selectedValues,todayNow,quarter);
          setShowContifyList(true);
          setShowList(false);

        }else if(selectedPeriod==sixMonths +"    -    "+ todayNow){
    
          getContifyListUsingTopics(tenantId,selectedValues,todayNow,sixMonths);
          setShowContifyList(true);
          setShowList(false);

        }else if(selectedPeriod==year +"    -    "+ todayNow){

          getContifyListUsingTopics(tenantId,selectedValues,todayNow,year);
          setShowContifyList(true);
          setShowList(false);

        }else{
      
      getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
      setShowContifyList(true);
      setShowList(false);
        }
     
    
    
      
      // getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
      // setShowContifyList(true);
      // setShowList(false);
      }

    })}
 
  
    };
    const handleRemove = (e, index) => {
      console.log("index"+JSON.stringify(index))
      let itemIndex=selectedValues.indexOf(index.name)

          selectedValues.splice(itemIndex, 1);
          
          const todayNow = moment().format("D MMM YYYY"); 
          const sevenDays = moment().subtract(7, 'days').format("D MMM YYYY"); 
          const month = moment().subtract(1, 'months').format("D MMM YYYY");
          const quarter = moment().subtract(3, 'months').format("D MMM YYYY");
          const sixMonths = moment().subtract(6, 'months').format("D MMM YYYY");
          const year = moment().subtract(1, 'years').format("D MMM YYYY");
     
          if(selectedPeriod==sevenDays +"    -    "+ todayNow){

            getContifyListUsingTopics(tenantId,selectedValues,todayNow,sevenDays);
            setShowContifyList(true);
            setShowList(false);
            
          }else if(selectedPeriod==month +"    -    "+ todayNow){
  
            getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
            setShowContifyList(true);
            setShowList(false);
  
          }else if(selectedPeriod==quarter +"    -    "+ todayNow){
  
            getContifyListUsingTopics(tenantId,selectedValues,todayNow,quarter);
            setShowContifyList(true);
            setShowList(false);
  
          }else if(selectedPeriod==sixMonths +"    -    "+ todayNow){
      
            getContifyListUsingTopics(tenantId,selectedValues,todayNow,sixMonths);
            setShowContifyList(true);
            setShowList(false);
  
          }else if(selectedPeriod==year +"    -    "+ todayNow){
  
            getContifyListUsingTopics(tenantId,selectedValues,todayNow,year);
            setShowContifyList(true);
            setShowList(false);
  
          }else{
        
        getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
        setShowContifyList(true);
        setShowList(false);
          }
       
      
          // getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
          // setShowContifyList(true);
          // setShowList(false);
          if(selectedValues.length==0){
            // getTmList(tenantId,salesTriggers,month,todayNow);   
            setShowList(true);
             setShowContifyList(false);
          }
    };

    // const handleRemove = (value) => {
    //   console.log("inside remove");
    //   console.log("selectedValues"+JSON.stringify(selectedValues))
    //   e.preventDefault();
    //   { selectedValues.map(liens => {
    //     if (selectedValues.indexOf(liens) === -1) {
    //       selectedValues.splice(liens.name, 1);
    
    //       console.log("selectedValues"+JSON.stringify(selectedValues))
    
    //       // getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
    //       // setShowContifyList(true);
    //       // setShowList(false);
    //       }
    
    //     })}
   
    // };
    // const handleRemove = (value) => {
    //   console.log("inside remove");
    //   console.log("selectedValues"+selectedValues);
    //     if (selectedValues.indexOf(selectedValues) === -1) {
    //       console.log("selectedValues"+JSON.stringify(selectedValues));
    //       selectedValues.splice(selectedValues, 1);
    //     }
    //     console.log("selectedValues"+JSON.stringify(selectedValues));
    //     getContifyListUsingTopics(tenantId,selectedValues,todayNow,month);
      
    // };

    
    // const allCompanyProps = {
  
    //   options: companyList,
    //   getOptionLabel: (option) => option.name,
    // };
 
    //  const selectedTopics = (value) => {
   
    //   // localStorage.setItem('@DeliveryApp:selectedOrgId', JSON.stringify(value.id));
    //   localStorage.setItem('@DeliveryApp:selectedTopicName', JSON.stringify(value.name));
    //   if(value!=null){
    //   setSelectedTopic(value.name);
    //   }
     
    //  };
  const classes = useStyles();

  if(dataStatus){

    return (
   
      <div>
<GridItem xs={12} sm={12} md={12}>
<Tooltip classes={{ tooltip: classes.customWidth }} title=" Click here to go to Dashboard" >
<h3 style={{ color: megaColor, fontWeight:'bold',fontSize:"22px",cursor:'pointer'}} onClick={enableTenant}>Tenant Name: {tenantName}</h3>
</Tooltip>
  <h4 style={{ color: megaColor, fontWeight:'bold',fontSize:"18px" }}>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</h4>
  <h4 style={{color: megaColor, fontWeight:'bold',fontSize:"18px",cursor:'pointer'}} onClick={enableProperty}><b>Property Name:&nbsp;{propertyName}</b></h4>
</GridItem>

 
      <GridContainer className={classes.gridContainer}>
       
        {/* <GridItem xs={12} sm={12} md={12}>
<h4 style={{ color: megaColor, fontWeight:'bold',paddingLeft:'30px' }}>Tenant : {tenantName}</h4>
</GridItem> */}

 
      {showLast&& <GridItem xs={12} sm={2}  style={{paddingLeft:"30px",textAlign:"left"}} >
    
    <FormControl className={classes.formControl}>
    <InputLabel 

      htmlFor="simple-select"
      className={classes.selectLabel}
    >
      Show Last&nbsp;&nbsp;&nbsp;&nbsp;
    </InputLabel>
    <Select
      MenuProps={{
        className: classes.selectMenu
      }}
      classes={{
        select: classes.select
      }}
      defaultValue={'Month'}
      onChange={handleChange}
      inputProps={{
        name: "simpleSelect",
        id: "simple-select"
      }}
    >
      <MenuItem
        disabled
       
        value="Show Last"
      >
        Show Last&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
       
        value="7 days"
      >
        7 days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
        
        value="Month"
      >
        Month&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
       
        value="Quarter"
      >
        Quarter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
       
        value="Six Months"
      >
    Six Months&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
      <MenuItem
       
        value="Year"
      >
  Year&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </MenuItem>
    </Select>
  </FormControl>
</GridItem>}
    

    
{showLast&& <GridItem xs={12} sm={4} >

   
<h5 style={{paddingTop:"10px"}}>  <b><font color="#659EC7">{selectedPeriod}</font></b> 
</h5> 
</GridItem>}

<GridItem xs={12} sm={2}></GridItem>
<GridItem xs={12} sm={4} align="right" >     

<Multiselect
              // value={contifyTopics}
              options={contifyTopics}
              placeholder="Category"
              displayValue="name"
              showCheckbox={true}
              closeOnSelect={false}
              // onClick={handleOnchange}
              onSelect={selectedTopics}
              onRemove={handleRemove}
                          />

</GridItem>
      

 <GridItem xs={12} sm={12} >
<div>
{/* <ContifyCategories/> */}

</div>


</GridItem>

 </GridContainer>
    
     <br/>
    <br/>

 

{showDataFound&&showList&&<CTTable



              columns={[

                

              
                {
                  Header: "Date",
                  accessor: data => {
                    return Moment(data.pubDate)
                    .locale('en')
                      .format(" MMM D, YYYY")
                  },
                
              },
                {
                  Header: "Category",
                  accessor: "category"
                },
           
                {
                  Header: "Content Type",
                 accessor:"contentType"
                },
                
               
                {
                  Header: "Title",
                  accessor: "title",
                // Cell:({ cell,row })  =>(

                //   <a href={row.original.title} target = "_blank"> {cell.value} </a>

                // ),
                  Cell: ({ cell,row }) => (

                    // <h6>  <a href = {sourceUrl} target = "_blank">Read More</a></h6>
                    <Link
                    align="justify"
        component="button"
        variant="body2"
        onClick={() => showModal(row.original.title.charAt(0).toUpperCase()+row.original.title.slice(1),row.original.url)}
      >
      {cell.value.charAt(0).toUpperCase()+cell.value.slice(1)}
      </Link>
                   ),
                   
                   }
              ]}
              data={tmNotificationList}
       
            />}

{showDataFound&&showContifyList &&<CTTable



columns={[

  


  {
    Header: "Date",
    accessor: data => {
      return Moment(data.pubDate)
      .locale('en')
        .format(" MMM D, YYYY")
    },
  
},
{
  Header: "Category",
  accessor: "category"
},

{
  Header: "Content Type",
 accessor:"contentType"
},
  {
    Header: "Title",
    accessor: "title",
  // Cell:({ cell,row })  =>(

  //   <a href={row.original.title} target = "_blank"> {cell.value} </a>

  // ),
    Cell: ({ cell,row }) => (

      // <h6>  <a href = {sourceUrl} target = "_blank">Read More</a></h6>
      <Link
      align="justify"
component="button"
variant="body2"
onClick={() => showModal(row.original.title.charAt(0).toUpperCase()+row.original.title.slice(1),row.original.url)}
>
{cell.value.charAt(0).toUpperCase()+cell.value.slice(1)}
</Link>
     ),
     
     }
]}
data={contifyListUsingTopic}

/>}


          


 <div>
         
         <Dialog fullWidth={true}
            maxWidth={'sm'}
           classes={{
             root: classes.center,
             paper: classes.modal
           }}
           open={modal}
           transition={Transition}
           keepMounted
           onClose={() => setModal(false)}
           aria-labelledby="modal-slide-title"
           aria-describedby="modal-slide-description"
         >
           <DialogTitle
             id="classic-modal-slide-title"
             disableTypography
             className={classes.modalHeader}
           >
             <GridItem align="right">
             <Button
               justIcon
               className={classes.modalCloseButton}
               key="close"
               aria-label="Close"
               color="transparent"
               onClick={() => setModal(false)}
             >
               <Close className={classes.modalClose} />
             </Button>
             </GridItem>
            
           </DialogTitle>
           <DialogContent
             id="modal-slide-description"
             className={classes.modalBody}
           
           >
               <h4 className={classes.modalTitle}><b>Title</b></h4>
              <h5>{modalValue}</h5>
             <h6>  <a href = {url} target = "_blank">ReadMore</a></h6>
           </DialogContent>
           <DialogActions
             className={classes.modalFooter + " " + classes.modalFooterCenter}
           >
             <Button color="info" onClick={() => setModal(false)}>Close</Button>
             
           </DialogActions>
         </Dialog>
       </div>


  {showNoDataFound&&<GridItem align="center" >
              <h3><b>No News Available...</b></h3>
            </GridItem>
  }
  <br></br>
  <br></br>

       {/* <div>
           
 
           <SweetAlert
          info
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
          confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px",backgroundColor: infoColor[1]}}
          onConfirm={() => hideAlert()}
          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
         <b style={{fontSize:"20px"}}>No News Available...</b> 
        </SweetAlert>
      
                     </div>
       {/* {showNoDataFound&& <NoDataFound/>}   */}

          </div> 

       

    );
  }else{
    return(
  <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
  }
  }

News.propTypes={
  loading: PropTypes.bool.isRequired,
  tmMonitoredTenantsNewsRequest: PropTypes.func.isRequired,
  tmGetTenantPropertyDetailsRequest: PropTypes.func.isRequired,
  tmGetContifyTopicsRequest: PropTypes.func.isRequired,
  tmGetCompanyInsightSearchListUsingTopicRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmNotificationList :state.tenantmonitoring.tmNotificationList,
  tenantPropertyDetails: state.tenantmonitoring.tenantPropertyDetails,
  contifyTopics: state.tenantmonitoring.contifyTopics,
  contifyListUsingTopic: state.tenantmonitoring.contifyListUsingTopic,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(News);
