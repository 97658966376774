import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListTable from "components/ListSelection/ListTable.js";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// core components

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import SelectAllIcon from '@material-ui/icons/SelectAll';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import { useHistory } from "react-router-dom";
import CompanyListTable from './CompanyListTable';
import GridItem from "components/Grid/GridItem.js";
import {
  whiteColor,
  hexToRgb,
  lyticsColor,  
  infoColor,
  megaColor
} from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "components/Grid/GridContainer.js";

import { Link } from 'react-router-dom';

const useStyles = makeStyles(styles);
// export default function CheckboxListSecondary() {

const CompanyListSelection = ({resultSet,parentCallBack,searchedCompany}) => {
  const history = useHistory();


  const classes = useStyles();
  const [checked, setChecked] = React.useState([1]);
  const [dataStauts, setDataStauts] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const[tenantName,setTenantName] = React.useState("");
  const[id,setId] = React.useState("");
  const[address1,setAddress1] = React.useState("");
  const[address2,setAddress2] = React.useState("");
  const[city,setCity] = React.useState("");
  const[state,setState] = React.useState("");
  const[postCode,setPostCode] = React.useState("");
  const[locationType,setLocationType] = React.useState("");
  const [resultSet1,setResultSet1] = React.useState([]);
  const [count,setCount]=React.useState("");


  // const [selectedRows, setSelectedRows] = useState([]);


  const buttons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const selectID = (value) => {

   parentCallBack(value);
  //  history.push({ 
  //   pathname: '/admin/tenant'
  //  });
   
  };

  // const handleCheckbox = (event, row) => {
  //   if (event.target.checked === true) {
  //     selectedRows.push(row.id);
  //   } else {
  //     selectedRows.pop(row.id);
  //   }
  // };

  const [selectedRows, setSelectedRows] = React.useState([]);

const handleCheckbox = (event, row) => {
  if (event.target.checked === true) {
    setSelectedRows([
       ...selectedRows,
       row
    ])
  } else {
    const nextSelectedRows = selectedRows.filter(selectedRow => selectedRow.id != row.id);
    setSelectedRows(nextSelectedRows)
  }
}


  
  const getViewTenantDetails=(tenantName,id,address1,
    address2,city,state,postCode,locationType)=>{
      
      setTenantName(tenantName);
      setId(id);
      setAddress1(address1);
      setAddress2(address2);
      setCity(city);
      setState(state);
      setPostCode(postCode);
      setLocationType(locationType);
    
 
}


  

  React.useEffect(() => {
 console.log("pg"+resultSet.length);

 setResultSet1(resultSet.tenantSearchListResponses);
setCount(resultSet.totalCount)
    // alert(searchedCompany);
    }, [resultSet]);


    // const tmCreditSafeCompanyListRequestA = async (companyListRequest) => {
    //    await tmCreditSafeCompanyListRequest(companyListRequest);
    //    setDataStauts(true);
      

    //   };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  
  if(resultSet1!=null){

 
  return (
    <GridContainer>
       <GridItem xs={12} sm={12} md={12} style={{paddingRight:"250px",textAlign:"right",paddingBottom:"25px"}}>
       <h3 style={{color:megaColor}}><b>Total Count:{count}</b></h3>
   
       </GridItem>
    

 <GridItem>

<CompanyListTable


searchedCompany1={searchedCompany}
// resultSet1={resultSet}

columns={[
  
{
  Header: "Company Name",
  accessor: "tenantName",
  //width: getColumnWidthNew(companyList, 'accessor', 'Tenant'),
},



  {
    Header: "Sel ID",
    accessor: "creditSafeId",
    width: 50,
  },
  {
    Header: "Type",
    accessor: "type",
    width: 50,
  },
 
  {
    Header: "Address",
    accessor: "address",
    width: 50,
  },

  // {
  //   Header: "Address2",
  //   accessor: "address2",
  //   width: 50,
  // },
  {
    Header: "State",
    accessor: "state",
    align:"center",
    width: 50,
  },

  {
    Header: "City",
    accessor: "city",
    width: 30,
  },
  {
    Header: "Location Type",
    accessor: "headQuarters",
    width: 50,
  },
  {
    Header: "Zipcode",
    accessor: "postCode",
    width: 50,
  },


  
]}
data={resultSet1}



getTdProps={(state, rowInfo, column, instance) => {
  // rowInfo contains a lot of information about the row (including index on both the
  // page and it's index within the data set), the rowInfo.original objet contains 
  // values in the cells of the row clicked.
  return {

    
    // You can define an onClick function here that will apply to all rows
    onClick: (e, handleOriginal) => {

       const rowData = rowInfo.original
       // You can interact with the row data here, to use it however you want
       this.setState({userName: rowData.userName, userEmail: rowData.userEmail})
    }
}}}



/>
</GridItem> 
    </GridContainer>
    
//  <TableBody>
//  {expenseTransactions.map((row) => (
//    <TableRow key={row.id}>
//      <TableCell>
//        <Checkbox onChange={(event) => handleCheckbox(event, row)} />
//      </TableCell>
//    </TableRow>
// </TableBody>
  //   <Table
  //     tableHead={["#","Name","Job Position","Since","Salary","Actions"]}
  //     tableData={[["1","Andrew Mike","Develop","2013","€ 99,225",buttons],
  //     ["1","Andrew Mike","Develop","2013","€ 99,225",buttons]]}
  //     customCellClasses={[
  //       classes.center,
  //       classes.right,
  //       classes.right
  //     ]}
  //     // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
  //     customClassesForCells={[0, 4, 5]}
  //     customHeadCellClasses={[
  //       classes.center,
  //       classes.right,
  //       classes.right
  //     ]}
  //     // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
  //     customHeadClassesForCells={[0, 4, 5]}
  //   />
   );
}else{

  
return(

  <div align="center"  style={{fontSize: '17px' , fontWeight: 'bold'}}>

{/* {loading ?  */}
    <Loader
      
      type="ThreeDots"
      color="#00BFFF"
      height={500}
      width={100}
      timeout={30000} //10 secs
   /> 
   {/* : "No Data Available..."} */}
  
</div>


)


}

}


CompanyListSelection.propTypes = {
 

    loading: PropTypes.bool.isRequired,
    tmCreditSafeCompanyListRequest: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
   
    creditSafeCompanyList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ).isRequired,
  
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    creditSafeCompanyList: state.tenantmonitoring.creditSafeCompanyList,
    loading: state.tenantmonitoring.loading,
    
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CompanyListSelection);
  
