import React,{useEffect,useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import PropTypes from 'prop-types';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
//import { Link } from 'react-router-dom';
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ScoreCard from "components/ScoreCard/ScoreCard.js";
import PIRS from "components/PIRS/PIRS.js";
import xScoreCard from "components/ScoreCard/ScoreCard.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MTTable from "components/MTTable/MTTable.js";
import NFTable from "components/NFTable/NFTable.js";
import CTTable from "components/CTTable/CTTable.js";
import Danger from "components/Typography/Danger.js";
import ReactStoreIndicator from 'react-score-indicator'
import CardFooter from "components/Card/CardFooter.js";
import moment from 'moment';
import Badge from "components/Badge/Badge.js";
import { dataTable } from "variables/general.js";
import Moment from 'moment';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check"
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import Link from '@material-ui/core/Link';
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Heading from "components/Heading/Heading.js";
import { useLocation } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import modalStyles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { grayColor } from "assets/jss/material-dashboard-pro-react.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SweetAlert from "views/Components/SweetAlert.js";
import ModalP from "views/Modal/Modal.js"
import Popup from 'reactjs-popup';
import CreditReport from "components/CreditReport/CreditReport.js";
import DerogatoryReport from "components/DerogatoryReport/DerogatoryReport.js";
import News from "components/News/News.js";
import { Multiselect } from 'multiselect-react-dropdown';
import OrgSelected from 'components/OrgSelected/OrgSelected.js';



// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import Bankruptcy from "components/Bankruptcy/Bankruptcy";
import ParentCreditReport from "components/CreditReport/ParentCreditReport";
import ParentDerogatoryReport from "components/DerogatoryReport/ParentDerogatoryReport";
import ParentPIRS from "components/PIRS/ParentPIRS";
import ParentNews from "components/News/ParentNews";
import ParentBankruptcy from "components/Bankruptcy/ParentBankruptcy";


const styles = {
  ...modalStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color:'#fff',

  },
 cardTitle : {
   
    marginTop: "0",
    marginBottom: "3px",
    minHeight: "auto",
    textAlign:"center",
    fontWeight:700

    },
    headerStyle2:{
      fontWeight:700
    },
    scoreValue: {
      position: "absolute",
      left: 0,
      right: 0,
     
      width: "90%",
      maxWidth: "75%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    modalCloseButton: {
      color: grayColor[0],
      marginTop: "-12px",
      WebkitAppearance: "none",
      padding: "0",
      cursor: "pointer",
      background: "0 0",
      border: "0",
      fontSize: "inherit",
      opacity: ".9",
      textShadow: "none",
      fontWeight: "700",
      lineHeight: "1",
      float: "right"
    },
    modalClose: {
      width: "16px",
      height: "16px"
    },
}


const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

  const ParentNotification = ({
    user,
    tmNotificationList,
    tmBankoNotificationList,
    tmLexisNexisNotificationList, 
    tmNotificationRequest, 
    tmLexisNexisNotificationRequest,
    tmBankoNotificationRequest,
    tmMonitoredTenantsNewsRequest,
    tmPirsRequest,
    tmCreditScoreRequest,
    pirsData,
    creditScoreData,
    loading

  }) => {
    const location = useLocation();
    const [modal, setModal] = React.useState(false);
    const [dataList, setDataList] = React.useState(tmNotificationList);
    const [parentId, setParentId] = React.useState(location.state.parentId);
    const [contifyId, setContifyId] = React.useState(location.state.contifyCompanyId);
    const [accountNumber, setAccountNumber] = React.useState(location.state.accountNumber);
    const [parentName, setParentName] = React.useState(location.state.parentName);
    const [naics, setNaics] = React.useState(location.state.naics);
    const [modalValue, setModalValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [userId, setUserId] = React.useState(user.id);
    const[showBaseLineReport,setShowBaseLineReport] = React.useState(false);
    const [score, setScore] = React.useState(pirsData);
    const [salesTriggers, setSalesTriggers] = React.useState("");
    const [creditScore, setCreditScore] = React.useState("");

    const [creditScoreStatus, setCreditScoreStatus] = React.useState(true);
    const[fileDate,setFileDate] = React.useState("");
    const[statusDate,setStatusDate] = React.useState("");
    const[chapter,setChapter] = React.useState("");
    const[caseNumber,setCaseNumber] = React.useState("");
    const[dispositionCode,setDispositionCode] = React.useState("");
    const[ssnTaxId,setSsnTaxId] = React.useState("");
    const[business,setBusiness] = React.useState("");
    const[debtorsCity,setDebtorsCity] = React.useState("");
    const[debtorsState,setDebtorsState] = React.useState("");
    const[attorneyName,setAttorneyName] = React.useState("");
    const[attorneyAddress,setAttorneyAddress] = React.useState("");
   
   
 


    const showModal = (value) => {
  
     setModal(true);
     setModalValue(value);
   }; 
   
  const getBaseLineReport=(parentId,fileDate,statusDate,chapter,caseNumber,dispositionCode,ssnTaxId,
    business,debtorsCity,debtorsState,attorneyName,attorneyAddress)=>{
    setShowBaseLineReport(true);

       setParentId(parentId);
       setFileDate(fileDate);
       setStatusDate(statusDate);
       setChapter(chapter);
       setCaseNumber(caseNumber);
       setDispositionCode(dispositionCode);
       setSsnTaxId(ssnTaxId);
       setBusiness(business);
       setDebtorsCity(debtorsCity);
       setDebtorsState(debtorsState);
       setAttorneyName(attorneyName);
       setAttorneyAddress(attorneyAddress);
}
    useEffect(() => {
     

      const todayNow = moment().format("D MMM YYYY"); 
      const month = moment().subtract(1, 'months').format("D MMM YYYY");
   
      // getTmNotificationList(tenantId,salesTriggers,todayNow,month);
     
   
    }, []);


        const getColumnWidthNew = (rows, accessor, headerText) => {
          const maxWidth = 400
          const magicSpacing = 10
          const cellLength = Math.max(
            ...rows.map(row => (`${row[accessor]}` || '').length),
            headerText.length,
          )
          return Math.min(maxWidth, cellLength * magicSpacing)
        }

  
  const classes = useStyles();
 
  return (
  
   



    <GridContainer>
  

      <GridItem xs={12} sm={12}>
      {/* <h5 style={{paddingLeft :'15px',paddingBottom:'25px'}}><b> <OrgSelected orgName={tenantName}/></b></h5>
     */}
      <CustomTabs
        headerColor="info"
        tabs={[


          {
            tabName: "Credit Report" ,
            tabIcon: ReportOutlinedIcon,
            variant:"fullWidth",
            tabContent: (
              
              // <DerogatoryReport scores={creditScoreData}/>
              <ParentCreditReport params={{"parentId":parentId,"organizationId":"1"}}/>
       
            )
          },
          {

            tabName: "Derogatory",
            tabIcon: LocalLibraryOutlinedIcon,
            tabContent: (

              <ParentDerogatoryReport params={{"parentId":parentId,"organizationId":"1"}}/>  
           
            )
          },

          {
            tabName: "Pandemic Risk report" ,
            tabIcon: BookmarksOutlinedIcon,
            tabContent: (
              
          
          <ParentPIRS scores={pirsData} parentId={parentId}/>
       
            )
          },
          {
            tabName: "News" ,
            tabIcon: AnnouncementOutlinedIcon,
            tabContent: (
              <ParentNews parentId={parentId}/>   
              
            )
          },


          {
            tabName: "Bankruptcy",
            tabIcon: AccountBalanceOutlinedIcon,
            indicatorColor: "success",
            textColor: "succees",
            tabContent: (
<ParentBankruptcy parentId={parentId} />
                         
            )
          }
        ]}
      />
    


       <div>
         
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={modal}
            transition={Transition}
            keepMounted
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setModal(false)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}><b>Title</b></h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <h5>{modalValue}</h5>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={() => setModal(false)}>Close</Button>
              
            </DialogActions>
          </Dialog>
        </div>

        <div>

<Dialog size={"lg"}
           classes={{
             root: classes.center,
             paper: classes.modal
           }}
           open={showBaseLineReport}
           transition={Transition}
           keepMounted
           onClose={() => setShowBaseLineReport(false)}
           aria-labelledby="modal-slide-title"
           aria-describedby="modal-slide-description"
         >
           <DialogTitle
             id="classic-modal-slide-title"
             disableTypography
             className={classes.modalHeader}
           >
             <GridItem align="right">
             <Button
               justIcon
               className={classes.modalCloseButton}
               key="close"
               aria-label="Close"
               color="transparent"
               onClick={() => setShowBaseLineReport(false)}
             >
               <Close className={classes.modalClose} />
             </Button>
             </GridItem>
             <h4 ><b>{attorneyAddress}</b></h4>
           </DialogTitle>
           <DialogContent
             id="modal-slide-description"
             className={classes.modalBody}
           
           >
              
             <h5>{fileDate}</h5>
             <h6>{statusDate} </h6>
           </DialogContent>
           <DialogActions
             className={classes.modalFooter + " " + classes.modalFooterCenter}
           >
             {/* <Button color="info" onClick={() => setShowBaseLineReport(false)}>Close</Button> */}
             
           </DialogActions>
         </Dialog>
</div>
     

      </GridItem>
  
    </GridContainer>
  );

}

ParentNotification.propTypes = {
  // tmNotificationList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     tenantId: PropTypes.number,
  //     contifyId: PropTypes.number,
  //   }),
  // ).isRequired,



  loading: PropTypes.bool.isRequired,
  tmNotificationRequest: PropTypes.func.isRequired,
  tmLexisNexisNotificationRequest:PropTypes.func.isRequired,
  tmMonitoredTenantsNewsRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tmNotificationList: state.tenantmonitoring.tmNotificationList,
  pirsData:state.tenantmonitoring.pirsData,
  creditScoreData:state.tenantmonitoring.creditScoreData,
  tmBankoNotificationList:state.tenantmonitoring.tmBankoNotificationList,
  tmLexisNexisNotificationList:state.tenantmonitoring.tmLexisNexisNotificationList,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentNotification);

