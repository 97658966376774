import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";
import { Link } from 'react-router-dom';

import SweetAlert from "react-bootstrap-sweetalert";


import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Assignment from "@material-ui/icons/Assignment";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Check from "@material-ui/icons/Check";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { megaColor } from 'assets/jss/material-dashboard-pro-react';
import { useHistory } from "react-router-dom";

import {
    whiteColor,
    hexToRgb,
    lyticsColor,  
    infoColor
  } from "assets/jss/material-dashboard-pro-react.js";
import PropertyListSelection from "./PropertyListSelection";
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';



const useStyles = makeStyles(styles);

const top100Films = [
  { title: 'Mathew John Baynton', year: 1994 },
  { title: 'Thomas Stewart Baker ', year: 1972 },
  { title: 'Benjamin Thomas', year: 1974 },
  { title: 'Steven Berkoff', year: 2008 },
  { title: 'James Curry', year: 1957 },
 
];




const PropertySearch = ({loading,tmPropertySearchRequest,creditSafeCompanyList,sendDataBack,propertySearch}) => {

    const history = useHistory();

  const [input, setInput] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [postCode, setPostCode] = React.useState('');

  const [exact, setExact] = React.useState('');

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

//susmy
const [requiredAddress1, setrequiredAddress1] = React.useState("");
const [requiredStateAddress1, setrequiredStateAddress1] = React.useState("");

const [requiredAddress2, setrequiredAddress2] = React.useState("");
const [requiredStateAddress2, setrequiredStateAddress2] = React.useState("");

const [requiredState1, setrequiredState1] = React.useState("");
const [requiredStateState1, setrequiredStateState1] = React.useState("");

const [requiredCity, setrequiredCity] = React.useState("");
const [requiredStateCity, setrequiredStateCity] = React.useState("");

const [requiredCountry, setrequiredCountry] = React.useState("");
const [requiredStateCountry, setrequiredStateCountry] = React.useState("");

const [requiredZip, setrequiredZip] = React.useState("");
const [requiredStateZip, setrequiredStateZip] = React.useState("");




  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const [showList, setShowList] = React.useState(true);

const [requiredName, setrequiredName] = React.useState("");
const [requiredStateName, setrequiredStateName] = React.useState("");
const [companyName, setCompanyName] = React.useState('');
const [locationType, setLocationType] = React.useState('');
// const property = [{
//   state1 : "DRUCKER DIAGNOSTICS LLC",
  
//   "address1" : "CREDITSAFE",
 
//   "city" : "PORT MATILDA",
//   "address2" : "168 BRADFORD DR, PORT MATILDA, PA, 16870",
//   "zip" : "11234",
//   "country" : "HeadOffice"
// }]


//sweet alert
const [alert1,setAlert1] = React.useState(false);


const [alert,setAlert] = React.useState(false);

const hideAlert = () => {
  setAlert(false);
}
//get data



const [type,setType] =React.useState("");
const [comments,setComments] =React.useState("");
const [userId,setUserId] =React.useState(""); 
const [dataStatus,setDataStatus] =React.useState(false);
const [companyListRequest,setCompanyListRequest] =React.useState("");

const [hideButton, setHideButton] = React.useState(true);

  const recieveSelection = (value) => {
  
    sendDataBack(value);
    
  }

  const handleSimple = event => {
    setState(event.target.value);
  };



//   const submitForm = () => {
//     setHideButton(false);

//     const propertySearchRequest={
//         state1:state1,
//         city:city,
//         country:country,
//         address1:address1,
//         address2:address2,
//         zip:zip,
//         exact:false
//     }
//     setCompanyListRequest(propertySearchRequest);
//    tmCreditSafeCompanyListRequestA(propertySearchRequest).then((data)=>{
//     setTimeout(() => {
//       setDataStatus(true);
//       }, 2000);
//     });

// };

const submitForm = () => {
  localStorage.removeItem('@DeliveryApp:selectedArrayItemsProperty');
    if(verifyLength(state,1)&&verifyLength(city,1)&&verifyLength(address,1)&&verifyLength(postCode,1))
      {

        setHideButton(false);

            const propertySearchRequest1={
              address:address,
              city:city,
              state:state,
              postCode:postCode,
            
            }
    
            setCompanyListRequest(propertySearchRequest1);
            tmPropertySearchRequestA(propertySearchRequest1).then((data)=>{
      setTimeout(() => {
        setDataStatus(true);
        }, );
      });
    setHideButton(true);
      console.log(propertySearchRequest1);
        }
          else{
            setAlert(true);
            if(requiredState===""){
              setrequiredState("error");
            }
            if(requiredStateCity===""){
              setrequiredStateCity("error");
            }
            if(requiredStateState1===""){
              setrequiredStateState1("error");
            }
          
            if(requiredStateZip===""){
              setrequiredStateZip("error");
            }
          }
      
  //  tmCreditSafeCompanyListRequestA(companyListRequest1).then((data)=>{
  //   setTimeout(() => {
  //     setDataStatus(true);
  //     }, 2000);
  //   });

};


const propertyListRequest2={
  address:address,
  city:city,
  state:state,
  postCode:postCode,
 
}

const propertyListRequestParams=propertyListRequest2;


  React.useEffect(() => {
   setShowList(false);
    console.log("java..........1.........."+dataStatus);  
    console.log("list"+propertySearch);
   
    }, [dataStatus]);


    const tmPropertySearchRequestA = async (companyListRequest) => {
      // await tmCreditSafeCompanyListRequest(companyListRequest);
       await tmPropertySearchRequest(companyListRequest);
      

      };


      const handleCancel = e => {
        setAddress("");
        // setAddress2("");
        setState("");
        setCity("");
        // setCountry("");
        setPostCode("");
        setDataStatus(null);
   
        setTimeout(() => {
          e.target.value=null;
          }, 1000);
        //  setProgress(0); 
          };
  
  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

  const [value, setValue] = React.useState(null);


  

  const classes = useStyles(); 

  return (
    <div >
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 style={{color:megaColor}}><b>PROPERTY SEARCH</b></h3>
          <Card>
            
            <CardBody>
           
           
            <GridContainer>

            {/* <GridItem xs={12} sm={12} md={4}>
<CustomInput
                  labelText="Company name *"
                    id="companyName"
                    success={requiredStateName === "success"}
                    error={requiredStateName === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                            setCompanyName(event.target.value);
                        
                        } else {
                          setrequiredStateName("error");
                        }
                        setrequiredName(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredStateName === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem> */}
      
      <GridItem xs={12} sm={12} md={6}>
<CustomInput
                    labelText="Address *"
                    id="address"
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value),1) {
                          setAddress(event.target.value);
                        } else {
                          setrequiredState("error");
                        }
                        setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
            
          
<GridItem xs={12} sm={12} md={6}>
            <CustomInput
                                labelText="City *"
                                id="city"
                                success={requiredStateCity === "success"}
                                error={requiredStateCity === "error"}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  onChange: event => {
                                  
                                  
                                    if (verifyLength(event.target.value),1) {
                                      setCity(event.target.value);
                                    
                                    } else {
                                      setrequiredStateCity("error");
                                    }
                                    setrequiredCity(event.target.value);
                                  },
                                  type: "text",
                                  endAdornment:
                                 
                                    requiredStateCity === "error" ? (
                                      <InputAdornment position="end">
                                        <Close className={classes.danger} />
                                      </InputAdornment>
                                    ) : (
                                      undefined
                                    )
                                }}
            
                              />
            </GridItem>
              
      {/* <GridItem xs={12} sm={12} md={4}>
<CustomInput
                    labelText="Address 2 *"
                    id="website"
                    success={requiredStateAddress2 === "success"}
                    error={requiredStateAddress2 === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value),1) {
                          setAddress2(event.target.value);
                        } else {
                          setrequiredStateAddress2("error");
                        }
                        setrequiredAddress2(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredStateAddress2 === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
          */}
                          
            
              </GridContainer>
              <GridContainer>


              <GridItem xs={12} sm={12} md={6} style={{paddingTop:"10px"}}>
 <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
         
          >State
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            // defaultValue={"1"}
            labelId="demo-simple-select-standard-label"
            label="State"
            value={state}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
               <MenuItem value="AL">
               Alabama
            </MenuItem>
            <MenuItem value="AK">
            Alaska
            </MenuItem>
           
 <MenuItem value="AS">
 American Samoa
            </MenuItem>
            <MenuItem value="AZ">
            Arizona
            </MenuItem>
            <MenuItem value="AR">
            Arkansas
            </MenuItem>
            <MenuItem value="CA">
         
            California
            </MenuItem>
            <MenuItem value="CO">
            Colorado
            </MenuItem>
            <MenuItem value="CT">
            Connecticut
            </MenuItem>
            <MenuItem value="DE">
            Delaware
            </MenuItem>
            <MenuItem value="DC">
            District Of Columbia
            </MenuItem>
            <MenuItem value="FM">
            Federated States Of Micronesia
            </MenuItem>
            <MenuItem value="FL">
            Florida
            </MenuItem>
            <MenuItem value="GA">
            Georgia
            </MenuItem>
            <MenuItem value="GU">
            Guam
            </MenuItem>
            <MenuItem value="HI">
            Hawaii
            </MenuItem>
            <MenuItem value="ID">
            Idaho
            </MenuItem>
            <MenuItem value="IL">
            Illinois
            </MenuItem>
            <MenuItem value="IN">
            Indiana
            </MenuItem>
            <MenuItem value="IA">
            Iowa
            </MenuItem>
            <MenuItem value="KS">
            Kansas
            </MenuItem>
            <MenuItem value="KY">
            Kentucky
            </MenuItem>
            <MenuItem value="LA">
            Louisiana
            </MenuItem>
            <MenuItem value="ME">
            Maine
            </MenuItem>
            <MenuItem value="MH">
            Marshall Islands
            </MenuItem>
            <MenuItem value="MD">
            Maryland
            </MenuItem>
            <MenuItem value="MA">
            Massachusetts
            </MenuItem>
            <MenuItem value="MI">
            Michigan
            </MenuItem>
            <MenuItem value="MN">
            Minnesota
            </MenuItem>
            <MenuItem value="MS">
            Mississippi
               </MenuItem>
            <MenuItem value="MO">
            Missouri
            </MenuItem>
            <MenuItem value="MT">
            Montana
            </MenuItem>
            <MenuItem value="NE">
            Nebraska
            </MenuItem>
            <MenuItem value="NV">
            Nevada
            </MenuItem>
            <MenuItem value="NH">
            New Hampshire
            </MenuItem>
            <MenuItem value="NJ">
            New Jersey
            </MenuItem>

            <MenuItem value="NM">
            New Mexico
            </MenuItem>

            <MenuItem value="NY">
            New York
            </MenuItem>

            <MenuItem value="NC">
            North Carolina
            </MenuItem>

            <MenuItem value="ND">
            North Dakota
            </MenuItem>

            <MenuItem value="MP">
            Northern Mariana Islands
            </MenuItem>

            <MenuItem value="OH">
            Ohio
            </MenuItem>


            <MenuItem value="OK">
            Oklahoma
            </MenuItem>

            <MenuItem value="OR">
            Oregon
            </MenuItem>

            <MenuItem value="PW">
            Palau
            </MenuItem>

            <MenuItem value="PA">
            Pennsylvania
            </MenuItem>

            <MenuItem value="PR">
            Puerto Rico
            </MenuItem>

            <MenuItem value="RI">
            Rhode Island
            </MenuItem>
            <MenuItem value="SC">
            South Carolina
            </MenuItem>
            <MenuItem value="SD">
            South Dakota
            </MenuItem>
            <MenuItem value="PR">
            Tennessee
            </MenuItem>
            <MenuItem value="TX">
            Texas
            </MenuItem>
            <MenuItem value="UT">
            Utah
            </MenuItem>
            <MenuItem value="VT">
            Vermont
            </MenuItem>
            <MenuItem value="VI">
            Virgin Islands
            </MenuItem>

            <MenuItem value="VA">
            Virginia
            </MenuItem>

            <MenuItem value="WA">
            Washington
            </MenuItem>
            <MenuItem value="WV">
            West Virginia
            </MenuItem>
            <MenuItem value="WI">
            Wisconsin
            </MenuItem>
            <MenuItem value="WY">
            Wyoming
            </MenuItem>

          </Select>
        </FormControl>
        </GridItem>
             
            {/* <GridItem xs={12} sm={12} md={6}>
<CustomInput
                   labelText="State *"
                    id="state"
                    success={requiredStateState1 === "success"}
                    error={requiredStateState1 === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setState(event.target.value);
                        
                        } else {
                          setrequiredStateState1("error");
                        }
                        setrequiredState1(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredStateState1 === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem> */}
        
           
         <GridItem xs={12} sm={12} md={6}>
<CustomInput
                  labelText="Zipcode *"
                    id="postCode"
                    success={requiredStateZip === "success"}
                    error={requiredStateZip === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setPostCode(event.target.value);
                        
                        } else {
                          setrequiredStateZip("error");
                        }
                        setrequiredZip(event.target.value);
                      },
                      type: "number",
                      endAdornment:
                     
                        requiredStateZip === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
              
            
             
              </GridContainer>
        {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
<CustomInput
                   labelText="Location Type *"
                    id="locationType"
                    success={requiredStateCountry === "success"}
                    error={requiredStateCountry === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                      
                      
                        if (verifyLength(event.target.value),1) {
                          setLocationType(event.target.value);
                        
                        } else {
                          setrequiredStateCountry("error");
                        }
                        setrequiredCountry(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                     
                        requiredStateCountry === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
</GridItem>
        </GridContainer> */}

            <br></br>
              <GridItem xs={12} sm={12} md={12}>
              {hideButton&&
      <div className={classes.buttonGroup}>
        <Button color="info" size="sm" style={{fontSize:18, fontWeight:"bold"}} className={classes.firstButton} onClick={submitForm}>
        <SearchIcon/> Search
        </Button>
        <Button   type="reset" color="info" size="sm" style={{fontSize:18, fontWeight:"bold"}} className={classes.firstButton} 
        onClick={handleCancel}
    
        >
       <RotateLeftIcon/> Reset&nbsp;
        </Button>

      </div>

      }
</GridItem>

  {dataStatus && <PropertyListSelection resultSet={propertySearch} searchedProperty={propertyListRequestParams}/>} 
{/* <Table
      striped
      tableHead={["CreditSafe ID","Tenant Name"]}
      tableData={creditSafeCompanyList}
      customCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 5 is for classes.right, 6 is for classes.right
      customClassesForCells={[0, 5, 6]}
      customHeadCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 5 is for classes.right, 6 is for classes.right
      customHeadClassesForCells={[0, 5, 6]}
    /> */}
          

    <SweetAlert
            error
          show={alert}
          style={{ display: "block", marginTop: "-100px" }}
           onConfirm={() => hideAlert()}
           confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}

          // onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes.button + " " + classes.error
          }
        >
          Please fill all mandatory fields
        </SweetAlert>


              <Clearfix /> 
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
      </form>
    </div>
  );
}

      
PropertySearch.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmCreditSafeCompanyListRequest: PropTypes.func.isRequired,
  tmPropertySearchRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
 
  propertySearch: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,

};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  creditSafeCompanyList: state.tenantmonitoring.creditSafeCompanyList,
  propertySearch: state.tenantmonitoring.propertySearch,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertySearch);