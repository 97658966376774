import React, {useEffect} from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useRowSelect
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from 'react-router-dom';


// core components
import styles1 from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Paginations from "components/Pagination/Pagination.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Dropdown from 'react-mui-multiselect-dropdown'
import purple from '@material-ui/core/colors/purple'

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';

import { megaColor } from "assets/jss/material-dashboard-pro-react";


import {
	Checkbox,
	Table,
	TableBody,
	TableRow,
  TableCell
} from "@material-ui/core";

import MergedList from "./MergedList";
import AddCircleIcon from '@material-ui/icons/AddCircle';

var itemArray;

const newStyles = {
  ...styles,
  ...styles1,
  ...buttonsStyle,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(newStyles);




// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (




    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: filterValue || "",
        onChange: e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search  records...`
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function CompanyListTable ({ user,columns, data , tmCompanySearchListSaveRequest,companySearchListSave,resultSet1,searchedCompany1}) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [checkedA, setCheckedA] = React.useState(true);
  const [checkedB, setCheckedB] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const history = useHistory();
  const location = useLocation();
  // tenantName,id,address1,state1,address2,city,zip,country
  const [state1, setState1] = React.useState("");

  const [selectedItems, setSelectedItems] = React.useState("");
  const [name, setName] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [creditSafeId, setCreditSafeId] = React.useState("");
  const [userId, setUserId] = React.useState(user.id);

  const [locationType,setLocationType] = React.useState("");

  const [exact, setExact] = React.useState('');


  const [selectedEmployee, setSelectedEmployees] = React.useState([])
  const [selectedSkills, setSelectedSkills] = React.useState([])
  const [selectedCities, setSelectedCities] = React.useState([])

  const [selectedArrayItems, setSelectedArrayItems] = React.useState([])
  const[searchedParametersFromCompany,setSearchedParametersFromCompany] =React.useState(searchedCompany1)

  const [skills, setSkills] = React.useState([])
  const [employees, setEmployees] = React.useState([])
  const [cities, setCities] = React.useState([])

  const [dataStatus,setDataStatus] =React.useState(false);
  const [showMergedList,setShowMergedList] =React.useState(true);
const [rentRollCompanySearchResultItems,setRentRollCompanySearchResultItems] =React.useState([]);
const[state,setState] = React.useState("");

// const [selectedItemList, setSelectedItemList] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedArrayItems')));

// const handleCheckboxChange = (event)=> {setState({selectedRows: event.target.value})}
const [checked, setChecked] = React.useState([true]);

const [selectedRows, setSelectedRows] = React.useState([]);
const [selectedRowsCompany, setSelectedRowsCompany] = React.useState([]);


const[combinedList,setCombinedList] =React.useState([]);

const [showSelectandMerge, setShowSelectandMerge] = React.useState(false);

const[propertySearchListRequestParams,setPropertySearchListRequestParams] =React.useState("");

const[searchedItemsFromProperty,setSearchedItemsFromProperty] = React.useState()



const [showSelectedCompany, setShowSelectedCompany] = React.useState(false);

  const classes = useStyles()

  const handleCloseShowSelectandMerge = () => {
    setShowSelectandMerge(false);
  };

  const handleClickOpenShowSelectandMerge = () => {
    setShowSelectandMerge(true);
  };


  const handleCloseShowSelectedCompany = () => {
    setShowSelectedCompany(false);
  };

  const handleClickOpenShowSelectedCompany = () => {
    setShowSelectedCompany(true);
  };


  const submitForm = (formSubmitEvent) => {

    let produce;
    localStorage.setItem('@DeliveryApp:selectedArrayItemsCompany', JSON.stringify(selectedArrayItems));
   
    localStorage.setItem('@DeliveryApp:searchedParamsFromCompany', JSON.stringify(searchedParametersFromCompany));
    

       var combinedList1 = JSON.parse(localStorage.getItem('@DeliveryApp:selectedArrayItemsProperty'));
  if(combinedList1==null){
     produce = [...selectedArrayItems];
  }
  else{
   produce = [...selectedArrayItems, ...combinedList1];
  }
   
  
    
       history.push({ 
         pathname: '/admin/rentroll',
          state:{resultSet:produce}
         })
   
         setShowSelectandMerge(true);
      

      // history.push('/admin/rentroll',{resultSet:combinedList,searchedParamsfromCompany:"searchedParams"});
       // <Link to={{pathname:'/admin/rentroll',state:{resultSet:combinedList,searchedParamsfromCompany:"searchedParams"}}}  />
   
       // var storedNames = localStorage.getItem('@DeliveryApp:selectedArrayItemsCompany');
       // storedNames.map(item => {
       // alert(item.tenantName);
       // })
       // if(storedNames.length > 0){
       //   alert("from page "+JSON.stringify(storedNames));
       // selectedArrayItems.push(companySearchListRequestParams); 
       // }
   
       //let newList = [...selectedArrayItems, ...companySearchListRequestParams];
      //const combinedList1 = selectedArrayItems.concat(companySearchListRequestParams);
     // setCombinedList(combinedList1);
      // alert("from property1"+combinedList);
   
      // localStorage.setItem('@DeliveryApp:selectedArrayItemsProperty', newList);
       
       // const propertySearchListRequestParams = selectedArrayItems;
       // setRentRollSearchResultItems(propertySearchListRequestParams);
       // setRentRollPropertySearchResultItems(propertySearchListRequestParams);
       // tmPropertySearchListSaveRequestA(propertySearchListRequestParams,userId);
       // setTimeout(() => {
        
       //    setDataStatus(true);
       //   }, 2000);
    
   
     };

  // const submitForm = () => {
  //   alert(selectedArrayItems);
  //   // var storedNames = localStorage.getItem('@DeliveryApp:selectedArrayItemsProperty');
  //   // if(storedNames > 0){
  //   // selectedArrayItems.push(storedNames); 
  //   // }                   
  //   localStorage.setItem('@DeliveryApp:selectedArrayItemsCompany', JSON.stringify(selectedArrayItems));
 
  //   // history.goBack();
  //  alert("from Company"+JSON.stringify(selectedArrayItems));
     

  //   // setCompanySearchListRequestParams(storedNames);
  //   // setShowSelectedCompany(true);
  //   // const companySearchListRequestParams=selectedArrayItems;
  
  //   // setRentRollCompanySearchResultItems(companySearchListRequestParams);
  //   // tmCompanySearchListSaveRequestA(companySearchListRequestParams,userId);
  //   // setTimeout(() => {
  //   //   setDataStatus(true);
   
  //   //   }, 2000);
  
     
    
   

  // };
  // const companySearchListRequestParams=selectedArrayItems;
  const searchedParams=searchedCompany1;

  const [searchedCompanyfromList, setSearchedCompanyfromList]=React.useState("");

  const tmCompanySearchListSaveRequestA = async (rentRollCompanySearchResultItems,userId) => {
    // await tmCreditSafeCompanyListRequest(companyListRequest);
     await tmCompanySearchListSaveRequest(rentRollCompanySearchResultItems,userId);
    //  alert(JSON.stringify(rentRollCompanySearchResultItems));

    };

    const [searchedItem, setSearchedItem] = React.useState("");

// const searchedCompany=(rentRollCompanySearchResultItems)=>{
//   setSearchedItem(rentRollCompanySearchResultItems.tenantName);
//   alert("search"+rentRollCompanySearchResultItems);
// }


  const onRemove = (selectedList, removedItem) =>{
  
  }

  const populateData = () => {
    const skillsData = [
      { id: 1, name: 'React Js' },
      { id: 2, name: 'Angular' },
      { id: 3, name: 'Node JS' }
    ]
    setSkills(skillsData)
  }

  React.useEffect(() => {
    setPropertySearchListRequestParams(localStorage.getItem('@DeliveryApp:selectedArrayItemsProperty'));
    setSearchedItemsFromProperty(localStorage.getItem('@DeliveryApp:searchedParamsFromProperty'));
  
    populateData()
// alert("combinedList"+combinedList);
// alert("searchedParamsFromCompany"+searchedParamsFromCompany);
   
  }, [combinedList])



  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

 
  

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );
       const defaultRef = React.useRef()
     
  
   const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const resolvedRef = ref || defaultRef
      // React.useEffect(() => {
      //   resolvedRef.current.indeterminate = indeterminate
        
      // }, [resolvedRef, indeterminate])

      
  
      return (
        <>

        <Checkbox 
            ref={resolvedRef} {...rest} 
          />
          {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
        </>
      )
      
    }
    
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, selectedRowTitle }
  } = useTable(
    {
      columns,
      data,
    
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 ,hiddenColumns: ["creditSafeId","type"]}
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [

        ...columns,
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // Header: ({ getToggleAllRowsSelectedProps,row }) => (
          //   <div>
          //     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          //   </div>
          // ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={() => handleSelected(row)}/>
            </div>
          ),
        },
       
      ])
    }
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function() {}
  );
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  
  const handleSelected=(row)=>{


    if(!row.isSelected){
      
     selectedArrayItems.push(row.original);
     
    }else{
     var index = selectedArrayItems.indexOf(row.original);
      selectedArrayItems.splice(index, 1);
    }
  
    
  
   
     }
    //  const handleAllRowsSelected=(event,value,row)=>{
      
    //   var isChecked = event.value;  
    //   if(!row.isChecked){
        
    //    selectedArrayItems.push(row.original);
       
    //   }else{
    //    var index = selectedArrayItems.indexOf(row.original);
    //     selectedArrayItems.splice(index, 1);
    //   }
    
      
    
     
    //    }
    //  const handleAllRowsSelected=(row)=>{


    //   if(!row.isSelected){
        
    //    selectedArrayItems.push(row.original);
       
    //   }else{
    //    var index = selectedArrayItems.indexOf(row.original);
    //     selectedArrayItems.splice(index, 1);
    //   }
    
      
    
     
    //    }

   
     
  

 //setSelectedItems(itemArray); 
  return (

    
    <>



{/* <div>
<pre>
          <code>

            {JSON.stringify(
              {
                // selectedRowIds: selectedRowIds,
                // 'id': selectedFlatRows.map(
                //   d => d.original.id
                // ),
                // selectedRowTitle: selectedRowTitle,
                selectedRows : selectedFlatRows.map(
                  d => d.original
                ),
              
                
              },
              null,
              2
            )}
          </code>
        </pre>
      </div> */}


 
      <div className="ReactTable -striped -highlight">
     
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length  !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc
                    })}
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                      {/* {generateSortingIndicator(column)} */}
                    </div>
                    {/* Render the columns filter UI */}
                    
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -even": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map(cell => {
                    return (
                      
                      <td {...cell.getCellProps()} className="rt-td" style={{textAlign:cell.column.align, color:cell.column.color, width:cell.column.width}}>
                        {cell.render("Cell")}
                      </td>
                       
                    );
                  })}
                </tr>
              );
            })}
           
         
          </tbody>
        </table>
<br></br>

        <div className="pagination-bottom">
        
      
        <div className="-pagination">
          <div className="-previous">
            <Button justIcon round
              color="info"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
             
            >
        <NavigateBeforeIcon/> 
            </Button>
          </div>
          <div className="-center">
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.formControlMargins
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={pageSelect}
                    onChange={event => {
                      gotoPage(event.target.value);
                      handlePageSelect(event.target.value);
                    }}
                    inputProps={{
                      name: "pageSelect",
                      id: "page-select"
                    }}
                  >
                    {pageSelectData.map((prop, key) => {
                      return (
                        <MenuItem
                          key={key}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={key}
                        >
                          Page {key + 1}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.formControlMargins
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={numberOfRows}
                    onChange={event => {
                      setPageSize(event.target.value);
                      setNumberOfRows(event.target.value);
                    }}
                    inputProps={{
                      name: "numberOfRows",
                      id: "number-of-rows"
                    }}
                  >
                    {numberOfRowsData.map(prop => {
                      return (
                        <MenuItem
                          key={prop}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={prop}
                        >
                          {prop} rows
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </div>
          <div className="-next">
            <Button  justIcon round
              color="info"
              onClick={() => nextPage()}
              disabled={!canNextPage}
             
            >
             <NavigateNextIcon/> 
            </Button>
          </div>
          </div>
        </div>
        <br></br>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
        <div className={classes.buttonGroup}>
        {/* <Button style={{ background: megaColor,color:"white",fontSize:"14px"}} size="sm" round onClick={() => history.push('/admin/propertydetails')}><b>&nbsp;Generate Score</b></Button> */}
        {/* <Link to={{pathname:'/admin/mergedlist'} }> */}

        {/* <Button   type="reset" color="info" onClick={handleClickOpenShowSelectandMerge}  style={{fontSize:18, fontWeight:"bold"}} className={classes.firstButton}>
        Select & Merge
        </Button> */}
        {/* </Link> */}
      
        </div>
        </GridItem>
      

    
    

<GridItem align="right" xs={12} sm={12} md={12}>
      <div className={classes.buttonGroup} >
         {/* <Link to={{pathname:'/admin/rentroll',state:{resultSet:companySearchListRequestParams,searchedParamsfromCompany:searchedParams}}} > */}
        <Button   type="reset" color="info" onClick={submitForm} style={{fontSize:18, fontWeight:"bold"}} className={classes.firstButton}>
    <AddCircleIcon/>Add to Selection
        </Button>
           {/* </Link> */}
        </div>
        </GridItem>
        </GridContainer>
       
         <GridItem>
               
               <Dialog  fullWidth={true} maxWidth={'sm'} 
 open={showSelectandMerge} onClose={handleCloseShowSelectandMerge}  aria-labelledby="form-dialog-title">
     
       <GridItem align="right">
     
            
          
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseShowSelectandMerge}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<DialogContent>
<h3 align="center" style={{fontWeight:"bold", fontSize:"20px",color:megaColor}}>Your selection has been added.</h3>
  <h3 align="center" style={{fontWeight:"bold", fontSize:"20px",color:megaColor}}>Click on the selected companies to view the results. </h3>
 
  <br></br>
 <br></br>
</DialogContent>
       </Dialog>
               </GridItem> 
      
      
     </div>
    </>
    
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";



CompanyListTable.propTypes = {
 

  loading: PropTypes.bool.isRequired,
  tmCompanySearchListSaveRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
 
  companySearchListSave: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,

};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  companySearchListSave: state.tenantmonitoring.companySearchListSave,
  loading: state.tenantmonitoring.loading,
  
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyListTable);


// export default CompanyListTable;
