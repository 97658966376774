import React, { Component } from "react";
import Chart from "react-apexcharts";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMapTenantRisk from "components/Charts/HeapMapTenantRisk.js";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBaseTenant from 'components/MenuBase/SideMenuBaseTenant.js';



import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import { getTenantsCountByPropertyRange } from "store/sagas/tenantmonitoring";

const useStyles = makeStyles(styles);



// class HeapMapTenant extends React.Component {
//   constructor(props) {

//     super(props);

const HeatMapPropertyTenant = ({user, tmTenantsScoreByPropertyRangeRequest,tmTenantNameScoreByPropertyRangeRequest,tenantScoreByPropertyRange,tmTenantsCountByPropertyRangeRequest,tenantNamesByPropertyRange,propertyId,propertyName,selectedRangeFrom,selectedRangeTo,month,year}) =>{

  const classes = useStyles(); 
  const history = useHistory();
 
  
  const[dashboardSelectedTenantId,setDashboardSelectedTenantId]=React.useState();
  const[dashboardSelectedTenantName,setDashboardSelectedTenantName]=React.useState();
  const[heatMapData,setHeatMapData]=React.useState("");
  const[heatMapTenantVisible,setHeatMapTenantVisible]=React.useState(true);
  const[heatMapTenantRiskVisible,setHeatMapTenantRiskVisible]=React.useState(false);
  
  const [userId, setUserId] = React.useState(user.id);

  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  
  const enableTenant = (value) => {
   
    setDashboardSelectedTenantId(value.tenantId); 
    setDashboardSelectedTenantName(value.tenantName);
    setHeatMapTenantVisible(false);
    setHeatMapTenantRiskVisible(true);
    // setPropertyName(value.propertyName);
    //  setShowHeatMapFundRisk(true);
    //  setHeatMapPropertyVisible(false);
  }
  
  
  React.useEffect(() => {
  
        getTmDashboardTenantByPropertyRange(user.organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,"2020");
       getTmDashboardPropertyTenantNamesByRange(user.organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,"2020");

       getTmTenantsCountByPropertyRange( propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo);
          //setFundDashboard(tmDashboardFund);
      //    if(fundDashboard){
      //     setIsLoadingPage(true);
      //    }
     
        }, []);
  
        const getTmDashboardTenantByPropertyRange = async (organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year) => {
          await tmTenantsScoreByPropertyRangeRequest(organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year);
        //  setHeatMapData(tenantScoreByPropertyRange);
        };

          const getTmDashboardPropertyTenantNamesByRange = async (organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year) => {
         await tmTenantNameScoreByPropertyRangeRequest(organizationId,propertyId,selectedRangeFrom,selectedRangeTo,month,year);
            };

            const getTmTenantsCountByPropertyRange = async (propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo) => {
              await tmTenantsCountByPropertyRangeRequest(propertyId,selectedRangeFrom,year,month,organisationId,selectedRangeTo);
                 };

            const handleChange = (tenantId,tenantName) => {
            
             setHeatMapTenantVisible(false);
             setHeatMapTenantRiskVisible(true);

            //  history.push({
            //   pathname: '/admin/dashboard/',
            //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
            // })
            
             setDashboardSelectedTenantName(tenantName);
             setDashboardSelectedTenantId(tenantId);
            }

            function handleClickHome(event) {
              // event.preventDefault();
              history.push({ 
               pathname: '/'
              });
              
             }
            function handleClickRiskDashboard(event) {
              // event.preventDefault();
              history.push({ 
               pathname: '/dashboard'
              });
              
             }

  const tenantData=  [
      {
         name: "Thyssenkrupp Materials NA, Inc.",
         data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 0
              },
              {
                  "x": "OCT",
                  "y": 0
              },
              {
                  "x": "NOV",
                  "y": 308
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      },
     
      {
        name: "Maurice Pincoffs Company, INC.",
        data: [
              {
                  "x": "JAN",
                  "y": 0
              },
              {
                  "x": "FEB",
                  "y": 0
              },
              {
                  "x": "MAR",
                  "y": 0
              },
              {
                  "x": "APR",
                  "y": 0
              },
              {
                  "x": "MAY",
                  "y": 0
              },
              {
                  "x": "JUN",
                  "y": 0
              },
              {
                  "x": "JUL",
                  "y": 0
              },
              {
                  "x": "AUG",
                  "y": 0
              },
              {
                  "x": "SEP",
                  "y": 386
              },
              {
                  "x": "OCT",
                  "y": 384
              },
              {
                  "x": "NOV",
                  "y": 385
              },
              {
                  "x": "DEC",
                  "y": 0
              }
          ]
      }
      
  ]

    function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

    const chartOps = { 

      series: tenantScoreByPropertyRange,
    //  series:heatMapData,
     
      options: {
        yaxis: {
          labels: {
            show: false,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                    position: 'top',
                      labels: {
                        show: true,
                        
                          style: {
                                    fontSize: '18px',
                                    fontWeight: 700,
                                  }
                                }
                              },
        chart: {
          height: 150,
          type: 'heatmap',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 400,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 300,
                  to: 399,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 200,
                  to: 299,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 100,
                  to: 199,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 1,
                  to: 99,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
          showForNullSeries: true,
          show : false,
          // formatter: function(seriesName, opts) {
          //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
          // }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 3
        },
        title: {
          text: ' '
        },
      },
    
    
    };
  




    return (
      
      <GridContainer>

       


      <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}}>
        {heatMapTenantVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>TENANTS</div></b></h5>
      <SideMenuBaseTenant style ={{height:'440px'}} items={ tenantNamesByPropertyRange } parentCallBack={enableTenant}/>
       </div>}
     </GridItem>

    {heatMapTenantVisible && <GridItem xs={12} sm={9} md={9}>
<div id="chart">
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap" height={580}  />
</div>
    


</GridItem>
    }


    {/* {heatMapTenantRiskVisible && <HeatMapTenantRisk source={"property"} labelStatus={true} tenantName={dashboardSelectedTenantName} tenantId={dashboardSelectedTenantId}/>} */}
 <GridItem xs={12} sm={12} md={12}>

 {heatMapTenantRiskVisible && <HeatMapTenantRisk source={"property"} labelStatus={true} tenantName={dashboardSelectedTenantName} tenantId={dashboardSelectedTenantId}/>}
 <br/>
 <br/>

  {heatMapTenantVisible && <div className={classes.buttonGroup}>
 {/* <Button color="transparent" className={classes.firstButton}>
                   <b>Tenant Names </b>
                 </Button>
                {heatMapTenantVisible&&tenantNamesByPropertyRange.map(tenant => (

                <Button  style = {{color:'#00628B',backgroundColor:'#81A594'}} className={classes.middleButton} onClick={() => handleChange(tenant.id,tenant.name)}>
                <b> {tenant.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ArrowUpwardIcon/></b>
                </Button> */}

  </div> 
 }
 </GridItem>

   </GridContainer>
    
    )

}
HeatMapPropertyTenant.propTypes = {

  loading: PropTypes.bool.isRequired,
  tmTenantsScoreByPropertyRangeRequest: PropTypes.func.isRequired,
  tmTenantNameScoreByPropertyRangeRequest: PropTypes.func.isRequired,
  tmTenantsCountByPropertyRangeRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  tenantScoreByPropertyRange: state.tenantmonitoring.tenantScoreByPropertyRange,
  tenantNamesByPropertyRange:state.tenantmonitoring.tenantNamesByPropertyRange,
  tenantsCountByPropertyRange:state.tenantmonitoring.tenantsCountByPropertyRange,
 
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeatMapPropertyTenant);

