import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Html from 'react-pdf-html';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import ReactPdfTable from "react-pdf-table";
import Moment from 'moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";

import moment from 'moment';

Font.register({
  family: "RobotoRegular",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
});
// Create styles


const PropertyMonthlyPdfReport = ({propertyName,organisationName,generateMonthlyPropertyDetailsPdfReport,riskListGraphMonthlyReport,
  dataUrlList,imageUrl,organisationId
}) =>{



  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: 'white'
    },
    boldExample: {
      fontWeight: "bold",
      fontFamily: RobotoRegular,
      paddingTop: "15px",
      fontSize: "11px",
      textAlign: 'justify'
    },
    body: {
      paddingTop: 65,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
   
    textFont: {
  
      paddingTop: "15px",
      fontSize: "11px",
      textAlign: 'justify',
      fontFamily: "Roboto",
    },
   
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'right',
      color: 'grey',
      
    },
    image: {
      marginVertical: 5,
      marginHorizontal: 150,
      width:"230",
      height:"120",
     
    },
    imageCarter: {
      marginVertical: 5,
      marginHorizontal: 150,
      width:"200",
      height:"90",
     
    },
    image1: {
      width:"530",
      height:"340",
      marginTop:10
      
    },
    
    image2: {
      width:"250",
      height:"150", 
      marginTop:20,
    
      
    },
    image3: {
      width:"20",
      height:"30",
      marginTop:90,
      marginLeft:10,
      marginRight:10
      
    },
    
    inline: {
      display: "flex",
      flexDirection: "row"
    },
    
    inline1: {
      display: "flex",
      flexDirection: "row",
      width:"205",
      height:"52", 
      margin: 60,
      align: "left",
      width:"50%"
      
    },
    inline2: {
      display: "flex",
      flexDirection: "row",
      color: megaColor, 
      fontSize:13, 
      margin: 50,
      justifyContent:"right",
      width:"50%"
    },
   
    table: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:15,
      // height:"auto"
     
    }, 
    tableCautionary: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:10,
      borderColor:"#FFFFFF",
      marginBottom:10
      // height:"auto"
     
    }, 
  
    table1: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:10,
      // height:"190px",
     
    },
  
    table2: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:10,
      // height:"190px",
      // marginLeft:10
     
    }, 
  
    table3: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:30 
    },
    table4: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop: 25
    },
    table5: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop: 30
      // height:"180px"
    },
    table6: { 
      borderCollapse: "collapse",
      width: "100%",
      marginTop:20,
      // height:"auto"
     
    },
    table7: { 
      borderCollapse: "collapse",
      width: "100%",
      // marginLeft:20,
      marginTop:20,
      // height:"auto"
     
    }, 
    table3Orange: { 
      borderCollapse: "collapse",
      border:"1px solid #FFFFFF",
      width: "100%",
      height:"auto",
      // marginTop:25 
    },
    table4Orange: { 
      borderCollapse: "collapse",
      border:"1px solid #FFFFFF",
      width: "100%",
    },
    table5Orange: { 
      borderCollapse: "collapse",
      border:"1px solid #FFFFFF",
      width: "100%",
      // height:"auto"
    },
  
    tableRow: { 
      margin: 0.5, 
      flexDirection: "row",
      // backgroundColor:"#cdd4ea",
    }, 
    
    tableRow1: { 
      marginTop:10, 
      flexDirection: "row",
      
      // backgroundColor:"#cdd4ea",
    }, 
    
  
    tableCell: { 
      margin:5,
      fontSize: "10px",
      color:"#FFFFFF"
    },
  
    tableCell1: { 
      margin:4,
      fontSize: "10px" ,
      color:"#FFFFFF"
    },
    tableHeader1: { 
      width: "70%", 
     backgroundColor:"#3f3b7a"
    }, 
    
    tableHeader11: { 
      width: "60%", 
     backgroundColor:"#3f3b7a"
    },
    tableHeader111: { 
      width: "60%", 
     backgroundColor:"#3f3b7a"
    }, 
    tableCell1Left: { 
      width: "60%", 
      margin: 0.5
    //  backgroundColor:"#e8ebf5"
    }, 
    tableCell1Right: { 
      width: "40%", 
    //  backgroundColor:"#e8ebf5",
    marginLeft:0.5
    }, 
  
    tableHeaderFund: { 
      width: "60%", 
      backgroundColor:"#4573c4",
      marginLeft:1
      },
  
    tableHeader2: { 
    width: "60%", 
    backgroundColor:"#3f3b7a",
    marginLeft:0.5
    },
  
    tableHeader222: { 
      width: "15%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },
      tableHeader2222: { 
        width: "25%", 
        backgroundColor:"#3f3b7a",
        marginLeft:0.5,
        
        },
  
      
  
        tableHeader33Category: { 
          width: "40%", 
          backgroundColor:"#3f3b7a",
          marginLeft:0.5
          },
          tableHeader33CategoryNegativeNews: { 
            width: "60%", 
            backgroundColor:"#3f3b7a",
            marginLeft:0.5
            },
          tableHeader33CategoryPositiveNews: { 
            width: "55%", 
            backgroundColor:"#3f3b7a",
            marginLeft:0.5
            },
          tableHeader33Category1: { 
            width: "40%", 
            backgroundColor:"#3f3b7a",
            marginLeft:0.5
            },
            tableHeader33Category1PostiveNews: { 
              width: "45%", 
              backgroundColor:"#3f3b7a",
              marginLeft:0.5
              },
    tableHeader22: { 
      width: "25%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },
  
  
    tableHeader3: { 
      width: "15%", 
     backgroundColor:"#3f3b7a",
     marginLeft:0.5
    },
    tableHeader33: { 
      width: "25%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },
    tableHeader4: { 
      width: "15%", 
     backgroundColor:"#3f3b7a",
     marginLeft:0.5
    },
    tableHeader44: { 
      width: "20%", 
     backgroundColor:"#3f3b7a",
     marginLeft:0.5
    },
    tableHeader5: { 
      width: "10%", 
     backgroundColor:"#3f3b7a",
     marginLeft:0.5
    },
  
    tableHeader55: { 
      width: "15%", 
     backgroundColor:"#3f3b7a",
     marginLeft:0.5
    },
    tableHeader6: { 
      width: "40%", 
      backgroundColor:"#4573c4",
      marginLeft:1
      },
  
      tableHeader2Orange: { 
        width: "40%", 
        backgroundColor:"#3f3b7a",
        // marginLeft:1
        },
      
        tableHeader3Orange: { 
          width: "20%", 
         backgroundColor:"#3f3b7a"
        },
        
        tableHeader4Orange: { 
          width: "20%", 
         backgroundColor:"#3f3b7a"
        },
      
        tableHeader5Orange: { 
          width: "20%", 
         backgroundColor:"#3f3b7a"
        },
      
        tableHeader6Orange: { 
          width: "40%", 
          backgroundColor:"#3f3b7a",
          marginLeft:1
          },
      
    tableCell2Left: { 
    width: "60%", 
    backgroundColor:"#e8ebf5",
  
    },
  
  tableCell3: { 
  width: "40%", 
  backgroundColor:"#e8ebf5",
    
  },
  
  tableCell4: { 
   width: "20%", 
  backgroundColor:"#e8ebf5",
  },
  tableCell5: { 
   width: "20%", 
  backgroundColor:"#e8ebf5",
        
   },
  tableCell6: { 
   width: "20%", 
  backgroundColor:"#e8ebf5",
  },
  
  tableCell7: { 
    width: "40%", 
    backgroundColor:"#cdd4ea",
      
    },
    
    tableCell8: { 
     width: "20%", 
    backgroundColor:"#cdd4ea",
    },
    tableCell9: { 
     width: "20%", 
    backgroundColor:"#cdd4ea",
          
     },
    tableCell10: { 
     width: "20%", 
    backgroundColor:"#cdd4ea",
    },
  
    tableCell3Orange: { 
      width: "40%", 
      backgroundColor:"#fcece7",
        
      },
      
      tableCell4Orange: { 
       width: "20%", 
      backgroundColor:"#fcece7",
      },
      tableCell5Orange: { 
       width: "20%", 
      backgroundColor:"#fcece7",
            
       },
      tableCell6Orange: { 
       width: "20%", 
      backgroundColor:"#fcece7",
      },
      
      tableCell7Orange: { 
        width: "40%", 
        backgroundColor:"#ffffff",
          
        },
        
        tableCell8Orange: { 
         width: "20%", 
        backgroundColor:"#ffffff",
        },
        tableCell9Orange: { 
         width: "20%", 
        backgroundColor:"#ffffff",
              
         },
        tableCell10Orange: { 
         width: "20%", 
        backgroundColor:"#ffffff",
        },
    tableCell2Right: { 
      width: "40%", 
      backgroundColor:"#e8ebf5",
      marginLeft:1
      },
  
    tableCellHeader: {
      margin: 6, 
      fontSize: "11px",
      fontWeight: 500,
      
    },
    tableCellHeader11: {
      margin: 5, 
      fontSize: "10px",
      fontWeight: 500,
      
    },
  
    tableCellHeader1: {
      margin: 6, 
      fontSize: "10px",
      fontWeight: 500,
      textAlign:"center",
      // paddingRight:"20px",
      
    },

    tableCellHeader1111: {
      margin: 6, 
      fontSize: "10px",
      fontWeight: 500,
      textAlign:"center",
      // paddingRight:"20px",
      
    },
    tableCellHeader1Cautionary: {
      margin: 6, 
      fontSize: "9px",
      fontWeight: 500,
      textAlign:"left",
      // paddingRight:"20px",
      
    },
    tableCellHeader1Category: {
      margin: 6, 
      fontSize: "10px",
      fontWeight: 500,
      textAlign:"left",
      // paddingRight:"20px",
      
    },
    tableCellHeader2: {
      margin: 5, 
      fontSize: "11px",
      fontWeight: 500,
      color:"#FFFFFF"
    },
    tableCellHeader2Credit: {
      margin: 5, 
      fontSize: 11,
      fontWeight: 500,
      color:"#FFFFFF"
    },
    tableCellHeaderCategory: {
      margin: 6, 
      fontSize: 12,
      fontWeight: 500,
      color:"#FFFFFF"
    },
    row:{
      borderBottom: "1pt solid #cfe0e8",
      paddingTop: "5px",
      display: "flex", 
      flexDirection: "row",
    },
   
    inlineLogo: {
      display: "flex",
      flexDirection: "row",
      justifyContent:"space-between",
     position: 'absolute',
     top: 30,
     justifyContent:"space-between",
     left:"35px",
     right:"35px"
    },
  
    inlineFooter: {
      display: "flex",
      flexDirection: "row",
     borderTop:"2px solid #3f3b7a",
     position: 'absolute',
     bottom: 30,
     color: '#828081',
     justifyContent:"space-between",
     left:"35px",
     right:"35px"
    },
  
    inlineFooter1: {
      display: "flex",
      flexDirection: "row",
     fontSize:"10px",
     color: '#828081',
     marginTop:6
    },
  
    inlineFooter2: {
      display: "flex",
      flexDirection: "row",
     fontSize:"10px",
     color: '#828081',
     marginTop:6
    },
    inlineFooter3: {
      display: "flex",
      flexDirection: "row",
     fontSize:"10px",
     color: '#828081',
     marginTop:6
    },
    inlineFooter4: {
      display: "flex",
      flexDirection: "row",
     fontSize:"10px",
     color: '#828081',
     marginTop:6
    },
    inlineFooter5: {
      display: "flex",
      flexDirection: "row",
     fontSize:"10px",
     color: '#828081',
     marginTop:6
    },
  
    card: {
      width: "60%",
      height: "auto",
      borderRadius: "15px",
      backgroundColor: "#FFFFFF",
       borderStyle: "outset",
       borderWidth: "1px 1px 1px 1px",
       borderColor: "#cfe0e8",
       marginTop:10,
      display:"flex",
      flexDirection:"row",
    },
    box:{
      border:"1px solid #3f3b7a",
      width:"60%",
      height:"200px",
      marginTop:10,
      display:"flex",
      flexDirection:"row",
      borderRadius:"10px",
    },
    box1:{
      border:"1px solid #3f3b7a",
      width:"40%",
      height:"auto",
      marginTop:40,
      marginBottom:40,
      display:"flex",
      flexDirection:"column",
      borderRadius:"10px",
      backgroundColor:"#f1f5fb",
      padding:"7px"
    },
    box2:{
      border:"1px solid #3f3b7a",
      borderBottom:"5px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      width:"100%",
      height:"80px",
      // marginBottom:25,   
      display:"flex",
      flexDirection:"row",
      borderRadius:"10px",
      marginTop:12
    },
  
    box2Bottom:{
      border:"1px solid #8190b9",
      width:"100%",
      height:"60px",
      display:"flex",
      flexDirection:"row",
      backgroundColor:"#f1f5fb"
  
    },
    box3:{
      border:"1px solid #FFFFFF",
      width:"40%",
      marginTop:35,
      marginLeft:10,
      height:"auto"
     
    },
    boxFund1:{
      border:"1px solid #3f3b7a",
      width:"75%",
      height:"65px",
      marginBottom:10,   
      marginLeft:40,
      backgroundColor:"#f1f5fb",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      paddingTop:"20px",
      borderRadius:"5px",
    },
    boxFund2:{
      border:"1px solid #8190b9",
      width:"65%",
      height:"60px",
      marginBottom:10,   
      marginLeft:40,
      backgroundColor:"#734ea0",
      display:"flex",
      flexDirection:"row",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      paddingTop:"20px"
    },
    boxNonFund:{
      border:"1px solid #8190b9",
      width:"65%",
      height:"50px",
      marginBottom:10,   
      marginLeft:40,
      backgroundColor:"#9bd45f",
      display:"flex",
      flexDirection:"row",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      paddingTop:"15px"
    },
    box4:{
      width:"100%",
      height:"auto",
      border:"1px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      borderRadius:"5px",
      marginTop:10
    },
    box4Credit:{
      width:"100%",
      height:"auto",
      border:"1px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      borderRadius:"5px",
      marginTop:20
      
    },
    box444:{
      width:"100%",
      height:"auto",
      border:"1px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      borderRadius:"5px",
      marginTop:20
    },
    box44:{
      width:"75%",
      height:"65px",
      border:"1px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      borderRadius:"5px",
      padding:7,
      marginTop:20,
      marginLeft:40,
    },
    // box5:{
    //   width:"50%",
    //   height:"100px",
    //   display:"flex",
    //   flexDirection:"row",
    //   border:"1px solid #8190b9",
    //   backgroundColor:"#f1f5fb",
    //   display:"flex",
    //   flexDirection:"row",
    //   marginRight:20,
    //   marginTop:10,
    //   padding:"7px"
  
    // },
  
    box5:{
      width:"100%",
      border:"1px solid #3f3b7a",
      backgroundColor:"#f1f5fb",
      marginTop:10,
      padding:"7px",
      borderRadius:"5px"
  
    },
    box6:{
      width:"50%",
      height:"auto",
      display:"flex",
      flexDirection:"row",
      border:"1px solid #8190b9",
      backgroundColor:"#f1f5fb",
      display:"flex",
      flexDirection:"row",
      marginTop:10,
      padding:"7px"
    },
    
    box7:{
      border:"1px solid #8190b9",
      width:"25%",
      height:"300px",
      marginTop:20,
      marginLeft:20
  
    },
    arrow: {
      width:"14px",
      height:"14px",
      marginLeft:5
    
    },
    arrow1: {
      width:"14px",
      height:"14px",
      textAlign:"center",
      marginTop:5,
      marginLeft:15 
    },
  
    arrow2: {
      width:"10px",
      height:"10px",
      textAlign:"left",
      // marginTop:20
      // marginLeft:50 
    },
    inlineHeader: {
      display: "flex",
      flexDirection: "row",
      left:"465px"
    },
    imageHeader: {
      width:"60px",
      height:"20px",
    },
    tableCell66: { 
      margin:3,
      fontSize: "11px",
      fontWeight:800,
      color:"#FFFFFF",
      textAlign:"center"
    },
    tableCell1: { 
      margin:5,
      fontSize: "11px" ,
      color:"#FFFFFF",
      textAlign:"left",
      marginTop:"9px",
      fontWeight:"bold"
    },
    tableCell2: { 
      margin:5,
      fontSize: "11px" ,
      color:"#FFFFFF",
      textAlign:"center",
      marginTop:"9px",
      fontWeight:"bold"
    },
    tableCell55: { 
      margin:3,
      fontSize: "11px",
      fontWeight:800,
      color:"#FFFFFF",
      textAlign:"center",
      marginTop:"9px"
    },
    tableHeader444: { 
      width: "20%", 
      backgroundColor:"#3f3b7a",
      marginLeft:0.5
      },
      tableHeader333: { 
        width: "30%", 
        backgroundColor:"#3f3b7a",
        marginLeft:0.5
        },
  });

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [chartLabels,setChartLabels] = React.useState([]);
  const [chartData,setChartData] = React.useState([]);


  const [dataStatus, setDataStatus] = React.useState(false);
  const [showTopPerformingTenantsList,setShowTopPerformingTenantsList] = React.useState(false);
  const [showTopPerformingIndustriesList,setShowTopPerformingIndustriesList] = React.useState(false);
  const [showCautinaryUccList,setShowCautinaryUccList] = React.useState(false);

  const[showPieChart,setShowPieChart]=React.useState(false);

  const [noDataAvailableTopPerformingTenantsList,setNoDataAvailableTopPerformingTenantsList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustriesList,setNoDataAvailableTopPerformingIndustriesList] = React.useState(false);
 

  const [noDataAvailablePropertyNotification,setNoDataAvailablePropertyNotification] = React.useState(false);
  const [showPropertyNotification,setShowPropertyNotification] = React.useState(false);

  const [noDataAvailableDerogCount,setNoDataAvailableDerogCount] = React.useState(false);
  const [showDerogCount,setShowDerogCount] = React.useState(false);

  const [noDataAvailableLiensCount,setNoDataAvailableLiensCount] = React.useState(false);
  const [showLiensCount,setShowLiensCount] = React.useState(false);

  const [noDataAvailableJudgmentCount,setNoDataAvailableJudgmentCount] = React.useState(false);
  const [showJudgementCount,setShowJudgementCount] = React.useState(false);

  const [noDataAvailableSuitsCount,setNoDataAvailableSuitsCount] = React.useState(false);
  const [showSuitsCount,setShowSuitsCount] = React.useState(false);

  const [noDataAvailableCautionaryUccCount,setNoDataAvailableCautionaryUccCount] = React.useState(false);
  const [showCautionaryUccCount,setShowCautionaryUccCount] = React.useState(false);
  const [noDataAvailableCautionaryUccList,setNoDataAvailableCautionaryUccList] = React.useState(false);

  const[showLineChart,setShowLineChart]=React.useState(false);

  const [showLowRiskCountPercentage, setShowLowRiskCountPercentage] = React.useState(false);
  const [showMediumLowRiskCountPercentage, setShowMediumLowRiskCountPercentage] = React.useState(false);
  const [showModerateHighRiskCountPercentage, setShowModerateHighRiskCountPercentage] = React.useState(false);
  const [showMediumHighRiskCountPercentage, setShowMediumHighRiskCountPercentage] = React.useState(false);
  const [showHighRiskCountPercentage, setShowHighRiskCountPercentage] = React.useState(false);

  const [noDataAvailableLowestScoringProperties,setNoDataAvailableLowestScoringProperties] = React.useState(false);
  const [noDataAvailableLowestScoringTenants,setNoDataAvailableLowestScoringTenants] = React.useState(false);
  const [noDataAvailableLowestScoringIndustries,setNoDataAvailableLowestScoringIndustries] = React.useState(false);
  const [showLowestScoringProperties,setShowLowestScoringProperties] = React.useState(false);
  const [showLowestScoringTenants,setShowLowestScoringTenants] = React.useState(false);
  const [showLowestScoringIndustries,setShowLowestScoringIndustries] = React.useState(false);

  const [showIncrementedCreditScoreList, setShowIncrementedCreditScoreList] = React.useState(false);
  const [showDecrementedCreditScoreList, setShowDecrementedCreditScoreList] = React.useState(false);
  const [showPositiveAlertsTenantList,setShowPositiveAlertsTenantList] = React.useState(false);
  const [showNegativeAlertsTenantList,setShowNegativeAlertsTenantList] = React.useState(false);

  const [noDataAvailablePositiveAlertsTenantList,setNoDataAvailablePositiveAlertsTenantList] = React.useState(false);
  const [noDataAvailableNegativeAlertsTenantList,setNoDataAvailableNegativeAlertsTenantList] = React.useState(false);
  const [noDataAvailableIncreasedCreditScoreList,setNoDataAvailableIncreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableDecreasedCreditScoreList,setNoDataAvailableDecreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustyList,setNoDataAvailableTopPerformingIndustyList] = React.useState(false);

  const [showLowestScoringList,setShowLowestScoringList] = React.useState(false);
  const [showTaxLienList,setShowTaxLienList] = React.useState(false);
  const [noDataAvailableTaxLienList,setNoDataAvailableTaxLienList] = React.useState(false);

  const [showJudgementList,setShowJudgementList] = React.useState(false);
  const [noDataAvailableJudgementList,setNoDataAvailableJudgementList] = React.useState(false);

  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [noDataAvailableSuitsList,setNoDataAvailableSuitsList] = React.useState(false);

  const [showNewCautinaryUccList,setShowNewCautinaryUccList] = React.useState(false);
  const [noDataAvailableNewCautionaryUccList,setNoDataAvailableNewCautionaryUccList] = React.useState(false);

  const [showNewTaxLienList,setShowNewTaxLienList] = React.useState(false);
  const [noDataAvailableNewTaxLienList,setNoDataAvailableNewTaxLienList] = React.useState(false);

  const [showNewJudgementList,setShowNewJudgementList] = React.useState(false);
  const [noDataAvailableNewJudgementList,setNoDataAvailableNewJudgementList] = React.useState(false);

  const [showNewSuitsList,setShowNewSuitsList] = React.useState(false);
  const [noDataAvailableNewSuitsList,setNoDataAvailableNewSuitsList] = React.useState(false);

  const [showNoNewDerogFilings, setShowNoNewDerogFilings] = React.useState(false);
  const [showNewDerogFilingsCount, setShowNewDerogFilingsCount] = React.useState(false);


  // const [imageUrl, setImageUrl] = React.useState(location.state.imageUrl);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const todayNow = moment().format("MMMM YYYY"); 
  var currentYear  = moment().format('YYYY');
  const[showArepLogo,setShowArepLogo]=React.useState(false);
  const[showCarterLogo,setShowCarterLogo]=React.useState(false);


  React.useEffect(() => {
  
    if(organisationId=="60f7cf3a09b25f474b336f8b"){
      setShowArepLogo(true);
      setShowCarterLogo(false);
     }else if(organisationId=="654b476a423c6401f20f16db"){
       setShowArepLogo(false);
       setShowCarterLogo(true);
     }
     
    setShowPieChart(true);
    setShowLineChart(true);
    setChartLabels(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.riskCounts.labels);
    setChartData(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.riskCounts.riskCounts);
  
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowRiskCountPercentage > 0){
      setShowLowRiskCountPercentage(true);
    }else{
      setShowLowRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.mediumLowRiskCountPercentage > 0){
      setShowMediumLowRiskCountPercentage(true);
    }else{
      setShowMediumLowRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.moderateHighRiskCountPercentage > 0){
      setShowModerateHighRiskCountPercentage(true);
    }else{
      setShowModerateHighRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.mediumHighRiskCountPercentage > 0){
      setShowMediumHighRiskCountPercentage(true);
    }else{
      setShowMediumHighRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.highRiskCountPercentage > 0){
      setShowHighRiskCountPercentage(true);
    }else{
      setShowHighRiskCountPercentage(false);
    }
    
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList!==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList!=[]){
      setShowTopPerformingTenantsList(true);
      setNoDataAvailableTopPerformingTenantsList(false);
    }
    
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList===null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList==[ ]){
      setShowTopPerformingTenantsList(false);
      setNoDataAvailableTopPerformingTenantsList(true);
    }
    
    
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList != [ ]){
      setShowTopPerformingIndustriesList(true);
      setNoDataAvailableTopPerformingIndustriesList(false);
    
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList == [ ]){
      setShowTopPerformingIndustriesList(false);
      setNoDataAvailableTopPerformingIndustriesList(true);
    }
    //lowscoring tenants
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList != [ ]){
      setShowLowestScoringTenants(true);
      setNoDataAvailableLowestScoringTenants(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList == [ ]){
      setShowLowestScoringTenants(false);
      setNoDataAvailableLowestScoringTenants(true);
    }

    //lowscoring industries
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList != [ ]){
      setShowLowestScoringIndustries(true);
      setNoDataAvailableLowestScoringIndustries(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList == [ ]){
      setShowLowestScoringIndustries(false);
      setNoDataAvailableLowestScoringIndustries(true);
    }
    //cautionary ucc list
    // if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccDetailsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccDetailsList != [ ]){
    //   setShowCautinaryUccList(true);
      
    // }
    // if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccDetailsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccDetailsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccDetailsList == [ ]){
    //   setShowCautinaryUccList(false);
    // }
    
    //incremented credit score
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList != [ ]){
      setShowIncrementedCreditScoreList(true);
      setNoDataAvailableIncreasedCreditScoreList(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList == [ ]){
      setShowIncrementedCreditScoreList(false);
      setNoDataAvailableIncreasedCreditScoreList(true);
    }

    //decremented score list
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList != [ ]){
      setShowDecrementedCreditScoreList(true);
      setNoDataAvailableDecreasedCreditScoreList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList == [ ]){
      setShowDecrementedCreditScoreList(false);
      setNoDataAvailableDecreasedCreditScoreList(true);
    }

    //positivenews list

    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList != [ ]){
      setShowPositiveAlertsTenantList(true);
      setNoDataAvailablePositiveAlertsTenantList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList == [ ]){
      setShowPositiveAlertsTenantList(false);
      setNoDataAvailablePositiveAlertsTenantList(true);
    }
    // negative news list
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList != [ ]){
      setShowNegativeAlertsTenantList(true);
      setNoDataAvailableNegativeAlertsTenantList(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList == [ ]){
      setShowNegativeAlertsTenantList(false);
      setNoDataAvailableNegativeAlertsTenantList(true);
    }

//cautionaryUcclist
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO != null  ){
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  != [ ]){
  setShowCautinaryUccList(true);
  setNoDataAvailableCautionaryUccList(false);
}
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  == [ ]){
  setShowCautinaryUccList(false);
  // setNoDataAvailableCautionaryUccList(true);
}
    
}

//taxlien
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList
    != [ ]){
    setShowTaxLienList(true);
    setNoDataAvailableTaxLienList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList
    .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList
    == [ ]){
      setShowTaxLienList(false);
      // setNoDataAvailableTaxLienList(true);
  }
      
  }

  //judgement
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList
    != [ ]){
    setShowJudgementList(true);
    setNoDataAvailableJudgementList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList
    .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList
    == [ ]){
      setShowJudgementList(false);
      // setNoDataAvailableJudgementList(true);
  }
      
  }

   //SUits
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList
    != [ ]){
    setShowSuitsList(true);
    setNoDataAvailableSuitsList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList
    .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList
    == [ ]){
      setShowSuitsList(false);
      // setNoDataAvailableSuitsList(true);
  }
      
  }

  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList==null&& 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList==null && 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOListt==null && 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList==null){
    setShowSuitsList(false);
    setShowJudgementList(false);
    setShowTaxLienList(false);
    setShowCautinaryUccList(false);
  }else{
    // setShowNoNewDerogFilings(false);
    // setShowNewDerogFilingsCount(true);
  }

  //new cautionaryUcclist
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
    != [ ]){
    setShowNewCautinaryUccList(true);
    setNoDataAvailableNewCautionaryUccList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
    .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
    == [ ]){
    setShowNewCautinaryUccList(false);
    // setNoDataAvailableNewCautionaryUccList(true);
  }
      
  }
  
  //new taxlien
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList
      != [ ]){
      setShowNewTaxLienList(true);
      setNoDataAvailableNewTaxLienList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList
      .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList
      == [ ]){
        setShowNewTaxLienList(false);
        // setNoDataAvailableNewTaxLienList(true);
    }
        
    }
  
    //new judgement
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList
      != [ ]){
      setShowNewJudgementList(true);
      setNoDataAvailableNewJudgementList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList
      .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList
      == [ ]){
        setShowNewJudgementList(false);
        // setNoDataAvailableNewJudgementList(true);
    }
        
    }
  
     //new suits
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList
      != [ ]){
      setShowNewSuitsList(true);
      setNoDataAvailableNewSuitsList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList
      .length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList
      == [ ]){
        setShowNewSuitsList(false);
        // setNoDataAvailableNewSuitsList(true);
    }
        
    }

    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList==null&& 
      generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList==null && 
      generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOListt==null && 
      generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO.suitsTenantDTOList==null){
      setShowNoNewDerogFilings(true);
      setShowNewDerogFilingsCount(false);
      setShowNewSuitsList(false);
      setShowNewJudgementList(false);
      setShowNewTaxLienList(false);
      setShowNewCautinaryUccList(false);
    }else{
      setShowNoNewDerogFilings(false);
      setShowNewDerogFilingsCount(true);
    }


      if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.recommendationDTO.propertyNotificationCount===0){
        setShowPropertyNotification(false);
        setNoDataAvailablePropertyNotification(true);
      }else{
        setShowPropertyNotification(true);
        setNoDataAvailablePropertyNotification(false);
      }
      
    
      if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.recommendationDTO.liensCount===0 && generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.recommendationDTO.judgementCount===0 && generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.recommendationDTO.suitsCount===0 && generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.recommendationDTO.cautionaryUccCount===0){
        setShowDerogCount(false);
        setNoDataAvailableDerogCount(true);
      }else{
        setShowDerogCount(true);
        setNoDataAvailableDerogCount(false);
      }
    
      
        }, [dataStatus]);
    

const ChartJsImage = require('chartjs-to-image');


const myChart = new ChartJsImage();
myChart.setConfig({
  type: 'pie',
  data: { labels: chartLabels, datasets: [{data: chartData, backgroundColor: [
    '#4caf50',
    '#32cd32',
    '#ffdf00',
    '#ff9800',
    'red',
]}] },
options: {
  plugins: {
    datalabels: {
      // align: 'end',
      // anchor: 'end',
      color: "#000000",
      formatter: function (value) {
        return Math.round(value) + '%';
      },
      font: {
        weight: 'bold',
        size: 18,
      }
    }
   
  },
  legend: {
    display: true,
    position: "bottom"
  },
}
});



const dataUrl =  myChart.getUrl();

return(
<Document>
    <Page size="A4" style={styles.body} >

    <View style={styles.inlineHeader} fixed>
  <Image
        style={styles.imageHeader}
        src={require("assets/img/tenanttracker_11803_logo_500.png")}
   
      />
</View>
<View>
    {showArepLogo&&<Image
        style={styles.image}
        src={require("assets/img/AREP_Logo.jpg")}
      
      />}
        {showCarterLogo&&<Image
        style={styles.imageCarter}
        src={require("assets/img/AphorioCarterSponsorLogo.png")}
      
      />}
</View>
<Image
        style={styles.image1}
        // src={require("/home/adminuser/projects/tenantmonitoring/property-image-uploads/"+imageUrl)}    
        src={require("/home/ubuntu/tm-images/property/"+imageUrl)}
      />
  <Text style={{fontSize:"30px" ,fontWeight: 900, marginTop:30,textAlign:"center"}}>Monthly {propertyName} Summary</Text>
  <Text style={{fontSize:"20px" ,fontWeight: 900, marginTop:30,textAlign:"center"}}>{todayNow}</Text>

<View break>
  <Text style={{fontSize:"27px" ,fontWeight: 900,textAlign:"center"}}>{propertyName}</Text>
  <View style={styles.box4}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             <Text style={{fontSize:"12px",color:"#000000"}}>{propertyName} has an overall score of {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.propertyScore} (on a scale of 0-500).</Text>
             </View>      
        </View> 
  <View style={styles.inline}>
  <View style={styles.box}>
     <View style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center"}}>
        <View>
      <Text style={{fontSize:"12px" ,fontWeight: 900,paddingLeft:"80px"}}>Property Entity Risk</Text> 

          {showPieChart&& <Image
        style={styles.image2}
        src={dataUrl}
      
      />}
      </View>
        </View>

  </View>

  <Image
        style={styles.image3}
        src={require("assets/img/bluerightarrow.png")}
      
      />

  <View style={styles.box1}>
    <View style={{display:"flex",flexDirection:"column",width:"90%"}}>
  <Text style={{fontSize:"13px",color:"#000000",marginTop:5}}>{propertyName} has: </Text>
  {showLowRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
 <Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowRiskCountPercentage}% of its tenants with a Low Risk Score (401 and higher)</Text>
 </View>
 </View>}
 
 {showMediumLowRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.mediumLowRiskCountPercentage}% of the tenants with a Medium Low Risk Score (301 to 400)</Text>
 </View>
 </View>}
 
 {showModerateHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.moderateHighRiskCountPercentage}% of the tenants have a Moderately High Risk Score (201 to 300)</Text>
 </View>
 </View>}

 {showMediumHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.mediumHighRiskCountPercentage}% of the tenants with a Medium High Risk Score (101 to 200)</Text>
 </View>
 </View>}

 {showHighRiskCountPercentage&&<View style={{display:"flex",flexDirection:"row",marginTop:5}}>
<View >
 <Image
 style={styles.arrow2}
 src={require("assets/img/squarebullet.png")}

 />
 </View>
 <View style={{marginLeft:3}}>
<Text style={{fontSize:"10px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.highRiskCountPercentage}% of the tenants have a very High Risk Score (0 to 100)</Text>
 </View>
 </View>}
 
  </View>
  </View>
  </View>

  <Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Top Performing Entities</Text>
  {showTopPerformingTenantsList&&<View style={styles.table2}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Monitored Entities</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell2}>Monitored Entity Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Property (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingTenantsList.map((topPerformingTenant, i)=>(
  <View style={styles.tableRow}>

       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingTenant.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingTenant.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingTenant.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {topPerformingTenant.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingTenant.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 
    </View>))}
</View>}
{noDataAvailableTopPerformingTenantsList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>There are no tenants which have a score of more than 300 for the current month.</Text>}


<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Lowest Performing Entities</Text>
{showLowestScoringTenants&&<View style={styles.table2}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Monitored Entities</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell2}>Monitored Entity Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Property (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringTenantsList.map((lowestScoringTenant, i)=>(
  <View style={styles.tableRow}>

       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{lowestScoringTenant.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringTenant.currentScore}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringTenant.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {lowestScoringTenant.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          lowestScoringTenant.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View>
    </View>))}

</View>}
{noDataAvailableLowestScoringTenants&&<Text style={{fontSize:"13px",marginTop:"20px",fontWeight:900,textAlign:"left"}}>There are no tenants which have a score less than 300 for the current month.</Text>}

</View>

<View break>

<Text style={{fontSize:"15px",color:"#000000",marginTop:10}}>Top Performing Industries</Text>

{showTopPerformingIndustriesList&&<View style={styles.table1}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Industries</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell55}>Industry Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Property (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.topPerformingIndustryList.map((topPerformingIndustry,i)=>(

<View style={styles.tableRow}>
 
<View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{topPerformingIndustry.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingIndustry.currentScore}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{topPerformingIndustry.percentageOfPortfolio}%</Text> 
</View> 
<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {topPerformingIndustry.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          topPerformingIndustry.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View> 

    </View>))}
</View>}
{noDataAvailableTopPerformingIndustriesList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for top performing industries...</Text>}


<Text style={{fontSize:"15px",color:"#000000",marginTop:20}}>Lowest Performing Industries</Text>

{showLowestScoringIndustries&&<View style={styles.table1}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader2}> 
            <Text style={styles.tableCell1}>Industries</Text> 
          </View> 
          <View style={styles.tableHeader4}> 
            <Text style={styles.tableCell55}>Industry Score</Text> 
          </View> 
          <View style={styles.tableHeader3}> 
            <Text style={styles.tableCell2}>Property (%)</Text> 
          </View> 
          <View style={styles.tableHeader5}> 
            <Text style={styles.tableCell66}>Monthly Change</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.lowestScoringIndustriesList.map((lowestScoringIndustry,i)=>(

<View style={styles.tableRow}>
 
<View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{lowestScoringIndustry.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringIndustry.currentScore}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "15%"}}> 
<Text style={styles.tableCellHeader1}>{lowestScoringIndustry.percentageOfPortfolio}%</Text> 
</View> 

<View style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323	",  width: "10%",textAlign:"right"}}> 
          {lowestScoringIndustry.monthlyChange==="up" ? <Image
          style={styles.arrow1}
          src={require("assets/img/arrowgreen.png")}

          /> : 
          lowestScoringIndustry.monthlyChange==='down' ?<Image
          style={styles.arrow1}
          src={require("assets/img/arowred.png")}

          />:
          <Image
          style={styles.arrow1}
          src={require("assets/img/arroworange.png")}

          />}
          </View>
    </View>))}
</View>}
{noDataAvailableLowestScoringIndustries&&<Text style={{fontSize:"13px",marginTop:"20px",fontWeight:900,textAlign:"left"}}>No Data Available for lowest scoring industries...</Text>}

</View>
<View break>
<Text style={{fontSize:"17px",color:"#000000"}}>Credit Score Changes</Text>

<View style={styles.box4Credit}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.increasedTenantCreditScoreCount} tenant(s) of the total {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.totalTenantCount} tenants in the property had increases of over 50 basis points, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.decreasedTenantCreditScoreCount} tenants of the total {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.totalTenantCount} tenants in the property had decreases of over 50 basis points.</Text>
             </View>      
        </View> 
{/* <View style={styles.inline}> */}

<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Entities with Increased Credit Score</Text>

{showIncrementedCreditScoreList&& <View style={styles.table6}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader11}> 
            <Text style={styles.tableCellHeader2Credit}>Monitored Entities</Text> 
          </View> 
          <View style={styles.tableHeader2222}> 
            <Text style={styles.tableCellHeader2Credit}>Previous Score</Text> 
          </View> 
          <View style={styles.tableHeader2222}> 
            <Text style={styles.tableCellHeader2Credit}>Current Score</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreIncrementedList.map((increment, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{increment.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "25%"}}> 
<Text style={styles.tableCellHeader1111}>{increment.prevScore}</Text> 
</View> 

<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "25%"}}> 
<Text style={styles.tableCellHeader1111}>{increment.currScore}&nbsp;({increment.percentage}%)</Text> 
</View>

    </View>))}
</View>}
{noDataAvailableIncreasedCreditScoreList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>There are no tenants which have a credit score which increased by 50 basis points in the current month.</Text>}

<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Entities with Decreased Credit Score</Text>

{showDecrementedCreditScoreList&&<View style={styles.table7}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader11}> 
            <Text style={styles.tableCellHeader2Credit}>Monitored Entities</Text> 
          </View> 
          <View style={styles.tableHeader2222}> 
            <Text style={styles.tableCellHeader2Credit}>Previous Score</Text> 
          </View> 
          <View style={styles.tableHeader2222}> 
            <Text style={styles.tableCellHeader2Credit}>Current Score</Text> 
          </View> 
        
          {/* <View style={styles.tableHeader2222}> 
            <Text style={styles.tableCellHeader2Credit}>Property</Text> 
          </View>  */}
          {/* <View style={styles.tableHeader222}> 
            <Text style={styles.tableCellHeader2Credit}>Decrease(%)</Text> 
          </View>  */}
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreDecrementedList.map((decrement, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader}>{decrement.name}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "25%"}}> 
<Text style={styles.tableCellHeader1111}>{decrement.prevScore}</Text> 
</View> 

<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "25%"}}> 
<Text style={styles.tableCellHeader1111}>{decrement.currScore}&nbsp;({decrement.percentage}%)</Text> 
</View>



    </View>))}
</View>}

{noDataAvailableDecreasedCreditScoreList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>There are no tenants which have a credit score which decreased by 50 basis points in the current month.</Text>}

{/* </View> */}

</View>

<View break>
<Text style={{fontSize:"18",color:"#000000"}}>Derogatory Summary</Text>


{showNoNewDerogFilings && <View style={styles.box4}>
<View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPdfReport.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPdfReport.tenantLiensCount} had new liens, {generateMonthlyPdfReport.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPdfReport.tenantJudgementCount} had new judgements, and {generateMonthlyPdfReport.tenantSuitsCount} had new civil suits, and {generateMonthlyPdfReport.inactiveTenantCount} became inactive.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>There are no new derogatory filings for the current month.</Text>
             </View>

        </View>}

        {showNewDerogFilingsCount && <View style={styles.box4}>
        <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantLiensCount} had new liens, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantJudgementCount} had new judgements, and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantSuitsCount} had new civil suits, and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.inactiveTenantCount} became inactive.</Text> */}
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantDerogatoryCount} tenant(s) have active derogatory filings;&nbsp;{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newLiensDerogCount} active tax liens, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccDerogCount} active cautionary UCC filings, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementDerogCount} active judgements and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsDerogCount} active civil suits.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.creditScoreText}</Text>
             </View>      

        </View>}
        {showNewCautinaryUccList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:10}}>Cautionary UCC Filings</Text>}

        {showNewCautinaryUccList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newCautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList.map((cautionaryUccDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {cautionaryUccDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader55}> 
            <Text style={styles.tableCell}>Date Filed</Text> 
          </View> 
          <View style={styles.tableHeader44}> 
            <Text style={styles.tableCell}>Jurisdiction</Text> 
          </View> 
          <View style={styles.tableHeader33}> 
            <Text style={styles.tableCell}>Secured Party Information</Text> 
          </View> 
          <View style={styles.tableHeader33Category}> 
            <Text style={styles.tableCell}>Collateral</Text> 
          </View> 
    </View>

    {cautionaryUccDetails.cautionaryUccDetailsList.map((cautionaryUccDetails, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.jurisdiction}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "25%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.securedPartyName}%</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "40%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.collateral}%</Text> 
</View> 
      
    </View>))}

</View>
</View>
        ))}
{noDataAvailableNewCautionaryUccList&&<Text style={{fontSize:"13px",marginTop:10,fontWeight:900,textAlign:"left"}}>No Data Available for Cautionary UCC Filings...</Text>}


{showNewTaxLienList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>TaxLien Filings</Text>}

{showNewTaxLienList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newTaxLienListForPdfDTO.taxLiensTenantDTOList.map((taxLienDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {taxLienDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
</View>

{taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.jurisdiction}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableNewTaxLienList&&<Text style={{fontSize:"13px",marginTop:10,fontWeight:900,textAlign:"left"}}>No Data Available for TaxLien Filings...</Text>}


{showNewJudgementList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Judgement Filings</Text>}

{showNewJudgementList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newJudgementListForPdfDTO.judgementTenantDTOList.map((judgementDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {judgementDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableNewJudgementList&&<Text style={{fontSize:"13px",marginTop:10,fontWeight:900,textAlign:"left"}}>No Data Available for Judgement Filings...</Text>}


{showNewSuitsList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Civil Suits</Text>}

{showNewSuitsList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newSuitsListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.newSuitsListForPdfDTO.map((suitsDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {suitsDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suit, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(suit.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableNewSuitsList&&<Text style={{fontSize:"13px",marginTop:10,marginBottom:20,fontWeight:900,textAlign:"left"}}>No Data Available for Civil Suits...</Text>}


<View style={styles.box4}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMoanthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantDerogatoryCount} tenants had derogatory filings; {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantLiensCount} had new liens, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantCautionaryUccCount} had new cautionary UCC filings and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantJudgementCount} had new judgements, and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantSuitsCount} had new civil suits, and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.inactiveTenantCount} became inactive.</Text> */}
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantDerogatoryCount} tenant(s) have active derogatory filings;&nbsp;{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantLiensCount} active tax liens, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantCautionaryUccCount} active cautionary UCC filings, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantJudgementCount} active judgements and {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.tenantSuitsCount} active civil suits.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.derogText}</Text>

             </View>      
        </View> 
   
        {showCautinaryUccList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Cautionary UCC Filings</Text>}

        {showCautinaryUccList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList.map((cautionaryUccDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {cautionaryUccDetails.tenantName}</Text>

<View style={styles.tableCautionary}>
<View style={styles.tableRow}>
    <View style={styles.tableHeader55}> 
            <Text style={styles.tableCell}>Date Filed</Text> 
          </View> 
          <View style={styles.tableHeader44}> 
            <Text style={styles.tableCell}>Jurisdiction</Text> 
          </View> 
          <View style={styles.tableHeader33}> 
            <Text style={styles.tableCell}>Secured Party Information</Text> 
          </View> 
          <View style={styles.tableHeader33Category}> 
            <Text style={styles.tableCell}>Collateral</Text> 
          </View> 
    </View>

    {cautionaryUccDetails.cautionaryUccDetailsList.map((cautionaryUccDetails, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.jurisdiction}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "25%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.securedPartyName}%</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "40%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{cautionaryUccDetails.collateral}%</Text> 
</View> 
      
    </View>))}

</View>
</View>
        ))}
{noDataAvailableCautionaryUccList&&<Text style={{fontSize:"13px",marginTop:10,fontWeight:900,textAlign:"left"}}>No Data Available for Cautionary UCC Filings...</Text>}


{showTaxLienList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>TaxLien Filings</Text>}

{showTaxLienList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.taxLienListForPdfDTO.taxLiensTenantDTOList.map((taxLienDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {taxLienDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Jurisdiction</Text> 
  </View> 
</View>

{taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{taxLien.jurisdiction}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableTaxLienList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for TaxLien Filings...</Text>}


{showJudgementList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Judgement Filings</Text>}

{showJudgementList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.judgementListForPdfDTO.judgementTenantDTOList.map((judgementDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {judgementDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{judgement.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableJudgementList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for Judgement Filings...</Text>}


{showSuitsList&&<Text style={{fontSize:"16px",color:"#000000",marginTop:20}}>Civil Suits</Text>}

{showSuitsList&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.suitsListForPdfDTO.suitsTenantDTOList.map((suitsDetails, i)=>(
   
   <View>
   <Text style={{fontSize:"13",color:"#000000",marginTop:10}}>Entity: {suitsDetails.tenantName}</Text>

   <View style={styles.tableCautionary}>
<View style={styles.tableRow}>
<View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Date Filed</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Type</Text> 
  </View> 
  <View style={styles.tableHeader444}> 
    <Text style={styles.tableCell}>Filing Number</Text> 
  </View> 
  <View style={styles.tableHeader55}> 
    <Text style={styles.tableCell}>Amount</Text> 
  </View> 
  <View style={styles.tableHeader333}> 
    <Text style={styles.tableCell}>Plaintiff</Text> 
  </View> 
</View>

{suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suit, i)=>(
<View style={styles.tableRow}>
<View  style={{backgroundColor:"#FFFFFF",width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{Moment(suit.filedDate).format('MM-DD-YYYY')}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.filingType}</Text> 
</View>
<View style={{backgroundColor: "#FFFFFF", width: "20%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.filingNumber}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "15%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.amount}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF", width: "30%"}}> 
<Text style={styles.tableCellHeader1Cautionary}>{suit.plaintiff}</Text> 
</View> 

</View>))}

</View>

</View>
        ))}
{noDataAvailableSuitsList&&<Text style={{fontSize:"13px",marginTop:20,marginBottom:20,fontWeight:900,textAlign:"left"}}>No Data Available for Civil Suits...</Text>}

</View>

<View break>

<Text style={{fontSize:"17",color:"#000000"}}>News Summary</Text>

<View style={styles.box444}>
             <View style={{justifyContent:"center",padding:"7px"}}>
             {/* <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsTenantCount} tenant(s) had positive news alerts, {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsTenantCount} tenants had negative news alerts over the last month.</Text> */}
             <Text style={{fontSize:"12px",color:"#000000"}}>{generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.newsText}</Text>

             </View>      
        </View> 
  <Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Entities with Positive News Alerts:</Text>

  {showPositiveAlertsTenantList&&<View style={styles.table}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader33Category1PostiveNews}> 
            <Text style={styles.tableCellHeader2}>Name</Text> 
          </View> 
          <View style={styles.tableHeader33CategoryPositiveNews}> 
            <Text style={styles.tableCellHeader2}>Category</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.positiveNewsList.map((positiveNews, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "45%",borderRight:"1px solid #3B5323"}}> 
<Text style={styles.tableCellHeader11}>{positiveNews.tenantName}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "55%"}}> 
<Text style={styles.tableCellHeader1Category}>{positiveNews.category.join(", ")}</Text> 
</View> 

    </View>))}
</View>}

{noDataAvailablePositiveAlertsTenantList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for positive news alerts...</Text>}

<Text style={{fontSize:"15px",color:"#000000",marginTop:30}}>Entities with Negative News Alerts:</Text>

{showNegativeAlertsTenantList&&<View style={styles.table}>
    <View style={styles.tableRow}>
          <View style={styles.tableHeader33Category1}> 
            <Text style={styles.tableCellHeader2}>Name</Text> 
          </View> 
          <View style={styles.tableHeader33CategoryNegativeNews}> 
            <Text style={styles.tableCellHeader2}>Category</Text> 
          </View> 
    </View>

    {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList!=""&&generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO.negativeNewsList.map((negativeNews, i)=>(
 <View style={styles.tableRow}>
       <View  style={{backgroundColor:"#FFFFFF", borderBottom:"1px solid #3B5323", width: "40%",borderRight:"1px solid #3B5323"}}> 
<Text style={styles.tableCellHeader11}>{negativeNews.tenantName}</Text> 
</View> 
<View style={{backgroundColor: "#FFFFFF",borderBottom:"1px solid #3B5323", width: "60%"}}> 
<Text style={styles.tableCellHeader1Category}>{negativeNews.category.join(", ")}</Text> 
</View> 

    </View>))}

</View>}
{noDataAvailableNegativeAlertsTenantList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900,textAlign:"left"}}>No Data Available for negative news alerts...</Text>}

</View>

<View style={styles.inlineFooter} fixed>
  <View style={styles.inlineFooter1}>
     <Text >Megalytics®</Text>
  </View>
  <View style={styles.inlineFooter2}>
     <Text style={{paddingLeft:"33px"}}>Copyright © {currentYear} </Text>
  </View>
  <View style={styles.inlineFooter3}>
    <Text style={{paddingLeft:"33px"}}>All Rights Reserved</Text>
  </View>
  <View style={styles.inlineFooter4}>
    <Text style={{paddingLeft:"33px"}}>Proprietary and Confidential</Text>
  </View>
  <View style={styles.inlineFooter5} >
      <Text style={{paddingLeft:"36px"}} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )}  />
</View>
</View>
    </Page>
   
  </Document>
  
  );
 
  
}
  export default PropertyMonthlyPdfReport;