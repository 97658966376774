import React from 'react';
import styled from 'styled-components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
const handleClick = (tenantId,tenantName,tenantAddress,city,state,zip,parentCallBack) => {
 
  const fund_object = {
    tenantId: tenantId,
    tenantName: tenantName,
    tenantAddress: tenantAddress,
    city: city,
    state: state,
    zip: zip,
  };
  parentCallBack(fund_object);

  
  };

const setDirection = (direction) => {
  const defaults = {
    horizontal: 'row nowrap',
    vertical: 'column nowrap',
  };
  return defaults[direction || 'horizontal'];
};

export const renderMenuItems = ({ items,height,parentCallBack}) => (

  
  
  items.map(
    (item, index) => <li key={ index } style={{float:'right',height:height+'px'}} onClick={()=>handleClick(item.tenantId,item.tenantName,item.tenantAddress,item.city,item.state,item.zip,parentCallBack)} >{ item.tenantName }{item.currentStatus==='up' ? <ArrowUpwardIcon/> : item.currentStatus==='down'?<ArrowDownwardIcon/>:<TrendingFlatIcon/>}</li>
  )
);

export const MenuBaseContainer = styled.ul`
  list-style: none;
  font-weight: bold;
  float: right;
  ${props => (props.direction ? `flex-flow: ${setDirection(props.direction)}` : '')};
`;

const MenuBaseTenant = props => (
  <MenuBaseContainer { ...props }>
    { renderMenuItems(props) }
  </MenuBaseContainer>
);

export default MenuBaseTenant;