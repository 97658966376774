import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMapPropertyTenants from "components/Charts/HeatMapPropertyTenants.js";
import TMHeatMapPropertyTenant from "components/Charts/TMHeatMapPropertyTenant.js";

import Pagination from '@material-ui/lab/Pagination';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SideMenuBaseProperty from 'components/MenuBase/SideMenuBaseProperty.js';
import HeatMapPropertyRisk from "components/Charts/HeatMapPropertyRisk.js";
import HeatMapRisk1 from "components/Charts/HeatMapRisk1.js";
import TMHeatMapRisk1 from "components/Charts/TMHeatMapRisk1.js";
import Badge from "components/Badge/Badge.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import { useLocation } from "react-router-dom";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import Card from "components/Card/Card.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const useStyles = makeStyles(styles);

const TMHeapMapPropertyFM = ({user,tmProperyByFundRequest,tmPropertyByFund,tmFundPropertyDashboard,organizationId,tmPropertyDashboardByFundRequest,tmFundPropertyDashboardRequest,propertyByOrg,tmProperyTenantsByOrganizationRequest,propertyHeatMap,fundId,tmPropertyDashboard,fundName,fundIdA,backNavigation,totalCount,tmCurrentMonthFundScoreByFundIdRequest,currentMonthFundScore }) =>{
  const classes = useStyles(); 
  const history = useHistory();

  const location = useLocation();
  const[heatMapPropertyVisible,setHeatMapPropertyVisible]=React.useState(true);

  const[showBackNavigation,setShowBackNavigation]=React.useState(false);

  const[heatMapPropertyRiskVisible,setHeatMapPropertyRiskVisible]=React.useState(false);
  const[heatMapPropertyTenantVisible,setHeatMapPropertyTenantVisible]=React.useState(false);
  const[propertyId,setPropertyId]=React.useState("");
  const[propertyName,setPropertyName]=React.useState("");
  const [selectedFundId, setSelectedFundId] = React.useState("");
  const [selectedFundName, setSelectedFundName] = React.useState("");
  const [selectedRangeFrom, setSelectedRangeFrom] = React.useState(201);
  const [selectedRangeTo, setSelectedRangeTo] = React.useState(300);
  const [directNavigation, setDirectNavigation] = React.useState(backNavigation);
  const [dataStatus, setDataStatus] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageNum, setPageNum] = React.useState(0);
  //const [totalCount, setTotalCount] = React.useState("");
  const [page, setPage] = React.useState(1);

  const[showHeatMapFundRisk,setShowHeatMapFundRisk]= React.useState(false);
 

  const [userId, setUserId] = React.useState(user.id);

  const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
  //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

  // const [showHeatMap, setShowHeatMap] = React.useState(true);
  // const [showHeatMapTenant, setShowHeatMapTenant] = React.useState(false);
  // const [showHeatMapFundRisk, setShowHeatMapFundRisk] = React.useState(false);
   const [showHeatMapProperty, setShowHeatMapProperty] = React.useState(false);
  // const [showHeatMapTenantRisk, setShowHeatMapTenantRisk] = React.useState(false);



  
  const [month, setMonth] = React.useState("");

  const [year, setYear] = React.useState("");

  const [fundIdNav, setFundIdNav] = React.useState("");

  const [fundNameNav, setFundNameNav] = React.useState("");
  const [fundScoreCss,setFundScoreCss] = React.useState("");
  const [fundScore,setFundScore] = React.useState("");
  const enableProperty = (value) => {
   

    setPropertyId(value.propertyId); 
    setPropertyName(value.propertyName);
    setShowHeatMapFundRisk(true);
    setHeatMapPropertyVisible(false);
    setHeatMapPropertyTenantVisible(false);
    setDirectNavigation(true);

   
    
  }

  function handleClickFundDashboard(event) {
   
         
    setShowBackNavigation(true);
    history.push({ 
      pathname: '/dashboard1'
     });
    
   }
      React.useEffect(() => {
        
        if(backNavigation){
       
          setDirectNavigation(false);
        }
       


if(!dataStatus){
  
  getTmDashboardPropertyMap(user.organizationId,fundId,pageSize,pageNum); 
  getTmDashboardPropertyList(fundId,user.id,pageSize,pageNum); 
  getTmCurrentMonthFundScore(fundId);
  // setFundScore(currentMonthFundScore);
  
}else{
  if(currentMonthFundScore > 401 && currentMonthFundScore <= 500){
    setFundScoreCss("#4caf50")
   }else if(currentMonthFundScore > 301 && currentMonthFundScore < 400){
    setFundScoreCss("#32cd32")
   }else if(currentMonthFundScore > 201 && currentMonthFundScore <= 300){
    setFundScoreCss("#ffdf00")
   }else if(currentMonthFundScore > 101 && currentMonthFundScore <= 200){
    setFundScoreCss("#ff9800")
  }else if(currentMonthFundScore >= 0 && currentMonthFundScore <= 100){
    setFundScoreCss("#FF0000")
  }
 
}
setTimeout(() => {
  setDataStatus(true);
  }, 2000);
          
        }, [dataStatus]);

        const  handlePageChange =(event, value) => {
             
          setPage(value);

          getTmDashboardPropertyMap(user.organizationId,fundId,pageSize,value-1); 
          getTmDashboardPropertyList(fundId,user.id,pageSize,value-1); 
          console.log("pageNum"+pageNum);
          console.log("pageSize"+pageSize);
          setTimeout(() => {
           // setDataStatus(true);
            }, 6000);
        
            console.log(tmPropertyByFund.resultSize); 
              };


        const enableTenant = (range) => {
      
          const {rangeFrom,rangeTo,month,year}=range;
          setSelectedRangeFrom(range.rangeFrom);
          setSelectedRangeTo(range.rangeTo);
          setMonth(range.month.substr(0,3));
          setYear(range.year); 
          setHeatMapPropertyRiskVisible(false);
          setHeatMapPropertyVisible(false);
          setShowHeatMapFundRisk(false);
          setHeatMapPropertyTenantVisible(true);
        }
        function handleClickHome(event) {
          // event.preventDefault();
          history.push({ 
           pathname: '/'
          });
          
         }
        function handleClickFundDashboard(event) {
          // event.preventDefault();
         
          // history.push({ 
          //  pathname: '/dashboard'
          // });
          setShowBackNavigation(true);
          history.push({ 
            pathname: '/dashboard'
           });
          
         }
  
        const getTmDashboardPropertyMap = async (organisationId,fundId,pageSize,pageNum) => {
          await tmProperyByFundRequest(organisationId,fundId,pageSize,pageNum);
          };

          const getTmDashboardPropertyList = async (fundId,userId,pageSize,pageNum) => {
            await tmFundPropertyDashboardRequest(fundId,userId,pageSize,pageNum);
            };

            const getTmCurrentMonthFundScore = async (fundId) => {
              await tmCurrentMonthFundScoreByFundIdRequest(fundId);
              console.log("currentMonthFundScore"+currentMonthFundScore);
             
              };
            
            const handleChange = (propertyId,propertyName) => {
            
              setHeatMapPropertyVisible(false);
              setHeatMapPropertyRiskVisible(true);
              setPropertyId(propertyId);
              setPropertyName(propertyName);
             //  history.push({
             //   pathname: '/admin/dashboard/',
             //   state : { tenantId : tenantId, showHeatMapTenantRisk:true}
             // })
             
 
            //  setDashboardSelectedTenantId(tenantId);
             }

   
    // function generateData(count, yrange) {
    //   var i = 0;
    //   var series = [];
    //   while (i < count) {
    //     var x = (i + 1).toString();
    //     var y =
    //       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    
    //     series.push({
    //       x: x,
    //       y: y
    //     });
    //     i++;
    //   }
    //   return series;
    // }

   const chartOps = {


      series: tmFundPropertyDashboard,
      options: {
        yaxis: {
          labels: {
            show: false,
            align: 'right',
            minWidth: 0,
            maxWidth: 300,
              style: {
                        fontSize: '18px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                    position: 'top',
                    labels: {
                      show: true,
                      // align: 'right',
                      // minWidth: 0,
                      // maxWidth: 300,
                        style: {
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  colors: ['black','black','black','black','black','black','black','black','black','black','black','black',],
                                  
                                }
                              }
                              },
        
        chart: {
          height: 250,
          type: 'heatmap',
          legend: {
            show: false,
          },
          toolbar:{
            show:false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
             // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          },
     
        },
        
        plotOptions: {
          heatmap: {
            xaxis:{
              position: 'top',  
          },
            yaxis: {
              labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 300,
                style: {
                    colors: [],
                    fontSize: '50px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 401,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 301,
                  to: 400,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 201,
                  to: 300,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 101,
                  to: 200,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 0,
                  to: 100,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
          showForNullSeries: true,
          show : false,
          // formatter: function(seriesName, opts) {
          //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
          // }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
            if(val == 0){
          //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
           return "NA";      
      }else
            return val;
        }
        },
        stroke: {
          width: 3
        },
        title: {
          text: ''
        },
      },
    
    
    };
  


  if(dataStatus){
    return (

      <div>
      {!directNavigation&&

      <GridContainer >




<GridItem xs={12} sm={12} md={4} style={{paddingLeft:'30px',paddingTop:'30px'}}>
     <Breadcrumbs aria-label="breadcrumb">
      <Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
        {/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
        <Badge color="info"><b>Back</b></Badge> 
      </Link>
      
     
    </Breadcrumbs>
    </GridItem>
   
    <GridItem xs={12} sm={12} md={4}>
    <h3 > <b style={{ color: megaColor}}>{fundName}:</b>&nbsp;<b style={{ color: fundScoreCss}}>{currentMonthFundScore}</b></h3>
         </GridItem>
        
        
         <GridItem xs={12} sm={12} md={4}>
    <h3 > <b style={{ color: megaColor}}>Number of Properties: {tmPropertyByFund.totalCount}</b></h3>
         </GridItem>
      
          {/* <GridItem xs={12} sm={12} md={4}></GridItem>
         <GridItem xs={12} sm={12} md={4}>
    <h3 > <b style={{ color: fundScoreCss}}>{currentMonthFundScore}</b></h3>
         </GridItem>
         <GridItem xs={12} sm={12} md={4}></GridItem> */}

    <GridItem xs={12} sm={5} md={5}>
    <h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>Property Risk Score by Month</b></h3>
         </GridItem>

         {/* <GridItem xs={12} sm={12} md={7} style={{paddingRight:"100px",textAlign:"right",marginTop:"15px"}}>
        <h4 style={{ color: megaColor }}> <b>The arrow indicates current month’s performance of the funds vs 1 year ago</b></h4>
         <h4 style={{ color: megaColor,paddingRight:"4px" }}> <b><TrendingFlatIcon /> : Indicates performance remains the same.</b></h4>
         <h4 style={{ color: megaColor,paddingRight:"42px" }}> <b><ArrowUpwardIcon /> : Indicates performance improvement.</b></h4>
         <h4 style={{ color: megaColor,paddingRight:"159px"}}> <b><ArrowDownwardIcon /> : Performance declined.</b></h4>
                  </GridItem> */}
  



<GridItem xs={12} sm={7} md={7} className={classes.vertical} style={{paddingRight:"120px",paddingTop:'10px',textAlign:"right"}} >


<div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
                <Button color="transparent" size="sm" className={classes.firstButton}>
                <b><h7 style={{color:'red'}}>High Risk</h7></b>
                </Button>

                <Button color="transparent" size="sm" className={classes.middleButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent" size="sm" className={classes.middleButton}>
                 <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
                </Button>
                <Button color="transparent"  size="sm" className={classes.lastButton}>
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
              </Button>
              </div>
              <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
                <Button color="danger" size="sm" className={classes.firstButton}>
                  <b>0 - 100 </b>
                </Button>
                <Button color="warning" size="sm" className={classes.middleButton}>
                <b>101 - 200 </b> 
                </Button>
                <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
                <b>201 - 300 </b>
                </Button>
                <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
                <b>301 - 400 </b>
                </Button>
                <Button color="success"  size="sm" className={classes.lastButton}>
                <b>401 - 500 </b>
                </Button>
              </div>

 </GridItem>
  

 <GridItem xs={12} sm={12} md={3}></GridItem>
   <GridItem xs={12} sm={12} md={9} style={{paddingRight:"100px",marginTop:"15px"}}>
        <h4 style={{ color: megaColor }}> <b>The arrow indicates current month’s performance of the properties vs 1 year ago</b></h4>
         <h4 style={{ color: megaColor,paddingRight:"4px",paddingTop:"10px" }}> <b style={{paddingLeft:"10px"}}><TrendingFlatIcon /> : Remains the same.</b> <b style={{paddingLeft:"30px"}}><ArrowUpwardIcon /> : Improvement.</b><b  style={{paddingLeft:"30px"}}><ArrowDownwardIcon/> : Declined.</b></h4>
        
                  </GridItem>
       




        <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}} className={classes.control}>
        {heatMapPropertyVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>PROPERTY NAME</div></b></h5>
      <SideMenuBaseProperty style ={{height:tmPropertyByFund.listHeight+'px'}} items={ tmPropertyByFund.propertyList } parentCallBack={enableProperty} height={tmPropertyByFund.lineHeight}/>
       </div>}
     </GridItem> 

   {/* <GridItem xs={12} sm={3} md={3} style={{paddingTop:'0px',textAlign: 'right'}} className={classes.control}>
        {heatMapPropertyVisible &&   
        <div style={{display: 'inline-block', textAlign: 'right'}}>
       <h5> <b><div style={{paddingTop:'5px'}}>PROPERTY NAME</div></b></h5>
      <SideMenuBaseProperty style ={{height:"200px"}} items={ tmPropertyByFund.propertyList } parentCallBack={enableProperty} height={tmPropertyByFund.lineHeight}/>
       </div>}
     </GridItem>  */}


    {heatMapPropertyVisible && <GridItem xs={12} sm={9} md={9}>
    <GridItem style={{marginLeft:"-39px"}}>
    
    <div id="chart">
<Chart  options={chartOps.options} series={chartOps.series} type="heatmap"  height={tmPropertyByFund.chartHeight} />
</div>
</GridItem>
<GridItem xs={12} sm={3} md={3}></GridItem>


<GridItem xs={12} sm={9} md={9} className={classes.vertical} style={{paddingLeft:"px",paddingTop:'10px',textAlign:"left"}} >
  
<div>
 <Pagination variant="outlined" color="primary"
shape="rounded" count={tmPropertyByFund.totalPages} page={page}  onChange={handlePageChange} size="large" />
</div>

</GridItem>
</GridItem>


    }

 

<GridItem xs={12} sm={12} md={12}>

  
 <br/>
<br/> 

 </GridItem>
 
   </GridContainer>
  }

{showHeatMapFundRisk && <TMHeatMapRisk1 propertyName={propertyName} propertyId={propertyId} parentCallBack ={enableTenant} fundId={fundId} fundName={fundName}/> }

{heatMapPropertyRiskVisible && <HeatMapPropertyRisk propertyId={propertyId} propertyName={propertyName} parentCallBack ={enableTenant}/>}
{heatMapPropertyTenantVisible && <TMHeatMapPropertyTenant fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year}/>}

  </div>
    )

  }else{
    return(
      <div align="center">
      <Loader type="ThreeDots" color="#00BFFF" height={500} width={100} timeout={3000} />
      </div>
    )
  }

}


TMHeapMapPropertyFM.propTypes = {
  loading: PropTypes.bool.isRequired,
  tmProperyTenantsByOrganizationRequest: PropTypes.func.isRequired,
  tmPropertyByOrgIdRequest: PropTypes.func.isRequired,
  tmFundPropertyDashboardRequest: PropTypes.func.isRequired,
  tmCurrentMonthFundScoreByFundIdRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  propertyHeatMap: state.tenantmonitoring.propertyHeatMap,
  tmPropertyByFund: state.tenantmonitoring.tmPropertyByFund,
  tmPropertyDashboard: state.tenantmonitoring.tmPropertyDashboard,
  tmFundPropertyDashboard: state.tenantmonitoring.tmFundPropertyDashboard,
  propertyByOrg: state.tenantmonitoring.propertyByOrg,
  currentMonthFundScore: state.tenantmonitoring.currentMonthFundScore,
  loading: state.tenantmonitoring.loading,
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TMHeapMapPropertyFM);

