import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfOne from './PdfOne';
import PdfReport from './PdfReport';
import ReactPDF from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { set } from "date-fns";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import PdfMonthlyReport from "./PdfMonthlyReport";
import { Link } from 'react-router-dom';
import MonthlyReport from "./ReportLatest.pdf";
import MonthlyFundReport from "./MonthlyFundReport";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from '@material-ui/core/Card';
import CardBody from "components/Card/CardBody.js";

const styles = {

  root: {
    width:"95%",
    height:"95%",
    boxShadow: "10px 10px 15px 0 rgba(0,0,0,0.3)"
   
  },
  horizontal: {
    display: "flex",
    alignItem: "center",
    paddingRight:"40px",
    paddingBottom:"40px", 
  paddingLeft:"40px",
  columnGap: "0.3em"
  
  },
};
const useStyles = makeStyles(styles);

const CleanupSettings = ({user,tmCleanUpScoreRequest,tmCleanUpNewsRequest,cleanUpScore,cleanUpNews}) =>{


  const location = useLocation();
  const [dataStatus, setDataStatus] = React.useState(false);
//   const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));

const[userId,setUserId]=React.useState(user.id);
const[dateRange, setDateRange] =React.useState("");
// const[fundName, setFundName] = React.useState(location.state.fundName);

 
  React.useEffect(() => {
   
    // if(!dataStatus){
      // setDataStatus(false);
    //   getMonthlyFundPdfReports(organisationId,fundId);

  setTimeout(() => {
    setDataStatus(true);
  }, 500);

 
    }, [dataStatus]);

    const getCleanUpScore = async (organisationId) => {
      await tmCleanUpScoreRequest(organisationId);
      };
      const getCleanUpNews = async (organisationId,dateRange) => {
        await tmCleanUpNewsRequest(organisationId,dateRange);
        };
  
      
    const handleClickOpenCleanUpScore = (value) => {

      getCleanUpScore(organisationId);

    };
    const handleClickOpenCleanUpNews = (value) => {

      getCleanUpNews(organisationId,dateRange);

    };
  
      const classes = useStyles();

      if(dataStatus){
    return(

      <Card>
 
 <GridContainer>
 <GridItem xs={12} sm={12} md={4} className={classes.horizontal} style={{marginTop:30,marginLeft:30,marginBottom:30}}>
      
      <Card className={classes.root} variant="outlined"> 
        
        <CardBody>

          <h5 align="center" className={classes.cardTitles}><b >Clean up Scores</b></h5>
          <div align="center">
                  <Link onClick={() => handleClickOpenCleanUpScore()} style={{cursor: "pointer"}}>Clean</Link>
     </div>
        </CardBody>

      </Card>
     
      </GridItem>

      <GridItem xs={12} sm={12} md={4} className={classes.horizontal} style={{marginTop:30,marginLeft:30,marginBottom:30}}>
      
      <Card className={classes.root} variant="outlined"> 
        
        <CardBody>

          <h5 align="center" className={classes.cardTitles}><b >Clean up News</b></h5>
          <div align="center">
                  <Link onClick={() => handleClickOpenCleanUpNews()} style={{cursor: "pointer"}}>Clean</Link>
     </div>
        </CardBody>

      </Card>
     
      </GridItem>

      </GridContainer>
        </Card>
       
);
    }else{
  return(
   
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
}
    }

    CleanupSettings.propTypes = {

      loading: PropTypes.bool.isRequired,
      tmCleanUpScoreRequest: PropTypes.func.isRequired,
      tmCleanUpNewsRequest: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    };
    
    const mapStateToProps = state => ({
      user: state.auth.loggedUser,
      cleanUpScore: state.tenantmonitoring.cleanUpScore,
      cleanUpNews: state.tenantmonitoring.cleanUpNews,
 
    });              
    
    const mapDispatchToProps = dispatch => bindActionCreators(
      {
    
        ...TMActions,
      },
      dispatch,
    );
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps,
    )(CleanupSettings);
