import React from 'react';
import styled from 'styled-components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
const handleClick = (id,name,parentCallBack) => {
 
  const fund_object = {
    fundId: id,
    fundName: name,
  };
  parentCallBack(fund_object);

  
  };

const setDirection = (direction) => {
  const defaults = {
    horizontal: 'row nowrap',
    vertical: 'column nowrap',
  };
  return defaults[direction || 'horizontal'];
};

export const renderMenuItems = ({ items,parentCallBack }) => (

  
  
  items.map(
    (item, index) => <li key={ index }  onClick={()=>handleClick(item.id,item.name,parentCallBack)} >{item.name}{item.currentStatus==='up' ? <ArrowUpwardIcon/> : item.currentStatus==='down'?<ArrowDownwardIcon/>:<TrendingFlatIcon/>}</li>
  )
);

export const MenuBaseContainer = styled.ul`
  list-style: none;
  font-weight: bold;
  float: right;
  ${props => (props.direction ? `flex-flow: ${setDirection(props.direction)}` : '')};
`;

const MenuBase = props => (
 
  <MenuBaseContainer { ...props }>
    { renderMenuItems(props) }
  </MenuBaseContainer>
);

export default MenuBase;