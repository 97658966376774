
import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Moment from 'moment';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Assignment from "@material-ui/icons/Assignment";
import AddAlert from "@material-ui/icons/AddAlert";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import  styles from "src/assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';
import OrgSelected from 'components/OrgSelected/OrgSelected.js';

import ViewFailureListTable from 'views/Company/ViewFailureListTable.js';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";
import ViewLog from "./ViewLog";
  
  


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  
];










// const Liensreport = ({tmActiveLiensJudgmentDetailsRequest,liensJudgmentDetailsDTO,tmActiveLiensDetailsRequest,liensList}) => {
  const ViewFailureList = ({tmDistinctTenantsFromScoreExecutionLogRequest,distinctTenantsFromScoreExecutionLog,
tenantName
    }) => {

  const [input, setInput] = React.useState('');
 // const [dataList, setDataList] = React.useState(tenantFailureStatusLogListByOrg);

  
  const location = useLocation();

   //email validation
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");


  // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
// const [typeEmail, settypeEmail] = React.useState("");
// const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");
const [messageobj, setMessageobj] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");
const[failureCount,setFailureCount] = React.useState("");
const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
 
const[id,setId] = React.useState("");
const [tenantId,setTenantId] = React.useState(location.state.tenantId);
const[type,setType] = React.useState("");
const[status,setStatus] = React.useState("");
const [showViewLog, setShowViewLog] = React.useState(false);
const [dataStatus, setDataStatus] = React.useState(false);

const [organisationId, setOrganisationId] = React.useState(location.state.organisationId);
 const[month,setMonth] = React.useState(location.state.month);
const[year,setYear] = React.useState(location.state.year);



React.useEffect(() => {
   
  if(!dataStatus){
  
    getDistinctTenantsFromScoreExecutionLog(organisationId,month,year);

//   getActiveLiensList(tenantId);
//   getActiveLiensJudgmentScore(tenantId);
//   getActiveJudgementList(tenantId);

  }
  setTimeout(() => {
    setDataStatus(true);
    }, 2000);

}, [dataStatus]);



  const getDistinctTenantsFromScoreExecutionLog = async (organisationId,month,year)=>{
  
await tmDistinctTenantsFromScoreExecutionLogRequest(organisationId,month,year)
   //setFailureCount(organizationScoreExecutionLogList);
  
//    alert(month);
//  alert(organisationId);
//  alert(year);
  }

// 

const getFailureList=(tenantId,month1,year1)=>{
  setShowViewLog(true);

       setTenantId(tenantId);
    // setMonth(month1);
    // setYear(year1);
    //alert(organisationId);
    // alert(month1);
    // alert(year1);

}

  

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  
  const handleChange = (value) => {
    
  };

  // const handleCloseLiensreport = () => {
  //   setShowLiensreport(false);
  // };

  const submitForm = () => {


  };


  const flatProps = {
    options: top100Films.map((option) => option.title),
  };

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that verifies if value contains only numbers
const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

const typeClick = () => {
  if (requiredState === "") {
    setrequiredState("error");
  }
  if (typeEmailState === "") {
    settypeEmailState("error");
  }
  if (numberState === "") {
    setnumberState("error");
  }
  if (urlState === "") {
    seturlState("error");
  }
  if (equalToState === "") {
    setequalToState("error");
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
const getColumnWidthNew = (rows, accessor, headerText) => {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

// const showModal = (value) => {
  
//   setModal(true);
//   setModalValue(value);
// }; 

//for modal
    // export default function FormDialog() {
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    // }

    // const handleClickOpenViewLog = () => {

    //   setShowViewLog(true);
    // };
  
    const handleCloseViewLog = () => {
      setShowViewLog(false);
    };



  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
  
    <GridContainer>
   <GridItem xs={12}>
   
     </GridItem> 

  
   <GridItem xs={12}>
  
  
   

     <Card>
       <CardHeader style={{ background: megaColor,color:"white" ,fontSize:"17px"}} icon>
         <CardIcon color="info">
           <Assignment />
         </CardIcon>
         <h4><b>Tenant Execution Log List: {selectedOrganization} -{month} {year}</b></h4>
         
       </CardHeader>
       <CardBody>
       {messageobj && <SnackbarContent
message={messageobj}
close
icon={AddAlert}
color="info"
/>
}


            <GridContainer className={classes.gridContainer}>
            <GridItem>
               
               <Dialog fullWidth={true} verticalAlign={'middle'} 
maxWidth={'md'} open={showViewLog} onClose={handleCloseViewLog}  aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      <GridItem align="right" paddingTop="20px">
    
        <DialogActions>
        <Button 
          justIcon
          round
          simple
          onClick={handleCloseViewLog}
          color="info"
          className="close" >
          <CloseIcon/>
          </Button>
         
          </DialogActions>
</GridItem>
<GridItem paddingLeft='30px' >
          {showViewLog && <ViewLog  tenantId ={tenantId} month={month} year={year}/>}
          </GridItem>
          </Dialog>
               </GridItem>
      
</GridContainer>


{/* <h2 style={{ color: megaColor,fontSize:'22px', fontWeight:'bold' }}>Company Name:{selectedOrganization} -{month} {year}</h2> */}

             
           

             

         <ViewFailureListTable
           columns={[

             {
               Header: "Tenant Name",
               accessor: "tenantName",

              
              // Cell: ({ cell,row }) => (<Link to={{ state :{tenantId : row.original.tenantId, month:row.original.month, year:row.original.year, tenantName:row.original.tenantName} } }>{row.original.tenantName}</Link>
             
              //  ),
             
 //             
             },

            //  {
            //      Header:"Month",
            //      accessor:"month"

            //  },

            //  {
            //    Header:"Year",
            //    accessor:"year"
            //  },
            //  {
            //   Header:"TenantId",
            //   accessor:"tenantId"
            //  },


         //     {
         //       Header: "OrganizationId",
         //        accessor: "organisationId",
         //        width: 50,
                
         //    },

             
         //   {
         //     Header: "Organization Name",
            
         //     accessor: "organisationName",
         //     //width: getColumnWidth(tenantList, 'tenantName', 'Tenant'),
         //   //   Cell: ({ cell,row }) => (<Link to={{pathname:'/admin/tenantNotification/', state :{tenantId : row.original.id, uploadedDate:row.original.uploadedDate, orgName:row.original.orgName, totTenant : row.original.totTenant,successCount:row.original.successCount,failureCount:row.original.failureCount} } }>{row.original.orgName}</Link>
          
           
         //   //  ),
          
         //     // Cell: ({ cell,row ,
         //     //   column: { tenantId } }) => (
             
         //     // <Link to={{pathname:"/admin/tenantNotification/"} , {state :{data : row}} } >{row.value}   <Badge color="warning">15</Badge></Link>),
         //  // }
         //  width: getColumnWidthNew(organizationForExecutionLogByOrg, 'accessor', 'Name'),
         
       
 
         // },
        

       
        
           
             

           
             {
               Header: "Actions",
             accessor: "actions",
 //             Cell: ({ cell,row }) => (
 //               <Link
 
 //   onClick={() => handleClickOpenViewFailureList(row.original.organisationId,row.original.month,row.original.year)}
 // >
 // {cell.value}
 // </Link>
 //              ),
         
         width: getColumnWidthNew(distinctTenantsFromScoreExecutionLog, 'accessor', 'actions'),
   
               sortable: false,
               filterable: false,

                Cell: ({ cell,row }) => (<Link to={{ state :{tenantId : row.original.tenantId, month : row.original.month, year : row.original.year,tenantName : row.original.tenantName} } } 
                  onClick={() => getFailureList(row.original.tenantId,row.original.month,row.original.year,row.original.tenantName)}>View Log</Link>),

  //              Cell: ({ cell,row }) => (
  //                <Link to={{}}
    
  //  // onClick={() => getFailureList(row.original.tenantId,row.original.month,row.original.year)}
  //  >
// ViewLog
//    </Link>
//                 ),
              },


           ]}
           data={distinctTenantsFromScoreExecutionLog.map((prop, key) => {

            
             return {
               tenantId: prop.id,
               tenantName: prop.tenantName,
                month: prop.month,
                year: prop.year,


              //  actions:(
              //    <div>

              //     <Link to={{pathname:'/admin/view-log', state :{tenantId : prop.id, month:prop.month, year:prop.year} } }> 
              //         <Button
              //           size="sm" color="info"
              //       onClick={getFailureList}
              //      >
              //          View Log
              //        </Button>{" "}

                      
              //      </Link> 
                      
              //    </div>
              //  )
          
              
             };
           })}


     
           //  data={tenantUploadStatusList}

           getTdProps={(state, rowInfo, column, instance) => {
             // rowInfo contains a lot of information about the row (including index on both the
             // page and it's index within the data set), the rowInfo.original objet contains 
             // values in the cells of the row clicked.
             return {
               // You can define an onClick function here that will apply to all rows
               onClick: (e, handleOriginal) => {
     
                  const rowData = rowInfo.original
                  // You can interact with the row data here, to use it however you want
                  this.setState({month: rowData.month, year: rowData.year})
               }
         }}}
         
         />
       </CardBody>
       
     </Card>
     
     </GridItem>
     
    
 </GridContainer>
 
);
}else{
  return(
    <div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={4000} //3 secs
  /></div>
  )
}
}

ViewFailureList.propTypes = {
    organizationScoreExecutionLogList: PropTypes.arrayOf(
    PropTypes.shape({
      organisationId: PropTypes.string,
      month:PropTypes.string,
      year:PropTypes.string,
    }),
  ).isRequired,

  loading: PropTypes.bool.isRequired,
  tmDistinctTenantsFromScoreExecutionLogRequest: PropTypes.func.isRequired,
//   tmActiveTaxLienJudgmentScoreByTenantIdRequest: PropTypes.func.isRequired,
//   tmActiveJudgmentDetailsRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  distinctTenantsFromScoreExecutionLog: state.tenantmonitoring.distinctTenantsFromScoreExecutionLog,
     loading: state.tenantmonitoring.loading,
  organisationId: state.tenantmonitoring.organisationId,
//  month:state.tenantmonitoring.month,
//  year:state.tenantmonitoring.year,
//   taxLienJudgmentScore :state.tenantmonitoring.taxLienJudgmentScore,
//   judgmentList:state.tenantmonitoring.judgmentList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewFailureList);

