import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Html from 'react-pdf-html';
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import ReactPdfTable from "react-pdf-table";
import Moment from 'moment';

// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";

Font.register({
  family: "RobotoRegular",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
});
// Create styles

const PdfReport = ({ taxLienDetailList,reportsDetails,tenantName,
}) =>{

  
 const BORDER_COLOR = '#cfe0e8';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 35
const COLN_WIDTH = (100 - COL1_WIDTH) / 2
const COL2_WIDTH = 34
const COLN1_WIDTH = (100 - COL2_WIDTH) / 2

const COLN3_WIDTH = 10

const COLDATE_WIDTH = 20
const COLTYPE_WIDTH = 20
const COLNUMBER_WIDTH = 20
const COLAMOUNT_WIDTH = 10
const COLJURISDICTION_WIDTH = 30


const COLDOCKETTITLE_WIDTH = 20
const COLDOCKETNUMBER_WIDTH = 20
const COLFILINGDATE_WIDTH = 15
const COLCOURT_WIDTH = 10
const COLNATUREOFSUIT_WIDTH = 15
const COLCOMPANYINTEREST_WIDTH = 20

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  boldExample: {
    fontWeight: "bold",
    fontFamily: RobotoRegular,
    paddingTop: "15px",
    fontSize: "11px",
    textAlign: 'justify'
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
 
  textFont: {

    paddingTop: "15px",
    fontSize: "11px",
    textAlign: 'justify',
    fontFamily: "Roboto",
  },
 
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
    
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 80,
  
  },
  image1: {
    width:"240px",
    height:"150px", 
    marginTop:20
    
  },
  image2: {
    width:"75",
    height:"15", 
    align:"left"
    
  },
  image3: {
    width:"75",
    height:"15", 
    align:"right"
    
  },

  arrow: {
    width:"10",
    height:"10", 
    align:"left",
   
    
  },
  

  table3: { 
    borderCollapse: "collapse",
    width: "100%",
    backgroundColor: "#e3eed1",
    borderRadius:"10px"
  }, 
  tableRow1: { 
   
    flexDirection: "row" ,
    marginBottom:5
  },
  tableRow1BottomLine: { 
   
    flexDirection: "row" ,
    borderBottom: "1pt solid #cfe0e8",
   
  },  
  tableCol1DateHeader: { 
    width: COLDATE_WIDTH + '%', 
  
  }, 
  tableCol1TypeHeader: { 
    width: COLTYPE_WIDTH + '%', 
  
  }, 
  tableCol1NumberHeader: { 
    width: COLNUMBER_WIDTH + '%', 
  
  }, 
  tableCol1AmountHeader: { 
    width: COLAMOUNT_WIDTH + '%', 
  
  },     
  tableCol1JurisdictionHeader: { 
    width: COLJURISDICTION_WIDTH + '%', 
  
  }, 

  tableCol1DocketTitleHeader: { 
    width: COLDOCKETTITLE_WIDTH + '%', 
  
  }, 
  tableCol1DocketNumberHeader: { 
    width: COLDOCKETNUMBER_WIDTH + '%', 
  
  }, 
  tableCol1FilingDateHeader: { 
    width: COLFILINGDATE_WIDTH + '%', 
  
  }, 
  tableCol1CourtHeader: { 
    width: COLCOURT_WIDTH + '%', 
  
  },     
  tableCol1NatureOfSuitHeader: { 
    width: COLNATUREOFSUIT_WIDTH + '%', 
  
  }, 
  tableCol1COmpanyInterestHeader: { 
    width: COLCOMPANYINTEREST_WIDTH + '%', 
  
  },
  
  tableCol1: { 
    width: COLN3_WIDTH + '%', 
  
  },   
 
 
 tableCellHeader : {

    fontSize: "11px",
    fontWeight: 900,
    color: "#00628B",

  },
  
  tableCellHeaderDocketTitle : {

    width: COLDOCKETTITLE_WIDTH +'%',
  },

  tableCellHeaderDocketNumber : {

    fontSize: "11px",
    fontWeight: 900,
    width: COLDOCKETNUMBER_WIDTH +'%',
  },
  tableCellHeaderFilingDate : {

    fontSize: "11px",
    fontWeight: 900,
  
    width: COLFILINGDATE_WIDTH +'%',
  },
  tableCellHeaderCourt : {

    fontSize: "11px",
    fontWeight: 900,
  
    width: COLCOURT_WIDTH +'%',
  },
  tableCellHeaderNatureOfSuit : {

    fontSize: "11px",
    fontWeight: 900,
  
    width: COLNATUREOFSUIT_WIDTH +'%',
  },
  tableCellHeaderCompanyInterest : {

    fontSize: "11px",
    fontWeight: 900,
 
    width: COLCOMPANYINTEREST_WIDTH +'%',
  },
  inline: {
    display: "flex",
    flexDirection: "row"
  },

  inlineFullWidth: {
    display: "flex",
    flexDirection: "row",
    width:"100%",
    justifyContent:"space-between",
    lineHeight:"1.8px"
  },
  
  
  inline1: {
    display: "flex",
    flexDirection: "row",
    width:"205",
    height:"52", 
    margin: 60,
    align: "left",
    width:"50%"
    
  },
  inline2: {
    display: "flex",
    flexDirection: "row",
    color: megaColor, 
    fontSize:13, 
    margin: 50,
    justifyContent:"right",
    width:"50%"
  },
  table1: { 
    display: "table", 
    width: "auto", 
    borderCollapse: "collapse",
    width: "100%",
    border:"0px",
    borderColor: "red",
  }, 
  table: { 
    borderCollapse: "collapse",
    width: "100%",
    borderColor: BORDER_COLOR,
    borderWidth: "1px 1px 1px 1px",
    borderRadius: "15px",
    backgroundColor: "#e3eed1",
    marginTop:10,
    
  }, 

  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  
  tableCol1left: { 
    width: COL1_WIDTH + '%', 
    borderBottom: "1px solid #cfe0e8",
    borderRight: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",
    color: "#00628B",
    paddingTop: 15,

  },
  
  tableCol1bottom: { 
    width: COL1_WIDTH + '%', 
    borderRight: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",
    color: "#00628B",
    paddingTop: 15,

  },   
  tableCol1right: { 
    width: COLN_WIDTH + "%", 
    borderBottom: "1px solid #cfe0e8",
    borderLeft: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",

  },

  tableCol1rightbottom:{
    width: COLN_WIDTH + "%", 
    borderLeft: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",

  },

  tableCol1center:{
    width: COLN_WIDTH + "%", 
    borderBottom: "1px solid #cfe0e8",
    textAlign: "center",
    fontSize: "13px",
    paddingTop: 8,

  },
  
  tableCol1centerbottom:{
    width: COLN_WIDTH + "%", 
    textAlign: "center",
    fontSize: "15px",
   
  },

  
  tableCol1leftderog: { 
    width: COL2_WIDTH + '%', 
    borderBottom: "1px solid #cfe0e8",
    borderRight: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",
    color: "#00628B",
    paddingTop: 15,

  },
  
  tableCol1bottomderog: { 
    width: COL2_WIDTH + '%', 
    borderRight: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",
    color: "#00628B",
    paddingTop: 10,

  },   
  tableCol1rightderog: { 
    width: COLN1_WIDTH + "%", 
    borderBottom: "1px solid #cfe0e8",
    borderLeft: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",

  },

  tableCol1rightbottomderog:{
    width: COLN1_WIDTH + "%", 
    borderLeft: "1px solid #cfe0e8",
    textAlign: "left",
    fontSize: "13px",

  },

  tableCol1centerderog:{
    width: COLN1_WIDTH + "%", 
    borderBottom: "1px solid #cfe0e8",
    textAlign: "center",
    fontSize: "13px",
    padding: 4,

  },
  
  tableCol1centerbottomderog:{
    width: COLN1_WIDTH + "%", 
    textAlign: "center",
    fontSize: "13px",
    padding: 4,
   
  },


  tableCell: { 
    margin:8,
    fontSize: "9px" 
  },

  tableCellLeftAligned: { 
    fontSize: "9px",
    marginTop:10,
    marginBottom:8
  },
  tableCellAmount: { 
    marginTop: 10,
    fontSize: "10px",
    textAlign:"right",
    paddingRight:"10px"
  },
 

  tableCellTrailingScore: { 
  width: "60px",
  height: "20px",
   borderRadius: "35px",
   backgroundColor: reportsDetails.trailingDayColorCode,
   textAlign: "center",
  paddingTop: "10px",
  paddingBottom:"23px",
  alignItem: "center",
  fontSize: "12px",
  },

  tableCellThreeToSixMonthsScore: { 
    width: "60px",
    height: "20px",
     borderRadius: "35px",
     backgroundColor: reportsDetails.threeToSixmonthsPaymentColorCode,
     textAlign: "center",
    paddingTop: "10px",
    paddingBottom:"23px",
    alignItem: "center",
    fontSize: "12px",
    },

    tableCellDbt: { 
    color: "#4caf50", 
    textAlign: "center",
    paddingTop:"10px",
    fontSize: "13px",
       
},

tableCellCautionaryScore: { 
    width: "60px",
    height: "20px",
     borderRadius: "35px",
     backgroundColor: reportsDetails.cautionaryUccColorCode,
     textAlign: "center",
    paddingTop: "10px",
    paddingBottom:"23px",
    alignItem: "center",
    fontSize: "12px",
    },
  
    tableCellTaxLiensJudgScore: { 
      width: "60px",
      height: "20px",
       borderRadius: "35px",
       backgroundColor: reportsDetails.taxLiensAndJudgementColorCode,
       textAlign: "center",
      paddingTop: "10px",
      paddingBottom:"23px",
      alignItem: "center",
      fontSize: "12px",
      },

      tableCellCivilSuits: { 
        width: "60px",
        height: "20px",
         borderRadius: "35px",
         backgroundColor: reportsDetails.civilSuitsColorCode,
         textAlign: "center",
        paddingTop: "10px",
        paddingBottom:"23px",
        alignItem: "center",
        fontSize: "12px",
        },

        tableCellCorporationFilingScore: { 
            width: "60px",
            height: "20px",
             borderRadius: "35px",
             backgroundColor: reportsDetails.corporationFilingScoreColorCode,
             textAlign: "center",
            paddingTop: "10px",
            paddingBottom:"23px",
            alignItem: "center",
            fontSize: "12px",
            },
  
  tableCellCenter:{
    align: "center", 
    paddingLeft: "32%",
    paddingTop:"4px",
   
  },
  table1: {
    borderCollapse: "collapse",
    width: "100%",
    
  },
  card: {
    width: "100%",
    height: "auto",
    borderRadius: "15px",
    backgroundColor: "#e3eed1",
     borderStyle: "outset",
     borderWidth: "1px 1px 1px 1px",
     borderColor: "#cfe0e8",
    
  },
  
  container: {
    padding: "13px 13px 13px",
  },

  row:{
    borderBottom: "1pt solid #cfe0e8",
    paddingTop: "5px",
    display: "flex", 
    flexDirection: "row",
  },
  rowAutoHeight:{
    borderBottom: "1pt solid #cfe0e8",
    paddingTop: "5px",
    display: "flex", 
    flexDirection: "row",
    height:"auto"
  },
  commentaries: {
    fontSize: "9px",
    lineHeight: "1.8px",
    color: "#757D75",

  },
  card1: {
    width: "70px",
    height: "40px",
     borderRadius: "35px",
     backgroundColor: reportsDetails.derogColorCode,
     textAlign: "center",
    paddingTop: "15px",
    paddingBottom:"25px",
    alignItem: "center",
    fontSize: "15px",

  },
  inline3: {
    display: "flex",
    flexDirection: "row",
    width: "70px",
    height: "40px",
     borderRadius: "35px",
     backgroundColor: reportsDetails.creditScoreColorCode,
     textAlign: "center",
    paddingTop: "15px",
    paddingBottom:"25px",
    alignItem: "center",
    fontSize: "15px",
  },

  inline4: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "red",
    borderColor:"black",
    color:"black",
    fontSize: "10px",
    borderRadius: "3px",
    textAlign:"center",
    padding:"3px",
    // paddingTop: "6px",
    // paddingLeft:"35px",

  },

  inline5: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ff9800",
    borderColor:"black",
    color:"black",
    fontSize: "10px",
    borderRadius: "3px",
    textAlign:"center",
    padding:"3px",
    // paddingTop: "6px",
    // paddingLeft:"35px",
  
  },

  inline6: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffdf00",
    borderColor:"black",
    color:"black",
    fontSize: "10px",
    borderRadius: "3px",
    textAlign:"center",
    padding:"3px",
    // paddingTop: "6px",
    // paddingLeft:"35px",
  
  },

  inline7: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#32cd32",
    borderColor:"black",
    color:"black",
    fontSize: "10px",
    borderRadius: "3px",
    textAlign:"center",
    padding:"3px",
    // paddingTop: "6px",
    // paddingLeft:"35px",
   
  },

  inline8: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#4caf50",
    borderColor:"black",
    color:"black",
    fontSize: "10px",
    borderRadius: "3px",
    textAlign:"center",
    padding:"3px",
    // paddingTop: "6px",
    // paddingLeft:"35px",
  
  },

  inlineButton:{
    display: "flex",
    flexDirection: "row",

  },

  inlineFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    marginTop:15
  },

  inlineRange: {
    display: "flex",
    flexDirection: "row",
    height:"17px",
    marginTop:10
    
  },
  inlineRange1: {
    display: "flex",
    flexDirection: "column",
    marginTop:10,
    
    
  },
  inlineLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
   position: 'absolute',
   top: 30,
   justifyContent:"space-between",
   left:"35px",
   right:"35px"
  },

  inlineFooter: {
    display: "flex",
    flexDirection: "row",
   borderTop:"1px solid #e3eed1",
   position: 'absolute',
   bottom: 30,
   color: '#828081',
   justifyContent:"space-between",
   left:"35px",
   right:"35px"
  },

  inlineFooter1: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
  },

  inlineFooter2: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
  },
  inlineFooter3: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
  },
  inlineFooter4: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
  },
  inlineFooter5: {
    display: "flex",
    flexDirection: "row",
   fontSize:"10px",
   color: '#828081',
  },

  mainContainer:{
    width:"100%",
    backgroundColor:"#e3eed1",
    padding:"20px",
    borderRadius:"10px"
  },
  container1: {
    padding: "13px 13px 13px",
    backgroundColor:"#f4f9ee",
    width:"60%",
    height:"auto",
    borderLeft:"1px solid #707070",
    borderTop:"1px solid #707070",
    
  },
  container2: {
    padding: "13px 13px 13px",
    backgroundColor:"#f4f9ee",
    width:"40%",
    borderLeft:"1px solid #707070",
    borderRight:"1px solid #707070",
    borderTop:"1px solid #707070",
    
  },
  container3: {
    padding: "13px 13px 13px",
    backgroundColor:"#f4f9ee",
    borderLeft:"1px solid #707070",
    borderBottom:"1px solid #707070",
    borderTop:"1px solid #707070",
    width:"60%",
  },
  container4: {
    padding: "13px 13px 13px",
    backgroundColor:"#f4f9ee",
    borderLeft:"1px solid #707070",
    borderRight:"1px solid #707070",
    borderBottom:"1px solid #707070",
    borderTop:"1px solid #707070",
    width:"40%",
  },

  box:{
    border:"1px solid #D3D3D3",
    width:"100%",
    height:"230px",
    marginTop:20,
  
  },  
  inlineCreditReport: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: reportsDetails.creditScoreColorCode,
    borderColor:"black",
    color:"white",
    fontSize: "13px",
    borderRadius: "3px",
    textAlign:"center",
    width:"50px",
    paddingTop:"8px",
  },

  inlinePrincipalReport: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#32cd32",
    borderColor:"black",
    color:"white",
    fontSize: "13px",
    borderRadius: "3px",
    textAlign:"center",
    justifyContent:"center",
    width:"50px",
    paddingTop:"8px",
    marginBottom:5


  },

  inlineDerogReport: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: reportsDetails.derogColorCode,
    borderColor:"black",
    color:"white",
    fontSize: "13px",
    borderRadius: "3px",
    textAlign:"center",
    width:"50px",
    paddingTop:"8px",

  },
  
});

const [companyAddress, setCompanyAddress] = React.useState("");
const [legalName, setLegalName] = React.useState("");
const [legalFormOfEntity, setLegalFormOfEntity] = React.useState("");
const [entityType, setEntityType] = React.useState("");
const [primaryNaicsCode, setPrimaryNaicsCode] = React.useState("");
const [noOfLocations, setNoOfLocations] = React.useState("");
const [yearStarted, setYearStarted] = React.useState("");
const [primaryIndustry, setPrimaryIndustry] = React.useState("");
const [websiteUrl, setWebsiteUrl] = React.useState("");
const [ofac, setOfac] = React.useState("");
const [legalEntityName, setLegalEntityName] = React.useState("");
const [postalCode, setPostalCode] = React.useState("");
const [country, setCountry] = React.useState("");
const [state, setState] = React.useState("");
const [address, setAddress] = React.useState("");
const [city, setCity] = React.useState("");
const [corporationFilingName, setCorporationFilingName] = React.useState("");
const [nameType, setNameType] = React.useState("");
const [corporationFilingAddress, setCorporationFilingAddress] = React.useState("");
const [corporationFilingAddressType, setCorporationFilingAddressType] = React.useState("");
const [corporationFilingStatus, setCorporationFilingStatus] = React.useState("");
const [filingDate, setFilingDate] = React.useState("");

const [incorporationState, setIncorporationState] = React.useState("");
const [incorporationDate, setIncorporationDate] = React.useState("");
const [registeredAgentAddress, setRegisteredAgentAddress] = React.useState("");
const [registrationType, setRegistrationType] = React.useState("");

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [taxlienList, setTaxLienList]=React.useState(taxLienDetailList);
  const [dataStatus, setDataStatus] = React.useState(false);
  const [showLiensList, setShowLiensList] = React.useState(false);
  const [showJudgementList, setShowJudgementList] = React.useState(false);
  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [showBankruptcyList,setShowBankruptcyList] = React.useState(false);
  const [showCautionaryUccList,setShowCautionaryUccList] = React.useState(false);
  const [showGlobalWatchList,setShowGlobalWatchList] = React.useState(false);
  const [showEvictionList,setShowEvictionList] = React.useState(false);


  const [noDataAvailableLiens,setNoDataAvailableLiens] = React.useState(false);
  const [noDataAvailableJudgement,setNoDataAvailableJudgement] = React.useState(false);
  const [noDataAvailableSuits,setNoDataAvailableSuits] = React.useState(false);
  const [noDataAvailableBankruptcy,setNoDataAvailableBankruptcy] = React.useState(false);
  const [noDataAvailableCautionaryUcc,setNoDataAvailableCautionaryUcc] = React.useState(false);
  const [noDataAvailableGlobalWatchList,setNoDataAvailableGlobalWatchList] = React.useState(false);
  const [noDataAvailableEviction,setNoDataAvailableEviction] = React.useState(false);


  const[showCommentaryList,setShowCommentaryList]=React.useState(false);
  const[noCommentariesAvailable,setNoCommentariesAvailable]=React.useState(false);

  const[viabilityScoreGaugeData,setViabilityScoreGaugeData] = React.useState(0);
  const[showRadialGauge,setShowRadialGauge]=React.useState(false);
  const[viabilityScore,setViabilityScore]=React.useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


React.useEffect(() => {

  if(reportsDetails.companyAddress==null || reportsDetails.companyAddress=="undefined"){
    setCompanyAddress("NA");
  }else{
    setCompanyAddress(reportsDetails.companyAddress);
  }
  
  if(reportsDetails.legalName==null || reportsDetails.legalName=="undefined"){
    setLegalName("NA");
  }else{
    setLegalName(reportsDetails.legalName);
  }

  if(reportsDetails.legalFormOfEntity==null || reportsDetails.legalFormOfEntity=="undefined"){
    setLegalFormOfEntity("NA");
  }else{
    setLegalFormOfEntity(reportsDetails.legalFormOfEntity);
  }


  if(reportsDetails.entityType==null || reportsDetails.entityType=="undefined"){
    setEntityType("NA");
  }else{
    setEntityType(reportsDetails.entityType);
  }


  if(reportsDetails.primaryNaicsCode==null || reportsDetails.primaryNaicsCode=="undefined"){
    setPrimaryNaicsCode("NA");
  }else{
    setPrimaryNaicsCode(reportsDetails.primaryNaicsCode);
  }


  if(reportsDetails.noOfLocations==null || reportsDetails.noOfLocations=="undefined"){
    setNoOfLocations("NA");
  }else{
    setNoOfLocations(reportsDetails.noOfLocations);
  }


  if(reportsDetails.yearStarted==null || reportsDetails.yearStarted=="undefined"){
    setYearStarted("NA");
  }else{
    setYearStarted(reportsDetails.yearStarted);
  }


  if(reportsDetails.primaryIndustry==null || reportsDetails.primaryIndustr=="undefined"){
    setPrimaryIndustry("NA");
  }else{
    setPrimaryIndustry(reportsDetails.primaryIndustry);
  }


  if(reportsDetails.websiteUrl==null || reportsDetails.websiteUrl=="undefined"){
    setWebsiteUrl("NA");
  }else{
    setWebsiteUrl(reportsDetails.websiteUrl);
  }


  if(reportsDetails.ofac==null || reportsDetails.ofac=="undefined"){
    setOfac("NA");
  }else{
    setOfac(reportsDetails.ofac);
  }


  if(reportsDetails.legalEntityName==null || reportsDetails.legalEntityName=="undefined"){
    setLegalEntityName("NA");
  }else{
    setLegalEntityName(reportsDetails.legalEntityName);
  }


  if(reportsDetails.postalCode==null || reportsDetails.postalCode=="undefined"){
    setPostalCode("NA");
  }else{
    setPostalCode(reportsDetails.postalCode);
  }


  if(reportsDetails.country==null || reportsDetails.country=="undefined"){
    setCountry("NA");
  }else{
    setCountry(reportsDetails.country);
  }


  if(reportsDetails.state==null || reportsDetails.state=="undefined"){
    setState("NA");
  }else{
    setState(reportsDetails.state);
  }

  
  if(reportsDetails.address==null || reportsDetails.address=="undefined"){
    setAddress("NA");
  }else{
    setAddress(reportsDetails.address);
  }


  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }


  if(reportsDetails.corporationFilingName==null || reportsDetails.corporationFilingName=="undefined"){
    setCorporationFilingName("NA");
  }else{
    setCorporationFilingName(reportsDetails.corporationFilingName);
  }


  if(reportsDetails.nameType==null || reportsDetails.nameType=="undefined"){
    setNameType("NA");
  }else{
    setNameType(reportsDetails.nameType);
  }


  if(reportsDetails.corporationFilingAddress==null || reportsDetails.corporationFilingAddress=="undefined"){
    setCorporationFilingAddress("NA");
  }else{
    setCorporationFilingAddress(reportsDetails.corporationFilingAddress);
  }


  if(reportsDetails.corporationFilingAddressType==null || reportsDetails.corporationFilingAddressType=="undefined"){
    setCorporationFilingAddressType("NA");
  }else{
    setCorporationFilingAddressType(reportsDetails.corporationFilingAddressType);
  }


  if(reportsDetails.corporationFilingStatus==null || reportsDetails.corporationFilingStatus=="undefined"){
    setCorporationFilingStatus("NA");
  }else{
    setCorporationFilingStatus(reportsDetails.corporationFilingStatus);
  }


  if(reportsDetails.filingDate==null || reportsDetails.filingDate=="undefined"){
    setFilingDate("NA");
  }else{
    setFilingDate(reportsDetails.filingDate);
  }


  if(reportsDetails.incorporationState==null || reportsDetails.incorporationState=="undefined"){
    setIncorporationState("NA");
  }else{
    setIncorporationState(reportsDetails.incorporationState);
  }


  if(reportsDetails.incorporationDate==null || reportsDetails.incorporationDate=="undefined"){
    setIncorporationDate("NA");
  }else{
    setIncorporationDate(reportsDetails.incorporationDate);
  }


  if(reportsDetails.registeredAgentAddress==null || reportsDetails.registeredAgentAddress=="undefined"){
    setRegisteredAgentAddress("NA");
  }else{
    setRegisteredAgentAddress(reportsDetails.registeredAgentAddress);
  }


  if(reportsDetails.registrationType==null || reportsDetails.registrationType=="undefined"){
    setRegistrationType("NA");
  }else{
    setRegistrationType(reportsDetails.registrationType);
  }

  
  if(reportsDetails.city==null || reportsDetails.city=="undefined"){
    setCity("NA");
  }else{
    setCity(reportsDetails.city);
  }
 
  if(reportsDetails.viabilityScore==null || reportsDetails.viabilityScore=="undefined"){
    // setViabilityScoreGaugeData(0);
    setViabilityScore(0);
    setShowRadialGauge(false);
  }else{
    // console.log("viabilityScoreGaugeData"+reportsDetails.viabilityScoreGaugeData);
    // setViabilityScoreGaugeData(reportsDetails.viabilityScoreGaugeData);
    setViabilityScore(reportsDetails.viabilityScore)
    setShowRadialGauge(true);
  }

  if(reportsDetails.viabilityScoreGaugeData==null || reportsDetails.viabilityScoreGaugeData=="undefined"){
    setViabilityScoreGaugeData(0);
  }else{
    // console.log("viabilityScoreGaugeData"+reportsDetails.viabilityScoreGaugeData);
    setViabilityScoreGaugeData(reportsDetails.viabilityScoreGaugeData);

  }


  if(reportsDetails.taxLienDetailList!=null){
  if(reportsDetails.taxLienDetailList.length===0){
    setShowLiensList(false);
    setNoDataAvailableLiens(true);
   
  }else{
    setShowLiensList(true);
    setNoDataAvailableLiens(false);
  }
  }

  if(reportsDetails.judgementDetailList!=null){
  if(reportsDetails.judgementDetailList.length===0){
    setShowJudgementList(false);
    setNoDataAvailableJudgement(true);
  }else{
    setShowJudgementList(true);
    setNoDataAvailableJudgement(false);
  
  }
}

if(reportsDetails.suitsList!=null){
  if(reportsDetails.suitsList.length===0){
    setShowSuitsList(false);
    setNoDataAvailableSuits(true);
  }else{
    setShowSuitsList(true);
    setNoDataAvailableSuits(false);
  
  }
  
}
if(reportsDetails.cautionaryUccDetailsList!=null){
  if(reportsDetails.cautionaryUccDetailsList.length===0){
    setShowCautionaryUccList(false);
    setNoDataAvailableCautionaryUcc(true);
  }else{
    setShowCautionaryUccList(true);
    setNoDataAvailableCautionaryUcc(false);
  
  }
}

if(reportsDetails.watchListRecordList!=null){
  if(reportsDetails.watchListRecordList.length===0){
    setShowGlobalWatchList(false);
    setNoDataAvailableGlobalWatchList(true);
  }else{
    setShowGlobalWatchList(true);
    setNoDataAvailableGlobalWatchList(false);
  
  }
}

if(reportsDetails.tloEvictionList!=null){
  if(reportsDetails.tloEvictionList.length===0){
    setShowEvictionList(false);
    setNoDataAvailableEviction(true);
  }else{
    setShowEvictionList(true);
    setNoDataAvailableEviction(false);
  
  }
}

if(reportsDetails.commentaryList!=null){
  if(reportsDetails.commentaryList.length===0){
    setShowCommentaryList(false);
    setNoCommentariesAvailable(true);
  }else{
    setShowCommentaryList(true);
    setNoCommentariesAvailable(false);
  
  }
}
    }, [dataStatus,reportsDetails]);


    // var score=[reportsDetails.viabilityScoreGaugeData];
    var score=[viabilityScoreGaugeData];
    var color="";
    if(viabilityScoreGaugeData>0 && viabilityScoreGaugeData<=20){
      color="red"
    }else if(viabilityScoreGaugeData>21 && viabilityScoreGaugeData<=30){
      color="#ff9800"
    }else if(viabilityScoreGaugeData>31 && viabilityScoreGaugeData<=50){
      color="#ffdf00"
    }else if(viabilityScoreGaugeData>51 && viabilityScoreGaugeData<=70){
      color="#32cd32"
    }else if(viabilityScoreGaugeData>71 && viabilityScoreGaugeData<=100){
      color="#4caf50"
    }

    console.log("score"+score);

    const ChartJsImage = require('chartjs-to-image');
    const myChart = new ChartJsImage();
    myChart.setConfig({
      type: 'radialGauge',
      data: {
        labels: ["Metrics"],
        datasets: [
         {
          data: [score],
          backgroundColor: [color],
          borderWidth: 0,
          label: "Score",
          borderJoinStyle:'round'
         }
        ]
       },
       options: {
        responsive: true,
        legend: {},
        centerPercentage: 80
       }
    
     
    });
    
    myChart.toFile('/tmp/mychart.png');
    
    const dataUrl =  myChart.toDataUrl();

return(
<Document>
    <Page size="A4" style={styles.body} >
   {/* <Image 
   style={styles.image1}
   src={require("assets/img/tenanttracker_11803_logo_500.png")} /> */}

<View style={styles.inlineLogo}  fixed>
        <View  style={styles.inline}>
      <Image 
   style={styles.image2}
   src={require("assets/img/tenanttracker_11803_logo_500.png")} />
</View>
<View style={styles.inline}>
<Image 
   style={styles.image3}
   src={require("assets/img/tenanttracker_11803_logo_500.png")} />
   </View>
      </View>


    {/* <Text style={styles.heading}>REPORTS</Text> */}
    <Text style={{ color: megaColor, textAlign: 'center', margin: 35, fontSize:25 ,fontWeight: 800}} >
   {tenantName}
      </Text>
<Image
        style={styles.image}
        src={require("assets/img/property2.jpeg")}
      
      />
   
   <View style={styles.inline}>
   <Image
        // style={styles.image2}
        style={styles.inline1}
        src={require("assets/img/mega_11803_logo_notag_500.jpg")}></Image>
      
   <Text style={styles.inline2} >Megalytics®
        4809 North Ravenswood Avenue
        Suite 215
        Chicago, IL 60640
        Phone: 312-818-1930
        Email: info@megalytics.net</Text>
        
              </View>
       
     
 <View break>

       <View style={styles.box}>
          <Text style={{color: "#00628B",fontSize:"13px"}}>Megalytics® Viability Score</Text>
       

    <View style={styles.inline}>
          <View style={styles.inline}> 
          {showRadialGauge&& <Image
        style={styles.image1}
        src={dataUrl}
      
      />}
        </View>
       <View style={{display:"flex",flexDirection:"row",paddingLeft:"25%",marginTop:"10px"}}>
        <View style={styles.inlineRange1}>
         
          <Text style={{color:"black",fontSize: "10px",textAlign:"center",padding:"5px",marginTop:7}}>High Risk</Text>
          {/* <Text style={{color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}></Text>
          <Text style={{color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}></Text>
          <Text style={{color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}></Text> */}
          <Text style={{color:"black",fontSize: "10px",textAlign:"center",padding:"5px",marginTop:91}}>Low Risk</Text>
        </View>

        <View style={styles.inlineRange1}>
         
         <Text style={{ backgroundColor: "red",borderColor:"black",color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}>0-20</Text>
         <Text style={{ backgroundColor: "#ff9800",borderColor:"black",color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}>21-30</Text>
         <Text style={{ backgroundColor: "#ffdf00",borderColor:"black",color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}>31-50</Text>
         <Text style={{ backgroundColor: "#32cd32",borderColor:"black",color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}>51-70</Text>
         <Text style={{ backgroundColor: "#4caf50",borderColor:"black",color:"white",fontSize: "10px",borderRadius: "3px",textAlign:"center",padding:"5px",marginTop:7}}>71-100</Text>
       </View>
        </View>
      </View>
     
       </View>

       <View style={styles.box}>
       <Text style={{color: "#000000",fontSize:"11px",borderBottom:"1px solid #D3D3D3",textAlign:"right",lineHeight:"1.8px",paddingRight:"15px",paddingTop:"6px"}}>Component Indicator</Text>

       <Text style={{color: "#ff9800",fontSize:"12px",lineHeight:"1.8px",marginTop:10}}>Quantitative Indicators</Text>
          <View style={styles.inlineFullWidth}>
                <View style={{marginTop:10}}>
                    <Text style={{color: "#000000",fontSize:"13px",display:"flex",flexDirection:"row",textAlign:"left"}}>{tenantName} Credit Report</Text>
                </View>
                 <View style={{display:"flex",flexDirection:"row",justifyContent:"right",paddingRight:"40px"}}>
                   <Text style={styles.inlineCreditReport}>{reportsDetails.creditScore}</Text>
                 </View>
                 
          </View>
             <Text style={{color: "#ff9800",fontSize:"13px",lineHeight:"1.8px",marginTop:10}}>Qualitative Indicators</Text>

             <View style={styles.inlineFullWidth}>
                <View style={{marginTop:10}}>
                <Text style={{color: "#000000",fontSize:"13px"}}>{tenantName} Background Check</Text>
                </View>
                 <View style={{display:"flex",flexDirection:"row",justifyContent:"right",paddingRight:"40px"}}>
                   <Text style={styles.inlineDerogReport}>{reportsDetails.derogScore}</Text>
                 </View>
                 
          </View>
            
             {/* <View style={styles.inlineFullWidth}>
                <View style={{marginTop:10}}>
                <Text style={{color: "#000000",fontSize:"13px"}}>{tenantName} Principal/Owner Score</Text>
                </View>
                 <View style={{display:"flex",flexDirection:"row",justifyContent:"right",paddingRight:"40px"}}>
                   <Text style={styles.inlinePrincipalReport}>301</Text>
                 </View>
                 
          </View> */}

        
       </View>
       
     </View>


    <View break>
 <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900,lineHeight:"1.8px"}} >
   COMPANY SUMMARY
      </Text>

      <View style={styles.card}> 
        <View style={styles.container}> 
          <View style={styles.table1}> 

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Name</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "107px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{tenantName}</Text>
    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Legal Name</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "80px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{legalName}</Text>
    </View>

    <View style={styles.rowAutoHeight}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Primary Industry</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "64px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px", width: "450px"}}>{primaryIndustry}</Text>
    </View>
         
    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Primary NAICS Code</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "40px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{primaryNaicsCode}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "100px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Entity Type</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "60px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{entityType}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Year Started</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "79px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{yearStarted}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "111px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>OFAC</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "83px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{ofac}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Website URL</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "75px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{websiteUrl}</Text>
    </View>

       </View>
        </View>   
      </View>    
      </View>

      <View style={{marginTop: 27}}>
      <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900,lineHeight:"1.8px"}}>
   LEGAL ENTITY
      </Text>

      <View style={styles.card}> 
        <View style={styles.container}> 
          <View style={styles.table1}> 

          <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Name</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "107px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{tenantName}</Text>
    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Address</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "98px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{address}</Text>
    </View>
         
    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Country</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "100px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{country}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "120px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>State</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "83px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{state}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>City</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "118px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{city}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "85px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Zip / Postal Code</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "31px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{postalCode}</Text>

    </View>

       </View>
        </View>   
      </View>    
      </View>

      <View style={{marginTop: 27}}> 
      <Text style={{ color: "#00628B", textAlign: 'left', fontSize:16 ,fontWeight: 900,lineHeight:"1.8px"}}>
   CORPORATION FILINGS
      </Text>

      <View style={styles.card}> 
        <View style={styles.container}> 
          <View style={styles.table1}> 


          <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Name</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "107px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{corporationFilingName}</Text>
    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Address</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "98px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{corporationFilingAddress}</Text>
    </View>
         
    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Name Type</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "85px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{nameType}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "122px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Address Type</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "48px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{corporationFilingAddressType}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Status</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "107px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{corporationFilingStatus}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "108px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Filing Date</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "59px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{filingDate}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Incorporation State</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "50px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{incorporationState}</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "121px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Incorporation Date</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "25px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{incorporationDate}</Text>

    </View>

    <View style={styles.row}>
         <Text style={{fontSize:"10px" ,fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Registration Agent</Text>
         <Text style={{fontSize:"10px" ,paddingLeft: "53px",fontWeight: 900,display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>{registrationType}</Text>
    </View>

       </View>
        </View>   
      </View>    
     </View>

 

  
      <Text style={{ color: "#00628B", textAlign: 'left', paddingLeft: "8px",fontSize:16 ,fontWeight: 900, lineHeight:"1.8px"}} break>
   COMPANY CREDIT REPORT
      </Text>
      <View style={styles.inlineFlex}>
        <View style={styles.inline}>
          <Text style={styles.inline3}>{reportsDetails.creditScore}</Text>
        </View>
      
        <View style={styles.inlineRange}>
          <Text style={styles.inline4}>0-100</Text>
          <Text style={styles.inline5}>101-200</Text>
          <Text style={styles.inline6}>201-300</Text>
          <Text style={styles.inline7}>301-400</Text>
          <Text style={styles.inline8}>401-500</Text>
        </View>
      </View>
 <View>

<Text style={{ paddingTop: "15px",fontSize: "11px",textAlign: 'justify'}}>The Risk Score predicts the likelihood that a business's payment performance will become
    seriously delinquent, defined as 90-plus days beyond terms within the next 12 months or that
    the business will go bankrupt</Text>

 </View>
    <View style={{marginTop:20}}>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1left}> 
            <Text style={styles.tableCell}>Trailing 90 Days payment behaviour</Text> 
          </View> 
         
          <View style={styles.tableCol1center}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellTrailingScore}>{reportsDetails.trailingDayScore}</Text> 
          </View> 
          </View>

          <View style={styles.tableCol1right}>
            <Text style={styles.tableCell}>{reportsDetails.trailingDayCommentary}</Text> 
          </View>
        
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1left}> 
            <Text style={styles.tableCell}>3-6 Months Payment Trends</Text> 
          </View> 
          <View style={styles.tableCol1center}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellThreeToSixMonthsScore}>{reportsDetails.threeToSixmonthsPaymentScore}</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1right}>
            <Text style={styles.tableCell}>{reportsDetails.threeToSixmonthsPaymentCommentary}</Text> 
          </View>
      
        </View>    

         <View style={styles.tableRow}> 
          <View style={styles.tableCol1bottom}> 
            <Text style={styles.tableCell}>Industry Benchmark</Text> 
          </View> 
          <View style={styles.tableCol1centerbottom}> 
          <View >
            <Text style={styles.tableCellDbt}>{reportsDetails.dbtScore} DBT vs {reportsDetails.industryDbtScore} DBT</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1rightbottom}>
            <Text style={styles.tableCell}>{reportsDetails.industryBenchmarkCommentary}</Text> 
          </View>
      
        </View>        
      </View>    
      </View>
     <View style={{marginTop:20}}>
      <Text style={{fontSize:"10px",fontWeight:"bold"}}>*{reportsDetails.yearsInBusinessCommentary}</Text>
      </View>

      <View style={{marginTop:20}}>
      <Text style={{fontSize:"13px", lineHeight:"1.8px"}}>Commentaries</Text>

      {showCommentaryList&&reportsDetails.commentaryList.map(commentary=>(
      <View style={styles.inline}>

{commentary.positiveOrNegative==='Positive' ? <Image
          style={styles.arrow}
          src={require("assets/img/arrowupgreen.png")}

          /> : 
          commentary.positiveOrNegative==='Negative'?<Image
          style={styles.arrow}
          src={require("assets/img/arrowdownred.png")}

          />:
          <Image
          style={styles.arrow}
          src={require("assets/img/arrowforwardorange.png")}

          />}
                  
          
      <Text style={styles.commentaries}>{commentary.commentaryText}</Text>
          </View> 
       
          ))}
      </View>
      <Text style={{ color: "#00628B", textAlign: 'left',paddingLeft: "8px", fontSize:16 ,fontWeight: 900, lineHeight:"1.8px"}} break>
   COMPANY DEROGATORY PUBLIC FILINGS
      </Text>
      <View>
          <Text style={styles.card1}>{reportsDetails.derogScore}</Text>
        </View>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1leftderog}> 
            <Text style={styles.tableCell}>Cautionary UCC</Text> 
          </View> 
         
          <View style={styles.tableCol1centerderog}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellCautionaryScore}>{reportsDetails.cautionaryUccScore}</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1rightderog}>
            <Text style={styles.tableCell}>{reportsDetails.cautionaryUccCommentary}</Text> 
          </View>
        
        </View> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1leftderog}> 
            <Text style={styles.tableCell}>Taxliens & Judgement</Text> 
          </View> 
          <View style={styles.tableCol1centerderog}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellTaxLiensJudgScore}>{reportsDetails.taxLiensAndJudgementScore}</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1rightderog}>
            <Text style={styles.tableCell}>{reportsDetails.taxLiensAndJudgementCommentary}</Text> 
          </View>
      
        </View>    

        <View style={styles.tableRow}> 
          <View style={styles.tableCol1leftderog}> 
            <Text style={styles.tableCell}>Civil Suits</Text> 
          </View> 
          <View style={styles.tableCol1centerderog}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellCivilSuits}>{reportsDetails.civilSuitsScore}</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1rightderog}>
            <Text style={styles.tableCell}>{reportsDetails.civilSuitsCommentary}</Text> 
          </View>
      
        </View>    

        <View style={styles.tableRow}> 
          <View style={styles.tableCol1bottomderog}> 
            <Text style={styles.tableCell}>Corporation Filing Status</Text> 
          </View> 
          <View style={styles.tableCol1centerbottomderog}> 
          <View style={styles.tableCellCenter}>
            <Text style={styles.tableCellCorporationFilingScore}>{reportsDetails.corporationFilingScore}</Text> 
          </View> 
          </View>
          <View style={styles.tableCol1rightbottomderog}>
            <Text style={styles.tableCell}>{reportsDetails.corporationFilingCommentary}</Text> 
          </View>
      
        </View>     
      </View>  


      <View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Active Cautionary UCC</Text>
        </View>

        {showCautionaryUccList&&  <View style={{marginTop:20}}>
       
       <View style={styles.table3}> 

        <View style={styles.container}>
        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Filing Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Jurisdiction</Text> 
          </View> 
        </View>
        {showCautionaryUccList&&reportsDetails.cautionaryUccDetailsList!=""&&reportsDetails.cautionaryUccDetailsList.map(cautionaryUcc=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(cautionaryUcc.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCell}>{cautionaryUcc.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{cautionaryUcc.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {cautionaryUcc.jurisdiction}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}

{noDataAvailableCautionaryUcc&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}


      <View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Active Tax Liens</Text>
        </View>

        {showLiensList&& <View style={{marginTop:20}}>
      
        <View style={styles.table3}> 

        <View style={styles.container}>

        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Filing Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Amount</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellHeader}>Jurisdiction</Text> 
          </View> 
        </View>
        {showLiensList&&reportsDetails.taxLienDetailList!=""&&reportsDetails.taxLienDetailList.map(liens=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(liens.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellLeftAligned}>{liens.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{liens.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellAmount}>${liens.amount}</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {liens.jurisdiction}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}
{noDataAvailableLiens&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}

      <View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Active Judgements</Text>
        </View>

        {showJudgementList&&  <View style={{marginTop:20}}>
       
       <View style={styles.table3}> 

        <View style={styles.container}>
        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Case Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Amount</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellHeader}>Plaintiff</Text> 
          </View> 
        </View>
        {showJudgementList&&reportsDetails.judgementDetailList!=""&&reportsDetails.judgementDetailList.map(judgment=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(judgment.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCell}>{judgment.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{judgment.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellAmount}>{judgment.amount}</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {judgment.plaintiff}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}

{noDataAvailableJudgement&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}
     

<View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Active Suits</Text>
        </View>

        {showSuitsList&&  <View style={{marginTop:20}}>
       
       <View style={styles.table3}> 

        <View style={styles.container}>
        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Case Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Amount</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellHeader}>Plaintiff</Text> 
          </View> 
        </View>
        {showSuitsList&&reportsDetails.suitsList!=""&&reportsDetails.suitsList.map(judgment=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(judgment.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellLeftAligned}>{judgment.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{judgment.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellAmount}>${judgment.amount}</Text> 
          </View> 
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {judgment.plaintiff}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}

{noDataAvailableSuits&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}


<View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Global Watch List</Text>
        </View>

        {showGlobalWatchList&&  <View style={{marginTop:20}}>
       
       <View style={styles.table3}> 

        <View style={styles.container}>
        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Filing Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Jurisdiction</Text> 
          </View> 
        </View>
        {showGlobalWatchList&&reportsDetails.watchListRecordList!=""&&reportsDetails.watchListRecordList.map(watchListRecord=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(watchListRecord.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCell}>{watchListRecord.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{watchListRecord.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {watchListRecord.jurisdiction}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}

{noDataAvailableGlobalWatchList&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}


<View style={{marginTop:20}}>
        <Text style={{fontSize: "15px",fontWeight:900}}>Eviction List</Text>
        </View>

        {showEvictionList&&  <View style={{marginTop:20}}>
       
       <View style={styles.table3}> 

        <View style={styles.container}>
        <View style={styles.tableRow1}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellHeader}>Date Filed</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCellHeader}>Filing Type</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}> 
            <Text style={styles.tableCellHeader}>Filing Number</Text> 
          </View> 
          <View style={styles.tableCol1AmountHeader}> 
            <Text style={styles.tableCellHeader}>Jurisdiction</Text> 
          </View> 
        </View>
        {showEvictionList&&reportsDetails.tloEvictionList!=""&&reportsDetails.tloEvictionList.map(watchListRecord=>(
        <View style={styles.tableRow1BottomLine}> 
          <View style={styles.tableCol1DateHeader}> 
            <Text style={styles.tableCellLeftAligned}>{Moment(watchListRecord.filedDate).format('MM-DD-YYYY')}</Text> 
          </View> 
          <View style={styles.tableCol1TypeHeader}> 
            <Text style={styles.tableCell}>{watchListRecord.filingType}</Text> 
          </View> 
          <View style={styles.tableCol1NumberHeader}>
            <Text style={styles.tableCellLeftAligned}>{watchListRecord.filingNumber}</Text> 
          </View>
          <View style={styles.tableCol1JurisdictionHeader}> 
            <Text style={styles.tableCellLeftAligned}> {watchListRecord.jurisdiction}</Text> 
          </View> 
         
        </View> ))}
      
      </View>
      </View>
</View>}

{noDataAvailableEviction&&<Text style={{fontSize:"13px",marginTop:20,fontWeight:900}}>No Data Available...</Text>}


<Text style={{ color: "#00628B", textAlign: 'left', fontSize:18 ,fontWeight: 900}} break>
   Principal Profile
      </Text>
<View >
      <Text style={{ color: "#000000", textAlign: 'left',fontSize:12 ,fontWeight: 900,marginTop:20,borderBottom:"1px solid #e3eed1",marginBottom:20}} break>
      Owner/Principal: Ryan Rochefort
      </Text>

 <View style={styles.mainContainer}>
<View style={{display:"flex",flexDirection: "row",justifyContent:"space-around"}}>
      <View style={styles.container1}>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Name:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "45px",fontWeight: 900,display: "flex", flexDirection: "row"}}>RYAN ALLEN ROCHEFORT</Text>
    </View>
    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>DOB:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "50px",fontWeight: 900,display: "flex", flexDirection: "row"}}>XX/XX/1990, Born 32 Years Ago</Text>
    </View>
    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Address:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "55px",fontWeight: 900,display: "flex", flexDirection: "row",width:"480px"}}>8233 S VIRGINIA AVE, PALM BEACH GARDENS, FL 33418-6161 (PALM BEACH COUNTY)</Text>
    </View>
    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Gender:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "38px",fontWeight: 900,display: "flex", flexDirection: "row"}}>M</Text>
    </View>
    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Ethnicity:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "34px",fontWeight: 900,display: "flex", flexDirection: "row"}}>WHITE</Text>
    </View>

    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Is Sex Offender:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "3px",fontWeight: 900,display: "flex", flexDirection: "row"}}>No</Text>
    </View>
    <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Source Name:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "15px",fontWeight: 900,display: "flex", flexDirection: "row"}}>FLORIDA PALM BEACH COUNTY COURTS</Text>
    </View>
     <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row",lineHeight: "1.8px"}}>Source State:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "16px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>FL</Text>
    </View>
  
        </View>

  <View style={styles.container2}>
      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>First Name:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "12px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Middle Name:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Last Name:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "13px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Date Of Birth:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "4px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Age:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "40px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Address:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "23px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"green"}}>O</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Exact Match</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Height:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "30px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"grey"}}>X</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Not Available On Record</Text>
      </View>

      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"9px",fontColor:"black",display: "flex", flexDirection: "row"}}>Ethnicity:</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "22px",fontWeight: "500px",display: "flex", flexDirection: "row",color:"grey"}}>X</Text>
         <Text style={{fontSize:"9px",fontColor:"black" ,paddingLeft: "3px",fontWeight: "500px",display: "flex", flexDirection: "row"}}>Not Available On Record</Text>
      </View>
 </View>
  </View>
<View style={{display:"flex",flexDirection: "row",justifyContent:"space-around"}}>
  <View style={styles.container3}>

           <Text  style={{fontSize:"12px",fontColor:"black"}}>Crime Details - 03/13/2020 - FLORIDA PALM BEACH|PALM BEACH, FL</Text>
      <View style={{display: "flex", flexDirection: "row",marginTop:4}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row",width:"100px"}}>Offense &nbsp; Description1:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "12px",fontWeight: 900 ,display: "flex", flexDirection: "row",width:"300px"}}>TCATS OPERATING A MOTOR VEHICLE/USING A MOBILE HOME WITH AN EXPIRED REGISTRATION FOR MORE THAN 6 MON</Text>
      </View>
      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Case Number:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "13px",fontWeight: 900,display: "flex", flexDirection: "row"}}>502020TR036049AXXXNB</Text>
      </View>
      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Crime County:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "15px",fontWeight: 900,display: "flex", flexDirection: "row"}}>FLORIDA PALM BEACH|PALM BEACH</Text>
      </View>
      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>OffenseCode:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "16px",fontWeight: 900,display: "flex", flexDirection: "row"}}>320.07(3B)</Text>
      </View>
      <View style={{display: "flex", flexDirection: "row"}}>
         <Text style={{fontSize:"10px",fontColor:"black",display: "flex", flexDirection: "row"}}>Counts:</Text>
         <Text style={{fontSize:"10px",fontColor:"black" ,paddingLeft: "42px",fontWeight: 800 ,display: "flex", flexDirection: "row"}}>1</Text>
      </View>

    
 </View>
        <View style={styles.container4}>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Charges Filed Date: 08/29/2017</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Court Costs: $67.00</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Court: FAIRFAX COUNTY GENERAL DISTRICT</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Fines: $300.00</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Disposition: GUILTY</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Offense Date: 08/17/2017</Text>
        </View>
        </View>


        {/* <View style={{display:"flex",flexDirection: "row",justifyContent:"space-around",marginTop:20}}>
      <View style={styles.container1}>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Name: RYAN ALLEN ROCHEFORT</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>DOB: XX/XX/1990, Born 32 Years Ago</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Address: 8233 S VIRGINIA AVE, PALM BEACH GARDENS, FL</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>33418-6161 (PALM BEACH COUNTY)</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Gender: M</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Ethnicity: WHITE</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Is Sex Offender: No</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Source Name: FLORIDA PALM BEACH COUNTY COURTS</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Source State: FL</Text>
        </View>

        <View style={styles.container2}>
           <Text style={{fontSize:"10px",fontColor:"black"}}>First Name: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Middle Name: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Last Name: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Date Of Birth: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Age: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Address: O Exact Match</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Height: X Not Available On Record</Text>
           <Text style={{fontSize:"10px",fontColor:"black"}}>Ethnicity: X Not Available On Record</Text>
        </View>
  </View> */}
  {/* <View style={{display:"flex",flexDirection: "row",justifyContent:"space-around"}}>
        <View style={styles.container3}>
           <Text  style={{fontSize:"12px",fontColor:"black"}}>Crime Details - 03/13/2020 - FLORIDA PALM BEACH|PALM BEACH, FL</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>OffenseDescription1: TCATS OPERATING A MOTOR VEHICLE/USING A MOBILE HOME WITH AN EXPIRED REGISTRATION FOR MORE THAN 6 MON</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Case Number: 502020TR036049AXXXNB</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Crime County: FLORIDA PALM BEACH|PALM BEACH</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>OffenseCode: 320.07(3B)</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Counts: 1</Text>
        </View>
        <View style={styles.container4}>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Charges Filed Date: 08/29/2017</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Court Costs: $67.00</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Court: FAIRFAX COUNTY GENERAL DISTRICT</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Fines: $300.00</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Disposition: GUILTY</Text>
           <Text  style={{fontSize:"10px",fontColor:"black"}}>Offense Date: 08/17/2017</Text>
        </View>
        </View> */}

  </View>
</View>

      <Text style={{ color: "#00628B", textAlign: 'left',paddingLeft: "8px", fontSize:18 ,fontWeight: 900, borderBottom:"1px solid #00628B"}} break>
        Disclaimer
      </Text>

      <View style={{marginTop:20}}>
<Text style={{color:"black",fontSize:"11px",marginTop:10}}>
      1. You agree that Megalytics owns all right, title and interest, including without limitation all copyrights, patents,
trademarks, moral rights, trade secrets and other proprietary and intellectual property rights in and to the Materials and
the results of use of your using the Materials and information generated through the use of the Materials. Any
modification to any of the Materials by you directly or indirectly extending the capabilities of the Materials and all rights
therein are hereby assigned to Megalytics.
</Text>
<Text style={{color:"black",fontSize:"11px",marginTop:10}}>
2. Accessing, copying, storing, displaying, reproducing, distributing, selling, licensing, sublicensing, publishing or creating
derivative works of any Materials is expressly prohibited without the prior written permission from Megalytics and the
copyright holder identified in the individual contents copyright notice.
</Text>
<Text style={{color:"black",fontSize:"11px",marginTop:10}}>
3. Megalytics does not warrant to you or make any representations to you regarding the use or the results of the use of
the materials in terms of the correctness, accuracy, reliability, or otherwise. Without limiting the generality of the
foregoing, Megalytics disclaims any reliability or liability for the accuracy of such results or output.
</Text>
      </View>

<View style={styles.inlineFooter} fixed>
  <View style={styles.inlineFooter1}>
     <Text >Megalytics®</Text>
  </View>
  <View style={styles.inlineFooter2}>
     <Text style={{paddingLeft:"33px"}}>Copyright © 2022 </Text>
  </View>
  <View style={styles.inlineFooter3}>
    <Text style={{paddingLeft:"33px"}}>All Rights Reserved</Text>
  </View>
  <View style={styles.inlineFooter4}>
    <Text style={{paddingLeft:"33px"}}>Proprietary and Confidential</Text>
  </View>
  <View style={styles.inlineFooter5} >
      <Text style={{paddingLeft:"36px"}} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )}  />
</View>
</View>
    </Page>
   
  </Document>
  
  );
 
  
}
  export default PdfReport;