import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Creators as tmActions } from '../ducks/tenantmonitoring';
import { Creators as ErrorActions } from '../ducks/error';
import { useAppendSearchParam } from 'use-url-search-params-hooks';
import { buildUrl } from 'build-url';
import moment from 'moment';
import user from 'store/ducks/auth';




export function* getDashboard(action) {
  const { organizationId,userId,pageSize,pageNum } = action.payload;
 
  const url = buildUrl('api/', {
    path: 'dashboard',
    queryParams: {
      organizationId:organizationId,
      // month:"jan",
      // year:"2021",
      pageNum:pageNum,
      pageSize:pageSize,
      userId:userId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    // console.log("saga1"+JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
   
    yield put(tmActions.tmDashboardSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getFundDashboardCount(action) {
  const { organisationId,userId} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'fund-dashboard-count',
    queryParams: {
      organisationId:organisationId,
      // month:"jan",
      // year:"2021",
      userId:userId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    console.log("saga"+JSON.stringify(response.data));
    yield put(tmActions.tmFundDashboardCountSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getPropertyDashboardCount(action) {
  const { organisationId,fundId} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'property-dashboard-count',
    queryParams: {
      organisationId:organisationId,
      // month:"jan",
      // year:"2021",
      fundId:fundId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyDashboardCountSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantsCountByPropertyRange(action) {
  const { propertyId,rangeFrom,year,month,organisationId,rangeTo} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'tenants-count-by-property-range',
    queryParams: {
      propertyId:propertyId,
      rangeFrom:rangeFrom,
      year:year,
      month:month,
      organisationId:organisationId,
      rangeTo:rangeTo
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsCountByPropertyRangeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getPropertyCountByOrg(action) {
  const {organisationId} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'tenants-count-by-property-range',
    queryParams: {
   
      organisationId:organisationId
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyCountByOrgSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getPropertyCountByOrgAndUser(action) {
  const {organisationId,userId} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'property-count-by-org-user',
    queryParams: {
   
      organisationId:organisationId,
      userId:userId,
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    console.log("saga"+JSON.stringify(response.data));
    yield put(tmActions.tmPropertyCountByOrgSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getFundCountByOrg(action) {
  const {organisationId} = action.payload;
 console.log(organisationId);
  const url = buildUrl('api/', {
    path: 'fund-count-by-org',
    queryParams: {
   
      organisationId:organisationId
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    console.log("saga"+JSON.stringify(response.data));
    yield put(tmActions.tmFundCountByOrgSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getPropertyOverAllScoreByFund(action) {
  const { organisationId,userId,pageSize,pageNum} = action.payload;

  const url = buildUrl('api/', {
    path: 'property-dashboard',
    queryParams: {
      organizationId:organisationId,
      pageNum:pageNum,
      pageSize:pageSize,
      userId: userId
    }
  });

  try {
   
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyDashboardByFundSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getPropertyByFund(action) {
  const {organisationId,fundId,pageSize,pageNum } = action.payload;
console.log("organisationId"+organisationId);
console.log("fundId"+fundId);
console.log("pageSize"+pageSize);
console.log("pageNum"+pageNum);

  const url = buildUrl('api/', {
    path: 'property-list',
    queryParams: {
      fundId:fundId,
      pageNum:pageNum,
      pageSize:pageSize,
      organisationId:organisationId,
      //userId:userId
    }
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("count"+response.data.totalCount);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmProperyByFundSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getPirsScore(action) {

  const { naicsCode } = action.payload;
  const url = buildUrl('api/', {
    path: 'pirsscore',
    queryParams: {
      naicsCode:naicsCode,
     
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPirsSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getScoreCommentary(action) {

  const { score,scoreType } = action.payload;
  const url = buildUrl('api/', {
    path: 'scorecommentary_description',
    queryParams: {
      score: score,
      scoreType: scoreType
     
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmScoreCommentarySuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}




export function* getCreditScore(action) {
console.log("getCreditScore");

  const { tenantId } = action.payload;
  const url = buildUrl('api/', {
    path: 'tenantscore',
    queryParams: {
      tenantId:tenantId,
     
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
console.log("response"+JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCreditScoreSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getTenantMonitoringList(action) {
  const { start, end } = action.payload;
 
  const url = buildUrl('api/', {
    path: 'notification',
    queryParams: {
      page : 1,
      size : 10,
      sortProperty : 'tenantName',
      sortDirection : 'DESC',
      searchTerm : " ",
      start : moment(start).format("D MMM YYYY"),
      end : moment(end).format("D MMM YYYY"),
      notificationCategory : 0,
      viewAllTenants : 'true'
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantMonitoringListByFund(action) {
  const { userId } = action.payload;

  const url = buildUrl('api/', {
    path: 'notification-fund-manager',
    queryParams: {
      page : 1,
      size : 10,
      sortProperty : 'tenantName',
      sortDirection : 'DESC',
      searchTerm : " ",
    //  start : moment(start).format("D MMM YYYY"),
   //   end : moment(end).format("D MMM YYYY"),
      notificationCategory : 0,
      viewAllTenants : 'true',
      userId:userId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantMonitoringListByFundSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantMonitoringListByProperty(action) {
  const {userId} = action.payload;

  const url = buildUrl('api/', {
    path: 'notification-asset-manager',
    queryParams: {
      page : 1,
      size : 10,
      sortProperty : 'tenantName',
      sortDirection : 'DESC',
      searchTerm : " ",
     // start : moment(start).format("D MMM YYYY"),
      //end : moment(end).format("D MMM YYYY"),
      notificationCategory : 0,
      viewAllTenants : 'true',
      userId:userId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantMonitoringListByPropertySuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantMonitoringNotificationList(action) {
 
  const { contifyId, tenantId, naics, start, end } = action.payload;
  const url = buildUrl('api/', {
    path: 'company-notification',
    queryParams: {
      page : 1,
      size : 10,
      sortProperty : 'createdDate',
      sortDirection : 'DESC',
      contifyId : contifyId,
      tenantId:tenantId,
      naics:naics,
      searchTerm : " ",
      start : moment(start).format("D MMM YYYY"),
      end : moment(end).format("D MMM YYYY"),
      notificationCategory : 0,
      tenantType : 'tenant'
    }
  });
  try {

   // const response = yield call(api.get, 'api/company-notification?page=0&size=10&sortProperty=created_date&sortDirection=DESC&searchTerm=&start=Fri%20Mar%2013%202020&end=Wed%20Sep%2009%202020&contifyUpdateValue=allUpdates&socialMedia=socialNews&notificationTypeList=');
  // const response = yield call(api.get, 'api/company-notification?tenantId=1543&contifyId=135386&page=0&size=10&sortProperty=created_date&sortDirection=DESC&searchTerm=&notificationCategory=0&tenantType=tenant');
   const response = yield call(api.get, url);
   yield put(ErrorActions.hideError());
    yield put(tmActions.tmNotificationSuccess(response.data));

  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getBankoNotificationList(action) {
 
 
  const { accountNumber, tenantId, start, end } = action.payload;
 
  const url = buildUrl('api/', {
    path: 'banko-company-notification',
    queryParams: {
      page : 1,
      size : 10,
      sortProperty : 'createdDate',
      sortDirection : 'DESC',
      accountNumber : accountNumber,
      companyType : 'tenant',
      tenantId : tenantId,
      searchTerm : " ",
      start : moment(start).format("D MMM YYYY"),
      end : moment(end).format("D MMM YYYY"),
      notificationCategory : 0,
     
    }
  });
  try {

   // const response = yield call(api.get, 'api/company-notification?page=0&size=10&sortProperty=created_date&sortDirection=DESC&searchTerm=&start=Fri%20Mar%2013%202020&end=Wed%20Sep%2009%202020&contifyUpdateValue=allUpdates&socialMedia=socialNews&notificationTypeList=');
  // const response = yield call(api.get, 'api/company-notification?tenantId=1543&contifyId=135386&page=0&size=10&sortProperty=created_date&sortDirection=DESC&searchTerm=&notificationCategory=0&tenantType=tenant');
   const response = yield call(api.get, url);

   yield put(ErrorActions.hideError());
    yield put(tmActions.tmBankoNotificationSuccess(response.data));

  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantMonitoringPublicRecordNotificationList() {
  try {
   
    const response = yield call(api.get, 'api/lexisnexis-company-notification?page=0&size=10&sortProperty=created_date&sortDirection=DESC&start=Fri%20Mar%2013%202020&end=Wed%20Sep%2009%202020');
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmNotificationSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getDerogatoryScoreCount(action) {
  const {tenantId } = action.payload;
  const url = buildUrl('api/', {
    path: 'derogatory_score_count',
    queryParams: {
     
      tenantId:tenantId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
console.log("getDerogatoryScoreCount");
console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmDerogatoryScoreCountSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getFundRiskCount(action) {
  const { organizationId,fundId } = action.payload;
 
  const url = buildUrl('api/', {
    path: 'fund_risk_count',
    queryParams: {
      organizationId:organizationId,
      fundId:fundId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundRiskCountSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getTenantsScoreByRange(action) {
  const { organizationId,fundId ,rangeFrom,rangeTo,month,year} = action.payload;
  const url = buildUrl('api/', {
    path: 'tenants_score_by_range',
    queryParams: {
      organisationId:organizationId,
      fundId:fundId,
      rangeFrom:rangeFrom,
      rangeTo:rangeTo,
       month:"jan",
       year:"2021"

    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsScoreByRangeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getTenantByRange(action) {
  const {organizationId,fundId,rangeFrom,rangeTo,month,year} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'tenants_by_range',
    queryParams: {
      fundId:fundId,
      rangeFrom:rangeFrom,
      rangeTo:rangeTo,
      month:month,
      year:year

    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsByFundIdRangeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}





export function* getTenantsOverAlCredPirsDeroScore(action) {
  const {tenantId,month,year,userId} = action.payload;
console.log("month"+month);
console.log("year"+year);
  const url = buildUrl('api/', {
    path: 'tenant-pirs-credit-derogatoryscore',
    queryParams: {
      tenantId:tenantId,
      month:month,
      year: year,
      userId:userId
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsOverAlCredPirsDeroScoreSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getTenantsScoreByType(action) {
  const { tenantId,month,year,type} = action.payload;
  console.log("getTenantsScoreByType");
  console.log(tenantId);
  console.log(month);
  console.log(type);
  console.log(year);
  const url = buildUrl('api/', {
    path: 'tenant-score-by-type',
    queryParams: {
      tenantId:tenantId,
      month:month,
      year:year,
      type:type
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
console.log("getTenantsScoreByType :",response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsScoreByTypeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getTenantsCountByFund(action) {
  const { fundId,organisationId} = action.payload;
  const url = buildUrl('api/', {
    path: 'tenant_count_by_fund',
    queryParams: {
      fundId:fundId,
      organisationId:organisationId
      }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsCountByFundSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getScoreStatus(action) {
  const { id,type} = action.payload;
  const url = buildUrl('api/', {
    path: 'score_status',
    queryParams: {
      id:id,
      type:type
      }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmScoreStatusSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}
export function* getTenantOverAllScoreByProperty(action) {
  const {propertyId} = action.payload;
  const url = buildUrl('api/', {
    path: 'tenant_overallscore_by_property',
    queryParams: {
      propertyId:propertyId
     
      }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantOverAllScoreByPropertySuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getFundsByOrg(action) {
  const {orgId,userId,pageSize,pageNum} = action.payload;
  const url = buildUrl('api/', {
    path: 'fund-list',
    queryParams: {
      organisationId:orgId,
      pageNum:pageNum,
      pageSize: pageSize,
      userId:userId,
     
      }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    yield put(ErrorActions.hideError());
    console.log("saga1"+JSON.stringify(response.data));
    yield put(tmActions.tmFundByOrgIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getFundsByOrgCompanyAdmin(action) {
  const {organisationId,pageSize,pageNum} = action.payload;
 
  const url = buildUrl('api/', {
    path: 'fund-list-company',
    queryParams: {
      organisationId:organisationId,
      pageNum:pageNum,
      pageSize: pageSize,

     
      }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundsByOrgCompanyAdminSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getTenantsRiskCountByFund(action) {
  const { organizationId,fundId} = action.payload;
  const url = buildUrl('api/', {
    path: 'fund_risk_count',
    queryParams: {
      organizationId:organizationId,
      fundId:fundId
     
     

    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsRiskCountByFundSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getPropertyListByOrg(action) {
 console.log("getPropertyListByOrg");
  const {organisationId} = action.payload;
  const url = buildUrl('api/', {
    path:'property-by-org',
    queryParams: {
      organisationId:organisationId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   console.log(response.data);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyByOrgIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }

  // function newFunction() {
  //   return "getPropertyListByOrg";
  // }
}

export function* getPropertyListWithUser(action) {
  console.log("getPropertyListWithUser");
   const {organisationId} = action.payload;
   const url = buildUrl('api/', {
     path:'properties-with-user',
     queryParams: {
       organisationId:organisationId,
     }
   });
 
   try {
     //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
     const response = yield call(api.get, url);
    console.log(response.data);
   
     yield put(ErrorActions.hideError());
     yield put(tmActions.tmPropertyWithUserSuccess(response.data));
   
   } catch (err) {
     yield put(ErrorActions.setError('User not found or wrong credentials'));
   }
 
 
 }

export function* getTenantsCountByProperty(action) {
  const { propertyId} = action.payload;
  const url = buildUrl('api/', {
    path: 'tenant_count_by_property',
    queryParams: {
      propertyId:propertyId  

    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantCountByPropertySuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

export function* getTenantsRiskCountByProperty(action) {
  const { propertyId,userId} = action.payload;

  const url = buildUrl('api/', {
    path: 'tenant-risk-count-by-property',
    queryParams: {
      propertyId:propertyId,
      userId:userId
     
     

    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
   console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsRiskCountByPropertySuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getTenantsPropertyByOrganization(action) {

  const {organizationId} = action.payload;
  const url = buildUrl('api/', {
    path: 'tenants_overallscore_by_property',
    queryParams: {
      organizationId:organizationId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmProperyTenantsByOrganizationSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getTenantsScoreByPIRSType(action) {
  const { tenantId,month,year} = action.payload;
  console.log(tenantId);
console.log(month);
console.log(year);
  const url = buildUrl('api/', {
    path: 'tenant_pirs_score',
    queryParams: {
      tenantId:tenantId,
      month:month,
      year:year
   
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    console.log("data----------PIRS-----------"+response.data);
    console.log("response"+JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsScoreByPIRSSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}


export function* getTenantByPropertyRange(action) {
     
  const {organizationId,userId,propertyId,selectedRangeFrom,selectedRangeTo,month,year,pageSize,pageNum} = action.payload;
  console.log(action.payload.organizationId+"PayLoad");
  console.log("month"+month);
  console.log("year"+year);
  console.log("organizationId"+organizationId);
  console.log("userId"+userId);
  console.log("propertyId"+propertyId);
  console.log("selectedRangeFrom"+selectedRangeFrom);
  console.log("selectedRangeTo"+selectedRangeTo);
  console.log("pageSize"+pageSize);
  console.log("pageNum"+pageNum);

 
  const url = buildUrl('api/', {
    path: 'tenants_score_by_property_range',
    queryParams: {
      organizationId:organizationId,
      userId:userId,
      propertyId:propertyId,
      rangeFrom:selectedRangeFrom,
      rangeTo:selectedRangeTo,
      month:month,
      year:year,
       pageSize:pageSize,
      pageNum:pageNum
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
  console.log("getTenantByPropertyRange");
  console.log("getTenantByPropertyRange");
  console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantsScoreByPropertyRangeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}



export function* getTenantNameByPropertyRange(action) {
  const {organizationId,propertyId,rangeFrom,rangeTo,month,year,pageSize,pageNum} = action.payload;
 console.log("month1"+month);
 console.log("year1"+year);
//  console.log("organizationId1"+organizationId);
//  console.log("propertyId1"+propertyId);
//  console.log("rangeFrom1"+rangeFrom);
//  console.log("rangeTo1"+rangeTo);
//  console.log("pageSize1"+pageSize);
//  console.log("pageNum1"+pageNum);

  const url = buildUrl('api/', {
    path: 'tenants_by_property_range',
    queryParams: {
      organisationId:organizationId,
      propertyId:propertyId,
      rangeFrom:rangeFrom,
      rangeTo:rangeTo,
      month:month,
      year:year,
   
      pageSize:pageSize,
      pageNum:pageNum
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url);
    console.log("real data"+response.data);
   console.log("response"+JSON.stringify(response.data.chartHeight));
   console.log("response"+JSON.stringify(response.data.lineHeight));
   console.log("response"+JSON.stringify(response.data.listHeight));
   //console.log("response"+JSON.stringify(response.data.options));

   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantNameScoreByPropertyRangeSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('User not found or wrong credentials'));
  }
}

//jain

export function* saveFund(action) {
  const { fund } = action.payload;
 
  console.log("savefund");
console.log(fund);
  const url = buildUrl('api/', {
    path: 'fund-create',
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    console.log(url);
    const response = yield call(api.post, url, fund);
    console.log(url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundSaveSuccess(response.data));
    console.log(response.data);

 
  } catch (err) {
   
    yield put(ErrorActions.setError('error in saveFund'));
   

  }
}
// export function* savePropertySearchList(action) {
//   const { fund } = action.payload;
 
//   console.log("savePropertySearchList");
// // console.log(fund);
//   const url = buildUrl('api/', {
//     path: 'fund-create',
    
//   });

//   try {
//     //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
//     console.log(url);
//     const response = yield call(api.post, url, fund);
//     console.log("savePropertySearchList"+response.data);
  
//     yield put(ErrorActions.hideError());
//     yield put(tmActions.tmPropertySearchListSaveSuccess(response.data));
//     console.log(response.data);

  
//   } catch (err) {
    
//     yield put(ErrorActions.setError('error in savePropertySearchList'));
   

//   }
// }




export function* editFund(action) {
  const { fund } = action.payload;
console.log(fund);

  const url = buildUrl('api/', {
    path: 'fund-update',
 
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.put, url,fund);
  console.log("response");
  console.log(response);
 // alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundEditSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editFund'));
  }
}

export function* deleteFund(action) {
  const { fundId } = action.payload;

  const url = buildUrl('api/', {
    path: 'fund-delete',
    queryParams: {
      id:fundId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.delete, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundDeleteSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in deleteFund'));
  }
}

export function* listFund(action) {
  const { organisationId} = action.payload;

  const url = buildUrl('api/', {
    path: 'fund-list',
    queryParams: {
      //organisationId:"5f8ee03be74296262abdeb65",
      organisationId:organisationId,
     
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   console.log("listFund");
    const response = yield call(api.get, url);
    console.log(response);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundListSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in listFund'));
  }
}

export function* saveCompany(action) {
  const { company } = action.payload;

  const url = buildUrl('api/', {
    path: 'org-create'
 
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.post, url,company);

    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanySaveSuccess(response.data));
 
 
  } catch (err) {
    yield put(ErrorActions.setError('error in saveCompany'));
  }
}

export function* editCompany(action) {
  const { company } = action.payload;

  const url = buildUrl('api/', {
    path: 'org-update',
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.put, url, company);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyEditSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editCompany'));
  }
}

export function* deleteCompany(action) {
  const { companyId } = action.payload;

  const url = buildUrl('api/', {
    path: 'org-delete',
    queryParams: {
      id:companyId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.delete, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyDeleteSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in deleteCompany'));
  }
}

export function* listCompany(action) {
  const { organisationId } = action.payload;

  const url = buildUrl('api/', {
    path: 'org-list'
 
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   console.log("listCompany");
    const response = yield call(api.get, url);
    console.log(response);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyListSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in listCompany'));
  }
}


export function* saveProperty(action) {
  const { property } = action.payload;
  const propertyString=JSON.stringify(property)
  // console.log("saga"+JSON.stringify(property));

  const url = buildUrl('api/', {
    path: 'property-create',
    queryParams: {
      propertyString:JSON.stringify(property),
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.post, url);
    console.log("sg"+JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertySaveSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in saveProperty'));
  }
}

export function* editProperty(action) {
  const { property } = action.payload;
  const propertyString=JSON.stringify(property)
  console.log(property);
  const url = buildUrl('api/', {
    path: 'property-update',
    queryParams: {
      propertyString:JSON.stringify(property),
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.put, url,property);
 
    yield put(ErrorActions.hideError());
    console.log("sg"+JSON.stringify(response.data));
    yield put(tmActions.tmPropertyEditSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editProperty'));
  }
}

export function* deleteProperty(action) {
  const { propertyId } = action.payload;

  const url = buildUrl('api/', {
    path: 'property-delete',
    queryParams: {
      id:propertyId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.delete, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyDeleteSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in deleteProperty'));
  }
}
export function* listProperty(action) {
  const { organisationId ,fundId,userId} = action.payload;
  alert("evide Ethiyo2");
  const url = buildUrl('api/', {
    path: 'property-list',
    queryParams: {
      //organisationId:"5f8ee03be74296262abdeb65",
      organisationId:organisationId,
      fundId:fundId,
      //userId:"5f71f8a1bc82180272f8eed3"
      userId:userId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   console.log("listProperty");
    const response = yield call(api.get, url);
    console.log(response);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyListSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in listProperty'));
  }
}



export function* saveTenant(action) {
  const { tenant } = action.payload;

  const url = buildUrl('api/', {
    path: 'tenant-create'
 
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.post, url,tenant);
    //alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantSaveSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in saveTenant'));
  }
}

export function* editTenant(action) {
  const { tenant } = action.payload;
console.log("editTenant");
  const url = buildUrl('api/', {
    path: 'tenant-update',
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.put, url,tenant);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantEditSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editTenant'));
  }
}

export function* deleteTenant(action) {
  const { tenantId } = action.payload;

  const url = buildUrl('api/', {
    path: 'tenant-delete',
    queryParams: {
      id:tenantId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.delete, url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantDeleteSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in deleteTenant'));
  }
}

export function* listTenant(action) {
  const { organisationId ,propertyId} = action.payload;

  const url = buildUrl('api/', {
    path: 'tenant-list',
    queryParams: {
     // organisationId:"5f8ee03be74296262abdeb65",
      //propertyId:"60090efa6f6f3d09adf5be0b"
      organisationId:organisationId,
      propertyId:propertyId
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   console.log("listTenant");
    const response = yield call(api.get, url);
    console.log(response);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantListSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in listFund'));
  }
}

export function* uploadTenant(action) {

 
  const { file,organizationId,userId } = action.payload;

const formData=new FormData();
formData.append('uploadFile',file)


  const url = buildUrl('api/', {
    path: 'uploadcsv',
    queryParams: {
      organizationId:organizationId,
 
      uploadFile:formData
    }
   
   
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
 
    const response = yield call(api.post, url,formData);


    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUploadTenantSuccess(response.data));
    console.log("uploadTenant"+response.data);

  } catch (err) {
    yield put(ErrorActions.setError('error in uploadTenant'));
   
  }
}

export function* getPropertyImageUpload(action) {

  
  const { file,propertyId } = action.payload;
  console.log("file"+file);
  console.log("propertyId"+propertyId);

const formData=new FormData();
formData.append('uploadImage',file)


  const url = buildUrl('api/', {
    path: 'property-image-upload',
    queryParams: {
      propertyId:propertyId,
  
      uploadImage:formData
    }
   
   
    
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
  
    const response = yield call(api.post, url,formData);

console.log("getPropertyImageUpload");
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyImageUploadSuccess(response.data));
    console.log("getPropertyImageUpload"+response.data);

  } catch (err) {
    yield put(ErrorActions.setError('error in getPropertyImageUpload'));
   
  }
}


export function* getFundImageUpload(action) {

  
  const { file,fundId } = action.payload;
  console.log("file"+file);
  console.log("fundId"+fundId);

const formData=new FormData();
formData.append('uploadImage',file)


  const url = buildUrl('api/', {
    path: 'fund-image-upload',
    queryParams: {
      fundId:fundId,
  
      uploadImage:formData
    }
   
   
    
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
  
    const response = yield call(api.post, url,formData);

console.log("getFundImageUpload");
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundImageUploadSuccess(response.data));
    console.log("getFundImageUpload"+response.data);

  } catch (err) {
    yield put(ErrorActions.setError('error in getFundImageUpload'));
   
  }
}


export function* saveUser(action) {
const { managedUserVM } = action.payload;
console.log("saveUser");
console.log(managedUserVM);
  const url = buildUrl('api/', {
    path: 'users',
   
  });
  try {
    console.log(url);
    const response = yield call(api.post, url, managedUserVM);
    console.log(url);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserSaveSuccess(response.data));
    console.log(response.data);
 
  } catch (err) {
   
    yield put(ErrorActions.setError('error in saveUser'));
   

  }
}

export function* editUser(action) {
  const { user } = action.payload;
  console.log(user);

  const url = buildUrl('api/', {
    path: 'users',
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.put, url, user);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserEditSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editUser'));
  }
}


export function* deleteUser(action) {
  const { userId } = action.payload;
 
       
  console.log(userId);

  const url = buildUrl('api/', {
    path: 'delete-user',
    queryParams: {
      id:userId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.delete, url);

    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserDeleteSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in editUser'));
  }
}

export function* enableUser(action) {
  const { userId } = action.payload;

  console.log(userId);

  const url = buildUrl('api/', {
    path: 'enable-user',
    queryParams: {
      id:userId,
    }
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url,userId);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserEnableSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in enableUser'));
  }
}
export function* disableUser(action) {
  const { userId } = action.payload;

  console.log(userId);

  const url = buildUrl('api/', {
    path: 'disable-user',
    queryParams: {
      id:userId,
    }
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.get, url,userId);
 
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserDisableSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in disableUser'));
  }
}

export function* listUser(action) {
  const { fundId,pageSize,pageNum } = action.payload;

  const url = buildUrl('api/', {
    path: 'users'
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserListSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in getUserList'));
  }
}

export function* getUserNameAndRolebyLogin(action) {
  const { login } = action.payload;

  const url = buildUrl('api/', {
    path: 'user-name-role-by-login',
    queryParams: {
      login : login
    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserNameRoleSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in getUserNameAndRole'));
  }
}



export function* getContifyCompanyInsightSearchData(action) {
  const { salesTriggerId,startDate,endDate,tenantId} = action.payload;
  const url = buildUrl('api/', {
    path: 'contify-company-insight-search-by-salestriggerid',
    queryParams: {
      salesTriggerId : salesTriggerId,
      tenantId :tenantId,
      searchTerm : " ",
      startDate : moment(startDate).format("D MMM YYYY"),
      endDate : moment(endDate).format("D MMM YYYY"),
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmContifyCompanyInsightSearchDataSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('error in getContifyCompanyInsightSearchData'));
  }
}

export function* getActiveTaxLiensJudgmentDetails(action) {
  const { tenantId} = action.payload;
  const url = buildUrl('api/', {
    path: 'active-taxlien-judgment-details',
    queryParams: {
     
      tenantId :tenantId,
     
     
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getActiveTaxLiensJudgmentDetails");
    console.log(JSON.stringify(response.data.taxLienDetailList));
    console.log(JSON.stringify(response.data.judgmentDetails));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmActiveLiensJudgmentDetailsSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('error in getActiveTaxLiensJudgmentDetails'));
  }
}

export function* getTaxLienDetailsByTenantId(action) {
  const { tenantId,month,year } = action.payload;

  const url = buildUrl('api/', {
    path: 'active-taxlien-details',
    queryParams: {
     
      tenantId :tenantId,
      month:month,
      year:year,
     
 
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getTaxLienDetailsByTenantId");
    console.log("getTaxLienDetailsByTenantId"+JSON.stringify(response.data));
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmActiveLiensDetailsSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('error in getTaxLienDetailsByTenantId'));
  }
}



export function* getContifyCompanySearch(action) {
  console.log("getContifyCompanySearch");
  const {contifyCompanySearchRequest} = action.payload;
 
  // console.log(state);
  // console.log(city);
  // console.log(address);
  const url = buildUrl('api/', {
    path: '/contify-company-search',
    queryParams: {

      companyName :contifyCompanySearchRequest.companyName,
      companyUrl:contifyCompanySearchRequest.companyUrl,
      // state :contifyCompanySearchRequest.state,
      // city :contifyCompanySearchRequest.city,
      // address :contifyCompanySearchRequest.address,
      // postCode :contifyCompanySearchRequest.postCode,
      // exact:contifyCompanySearchRequest.exact
 
     
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getContifyCompanySearch");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmContifyCompanySearchSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('error in getContifyCompanySearch'));
  }
}
export function* getCreditSafeCompanyList(action) {
  console.log("getCreditSafeCompanyList");

  const { creditSafeCompanyRequest } = action.payload;
 
  const url = buildUrl('api/', {
    path: '/creditsafe-company-list',
    queryParams: {
     
      name :creditSafeCompanyRequest.name,
    website:creditSafeCompanyRequest.website,
    state :creditSafeCompanyRequest.state,
    city :creditSafeCompanyRequest.city,
    address :creditSafeCompanyRequest.address,
    postCode :creditSafeCompanyRequest.postalCode,
     exact:creditSafeCompanyRequest.exact
 
    }
   
  });

  try {
    const response = yield call(api.get, url);
    console.log("getCreditSafeCompanyList");
    console.log(response.data);
    yield put(ErrorActions.hideError());
   
    yield put(tmActions.tmCreditSafeCompanyListResultSuccess(response.data));
   
  } catch (err) {
 
    yield put(ErrorActions.setError('getCreditSafeCompanyList'));
  }
}

export function* getTloBusinessSearch(action) {
  console.log("getTloBusinessSearch");
  const {tloCompanySearchRequest} = action.payload;

  const url = buildUrl('api/', {
    path: '/tlo-company-search',
    queryParams: {

      companyName :tloCompanySearchRequest.companyName,
      companyUrl:tloCompanySearchRequest.companyUrl,
      state :tloCompanySearchRequest.state,
      city :tloCompanySearchRequest.city,
      // address :contifyCompanySearchRequest.address,
      postCode :tloCompanySearchRequest.postCode,
      // exact:contifyCompanySearchRequest.exact
 
     
    }
  });

  try {
     const response = yield call(api.get, url);
    console.log("getTloBusinessSearch");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTloCompanySearchSuccess(response.data));

  } catch (err) {
    yield put(ErrorActions.setError('error in getTloBusinessSearch'));
  }
}

export function* getPropertySearchList(action) {
  console.log("getPropertySearchList");

  const { propertySearchRequest } = action.payload;
 
  const url = buildUrl('api/', {
    path: '',
    queryParams: {

      state1:propertySearchRequest.state1,
      city:propertySearchRequest.city,
      country:propertySearchRequest.country,
      address1:propertySearchRequest.address1,
      address2:propertySearchRequest.address2,
      zip:propertySearchRequest.zip,
      exact:propertySearchRequest.exact
      
    //   name :creditSafeCompanyRequest.name,
    // website:creditSafeCompanyRequest.website,
    // state :creditSafeCompanyRequest.state,
    // city :creditSafeCompanyRequest.city,
    // address :creditSafeCompanyRequest.address,
    // postCode :creditSafeCompanyRequest.postalCode,
    //  exact:creditSafeCompanyRequest.exact
  
    }
    
  });

  try {
    const response = yield call(api.get, url);
    console.log("getPropertySearchList");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    
    yield put(tmActions.tmPropertySearchSuccess(response.data));
   
  } catch (err) {
  
    yield put(ErrorActions.setError('getPropertySearchList'));
  }
}
// 

export function* getCorteraBusinessSearch(action) {
  console.log("getCorteraBusinessSearch");
  const { corteraBusinessList} = action.payload;
  console.log(corteraBusinessList);
  const url = buildUrl('api/', {
    path: '/cortera-business-search',
    queryParams: {
     
      legalName :corteraBusinessList.legalName,
      city:corteraBusinessList.city,
     state:corteraBusinessList.state,
    //  address:corteraBusinessList.address,
    //  postCode:corteraBusinessList.postCode
 
    }
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getCorteraBusinessSearch");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCorteraBusinessSearchSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('getCorteraBusinessSearch'));
  }
}

export function* getFundListByOrg(action) {
  const { organisationId } = action.payload;
console.log("getFundListByOrg");
  const url = buildUrl('api/', {
    path: 'fund-by-org',
    queryParams: {
     // organisationId:"5f8ee03be74296262abdeb65",
     organisationId:organisationId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getFundListByOrg");
    console.log(response.data);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundListByOrgSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('getFundListByOrg'));
  }
}

export function* getFundListWithUser(action) {
  const { organisationId } = action.payload;
console.log("getFundListWithUser");
  const url = buildUrl('api/', {
    path: 'funds-with-user',
    queryParams: {
     // organisationId:"5f8ee03be74296262abdeb65",
     organisationId:organisationId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getFundListWithUser");
    console.log(response.data);
   
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundListWithUserSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('getFundListWithUser'));
  }
}



export function* getTenantListByOrg(action) {
  const { organisationId } = action.payload;

  const url = buildUrl('api/', {
    path: 'tenants-by-org',
    queryParams: {
      //organisationId:"5f8ee03be74296262abdeb65",
      organisationId:organisationId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getTenantListByOrg");
    console.log(response.data);
    //alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantListByOrgSuccess(response.data));
 //  
  } catch (err) {
   
    yield put(ErrorActions.setError('getTenantListByOrg'));
  }
}
{/*export function* getPropertyListByOrg(action) {
const { organisationId } = action.payload;

const url = buildUrl('api/', {
path: 'property-by-org',
     queryParams: {
       organisationId:"5f8ee03be74296262abdeb65",
     }
   });

   try {
     //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

     const response = yield call(api.get, url);
     console.log("getPropertyListByOrg");
     console.log(response.data);
     yield put(ErrorActions.hideError());
     yield put(tmActions.tmPropertyListByOrgSuccess(response.data));
 
   } catch (err) {
     yield put(ErrorActions.setError('getPropertyListByOrg'));
   }
 }*/}
export function* getUserListByOrg(action) {
  const { organisationId } = action.payload;

  const url = buildUrl('api/', {
    path: 'users-by-org',
    queryParams: {
      organizationId:organisationId,
    }
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getUserListByOrg");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserListByOrgSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('getUserListByOrg'));
  }
}
export function* getCompanyListByOrg(action) {
 
  const url = buildUrl('api/', {
    path: '/orglist'
   
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');

    const response = yield call(api.get, url);
    console.log("getCompanyListByOrg");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyListByOrgSuccess(response.data));
 //  
  } catch (err) {
    yield put(ErrorActions.setError('getCompanyListByOrg'));
  }

}
  export function* getFundManagerList(action) {
   
    const { organizationId} = action.payload;
    const url = buildUrl('api/', {
      path: 'fund-manager-list',
      queryParams: {
        organizationId:organizationId
      }
   
    });
 
    try {
      console.log("responsea");
      const response = yield call(api.get, url);
      console.log("response");
      console.log(response.data);
      yield put(ErrorActions.hideError());
      yield put(tmActions.tmFundManagerListSuccess(response.data));
   
    } catch (err) {
      yield put(ErrorActions.setError('error in getUserList'));
    }

  }
    export function* getAssetManagerList(action) {
      const { organizationId} = action.payload;
      console.log("getAssetManagerList");
      const url = buildUrl('api/', {
        path: 'asset-manager-list',
        queryParams: {
          organizationId:organizationId
        }
     
      });
   
      try {
       
        const response = yield call(api.get, url);
        console.log(response.data);
        yield put(ErrorActions.hideError());
        yield put(tmActions.tmAssetManagerListSuccess(response.data));
     
      } catch (err) {
        yield put(ErrorActions.setError('error in getAssetManagerList'));
      }


  }

  export function* getFundPropertyOverAllScore(action) {
    const { fundId,userId,pageSize,pageNum} = action.payload;
   console.log("fundId"+fundId);
   console.log("userId"+userId);
   console.log("pageSize"+pageSize);
   console.log("pageNum"+pageNum);
   
    const url = buildUrl('api/', {
      path: 'fund-property-dashboard',
      queryParams: {
        fundId:fundId,
       userId:userId,
       pageSize:pageSize,
       pageNum:pageNum

      }
   
    });
 
    try {
     
      const response = yield call(api.get, url);
      console.log("getFUndPropertyOverallscore");
      console.log(response.data);
      yield put(ErrorActions.hideError());
      yield put(tmActions.tmFundPropertyDashboardSuccess(response.data));
   
    } catch (err) {
      yield put(ErrorActions.setError('error in getFundPropertyOverAllScore'));
    }


}

export function* getCompanyAdminDashboard(action) {
  const {organizationId,userId,pageNum,pageSize} = action.payload;
  console.log("getCompanyAdminDashboard");
  const url = buildUrl('api/', {
    path: 'company-admin-dashboard',
    queryParams: {
      organizationId:organizationId,
      userId,userId,
      pageNum:pageNum,
      pageSize:pageSize


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyAdminDashboardSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in getCompanyAdminDashboard'));
  }
}

export function* createTenantInitialScore(action) {
  const {tenantId} = action.payload;
  console.log("createTenantInitialScore");
  const url = buildUrl('api/', {
    path: 'create-new-tenant-score',
    queryParams: {
      tenantId:tenantId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantInitialScoreSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in createTenantInitialScore'));
  }


}

export function* getFundById(action) {
  const {fundId} = action.payload;
  console.log("getFundById");

  const url = buildUrl('api/', {
    path: 'fund',
    queryParams: {
      id:fundId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log(response.data);

    yield put(ErrorActions.hideError());
    yield put(tmActions.tmFundByIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in getFundById'));
  }


}
export function* getCompanyById(action) {
  const {companyId} = action.payload;

  console.log("getCompanyById");
  const url = buildUrl('api/', {
    path: 'org',
    queryParams: {
      id:companyId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmCompanyByIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in getCompanyById'));
  }


}

export function* getTenantById(action) {
  const {tenantId} = action.payload;
  console.log(" getTenantById");
  const url = buildUrl('api/', {
    path: 'tenant',
    queryParams: {
      id:tenantId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantByIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in  getTenantById'));
  }


}
export function* getUserById(action) {
  const {userId} = action.payload;
  console.log("getUserById");
 
  const url = buildUrl('api/', {
    path: 'user',
    queryParams: {
      id:userId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log(response.data);
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmUserByIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in  getUserById'));
  }


}
export function* getPropertyById(action) {
  const {propertyId} = action.payload;
  // console.log("getPropertyById"+JSON.stringify(getPropertyById));

  const url = buildUrl('api/', {
    path: 'property',
    queryParams: {
      id:propertyId


    }
 
  });

  try {
   
    const response = yield call(api.get, url);
    console.log("response");
    console.log("get"+JSON.stringify(response.data));
    // alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyByIdSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in  getPropertyById'));
  }


}

export function* getPropertyListOfOrgByAssetManager(action) {
  const {organisationId,userId,pageSize,pageNum} = action.payload;
  console.log("getPropertyListOfOrgByAssetManager");
  const url = buildUrl('api/', {
    path: 'property-list-asset-manager',
    queryParams: {
      organisationId:organisationId,
      userId:userId,
      pageNum:pageNum,
      pageSize:pageSize

    }
 
  });

  try {
   
    const response = yield call(api.get, url);
console.log(response.data.totalCount);
console.log("response"+JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmPropertyListOfOrgByAssetManagerSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in  getPropertyListOfOrgByAssetManager'));
  }


}
export function* createTrailingScoresForAllTenants(action) {
  console.log("createTrailingScoresForAllTenants");
  const url = buildUrl('api/', {
  path: 'create-all-trailing-tenant-score',
  });
 
  try {
  const response = yield call(api.get, url);
  console.log("response");
  console.log(response.data);
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmAllTenantsTrailingScoreSuccess(response.data));
  } catch (err) {
  yield put(ErrorActions.setError('error in createTrailingScoresForAllTenants'));
  }
 
  }
 
  export function* getTaxLienJudgmentScoreByTenantId(action) {
    const { tenantId} = action.payload;
    const url = buildUrl('api/', {
    path: 'active-taxlien-judgment-score',
    queryParams: {
    tenantId :tenantId,
    }
    });
   
    try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   
    const response = yield call(api.get, url);
    console.log("getTaxLienJudgmentScoreByTenantId");
    console.log(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmActiveTaxLienJudgmentScoreByTenantIdSuccess(response.data));
    //
    } catch (err) {
    yield put(ErrorActions.setError('error in getTaxLienDetailsByTenantId'));
    }
    }

    export function* getJudgmentDetailsByTenantId(action) {
      const { tenantId,month,year} = action.payload;
 
      const url = buildUrl('api/', {
      path: 'active-judgment-details',
      queryParams: {
      tenantId :tenantId,
      month:month,
      year:year,
      }
      });
     
      try {
      //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
     
      const response = yield call(api.get, url);
      console.log("getJudgmentDetailsByTenantId");
      console.log(JSON.stringify(response.data));
     
      yield put(ErrorActions.hideError());
      yield put(tmActions.tmActiveJudgmentDetailsSuccess(response.data));
      //
      } catch (err) {
      yield put(ErrorActions.setError('error in getJudgmentDetailsByTenantId'));
      }
      }

      export function* getSuitDetailsByTenantId(action) {
        const { tenantId,month,year} = action.payload;
   
        const url = buildUrl('api/', {
        path: 'active-suits-details',
        queryParams: {
        tenantId :tenantId,
        month:month,
      year:year,
        }
        });
       
        try {
        //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
       
        const response = yield call(api.get, url);
        console.log("getSuitDetailsByTenantId");
        console.log(JSON.stringify(response.data));
        //  alert(JSON.stringify(response.data));
        yield put(ErrorActions.hideError());
        yield put(tmActions.tmActiveSuitDetailsSuccess(response.data));
        //
        } catch (err) {
        yield put(ErrorActions.setError('error in getSuitDetailsByTenantId'));
        }
        }

        export function* getUccDetailsByTenantId(action) {
          const { tenantId,month,year } = action.payload;
     
          //  console.log("uccList");
          const url = buildUrl('api/', {
          path: 'cautionary-ucc-details',
          queryParams: {
          tenantId :tenantId,
          month:month,
          year:year,
          }
          });
         
          try {
          //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
         
          const response = yield call(api.get, url);
          console.log("getUccDetailsByTenantId");
          console.log("uccList");
          console.log(JSON.stringify(response.data));
       
          yield put(ErrorActions.hideError());
          yield put(tmActions.tmActiveUccDetailsSuccess(response.data));
          //
          } catch (err) {
          yield put(ErrorActions.setError('error in getUccDetailsByTenantId'));
          }
          }

       



        export function* getMonitoredTenantsByFund(action) {
          const { userId,dateFrom,dateTo} = action.payload;
         
          const url = buildUrl('api/', {
          path: 'monitored-tenants-by-fund',
          queryParams: {
            userId :userId,
            startDate:dateFrom,
            endDate:dateTo
          }
          });
         
          try {
          //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
         
          const response = yield call(api.get, url);
         
       
          console.log("getMonitoredTenantsByFund");
          console.log(JSON.stringify(response.data));
          yield put(ErrorActions.hideError());
          yield put(tmActions.tmMonitoredTenantsByFundSuccess(response.data));
          //
          } catch (err) {
          yield put(ErrorActions.setError('error in getMonitoredTenantsByFund'));
          }
          }
 
          export function* getMonitoredTenantsByProperty(action) {
            const { userId,dateFrom,dateTo,pageSize,pageNum} = action.payload;
       
            const url = buildUrl('api/', {
            path: 'monitored-tenants-by-property',
            queryParams: {
              userId :userId,
              startDate:dateFrom,
              endDate:dateTo,
              pageSize:pageSize,
              pageNum:pageNum

            }
            });
           
            try {
            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
           
            const response = yield call(api.get, url);
           

            console.log("getMonitoredTenantsByProperty");
            console.log(JSON.stringify(response.data));
            yield put(ErrorActions.hideError());
            yield put(tmActions.tmMonitoredTenantsByPropertySuccess(response.data));
            //
            } catch (err) {
            yield put(ErrorActions.setError('error in getMonitoredTenantsByProperty'));
            }
            }


         

            export function* getMonitoredTenantsByCompanyAdmin(action) {
              const { organizationId,dateFrom,dateTo} = action.payload;
              const url = buildUrl('api/', {
              path: 'monitored-tenants-by-company-admin',
              queryParams: {
                organizationId:organizationId,
                startDate:dateFrom,
                endDate:dateTo
              }
              });
             
              try {
              //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
             
              const response = yield call(api.get, url);
              console.log("getMonitoredTenantsByCompanyAdmin");
              console.log(JSON.stringify(response.data));
              yield put(ErrorActions.hideError());
              yield put(tmActions.tmMonitoredTenantsByCompanyAdminSuccess(response.data));
              //
              } catch (err) {
              yield put(ErrorActions.setError('error in getMonitoredTenantsByCompanyAdmin'));
              }
              }
 
           
              export function* getSaveNotificationSettings(action) {
                const { notificationSettings} = action.payload;
               //alert(JSON.stringify(notificationSettings));
                const url = buildUrl('api/', {
                path: 'create-notification-settings',
               
                });
               
                try {
                //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
               
                const response = yield call(api.post, url,notificationSettings);
                console.log("getSaveNotificationSettings");
                console.log(JSON.stringify(response.data));
               // alert(JSON.stringify(response.data));
                yield put(ErrorActions.hideError());
                yield put(tmActions.tmSaveNotificationSettingsSuccess(response.data));
                //
                } catch (err) {
                yield put(ErrorActions.setError('error in getSaveNotificationSettings'));
                }
                }


                export function* getEditNotificationSettings(action) {
                  const { notificationSettings} = action.payload;
                  //alert(JSON.stringify(notificationSettings));
                  const url = buildUrl('api/', {
                  path: 'update-notification-settings',
                 
                  });
                 
                  try {
                  //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                 
                  const response = yield call(api.put, url,notificationSettings);
                  console.log("getEditNotificationSettings");
                  console.log(JSON.stringify(response.data));
                  //alert(JSON.stringify(response.data));
                  yield put(ErrorActions.hideError());
                  yield put(tmActions.tmEditNotificationSettingsSuccess(response.data));
                  //
                  } catch (err) {
                  yield put(ErrorActions.setError('error in getEditNotificationSettings'));
                  }
                  }

                  export function* getNotificationSettingsById(action) {
                    const { userId} = action.payload;
                 
                    const url = buildUrl('api/', {
                    path: 'get-notification-settings',
                    queryParams: {
                      userId:userId,
             
                    }
                 
                   
                    });
                   
                    try {
                    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                   
                    const response = yield call(api.get, url);
                    console.log("getNotificationSettingsById");
                    console.log(JSON.stringify(response.data));

                   // alert(JSON.stringify(response.data));
                    yield put(ErrorActions.hideError());
                    yield put(tmActions.tmNotificationSettingsByIdSuccess(response.data));
                    //
                    } catch (err) {
                    yield put(ErrorActions.setError('error in getNotificationSettingsById'));
                    }
                    }

                    export function* getMonotoredTenantsNews(action) {
                      const { tenantId,salesTriggers,startDate,endDate} = action.payload;
                      const url = buildUrl('api/', {
                      path: 'get-monitored-tenants-news',
                      queryParams: {
                        tenantId:tenantId,
                        salesTriggers:salesTriggers,
                        startDate:startDate,
                        endDate:endDate
                       
               
                      }
                   
                     
                      });
                     
                      try {
                      //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                     
                      const response = yield call(api.get, url);
                      console.log("getMonotoredTenantsNews");
                      // console.log("count"+response.data.insightSearchList);
                      console.log(JSON.stringify(response.data));
                      yield put(ErrorActions.hideError());
                      yield put(tmActions.tmMonitoredTenantsNewsSuccess(response.data));
                      //
                      } catch (err) {
                      yield put(ErrorActions.setError('error in getMonotoredTenantsNews'));
                      }
                      }


                      export function* getPopulateInitialDataForTenant(action) {
                        const { tenantId} = action.payload;
                        const url = buildUrl('api/', {
                        path: '',
                        queryParams: {
                          tenantId:tenantId,
                 
                        }
                     
                       
                        });
                       
                        try {
                        //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                       
                        const response = yield call(api.get, url);
                        console.log("getPopulateInitialDataForTenant");
                        console.log(JSON.stringify(response.data));
                        yield put(ErrorActions.hideError());
                        yield put(tmActions.tmPopulateInitialDataForTenantSuccess(response.data));
                        //
                        } catch (err) {
                        yield put(ErrorActions.setError('error in getPopulateInitialDataForTenant'));
                        }
                        }
 

                        export function* getTenantUploadStatusByOrgId(action) {
                          const { organisationId} = action.payload;
                         
                       
                          const url = buildUrl('api/', {
                            path: 'tenants-upload-status-log-by-org',
                            queryParams: {
                             // organisationId:"5f8ee03be74296262abdeb65",
                              //propertyId:"60090efa6f6f3d09adf5be0b"
                              organisationId:organisationId,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getTenantUploadStatusByOrgId");
                            const response = yield call(api.get, url);
                            console.log(response);
                         
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmTenantUploadStatusByOrgIdSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getTenantUploadStatusByOrgId'));
                          }
                        }

                        export function* getTenantFailureStatusLogListByOrg(action) {
                          const { organisationId} = action.payload;
                       
                       
                          const url = buildUrl('api/', {
                            path: 'tenants-upload-status-list-log-by-org',
                            queryParams: {
                             // organisationId:"5f8ee03be74296262abdeb65",
                              //propertyId:"60090efa6f6f3d09adf5be0b"
                              organisationId:organisationId,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getTenantFailureStatusLogListByOrg");
                            const response = yield call(api.get, url);
                            console.log(response);
                         
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmTenantFailureStatusLogListByOrgSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getTenantFailureStatusLogListByOrg'));
                          }
                        }
                   
                       
                        export function* getOrganizationScoreExecutionLogByOrg(action) {
                          const { organisationId} = action.payload;
                       
                       
                          const url = buildUrl('api/', {
                            path: 'get-organization-score-execution-log',
                            queryParams: {
                             // organisationId:"5f8ee03be74296262abdeb65",
                              //propertyId:"60090efa6f6f3d09adf5be0b"
                              organisationId:organisationId,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getOrganizationForExecutionLogByOrg");
                            const response = yield call(api.get, url);
                            console.log(response);
                         
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmOrganizationScoreExecutionLogByOrgSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getOrganizationForExecutionLogByOrg'));
                          }
                        }

                        export function* getOrganizationScoreExecutionLogList(action) {
                          const { organisationId,month,year} = action.payload;
                       
                       
                          const url = buildUrl('api/', {
                            path: 'get-organization-score-execution-log-list',
                            queryParams: {
                           
                              organisationId:organisationId,
                              month:month,
                              year:year,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getOrganizationScoreExecutionLogList");
                            const response = yield call(api.get, url);
                            console.log(response);
                         // alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmOrganizationScoreExecutionLogListSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getOrganizationScoreExecutionLogList'));
                          }
                        }

                        export function* getScoreExecutionLogListByTenantId(action) {
                          const { tenantId,month,year} = action.payload;
                       
                       
                          const url = buildUrl('api/', {
                            path: 'get-tenant-score-execution-log-list',
                            queryParams: {
                           
                              tenantId:tenantId,
                              month:month,
                              year:year,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getScoreExecutionLogListByTenantId");
                            const response = yield call(api.get, url);
                            console.log(response);
                          //  alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmScoreExecutionLogListByTenantIdSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getScoreExecutionLogListByTenantId'));
                          }
                        }
                        export function* getDistinctTenantsFromScoreExecutionLog(action) {
                          const { organisationId,month,year} = action.payload;
                       
                        // alert("saga"+organisationId);
                        // alert("saga"+month);
                        // alert("saga"+year);
                          const url = buildUrl('api/', {
                            path: 'get-distinct-tenants-score-execution-log',
                            queryParams: {
                           
                              organisationId:organisationId,
                              month:month,
                              year:year,
                             
                            }
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                           console.log("getDistinctTenantsFromScoreExecutionLog");
                            const response = yield call(api.get, url);
                            console.log(response);
                          //alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmDistinctTenantsFromScoreExecutionLogSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getDistinctTenantsFromScoreExecutionLog'));
                          }
                        }



                        export function* getOrgInitialDataStatus(action) {
                          const { organisationId } = action.payload;
                       
                        // alert("saga"+organisationId);
 
                          const url = buildUrl('api/', {
                            path: 'org-initial-data-status',
                            queryParams: {
                           
                              organisationId:organisationId,
                             
                             
                            }
                          });
                       
                          try {
                           
                           console.log("getOrgInitialDataStatus");
                            const response = yield call(api.get, url);
                            console.log(response);
                          //alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmOrgInitialDataStatusSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getOrgInitialDataStatus'));
                          }
                        }



                        export function* getPopulateInitialDataByOrg(action) {
                          const { organisationId } = action.payload;
                       
                        //alert("saga"+organisationId);
 
                          const url = buildUrl('api/', {
                            path: 'populate-tenants-initial-data-by-org',
                            queryParams: {
                           
                              organisationId:organisationId,
                             
                             
                            }
                          });
                       
                          try {
                           
                           console.log("getPopulateInitialDataByOrg");
                            const response = yield call(api.get, url);
                            console.log(response);
                          //alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmPopulateInitialDataByOrgSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getPopulateInitialDataByOrg'));
                          }
                        }



                        export function* getBankoReportByTenant(action) {
                          const { tenantId } = action.payload;
                       
                       // alert("saga"+tenantId);
 
                          const url = buildUrl('api/', {
                            path: 'get-banko-report-by-tenant',
                            queryParams: {
                           
                              tenantId:tenantId,
                             
                             
                            }
                          });
                       
                          try {
                           
                           console.log("getBankoReportByTenant");
                            const response = yield call(api.get, url);
                            console.log(response);
                         // alert(JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmGetBankoReportByTenantSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getBankoReportByTenant'));
                          }
                        }
       
                        export function* getUserByLoginAndEmail(action) {
                          const { login,email } = action.payload;
                          console.log("usernamesg"+JSON.stringify(login));
                          console.log("emailsg"+JSON.stringify(email));
                         
                          // console.log("saga"+JSON.stringify(getUserByLoginAndEmail));
                       // alert("saga"+tenantId);
 
                          const url = buildUrl('api/', {
                            path: 'user-by-login-email',
                            queryParams: {
                           
                              login:login,
                              email:email,
                             
                            }
                          });
                       
                          try {
                           
                           console.log("getUserByLoginAndEmail");
                            const response = yield call(api.get, url);
                            // console.log(response);
                           console.log("saga1"+JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmGetUserByLoginAndEmailSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getUserByLoginAndEmail'));
                          }
                        }
       
                        export function* getChangePassword(action) {
                          const { userId,password } = action.payload;
                          console.log("saga"+JSON.stringify(userId));
                          console.log("saga"+JSON.stringify(password));

                       // alert("saga"+tenantId);
 
                       
                          const url = buildUrl('api/', {
                            path: 'change-password',
                            queryParams: {
                           
                              userId:userId,
                              password :password                            
                            }
                           
                          });
                       
                          try {
                           
                           console.log("getChangePassword");
                            const response = yield call(api.get, url);
                            // console.log(response);
                            console.log("saga2"+JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmChangePasswordWithUserIdAndPasswordSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getChangePassword'));
                          }
                        }

                        export function* getUploadPirsLogList(action) {
                 console.log("getUploadPirsLogList");
 
                          const url = buildUrl('api/', {
                            path: 'upload-pirs-log-list',
                         
                          });
                       
                          try {
                           
                           console.log("getUploadPirsLogList");
                            const response = yield call(api.get, url);
                            // console.log(response);
                           console.log("getUploadPirsLogList"+JSON.stringify(response.data));
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmUploadPirsLogListSuccess(response.data));
                         
                          } catch (err) {
                            yield put(ErrorActions.setError('error in getUploadPirsLogList'));
                          }
                        }
           

                        export function* uploadPirsCsv(action) {

 
                          const { file,year,month} = action.payload;
                       
                        const formData=new FormData();
                        formData.append('uploadFile',file)
                       
                       
                          const url = buildUrl('api/', {
                            path: 'upload-pirs-csv',
                            queryParams: {
                              year:year,
                              month:month,
                              uploadFile:formData
                            }
                           
                           
                           
                          });
                       
                          try {
                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                         
                            const response = yield call(api.post, url,formData);
                       
                       
                            yield put(ErrorActions.hideError());
                            yield put(tmActions.tmUploadPirsCsvSuccess(response.data));
                            console.log("uploadPirsCsv"+response.data);
                       
                          } catch (err) {
                            yield put(ErrorActions.setError('error in uploadPirsCsv'));
                           
                          }
                        }                                      


                        export function* getPirsList(action) {
                          console.log("getPirsList");
                          const { month,year} = action.payload;
                                   const url = buildUrl('api/', {
                                     path: 'pirs-list',
                                     queryParams: {
                                      year:year,
                                      month:month
                                     
                                    }
                                   
                                   });
                                 
                                   try {
                                   
                                    console.log("getPirsList");
                                     const response = yield call(api.get, url);
                                     // console.log(response);
                                    console.log("getPirsList"+JSON.stringify(response.data));
                                     yield put(ErrorActions.hideError());
                                     yield put(tmActions.tmPirsListSuccess(response.data));
                                   
                                   } catch (err) {
                                     yield put(ErrorActions.setError('error in getPirsList'));
                                   }
                                 }

                                 export function* generateDashboard(action) {
                                  console.log("generateDashboard");
                                  const { organisationId} = action.payload;
                                           const url = buildUrl('api/', {
                                             path: 'generate-dashboard',
                                             queryParams: {
                                              organisationId:organisationId
                                             
                                             
                                            }
                                           
                                           });
                                         
                                           try {
                                           
                                            console.log("generateDashboard");
                                             const response = yield call(api.get, url);
                                             // console.log(response);
                                            console.log("generateDashboard"+JSON.stringify(response.data));
                                             yield put(ErrorActions.hideError());
                                             yield put(tmActions.tmGenerateDashboardSuccess(response.data));
                                           
                                           } catch (err) {
                                             yield put(ErrorActions.setError('error in generateDashboard'));
                                           }
                                         }

                                         export function* getDashboardUserList(action) {
                                          console.log("generateDashboard");
                                          const { organisationId} = action.payload;
                                                   const url = buildUrl('api/', {
                                                     path: 'dashboard-user-list',
                                                     queryParams: {
                                                      organizationId:organisationId
                                                     
                                                     
                                                    }
                                                   
                                                   });
                                                 
                                                   try {
                                                   
                                                    console.log("getDashboardUserList");
                                                     const response = yield call(api.get, url);
                                                     // console.log(response);
                                                    console.log("generateDagetDashboardUserListshboard"+JSON.stringify(response.data));
                                                     yield put(ErrorActions.hideError());
                                                     yield put(tmActions.tmDashboardUserListSuccess(response.data));
                                                   
                                                   } catch (err) {
                                                     yield put(ErrorActions.setError('error in getDashboardUserList'));
                                                   }
                                                 }
                                                 export function* generateDashboardByUser(action) {
                                                  console.log("generateDashboardByUser");
                                                  const { userId} = action.payload;
                                                           const url = buildUrl('api/', {
                                                             path: 'generate-dashboard-by-user',
                                                             queryParams: {
                                                              userId:userId
                                                             
                                                             
                                                            }
                                                           
                                                           });
                                                         
                                                           try {
                                                           
                                                            console.log("generateDashboardByUser");
                                                             const response = yield call(api.get, url);
                                                             // console.log(response);
                                                            console.log("generateDashboardByUser"+JSON.stringify(response.data));
                                                             yield put(ErrorActions.hideError());
                                                             yield put(tmActions.tmGenerateDashboardByUserSuccess(response.data));
                                                           
                                                           } catch (err) {
                                                             yield put(ErrorActions.setError('error in generateDashboardByUser'));
                                                           }
                                                         }



                                                         export function* getTenantListByRiskBasedOnCategory(action) {
                                                          console.log("getTenantListByRiskBasedOnCategory");
                                                          const { propertyId,category,year,month,organizationId,userId,pageNum,pageSize} = action.payload;
                                                          console.log(propertyId);
                                                          console.log(category);
                                                          console.log(year);
                                                          console.log(month);
                                                          console.log(organizationId);
                                                          console.log(userId);
                                                          console.log(pageNum);
                                                          console.log(pageSize);

                                                                   const url = buildUrl('api/', {
                                                                     path: 'tenant-list-by-risk',
                                                                     queryParams: {
                                                                      propertyId:propertyId,
                                                                      category:category,
                                                                      year:year,
                                                                      month:month,
                                                                      organisationId:organizationId,
                                                                      userId:userId,
                                                                      pageNum:pageNum,
                                                                      pageSize:pageSize,
     
                                                                    }
                                                                   
                                                                   });
                                                                 
                                                                   try {
                                                                   
                                                                    console.log("getTenantListByRiskBasedOnCategory");
                                                                     const response = yield call(api.get, url);
                                                                    console.log(response.data.totalPages);
                                                                    console.log("getTenantListByRiskBasedOnCategory"+JSON.stringify(response.data));
                                                                     yield put(ErrorActions.hideError());
                                                                     yield put(tmActions.tmTenantListByRiskSuccess(response.data));
                                                                   
                                                                   } catch (err) {
                                                                     yield put(ErrorActions.setError('error in getTenantListByRiskBasedOnCategory'));
                                                                   }
                                                                 }



                                                                 export function* getTenantScoreListByRiskBasedOnCategory(action) {
                                                                  console.log("getTenantScoreListByRiskBasedOnCategory");
                                                                  const { organizationId,propertyId,userId,category,year,month,pageNum,pageSize} = action.payload;
                                                                  console.log("1"+propertyId);
                                                                  console.log("1"+category);
                                                                  console.log("1"+year);
                                                                  console.log("1"+month);
                                                                  console.log("1"+organizationId);
                                                                  console.log("1"+userId);
                                                                  console.log("1"+pageNum);
                                                                  console.log("1"+pageSize);
       
                                                                           const url = buildUrl('api/', {
                                                                             path: 'tenant-score-list-by-risk',
                                                                             queryParams: {
                                                                              organizationId:organizationId,
                                                                              propertyId:propertyId,
                                                                              userId:userId,
                                                                              category:category,
                                                                              year:year,
                                                                              month:month,  
                                                                              pageNum:pageNum,
                                                                              pageSize:pageSize,
             
                                                                            }
                                                                           
                                                                           });
                                                                         
                                                                           try {
                                                                           
                                                                            console.log("getTenantScoreListByRiskBasedOnCategory");
                                                                             const response = yield call(api.get, url);
                                                                            //console.log(response.data.totalPages);
                                                                            console.log("getTenantScoreListByRiskBasedOnCategory"+JSON.stringify(response.data));
                                                                             yield put(ErrorActions.hideError());
                                                                             yield put(tmActions.tmTenantScoreListByRiskSuccess(response.data));
                                                                           
                                                                           } catch (err) {
                                                                             yield put(ErrorActions.setError('error in getTenantScoreListByRiskBasedOnCategory'));
                                                                           }
                                                                         }


                                                                         export function* getFundDashboardGridView(action) {
                                                                          console.log("getFundDashboardGridView");
                                                                          const { orgId,userId} = action.payload;
                                                                       
                                                                          console.log(orgId);
                                                                          console.log(userId);
                                                                         
               
                                                                                   const url = buildUrl('api/', {
                                                                                     path: 'fund-dashboard-grid',
                                                                                     queryParams: {
                                                                                      organizationId:orgId,
                                                                                   
                                                                                      userId:userId,
                                                                                      sortProperty:'fundName',
                     
                                                                                    }
                                                                                   
                                                                                   });
                                                                                 
                                                                                   try {
                                                                                   
                                                                                    console.log("getFundDashboardGridView");
                                                                                     const response = yield call(api.get, url);
                                                                                    //console.log(response.data.totalPages);
                                                                                 
                                                                                    console.log("getFundDashboardGridView"+JSON.stringify(response.data));
                                                                                     yield put(ErrorActions.hideError());
                                                                                     yield put(tmActions.tmFundDashboardGridViewSuccess(response.data));
                                                                                   
                                                                                   } catch (err) {
                                                                                     yield put(ErrorActions.setError('error in getFundDashboardGridView'));
                                                                                   }
                                                                                 }
                                                                                 export function* getCompanyAdminFundDashboardGridView(action) {
                                                                                  console.log("getCompanyAdminFundDashboardGridView");
                                                                                  const { organizationId,userId,pageSize,pageNum} = action.payload;
                                                                               
                                                                                  console.log(organizationId);
                                                                                  console.log(userId);
                                                                                 
                                                                                 
                       
                                                                                           const url = buildUrl('api/', {
                                                                                             path: 'company-admin-dashboard-grid',
                                                                                             queryParams: {
                                                                                              organizationId:organizationId,
                                                                                              userId:userId,
                                                                                              pageSize:pageSize,
                                                                                              pageNum:pageNum,
                                                                                             
                                                                                             
                             
                                                                                            }
                                                                                           
                                                                                           });
                                                                                         
                                                                                           try {
                                                                                           
                                                                                            console.log("getCompanyAdminFundDashboardGridView");
                                                                                             const response = yield call(api.get, url);
                                                                                            //console.log(response.data.totalPages);
                                                                                            console.log("getCompanyAdminFundDashboardGridView"+JSON.stringify(response.data));
                                                                                             yield put(ErrorActions.hideError());
                                                                                             yield put(tmActions.tmCompanyAdminFundDashboardGridViewSuccess(response.data));
                                                                                           
                                                                                           } catch (err) {
                                                                                             yield put(ErrorActions.setError('error in getCompanyAdminFundDashboardGridView'));
                                                                                           }
                                                                                         }



                                                                                         export function*  getFundPropertyGridViewDashboard(action) {
                                                                                          console.log(" getFundPropertyGridViewDashboard");
                                                                                          const {fundId,userId,pageSize,pageNum} = action.payload;
                                                                                       
                                                                                          console.log(fundId);
                                                                                          console.log(userId);
                                                                                          console.log(pageSize);
                                                                                          console.log(pageNum);

                               
                                                                                                   const url = buildUrl('api/', {
                                                                                                     path: 'fund-property-dashboard-grid',
                                                                                                     queryParams: {
                                                                                                      fundId:fundId,
                                                                                                      userId:userId,
                                                                                                      sortProperty:'propertyName',
                                                                                                      pageSize:pageSize,
                                                                                                      pageNum:pageNum,
                                     
                                                                                                    }
                                                                                                   
                                                                                                   });
                                                                                                 
                                                                                                   try {
                                                                                                   
                                                                                                    console.log(" getFundPropertyGridViewDashboard");
                                                                                                     const response = yield call(api.get, url);
                                                                                                    // console.log(response.data[0].colorCode);
                                                                                                    console.log(" getFundPropertyGridViewDashboard"+JSON.stringify(response.data));
                                                                                                    //alert("test......................");
                                                                                                    yield put(ErrorActions.hideError());
                                                                                                     yield put(tmActions.tmGetFundPropertyGridViewDashboardSuccess(response.data));
                                                                                                   
                                                                                                   } catch (err) {
                                                                                                     yield put(ErrorActions.setError('error in  getFundPropertyGridViewDashboard'));
                                                                                                   }
                                                                                                 }



                                                                                                 export function*  getPropertyDashboardGridView(action) {
                                                                                                  console.log("getPropertyDashboardGridView");
                                                                                                  const {organisationId,userId,pageSize,pageNum} = action.payload;
                                                                                               
                                                                                                  console.log(organisationId);
                                                                                                  console.log(userId);
                                                                                                  console.log(pageSize);
                                                                                                  console.log(pageNum);
       
                                       
                                                                                                           const url = buildUrl('api/', {
                                                                                                             path: 'property-dashboard-grid',
                                                                                                             queryParams: {
                                                                                                              organizationId:organisationId,
                                                                                                              userId:userId,
                                                                                                              pageSize:pageSize,
                                                                                                              pageNum:pageNum,
                                             
                                                                                                            }
                                                                                                           
                                                                                                           });
                                                                                                         
                                                                                                           try {
                                                                                                           
                                                                                                            console.log("   getPropertyDashboardGridView");
                                                                                                             const response = yield call(api.get, url);
                                                                                                            //console.log(response.data.totalPages);
                                                                                                            console.log("getPropertyDashboardGridView"+JSON.stringify(response.data));
                                                                                                             yield put(ErrorActions.hideError());
                                                                                                             yield put(tmActions.tmPropertyDashboardGridViewSuccess(response.data));
                                                                                                           
                                                                                                           } catch (err) {
                                                                                                             yield put(ErrorActions.setError('error in    getPropertyDashboardGridView'));
                                                                                                           }
                                                                                                         }

               
                                                                                                         export function*    getBankruptcyDetailsOfUser(action) {
                                                                                                          console.log("getBankruptcyDetailsOfUser");
                                                                                                          const {userId} = action.payload;

                                                                                                          console.log(userId);
                                                                                                         
                                                                                                          const url = buildUrl('api/', {
                                                                                                            path: 'get-banko-report-by-user',
                                                                                                            queryParams: {
                                                                                                            
                                                                                                             userId:userId,
                                                                                                        

                                            
                                                                                                           }
                                                                                                          
                                                                                                          });
                                                                                                        
                                                                                                          try {
                                                                                                            console.log("   getBankruptcyDetailsOfUser");
                                                                                                            const response = yield call(api.get, url);
                                                                                                           //console.log(response.data.totalPages);
                                                                                                           console.log("getBankruptcyDetailsOfUser"+JSON.stringify(response.data));
                                                                                                            yield put(ErrorActions.hideError());
                                                                                                            yield put(tmActions.tmGetBankrutcyDetailsOfUserSuccess(response.data));
                                                                                                          
                                                                                                          } catch (err) {
                                                                                                            yield put(ErrorActions.setError('error in    getBankruptcyDetailsOfUser'));
                                                                                                          }
                                                                                                        }
                                                                                                       
                                                                                                         export function*  getFundApexChartData(action) {
                                                                                                          console.log("getFundApexChartData");
                                                                                                          const {fundId,month,year,organizationId,userId} = action.payload;
                                                                                                    

                                                                                                          console.log(fundId);
                                                                                                          console.log(month);
                                                                                                          console.log(year);
                                                                                                          console.log(organizationId);
                                                                                                          console.log(userId);
                
                                                
                                                                                                                   const url = buildUrl('api/', {
                                                                                                                     path: 'fund-apex-chart-data',
                                                                                                                     queryParams: {
                                                                                                                      fundId:fundId,
                                                                                                                      month:month,
                                                                                                                      year:year,
                                                                                                                      organizationId:organizationId,
                                                                                                                      userId:userId,
                                                                                                                     }
                                                     
                                                                                                                    });
                                                                                                                         
                                                                                                                    try {
                                                                                                                    
                                                                                                                    console.log("   getFundApexChartData");
                                                                                                                     const response = yield call(api.get, url);
                                                                                                                    //console.log(response.data.totalPages);
                                                                                                                    console.log("getFundApexChartData"+JSON.stringify(response.data));
                                                                                                                     yield put(ErrorActions.hideError());
                                                                                                                     yield put(tmActions.tmFundApexChartDataSuccess(response.data));
                                                                                                                   
                                                                                                                   } catch (err) {
                                                                                                                     yield put(ErrorActions.setError('error in    getFundApexChartData'));
                                                                                                                   }
                                                                                                                 }
                                                   
                                                     
                                                                                                                 export function*  getFundPropertyApexChartData(action) {
                                                                                                                  console.log("getFundPropertyApexChartData");
                                                                                                                  const {propertyId,month,year,organizationId,userId} = action.payload;
                                                                                                               
                                                                                                                  console.log(propertyId);
                                                                                                                  console.log(month);
                                                                                                                  console.log(year);
                                                                                                                  console.log(organizationId);
                                                                                                                  console.log(userId);
                        
                                                        
                                                                                                                           const url = buildUrl('api/', {
                                                                                                                             path: 'fund-property-apex-chart-data',
                                                                                                                             queryParams: {
                                                                                                                              propertyId:propertyId,
                                                                                                                              month:month,
                                                                                                                              year:year,
                                                                                                                              organizationId:organizationId,
                                                                                                                              userId:userId,
                                                             
                                                                                                                            }
                                                                                                                          });
                                                                                                                         
                                                                                                                          try {
                                                                                                                            console.log("   getFundPropertyApexChartData");
                                                                                                                            const response = yield call(api.get, url);
                                                                                                                           //console.log(response.data.totalPages);
                                                                                                                           console.log("getFundPropertyApexChartData"+JSON.stringify(response.data));
                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                            yield put(tmActions.tmFundPropertyApexChartDataSuccess(response.data));
                                                                                                                          
                                                                                                                          } catch (err) {
                                                                                                                            yield put(ErrorActions.setError('error in    getFundPropertyApexChartData'));
                                                                                                                          }
                                                                                                                        }

                                                                                                                         export function*  getPropertyApexChartData(action) {
                                                                                                                              console.log("getPropertyApexChartData");
                                                                                                                              const {propertyId,month,year,organizationId,userId} = action.payload;
                                                                                                                           
                                                                                                                              console.log(propertyId);
                                                                                                                              console.log(month);
                                                                                                                              console.log(year);
                                                                                                                              console.log(organizationId);
                                                                                                                              console.log(userId);
                                    
                                                                    
                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                         path: 'property-apex-chart-data',
                                                                                                                                         queryParams: {
                                                                                                                                          propertyId:propertyId,
                                                                                                                                          month:month,
                                                                                                                                          year:year,
                                                                                                                                          organizationId:organizationId,
                                                                                                                                          userId:userId,
                                                                         
                                                                                                                                        }  
                                                                                                                                       
                                                                                                                                    });
                                                                                                                                   
                                                                                                                                    try {
                                                                                                                                      console.log("   getPropertyApexChartData");
                                                                                                                                      const response = yield call(api.get, url);
                                                                                                                                     //console.log(response.data.totalPages);
                                                                                                                                     console.log("getPropertyApexChartData"+JSON.stringify(response.data));
                                                                                                                                      yield put(ErrorActions.hideError());
                                                                                                                                      yield put(tmActions.tmPropertyApexChartDataSuccess(response.data));
                                                                                                                                    
                                                                                                                                    } catch (err) {
                                                                                                                                      yield put(ErrorActions.setError('error in    getPropertyApexChartData'));
                                                                                                                                    }
                                                                                                                                  }
                                                   
                                                                                                                 export function*    saveCompanySearchList(action) {
                                                                                                                  console.log("saveCompanySearchList");
                                                                                                                  const {rentRollSearchResultItems,userId} = action.payload;
                                                                                                                  // const rentRollSearchResultItems=[{  name:"Amazon",
                                                                                                                  // id:"",
                                                                                                                  // state1:"PA",
                                                                                                                  // city:"philadelphia",
                                                                                                                  // country:"US",
                                                                                                                  // address1:"ABC",
                                                                                                                  // address2:"dgfgh",
                                                                                                                  // zip:"123654",}]
                                                                                                                  // const {userId} = action.payload;
                                                                                                               
                                                                                                                  alert("rentRollSearchResultItems"+JSON.stringify(rentRollSearchResultItems));
                                                                                                                  console.log(userId);
                                                                                                                  console.log(rentRollSearchResultItems);
                                                                                                                 
                                                                                                                           const url = buildUrl('api/', {

                                                                                                                             path: 'rent-roll-search-result-item-company-create',
                                                                                                                          
                                                                                                                       
                                                                                                                          });
                                                                                                                                 
                                                                                                                          try {
                                                                                                                           
                                                                                                                            console.log("   saveCompanySearchList");
                                                                                                                             const response = yield call(api.post, url,rentRollSearchResultItems);

                                                                                                                            //console.log(response.data.totalPages);
                                                                                                                            console.log("saveCompanySearchList"+JSON.stringify(response.data));
                                                                                                                             yield put(ErrorActions.hideError());
                                                                                                                             yield put(tmActions.tmCompanySearchListSaveSuccess(response.data));
                                                                                                                           
                                                                                                                           } catch (err) {
                                                                                                                             yield put(ErrorActions.setError('error in    saveCompanySearchList'));
                                                                                                                           }
                                                                                                                         }

                                                                                                                           
                                                                                                                           
                                                                                                                              
                                                                                                                      
                                                                                                                         export function*    savePropertySearchList(action) {
                                                                                                                          console.log("savePropertySearchList");
                                                                                                                          const {rentRollSearchResultItems,userId} = action.payload;
                                                                                                                         
                                                                                                                          alert("rentRollSearchResultItems"+JSON.stringify(rentRollSearchResultItems));
                                                                                                                          console.log(rentRollSearchResultItems);
                                                                                                                          console.log(userId);
                                                                                                                         
                                                                                                                                   const url = buildUrl('api/', {
                                                                                                                                     path: 'rent-roll-search-result-item-property-create',
                                                                                                                                  
                                                                                                                                
                                                                                                                                   
                                                                                                                                   });
                                                                                                                                 
                                                                                                                                   try {


                                                                                                                                    console.log("   savePropertySearchList");
                                                                                                                                    const response = yield call(api.post, url,rentRollSearchResultItems);
                                                                                                                                   //console.log(response.data.totalPages);
                                                                                                                                   console.log("savePropertySearchList"+JSON.stringify(response.data));
                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                    yield put(tmActions.tmPropertySearchListSaveSuccess(response.data));
                                                                                                                                  
                                                                                                                                  } catch (err) {
                                                                                                                                    yield put(ErrorActions.setError('error in    savePropertySearchList'));
                                                                                                                                  }
                                                                                                                                }
                                                          
                                                                                                                        export function*    savePropertySearch(action) {
                                                                                                                         console.log("savePropertySearch");
                                                                                                                         const {propertyCompanySearchrequest} = action.payload;
                                                                                                                       console.log("saga"+JSON.stringify(propertyCompanySearchrequest));
                                                                                                                        
                                                                                                                                  const url = buildUrl('api/', {
                                                                                                                                    path: '/property-company-list',
                                                                                                                                    queryParams: {
    
                                                                                                                                    
                                                                                                                                   address :propertyCompanySearchrequest.address,
                                                                                                                                   city :propertyCompanySearchrequest.city,
                                                                                                                                   state :propertyCompanySearchrequest.state,
                                                                                                                                   postCode :propertyCompanySearchrequest.postCode,
                                                                                                                                   // exact:propertyCompanySearchrequest.exact
                                                                                                                                
                                                                                                                                   }
                                                                                                                                          
                                                                                                                                 
                                                                             
                                                                                                                                  
                                                                                                                                  });
                                                                                                                                
                                                                                                                                  try {
                                                                                                                                  
                                                                                                                                   console.log("   savePropertySearch");
                                                                                                                                    const response = yield call(api.get, url);
                                                                                                                                   console.log("pg"+response.data.totalCount);
                                                                                                                               
                                                                                                                                   console.log("savePropertySearch"+JSON.stringify(response.data));
                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                    yield put(tmActions.tmPropertySearchSuccess(response.data));
                                                                                                                                  
                                                                                                                                  } catch (err) {
                                                                                                                                    yield put(ErrorActions.setError('error in    savePropertySearch'));
                                                                                                                                  }
                                                                                                                                }
                                                                  
                                                                                                                               
                                                                                                                                        export function* saveCompanySearch(action) {
                                                                                                                                         console.log("saveCompanySearch");
                                                                                                                                       
                                                                                                                                         const { companySearchRequest } = action.payload;
                                                                                                                                        console.log("saga1"+JSON.stringify(companySearchRequest));
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/company-search',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                           name :companySearchRequest.name,
                                                                                                                                           website:companySearchRequest.website,
                                                                                                                                           state :companySearchRequest.state,
                                                                                                                                           city :companySearchRequest.city,
                                                                                                                                           address :companySearchRequest.address,
                                                                                                                                           postCode :companySearchRequest.postalCode,
                                                                                                                                           exact:companySearchRequest.exact
                                                                                                                                        
                                                                                                                                           }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                           console.log("saveCompanySearch");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmCompanySearchSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('saveCompanySearch'));
                                                                                                                                         }
                                                                                                                                       }

                                                                                                                                       export function* getSendEmail(action) {
                                                                                                                                         console.log("getSendEmail");
                                                                                                                                       
                                                                                                                                         // const {  } = action.payload;
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/test-mail',
                                                                                                                                       
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                           console.log("getSendEmail");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmSendEmailSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('getSendEmail'));
                                                                                                                                         }
                                                                                                                                       }


                                                                                                                                       export function* getTenantCountInRiskCount(action) {
                                                                                                                                         console.log("getTenantCountInRiskCount");
                                                                                                                                       
                                                                                                                                         const { propertyId } = action.payload;

                                                                                                                                       
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/tenant-count-in-risk-count',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                             propertyId:propertyId,
                                                                                                                         
                                                                                                                                             }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                           console.log("getTenantCountInRiskCount");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmTotalTenantCountInRiskCountSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('getTenantCountInRiskCount'));
                                                                                                                                         }
                                                                                                                                       }

                                                                                                                                       export function* genaerateFundInitialReport(action) {
                                                                                                                                         console.log("genaerateFundInitialReport");
                                                                                                                                       
                                                                                                                                         const { fundId } = action.payload;

                                                                                                                                       
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/fund-tenant-initial-report-gen',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                             id:fundId,
                                                                                                                         
                                                                                                                                             }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.post, url);
                                                                                                                                           console.log("genaerateFundInitialReport");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmGenarateFundInitialReportSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('genaerateFundInitialReport'));
                                                                                                                                         }
                                                                                                                                       }     

                                                                                                                                       export function* genaeratePropertyInitialReport(action) {
                                                                                                                                         console.log("genaeratePropertyInitialReport");
                                                                                                                                       
                                                                                                                                         const { propertyId } = action.payload;

                                                                                                                                       
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/property-tenant-initial-report-gen',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                             id:propertyId,
                                                                                                                         
                                                                                                                                             }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.post, url);
                                                                                                                                           console.log("genaeratePropertyInitialReport");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmGenaratePropertyInitialReportSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('genaeratePropertyInitialReport'));
                                                                                                                                         }
                                                                                                                                       }   
                                                                                                                                       
                                                                                                                                       export function* genaerateTenantInitialReport(action) {
                                                                                                                                         console.log("genaerateTenantInitialReport");
                                                                                                                                       
                                                                                                                                         const { tenantId } = action.payload;

                                                                                                                                       
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/tenant-initial-report-gen',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                             id:tenantId,
                                                                                                                         
                                                                                                                                             }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.post, url);
                                                                                                                                           console.log("genaerateTenantInitialReport");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmGenarateTenantInitialReportSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('genaerateTenantInitialReport'));
                                                                                                                                         }
                                                                                                                                       }  
                                                                                                                                       
                                                                                                                                       export function* generateScoreForFund(action) {
                                                                                                                                         console.log("generateScoreForFund");
                                                                                                                                       
                                                                                                                                         const { fundId } = action.payload;

                                                                                                                                       
                                                                                                                                        
                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                           path: '/populate-initial-data-by-fund',
                                                                                                                                           queryParams: {
                                                                                                                                            
                                                                                                                                             fundId:fundId,
                                                                                                                         
                                                                                                                                             }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                       
                                                                                                                                         try {
                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                           console.log("generateScoreForFund");
                                                                                                                                           console.log(response.data);
                                                                                                                                           yield put(ErrorActions.hideError());
                                                                                                                                          
                                                                                                                                           yield put(tmActions.tmGenarateScoreForFundSuccess(response.data));
                                                                                                                                          
                                                                                                                                         } catch (err) {
                                                                                                                                        
                                                                                                                                           yield put(ErrorActions.setError('generateScoreForFund'));
                                                                                                                                         }
                                                                                                                                       }     
                                                     

                                                                                                                                  export function*  getTenantScoreGraphDataByPropertyIdAndUserId(action) {
                                                                                                                                  
                                                                                                                                    const {userId,propertyId} = action.payload;
                                                                                                                                 
                                                                                                                                    console.log(propertyId);
                                                                                                          
                                                                                                                                    console.log(userId);
                                          
                                                                          
                                                                                                                                             const url = buildUrl('api/', {
                                                                                                                                               path: 'tenant-graph-data-by-property',
                                                                                                                                               queryParams: {
                                                                                                                                                userId:userId,
                                                                                                                                                propertyId:propertyId,
                                                                                                                                              }
                                                                                                                                             
                                                                                                                                             });
                                                                                                                                           
                                                                                                                                             try {
                                                                                                                                              
                                                                                                                                              console.log("   getTenantScoreGraphDataByPropertyIdAndUserId");
                                                                                                                                               const response = yield call(api.get, url);
                                                                                                                                              //console.log(response.data.totalPages);
                                                                                                                                              console.log("getTenantScoreGraphDataByPropertyIdAndUserId"+JSON.stringify(response.data));
                                                                                                                                               yield put(ErrorActions.hideError());
                                                                                                                                               yield put(tmActions.tmGetTenantScoreGraphDataByPropertyIdAndUserIdSuccess(response.data));
                                                                                                                                             
                                                                                                                                             } catch (err) {
                                                                                                                                               yield put(ErrorActions.setError('error in  getTenantScoreGraphDataByPropertyIdAndUserId'));
                                                                                                                                             }
                                                                                                                                            }


                                                                                                                                            export function*  getTenantRiskCountByPropertyGridView(action) {
                                                                                                                                  
                                                                                                                                              const {propertyId,userId} = action.payload;
                                                                                                                                           
                                                                                                                                              console.log(propertyId);
                                                                                                                    
                                                                                                                                              console.log(userId);
                                                    
                                                                                    
                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                         path: 'tenant-risk-count-by-property-grid-view',
                                                                                                                                                         queryParams: {
                                                                                                                                                         
                                                                                                                                                          propertyId:propertyId,
                                                                                                                                                          userId:userId,
                                                                                                                                                        }
                                                                                                                                                       
                                                                                                                                                       });
                                                                                                                                                     
                                                                                                                                                       try {
                                                                                                                                                        
                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                            
                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                         yield put(tmActions.tmGetTenantRiskCountByPropertyGridViewSuccess(response.data));
                                                                                                                                                         console.log("getTenantRiskCountByPropertyGridView..........."+JSON.stringify(response.data));
                                                     
                                                                                                                                                       } catch (err) {
                                                                                                                                                         yield put(ErrorActions.setError('error in  getTenantRiskCountByPropertyGridView'));
                                                                                                                                                       }
                                                                                                                                                      }

                                                                                                                                                      export function*  getTenantBarGraphData(action) {
                                                                                                                                  
                                                                                                                                                        const {propertyId} = action.payload;
                                                                                                                                                     
                                                                                                                                                        console.log(propertyId);
                                                                                                                              
                                                                                                                                                        
                                                              
                                                                                              
                                                                                                                                                                 const url = buildUrl('api/', {
                                                                                                                                                                   path: 'tenant-bar-graph-data',
                                                                                                                                                                   queryParams: {
                                                                                                                                                                   
                                                                                                                                                                    propertyId:propertyId,
                                                                                                                                                                    
                                                                                                                                                                  }
                                                                                                                                                                 
                                                                                                                                                                 });
                                                                                                                                                               
                                                                                                                                                                 try {
                                                                                                                                                                  
                                                                                                                                                                  // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                   const response = yield call(api.get, url);
                                                                                                                                                                  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                      
                                                                                                                                                                  yield put(ErrorActions.hideError());
                                                                                                                                                                   yield put(tmActions.tmGetTenantBarGraphDataSuccess(response.data));
                                                                                                                                                                   console.log("getTenantBarGraphData..........."+JSON.stringify(response.data));
                                                               
                                                                                                                                                                 } catch (err) {
                                                                                                                                                                   yield put(ErrorActions.setError('error in  getTenantBarGraphData'));
                                                                                                                                                                 }
                                                                                                                                                                }

                                                                                                                                                                export function*  getPropertyTenantLineChart(action) {
                                                                                                                                  
                                                                                                                                                                  const {propertyId} = action.payload;
                                                                                                                                                               
                                                                                                                                                                  console.log(propertyId);
                                                                                                                                        
                                                                                                                                                                  
                                                                        
                                                                                                        
                                                                                                                                                                           const url = buildUrl('api/', {
                                                                                                                                                                             path: 'credit-score-chart-data',
                                                                                                                                                                             queryParams: {
                                                                                                                                                                             
                                                                                                                                                                              propertyId:propertyId,
                                                                                                                                                                              
                                                                                                                                                                            }
                                                                                                                                                                           
                                                                                                                                                                           });
                                                                                                                                                                         
                                                                                                                                                                           try {
                                                                                                                                                                            
                                                                                                                                                                            // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                             const response = yield call(api.get, url);
                                                                                                                                                                            //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                
                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                             yield put(tmActions.tmGetPropertyTenantLineChartSuccess(response.data));
                                                                                                                                                                             console.log("getPropertyTenantLineChart..........."+JSON.stringify(response.data));
                                                                         
                                                                                                                                                                           } catch (err) {
                                                                                                                                                                             yield put(ErrorActions.setError('error in  getPropertyTenantLineChart'));
                                                                                                                                                                           }
                                                                                                                                                                          }

                                                                                                                                                                          export function*  getPropertyTenantLineChartPagination(action) {
                                                                                                                                  
                                                                                                                                                                            const {propertyId,pageNum,pageSize} = action.payload;
                                                                                                                                                                         
                                                                                                                                                                            console.log(propertyId);
                                                                                                                                                                            console.log("pageNum"+pageNum);
                                                                                                                                                                            console.log("pageSize"+pageSize);
                                                                                                                                                  
                                                                                                                                                                            
                                                                                  
                                                                                                                  
                                                                                                                                                                                     const url = buildUrl('api/', {
                                                                                                                                                                                       path: 'credit-score-chart-data-pagination',
                                                                                                                                                                                       queryParams: {
                                                                                                                                                                                       
                                                                                                                                                                                        propertyId:propertyId,
                                                                                                                                                                                        pageNum:pageNum,
                                                                                                                                                                                        pageSize:pageSize,
                                                                                                                                                                                        
                                                                                                                                                                                      }
                                                                                                                                                                                     
                                                                                                                                                                                     });
                                                                                                                                                                                   
                                                                                                                                                                                     try {
                                                                                                                                                                                      
                                                                                                                                                                                      // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                       const response = yield call(api.get, url);
                                                                                                                                                                                      //console.log(response.data.totalPages);
                                                                                                                                                                                      console.log(response.data.totalPages);                                                                                         
                                                                                                                                                                                      yield put(ErrorActions.hideError());
                                                                                                                                                                                       yield put(tmActions.tmGetPropertyTenantLineChartPaginationSuccess(response.data));
                                                                                                                                                                                       console.log("getPropertyTenantLineChartPagination..........."+JSON.stringify(response.data));
                                                                                   
                                                                                                                                                                                     } catch (err) {
                                                                                                                                                                                       yield put(ErrorActions.setError('error in  getPropertyTenantLineChartPagination'));
                                                                                                                                                                                     }
                                                                                                                                                                                    }


                                                                                                                                                                                    export function*  getPropertyTenantSearch(action) {
                                                                                                                                  
                                                                                                                                                                                      const {searchTerm,propertyId,pageNum,pageSize} = action.payload;
                                                                                                                                                                                   
                                                                                                                                                                                      console.log(propertyId);
                                                                                                                                                                                      console.log("pageNum"+pageNum);
                                                                                                                                                                                      console.log("pageSize"+pageSize);
                                                                                                                                                                                      console.log("searchTerm"+searchTerm);
                                                                                                                                                            
                                                                                                                                                                                      
                                                                                            
                                                                                                                            
                                                                                                                                                                                               const url = buildUrl('api/', {
                                                                                                                                                                                                 path: 'credit-score-chart-search',
                                                                                                                                                                                                 queryParams: {
                                                                                                                                                                                                 
                                                                                                                                                                                                  searchTerm:searchTerm,
                                                                                                                                                                                                  propertyId:propertyId,
                                                                                                                                                                                                  pageNum:pageNum,
                                                                                                                                                                                                  pageSize:pageSize,
                                                                                                                                                                                                  
                                                                                                                                                                                                }
                                                                                                                                                                                               
                                                                                                                                                                                               });
                                                                                                                                                                                             
                                                                                                                                                                                               try {
                                                                                                                                                                                                
                                                                                                                                                                                                // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                 const response = yield call(api.get, url);
                                                                                                                                                                                                //console.log(response.data.totalPages);
                                                                                                                                                                                                console.log(response.data.totalPages);   
                                                                                                                                                                                                console.log("searchTerm"+response.data.searchTerm);
                                                                                                                                                                                               console.log("tenantList"+JSON.stringify(response.data.tenantList));
                                                                                                                                                                                                console.log("totalCount"+response.data.totalCount);                                                                                           
                                                                                                                                                                                                yield put(ErrorActions.hideError());
                                                                                                                                                                                                 yield put(tmActions.tmGetPropertyTenantSeachSuccess(response.data));
                                                                                                                                                                                                 console.log("getPropertyTenantSearch..........."+JSON.stringify(response.data));
                                                                                             
                                                                                                                                                                                               } catch (err) {
                                                                                                                                                                                                 yield put(ErrorActions.setError('error in  getPropertyTenantSearch'));
                                                                                                                                                                                               }
                                                                                                                                                                                              }
                                                                                                                                                                                              export function*  getPreviousMonthTenantRiskCount(action) {
                                                                                                                                  
                                                                                                                                                                                                const {propertyId,userId} = action.payload;
                                                                                                                                                                                             
                                                                                                                                                                                                console.log(propertyId);
                                                                                                                                                                                                console.log("userId"+userId);
                                                                                                                                                                                               
                                                                                                                                                                      
                                                                                                                                                                                                
                                                                                                      
                                                                                                                                      
                                                                                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                                                                                           path: 'previous-month-tenant-risk-count',
                                                                                                                                                                                                           queryParams: {
                                                                                                                                                                                                           
                                                                                                                                                                                                          
                                                                                                                                                                                                            propertyId:propertyId,
                                                                                                                                                                                                            userId:userId,
                                                                                                                                                                                                          
                                                                                                                                                                                                            
                                                                                                                                                                                                          }
                                                                                                                                                                                                         
                                                                                                                                                                                                         });
                                                                                                                                                                                                       
                                                                                                                                                                                                         try {
                                                                                                                                                                                                          
                                                                                                                                                                                                          // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                                                                                          //console.log(response.data.totalPages);
                                                                                                                                                                                                        
                                                                                                                                                                                                         console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                          yield put(ErrorActions.hideError());
                                                                                                                                                                                                           yield put(tmActions.tmGetPreviousMonthTenantRiskCountSuccess(response.data));
                                                                                                                                                                                                           console.log("getPreviousMonthTenantRiskCount..........."+JSON.stringify(response.data));
                                                                                                       
                                                                                                                                                                                                         } catch (err) {
                                                                                                                                                                                                           yield put(ErrorActions.setError('error in  getPreviousMonthTenantRiskCount'));
                                                                                                                                                                                                         }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        export function*  getTenantListByRiskBasedOnLowRisk(action) {
                                                                                                                                  
                                                                                                                                                                                                          const {searchTerm,propertyId,userId,month,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                       
                                                                                                                                                                                                          console.log(propertyId);
                                                                                                                                                                                                          
                                                                                                                                                                                                                   const url = buildUrl('api/', {
                                                                                                                                                                                                                     path: 'tenant-list-based-on-low-risk',
                                                                                                                                                                                                                     queryParams: {
                                                                                                                                                                                                                     
                                                                                                                                                                                                                      searchTerm:searchTerm,
                                                                                                                                                                                                                      propertyId:propertyId,
                                                                                                                                                                                                                      userId:userId,
                                                                                                                                                                                                                      month:month,
                                                                                                                                                                                                                      pageNum:pageNum,
                                                                                                                                                                                                                      pageSize:pageSize,
                                                                                                                                                                                                                      
                                                                                                                                                                                                                     
                                                                                                                                                                                                                   
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                   
                                                                                                                                                                                                                   });
                                                                                                                                                                                                                 
                                                                                                                                                                                                                   try {
                                                                                                                                                                                                                    
                                                                                                                                                                                                                    // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                     const response = yield call(api.get, url);
                                                                                                                                                                                                                    //console.log(response.data.totalPages);
                                                                                                                                                                                                                  
                                                                                                                                                                                                                   console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                                                                                                     yield put(tmActions.tmGetTenantListBasedOnLowRiskSuccess(response.data));
                                                                                                                                                                                                                     console.log("getTenantListByRiskBasedOnLowRisk..........."+JSON.stringify(response.data));
                                                                                                                 
                                                                                                                                                                                                                   } catch (err) {
                                                                                                                                                                                                                     yield put(ErrorActions.setError('error in  getTenantListByRiskBasedOnLowRisk'));
                                                                                                                                                                                                                   }
                                                                                                                                                                                                                  }

                                                                                                                                                                                                                  export function*  getTenantListByRiskBasedOnMediumLowRisk(action) {
                                                                                                                                  
                                                                                                                                                                                                                    const {searchTerm,propertyId,userId,month,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                 
                                                                                                                                                                                                                    console.log(propertyId);
                                                                                                                                                                                                                    
                                                                                                                                                                                                                             const url = buildUrl('api/', {
                                                                                                                                                                                                                               path: 'tenant-list-based-on-medium-low-risk',
                                                                                                                                                                                                                               queryParams: {
                                                                                                                                                                                                                               
                                                                                                                                                                                                                                searchTerm:searchTerm,
                                                                                                                                                                                                                                propertyId:propertyId,
                                                                                                                                                                                                                                userId:userId,
                                                                                                                                                                                                                                month:month,
                                                                                                                                                                                                                                pageNum:pageNum,
                                                                                                                                                                                                                                pageSize:pageSize,
                                                                                                                                                                                                                               
                                                                                                                                                                                                                             
                                                                                                                                                                                                                              }
                                                                                                                                                                                                                             
                                                                                                                                                                                                                             });
                                                                                                                                                                                                                           
                                                                                                                                                                                                                             try {
                                                                                                                                                                                                                              
                                                                                                                                                                                                                              // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                               const response = yield call(api.get, url);
                                                                                                                                                                                                                              //console.log(response.data.totalPages);
                                                                                                                                                                                                                            
                                                                                                                                                                                                                             console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                              yield put(ErrorActions.hideError());
                                                                                                                                                                                                                               yield put(tmActions.tmGetTenantListBasedOnMediumLowRiskSuccess(response.data));
                                                                                                                                                                                                                               console.log("getTenantListByRiskBasedOnMediumLowRisk..........."+JSON.stringify(response.data));
                                                                                                                           
                                                                                                                                                                                                                             } catch (err) {
                                                                                                                                                                                                                               yield put(ErrorActions.setError('error in  getTenantListByRiskBasedOnMediumLowRisk'));
                                                                                                                                                                                                                             }
                                                                                                                                                                                                                            }

                                                                                                                                                                                                                            export function*  getTenantListByRiskBasedOnMediumHighRisk(action) {
                                                                                                                                  
                                                                                                                                                                                                                              const {searchTerm,propertyId,userId,month,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                           
                                                                                                                                                                                                                              console.log(propertyId);
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                                                                                                         path: 'tenant-list-based-on-medium-high-risk',
                                                                                                                                                                                                                                         queryParams: {
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                          searchTerm:searchTerm,
                                                                                                                                                                                                                                          propertyId:propertyId,
                                                                                                                                                                                                                                          userId:userId,
                                                                                                                                                                                                                                          month:month,
                                                                                                                                                                                                                                          pageNum:pageNum,
                                                                                                                                                                                                                                          pageSize:pageSize,
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                       });
                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                       try {
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                       console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                         yield put(tmActions.tmGetTenantListBasedOnMediumHighRiskSuccess(response.data));
                                                                                                                                                                                                                                         console.log("getTenantListByRiskBasedOnMediumHighRisk..........."+JSON.stringify(response.data));
                                                                                                                                     
                                                                                                                                                                                                                                       } catch (err) {
                                                                                                                                                                                                                                         yield put(ErrorActions.setError('error in  getTenantListByRiskBasedOnMediumHighRisk'));
                                                                                                                                                                                                                                       }
                                                                                                                                                                                                                                      }



                                                                                                                                                                                                                            export function*  getTenantListByRiskBasedOnModerateHighRisk(action) {
                                                                                                                                  
                                                                                                                                                                                                                              const {searchTerm,propertyId,userId,month,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                           
                                                                                                                                                                                                                              console.log(propertyId);
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                                                                                                         path: 'tenant-list-based-on-moderate-high-risk',
                                                                                                                                                                                                                                         queryParams: {
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                          searchTerm:searchTerm,
                                                                                                                                                                                                                                          propertyId:propertyId,
                                                                                                                                                                                                                                          userId:userId,
                                                                                                                                                                                                                                          month:month,
                                                                                                                                                                                                                                          pageNum:pageNum,
                                                                                                                                                                                                                                          pageSize:pageSize,
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                       });
                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                       try {
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                       console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                         yield put(tmActions.tmGetTenantListBasedOnModerateHighRiskSuccess(response.data));
                                                                                                                                                                                                                                         console.log("getTenantListByRiskBasedOnModerateHighRisk..........."+JSON.stringify(response.data));
                                                                                                                                     
                                                                                                                                                                                                                                       } catch (err) {
                                                                                                                                                                                                                                         yield put(ErrorActions.setError('error in  getTenantListByRiskBasedOnModerateHighRisk'));
                                                                                                                                                                                                                                       }
                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                            export function*  getTenantListByRiskBasedOnHighRisk(action) {
                                                                                                                                  
                                                                                                                                                                                                                              const {searchTerm,propertyId,userId,month,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                           
                                                                                                                                                                                                                              console.log(propertyId);
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                                                                                                         path: 'tenant-list-based-on-high-risk',
                                                                                                                                                                                                                                         queryParams: {
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                          searchTerm:searchTerm,
                                                                                                                                                                                                                                          propertyId:propertyId,
                                                                                                                                                                                                                                          userId:userId,
                                                                                                                                                                                                                                          month:month,
                                                                                                                                                                                                                                          pageNum:pageNum,
                                                                                                                                                                                                                                          pageSize:pageSize,
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                       });
                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                       try {
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                       console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                         yield put(tmActions.tmGetTenantListBasedOnHighRiskSuccess(response.data));
                                                                                                                                                                                                                                         console.log("getTenantListByRiskBasedOnHighRisk..........."+JSON.stringify(response.data));
                                                                                                                                     
                                                                                                                                                                                                                                       } catch (err) {
                                                                                                                                                                                                                                         yield put(ErrorActions.setError('error in  getTenantListByRiskBasedOnHighRisk'));
                                                                                                                                                                                                                                       }
                                                                                                                                                                                                                                      }

                                                                                                                                                                                                                                      export function*  getCommentaryFromCompanyReport(action) {
                                                                                                                                  
                                                                                                                                                                                                                                        const {tenantId,month,year} = action.payload;
                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                        console.log(tenantId);
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                 const url = buildUrl('api/', {
                                                                                                                                                                                                                                                   path: 'years-in-business-commentary',
                                                                                                                                                                                                                                                   queryParams: {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                   tenantId:tenantId,
                                                                                                                                                                                                                                                   month:month,
                                                                                                                                                                                                                                                   year:year,
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                 });
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                 try {
                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                  // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                   const response = yield call(api.get, url);
                                                                                                                                                                                                                                                  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                 console.log("response"+JSON.stringify(response.data[0]));
                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                  yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                   yield put(tmActions.tmGetCommentaryFromCompanyReportSuccess(response.data));
                                                                                                                                                                                                                                                   console.log("getCommentaryFromCompanyReport..........."+JSON.stringify(response.data));
                                                                                                                                               
                                                                                                                                                                                                                                                 } catch (err) {
                                                                                                                                                                                                                                                   yield put(ErrorActions.setError('error in  getCommentaryFromCompanyReport'));
                                                                                                                                                                                                                                                 }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                export function* getCreditSafeBankruptcyReport(action) {
                                                                                                                                                                                                                                                  const { tenantId } = action.payload;
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                               // alert("saga"+tenantId);
                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                  const url = buildUrl('api/', {
                                                                                                                                                                                                                                                    path: 'creditsafe-banko-report-by-tenant',
                                                                                                                                                                                                                                                    queryParams: {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                      tenantId:tenantId,
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                  });
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                  try {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                   console.log("getCreditSafeBankruptcyReport");
                                                                                                                                                                                                                                                    const response = yield call(api.get, url);
                                                                                                                                                                                                                                                    console.log(response);
                                                                                                                                                                                                                                                 // alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                    yield put(tmActions.tmCreditSafeBankruptcyReportByTenantIdSuccess(response.data));
                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                  } catch (err) {
                                                                                                                                                                                                                                                    yield put(ErrorActions.setError('error in getCreditSafeBankruptcyReport'));
                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                export function* getCurrentMonthFundScoreByFundId(action) {
                                                                                                                                                                                                                                                  const { fundId } = action.payload;
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                               // alert("saga"+tenantId);
                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                  const url = buildUrl('api/', {
                                                                                                                                                                                                                                                    path: 'current-month-fund-score',
                                                                                                                                                                                                                                                    queryParams: {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                      fundId:fundId,
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                  });
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                  try {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                   console.log("getCurrentMonthFundScoreByFundId");
                                                                                                                                                                                                                                                    const response = yield call(api.get, url);
                                                                                                                                                                                                                                                    console.log(response);
                                                                                                                                                                                                                                                 // alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                    yield put(tmActions.tmCurrentMonthFundScoreByFundIdSuccess(response.data));
                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                  } catch (err) {
                                                                                                                                                                                                                                                    yield put(ErrorActions.setError('error in getCurrentMonthFundScoreByFundId'));
                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                }                                                                                 
                                                                                                                                                                                                                                                export function* getPropertyCityAndStateByPropertyId(action) {
                                                                                                                                                                                                                                                  const { propertyId } = action.payload;
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                               // alert("saga"+tenantId);
                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                  const url = buildUrl('api/', {
                                                                                                                                                                                                                                                    path: 'property-city-state',
                                                                                                                                                                                                                                                    queryParams: {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                      propertyId:propertyId,
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                  });
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                  try {
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                   console.log("getPropertyCityAndStateByPropertyId");
                                                                                                                                                                                                                                                    const response = yield call(api.get, url);
                                                                                                                                                                                                                                                    console.log(response);
                                                                                                                                                                                                                                                 // alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                    yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                    yield put(tmActions.tmPropertyCityAndStateByPropertyIdSuccess(response.data));
                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                  } catch (err) {
                                                                                                                                                                                                                                                    yield put(ErrorActions.setError('error in getPropertyCityAndStateByPropertyId'));
                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                }                                                                                 
                                                                                                                                                                                                                                                export function*  getNotificationLogList(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                  const {pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                  console.log("pageNum"+pageNum);
                                                                                                                                                                                                                                                  console.log("pageSize"+pageSize);

                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                           const url = buildUrl('api/', {
                                                                                                                                                                                                                                                             path: 'notification-log-list',
                                                                                                                                                                                                                                                             queryParams: {
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                              pageNum:pageNum,
                                                                                                                                                                                                                                                              pageSize:pageSize,
                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                           });
                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                           try {
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                            // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                             const response = yield call(api.get, url);
                                                                                                                                                                                                                                                            //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                           console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                             yield put(tmActions.tmGetNotificationLogListSuccess(response.data));
                                                                                                                                                                                                                                                             console.log("getNotificationLogList..........."+JSON.stringify(response.data));
                                                                                                                                                         
                                                                                                                                                                                                                                                           } catch (err) {
                                                                                                                                                                                                                                                             yield put(ErrorActions.setError('error in  getNotificationLogList'));
                                                                                                                                                                                                                                                           }
                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                          export function*  getTenantPropertyDetails(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                            const {tenantId,month,year} = action.payload;
                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                            console.log("tenantId"+tenantId);
                                                                                                                                                                                                                                                            console.log("month"+month);
                                                                                                                                                                                                                                                            console.log("year"+year);
                                                                                                                                                                                                                                                           
          
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                     const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                       path: 'tenant-property-details',
                                                                                                                                                                                                                                                                       queryParams: {
                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                        tenantId:tenantId,
                                                                                                                                                                                                                                                                        month:month,
                                                                                                                                                                                                                                                                        year:year,
                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                     });
                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                     try {
                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                      // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                       const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                      //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                     console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                      yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                       yield put(tmActions.tmGetTenantPropertyDetailsSuccess(response.data));
                                                                                                                                                                                                                                                                       console.log("getTenantPropertyDetails..........."+JSON.stringify(response.data));
                                                                                                                                                                   
                                                                                                                                                                                                                                                                     } catch (err) {
                                                                                                                                                                                                                                                                       yield put(ErrorActions.setError('error in  getTenantPropertyDetails'));
                                                                                                                                                                                                                                                                     }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                    export function*  getContifyTopics(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                      // const {tenantId,month,year} = action.payload;
                                                       
                                                                                                                                                                                                                                                                               const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                 path: 'contify-topics',
                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                               });
                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                               try {
                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                 const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                               console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                 yield put(tmActions.tmGetContifyTopicsSuccess(response.data));
                                                                                                                                                                                                                                                                                 console.log("  getContifyTopics..........."+JSON.stringify(response.data));
                                                                                                                                                                             
                                                                                                                                                                                                                                                                               } catch (err) {
                                                                                                                                                                                                                                                                                 yield put(ErrorActions.setError('error in getContifyTopics'));
                                                                                                                                                                                                                                                                               }
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                              export function*  getContifyListUsingTopics(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                const {tenantId,selectedValues,startDate,endDate} = action.payload;

                                                                                                                                                                                                                                                                                console.log("tenantId"+tenantId);
                                                                                                                                                                                                                                                                                console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                                                                                                                                                                                                                                console.log("startDate"+startDate);
                                                                                                                                                                                                                                                                                console.log("endDate"+endDate);
                                                                                                                                                                                                                                                                                console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                 
                                                                                                                                                                                                                                                                                         const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                           path: 'get-contify-list-using-topics',
                                                                                                                                                                                                                                                                                           queryParams: {
                                                                                                                                                                                                                                                                                            tenantId:tenantId,
                                                                                                                                                                                                                                                                                            selectedValues:selectedValues,
                                                                                                                                                                                                                                                                                            startDate:startDate,
                                                                                                                                                                                                                                                                                            endDate:endDate
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                         });
                                                                                                                                                                                                                                                                                         console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                         try {
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                          // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                           const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                          //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                         console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                          yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                           yield put(tmActions.tmGetCompanyInsightSearchListUsingTopicSuccess(response.data));
                                                                                                                                                                                                                                                                                           console.log("  getContifyListUsingTopics..........."+JSON.stringify(response.data));
                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                         } catch (err) {
                                                                                                                                                                                                                                                                                           yield put(ErrorActions.setError('error in getContifyListUsingTopics'));
                                                                                                                                                                                                                                                                                         }
                                                                                                                                                                                                                                                                                        }

                                                                                                                                                                                                                                                                                        export function* saveParent(action) {
                                                                                                                                                                                                                                                                                          const { parent } = action.payload;
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'parent-create'
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                            const response = yield call(api.post, url,parent);
                                                                                                                                                                                                                                                                                            //alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmParentSaveSuccess(response.data));
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in saveParent'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }

                                                                                                                                                                                                                                                                                        export function* getParentListByOrg(action) {
                                                                                                                                                                                                                                                                                          const { organisationId } = action.payload;
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'parent-list',
                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                              //organisationId:"5f8ee03be74296262abdeb65",
                                                                                                                                                                                                                                                                                              organisationId:organisationId,
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                            const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                            console.log("getParentListByOrg");
                                                                                                                                                                                                                                                                                            console.log(response.data);
                                                                                                                                                                                                                                                                                            //alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmParentListByOrgSuccess(response.data));
                                                                                                                                                                                                                                                                                         //  
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('getParentListByOrg'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                        export function* getParentById(action) {
                                                                                                                                                                                                                                                                                          const {parentId} = action.payload;
                                                                                                                                                                                                                                                                                          console.log(" getTenantById");
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'parent',
                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                              id:parentId,
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                            console.log("response");
                                                                                                                                                                                                                                                                                            console.log(response.data);
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmParentByIdSuccess(response.data));
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in  getTenantById'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                        export function* editParent(action) {
                                                                                                                                                                                                                                                                                          const { parent } = action.payload;
                                                                                                                                                                                                                                                                                        console.log("editParent");
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'parent-update',
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                            const response = yield call(api.put, url,parent);
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmParentEditSuccess(response.data));
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in editParent'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }

                                                                                                                                                                                                                                                                                        export function* deleteParent(action) {
                                                                                                                                                                                                                                                                                          const { parentId } = action.payload;
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'parent-delete',
                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                              id:parentId,
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                            const response = yield call(api.post, url);
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmParentDeleteSuccess(response.data));
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in deleteParent'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }

                                                                                                                                                                                                                                                                                        export function* getParentListByOrgInTenant(action) {
                                                                                                                                                                                                                                                                                          console.log("getParentListByOrgInTenant");
                                                                                                                                                                                                                                                                                           const {organisationId} = action.payload;
                                                                                                                                                                                                                                                                                           const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                             path:'parent-by-org',
                                                                                                                                                                                                                                                                                             queryParams: {
                                                                                                                                                                                                                                                                                               organisationId:organisationId,
                                                                                                                                                                                                                                                                                             }
                                                                                                                                                                                                                                                                                           });
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                           try {
                                                                                                                                                                                                                                                                                             //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                             const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                            console.log(response.data);
                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                             yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                             yield put(tmActions.tmParentByOrgIdSuccess(response.data));
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                           } catch (err) {
                                                                                                                                                                                                                                                                                             yield put(ErrorActions.setError('User not found or wrong credentials'));
                                                                                                                                                                                                                                                                                           }
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                         }
                                                                                                                                                                                                                                                                                         export function* unlinkParent(action) {
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                           const {tenantId} = action.payload;
                                                                                                                                                                                                                                                                                           console.log("tenantId"+tenantId);
                                                                                                                                                                                                                                                                                           const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                             path:'unlink-parent',
                                                                                                                                                                                                                                                                                             queryParams: {
                                                                                                                                                                                                                                                                                                 tenantId:tenantId,
                                                                                                                                                                                                                                                                                             }
                                                                                                                                                                                                                                                                                           });
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                           try {
                                                                                                                                                                                                                                                                                             //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                             const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                            console.log(response.data);
                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                             yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                             yield put(tmActions.tmUnlinkParentSuccess(response.data));
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                           } catch (err) {
                                                                                                                                                                                                                                                                                             yield put(ErrorActions.setError('User not found or wrong credentials'));
                                                                                                                                                                                                                                                                                           }
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                         }

                                                                                                                                                                                                                                                                                         export function* genaerateParentInitialReport(action) {
                                                                                                                                                                                                                                                                                          console.log("genaerateParentInitialReport");
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          const { parentId } = action.payload;
                                                                                                                                                 
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: '/parent-initial-report-gen',
                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                              id:parentId,
                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                            const response = yield call(api.post, url);
                                                                                                                                                                                                                                                                                            console.log("genaerateParentInitialReport");
                                                                                                                                                                                                                                                                                            console.log(response.data);
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmGenarateParentInitialReportSuccess(response.data));
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('genaerateParentInitialReport'));
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }  

                                                                                                                                                                                                                                                                                        export function* getParentCreditScore(action) {
                                                                                                                                                                                                                                                                                          console.log("getParentCreditScore");
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                            const { parentId } = action.payload;
                                                                                                                                                                                                                                                                                            const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                              path: 'parentscore',
                                                                                                                                                                                                                                                                                              queryParams: {
                                                                                                                                                                                                                                                                                                parentId:parentId,
                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                            try {
                                                                                                                                                                                                                                                                                              //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                              const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                          console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                              yield put(tmActions.tmParentCreditScoreSuccess(response.data));
                                                                                                                                                                                                                                                                                           //  
                                                                                                                                                                                                                                                                                            } catch (err) {
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.setError('User not found or wrong credentials'));
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          export function* getParentDerogatoryScoreCount(action) {
                                                                                                                                                                                                                                                                                            const {parentId } = action.payload;
                                                                                                                                                                                                                                                                                            const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                              path: 'parent_derogatory_score_count',
                                                                                                                                                                                                                                                                                              queryParams: {
                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                parentId:parentId,
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                            try {
                                                                                                                                                                                                                                                                                              //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                              const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                          console.log("getParentDerogatoryScoreCount");
                                                                                                                                                                                                                                                                                          console.log(response.data);
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                              yield put(tmActions.tmParentDerogatoryScoreCountSuccess(response.data));
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            } catch (err) {
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.setError('User not found or wrong credentials'));
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          export function* getParentScoreByPIRSType(action) {
                                                                                                                                                                                                                                                                                            const { parentId,month,year} = action.payload;
                                                                                                                                                                                                                                                                                            console.log(parentId);
                                                                                                                                                                                                                                                                                          console.log(month);
                                                                                                                                                                                                                                                                                          console.log(year);
                                                                                                                                                                                                                                                                                            const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                              path: 'parent_pirs_score',
                                                                                                                                                                                                                                                                                              queryParams: {
                                                                                                                                                                                                                                                                                                parentId:parentId,
                                                                                                                                                                                                                                                                                                month:month,
                                                                                                                                                                                                                                                                                                year:year
                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                            try {
                                                                                                                                                                                                                                                                                              //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                              const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                              console.log("data----------PIRS-----------"+response.data);
                                                                                                                                                                                                                                                                                              console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                              yield put(tmActions.tmParentsScoreByPIRSSuccess(response.data));
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            } catch (err) {
                                                                                                                                                                                                                                                                                              yield put(ErrorActions.setError('User not found or wrong credentials'));
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          export function* getMonotoredParentsNews(action) {
                                                                                                                                                                                                                                                                                            const { parentId,salesTriggers,startDate,endDate} = action.payload;
                                                                                                                                                                                                                                                                                            const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                            path: 'get-monitored-parents-news',
                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                              parentId:parentId,
                                                                                                                                                                                                                                                                                              salesTriggers:salesTriggers,
                                                                                                                                                                                                                                                                                              startDate:startDate,
                                                                                                                                                                                                                                                                                              endDate:endDate
                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            try {
                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                            const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                            console.log("getMonotoredParentsNews");
                                                                                                                                                                                                                                                                                            // console.log("count"+response.data.insightSearchList);
                                                                                                                                                                                                                                                                                            console.log(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                            yield put(tmActions.tmMonitoredParentsNewsSuccess(response.data));
                                                                                                                                                                                                                                                                                            //
                                                                                                                                                                                                                                                                                            } catch (err) {
                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in getMonotoredParentsNews'));
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                            }

                                                                                                                                                                                                                                                                                            export function* getCreditSafeParentBankruptcyReport(action) {
                                                                                                                                                                                                                                                                                              const { parentId } = action.payload;
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                           // alert("saga"+tenantId);
                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                              const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                path: 'creditsafe-banko-report-by-parent',
                                                                                                                                                                                                                                                                                                queryParams: {
                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                  parentId:parentId,
                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                              });
                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                              try {
                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                               console.log("getCreditSafeParentBankruptcyReport");
                                                                                                                                                                                                                                                                                                const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                console.log(response);
                                                                                                                                                                                                                                                                                             // alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                yield put(tmActions.tmCreditSafeBankruptcyReportByParentIdSuccess(response.data));
                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                              } catch (err) {
                                                                                                                                                                                                                                                                                                yield put(ErrorActions.setError('error in getCreditSafeParentBankruptcyReport'));
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                            }

                                                                                                                                                                                                                                                                                            export function*  getParentContifyListUsingTopics(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                              const {parentId,selectedValues,startDate,endDate} = action.payload;
              
                                                                                                                                                                                                                                                                                              console.log("parentId"+parentId);
                                                                                                                                                                                                                                                                                              console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                                                                                                                                                                                                                                              console.log("startDate"+startDate);
                                                                                                                                                                                                                                                                                              console.log("endDate"+endDate);
                                                                                                                                                                                                                                                                                              console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                               
                                                                                                                                                                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                         path: 'get-parent-contify-list-using-topics',
                                                                                                                                                                                                                                                                                                         queryParams: {
                                                                                                                                                                                                                                                                                                          parentId:parentId,
                                                                                                                                                                                                                                                                                                          selectedValues:selectedValues,
                                                                                                                                                                                                                                                                                                          startDate:startDate,
                                                                                                                                                                                                                                                                                                          endDate:endDate
                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                       });
                                                                                                                                                                                                                                                                                                       console.log("selectedValues"+JSON.stringify(selectedValues));
                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                       try {
                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                       console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                         yield put(tmActions.tmGetParentCompanyInsightSearchListUsingTopicSuccess(response.data));
                                                                                                                                                                                                                                                                                                         console.log("getParentContifyListUsingTopics..........."+JSON.stringify(response.data));
                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                       } catch (err) {
                                                                                                                                                                                                                                                                                                         yield put(ErrorActions.setError('error in getParentContifyListUsingTopics'));
                                                                                                                                                                                                                                                                                                       }
                                                                                                                                                                                                                                                                                                      }

                                                                                                                                                                                                                                                                                                      export function* getUccDetailsByParentId(action) {
                                                                                                                                                                                                                                                                                                        const { parentId,month,year } = action.payload;
                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                        //  console.log("uccList");
                                                                                                                                                                                                                                                                                                        const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                        path: 'parent-cautionary-ucc-details',
                                                                                                                                                                                                                                                                                                        queryParams: {
                                                                                                                                                                                                                                                                                                        parentId :parentId,
                                                                                                                                                                                                                                                                                                        month:month,
                                                                                                                                                                                                                                                                                                        year:year,
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                        });
                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                        try {
                                                                                                                                                                                                                                                                                                        //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                        const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                        console.log("getUccDetailsByParentId");
                                                                                                                                                                                                                                                                                                        console.log("uccList");
                                                                                                                                                                                                                                                                                                        console.log(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                        yield put(tmActions.tmActiveUccDetailsByParentIdSuccess(response.data));
                                                                                                                                                                                                                                                                                                        //
                                                                                                                                                                                                                                                                                                        } catch (err) {
                                                                                                                                                                                                                                                                                                        yield put(ErrorActions.setError('error in getUccDetailsByParentId'));
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                        export function* getTaxLienDetailsByParentId(action) {
                                                                                                                                                                                                                                                                                                          const { parentId,month,year } = action.payload;
                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                            path: 'parent-active-taxlien-details',
                                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                              parentId :parentId,
                                                                                                                                                                                                                                                                                                              month:month,
                                                                                                                                                                                                                                                                                                              year:year,
                                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                            const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                            console.log("getTaxLienDetailsByParentId");
                                                                                                                                                                                                                                                                                                            console.log("getTaxLienDetailsByParentId"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                            yield put(tmActions.tmActiveLiensDetailsByParentIdSuccess(response.data));
                                                                                                                                                                                                                                                                                                         //  
                                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in getTaxLienDetailsByParentId'));
                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                        export function* getJudgmentDetailsByParentId(action) {
                                                                                                                                                                                                                                                                                                          const { parentId,month,year} = action.payload;
                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                          const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                          path: 'parent-active-judgment-details',
                                                                                                                                                                                                                                                                                                          queryParams: {
                                                                                                                                                                                                                                                                                                          parentId :parentId,
                                                                                                                                                                                                                                                                                                          month:month,
                                                                                                                                                                                                                                                                                                          year:year,
                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                          });
                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                          try {
                                                                                                                                                                                                                                                                                                          //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                          const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                          console.log("getJudgmentDetailsByTenantId");
                                                                                                                                                                                                                                                                                                          console.log(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                          yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                          yield put(tmActions.tmActiveJudgmentDetailsByParentIdSuccess(response.data));
                                                                                                                                                                                                                                                                                                          //
                                                                                                                                                                                                                                                                                                          } catch (err) {
                                                                                                                                                                                                                                                                                                          yield put(ErrorActions.setError('error in getJudgmentDetailsByTenantId'));
                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                          export function* getSuitDetailsByParentId(action) {
                                                                                                                                                                                                                                                                                                            const { parentId,month,year} = action.payload;
                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                            const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                            path: 'parent-active-suits-details',
                                                                                                                                                                                                                                                                                                            queryParams: {
                                                                                                                                                                                                                                                                                                              parentId :parentId,
                                                                                                                                                                                                                                                                                                            month:month,
                                                                                                                                                                                                                                                                                                          year:year,
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                            });
                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                                            try {
                                                                                                                                                                                                                                                                                                            //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                                            const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                            console.log("getSuitDetailsByParentId");
                                                                                                                                                                                                                                                                                                            console.log(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                            //  alert(JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                            yield put(tmActions.tmActiveSuitDetailsByParentIdSuccess(response.data));
                                                                                                                                                                                                                                                                                                            //
                                                                                                                                                                                                                                                                                                            } catch (err) {
                                                                                                                                                                                                                                                                                                            yield put(ErrorActions.setError('error in getSuitDetailsByParentId'));
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                            }

                                                                                                                                                                                                                                                                                                            export function*  getNotificationLogSearchList(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                                              const {organizationId,userId,type,category,alerts,dateFrom,dateTo,pageNum,pageSize} = action.payload;
                                                                                                                                                                                                                                                                                                           


                                                                                                                                                                                                                                                                                                              console.log("organizationId"+organizationId);
                                                                                                                                                                                                                                                                                                              console.log("userId"+userId);
                                                                                                                                                                                                                                                                                                              console.log("type"+type);
                                                                                                                                                                                                                                                                                                              console.log("category"+category);
                                                                                                                                                                                                                                                                                                              console.log("alerts"+alerts);
                                                                                                                                                                                                                                                                                                              console.log("dateFrom"+dateFrom);
                                                                                                                                                                                                                                                                                                              console.log("dateTo"+dateTo);
                                                                                                                                                                                                                                                                                                              console.log("pageNum"+pageNum);
                                                                                                                                                                                                                                                                                                              console.log("pageSize"+pageSize);
                                                            
                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                       const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                                         path: 'notification-log-search-list',
                                                                                                                                                                                                                                                                                                                         queryParams: {
                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                          organisationId:organizationId,
                                                                                                                                                                                                                                                                                                                          mailToUserId:userId,
                                                                                                                                                                                                                                                                                                                          type:type,
                                                                                                                                                                                                                                                                                                                          category:category,
                                                                                                                                                                                                                                                                                                                          alerts:alerts,
                                                                                                                                                                                                                                                                                                                          dateFrom:moment(dateFrom).format("YYYY-MM-DD"),
                                                                                                                                                                                                                                                                                                                          dateTo:moment(dateTo).format("YYYY-MM-DD"),
                                                                                                                                                                                                                                                                                                                          pageNum:pageNum,
                                                                                                                                                                                                                                                                                                                          pageSize:pageSize,
                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                                       });
                                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                                       try {
                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                        // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                                         const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                        //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                       console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                        yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                                         yield put(tmActions.tmGetNotificationLogSearchListSuccess(response.data));
                                                                                                                                                                                                                                                                                                                         console.log("getNotificationLogSearchList..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                                       } catch (err) {
                                                                                                                                                                                                                                                                                                                         yield put(ErrorActions.setError('error in  getNotificationLogSearchList'));
                                                                                                                                                                                                                                                                                                                       }
                                                                                                                                                                                                                                                                                                                      }

                                                                                                                                                                                                                                                                                                                      export function*  getNewsFtl(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                                                        const {organisationId,userId,category,tenantId,contifyId} = action.payload;
                                                                                                                                                                                                                                                                                                                     
          
          
                                                                                                                                                                                                                                                                                                                        console.log("organisationId"+organisationId);
                                                                                                                                                                                                                                                                                                                        console.log("userId"+userId);
                                                                                                                                                                                                                                                                                                                        console.log("tenantId"+tenantId);
                                                                                                                                                                                                                                                                                                                        console.log("category"+category);
                                                                                                                                                                                                                                                                                                                        console.log("contifyId"+contifyId);
                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                        
                                                                      
                                                                                                                                                                                                                                                                                                                                 const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                                                   path: 'news-ftl',
                                                                                                                                                                                                                                                                                                                                   queryParams: {
                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                    organisationId:organisationId,
                                                                                                                                                                                                                                                                                                                                    userId:userId,
                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                    category:category,
                                                                                                                                                                                                                                                                                                                                    tenantId:tenantId,
                                                                                                                                                                                                                                                                                                                                    contifyId:contifyId,
                                                                                                                                                                                                                                                                                                                                    // dateFrom:moment(dateFrom).format("YYYY-MM-DD"),
                                                                                                                                                                                                                                                                                                                                    // dateTo:moment(dateTo).format("YYYY-MM-DD"),
                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                                                                 });
                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                 try {
                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                  // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                                                   const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                 console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                                  yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                                                   yield put(tmActions.tmGetNewsFtlSuccess(response.data));
                                                                                                                                                                                                                                                                                                                                   console.log("getNewsFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                 } catch (err) {
                                                                                                                                                                                                                                                                                                                                   yield put(ErrorActions.setError('error in  getNewsFtl'));
                                                                                                                                                                                                                                                                                                                                 }
                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                export function*  getDerogatoryFtl(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                  const {userId,month,year} = action.payload;
         
                                                                                                                                                                                                                                                                                                                                  console.log("userId"+userId);
                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                           const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                                                             path: 'derogatory-ftl',
                                                                                                                                                                                                                                                                                                                                             queryParams: {
                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                              userId:userId,
                                                                                                                                                                                                                                                                                                                                              month:month,
                                                                                                                                                                                                                                                                                                                                              year:year
                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                                                                           });
                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                                           try {
                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                            // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                                                             const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                            //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                           console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                                                            yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                                                             yield put(tmActions.tmGetDerogatoryFtlSuccess(response.data));
                                                                                                                                                                                                                                                                                                                                             console.log("getDerogatoryFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                                           } catch (err) {
                                                                                                                                                                                                                                                                                                                                             yield put(ErrorActions.setError('error in  getDerogatoryFtl'));
                                                                                                                                                                                                                                                                                                                                           }
                                                                                                                                                                                                                                                                                                                                          }          
                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                          export function*  getCreditFtl(action) {
                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                            const {userId,month,year} = action.payload;
                                                                                                                                                                                                                                                                                                                                         
                              
                              
                                                                                                                                                                                                                                                                                                                                            console.log("userId"+userId);
                                                                                                                                                                                                                                                                                                                                           
                                                                                          
                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                     const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                                                                       path: 'credit-ftl',
                                                                                                                                                                                                                                                                                                                                                       queryParams: {
                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                        userId:userId,
                                                                                                                                                                                                                                                                                                                                                        month:month,
                                                                                                                                                                                                                                                                                                                                                        year:year
                                                                                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                     
                                                                                                                                                                                                                                                                                                                                                     });
                                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                                                                     try {
                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                      // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                                                                       const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                      //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                     console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                      yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                                                                       yield put(tmActions.tmGetCreditFtlSuccess(response.data));
                                                                                                                                                                                                                                                                                                                                                       console.log("getCreditFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                                                                                     } catch (err) {
                                                                                                                                                                                                                                                                                                                                                       yield put(ErrorActions.setError('error in  getCreditFtl'));
                                                                                                                                                                                                                                                                                                                                                     }
                                                                                                                                                                                                                                                                                                                                                    } 
                                                                                                                                                                                                                                                                                                                                          

                                                                                                                                                                                                                                                                                                                                                    export function*  getTenantScoreFtl(action) {
                                                                                                                                                                                                                                                                                                                                                      const {userId,month,year} = action.payload;
                                                                                                                                                                                                                                                                                                                                                      console.log("userId"+userId);
                                                                                                                                                                                                                                                                                                                                                      console.log("month"+month);
                                                                                                                                                                                                                                                                                                                                                      console.log("year"+year);
                                                                                                                                                                                                                                                                                                                                                     
                                                                                                    
                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                               const url = buildUrl('api/', {
                                                                                                                                                                                                                                                                                                                                                                 path: 'tenant-score-ftl',
                                                                                                                                                                                                                                                                                                                                                                 queryParams: {
                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                  userId:userId,
                                                                                                                                                                                                                                                                                                                                                                  month:month,
                                                                                                                                                                                                                                                                                                                                                                  year:year
                                                                                                                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                               });
                                                                                                                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                               try {
                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                // console.log("getTenantRiskCountByPropertyGridView");
                                                                                                                                                                                                                                                                                                                                                                 const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                                               console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                                                                                                                yield put(ErrorActions.hideError());
                                                                                                                                                                                                                                                                                                                                                                 yield put(tmActions.tmGetTenantScoreFtlSuccess(response.data));
                                                                                                                                                                                                                                                                                                                                                                 console.log("getTenantScoreFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                               } catch (err) {
                                                                                                                                                                                                                                                                                                                                                                 yield put(ErrorActions.setError('error in  getTenantScoreFtl'));
                                                                                                                                                                                                                                                                                                                                                               }
                                                                                                                                                                                                                                                                                                                                                              } 

export function*  getSuitsFtl(action) {
const {userId,month,year} = action.payload;
console.log("userId"+userId);

const url = buildUrl('api/', {
path: 'suits-ftl',
queryParams: {
                                                                                                                                                                                                                                                                                                                                                                          
userId:userId,
month:month,
year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
}
});
                                                                                                                                                                                                                                                                                                                                                                       
try {
// console.log("getTenantRiskCountByPropertyGridView");
const response = yield call(api.get, url);
//console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                        
console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
yield put(ErrorActions.hideError());
yield put(tmActions.tmGetSuitsFtlSuccess(response.data));
console.log("getSuitsFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                       
} catch (err) {
yield put(ErrorActions.setError('error in  getSuitsFtl'));
}
} 
                                                                                                                                                                                                                                                                                                                                                              
export function*  getPirsFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'pirs-ftl',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetPirsFtlSuccess(response.data));
  console.log("getPirsFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getPirsFtl'));
  }
  } 
  
export function*  getActiveStatusFtl(action) {
    const {userId,month,year} = action.payload;
    console.log("userId"+userId);
    
    const url = buildUrl('api/', {
    path: 'active-status-ftl',
    queryParams: {
                                                                                                                                                                                                                                                                                                                                                                              
    userId:userId,
    month:month,
    year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
    }
    });
                                                                                                                                                                                                                                                                                                                                                                           
    try {
    // console.log("getTenantRiskCountByPropertyGridView");
    const response = yield call(api.get, url);
    //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                            
    console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmGetActiveStatusFtlSuccess(response.data));
    console.log("getActiveStatusFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                           
    } catch (err) {
    yield put(ErrorActions.setError('error in  getActiveStatusFtl'));
    }
} 
export function*  getPropertyOverallScoreFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'property-overall-score-ftl',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetPropertyOverallScoreFtlSuccess(response.data));
  console.log("getPropertyOverallScoreFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getPropertyOverallScoreFtl'));
  }
} 

export function*  getFundOverallScoreFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'fund-overall-score-ftl',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetFundOverallScoreFtlSuccess(response.data));
  console.log("getFundOverallScoreFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getFundOverallScoreFtl'));
  }
} 

export function*  getCautionaryUccMonthlyFtl(action) {
  const {userId,prevMonth,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'cautionary-ucc-ftl-monthly',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:prevMonth,
  year:year,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetCautionaryUccMonthlyFtlSuccess(response.data));
  console.log("getCautionaryUccMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getCautionaryUccMonthlyFtl'));
  }
}

export function*  getCautionaryUccInitialPopulateMonthlyFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'cautionary-ucc-ftl-initial-populate',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetCautionaryUccInitialPopulateFtlSuccess(response.data));
  console.log("getCautionaryUccInitialPopulateMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getCautionaryUccInitialPopulateMonthlyFtl'));
  }
} 

export function*  getLiensMonthlyFtl(action) {
  const {userId,prevMonth,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'liens-ftl-monthly',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:prevMonth,
  year:year,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetLiensMonthlyFtlSuccess(response.data));
  console.log("getLiensMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getLiensMonthlyFtl'));
  }
} 

export function*  getLiensInitialPopulateMonthlyFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'liens-ftl-initial-populate',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetLiensInitialPopulateFtlSuccess(response.data));
  console.log("getLiensInitialPopulateMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getLiensInitialPopulateMonthlyFtl'));
  }
} 

export function*  getJudgementMonthlyFtl(action) {
  const {userId,prevMonth,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'judgement-ftl-monthly',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:prevMonth,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetJudgementMonthlyFtlSuccess(response.data));
  console.log("getJudgementMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getJudgementMonthlyFtl'));
  }
} 

export function*  getJudgementInitialPopulateMonthlyFtl(action) {
  const {userId,month,year} = action.payload;
  console.log("userId"+userId);
  
  const url = buildUrl('api/', {
  path: 'judgement-ftl-initial-populate',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
  userId:userId,
  month:month,
  year:year
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetJudgementInitialPopulateFtlSuccess(response.data));
  console.log("getJudgementInitialPopulateMonthlyFtl..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getJudgementInitialPopulateMonthlyFtl'));
  }
} 
export function* saveCommentary(action) {
  const { tenantCommentary } = action.payload;

  const url = buildUrl('api/', {
    path: 'tenant-commentary-create'
 
  });

  try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
    const response = yield call(api.post, url,tenantCommentary);
    //alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmTenantCommentarySaveSuccess(response.data));
 
  } catch (err) {
    yield put(ErrorActions.setError('error in saveCommentary'));
  }
}                                                                                                                                                                                                                                                                                                                                                    

export function*  getCommentaryList(action) {
  const {organisationId,userId,tenantId,pageSize,pageNum} = action.payload;
  console.log("organisationId"+organisationId);
  console.log("userId"+userId);
  console.log("pageSize"+pageSize);
  console.log("pageNum"+pageNum);
  console.log("tenantId"+tenantId);
  
  const url = buildUrl('api/', {
  path: 'commentary-list',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    userId:userId,
    tenantId:tenantId,
    pageSize:pageSize,
    pageNum:pageNum,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmCommentaryListSuccess(response.data));
  console.log("getCommentaryList..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getCommentaryList'));
  }
} 

export function*  getTenantReportsDetails(action) {
  const {organisationId,tenantId,businessToken} = action.payload;
  console.log("organisationId"+organisationId);
  console.log("tenantId"+tenantId);
  console.log("tenantId"+businessToken);
  
  const url = buildUrl('api/', {
  path: 'tenant-reports-details',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    tenantId:tenantId,
    businessToken:businessToken
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {
  // console.log("getTenantRiskCountByPropertyGridView");
  const response = yield call(api.get, url);
  //console.log(response.data.totalPages);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGetTenantReportsDetailsSuccess(response.data));
  console.log("getTenantReportsDetails..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  getTenantReportsDetails'));
  }
} 

export function*  generateTenantReport(action) {
  const {tenantId,businessToken,creditSafeId} = action.payload;
  console.log("businessToken"+businessToken);
  console.log("tenantId"+tenantId);
  console.log("creditSafeId"+creditSafeId);
  
  const url = buildUrl('api/', {
  path: 'fetch-api',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    tenantId:tenantId,
    tloId:businessToken,
    creditSafeId:creditSafeId,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("response"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGenerateTenantReportSuccess(response.data));
  console.log("generateTenantReport..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  generateTenantReport'));
  }
} 

export function*  generateMonthlyPdfReport(action) {
  const {organisationId} = action.payload;
  
  
  const url = buildUrl('api/', {
  path: 'monthly-reports-details',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGenerateMonthlyPdfReportSuccess(response.data));
                                                                                                                                                                                                                                                                          
  } catch (err) {
  yield put(ErrorActions.setError('error in  generateMonthlyPdfReport'));
  }
} 


export function*  generateMonthlyPropertyDetailsPdfReport(action) {
  const {organisationId,propertyId,userId} = action.payload;
  
  const url = buildUrl('api/', {
  path: 'property-monthly-reports-details',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    propertyId:propertyId,
    userId:userId
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGenerateMonthlyPropertyDetailsPdfReportSuccess(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  generateMonthlyPropertyDetailsPdfReport'));
  }
} 

export function*  generateRiskListGraphMonthlyReport(action) {
  const {organisationId,propertyId,userId} = action.payload;
  console.log("organisationId"+organisationId);
  
  const url = buildUrl('api/', {
  path: 'monthly-risk-list-graph',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    propertyId:propertyId,
    userId:userId
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("generateRiskListGraphMonthlyReport"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmRiskListGraphMonthlyReportSuccess(response.data));
  console.log("generateRiskListGraphMonthlyReport..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  generateRiskListGraphMonthlyReport'));
  }
}

export function*  saveGraphMonthlyReport(action) {
  const {organisationId,propertyId,userId,tenantId,dataUrl} = action.payload;
  console.log("url"+JSON.stringify(dataUrl));
  const url = buildUrl('api/', {
  path: 'save-monthly-risk-graph',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    propertyId:propertyId,
    userId:userId,
    tenantId:tenantId,
    dataUrl:dataUrl,
    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
  console.log("saveGraphMonthlyReport"+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGraphMonthlySaveSuccess(response.data));
  console.log("saveGraphMonthlyReport..........."+JSON.stringify(response.data));
                                                                                                                                                                                                                                                                         
  } catch (err) {
  yield put(ErrorActions.setError('error in  saveGraphMonthlyReport'));
  }
}                                                                                                  
                                                                                                         
export function*  generateMonthlyFundReport(action) {
  const {organisationId,fundId} = action.payload;
  
  
  const url = buildUrl('api/', {
  path: 'monthly-fund-reports-details',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
    fundId:fundId,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmGenerateMonthlyFundReportSuccess(response.data));
                                                                                                                                                                                                                                                                          
  } catch (err) {
  yield put(ErrorActions.setError('error in  generateMonthlyFundReport'));
  }
} 
                                  
export function*  cleanUpScore(action) {
  const {organisationId} = action.payload;
  
  
  const url = buildUrl('api/', {
  path: 'clean-up-score',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmCleanUpScoreSuccess(response.data));
                                                                                                                                                                                                                                                                          
  } catch (err) {
  yield put(ErrorActions.setError('error in  cleanUpScore'));
  }
}                                                                                          
      

export function*  cleanUpNews(action) {
  const {organisationId,dateRange} = action.payload;
  
  
  const url = buildUrl('api/', {
  path: 'clean-up-news',
  queryParams: {
                                                                                                                                                                                                                                                                                                                                                                            
    organisationId:organisationId,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  }
  });
                                                                                                                                                                                                                                                                                                                                                                         
  try {

  const response = yield call(api.get, url);
                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmCleanUpNewsSuccess(response.data));
                                                                                                                                                                                                                                                                          
  } catch (err) {
  yield put(ErrorActions.setError('error in  cleanUpNews'));
  }
}
export function* saveTenantNewsSettings(action) {
  const { tenantNewsSettings} = action.payload;
 //alert(JSON.stringify(notificationSettings));
  const url = buildUrl('api/', {
  path: 'create-tenant-news-settings',
 
  });
 
  try {
  //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
 
  const response = yield call(api.post, url,tenantNewsSettings);
  console.log("saveTenantNewsSettings");
  console.log(JSON.stringify(response.data));
 // alert(JSON.stringify(response.data));
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmSaveTenantNewsSettingsSuccess(response.data));
  //
  } catch (err) {
  yield put(ErrorActions.setError('error in saveTenantNewsSettings'));
  }
  } 

  export function* editTenantNewsSettings(action) {
    const { tenantNewsSettings} = action.payload;
    //alert(JSON.stringify(notificationSettings));
    const url = buildUrl('api/', {
    path: 'update-tenant-news-settings',
   
    });
   
    try {
    //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
   
    const response = yield call(api.put, url,tenantNewsSettings);
    console.log("editTenantNewsSettings");
    console.log(JSON.stringify(response.data));
    //alert(JSON.stringify(response.data));
    yield put(ErrorActions.hideError());
    yield put(tmActions.tmEditTenantNewsSettingsSuccess(response.data));
    //
    } catch (err) {
    yield put(ErrorActions.setError('error in editTenantNewsSettings'));
    }
}
export function* getTenantNewsSettingsById(action) {
  const {tenantId} = action.payload;

  const url = buildUrl('api/', {
  path: 'get-tenant-news-settings',
  queryParams: {
    tenantId:tenantId,

  }

 
  });
 
  try {
  //const response = yield call(api.get, 'api/notification?page=1&size=10&sortProperty=tenantName&sortDirection=DESC&searchTerm=&start=Thu%20Mar%2012%202020&end=Tue%20Sep%2008%202020&notificationCategory=0&viewAllTenants=true');
 
  const response = yield call(api.get, url);
  console.log("getTenantNewsSettingsById");
  console.log(JSON.stringify(response.data));

 // alert(JSON.stringify(response.data));
  yield put(ErrorActions.hideError());
  yield put(tmActions.tmTenantNewsSettingsByIdSuccess(response.data));
  //
  } catch (err) {
  yield put(ErrorActions.setError('error in getTenantNewsSettingsById'));
  }
}
export function*  getPropertyDashboardGridViewByFundId(action) {

const {fundId,userId,pageSize,pageNum} = action.payload;

const url = buildUrl('api/', {
path: 'property-dashboard-grid-fund',
queryParams: {
fundId:fundId,
userId:userId,
pageSize:pageSize,
pageNum:pageNum,
}
 });
try {
const response = yield call(api.get, url);
console.log("getPropertyDashboardGridViewByFundId"+JSON.stringify(response.data));
yield put(ErrorActions.hideError());
yield put(tmActions.tmPropertyDashboardGridViewByFundIdSuccess(response.data));
} catch (err) {
yield put(ErrorActions.setError('error in getPropertyDashboardGridViewByFundId'));
 }
}
                                                                         
                                                                         
                                                                 
